<div class="page-title">
  <h1>{{workers_mode ? 'Trabajadores' : 'Usuarios'}}</h1>
  <button mat-flat-button color="accent" (click)="addUser()" [disabled]="!permissions.validateDepartment(['direction', 'rrhh'])">
    <mat-icon style="margin-right: 10px">person_add</mat-icon>
    Añadir {{ workers_mode ? 'trabajador' : 'usuario' }}
  </button>
</div>

<div class="bisual-table">
  <form class="card paginator" [formGroup]="filterForm">
    <mat-paginator
      [length]="length"
      [pageSize]="pageSize"
      [pageSizeOptions]="pageSizeOptions"
      [pageIndex]="pageIndex"
      (page)="changePage($event)"
      style="margin-right: auto;"
      >
    </mat-paginator>

    @if (!workers_mode) {
      <mat-form-field appearance="outline" class="bisual-paginator-select">
        <mat-label>Roles</mat-label>
        <mat-select formControlName="role_id">
          <mat-option [value]="''">Todos</mat-option>
          @for (r of roles; track r) {
            <mat-option [value]="r.id">
              {{r.name}}
            </mat-option>
          }
        </mat-select>
      </mat-form-field>
    }
    <!-- <mat-form-field appearance="outline" class="bisual-paginator-select" *ngIf="!workers_mode">
    <mat-label>Categoria</mat-label>
    <mat-select formControlName="department_category_id">
      <mat-option [value]="''">Todos</mat-option>
      <mat-option *ngFor="" [value]="">
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field appearance="outline" class="bisual-paginator-select">
    <mat-label>Departamentos</mat-label>
    <mat-select formControlName="department_id">
      <mat-option [value]="''">Todos</mat-option>
      <mat-option *ngFor="let d of departments" [value]="d.id">
        {{d.name}}
      </mat-option>
    </mat-select>
  </mat-form-field> -->
  @if (!workers_mode) {
    <mat-form-field appearance="outline" class="bisual-paginator-select">
      <mat-label>Empresas</mat-label>
      <mat-select formControlName="company_id">
        <mat-option [value]="''">Todas</mat-option>
        @for (c of company; track c) {
          <mat-option [value]="c.id">
            {{ c.fact_name }}
          </mat-option>
        }
      </mat-select>
    </mat-form-field>
  }
  <mat-form-field>
    <input matInput type="text" formControlName="search">
    <button matSuffix mat-icon-button aria-label="Clear">
      <mat-icon>search</mat-icon>
    </button>
  </mat-form-field>
</form>

<div class="bisual-table-container">
  <table mat-table [dataSource]="dataSource">

    <ng-container matColumnDef="image">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let user">
        <mat-icon>account_circle</mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>Nombre</th>
      <td mat-cell *matCellDef="let user">
        <a [routerLink]="[user.id]">{{user.name + ' ' + user.surnames}}</a>
      </td>
    </ng-container>

    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef>Email</th>
      <td mat-cell *matCellDef="let user">
        {{user.email }}
      </td>
    </ng-container>

    <ng-container matColumnDef="role">
      <th mat-header-cell *matHeaderCellDef>Rol</th>
      <td mat-cell *matCellDef="let user">{{user.role.name}} - {{ user.current_working_contract?.current_working_contract_variable_condition?.department_category?.name ?? (user.client_role | titlecase) ?? '?' }}</td>
    </ng-container>

    <ng-container matColumnDef="company">
      <th mat-header-cell *matHeaderCellDef>Empresa</th>
      <td mat-cell *matCellDef="let user">
        @if (user.company!=null) {
          <a [routerLink]="['/employees', 'direction', 'users-management', 'companies', user.company.id]">{{user.company.fact_name}}</a>
        }
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let user; let i = index;">
        <button mat-icon-button [matMenuTriggerFor]="menu">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          @if (router.url.includes('/users-management')) {
            <button mat-menu-item [routerLink]="[user.id]" [disabled]="!permissions.validateDepartment(['direction', 'rrhh'])">
              <mat-icon matPrefix>edit</mat-icon>
              Editar usuario
            </button>
          }
          @if (router.url.includes('/users-management')) {
            <button mat-menu-item (click)="deleteUser(i)" [disabled]="!permissions.validateDepartment(['direction', 'rrhh'])">
              <mat-icon matPrefix>delete</mat-icon>
              Borrar usuario
            </button>
          }
          @if (router.url.includes('/workers')) {
            <button mat-menu-item [routerLink]="[user.id]" [disabled]="!permissions.validateDepartment(['direction', 'rrhh'])">
              <mat-icon matPrefix>edit</mat-icon>
              Editar trabajador
            </button>
          }
          @if (router.url.includes('/workers')) {
            <button mat-menu-item (click)="deleteUser(i)" [disabled]="!permissions.validateDepartment(['direction', 'rrhh'])">
              <mat-icon matPrefix>delete</mat-icon>
              Borrar trabajador
            </button>
          }
        </mat-menu>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>
</div>
