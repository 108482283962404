<h3 mat-dialog-title>Detalles de la fase</h3>

<mat-dialog-content [formGroup]="form">
  <div class="container">
    <div class="row">
      <div class="col-12 col-lg-6">
        <mat-label>Nombre de tablero</mat-label>
        <mat-form-field appearance="outline">
          <input matInput formControlName="title">
        </mat-form-field>
      </div>
      <div class="col-12 col-lg-6">
        <mat-label>Estado</mat-label>
        <mat-form-field appearance="outline">
          <mat-select formControlName="project_phase_stage_id">
            @for (pps of project_phase_stages; track pps) {
              <mat-option [value]="pps.id">{{ pps.title }}</mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-12 col-lg-6" style="margin-top: 20px">
        <div class="row">
          <label>Horas requeridas</label>
          <div class="col-12">
            <mat-checkbox formControlName="needs_hours_story">
              <img [src]="'story' | projectTaskTypeImage" alt="Stories" width="20" />
              Requiere horas en Stories
            </mat-checkbox>
          </div>
          <div class="col-12">
            <mat-checkbox formControlName="needs_hours_task">
              <img [src]="'task' | projectTaskTypeImage" alt="Tasks" width="20" />
              Requiere horas en Tasks
            </mat-checkbox>
          </div>
          <div class="col-12">
            <mat-checkbox formControlName="needs_hours_issue">
              <img [src]="'issue' | projectTaskTypeImage" alt="Issues" width="20" />
              Requiere horas en Issues
            </mat-checkbox>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-6" style="margin-top: 20px">
        <div class="row">
          <label>Otros permisos</label>
          <div class="col-12">
            <mat-checkbox formControlName="can_client_business_add_approval_tasks">Un cliente (business) puede crear tareas por aprobar en backlog</mat-checkbox>
          </div>
          <div class="col-12">
            <mat-checkbox formControlName="can_client_dev_manage_backlog">Un cliente (DEV) puede gestionar el backlog libremente</mat-checkbox>
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button color="transparent" (click)="onCancel()">Cancelar</button>
  <button mat-flat-button color="accent" (click)="save()" [disabled]="!form.valid" type="submit">Guardar</button>
</mat-dialog-actions>
