<div class="page-title">
    <h1>Proveedores</h1>
    <button mat-flat-button color="accent" (click)="addProvider()">
        <mat-icon style="margin-right: 10px">person_add</mat-icon>
        Añadir proveedor
    </button>
</div>

<div class="bisual-table">
    <form class="card paginator" [formGroup]="filterForm">
        <mat-paginator
            [length]="length"
            [pageSize]="pageSize"
            [pageSizeOptions]="pageSizeOptions"
            [pageIndex]="pageIndex"
            (page)="changePage($event)"
            style="margin-right: auto;"
        ></mat-paginator>

        <mat-form-field>
            <input matInput type="text" formControlName="search">
            <button matSuffix mat-icon-button aria-label="Clear">
                <mat-icon>search</mat-icon>
            </button>
        </mat-form-field>
    </form>

    <div class="bisual-table-container">
        <table mat-table [dataSource]="dataSource">
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef>Nombre de empresa</th>
                <td mat-cell *matCellDef="let provider">
                    <a [routerLink]="[provider.id]">{{provider.fact_name}}</a>
                </td>
            </ng-container>

            <ng-container matColumnDef="country">
                <th mat-header-cell *matHeaderCellDef>País</th>
                <td mat-cell *matCellDef="let provider">{{provider.fact_country}}</td>
            </ng-container>

            <ng-container matColumnDef="contasimple">
                <th mat-header-cell *matHeaderCellDef>Contasimple</th>
                <td mat-cell *matCellDef="let provider">{{provider.contasimple_id!=null ? 'Activo' : 'Inactivo'}}</td>
            </ng-container>

            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let provider; let i = index;">
                    <button mat-icon-button [matMenuTriggerFor]="menu">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item [routerLink]="[provider.id]">
                            <mat-icon matPrefix>edit</mat-icon>
                            Editar proveedor
                        </button>
                        <button mat-menu-item (click)="deleteProvider(i)" [disabled]="!permissions.validateDepartment(['direction', 'finance'])">
                            <mat-icon matPrefix>delete</mat-icon>
                            Borrar proveedor
                        </button>
                    </mat-menu>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>
</div>
