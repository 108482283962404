import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/models/user.model';
import { UserService } from 'src/app/services/user.service';
import { ActivatedRoute } from '@angular/router';



@Component({
  selector: 'app-my-working-contracts',
  templateUrl: './my-working-contracts.component.html',
  styleUrls: ['./my-working-contracts.component.css']
})
export class MyWorkingContractsComponent implements OnInit {

  user:User = null as any;


  constructor(private userService:UserService,
              private activatedRoute:ActivatedRoute,
    ) {

  }

  ngOnInit(): void {
    this.user = this.userService.getCurrentUser();
  }


}
