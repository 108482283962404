import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BusinessMail } from 'src/app/models/business_mail.model';
import { ApiService } from 'src/app/services/api.service';
import { PermissionsService } from 'src/app/services/permissions.service';
import { RegexService } from 'src/app/services/regex.service';

@Component({
  selector: 'app-business-mail-detail-edit',
  templateUrl: './business-mail-detail-edit.component.html',
  styleUrls: ['./business-mail-detail-edit.component.css']
})
export class BusinessMailDetailEditComponent implements OnInit {

  formGroup:UntypedFormGroup;

  private _business_mail:BusinessMail = null as any;
  get business_mail() { return this._business_mail; };
  @Input() set business_mail(bm:BusinessMail) {
    if(bm!=null) {
      this._business_mail = bm;
      this.formGroup.patchValue(bm);
    }
  }
  
  constructor(private fb:UntypedFormBuilder,
              private api:ApiService,
              private regex:RegexService,
              public permissions:PermissionsService) {

    const regexParams = this.regex.getRegex();

    this.formGroup = this.fb.group({
      id: [''],
      email: ['', Validators.compose([Validators.required, Validators.email])],
      provider_id: ['', Validators.required],
      company_id: [''],
      expiration_date: [''],
      is_fact_external: ['', Validators.required],
      renewal_days_period: ['', Validators.required],
      ovh_id: [''],
      quantity: [''],
      imap_server: ['', Validators.required],
      imap_port: ['', Validators.required],
      imap_encryption: ['', Validators.required],
      smtp_server: ['', Validators.required],
      smtp_port: ['', Validators.required],
      smtp_encryption: ['', Validators.required],
      webmail_url: ['', Validators.required],
    });

    this.formGroup.controls['email'].disable();

  }

  ngOnInit(): void {
  }

  save() {
    this.api.updateBusinessMail(this.business_mail.id, this.formGroup.value).subscribe(
      data => {

      } 
    );
  }

}
