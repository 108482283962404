import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Project } from 'src/app/models/project.model';
import { ProjectPhaseStage } from 'src/app/models/project_phase_stage.model';
import { ApiService } from 'src/app/services/api.service';
import { PermissionsService } from 'src/app/services/permissions.service';
import { UtilsService } from 'src/app/services/utils.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.css']
})
export class ProjectsComponent implements OnInit {

  displayedColumns: string[];
  dataSource:MatTableDataSource<Project> = new MatTableDataSource<Project>();

  // dades per el paginator
  length:number = 0;
  pageSize:number = 10;
  pageSizeOptions:number[] = [5,10,20,50];
  pageIndex:number = 1;

  filterForm:UntypedFormGroup;
  private formPersistence:any;

  stages:ProjectPhaseStage[] = [];

  constructor(private fb:UntypedFormBuilder,
              private activatedRoute:ActivatedRoute,
              private api:ApiService,
              private router:Router,
              private utils:UtilsService,
              private userService:UserService,
              public permissions:PermissionsService) {
    this.filterForm = this.fb.group({
      search: [this.activatedRoute.snapshot.queryParamMap.get('search')!=null ? this.activatedRoute.snapshot.queryParamMap.get('search') : '', Validators.compose([Validators.minLength(3)])],
      per_page: [this.activatedRoute.snapshot.queryParamMap.get('per_page')!=null ? +(this.activatedRoute.snapshot.queryParamMap.get('per_page') as any) : this.pageSize, Validators.required],
      page: [this.activatedRoute.snapshot.queryParamMap.get('page')!=null ? +(this.activatedRoute.snapshot.queryParamMap.get('page') as any) : this.pageIndex, Validators.required],
      total: [this.activatedRoute.snapshot.queryParamMap.get('total')!=null ? +(this.activatedRoute.snapshot.queryParamMap.get('total') as any) : this.length],
      ro_is_active: [this.activatedRoute.snapshot.queryParamMap.get('ro_is_active')!=null ? +(this.activatedRoute.snapshot.queryParamMap.get('ro_is_active') as any) : 1],
      ro_is_favorite: [this.activatedRoute.snapshot.queryParamMap.get('ro_is_favorite')!=null ? +(this.activatedRoute.snapshot.queryParamMap.get('ro_is_favorite') as any) : ''],
    });
  }

  ngOnInit(): void {
    this.fetchStages();
    this.initFilterFormListener();
    this.listenQueryParameters();
    this.filterColumnsForRol();
  }

  changePage(event:PageEvent) {
    this.filterForm.patchValue({
      page: event.pageIndex,
      per_page: event.pageSize
    });
  }

  private fetchProjects() {
    const query:any = this.filterForm.value;
    this.api.getProjectsPaged({...query, with: 'project_phases,company,project_manager,product_owner,active_contracts..current_contract_variable_condition', append: 'ro_is_active'}).subscribe(
      data => {
        this.dataSource.data = data.data;

        this.length = +data.meta.total;
        this.pageIndex = +data.meta.current_page-1;
        this.pageSize = +data.meta.per_page;
      }
    );
  }

  private filterColumnsForRol(){
    let userRole = this.userService.getCurrentUser().role.slug;
    if(userRole == "worker"){
      this.displayedColumns = ['ro_is_favorite', 'title', 'company', 'product_owner', 'project_manager', 'contract', 'actions'];
    }
    else if(userRole == "client"){
      this.displayedColumns = ['title', 'product_owner', 'project_manager', 'contract'];
    }
  }

  private initFilterFormListener() {
    let userRole = this.userService.getCurrentUser().role.slug;
    this.filterForm.valueChanges.subscribe(
      data => {
        data.per_page = +data.per_page;
        if(this.formPersistence==null || JSON.stringify(this.formPersistence)!=JSON.stringify(data)) {
          if(this.formPersistence.per_page!=data.per_page) data.page = 0;
          if(userRole == "worker"){
            this.router.navigate(['/employees', 'development', 'projects'], { queryParams: data });
          }
          else if(userRole == "client"){
            this.router.navigate(['/clients', 'projects'], { queryParams: data });
          }

        }
      }
    );
  }

  private listenQueryParameters() {
    this.activatedRoute.queryParams.subscribe(
      params => {
        if(JSON.stringify(params)!==JSON.stringify(this.filterForm.value)) { //si no ve de filtre s'ha de setejar el form
          let params_temp = this.utils.cloneObj(params); //params es read_only
          Object.keys(params_temp).forEach(param_key => {
            if(params_temp[param_key]!=null && params_temp[param_key]!="" && !isNaN(+params_temp[param_key])) params_temp[param_key] = +params_temp[param_key]; // si es numero, el transformem
          });
          this.filterForm.patchValue(params_temp, { emitEvent: false });
        }
        this.formPersistence = params;
        this.fetchProjects();
      }
    );
  }

  private fetchStages() {
    this.api.getProjectPhaseStages().subscribe(
      data => {
        this.stages = data;
      }
    );
  }

  updateFavoriteProject(project:Project) {
    if(!project.ro_is_favorite) {
      this.api.addProjectToFavorite(this.userService.getCurrentUser().id, project.id).subscribe(
        data => {
          this.fetchProjects();
        }
      );
    } else {
      this.api.removeProjectFromFavorite(this.userService.getCurrentUser().id, project.id).subscribe(
        data => {
          this.fetchProjects();
        }
      );
    }
  }

}
