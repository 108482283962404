<div id="content">
  <div class="page-title">
    <h1>{{ titleContract }}</h1>
    @if (router.url.includes('/rrhh/working-contracts')) {
      <button
        mat-flat-button
        [disabled]="!contractForm.valid"
        (click)="save()"
        color="accent"
        >
        <mat-icon style="margin-right: 10px">save</mat-icon>
        Guardar
      </button>
    }
  </div>

  <div class="bisual-table">
    <form [formGroup]="contractForm" class="card">
      <div class="container">
        <div class="row">
          <div class="col-12 col-lg-2">
            <mat-form-field appearance="outline">
              <mat-label>Usuario</mat-label>
              <input
                type="text"
                matInput
                [formControl]="userContractSelectControl"
                [matAutocomplete]="user_contract_select_auto"
                />
              <mat-autocomplete
                #user_contract_select_auto="matAutocomplete"
                [displayWith]="displayFnUser"
                (optionSelected)="selectCustomer($event.option.value)"
                >
                @for (u of available_users; track u) {
                  <mat-option [value]="u">{{
                    u.name
                  }}</mat-option>
                }
              </mat-autocomplete>
            </mat-form-field>
          </div>

          <div class="col-12 col-lg-2">
            <mat-form-field appearance="outline">
              <mat-label>Horas semanales</mat-label>
              <input
                type="text"
                matInput
                formControlName="hours_per_week_expected"
                />
            </mat-form-field>
          </div>

          <div class="col-12 col-lg-2">
            <mat-form-field appearance="outline">
              <mat-label>Precio hora</mat-label>
              <input type="text" matInput formControlName="price_per_hour" />
            </mat-form-field>
          </div>

          <div class="col-12 col-lg-2">
            <mat-form-field appearance="outline">
              <mat-label>Pagos anuales</mat-label>
              <input
                type="text"
                matInput
                formControlName="number_of_payments_per_year"
                />
            </mat-form-field>
          </div>

          <div class="col-12 col-lg-2">
            <mat-form-field appearance="outline">
              <mat-label>Salario bruto anual</mat-label>
              <input
                type="number"
                matInput
                min="0"
                formControlName="brute_anual_salary"
                />
              <mat-icon matSuffix>euro</mat-icon>
            </mat-form-field>
          </div>

        </div>

        <div class="row">
          <div class="col">
            <mat-form-field appearance="outline">
              <mat-label>Tipo contrato</mat-label>
              <mat-select formControlName="working_contract_type_id">
                @for (contractType of workingContractsTypes; track contractType) {
                  <mat-option
                    [value]="contractType.id"
                    >{{ contractType.name }}</mat-option
                    >
                  }
                </mat-select>
              </mat-form-field>
            </div>

            <div class="col">
              <mat-form-field appearance="outline">
                <mat-label>Inicio del contrato</mat-label>
                <input
                  matInput
                  [matDatepicker]="picker"
                  formControlName="start_date"
                  />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="picker"
                ></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>
            </div>

            <div class="col">
              <mat-form-field  appearance="outline">
                <mat-label>Final del contrato</mat-label>
                <input
                  matInput
                  [matDatepicker]="picker2"
                  formControlName="end_date"
                  />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="picker2"
                ></mat-datepicker-toggle>
                <mat-datepicker #picker2></mat-datepicker>
              </mat-form-field>
            </div>

            <div class="col">
              <mat-checkbox formControlName="is_signed_by_bisual"
                >Firmado por bisual</mat-checkbox
                >
              </div>

              <div class="col">
                <mat-checkbox formControlName="is_signed_by_worker"
                  >Firmado por el trabajador</mat-checkbox
                  >
                </div>
              </div>

              <div class="row">
                <mat-form-field appearance="outline">
                  <mat-label>Descripción de trabajo</mat-label>
                  <textarea matInput
                    cdkTextareaAutosize
                    cdkAutosizeMinRows="3"
                    cdkAutosizeMaxRows="15"
                  formControlName="job_description"></textarea>
                  <!-- <input type="text" matInput formControlName="job_description" /> -->
                </mat-form-field>
              </div>
            </div>
            <div class="bisual-table mt-5">
              <div class="bisual-table-container">
                <table>
                  <thead>
                    <tr>
                      <th class="td-contracts"></th>
                      <th>LUNES</th>
                      <th>MARTES</th>
                      <th>MIÉRCOLES</th>
                      <th>JUEVES</th>
                      <th>VIERNES</th>
                      <th>SÁBADO</th>
                      <th>DOMINGO</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class="td-contracts"><b>Mañana</b></td>
                      <td>
                        <div class="container">
                          <div class="row">
                            <div class="col-6 row-table__contract">
                              <mat-form-field>
                                <input
                                  type="number"
                                  formControlName="monday_morning_entry_time"
                                  matInput
                                  step=".5" min="0" max="23.5"
                                  />
                              </mat-form-field>
                            </div>
                            <div class="col-6 row-table__contract">
                              <mat-form-field>
                                <input
                                  type="number"
                                  formControlName="monday_morning_exit_time"
                                  matInput
                                  step=".5" min="0" max="23.5"
                                  />
                              </mat-form-field>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="container">
                          <div class="row">
                            <div class="col-6 row-table__contract">
                              <mat-form-field>
                                <input
                                  type="number"
                                  formControlName="tuesday_morning_entry_time"
                                  matInput
                                  step=".5" min="0" max="23.5"
                                  />
                              </mat-form-field>
                            </div>
                            <div class="col-6 row-table__contract">
                              <mat-form-field>
                                <input
                                  type="number"
                                  formControlName="tuesday_morning_exit_time"
                                  matInput
                                  step=".5" min="0" max="23.5"
                                  />
                              </mat-form-field>
                            </div>
                          </div>
                        </div>
                      </td>

                      <td>
                        <div class="container">
                          <div class="row">
                            <div class="col-6 row-table__contract">
                              <mat-form-field>
                                <input
                                  type="number"
                                  formControlName="wednesday_morning_entry_time"
                                  matInput
                                  step=".5" min="0" max="23.5"
                                  />
                              </mat-form-field>
                            </div>
                            <div class="col-6 row-table__contract">
                              <mat-form-field>
                                <input
                                  type="number"
                                  formControlName="wednesday_morning_exit_time"
                                  matInput
                                  step=".5" min="0" max="23.5"
                                  />
                              </mat-form-field>
                            </div>
                          </div>
                        </div>
                      </td>

                      <td>
                        <div class="container">
                          <div class="row">
                            <div class="col-6 row-table__contract">
                              <mat-form-field>
                                <input
                                  type="number"
                                  formControlName="thursday_morning_entry_time"
                                  matInput
                                  step=".5" min="0" max="23.5"
                                  />
                              </mat-form-field>
                            </div>
                            <div class="col-6 row-table__contract">
                              <mat-form-field>
                                <input
                                  type="number"
                                  formControlName="thursday_morning_exit_time"
                                  matInput
                                  step=".5" min="0" max="23.5"
                                  />
                              </mat-form-field>
                            </div>
                          </div>
                        </div>
                      </td>

                      <td>
                        <div class="container">
                          <div class="row">
                            <div class="col-6 row-table__contract">
                              <mat-form-field>
                                <input
                                  type="number"
                                  formControlName="friday_morning_entry_time"
                                  matInput
                                  step=".5" min="0" max="23.5"
                                  />
                              </mat-form-field>
                            </div>
                            <div class="col-6 row-table__contract">
                              <mat-form-field>
                                <input
                                  type="number"
                                  formControlName="friday_morning_exit_time"
                                  matInput
                                  step=".5" min="0" max="23.5"
                                  />
                              </mat-form-field>
                            </div>
                          </div>
                        </div>
                      </td>

                      <td>
                        <div class="container">
                          <div class="row">
                            <div class="col-6 row-table__contract">
                              <mat-form-field>
                                <input
                                  type="number"
                                  formControlName="saturday_morning_entry_time"
                                  matInput
                                  step=".5" min="0" max="23.5"
                                  />
                              </mat-form-field>
                            </div>
                            <div class="col-6 row-table__contract">
                              <mat-form-field>
                                <input
                                  type="number"
                                  formControlName="saturday_morning_exit_time"
                                  matInput
                                  step=".5" min="0" max="23.5"
                                  />
                              </mat-form-field>
                            </div>
                          </div>
                        </div>
                      </td>

                      <td>
                        <div class="container">
                          <div class="row">
                            <div class="col-6 row-table__contract">
                              <mat-form-field>
                                <input
                                  type="number"
                                  formControlName="sunday_morning_entry_time"
                                  matInput
                                  step=".5" min="0" max="23.5"
                                  />
                              </mat-form-field>
                            </div>
                            <div class="col-6 row-table__contract">
                              <mat-form-field>
                                <input
                                  type="number"
                                  formControlName="sunday_morning_exit_time"
                                  matInput
                                  step=".5" min="0" max="23.5"
                                  />
                              </mat-form-field>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td class="td-contracts"><b>Tarde</b></td>
                      <td>
                        <div class="container">
                          <div class="row">
                            <div class="col-6 row-table__contract">
                              <mat-form-field>
                                <input
                                  type="number"
                                  formControlName="monday_afternoon_entry_time"
                                  matInput
                                  step=".5" min="0" max="23.5"
                                  />
                              </mat-form-field>
                            </div>
                            <div class="col-6 row-table__contract">
                              <mat-form-field>
                                <input
                                  type="number"
                                  formControlName="monday_afternoon_exit_time"
                                  matInput
                                  step=".5" min="0" max="23.5"
                                  />
                              </mat-form-field>
                            </div>
                          </div>
                        </div>
                      </td>

                      <td>
                        <div class="container">
                          <div class="row">
                            <div class="col-6 row-table__contract">
                              <mat-form-field>
                                <input
                                  type="number"
                                  formControlName="tuesday_afternoon_entry_time"
                                  matInput
                                  step=".5" min="0" max="23.5"
                                  />
                              </mat-form-field>
                            </div>
                            <div class="col-6 row-table__contract">
                              <mat-form-field>
                                <input
                                  type="number"
                                  formControlName="tuesday_afternoon_exit_time"
                                  matInput
                                  step=".5" min="0" max="23.5"
                                  />
                              </mat-form-field>
                            </div>
                          </div>
                        </div>
                      </td>

                      <td>
                        <div class="container">
                          <div class="row">
                            <div class="col-6 row-table__contract">
                              <mat-form-field>
                                <input
                                  type="number"
                                  formControlName="wednesday_afternoon_entry_time"
                                  matInput
                                  step=".5" min="0" max="23.5"
                                  />
                              </mat-form-field>
                            </div>
                            <div class="col-6 row-table__contract">
                              <mat-form-field>
                                <input
                                  type="number"
                                  formControlName="wednesday_afternoon_exit_time"
                                  matInput
                                  step=".5" min="0" max="23.5"
                                  />
                              </mat-form-field>
                            </div>
                          </div>
                        </div>
                      </td>

                      <td>
                        <div class="container">
                          <div class="row">
                            <div class="col-6 row-table__contract">
                              <mat-form-field>
                                <input
                                  type="number"
                                  formControlName="thursday_afternoon_entry_time"
                                  matInput
                                  step=".5" min="0" max="23.5"
                                  />
                              </mat-form-field>
                            </div>
                            <div class="col-6 row-table__contract">
                              <mat-form-field>
                                <input
                                  type="number"
                                  formControlName="thursday_afternoon_exit_time"
                                  matInput
                                  step=".5" min="0" max="23.5"
                                  />
                              </mat-form-field>
                            </div>
                          </div>
                        </div>
                      </td>

                      <td>
                        <div class="container">
                          <div class="row">
                            <div class="col-6 row-table__contract">
                              <mat-form-field>
                                <input
                                  type="number"
                                  formControlName="friday_afternoon_entry_time"
                                  matInput
                                  step=".5" min="0" max="23.5"
                                  />
                              </mat-form-field>
                            </div>
                            <div class="col-6 row-table__contract">
                              <mat-form-field>
                                <input
                                  type="number"
                                  formControlName="friday_afternoon_exit_time"
                                  matInput
                                  step=".5" min="0" max="23.5"
                                  />
                              </mat-form-field>
                            </div>
                          </div>
                        </div>
                      </td>

                      <td>
                        <div class="container">
                          <div class="row">
                            <div class="col-6 row-table__contract">
                              <mat-form-field>
                                <input
                                  type="number"
                                  formControlName="saturday_afternoon_entry_time"
                                  matInput
                                  step=".5" min="0" max="23.5"
                                  />
                              </mat-form-field>
                            </div>
                            <div class="col-6 row-table__contract">
                              <mat-form-field>
                                <input
                                  type="number"
                                  formControlName="saturday_afternoon_exit_time"
                                  matInput
                                  step=".5" min="0" max="23.5"
                                  />
                              </mat-form-field>
                            </div>
                          </div>
                        </div>
                      </td>

                      <td>
                        <div class="container">
                          <div class="row">
                            <div class="col-6 row-table__contract">
                              <mat-form-field>
                                <input
                                  type="number"
                                  formControlName="sunday_afternoon_entry_time"
                                  matInput
                                  step=".5" min="0" max="23.5"
                                  />
                              </mat-form-field>
                            </div>
                            <div class="col-6 row-table__contract">
                              <mat-form-field>
                                <input
                                  type="number"
                                  formControlName="sunday_afternoon_exit_time"
                                  matInput
                                  step=".5" min="0" max="23.5"
                                  />
                              </mat-form-field>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </form>
        </div>
      </div>
