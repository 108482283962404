import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Holiday } from 'src/app/models/holiday.model';

@Component({
  selector: 'app-vacations-and-holidays-dialog',
  templateUrl: './vacations-and-holidays-dialog.component.html',
  styleUrls: ['./vacations-and-holidays-dialog.component.css']
})
export class VacationsAndHolidaysDialogComponent implements OnInit {

  ta:any;

  constructor(public dialogRef: MatDialogRef<VacationsAndHolidaysDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data:any) {
                this.ta = data.total_availability;
              }

  ngOnInit(): void {
    if(this.ta==null) this.close();
  }

  close() {
    this.dialogRef.close();
  }

  getZonesString(h:Holiday) {
    let res:string = null as any;

    // ciutat
    if(h.city!=null) res = h.city;

    // state
    if(h.state != null) {
      if(res==null) res = h.state;
      else res += `, ${h.state}`;
    }

    // country
    if(h.country != null) {
      if(res==null) res = h.country;
      else res += `, ${h.country}`;
    }

    return res;
  }

}
