@if (meetingableId) {
  <app-meeting-record
    [meetingableType]="meetingableType"
    [meetingableId]="meetingableId"
    [meetingRecordId]="meetingRecordId"
    [onSaveRoute]="'/employees/development/projects/' + meetingableId + '/meeting-records/:meeting_record_id'"
    [onBackRoute]="'/employees/development/projects/' + meetingableId + '/meeting-records'"
    >
  </app-meeting-record>
}
