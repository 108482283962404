import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BusinessMail } from 'src/app/models/business_mail.model';
import { Department } from 'src/app/models/department.model';
import { Domain } from 'src/app/models/domain.model';
import { Hosting } from 'src/app/models/hosting.model';
import { PaymentType } from 'src/app/models/payment_type.model';
import { Provider } from 'src/app/models/provider.model';
import { ProviderOrder } from 'src/app/models/provider_order.model';
import { ProviderOrderCategory } from 'src/app/models/provider_order_category.model';
import { ApiService } from 'src/app/services/api.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-date-from-to-dialog',
  templateUrl: './date-from-to-dialog.component.html',
  styleUrls: ['./date-from-to-dialog.component.css']
})
export class DateFromToDialogComponent implements OnInit {

  formGroup:UntypedFormGroup;

  constructor(public dialogRef:MatDialogRef<DateFromToDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data:any,
              private fb:UntypedFormBuilder,
              private api:ApiService,
              private utils:UtilsService) {

    this.formGroup = this.fb.group({
      start: [this.data.startDate, Validators.required],
      end: [this.data.endDate, Validators.required],
    });

  }

  ngOnInit(): void {
  }

  closeDialog(){
    this.dialogRef.close();
  }

  onDateInputChange(event: any, dateType: string) {
    const selectedDate = new Date(event.target.value);
    //if (selectedDate.getDay() !== 1 && selectedDate.getDay() != 5) { // 0: domingo, 1: lunes, ..., 6: sábado
      this.formGroup.controls[dateType].setValue(selectedDate);
    //}
  }

  sendDate(){
    this.dialogRef.close(this.formGroup.value);
  }
}
