import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BusinessMail } from 'src/app/models/business_mail.model';
import { Department } from 'src/app/models/department.model';
import { Domain } from 'src/app/models/domain.model';
import { Hosting } from 'src/app/models/hosting.model';
import { PaymentType } from 'src/app/models/payment_type.model';
import { Provider } from 'src/app/models/provider.model';
import { ProviderOrder } from 'src/app/models/provider_order.model';
import { ProviderOrderCategory } from 'src/app/models/provider_order_category.model';
import { ApiService } from 'src/app/services/api.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-provider-order-dialog',
  templateUrl: './provider-order-dialog.component.html',
  styleUrls: ['./provider-order-dialog.component.css']
})
export class ProviderOrderDialogComponent implements OnInit {

  formGroup:UntypedFormGroup;

  payment_types:PaymentType[] = [];
  departments:Department[] = [];
  provider_order_categories:ProviderOrderCategory[] = [];

  providers_filtered:Provider[] = [];
  providers_property_control:UntypedFormControl = new UntypedFormControl('', Validators.compose([Validators.minLength(1)]));

  iva_types:number[] = [0,4,7,8,10,16,18,21];

  get lines() {
    return this.formGroup.get('lines') as UntypedFormArray;
  }

  hostings_filtered:Hosting[] = [];
  hostings_property_control:UntypedFormControl = new UntypedFormControl('', Validators.compose([Validators.minLength(1)]));
  domains_filtered:Domain[] = [];
  domains_property_control:UntypedFormControl = new UntypedFormControl('', Validators.compose([Validators.minLength(1)]));
  business_mails_filtered:BusinessMail[] = [];
  business_mails_property_control:UntypedFormControl = new UntypedFormControl('', Validators.compose([Validators.minLength(1)]));

  constructor(public dialogRef:MatDialogRef<ProviderOrderDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data:any,
              private fb:UntypedFormBuilder,
              private api:ApiService,
              private utils:UtilsService) {

    this.formGroup = this.fb.group({
      invoice_number: ['', Validators.required],
      invoice_date: [new Date(), Validators.required],
      tax_prcnt: [21, Validators.compose([Validators.required, Validators.min(0)])],
      total: [0, Validators.compose([Validators.required, Validators.min(0.01)])],
      due_date: [''],
      payed: [true, Validators.required],
      lines: this.fb.array([]),
      payment_type_id: [''],
      provider_id: ['', Validators.required],
      provider_order_category_id: ['', Validators.required],
      department_id: ['']
    });

    // afegim la primera linia
    const first_line_control = this.createLineFormControl();
    first_line_control.controls['tax_prcnt'].disable();
    (this.formGroup.controls['lines'] as UntypedFormArray).push(first_line_control);
  }

  ngOnInit(): void {
    this.listenForm();
    this.listenAutocompleteControls();
    this.fetchPaymentTypes();
    this.fetchDepartments();
    this.fetchProviderOrderCategories();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  save() {
    if(confirm("¿Estás seguro que quieres crear este gasto?")) {
      let body:any = this.utils.cloneObj(this.formGroup.value);

      body.lines.forEach((line:any, i:number) => {
        delete line.type;
        delete line.hosting_search;
        delete line.domain_search;
        delete line.business_mail_search;
      });

      this.api.createProviderOrder(body).subscribe(
        data => {
          this.dialogRef.close(data as ProviderOrder);
        }
      );
    }
  }

  selectProviderFilter(provider:Provider) {
    this.formGroup.patchValue({ provider_id: provider.id });
  }

  getLineFormGroup(i:number):UntypedFormGroup {
    return this.lines.controls[i] as UntypedFormGroup;
  }

  addLine() {
    this.lines.push(this.createLineFormControl());
  }

  deleteLine(i:number) {
    this.lines.removeAt(i);
  }

  selectHostingFilter(i:number, hosting:Hosting) {
    this.lines.controls[i].patchValue({ hosting_id: hosting.id });
  }

  selectDomainFilter(i:number, domain:Domain) {
    this.lines.controls[i].patchValue({ domain_id: domain.id });
  }

  selectBusinessMailFilter(i:number, bm:BusinessMail) {
    this.lines.controls[i].patchValue({ business_mail_id: bm.id });
  }

  private listenAutocompleteControls() {
    // companies autocomplete
    this.providers_property_control.valueChanges.subscribe(
      (data:string) => {
        if(this.providers_property_control.valid && data.length > 0) {
          let params:any = {};
          params['search'] = data;
          this.api.getProviders(params).subscribe(
            providers => {
              this.providers_filtered = providers;
            }
          );
        }
        else {
          this.formGroup.patchValue({ provider_id: '' });
          this.providers_filtered = [];
        }
      }
    );
  }

  private listenForm() {
    // propagacio de tax_prcnt a les linies
    this.formGroup.controls['tax_prcnt'].valueChanges.subscribe(
      data => {
        this.recalculateOrderTotal();
      }
    );
  }

  private createLineFormControl() {
    const group = this.fb.group({
      notes: ['', Validators.compose([Validators.required, Validators.minLength(5)])],
      tax_prcnt: [this.formGroup!=null ? this.formGroup.value['tax_prcnt'] : 21, Validators.required],
      total: [0, Validators.compose([Validators.required, Validators.min(0)])],
      hosting_id: [''],
      domain_id: [''],
      business_mail_id: [''],
      type: [''],
      hosting_search: [''],
      domain_search: [''],
      business_mail_search: [''],
    });

    /**
     * SET UP FORM CONTROL LISTENERS
     */
    group.controls['total'].valueChanges.subscribe(
      data => {
        if(!isNaN(+data!)) {
          this.utils.sleep(50).then(() => {
            this.recalculateOrderTotal();
          });
        }
      }
    );

    group.controls['type'].valueChanges.subscribe(
      data => {
        if(data==null || data=='') {
          group.patchValue({
            hosting_id: '',
            domain_id: '',
            business_mail_id: '',
          });
        }
      }
    );

    group.controls['hosting_search'].valueChanges.subscribe(
      data => {
        if(data!.length > 2) {
          let params:any = {};
          params['company_id'] = this.formGroup.value['company_id'];
          params['hosting_fqdn'] = data;
          this.api.getHostings(params).subscribe(
            hostings => {
              this.hostings_filtered = hostings;
            }
          );
        }
        else {
          group.controls['hosting_id'].setValue('');
          this.hostings_filtered = [];
        }
      }
    );

    group.controls['domain_search'].valueChanges.subscribe(
      data => {
        if(data!.length > 2) {
          let params:any = {};
          params['company_id'] = this.formGroup.value['company_id'];
          params['domain'] = data;
          this.api.getDomains(params).subscribe(
            domains => {
              this.domains_filtered = domains;
            }
          );
        }
        else {
          group.controls['domain_id'].setValue('');
          this.domains_filtered = [];
        }
      }
    );

    group.controls['business_mail_search'].valueChanges.subscribe(
      data => {
        if(data!.length > 2) {
          let params:any = {};
          params['company_id'] = this.formGroup.value['company_id'];
          params['email'] = data;
          this.api.getBusinessMails(params).subscribe(
            bms => {
              this.business_mails_filtered = bms;
            }
          );
        }
        else {
          group.controls['business_mail_id'].setValue('');
          this.business_mails_filtered = [];
        }
      }
    );

    /**
     * DISABLE FORM CONTROLS
     */
    // group.controls['tax_prcnt'].disable();

    /**
     * RETURN GROUP
     */
    return group;
  }

  private recalculateOrderTotal() {
    let total = 0;
    let prcnt = this.formGroup.value['tax_prcnt'];

    for(let line of this.lines.controls) {
      const tot = line.value['total'];

      line.patchValue({
        tax_prcnt: prcnt,
        total: +tot
      }, { emitEvent: false });

      total += tot;
    }

    this.formGroup.patchValue({
      total: total,
    });
  }

  private fetchPaymentTypes() {
    this.api.getPaymentTypes().subscribe(
      data => {
        this.payment_types = data;
      }
    );
  }

  private fetchDepartments() {
    this.api.getDepartments().subscribe(
      data => {
        this.departments = data.data;
      }
    );
  }

  private fetchProviderOrderCategories() {
    this.api.getProviderOrderCategories().subscribe(
      data => {
        this.provider_order_categories = data;
      }
    );
  }

}
