import { Pipe, PipeTransform } from '@angular/core';
import { DateAdapter } from '@angular/material/core';

@Pipe({
  name: 'CalendarDateToCustomFormat'
})
export class CalendarDateToCustomFormatPipe implements PipeTransform {

  constructor(private dateAdapter: DateAdapter<Date>) { }

  transform(value:Date):string {
    let lastDay: Date = new Date(value);
    lastDay.setDate(value.getDate() + 6);
    return this.dateAdapter.format(value, 'd/M/yy') + ' - ' + this.dateAdapter.format(lastDay, 'd/M/yy');
  }

}
