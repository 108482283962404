<div id="content">
  <nav mat-tab-nav-bar [tabPanel]="tabPanel">
    @for (link of navLinks; track link) {
      <a mat-tab-link
        [routerLink]="link.link"
        routerLinkActive #rla="routerLinkActive"
      [active]="rla.isActive">{{ link.label }}</a>
    }
  </nav>
  <mat-tab-nav-panel #tabPanel></mat-tab-nav-panel>

  @if (activeLinkIndex==0) {
    <app-business-mail-detail-resume [business_mail]="business_mail"></app-business-mail-detail-resume>
  }
  @if (activeLinkIndex==1) {
    <app-business-mail-detail-billing [business_mail]="business_mail"></app-business-mail-detail-billing>
  }
  @if (activeLinkIndex==2) {
    <app-business-mail-detail-credentials [business_mail]="business_mail"></app-business-mail-detail-credentials>
  }
  @if (activeLinkIndex==3) {
    <app-business-mail-detail-edit [business_mail]="business_mail"></app-business-mail-detail-edit>
  }
  @if (activeLinkIndex==4) {
    <app-business-mail-detail-ovh [business_mail]="business_mail"></app-business-mail-detail-ovh>
  }
</div>
