<h1 mat-dialog-title>Configuración de notificaciones semanales de deploy</h1>

<mat-dialog-content [formGroup]="form">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <label for="day_of_week_iso">Elige día de la semana: </label>
        <mat-radio-group formControlName="day_of_week_iso">
          <mat-radio-button [value]="1">Lunes</mat-radio-button>
          <mat-radio-button [value]="2">Martes</mat-radio-button>
          <mat-radio-button [value]="3">Miércoles</mat-radio-button>
          <mat-radio-button [value]="4">Jueves</mat-radio-button>
          <mat-radio-button [value]="5">Viernes</mat-radio-button>
          <mat-radio-button [value]="6">Sábado</mat-radio-button>
          <mat-radio-button [value]="7">Domingo</mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
    <div class="row" style="margin-top: 10px">
      <div class="col-12 col-lg-6">
        <mat-form-field appearance="outline">
          <mat-label>Hora de envío</mat-label>
          <input matInput type="time" formControlName="local_time" />
        </mat-form-field>
      </div>
    </div>
    <div class="row" style="margin-top: 10px">
      <div class="col-12">
        <mat-form-field appearance="outline">
          <mat-label>Usuarios a notificar</mat-label>
          <mat-chip-grid #chipGridUsers>
            @for (user of form.value.user_notifiables; track user; let i = $index) {
              <mat-chip-row (removed)="removeUser(i)">
                {{ user.name + ' ' + user.surnames }}
                <button matChipRemove>
                  <mat-icon>cancel</mat-icon>
                </button>
              </mat-chip-row>
            }
          </mat-chip-grid>
          <input placeholder="Buscar usuarios..." #usersInput [formControl]="usersSearchCtrl"
            [matChipInputFor]="chipGridUsers" [matAutocomplete]="autoUsers"/>
          <mat-autocomplete #autoUsers="matAutocomplete" (optionSelected)="selectedUser($event.option.value)">
            @for (user of filteredUsers | async; track user) {
              <mat-option [value]="user">
                {{ user.name + ' ' + user.surnames }}
              </mat-option>
            }
          </mat-autocomplete>
        </mat-form-field>
      </div>
    </div>
    <div class="row" style="margin-top: 10px">
      <div class="col-12">
        <mat-form-field appearance="outline">
          <mat-label>Canales de comunicación de empresa</mat-label>
          <mat-chip-grid #chipGridChannels>
            @for (channel of form.value.company_notification_channels; track channel; let i = $index) {
              <mat-chip-row (removed)="removeChannel(i)">
                {{ channel.email != null ? channel.email : (channel.slack_channel_name != null ? (channel.slack_channel_name + ' (Slack)') : (channel.teams_channel_name!=null ? (channel.teams_channel_name + ' (Teams)') : '?')) }}
                <button matChipRemove>
                  <mat-icon>cancel</mat-icon>
                </button>
              </mat-chip-row>
            }
          </mat-chip-grid>
          <input placeholder="Buscar canales..." #channelsInput [formControl]="channelsSearchCtrl"
            [matChipInputFor]="chipGridChannels" [matAutocomplete]="autoChannels"/>
          <mat-autocomplete #autoChannels="matAutocomplete" (optionSelected)="selectedChannel($event.option.value)">
            @for (channel of filteredChannels | async; track channel) {
              <mat-option [value]="channel">
                {{ channel.email != null ? (channel.email + ' (Email)') : (channel.slack_channel_name != null ? (channel.slack_channel_name + ' (Slack)') : '?') }}
              </mat-option>
            }
          </mat-autocomplete>
        </mat-form-field>
      </div>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-flat-button (click)="close()">Cancelar</button>
  @if (!isCreate) {
    <button mat-flat-button (click)="deleteConfiguration()">Desactivar envíos</button>
  }
  <button mat-flat-button color="accent" [disabled]="!form.valid || !form.touched" (click)="submit()">
    <mat-icon>check</mat-icon>
    Guardar configuración
  </button>
</mat-dialog-actions>
