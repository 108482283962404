import { Component, Input, OnInit, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { ProjectUserMember } from 'src/app/models/project_user_member.model';
import { User } from 'src/app/models/user.model';

@Component({
  selector: 'app-profile-pic-with-role',
  templateUrl: './profile-pic-with-role.component.html',
  styleUrls: ['./profile-pic-with-role.component.css']
})
export class ProfilePicWithRoleComponent implements OnInit {

  @Input() user: User|null;
  @Input() size: number = 50;
  @Output() userClicked = new EventEmitter();
  @Input() project_user_member: ProjectUserMember|undefined = undefined;
  profile_pic: string = "assets/img/default_profile_picture.png";

  constructor() { }

  ngOnInit(): void {
    this.updateUserData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.user && !changes.user.firstChange) {
      this.updateUserData();
    }
  }

  updateUserData(): void {
    if (this.user != null && this.user.profile_picture_url != null) {
      this.profile_pic = this.user.profile_picture_url;
    } else {
      this.profile_pic = "assets/img/default_profile_picture.png";
    }

    if(this.user != null) {
      if(this.project_user_member == null && (this.user as any).project_user_member != null) {
        this.project_user_member = (this.user as any).project_user_member;
      }
    }
  }

  onUserClick() {
    this.userClicked.emit(this.user?.id.toString());
  }

}
