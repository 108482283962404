import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ProjectTaskBoard } from 'src/app/models/project_task_board.model';
import { ApiService } from 'src/app/services/api.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-task-to-deadlock-dialog',
  templateUrl: './task-to-deadlock-dialog.component.html',
  styleUrl: './task-to-deadlock-dialog.component.css'
})
export class TaskToDeadlockDialogComponent {
  project_task_board:ProjectTaskBoard|undefined;
  form:FormGroup;

  constructor(public dialogRef: MatDialogRef<TaskToDeadlockDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data:{ project_task_board_id:number, project_id:number, project_task_id:number, direction: 'in'|'out' },
              private api:ApiService,
              private fb:FormBuilder,
              private user:UserService) {
    this.form = this.fb.group({
      project_task_id: [this.data.project_task_id, Validators.compose([Validators.required])],
      content: ['', Validators.compose([Validators.required, Validators.minLength(20)])],
      user_id: [this.user.getCurrentUser().id, Validators.compose([Validators.required])],
    });
  }

  async submit() {
    const comment = await this.api.createComment(this.form.value).toPromise();
    this.dialogRef.close(comment);
  }
}
