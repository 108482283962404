@if (project) {
  <div id="content" class="extensible" [formGroup]="filterForm">
    <div class="page-title">
      <section class="phase-title">
        <span>{{ project.title }}</span>
        <h1>{{ selectedBoard ? selectedBoard.title ?? 'Sprint ' + (boards.indexOf(selectedBoard) + 1) : 'Fase del proyecto'}}</h1>
      </section>
      <section class="phase-right">
        <button mat-flat-button [routerLink]="['../../phases']">
          <mat-icon>arrow_back</mat-icon>
          Volver al proyecto
        </button>
        @if(selectedBoard) {
          <section>
            @if (selectedBoard.end_date && isEndingDateBiggerThanToday(selectedBoard.end_date)) {
              <div id="remaining" style="color: var(--ds-text-subtlest,var(--ds-text-subtlest,#6B778C));">
                <mat-icon>access_time</mat-icon>
                {{ getDiferenceInDays(selectedBoard.end_date) }} días restantes
              </div>
            }
          </section>
          <mat-button-toggle-group formControlName="mode">
            <mat-button-toggle value="all">Todas</mat-button-toggle>
            <mat-button-toggle value="assigned">Asignadas a mí</mat-button-toggle>
            <mat-button-toggle value="listening">Soy seguidor</mat-button-toggle>
          </mat-button-toggle-group>
        }
        <section class="actions">
          <button mat-icon-button [matMenuTriggerFor]="menu">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <!-- <button mat-menu-item (click)="downloadImportTasksCSV()">
              <mat-icon>upload_file</mat-icon>
              Plantilla de creación de tareas
            </button> -->
            <button mat-menu-item (click)="showColumn = !showColumn">
              <mat-icon>{{ showColumn ? 'visibility_off' : 'visibility'}}</mat-icon>
              {{ showColumn ? 'Esconder' : 'Mostrar'}} columna lateral
            </button>
            @if (selectedBoard != null && isProductManager) {
              <button mat-menu-item (click)="openBoardSettings()">
                <mat-icon>settings</mat-icon>
                Configuración de tablero
              </button>
            }
            <div>
              <div class="field" mat-menu-item (click)="$event.stopPropagation()">
                <mat-checkbox formControlName="show_archived_boards">Ver tableros archivados</mat-checkbox>
              </div>
              <div class="field" mat-menu-item (click)="$event.stopPropagation()">
                <mat-checkbox formControlName="show_archived_tasks">Ver tareas archivadas</mat-checkbox>
              </div>
            </div>
          </mat-menu>
        </section>
      </section>
    </div>
    <div class="lateral-sidebar-structure" [ngStyle]="{'grid-template-columns': (showColumn ? '350px 1fr' : '1fr')}">
      @if (showColumn) {
        <nav>
          <ul>
            <li routerLinkActive="active" (click)="selectBoard(null)">
              <a [routerLink]="['backlog']" [queryParams]="filterForm.value">Backlog</a>
            </li>
            @for (board of boards; track board) {
              <li routerLinkActive="active" (click)="selectBoard(board)">
                <a class="board-title" [ngClass]="{ nonactive: board.status === 'finished', active: board.status === 'active' }" [routerLink]="['boards', board.id]" [queryParams]="filterForm.value">
                  @if (board.is_archived) {
                    <mat-icon>archive</mat-icon>
                  }
                  {{ board.title ?? 'Sprint ' + (boards.indexOf(board) + 1) }}
                </a>
              </li>
            }
          </ul>
        </nav>
      }
      <div class="lateral-sidebar-content">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
}
