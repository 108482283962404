import { Component, OnInit } from '@angular/core';
import { BisualSetting } from 'src/app/models/bisual-setting.model';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-configuration',
  templateUrl: './configuration.component.html',
  styleUrls: ['./configuration.component.css']
})
export class ConfigurationComponent implements OnInit {

  settings:SettingStructureRow[] = [];
  save_disabled:boolean = true;

  constructor(private api:ApiService) { }

  ngOnInit(): void {
    this.fetchSettings();
  }

  save() {
    const data = this.loadApiToUploadToApi();
    this.api.updateBulkBisualSettings(data).subscribe(
      data => {
        this.markAllAsNotEdit();
        this.save_disabled = true;
      }
    );
  }

  onInputChange(newVal:any, set:{setting:BisualSetting; edited:boolean}) {
    if(set.setting.value!=newVal) {
      set.setting.value = newVal;
      set.edited = true;
      this.save_disabled = false;
    }
  }

  private fetchSettings() {
    this.api.getBisualSettings().subscribe(
      data => {
        let res:any = {};
        data.forEach(setting => {
          if(res[setting.section]==null) {
            res[setting.section] = {
              section: setting.section,
              settings: []
            } as SettingStructureRow;
          }

          res[setting.section].settings.push({
            setting: setting,
            edited: false
          });
        });

        this.settings = Object.values(res);
      }
    );
  }

  private loadApiToUploadToApi() {
    return this.settings.map(item => item.settings)
    .flat()
    .filter(item => item.edited)
    .reduce((a:any, v:any) => {
      if(a.setting!=null) {
        // és primera vegada
        return {
          [a.setting.key]: a.setting.value,
          [v.setting.key]: v.setting.value
        };
      }
      else {
        return { ...a, [v.setting.key]: v.setting.value};
      }
    });
  }

  private markAllAsNotEdit() {
    for(let s of this.settings) {
      for(let set of s.settings) {
        if(set.edited) set.edited = false;
      }
    }
  }

}

interface SettingStructureRow {
  section:string;
  settings:{
    setting: BisualSetting;
    edited:boolean
  }[];
}
