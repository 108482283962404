@if (isTaskLoaded) {
  <div mat-dialog-title>
    <section id="issue-type">
      <div class="task-code">
        <img class="task-type" [src]="task.type | projectTaskTypeImage" />
        <span class="code">{{task.code}}</span>
      </div>
    </section>
    <section id="issue-options">
      <section id="issue-options-buttons">
        @if (project_task_board != null && currentBoard != null && (isProjectManagerOrProductManager || (isClientDeveloper && currentBoard.can_client_dev_manage_board))) {
          <button (click)="removeFromBoard()" mat-icon-button [matTooltip]="'Borrar tarea del tablero'">
            <mat-icon>do_not_disturb_on</mat-icon>
          </button>
        }
        <button mat-icon-button (click)="close()">
          <mat-icon>close</mat-icon>
        </button>
      </section>
    </section>
  </div>
  <mat-dialog-content>
    <ngx-dropzone (change)="onSelect($event)" [disableClick]="true">
      <section id="issue-body" class="row">
        <section id="issue-content" class="col-12 col-md-8">
          @if (editingField !== 'title') {
            <div (click)="setEditingField('title')" id="title-container">
              <h1>{{task.title}}</h1>
            </div>
          }
          @if (editingField == 'title') {
            <div id="title-container" class="position-relative">
              <mat-form-field class="w-100 nobottom" style="font-size: 1rem; font-family: 'Playfair Display', sans-serif; font-weight: 700;" appearance="outline">
                <input #editingInput (keydown.enter)="updateTask()" type="text" [(ngModel)]="task.title" form placeholder="Título de tarea" matInput>
              </mat-form-field>
              <section class="action-buttons position-absolute end-0">
                <button color="primary"  (click)="updateTask()">
                  <mat-icon>check</mat-icon>
                </button>
                <button color="warn" (click)="setEditingField('')">
                  <mat-icon>close</mat-icon>
                </button>
              </section>
            </div>
          }
          <div id="description-container">
            <div class="content-label">Descripción</div>
            @if (editingField !== 'description' && task.description !== null) {
              <div [innerHTML]="task.description" (click)="setEditingField('description')" class="description">
              </div>
            }
            @if (editingField !== 'description' && task.description === null) {
              <div class="placeholders description" (click)="setEditingField('description')">Añade una descripción más detallada...</div>
            }
            @if (editingField == 'description') {
              <div>
                <editor
                  [apiKey]="tinymce.getKey()"
                  [(ngModel)]="task.description"
                  cloudChannel="6"
                  [disabled]="false"
                  id="description-editor"
                  [init]="tinyMceInit"
                  initialValue=""
                  [inline]="false"
                  tagName="div"
                  plugins= "anchor autolink charmap codesample directionality help image insertdatetime link lists media nonbreaking pagebreak searchreplace table visualblocks visualchars wordcount advlist autolink lists link image charmap preview anchor searchreplace visualblocks code fullscreen insertdatetime media table emoticons codesample"
                  toolbar= "undo redo spellcheckdialog | blocks fontfamily fontsize | codesample | bold italic underline strikethrough | forecolor backcolor | insertfile link image | align lineheight checklist bullist numlist | indent outdent | removeformat typography | table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol">
                </editor>
                <div class="editorButtons">
                  <button (click)="updateTask()" class="save">
                    Guardar
                  </button>
                  <button (click)="setEditingField('')" class="cancel">
                    Cancelar
                  </button>
                </div>
              </div>
            }
          </div>
          <div class="dropzone-image-preview">
            @for (attachedFile of task.attachments; track attachedFile) {
              <div class="attachment" (mouseenter)="hoveringAttachedFile = attachedFile.id">
                @if (checkIfFileIsImage(attachedFile.file_name)) {
                  <img [src]="attachedFile.file_path" />
                }
                @if (!checkIfFileIsImage(attachedFile.file_name)) {
                  <div class="attached-file" [ngClass]="'attached-' + getFileNameExtension(attachedFile.file_name)">
                    @if (checkIfFileCanBeDisplayed(attachedFile.file_name)) {
                      <iframe [src]="attachedFile.file_path_sanitized" frameborder="0"></iframe>
                    }
                  </div>
                }
                <div class="attachment-label">
                  {{ (attachedFile.file_name.length>15) ? (attachedFile.file_name | slice:0:15)+'..':(attachedFile.file_name) }}
                </div>
                @if (hoveringAttachedFile === attachedFile.id) {
                  <div class="attachment-actions">
                    <a href={{attachedFile.file_path}} target="_blank">
                      <button mat-icon-button>
                        <mat-icon>get_app</mat-icon>
                      </button>
                    </a>
                    <button (click)="deleteAttachedFile(attachedFile)" mat-icon-button>
                      <mat-icon>close</mat-icon>
                    </button>
                  </div>
                }
              </div>
            }
          </div>
          <div id="activity-container">
            <div class="content-label">Actividad</div>
            <div class="activity">
              <header class="activity-header">
                <div>Ver:</div>
                <div class="activity-filter">
                  <button [ngClass]="{'active': activeActivityMenu === 'all'}" (click)="activeActivityMenu = 'all'">Todo</button>
                  <button [ngClass]="{'active': activeActivityMenu === 'comments'}" (click)="activeActivityMenu = 'comments'">Comentarios</button>
                  <button [ngClass]="{'active': activeActivityMenu === 'history'}" (click)="activeActivityMenu = 'history'">Historial</button>
                  <button [ngClass]="{'active': activeActivityMenu === 'worklog'}" (click)="activeActivityMenu = 'worklog'">Registro de trabajo</button>
                  <button [ngClass]="{'active': activeActivityMenu === 'distribution'}" (click)="activeActivityMenu = 'distribution'">Distribución</button>
                </div>
              </header>
              <section class="activity-body">
                @if (activeActivityMenu === 'comments' || activeActivityMenu === 'all') {
                  <section id="comments">
                    @if (editingField !== 'creatingComment') {
                      <section id="add-comment">
                        <img class="profile-image" [src]="me.profile_picture_url" />
                        <textarea (click)="editingField = 'creatingComment'" class="comment-input" placeholder="Añade un comentario..."></textarea>
                      </section>
                    }
                    @if (editingField === 'creatingComment') {
                      <section class="new-comment">
                        <section id="add-comment">
                          <img class="profile-image" [src]="me.profile_picture_url" />
                          <editor
                            [apiKey]="tinymce.getKey()"
                            [(ngModel)]="newComment"
                            cloudChannel="6"
                            [disabled]="false"
                            id="new-comment-editor"
                            [init]="{
                              automatic_uploads: true,
                              statusbar: false,
                              menubar: false,
                              file_picker_types: 'file image media',
                              auto_focus: true
                            }"
                            initialValue=""
                            [inline]="false"
                            tagName="div"
                            plugins= "anchor autolink charmap codesample directionality help image insertdatetime link lists media nonbreaking pagebreak searchreplace table visualblocks visualchars wordcount advlist autolink lists link image charmap preview anchor searchreplace visualblocks code fullscreen insertdatetime media table emoticons codesample"
                            toolbar= "undo redo spellcheckdialog | blocks fontfamily fontsize | codesample | bold italic underline strikethrough | forecolor backcolor | insertfile link image | align lineheight checklist bullist numlist | indent outdent | removeformat typography | table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol">
                          </editor>
                        </section>
                        <div class="editorButtons">
                          <button (click)="createComment()" class="save">
                            Guardar
                          </button>
                          <button (click)="editingField = ''" class="cancel">
                            Cancelar
                          </button>
                        </div>
                      </section>
                    }
                    @for (comment of task.comments; track comment) {
                      <div class="activity-entry">
                        <img class="profile-image" [src]="comment.user.profile_picture_url" />
                        @if (!editingComment || editingComment.id !== comment.id) {
                          <div class="activity-detail">
                            <header>
                              <div class="activity-user-name">{{comment.user.name + ' ' + comment.user.surnames}}</div>
                              <div class="activity-user-role">
                                {{
                                  all_team_users[comment.user.id]!=null && all_team_users[comment.user.id].project_user_member ?
                                  all_team_users[comment.user.id].project_user_member!.department_category_name :
                                  (
                                    comment.user.current_working_contract != null && comment.user.current_working_contract.current_working_contract_variable_condition != null && comment.user.current_working_contract.current_working_contract_variable_condition.department_category != null ?
                                      comment.user.current_working_contract.current_working_contract_variable_condition.department_category.name :
                                      'Rol desconocido'
                                  )
                                }}
                              </div>
                              <div class="activity-detail-date">{{ comment.updated_at | dateAgo }}</div>
                            </header>
                            <div class="body" [innerHTML]="comment.content"></div>
                            @if (comment.user_id === me.id) {
                              <footer>
                                <button (click)="setEdittingComment(comment)">
                                  <span>Editar</span>
                                </button>
                                ·
                                <button (click)="deleteComment(comment)">
                                  <span>Borrar</span>
                                </button>
                              </footer>
                            }
                          </div>
                        }
                        @if (editingComment && editingComment.id === comment.id) {
                          <div class="activity-detail">
                            <editor
                              [apiKey]="tinymce.getKey()"
                              [(ngModel)]="editingComment.content"
                              cloudChannel="6"
                              [disabled]="false"
                              id="new-comment-editor"
                              [init]="{
                                automatic_uploads: true,
                                statusbar: false,
                                menubar: false,
                                file_picker_types: 'file image media',
                                auto_focus: true
                              }"
                              initialValue=""
                              [inline]="false"
                              tagName="div"
                              plugins= "anchor autolink charmap codesample directionality help image insertdatetime link lists media nonbreaking pagebreak searchreplace table visualblocks visualchars wordcount advlist autolink lists link image charmap preview anchor searchreplace visualblocks code fullscreen insertdatetime media table emoticons codesample"
                              toolbar= "undo redo spellcheckdialog | blocks fontfamily fontsize | codesample | bold italic underline strikethrough | forecolor backcolor | insertfile link image | align lineheight checklist bullist numlist | indent outdent | removeformat typography | table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol">
                            </editor>
                            <div class="editorButtons">
                              <button (click)="updateComment(editingComment)" class="save">
                                Guardar
                              </button>
                              <button (click)="this.editingComment = null" class="cancel">
                                Cancelar
                              </button>
                            </div>
                          </div>
                        }
                      </div>
                    }
                  </section>
                }
                @if (activeActivityMenu === 'worklog' || activeActivityMenu === 'all') {
                  <section id="worklog">
                    @for (worklog of task.ro_working_times; track worklog) {
                      <div class="activity-entry">
                        <img class="profile-image" [src]="worklog.user.profile_picture_url" />
                        <div class="activity-detail">
                          <p style="margin-bottom: 5px">
                            <span class="activity-user-name">{{worklog.user.name + ' ' + worklog.user.surnames}}</span>
                            <span class="activity-detail-title">
                              ha registrado {{ worklog.duration_in_seconds_real | secondsToStringTime}} {{ worklog.duration_in_seconds_real !== worklog.duration_in_seconds_for_client ? ('(de las cuales ' + (worklog.duration_in_seconds_for_client | secondsToStringTime) + ' productivas)') : '' }} de {{ worklog.working_time_category!=null ? worklog.working_time_category.name : 'Trabajo' }},
                            </span>
                            <span class="activity-detail-date">{{ worklog.updated_at | dateAgo }}</span>
                          </p>
                          <div class="body">
                            {{ worklog.description | stripHtmlPipe }}
                          </div>
                          @if (worklog.user_id === me.id) {
                            <footer>
                              <button (click)="openTimeTrackingDialog(worklog, undefined)">
                                <span>Editar</span>
                              </button>
                              ·
                              <button (click)="deleteWorkingTime(worklog)">
                                <span>Borrar</span>
                              </button>
                            </footer>
                          }
                        </div>
                      </div>
                    }
                  </section>
                }
                @if (activeActivityMenu === 'history' || activeActivityMenu === 'all') {
                  <section id="history">
                    @for (history_log of task.history_logs; track history_log) {
                      <div class="activity-entry">
                        <img class="profile-image" [src]="history_log.user.profile_picture_url" />
                        <div class="activity-detail">
                          <header>
                            <div class="activity-user-name">{{history_log.user.name + ' ' + history_log.user.surnames}}</div>
                            <div class="activity-detail-title">{{history_log.type | projectTaskHistoryLogFieldPipe}}</div>
                            <div class="activity-detail-date">{{ history_log.updated_at | dateAgo }}</div>
                          </header>
                          <div class="body">
                            @if (history_log.old_value) {
                              <div class="old-wrapper">
                                <div class="old_value value">
                                  {{ ((history_log.old_value.length>65)? (history_log.old_value | slice:0:65)+'..':(history_log.old_value)) | stripHtmlPipe }}
                                </div>
                                <div class="right_arrow"></div>
                              </div>
                            }
                            @if (history_log.new_value) {
                              <div class="new_value value">
                                {{ ((history_log.new_value.length>65)? (history_log.new_value | slice:0:65)+'..':(history_log.new_value)) | stripHtmlPipe }}
                              </div>
                            }
                          </div>
                        </div>
                      </div>
                    }
                  </section>
                }
                @if (activeActivityMenu === 'distribution') {
                  <section id="distribution">
                    @if(distribution_table!=null && distribution_table.boards!=null && distribution_table.boards.length) {
                      <div class="table">
                        <div class="row">
                          <div class="cell"></div>
                          @for(col of distribution_table.general.categories; track col;) {
                            <div class="cell">{{ col.title }}</div>
                          }
                        </div>
                        <!-- General -->
                        <ng-container *ngTemplateOutlet="distributionTableRowTemplate; context: {$implicit: distribution_table.general}"></ng-container>
                        <!-- Asignado -->
                        <ng-container *ngTemplateOutlet="distributionTableRowTemplate; context: {$implicit: distribution_table.assigned}"></ng-container>
                        <!-- Boards -->
                        @for(row of distribution_table.boards; track row;) {
                          <ng-container *ngTemplateOutlet="distributionTableRowTemplate; context: {$implicit: row}"></ng-container>
                        }

                        <ng-template #distributionTableRowTemplate let-row>
                          <div class="row">
                            <div class="cell" [ngClass]="{'bold': row.bold_title}" [matTooltip]="row.from != null ? ('El tablero empieza en ' + (row.from | date:'shortDate') + ' y finaliza en ' + (row.to | date:'shortDate') + '.') : 'No hay fecha de inicio en este tablero.'">{{ row.title }}</div>
                            @for(col of row.categories; track col;) {
                              <div class="cell">
                                <div class="spinner">
                                  <mat-progress-spinner [value]="getProgressSpinnerDistributionTableValue(col)" [diameter]="60" [ngClass]="getProgressSpinnerDistributionTableClassList(col)"></mat-progress-spinner>
                                  <span>
                                    {{ col.time_done/3600 | number:'1.0-0' }}/{{ col.time_to_do/3600 | number:'1.0-0' }}
                                  </span>
                                </div>
                              </div>
                            }
                          </div>
                        </ng-template>

                      </div>
                    }
                  </section>
                }
              </section>
            </div>
          </div>
        </section>
        <aside id="issue-sidebar" class="col-12 col-md-4">
          @if(task.is_draft) {
            <div class="row">
              <div class="col-12">
                <div id="draft-panel">
                  <p>Esta tarea está en <b>borrador</b>.</p>
                  @if(
                    isProjectManagerOrProductManager ||
                    (isClientDeveloper && ((currentBoard!=null && currentBoard.can_client_dev_manage_board) || (task.project_phase.can_client_dev_manage_backlog)))
                  ) {
                    <button mat-flat-button color="accent" (click)="publishTask()">Publicar tarea</button>
                  }
                  @else {
                    <p><i>Contacta con el Project Manager para aprobar la tarea.</i></p>
                  }
                </div>
              </div>
            </div>
          }
          <div class="row mb-0">
            @if (currentBoard!=null && project_task_board!=null) {
              <div class="col-sm-auto">
                <div id="column-selector">
                  <mat-select [ngModel]='project_task_board.project_phase_board_col_id' (ngModelChange)="changeColumn($event)" [disabled]="!(isProjectManagerOrProductManager || (isClientDeveloper && currentBoard.can_client_dev_manage_board)) && project_task_board.project_phase_board_col.project_phase_board_col_role!=null && project_task_board.project_phase_board_col.project_phase_board_col_role.slug !== 'under-review' && project_task_board.project_phase_board_col.project_phase_board_col_role.slug !== 'deadlock' && !imAssigned()">
                    @for (col of currentBoard.project_phase_board_cols; track col) {
                      <mat-option [value]="col.id">{{ col.title }}</mat-option>
                    }
                  </mat-select>
                </div>
              </div>
            }
            <div class="col" id="little-selectors" [ngClass]="{'horizontal': currentBoard==null || project_task_board==null}">
              <mat-form-field class="task-item-selector nobottom">
                <mat-select [value]="task.type" #selectedType [disabled]="!(isProjectManagerOrProductManager || (isClientDeveloper && ((currentBoard != null && currentBoard.can_client_dev_manage_board) || (task.project_phase.can_client_dev_manage_backlog))))">
                  <mat-select-trigger class="selected-task-item">
                    <div class="{{ selectedType.value }}"></div>
                    <div class="task-item-name">{{selectedType.value | projectTaskTypeFieldPipe }}</div>
                  </mat-select-trigger>
                  @for (v of ['issue','story','task']; track v) {
                    <mat-option value="{{v}}" (click)="chooseTaskType(v)">
                      <div class="task-item-option">
                        <img class="task-item-icon" src="{{v | projectTaskTypeImage}}" />
                        <span>{{v | projectTaskTypeFieldPipe}}</span>
                      </div>
                    </mat-option>
                  }
                </mat-select>
              </mat-form-field>
              <mat-form-field class="task-item-selector nobottom">
                <mat-select [value]="task.priority" #selectedPriority [disabled]="!(isProjectManagerOrProductManager || (isClientDeveloper && ((currentBoard != null && currentBoard.can_client_dev_manage_board) || (task.project_phase.can_client_dev_manage_backlog))))">
                  <mat-select-trigger class="selected-task-item">
                    <div class="{{ selectedPriority.value }}"></div>
                    <div class="task-item-name">{{selectedPriority.value | projectTaskPriorityFieldPipe}}</div>
                  </mat-select-trigger>
                  <mat-option value="critical" (click)="chooseNewPriority('critical')">
                    <div class="task-item-option">
                      <img class="task-item-icon" src="assets/img/icons/priority/critical.png" />
                      <span>Crítica</span>
                    </div>
                  </mat-option>
                  <mat-option value="high" (click)="chooseNewPriority('high')">
                    <div class="task-item-option">
                      <img class="task-item-icon" src="assets/img/icons/priority/high.png" />
                      <span>Alta</span>
                    </div>
                  </mat-option>
                  <mat-option value="medium" (click)="chooseNewPriority('medium')">
                    <div class="task-item-option">
                      <img class="task-item-icon" src="assets/img/icons/priority/medium.png" />
                      <span>Media</span>
                    </div>
                  </mat-option>
                  <mat-option value="low" (click)="chooseNewPriority('low')">
                    <div class="task-item-option">
                      <img class="task-item-icon" src="assets/img/icons/priority/low.png" />
                      <span>Baja</span>
                    </div>
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <hr>
          <div class="row mb-0">
            <div class="col-4 d-flex justify-center align-center">
              <mat-checkbox [(ngModel)]="task.is_hotfix" (change)="updateTask()" [disabled]="!(isProjectManagerOrProductManager || (isClientDeveloper && ((currentBoard != null && currentBoard.can_client_dev_manage_board) || (task.project_phase.can_client_dev_manage_backlog))))">Hotfix</mat-checkbox>
            </div>
            <div class="col-4 d-flex justify-center align-center">
              <mat-checkbox [(ngModel)]="task.needs_documentation" (change)="updateTask()" [disabled]="!(isProjectManagerOrProductManager || (isClientDeveloper && ((currentBoard != null && currentBoard.can_client_dev_manage_board) || (task.project_phase.can_client_dev_manage_backlog))))">Doc.</mat-checkbox>
            </div>
            <div class="col-4 d-flex justify-center align-center">
              <mat-checkbox [(ngModel)]="task.needs_testing" (change)="updateTask()" [disabled]="!(isProjectManagerOrProductManager || (isClientDeveloper && ((currentBoard != null && currentBoard.can_client_dev_manage_board) || (task.project_phase.can_client_dev_manage_backlog))))">Testing</mat-checkbox>
            </div>
          </div>
          <hr>

          <div class="row mb-0">
            <div class="col">
              <div class="header">
                <h5 class="label">Trabajo a realizar</h5>
                @if(isProjectManagerOrProductManager) {
                  <button mat-icon-button [matTooltip]="'Configurar categorías de tarea y estimaciones de tiempo'" (click)="openBudgetDetailDialog()">
                    <mat-icon>settings</mat-icon>
                  </button>
                }
              </div>
              <ul id="budgets-list">
                @if(project_task_board != null && board_general_estimation_template_data != null && board_category_estimation_template_data != null) {
                  <!-- General -->
                  <ng-container *ngTemplateOutlet="categoryEstimationTemplate; context: {$implicit: board_general_estimation_template_data }"></ng-container>
                  <!-- Time Budgets -->
                  @for(time_budget of project_task_board.project_task_board_time_budgets; track time_budget; let i = $index) {
                    <ng-container *ngTemplateOutlet="categoryEstimationTemplate; context: {$implicit: board_category_estimation_template_data[i] }"></ng-container>
                  }
                }
                @else if(general_estimation_template_data!=null && task_category_estimation_template_data!=null) {
                  <!-- General -->
                  <ng-container *ngTemplateOutlet="categoryEstimationTemplate; context: {$implicit: general_estimation_template_data }"></ng-container>
                  <!-- Time Budgets -->
                  @for(time_budget of task.project_task_time_budgets; track time_budget; let i = $index) {
                    <ng-container *ngTemplateOutlet="categoryEstimationTemplate; context: {$implicit: task_category_estimation_template_data[i] }"></ng-container>
                  }
                }

                <ng-template #categoryEstimationTemplate let-data>
                  <li>
                    <div class="budget-list-header">
                      @if(data.project_task_board_time_budget != null) {
                        <button mat-icon-button class="small-icon-button" [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu" [ngStyle]="{'margin-right': '5px'}">
                          <mat-icon>more_vert</mat-icon>
                        </button>
                        <mat-menu #menu="matMenu">
                          @if(data.project_task_board_time_budget.assignee_id != null) {
                            <button mat-menu-item (click)="chooseNewAssignedUser(data.project_task_board_time_budget, null)" [disabled]="!(isProjectManagerOrProductManager || (isClientDeveloper && currentBoard != null && currentBoard.can_client_dev_manage_board)) && data.project_task_board_time_budget.assignee_id!=me.id">
                              <mat-icon>person_remove</mat-icon>
                              <span>Desasignar usuario</span>
                            </button>
                          }
                          @else if(data.can_register) {
                            <button mat-menu-item (click)="chooseNewAssignedUser(data.project_task_board_time_budget, me)">
                              <mat-icon>person_add</mat-icon>
                              <span>Me lo pido</span>
                            </button>
                          }

                          @if(project_task_board!.active_project_task_board_time_budget_id == null || data.project_task_board_time_budget.id != project_task_board!.active_project_task_board_time_budget_id) {
                            <button mat-menu-item (click)="markCategoryAsActive(data.idx)" [disabled]="!(isProjectManagerOrProductManager || (isClientDeveloper && currentBoard != null && currentBoard.can_client_dev_manage_board))">
                              <mat-icon>toggle_on</mat-icon>
                              <span>Marcar categoría como activa</span>
                            </button>
                          }
                          @else {
                            <button mat-menu-item (click)="markCategoryAsInactive(data.idx)" [disabled]="!(isProjectManagerOrProductManager || (isClientDeveloper && currentBoard != null && currentBoard.can_client_dev_manage_board))">
                              <mat-icon>toggle_off</mat-icon>
                              <span>Marcar categoría como inactiva</span>
                            </button>
                          }

                          @if(data.project_task_board_time_budget.completed_by_user_id == null) {
                            <button mat-menu-item (click)="markCategoryAsCompleted(data.project_task_board_time_budget)" [disabled]="!(isProjectManagerOrProductManager || (isClientDeveloper && currentBoard != null && currentBoard.can_client_dev_manage_board)) && data.project_task_board_time_budget.assignee_id!=me.id">
                              <mat-icon>check_circle</mat-icon>
                              <span>Marcar categoría como completada</span>
                            </button>
                          }
                          @else {
                            <button mat-menu-item (click)="markCategoryAsIncomplete(data.project_task_board_time_budget)" [disabled]="!(isProjectManagerOrProductManager || (isClientDeveloper && currentBoard != null && currentBoard.can_client_dev_manage_board)) && data.project_task_board_time_budget.assignee_id!=me.id">
                              <mat-icon>clear</mat-icon>
                              <span>Marcar categoría como no completada</span>
                            </button>
                          }

                          @if(data.show_delete_time_budget) {
                            <button mat-menu-item (click)="deleteTaskBudget(data.project_task_board_time_budget)">
                              <mat-icon>delete</mat-icon>
                              <span>Eliminar para este board</span>
                            </button>
                          }
                        </mat-menu>
                      }

                      <h6 class="label">{{ data.title }}</h6>

                      @if(data.project_task_board_time_budget != null) {
                        @if(project_task_board!.active_project_task_board_time_budget_id != null && data.project_task_board_time_budget.id == project_task_board!.active_project_task_board_time_budget_id) {
                          <span class="active">
                            <mat-icon>autorenew</mat-icon>
                            <span>Activo</span>
                          </span>
                        }
                        @if(data.project_task_board_time_budget.completed_by_user_id != null) {
                          <div class="ask-modifications">
                            <mat-icon [matTooltip]="'Esta categoría se ha completado en este tablero'">check_circle</mat-icon>
                          </div>
                        }
                      }
                    </div>
                    <div class="budget-list-body">
                      @if(data.show_assignee) {
                        <mat-form-field appearance="outline" class="nobottom noborder">
                          <mat-select [value]="data.assignee" (selectionChange)="chooseNewAssignedUser(data.project_task_board_time_budget, $event.value, $event)" [disabled]="!imDeveloping">
                            <mat-select-trigger>
                              @if(data.project_task_board_time_budget.assignee == null) {
                                ??
                              }
                              @else {
                                <app-profile-pic-with-role
                                  [matTooltip]="data.project_task_board_time_budget.assignee != null ? (data.project_task_board_time_budget.assignee.name + ' ' + data.project_task_board_time_budget.assignee.surnames) : 'Sin asignar'"
                                  [size]="70"
                                  [user]="data.project_task_board_time_budget.assignee"
                                ></app-profile-pic-with-role>
                              }
                            </mat-select-trigger>

                            @for (user of data.available_assignees; track user) {
                              <mat-option [value]="user">
                                <img class="profile-image" [src]="user.profile_picture_url" style="margin-right: 5px;" />
                                {{user.name + ' ' + user.surnames}}
                              </mat-option>
                            }
                          </mat-select>
                        </mat-form-field>
                      }
                      <div class="project-task-spinner">
                        @for(spinner of data.spinners; track spinner; let i = $index) {
                          <div class="spinner" [matTooltip]="spinner.tooltip" [ngClass]="{ 'can-register': spinner.can_register || spinner.can_set_quick_task_time != undefined }" >
                            <mat-progress-spinner [ngClass]="spinner.class" [value]="spinner.value" [diameter]="i == 0 ? 70 : 30" [strokeWidth]="i == 0 ? 8 : 4"></mat-progress-spinner>
                            <div class="text">
                              <span>
                                {{ spinner.text }}
                              </span>
                              @if(spinner.can_register) {
                                <button mat-icon-button (click)="openTimeTrackingDialog(null, data.project_task_board_time_budget)">
                                  <mat-icon>add</mat-icon>
                                </button>
                              }
                              @else if(spinner.can_set_quick_task_time != undefined) {
                                <button mat-icon-button (click)="setQuickTaskTime(spinner.can_set_quick_task_time)">
                                  <mat-icon>timer</mat-icon>
                                </button>
                              }
                            </div>
                          </div>
                        }
                      </div>
                    </div>
                  </li>
                </ng-template>
              </ul>
            </div>
          </div>
          <hr>

          <div class="row mb-0" id="tag-wrapper">
            <div class="col">
              <mat-form-field appearance="outline" class="nobottom noborder">
                <mat-chip-grid #tagsGrid>
                  @for (tag of tags; track $index) {
                    <mat-chip-row (removed)="removeTag(tag)">
                      {{ (tag.length>22) ? (tag | slice:0:22)+'...':(tag) }}
                      <button matChipRemove [attr.aria-label]="'remove ' + tag" [disabled]="!imDeveloping">
                        <mat-icon>cancel</mat-icon>
                      </button>
                    </mat-chip-row>
                  }
                </mat-chip-grid>
                <input
                  placeholder="Nuevo tag..."
                  #tagInput
                  [formControl]="tagsCntrl"
                  [matChipInputFor]="tagsGrid"
                  [matAutocomplete]="autoTag"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  (matChipInputTokenEnd)="addTag($event)"
                  [disabled]="!imDeveloping"
                />
                <mat-autocomplete #autoTag="matAutocomplete" (optionSelected)="selectedTag($event)">
                  @for (tag of filteredTags | async; track tag) {
                    <mat-option [value]="tag">{{tag}}</mat-option>
                  }
                </mat-autocomplete>
              </mat-form-field>
            </div>
          </div>
          <hr>
          <div class="row mb-0" id="listeners-wrapper">
            <div class="col-md-6">
              <h5 class="label">Siguiendo</h5>
              <div class="d-flex list-profile-pics">
                @for (r of task.project_task_listeners; track r) {
                  <app-profile-pic-with-role [matTooltip]="r.name + ' ' + r.surnames" [user]="r" [size]="30" (userClicked)="imDeveloping ? removeListener(r) : null"></app-profile-pic-with-role>
                }
                @if (!imFollowingTask) {
                  <button mat-icon-button class="small-icon-button" matTooltip="Seguir tarea y recibir notificaciones" (click)="addListener(me)">
                    <mat-icon>add</mat-icon>
                  </button>
                }
              </div>
              @if (isProjectManagerOrProductManager || (isClientDeveloper && currentBoard != null && currentBoard.can_client_dev_manage_board)) {
                <mat-form-field appearance="outline" class="nobottom noborder">
                  <input
                    matInput
                    placeholder="Escribe..."
                    #userInputListeners
                    [formControl]="listenersCntrl"
                    [matAutocomplete]="autoListeners"
                  >
                    <mat-autocomplete #autoListeners="matAutocomplete">
                      @for (w of filteredUsersListeners | async; track w) {
                        <mat-option [value]="w" (onSelectionChange)="addListener(w)">
                          {{ w.name + ' ' + w.surnames }}
                        </mat-option>
                      }
                    </mat-autocomplete>
                </mat-form-field>
              }
            </div>
          </div>
          <hr>
          <small>Creada el {{task.created_at | date:'long'}}</small>
          <small>Actualizada el {{task.updated_at | date:'long'}}</small>
        </aside>
      </section>
    </ngx-dropzone>
  </mat-dialog-content>
}
@if (!isTaskLoaded) {
  <mat-dialog-content>
    <img style="width: 100%;" src="assets/img/loadingTask.png" />
  </mat-dialog-content>
}
