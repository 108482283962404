import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Department } from '../models/department.model';
import { PermissionsService } from '../services/permissions.service';
import { UtilsService } from '../services/utils.service';

@Injectable()
export class DepartmentsInitiatedGuard  {
  constructor(private permissions:PermissionsService,
              private utils:UtilsService) { }

  async canActivate(route:ActivatedRouteSnapshot, state:RouterStateSnapshot) {
    let current_departments = this.permissions.getCurrentDepartments();
    if(current_departments!=null && Object.keys(current_departments).length>0) return true;

    const dptResponse = await this.permissions.fetchingDepartmentsPromise;
    const departments:Department[] = dptResponse.data

    if(departments.length > 0) {
      return await this.isMyDepartmentInitiated();
    }
    else return false;
  }

  private async isMyDepartmentInitiated(it:number = 0):Promise<boolean> {
    if(it > 5) {
      return false;
    }
    else {
      const myDepartment = this.permissions.isMyDepartmentSet();
      if(myDepartment!=null) {
        return true;
      }
      else {
        await this.utils.sleep(100);
        return await this.isMyDepartmentInitiated(it++);
      }
    }
  }
}
