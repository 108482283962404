import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'secondsToStringTime'
})
export class SecondsToStringTimePipe implements PipeTransform {

  transform(value:number):string {
    if(value>0) {
      const hours = Math.floor(value / 3600);
      const seconds_rest = value % 3600;
      const minutes = Math.floor(seconds_rest / 60);

      if(hours==0 && minutes==0) return '-';
      else if(hours==0 && minutes!=0) return minutes + 'm';
      else if(hours!=0 && minutes==0) return hours + 'h';
      else return hours + "h " + minutes + "m";
    }
    return '0h';
  }

}
