import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Credential } from 'src/app/models/credential.model';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-credential-detail-dialog',
  templateUrl: './credential-detail-dialog.component.html',
  styleUrls: ['./credential-detail-dialog.component.css']
})
export class CredentialDetailDialogComponent implements OnInit {

  formGroup:UntypedFormGroup = null as any;

  constructor(private fb:UntypedFormBuilder,
              public dialogRef: MatDialogRef<CredentialDetailDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data:any,
              private api:ApiService) { }

  ngOnInit(): void {
    let company_id:number = this.data!=null ? this.data.company_id : null;
    let domain_id:number = this.data!=null ? this.data.domain_id : null;
    let hosting_id:number = this.data!=null ? this.data.hosting_id : null;
    let project_id:number = this.data!=null ? this.data.project_id : null;
    let business_mail_id:number = this.data!=null ? this.data.business_mail_id : null;
    let host:number = this.data!=null ? this.data.host : null;

    this.formGroup = this.fb.group({
      id: [''],
      name: ['', Validators.required],
      company_id: [company_id],
      domain_id: [domain_id],
      hosting_id: [hosting_id],
      project_id: [project_id],
      type: ['', Validators.required],
      username: [''],
      password: [''],
      host: [host],
      port: [''],
      ssh_public_key: [''],
      ssh_private_key: [''],
      protocol: ['', Validators.required],
      business_mail_id: [business_mail_id]
    });

    if(this.data != null && this.data.credential != null) {
      this.formGroup.patchValue(this.data.credential);
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  save() {
    if(this.formGroup.value['id']==null || this.formGroup.value['id']=="") {
      // create 
      this.api.createCredential(this.formGroup.value as Credential).subscribe(
        data => {
          this.dialogRef.close(data);
        }
      );
    }
    else {
      // update
      this.api.updateCredential(this.formGroup.value['id'], this.formGroup.value as Credential).subscribe(
        data => {
          this.dialogRef.close(data);
        }
      );
    }
  }

}
