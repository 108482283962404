import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AddNewWorkingTimeDialogComponent } from 'src/app/components/main/employees/my-zone/working-times-history/add-new-working-time-dialog/add-new-working-time-dialog.component';
import { Environment } from 'src/app/models/environment.model';
import { ProjectPhase } from 'src/app/models/project_phase.model';
import { ProjectPhaseBoardCol } from 'src/app/models/project_phase_board_col.model';
import { ProjectPhaseBoardColRole } from 'src/app/models/project_phase_board_col_role.model';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-project-phase-board-col-role-dialog',
  templateUrl: './project-phase-board-col-role-dialog.component.html',
  styleUrls: ['./project-phase-board-col-role-dialog.component.css']
})
export class ProjectPhaseBoardColRoleDialogComponent implements OnInit {
  form: UntypedFormGroup;
  projectPhaseBoardCol:ProjectPhaseBoardCol = null as any;
  description: string = '';
  projectPhaseBoardColRoles: {[key:number]:ProjectPhaseBoardColRole} = {};
  environments:Environment[] = null as any;

  private project_phase_id: number;
  project_phase:ProjectPhase = null as any;

  constructor(
    public dialogRef: MatDialogRef<AddNewWorkingTimeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: UntypedFormBuilder,
    private api: ApiService
  ) {
    this.projectPhaseBoardCol = data.projectPhaseBoardCol;
    this.project_phase_id = data.phase_id;

    this.form = this.fb.group({
      id: [data.projectPhaseBoardCol.id],
      project_phase_board_col_role_id: [ data.projectPhaseBoardCol.project_phase_board_col_role_id ],
      environment_id: [data.projectPhaseBoardCol.environment_id]
    });
  }

  ngOnInit(): void {
    this.listenToForm();
    this.listenToRoleChanges();
    this.fetchProjectPhase();
    this.fetchColumnRoles();
    this.fetchEnvironments();
  }

  private listenToForm() {
    this.form.valueChanges.subscribe(
      data => {
        this.updateFormValidation(data);
      }
    );
  }

  private listenToRoleChanges() {
    this.form.controls['project_phase_board_col_role_id'].valueChanges.subscribe((val) => {
      this.description = this.projectPhaseBoardColRoles[val].description;
    });
  }

  private fetchColumnRoles() {
    this.api.getProjectPhaseBoardColRoles().subscribe((res) => {
      this.projectPhaseBoardColRoles = {};
      res.forEach(role => {
        this.projectPhaseBoardColRoles[role.id] = role;
      });

      // force form value change to update description
      this.form.patchValue({
        project_phase_board_col_role_id: this.projectPhaseBoardCol.project_phase_board_col_role_id
      });
    });
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onSubmit() {
    this.api.updateBoardColumn(this.form.value).subscribe((res) => {
      this.dialogRef.close(res);
    });
  }

  private fetchEnvironments() {
    this.api.getEnvironments().subscribe(
      data => {
        this.environments = data;
      }
    );
  }

  private fetchProjectPhase() {
    this.api.getProjectPhase(this.project_phase_id).subscribe(
      data => {
        this.project_phase = data;
        if(this.requiresEnvironment(this.form.value)) {
          this.form.controls['environment_id'].addValidators(Validators.required);
        }
      }
    );
  }

  private updateFormValidation(formValue:any) {
    if(this.requiresEnvironment(formValue) && !this.form.controls['environment_id'].hasValidator(Validators.required)) {
      this.form.controls['environment_id'].addValidators(Validators.required);
    }
    else if(!this.requiresEnvironment(formValue) && this.form.controls['environment_id'].hasValidator(Validators.required)) {
      this.form.controls['environment_id'].removeValidators(Validators.required);
    }
  }

  private requiresEnvironment(formValue:any) {
    return  this.project_phase.project_deploy_weekly_notification_configuration_id != null &&
            formValue['project_phase_board_col_role_id'] != null &&
            Object.keys(this.projectPhaseBoardColRoles).length > 0 &&
            ['done', 'test'].indexOf(this.projectPhaseBoardColRoles[formValue['project_phase_board_col_role_id']].slug)!==-1;
  }


}
