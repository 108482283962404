import { Component, Input, OnInit } from '@angular/core';
import { BusinessMail } from 'src/app/models/business_mail.model';
import { ApiService } from 'src/app/services/api.service';
import { PermissionsService } from 'src/app/services/permissions.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-business-mail-detail-resume',
  templateUrl: './business-mail-detail-resume.component.html',
  styleUrls: ['./business-mail-detail-resume.component.css']
})
export class BusinessMailDetailResumeComponent implements OnInit {

  private _business_mail:BusinessMail = null as any;
  get business_mail() { return this._business_mail; };
  @Input() set business_mail(bm:BusinessMail) {
    if(bm!=null) {
      this._business_mail = bm;
    }
  }
  
  constructor(private api:ApiService,
              private utils:UtilsService,
              public permissions:PermissionsService) { }

  ngOnInit(): void {
  }

  cancelBusinessMail() {
    if(confirm("¿Estás seguro que quieres cancelar este dominio?")) {
      this.api.cancelBusinessMail(this.business_mail.id).subscribe(
        data => {
          this.business_mail.ended_by = data.ended_by;
          this.business_mail.ended_by_id = data.ended_by_id;
          this.business_mail.expiration_date = data.expiration_date;
          this.business_mail.status = data.status;
          this.business_mail.trial_ends_at = data.trial_ends_at;
        }
      );
    }
  }

  getBusinessMailStatusTooltip(status:string) {
    return this.utils.getStatusTooltip(status);
  }

  openInOvh() {
    window.open(`https://www.ovh.com/manager/web/#/exchange/${this.business_mail.ovh_id}/${this.business_mail.ovh_id}`, '_blank');
  }

}
