import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { debounceTime } from 'rxjs/operators';
import { User } from 'src/app/models/user.model';
import { WorkingContractType } from 'src/app/models/working_contract_type.model';
import { ApiService } from 'src/app/services/api.service';
import { PermissionsService } from 'src/app/services/permissions.service';

@Component({
  selector: 'app-working-contract-detail',
  templateUrl: './working-contract-detail.component.html',
  styleUrls: ['./working-contract-detail.component.css']
})
export class WorkingContractDetailComponent implements OnInit {

  contractForm:UntypedFormGroup;

  user: User = null as any;

  userContractSelectControl = new UntypedFormControl();
  userSelected: User = null as any;
  available_users: User[] = [];
  workingContractsTypes:WorkingContractType[] = [];
  titleContract = 'Nuevo contrato';

  constructor(
              private fb:UntypedFormBuilder,
              private api:ApiService,
              private activatedRoute:ActivatedRoute,
              public router:Router,
              public permissions:PermissionsService) {
    this.contractForm = this.fb.group({
      'user_id': ['', Validators.required],
      'hours_per_week_expected': ['', Validators.required],
      'price_per_hour': ['', Validators.min(0)],
      'number_of_payments_per_year': ['', Validators.required],
      'brute_anual_salary': ['', Validators.min(0)],
      'working_contract_type_id': ['', Validators.required],
      'start_date': ['', Validators.required],
      'end_date': [''],
      'is_signed_by_bisual': [false],
      'is_signed_by_worker': [false],
      'job_description': ['', Validators.required],
      'monday_morning_entry_time': [0, Validators.compose([Validators.min(0), Validators.max(23.5)])],
      'monday_morning_exit_time': [0, Validators.compose([Validators.min(0), Validators.max(23.5)])],
      'monday_afternoon_entry_time': [0, Validators.compose([Validators.min(0), Validators.max(23.5)])],
      'monday_afternoon_exit_time': [0, Validators.compose([Validators.min(0), Validators.max(23.5)])],
      'tuesday_morning_entry_time': [0, Validators.compose([Validators.min(0), Validators.max(23.5)])],
      'tuesday_morning_exit_time': [0, Validators.compose([Validators.min(0), Validators.max(23.5)])],
      'tuesday_afternoon_entry_time': [0, Validators.compose([Validators.min(0), Validators.max(23.5)])],
      'tuesday_afternoon_exit_time': [0, Validators.compose([Validators.min(0), Validators.max(23.5)])],
      'wednesday_morning_entry_time': [0, Validators.compose([Validators.min(0), Validators.max(23.5)])],
      'wednesday_morning_exit_time': [0, Validators.compose([Validators.min(0), Validators.max(23.5)])],
      'wednesday_afternoon_entry_time': [0, Validators.compose([Validators.min(0), Validators.max(23.5)])],
      'wednesday_afternoon_exit_time': [0, Validators.compose([Validators.min(0), Validators.max(23.5)])],
      'thursday_morning_entry_time': [0, Validators.compose([Validators.min(0), Validators.max(23.5)])],
      'thursday_morning_exit_time': [0, Validators.compose([Validators.min(0), Validators.max(23.5)])],
      'thursday_afternoon_entry_time': [0, Validators.compose([Validators.min(0), Validators.max(23.5)])],
      'thursday_afternoon_exit_time': [0, Validators.compose([Validators.min(0), Validators.max(23.5)])],
      'friday_morning_entry_time': [0, Validators.compose([Validators.min(0), Validators.max(23.5)])],
      'friday_morning_exit_time': [0, Validators.compose([Validators.min(0), Validators.max(23.5)])],
      'friday_afternoon_entry_time': [0, Validators.compose([Validators.min(0), Validators.max(23.5)])],
      'friday_afternoon_exit_time': [0, Validators.compose([Validators.min(0), Validators.max(23.5)])],
      'saturday_morning_entry_time': [0, Validators.compose([Validators.min(0), Validators.max(23.5)])],
      'saturday_morning_exit_time': [0, Validators.compose([Validators.min(0), Validators.max(23.5)])],
      'saturday_afternoon_entry_time': [0, Validators.compose([Validators.min(0), Validators.max(23.5)])],
      'saturday_afternoon_exit_time': [0, Validators.compose([Validators.min(0), Validators.max(23.5)])],
      'sunday_morning_entry_time': [0, Validators.compose([Validators.min(0), Validators.max(23.5)])],
      'sunday_morning_exit_time': [0, Validators.compose([Validators.min(0), Validators.max(23.5)])],
      'sunday_afternoon_entry_time': [0, Validators.compose([Validators.min(0), Validators.max(23.5)])],
      'sunday_afternoon_exit_time': [0, Validators.compose([Validators.min(0), Validators.max(23.5)])],
    })
  }

  ngOnInit(): void {
    this.listenControlSearch();
    this.fetchWorkingContractsTypes();
    this.fetchGetWorkingContract();
  }

  private fetchGetWorkingContract() {
    if(this.activatedRoute.snapshot.paramMap.get('id') !=null) {
      this.api.getWorkingContract(Number(this.activatedRoute.snapshot.paramMap.get('id')), { with: 'current_working_contract_variable_condition' }).subscribe(
        data => {
          this.contractForm.patchValue({
            'user_id': data.user.id,
            'hours_per_week_expected': data.current_working_contract_variable_condition.hours_per_week_expected,
            'price_per_hour': data.current_working_contract_variable_condition.price_per_hour,
            'number_of_payments_per_year': data.number_of_payments_per_year,
            'brute_anual_salary': data.current_working_contract_variable_condition.brute_anual_salary,
            'working_contract_type_id': data.working_contract_type_id,
            'start_date': data.start_date,
            'end_date': data.end_date,
            'is_signed_by_bisual': data.is_signed_by_bisual,
            'is_signed_by_worker': data.is_signed_by_worker,
            'job_description': data.job_description,
            'monday_morning_entry_time': data.current_working_contract_variable_condition.monday_morning_entry_time,
            'monday_morning_exit_time': data.current_working_contract_variable_condition.monday_morning_exit_time,
            'monday_afternoon_entry_time': data.current_working_contract_variable_condition.monday_afternoon_entry_time,
            'monday_afternoon_exit_time': data.current_working_contract_variable_condition.monday_afternoon_exit_time,
            'tuesday_morning_entry_time': data.current_working_contract_variable_condition.tuesday_morning_entry_time,
            'tuesday_morning_exit_time': data.current_working_contract_variable_condition.tuesday_morning_exit_time,
            'tuesday_afternoon_entry_time': data.current_working_contract_variable_condition.tuesday_afternoon_entry_time,
            'tuesday_afternoon_exit_time': data.current_working_contract_variable_condition.tuesday_afternoon_exit_time,
            'wednesday_morning_entry_time': data.current_working_contract_variable_condition.wednesday_morning_entry_time,
            'wednesday_morning_exit_time': data.current_working_contract_variable_condition.wednesday_morning_exit_time,
            'wednesday_afternoon_entry_time': data.current_working_contract_variable_condition.wednesday_afternoon_entry_time,
            'wednesday_afternoon_exit_time': data.current_working_contract_variable_condition.wednesday_afternoon_exit_time,
            'thursday_morning_entry_time': data.current_working_contract_variable_condition.thursday_morning_entry_time,
            'thursday_morning_exit_time': data.current_working_contract_variable_condition.thursday_morning_exit_time,
            'thursday_afternoon_entry_time': data.current_working_contract_variable_condition.thursday_afternoon_entry_time,
            'thursday_afternoon_exit_time': data.current_working_contract_variable_condition.thursday_afternoon_exit_time,
            'friday_morning_entry_time': data.current_working_contract_variable_condition.friday_morning_entry_time,
            'friday_morning_exit_time': data.current_working_contract_variable_condition.friday_morning_exit_time,
            'friday_afternoon_entry_time': data.current_working_contract_variable_condition.friday_afternoon_entry_time,
            'friday_afternoon_exit_time': data.current_working_contract_variable_condition.friday_afternoon_exit_time,
            'saturday_morning_entry_time': data.current_working_contract_variable_condition.saturday_morning_entry_time,
            'saturday_morning_exit_time': data.current_working_contract_variable_condition.saturday_morning_exit_time,
            'saturday_afternoon_entry_time': data.current_working_contract_variable_condition.saturday_afternoon_entry_time,
            'saturday_afternoon_exit_time': data.current_working_contract_variable_condition.saturday_afternoon_exit_time,
            'sunday_morning_entry_time': data.current_working_contract_variable_condition.sunday_morning_entry_time,
            'sunday_morning_exit_time': data.current_working_contract_variable_condition.sunday_morning_exit_time,
            'sunday_afternoon_entry_time': data.current_working_contract_variable_condition.sunday_afternoon_entry_time,
            'sunday_afternoon_exit_time': data.current_working_contract_variable_condition.sunday_afternoon_exit_time,
          });
          this.userContractSelectControl.patchValue(data.user);

          this.userContractSelectControl.disable();
          this.contractForm.disable();

          if(this.permissions.validateDepartment(['direction', 'rrhh'])) {
            this.contractForm.controls['end_date'].enable();
            this.contractForm.controls['price_per_hour'].enable();
            this.contractForm.controls['brute_anual_salary'].enable();
            this.contractForm.controls['monday_morning_entry_time'].enable();
            this.contractForm.controls['monday_morning_exit_time'].enable();
            this.contractForm.controls['monday_afternoon_entry_time'].enable();
            this.contractForm.controls['monday_afternoon_exit_time'].enable();
            this.contractForm.controls['tuesday_morning_entry_time'].enable();
            this.contractForm.controls['tuesday_morning_exit_time'].enable();
            this.contractForm.controls['tuesday_afternoon_entry_time'].enable();
            this.contractForm.controls['tuesday_afternoon_exit_time'].enable();
            this.contractForm.controls['wednesday_morning_entry_time'].enable();
            this.contractForm.controls['wednesday_morning_exit_time'].enable();
            this.contractForm.controls['wednesday_afternoon_entry_time'].enable();
            this.contractForm.controls['wednesday_afternoon_exit_time'].enable();
            this.contractForm.controls['thursday_morning_entry_time'].enable();
            this.contractForm.controls['thursday_morning_exit_time'].enable();
            this.contractForm.controls['thursday_afternoon_entry_time'].enable();
            this.contractForm.controls['thursday_afternoon_exit_time'].enable();
            this.contractForm.controls['friday_morning_entry_time'].enable();
            this.contractForm.controls['friday_morning_exit_time'].enable();
            this.contractForm.controls['friday_afternoon_entry_time'].enable();
            this.contractForm.controls['friday_afternoon_exit_time'].enable();
            this.contractForm.controls['saturday_morning_entry_time'].enable();
            this.contractForm.controls['saturday_morning_exit_time'].enable();
            this.contractForm.controls['saturday_afternoon_entry_time'].enable();
            this.contractForm.controls['saturday_afternoon_exit_time'].enable();
            this.contractForm.controls['sunday_morning_entry_time'].enable();
            this.contractForm.controls['sunday_morning_exit_time'].enable();
            this.contractForm.controls['sunday_afternoon_entry_time'].enable();
            this.contractForm.controls['sunday_afternoon_exit_time'].enable();
          }

          this.titleContract = `Contrato ${data.user.name}`;

        }
      )
    }
  }

  private fetchWorkingContractsTypes() {
    this.api.getWorkingContractsTypes().subscribe(
      data => {
        this.workingContractsTypes = data;
      }
    )
  }

  save() {
    if(this.activatedRoute.snapshot.paramMap.get('id') !=null) {
      if(confirm("¿Estás seguro que quieres modificar este contrato?")) {
        this.api.updateWorkingContract(this.activatedRoute.snapshot.paramMap.get('id') as string, this.contractForm.value).subscribe(
          data => {
          }
        );
      }
    } else {
      this.api.createWorkingContract(this.contractForm.value).subscribe(
        data => {

        }
      );
    }
  }

  selectCustomer(u:User) {
    this.contractForm.patchValue({user_id: u.id});
  }

  resetCustomer() {
    this.available_users = [];
    this.contractForm.patchValue({user_id: null});
  }

  displayFnUser(user:User): string {
    return user ? user.name : '';
  }

  private listenControlSearch() {
    this.userContractSelectControl.valueChanges.pipe(debounceTime(500)).subscribe(
      (search_string:string) => {
        if(search_string!=null) {
          const params = {
            search: search_string,
            'role-slug': 'worker'
          };
          this.fetchgetUsersAutoComplete(params);
        }
        else this.available_users = [];
      }
    )
  }

  private fetchgetUsersAutoComplete(params: object) {
    this.api.getUsers(params).subscribe(
      data => {
        this.available_users = data.data;
      }
    );
  }


}
