import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ClockRegisterMood } from 'src/app/models/clock-register-mood.model';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-force-clock-register-dialog',
  templateUrl: './force-clock-register-dialog.component.html',
  styleUrls: ['./force-clock-register-dialog.component.css']
})
export class ForceClockRegisterDialogComponent implements OnInit {

  status:any = null;
  justification:string = "";
  // generate a random number 1-100
  random:number = 0;
  get needs_mood(): boolean {
    return this.status.needs_mood;
  }
  clock_register_mood_id:number = null as any;
  moods:ClockRegisterMood[] = [];

  constructor(public dialogRef: MatDialogRef<ForceClockRegisterDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data:any,
              private api:ApiService) {

                this.status = data.status;
                this.random = Math.floor(Math.random() * 100) + 1;
              }

  ngOnInit() {
    if(this.needs_mood) this.fetchMoods();
  }

  click() {
    const data = {
      force: true,
      justification: this.justification,
      clock_register_mood_id: this.clock_register_mood_id
    };

    return this.dialogRef.close(data);
  }

  private fetchMoods() {
    this.api.getClockRegisterMoods().subscribe(
      data => {
        const keys = {
          green: 0,
          yellow: 1,
          red: 2
        };

        type ObjectKey = keyof typeof keys;

        data.forEach(mood => {
          const key = mood.mood_type as ObjectKey;
          if(this.random == 4){
            mood.emoji = "🤡";
          }
          this.moods.push(mood);
        });

        // randomize moods
        this.moods.sort(() => Math.random() - 0.5);
      }
    );
  }

}

interface ClockRegisterMoodStruct {
  [key:string]:ClockRegisterMood[];
}
