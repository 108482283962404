import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BusinessMail } from 'src/app/models/business_mail.model';
import { Company } from 'src/app/models/company.model';
import { Hosting } from 'src/app/models/hosting.model';
import { Project } from 'src/app/models/project.model';
import { Domain } from 'src/app/models/domain.model';
import { ProjectPhaseStage } from 'src/app/models/project_phase_stage.model';
import { ProjectService } from 'src/app/models/project_service.model';
import { Subscription } from 'src/app/models/subscription.model';
import { ApiService } from 'src/app/services/api.service';
import { UtilsService } from 'src/app/services/utils.service';
import { PaymentType } from 'src/app/models/payment_type.model';
import { Order } from 'src/app/models/order.model';

@Component({
  selector: 'app-add-new-order-dialog',
  templateUrl: './add-new-order-dialog.component.html',
  styleUrls: ['./add-new-order-dialog.component.css']
})
export class AddNewOrderDialogComponent implements OnInit {

  formGroup:UntypedFormGroup;

  project_phase_stages:ProjectPhaseStage[] = [];
  payment_types:PaymentType[] = [];

  companies_filtered:Company[] = [];
  companies_property_control:UntypedFormControl = new UntypedFormControl('', Validators.compose([Validators.minLength(1)]));
  projects_filtered:Project[] = [];
  projects_property_control:UntypedFormControl = new UntypedFormControl('', Validators.compose([Validators.minLength(1)]));
  services_filtered:ProjectService[] = [];
  services_property_control:UntypedFormControl = new UntypedFormControl('', Validators.compose([Validators.minLength(1)]));
  hostings_filtered:Hosting[] = [];
  hostings_property_control:UntypedFormControl = new UntypedFormControl('', Validators.compose([Validators.minLength(1)]));
  domains_filtered:Domain[] = [];
  domains_property_control:UntypedFormControl = new UntypedFormControl('', Validators.compose([Validators.minLength(1)]));
  business_mails_filtered:BusinessMail[] = [];
  business_mails_property_control:UntypedFormControl = new UntypedFormControl('', Validators.compose([Validators.minLength(1)]));
  subscriptions_filtered:Subscription[] = [];
  subscriptions_property_control:UntypedFormControl = new UntypedFormControl('', Validators.compose([Validators.minLength(1)]));

  iva_types:number[] = [0,4,7,8,10,16,18,21];

  get lines() {
    return this.formGroup.get('lines') as UntypedFormArray;
  }

  constructor(public dialogRef:MatDialogRef<AddNewOrderDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data:any,
              private fb:UntypedFormBuilder,
              private api:ApiService,
              private utils:UtilsService) {

    this.formGroup = this.fb.group({
      invoice_number: ['', Validators.required],
      invoice_date: [new Date(), Validators.required],
      company_id: ['', Validators.required],
      due_date: [''],
      total: [0, Validators.compose([Validators.required, Validators.min(0)])],
      subtotal: [0],
      tax_prcnt: [21, Validators.compose([Validators.required, Validators.min(0)])],
      tax_amount: [0],
      payed: [true, Validators.required],
      send_email: [false, Validators.required],
      lines: this.fb.array([]),
      payment_type_id: ['']
    });

    // afegim la primera linia
    const first_line_control = this.createLineFormControl();
    first_line_control.controls['tax_prcnt'].disable();
    this.formGroup.controls['invoice_number'].disable();
    (this.formGroup.controls['lines'] as UntypedFormArray).push(first_line_control);
  }

  ngOnInit(): void {
    this.listenForm();
    this.listenAutocompleteControls();
    this.fetchNextInvoiceNumber();
    this.fetchProjectPhaseStages();
    this.fetchPaymentTypes();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  save() {
    if(confirm("¿Estás seguro que quieres crear este pedido?")) {
      let body:any = this.utils.cloneObj(this.formGroup.value);
      delete body.tax_amount;
      delete body.subtotal;

      body.lines.forEach((line:any) => {
        delete line.subtotal;
        delete line.tax_amount;
        delete line.project_id;
        delete line.type;
        delete line.service_search;
        delete line.project_search;
        delete line.hosting_search;
        delete line.domain_search;
        delete line.business_mail_search;
        delete line.subscription_search;
        delete line.project_phases;
      });

      this.api.createOrder(body).subscribe(
        data => {
          this.dialogRef.close(data as Order);
        }
      );
    }
  }

  selectCompanyFilter(company:Company) {
    this.formGroup.patchValue({ company_id: company.id });
  }

  selectProjectFilter(i:number, project:Project) {
    this.lines.controls[i].patchValue({
      project_id: project.id,
      project_phases: project.project_phases
    });
  }

  selectServiceFilter(i:number, service:ProjectService) {
    this.lines.controls[i].patchValue({ project_service_id: service.id });
  }

  selectHostingFilter(i:number, hosting:Hosting) {
    this.lines.controls[i].patchValue({ hosting_id: hosting.id });
  }

  selectDomainFilter(i:number, domain:Domain) {
    this.lines.controls[i].patchValue({ domain_id: domain.id });
  }

  selectBusinessMailFilter(i:number, bm:BusinessMail) {
    this.lines.controls[i].patchValue({ business_mail_id: bm.id });
  }

  selectSubscriptionFilter(i:number, subscription:Subscription) {
    this.lines.controls[i].patchValue({ subscription_id: subscription.id });
  }

  getLineFormGroup(i:number):UntypedFormGroup {
    return this.lines.controls[i] as UntypedFormGroup;
  }

  addLine() {
    this.lines.push(this.createLineFormControl());
  }

  deleteLine(i:number) {
    this.lines.removeAt(i);
  }

  private listenAutocompleteControls() {
    // companies autocomplete
    this.companies_property_control.valueChanges.subscribe(
      (data:string) => {
        if(this.companies_property_control.valid && data.length > 0) {
          let params:any = {};
          params['search'] = data;
          this.api.getCompanies(params).subscribe(
            companies => {
              this.companies_filtered = companies.data;
            }
          );
        }
        else {
          this.formGroup.patchValue({ company_id: '' });
          this.companies_filtered = [];
        }
      }
    );
  }

  private fetchNextInvoiceNumber() {
    this.api.nextInvoiceNumber().subscribe(
      data => {
        this.formGroup.patchValue({ invoice_number: data.invoice_number });
      }
    );
  }

  private listenForm() {

    // propagacio de tax_prcnt a les linies
    this.formGroup.controls['tax_prcnt'].valueChanges.subscribe(
      data => {
        this.recalculateOrderTotal();
      }
    );
  }

  private createLineFormControl() {
    const group = this.fb.group({
      notes: ['', Validators.compose([Validators.required, Validators.minLength(5)])],
      subtotal: [0, Validators.min(0)],
      tax_prcnt: [this.formGroup!=null ? this.formGroup.value['tax_prcnt'] : 21, Validators.required],
      tax_amount: [0],
      total: [0, Validators.compose([Validators.required, Validators.min(0)])],
      project_service_id: [''],
      project_id: [''],
      project_phase_stage_id: [''],
      project_phase_id: [''],
      hosting_id: [''],
      domain_id: [''],
      business_mail_id: [''],
      subscription_id: [''],
      type: [''],
      service_search: [''],
      project_search: [''],
      hosting_search: [''],
      domain_search: [''],
      business_mail_search: [''],
      subscription_search: [''],
      project_phases: ['']
    });

    /**
     * SET UP FORM CONTROL LISTENERS
     */
    group.controls['subtotal'].valueChanges.subscribe(
      data => {
        if(!isNaN(+data!)) {
          this.utils.sleep(50).then(() => {
            let prcnt = this.formGroup.value['tax_prcnt'];
            group.controls['total'].patchValue(+((((prcnt/100)+1) * (+data!)).toFixed(2)), { emitEvent: false });
            this.utils.sleep(50).then(() => {
              this.recalculateOrderTotal();
            });
          });
        }
      }
    );

    group.controls['total'].valueChanges.subscribe(
      data => {
        if(!isNaN(+data!)) {
          this.utils.sleep(50).then(() => {
            let prcnt = this.formGroup.value['tax_prcnt'];
            group.controls['subtotal'].patchValue(+(((+data!) / ((prcnt/100)+1)).toFixed(2)), { emitEvent: false });
            this.utils.sleep(50).then(() => {
              this.recalculateOrderTotal();
            });
          });
        }
      }
    );

    group.controls['type'].valueChanges.subscribe(
      data => {
        if(data==null || data=='') {
          group.patchValue({
            project_service_id: '',
            project_phase_stage_id: '',
            project_phase_id: '',
            hosting_id: '',
            domain_id: '',
            business_mail_id: '',
            subscription_id: '',
          });
        }
      }
    );

    group.controls['project_search'].valueChanges.subscribe(
      data => {
        if(data!.length > 2) {
          let params:any = {};
          params['company_id'] = this.formGroup.value['company_id'];
          params['search'] = data;
          this.api.getProjects(params).subscribe(
            projects => {
              this.projects_filtered = projects.data;
            }
          );
        }
        else {
          group.controls['project_id'].setValue('');
          this.projects_filtered = [];
        }
      }
    );

    group.controls['service_search'].valueChanges.subscribe(
      data => {
        if(data!.length > 2) {
          let params:any = {};
          params['company_id'] = this.formGroup.value['company_id'];
          params['description'] = data;
          this.api.getProjectServices(params).subscribe(
            services => {
              this.services_filtered = services;
            }
          );
        }
        else {
          group.controls['project_service_id'].setValue('');
          this.services_filtered = [];
        }
      }
    );

    group.controls['hosting_search'].valueChanges.subscribe(
      data => {
        if(data!.length > 2) {
          let params:any = {};
          params['company_id'] = this.formGroup.value['company_id'];
          params['hosting_fqdn'] = data;
          this.api.getHostings(params).subscribe(
            hostings => {
              this.hostings_filtered = hostings;
            }
          );
        }
        else {
          group.controls['hosting_id'].setValue('');
          this.hostings_filtered = [];
        }
      }
    );

    group.controls['domain_search'].valueChanges.subscribe(
      data => {
        if(data!.length > 2) {
          let params:any = {};
          params['company_id'] = this.formGroup.value['company_id'];
          params['domain'] = data;
          this.api.getDomains(params).subscribe(
            domains => {
              this.domains_filtered = domains;
            }
          );
        }
        else {
          group.controls['domain_id'].setValue('');
          this.domains_filtered = [];
        }
      }
    );

    group.controls['business_mail_search'].valueChanges.subscribe(
      data => {
        if(data!.length > 2) {
          let params:any = {};
          params['company_id'] = this.formGroup.value['company_id'];
          params['email'] = data;
          this.api.getBusinessMails(params).subscribe(
            bms => {
              this.business_mails_filtered = bms;
            }
          );
        }
        else {
          group.controls['business_mail_id'].setValue('');
          this.business_mails_filtered = [];
        }
      }
    );

    group.controls['subscription_search'].valueChanges.subscribe(
      data => {
        if(data!.length > 2) {
          let params:any = {};
          params['company_id'] = this.formGroup.value['company_id'];
          params['name'] = data;
          this.api.getSubscriptions(params).subscribe(
            subscriptions => {
              this.subscriptions_filtered = subscriptions;
            }
          );
        }
        else {
          group.controls['subscription_id'].setValue('');
          this.subscriptions_filtered = [];
        }
      }
    );

    /**
     * DISABLE FORM CONTROLS
     */
    group.controls['tax_prcnt'].disable();
    group.controls['tax_amount'].disable();

    /**
     * RETURN GROUP
     */
    return group;
  }

  private recalculateOrderTotal() {
    let subtotal = 0;
    let tax_amount = 0;
    let total = 0;
    let prcnt = this.formGroup.value['tax_prcnt'];

    for(let line of this.lines.controls) {

      const sub = +line.value['subtotal'];
      const tax_am = (sub * prcnt / 100);
      const tot = (sub + tax_am);

      line.patchValue({
        subtotal: +sub.toFixed(2),
        tax_prcnt: prcnt,
        tax_amount: +tax_am.toFixed(2),
        total: +tot.toFixed(2)
      }, { emitEvent: false });

      subtotal += sub;
      tax_amount += tax_am;
      total += tot;
    }

    this.formGroup.patchValue({
      total: total,
      subtotal: subtotal,
      tax_amount: tax_amount
    });
  }

  private fetchProjectPhaseStages() {
    this.api.getProjectPhaseStages().subscribe(
      data => {
        this.project_phase_stages = data;
      }
    );
  }

  private fetchPaymentTypes() {
    this.api.getPaymentTypes().subscribe(
      data => {
        this.payment_types = data;
      }
    );
  }

}
