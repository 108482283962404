import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-calendar-event-detail-dialog',
  templateUrl: './calendar-event-detail-dialog.component.html',
  styleUrls: ['./calendar-event-detail-dialog.component.css']
})
export class CalendarEventDetailDialogComponent implements OnInit {
  event:any;
  form:UntypedFormGroup;

  constructor(
    private dialogRef: MatDialogRef<CalendarEventDetailDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any,
    fb:UntypedFormBuilder
  ) {
    this.event = data.event;
    const datePipe = new DatePipe('es-ES');
    this.form = fb.group({
      start: [datePipe.transform(this.event.start!.toISOString(), 'yyyy-MM-ddTHH:mm:ss')!.slice(0, 16)],
      end: [datePipe.transform( this.event.end!=null ? this.event.end!.toISOString() : this.event.start!.toISOString(), 'yyyy-MM-ddTHH:mm:ss')!.slice(0, 16)]
    });
  }

  ngOnInit(): void {

  }

  close() {
    this.dialogRef.close();
  }

  addMinutes(minutes:number) {
    //end date is start date + minutes
    const start = new Date(this.form.value.start);
    const end = new Date(start.getTime() + minutes * 60000);
    const datePipe = new DatePipe('es-ES');
    this.form.patchValue({end: datePipe.transform(end!.toISOString(), 'yyyy-MM-ddTHH:mm:ss')!.slice(0, 16)});
  }

  save() {
    this.dialogRef.close({
      start: new Date(this.form.value.start),
      end: new Date(this.form.value.end)
    });
  }
}
