import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.css']
})
export class ServicesComponent implements OnInit {

  navLinks: any[] = [
      {
          label: 'Hostings',
          link: './hostings',
          index: 0
      }, 
      {
          label: 'Dominios',
          link: './domains',
          index: 1
      }, 
      {
          label: 'Correos corporativos',
          link: './business-mails',
          index: 2
      }, 
  ];

  activeLinkIndex = 0; 

  constructor(private router:Router) { }

  ngOnInit(): void {
    this.router.events.subscribe((res) => {
      this.activeLinkIndex = this.navLinks.indexOf(this.navLinks.find(tab => tab.link === '.' + this.router.url));
    });
  }

}
