import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-move-wiki-item-dialog',
  templateUrl: './move-wiki-item-dialog.component.html',
  styleUrls: ['./move-wiki-item-dialog.component.css']
})
export class MoveWikiItemDialogComponent implements OnInit {
  wikiTree: any;
  treeControl = new NestedTreeControl<any>(node => node.children);
  dataSource = new MatTreeNestedDataSource<any>();
  selectedNode: any;

  constructor(
    public dialogRef: MatDialogRef<MoveWikiItemDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private api: ApiService
  ) {}

  ngOnInit(): void {
    this.fetchWikiTree();
  }

  private fetchWikiTree() {
    this.api.getWikiTree().subscribe(data => {
      this.wikiTree = data;
      this.dataSource.data = this.wikiTree;
    })
  }

  selectItem(item: any) {
    this.selectedNode = item;
  }

  hasChild = (_: number, node: any) => !!node.children && node.children.length > 0;

  isLeaf = (_: number, node: any) => !node.children || node.children.length === 0;

  close() {
    this.dialogRef.close();
  }

  move() {
    this.dialogRef.close(this.selectedNode);
  }

}
