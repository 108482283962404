import { Component, Input, OnInit } from '@angular/core';
import { Domain } from 'src/app/models/domain.model';
import { ApiService } from 'src/app/services/api.service';
import { PermissionsService } from 'src/app/services/permissions.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-domain-detail-resume',
  templateUrl: './domain-detail-resume.component.html',
  styleUrls: ['./domain-detail-resume.component.css']
})
export class DomainDetailResumeComponent implements OnInit {

  private _domain:Domain = null as any;
  get domain() { return this._domain; };
  @Input() set domain(d:Domain) {
    if(d!=null) {
      this._domain = d;
    }
  }

  constructor(private api:ApiService,
              private utils:UtilsService,
              public permissions:PermissionsService) { }

  ngOnInit(): void {
  }

  cancelDomain(domain:Domain) {
    if(confirm("¿Estás seguro que quieres cancelar este dominio?")) {
      this.api.cancelDomain(domain.id).subscribe(
        data => {
          this.domain.ended_by = data.ended_by;
          this.domain.ended_by_id = data.ended_by_id;
          this.domain.expiration_date = data.expiration_date;
          this.domain.status = data.status;
          this.domain.trial_ends_at = data.trial_ends_at;
        }
      );
    }
  }

  getDomainStatusTooltip(status:string) {
    return this.utils.getStatusTooltip(status);
  }

  openInCloudflare() {
    window.open('https://dash.cloudflare.com/d1057003eb5bf66a26b8b1924a44cbc9/bisual.net' + this.domain.domain, '_blank');
  }

  openInGodaddy() {
    window.open(`https://dcc.godaddy.com/control/${this.domain.domain}/settings`, '_blank');
  }


}
