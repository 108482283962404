<div id="content">

    <div class="page-title">
        <h1>Título de página</h1>
        <button mat-flat-button color="accent">
            <mat-icon style="margin-right: 10px">visibility</mat-icon>
            Botón header
        </button>
    </div>

    <div class="row">
        <div class="col-12 col-md-4">
            <div class="card col">
                <div class="card-header">
                    <div class="card-title">
                        <h2>Card title</h2>
                        <p class="subtitle">Card subtitle</p>
                    </div>
                </div>
                Card content
            </div>
        </div>
        <div class="col-12 col-md-4">
            <div class="card col">
                <div class="card-header">
                    <div class="card-title">
                        <h2>Card title</h2>
                        <p class="subtitle">Card subtitle</p>
                    </div>
                </div>
                card content
            </div>
        </div>
        <div class="col-12 col-md-4">
            <div class="card col">
                <div class="card-header">
                    <div class="card-title">
                        <h2>Card title</h2>
                        <p class="subtitle">Card subtitle</p>
                    </div>
                </div>
                card content
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-12 col-md-4">
            <div class="card col">
                <button mat-button>Botón normal</button>
                <button mat-button color="accent">Botón accent</button>
                <button mat-button color="warn">Botón warn</button>
                <button mat-button color="black">Botón black</button>
                <button mat-button color="success">Botón success</button>
                <button mat-button color="grey">Botón grey</button>
            </div>
        </div>
        <div class="col-12 col-md-4">
            <div class="card col">
                <button mat-stroked-button>Botón normal</button>
                <button mat-stroked-button color="accent">Botón accent</button>
                <button mat-stroked-button color="warn">Botón warn</button>
                <button mat-stroked-button color="black">Botón black</button>
                <button mat-stroked-button color="success">Botón success</button>
                <button mat-stroked-button color="grey">Botón grey</button>
            </div>
        </div>
        <div class="col-12 col-md-4">
            <div class="card col">
                <button mat-flat-button>Botón normal</button>
                <button mat-flat-button color="accent">Botón accent</button>
                <button mat-flat-button color="warn">Botón warn</button>
                <button mat-flat-button color="black">Botón black</button>
                <button mat-flat-button color="success">Botón success</button>
                <button mat-flat-button color="grey">Botón grey</button>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-12">
            <div class="card col">
                <div class="card-header">
                    <div class="card-title">
                        <h2>Simple table example</h2>
                        <p class="subtitle">This is a subtitle</p>
                    </div>
                </div>

                <div class="bisual-table">
                    <div class="bisual-table-row-header">
                        <div class="bisual-table-col">Header</div>
                        <div class="bisual-table-col">Header</div>
                        <div class="bisual-table-col">Header</div>
                        <div class="bisual-table-col">Header</div>
                    </div>
                    <div class="bisual-table-row">
                        <div class="bisual-table-col">Contenido de col</div>
                        <div class="bisual-table-col">Contenido de col</div>
                        <div class="bisual-table-col">Contenido de col</div>
                        <div class="bisual-table-col">Contenido de col</div>
                    </div>
                    <div class="bisual-table-row">
                        <div class="bisual-table-col">Contenido de col</div>
                        <div class="bisual-table-col">Contenido de col</div>
                        <div class="bisual-table-col">Contenido de col</div>
                        <div class="bisual-table-col">Contenido de col</div>
                    </div>
                    <div class="bisual-table-row">
                        <div class="bisual-table-col">Contenido de col</div>
                        <div class="bisual-table-col">Contenido de col</div>
                        <div class="bisual-table-col">Contenido de col</div>
                        <div class="bisual-table-col">Contenido de col</div>
                    </div>
                    <div class="bisual-table-row">
                        <div class="bisual-table-col">Contenido de col</div>
                        <div class="bisual-table-col">Contenido de col</div>
                        <div class="bisual-table-col">Contenido de col</div>
                        <div class="bisual-table-col">Contenido de col</div>
                    </div>
                </div>

            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-12 col-md-6">
            <div class="card col">
                <div class="card-header">
                    <div class="card-title">
                        <h2>Card title</h2>
                        <p class="subtitle">Card subtitle</p>
                    </div>
                    <button mat-stroked-button>Ir a soporte</button>
                </div>
            </div>
        </div>
        <div class="col-12 col-md-6">
            <div class="card col">
                <div class="card-header">
                    <div class="card-title">
                        <h2>Card title</h2>
                        <p class="subtitle">Card subtitle</p>
                    </div>
                    <button mat-stroked-button>Ir a soporte</button>
                </div>
            </div>
        </div>
    </div>

    <h2>Pagination table example</h2>
    <div class="bisual-table">
        <div class="card paginator">
            <mat-paginator [length]="100" [pageSize]="10" [pageSizeOptions]="[5,10,20]"></mat-paginator>
            <mat-form-field style="margin-left: auto;">
                <input matInput type="text">
                <button matSuffix mat-icon-button aria-label="Clear">
                    <mat-icon>search</mat-icon>
                </button>
            </mat-form-field>
        </div>
        <div class="bisual-table-container">
            <table mat-table [dataSource]="dataSource">

                <!-- Position Column -->
                <ng-container matColumnDef="position">
                <th mat-header-cell *matHeaderCellDef> No. </th>
                <td mat-cell *matCellDef="let element"> {{element.position}} </td>
                </ng-container>

                <!-- Name Column -->
                <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef> Name </th>
                <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                </ng-container>

                <!-- Weight Column -->
                <ng-container matColumnDef="weight">
                <th mat-header-cell *matHeaderCellDef> Weight </th>
                <td mat-cell *matCellDef="let element"> {{element.weight}} </td>
                </ng-container>

                <!-- Symbol Column -->
                <ng-container matColumnDef="symbol">
                <th mat-header-cell *matHeaderCellDef> Symbol </th>
                <td mat-cell *matCellDef="let element"> {{element.symbol}} </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </div>
    </div>

    <h2>Accordion and chips example</h2>
    <div class="example-action-buttons">
        <button mat-button (click)="accordion.openAll()">Expand All</button>
        <button mat-button (click)="accordion.closeAll()">Collapse All</button>
      </div>
      <mat-accordion class="example-headers-align" multi #accordion="matAccordion">
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Personal data
                </mat-panel-title>
                <mat-panel-description>
                    <mat-chip-option>Normal chip</mat-chip-option>
                </mat-panel-description>
            </mat-expansion-panel-header>

            <mat-form-field>
                <mat-label>First name</mat-label>
                <input matInput>
            </mat-form-field>

            <mat-form-field>
                <mat-label>Age</mat-label>
                <input matInput type="number" min="1">
            </mat-form-field>

        </mat-expansion-panel>
        <mat-expansion-panel disabled>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Destination
                </mat-panel-title>
                <mat-panel-description>
                    <mat-chip-option color="primary" selected>Primary chip</mat-chip-option>
                </mat-panel-description>
            </mat-expansion-panel-header>

            <mat-form-field>
                <mat-label>Country</mat-label>
                <input matInput>
            </mat-form-field>
        </mat-expansion-panel>

        <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Day of the trip
                    </mat-panel-title>
                    <mat-panel-description>
                        <mat-chip-option color="accent" selected>Accent chip</mat-chip-option>
                    </mat-panel-description>
                </mat-expansion-panel-header>

            <mat-form-field>
                <mat-label>Date</mat-label>
                <input matInput [matDatepicker]="picker" (focus)="picker.open()" readonly>
            </mat-form-field>
            <mat-datepicker #picker></mat-datepicker>
        </mat-expansion-panel>

        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Accordion #4
                </mat-panel-title>
                <mat-panel-description>
                    <mat-chip-option color="success" selected>Success chip</mat-chip-option>
                </mat-panel-description>
            </mat-expansion-panel-header>

            <mat-form-field>
                <mat-label>Country</mat-label>
                <input matInput>
            </mat-form-field>
        </mat-expansion-panel>

        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Accordion #5
                </mat-panel-title>
                <mat-panel-description>
                    <mat-chip-option color="warn" selected>Warn chip</mat-chip-option>
                </mat-panel-description>
            </mat-expansion-panel-header>

            <mat-form-field>
                <mat-label>Country</mat-label>
                <input matInput>
            </mat-form-field>
        </mat-expansion-panel>

        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Accordion #6
                </mat-panel-title>
                <mat-panel-description>
                    <mat-chip-option color="yellow" selected>Yellow chip</mat-chip-option>
                </mat-panel-description>
            </mat-expansion-panel-header>

            <mat-form-field>
                <mat-label>Country</mat-label>
                <input matInput>
            </mat-form-field>
        </mat-expansion-panel>
    </mat-accordion>

    <h2>Masonry</h2>
    <div class="bisual-two-col-masonry">
        <div class="card">
            <p>asdf</p>
            <p>asdf</p>
            <p>asdf</p>
        </div>
        <div class="card">
            <p>asdf</p>
            <p>asdf</p>
            <p>asdf</p>
            <p>asdf</p>
            <p>asdf</p>
        </div>
        <div class="card">
            <p>asdf</p>
            <p>asdf</p>
            <p>asdf</p>
            <p>asdf</p>
            <p>asdf</p>
            <p>asdf</p>
        </div>
        <div class="card">
            <p>asdf</p>
            <p>asdf</p>
        </div>
        <div class="card">
            <p>asdf</p>
            <p>asdf</p>
            <p>asdf</p>
            <p>asdf</p>
            <p>asdf</p>
            <p>asdf</p>
            <p>asdf</p>
        </div>
        <div class="card">
            <p>asdf</p>
            <p>asdf</p>
            <p>asdf</p>
        </div>
    </div>

</div>
