<div id="content">
  <div class="page-title">
    <h1>Actas de reunión</h1>
    <button mat-flat-button color="accent" [routerLink]="['create']">
      <mat-icon>add_user</mat-icon>
      Crear acta de reunión
    </button>
  </div>

  <div class="bisual-table">
    <form class="card paginator" [formGroup]="filterForm">
      <mat-paginator
          [length]="length"
          [pageSize]="pageSize"
          [pageSizeOptions]="pageSizeOptions"
          [pageIndex]="pageIndex"
          (page)="changePage($event)"
          style="margin-right: auto;"
      ></mat-paginator>
      <mat-form-field>
        <input matInput type="text" formControlName="search">
        <button matSuffix mat-icon-button aria-label="Clear">
            <mat-icon>search</mat-icon>
        </button>
      </mat-form-field>
    </form>

    <div class="bisual-table-container">
      <table mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="title">
          <th mat-header-cell *matHeaderCellDef>Concepto</th>
          <td mat-cell *matCellDef="let meeting">
              <span>{{ meeting.title }}</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef>Fecha</th>
          <td mat-cell *matCellDef="let meeting">
              <span>{{ meeting.date | date:'short' }}</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="assistants_client">
          <th mat-header-cell *matHeaderCellDef>Asistentes cliente</th>
          <td mat-cell *matCellDef="let meeting">
              <span>{{ meeting.assistants_client }}</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="assistants_provider">
          <th mat-header-cell *matHeaderCellDef>Asistentes Bisual</th>
          <td mat-cell *matCellDef="let meeting">
              <span>{{ meeting.assistants_provider }}</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let meeting; let i = index;">
            <!-- <button mat-icon-button [matMenuTriggerFor]="menu">
                <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item [routerLink]="[meeting.id]">
                <mat-icon style="margin-right: 10px">edit</mat-icon>
                Editar
              </button>
            </mat-menu> -->
            <button mat-icon-button [routerLink]="[meeting.id]">
              <mat-icon>edit</mat-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
  </div>
</div>
