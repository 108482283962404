import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { ProjectPhaseBoard } from 'src/app/models/project_phase_board.model';
import { ApiService } from 'src/app/services/api.service';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { PermissionsService } from 'src/app/services/permissions.service';
import { EditProjectPhaseBoardDialogComponent } from './project-phase-boards/edit-project-phase-board-dialog/edit-project-phase-board-dialog.component';

@Component({
  selector: 'app-project-phase-detail',
  templateUrl: './project-phase-detail.component.html',
  styleUrls: ['./project-phase-detail.component.css']
})
export class ProjectPhasesDetailComponent implements OnInit {

  private project_id:number;
  private phase_id:number;
  project:any = null as any;
  board_id:number;
  boards:ProjectPhaseBoard[] = [];
  columnsTemplateImportTasks:string[] = ['title', 'tags', 'description', 'type', 'priority', 'time_estimation', 'design_time_estimation', 'needs_testing', 'needs_documentation'];
  columnsTemplateImportTasksDefinition:string[] = ['varchar(255) required', 'etiqueta1/etiqueta2/...', 'longtext', 'enum(\'issue\',\'task\',\'story\') default \'task\'', 'enum(\'low\',\'high\',\'critical\',\'medium\') default \'medium\'', 'time_estimation in ms or \'1h 1m\' format default null', 'design_time_estimation in ms or \'1h 1m\' format default null', 'boolean, use 1 or 0, default 0', 'boolean, use 1 or 0, default 0'];
  separatorCSV:string = ';';
  showColumn:boolean = true;
  isProductManager:boolean = false;

  selectedBoard:any;

  filterForm:UntypedFormGroup;
  private formPersistence:any;

  constructor(private fb:UntypedFormBuilder,
              private activatedRoute:ActivatedRoute,
              private api:ApiService,
              private router:Router,
              private permissions:PermissionsService,
              private dialog:MatDialog) {
    this.filterForm = this.fb.group({
      show_archived_tasks: [this.activatedRoute.snapshot.queryParamMap.get('show_archived_tasks')!=null ? this.activatedRoute.snapshot.queryParamMap.get('show_archived_tasks')=='true' : false],
      show_archived_boards: [this.activatedRoute.snapshot.queryParamMap.get('show_archived_boards')!=null ? this.activatedRoute.snapshot.queryParamMap.get('show_archived_boards')=='true' : false],
      mode: [this.activatedRoute.snapshot.queryParamMap.get('mode')!=null ? this.activatedRoute.snapshot.queryParamMap.get('mode') : 'all'],
    });
  }

  ngOnInit(): void {
    this.initFilterFormListener();
    this.initRouteParameters();
    this.fetchProject()
    this.fetchBoards();
    this.initIsProductManager();
  }

  private initRouteParameters() {
    this.project_id = +((this.activatedRoute.snapshot.paramMap.get('project_id') ?? this.activatedRoute.parent?.snapshot.paramMap.get('project_id')) as string);
    this.phase_id = this.activatedRoute.snapshot.paramMap.get('phase_id')!=null ? +(this.activatedRoute.snapshot.paramMap.get('phase_id') as string) : null as any;
    this.board_id = this.activatedRoute.snapshot.children[0].paramMap.get('board_id')!=null ? +(this.activatedRoute.snapshot.children[0].paramMap.get('board_id') as string) : null as any;
  }

  private initFilterFormListener() {
    this.filterForm.valueChanges.subscribe(
      data => {
        if(this.formPersistence==null || JSON.stringify(this.formPersistence)!=JSON.stringify(data)) {
          this.router.navigate([], { queryParams: data });
          this.fetchBoards();
        }
      }
    );
  }

  private fetchBoards() {
    let params = null;
    if(!this.filterForm.get('show_archived_boards')?.value) {
      params = { is_archived: false, with: 'default_followers,company_notification_channels', order_by: 'start_date', order_by_direction: 'desc' };
    }
    this.api.getPhaseBoards(this.phase_id, params).subscribe(
      boards => {
        this.boards = boards.sort((a,b) => {
          const valA = a.status === "active" ? 0 : (a.status === "not_started" ? 1 : 2);
          const valB = b.status === "active" ? 0 : (b.status === "not_started" ? 1 : 2);

          return valA - valB;
        });

        if(this.board_id!=null) {
          this.selectedBoard = this.boards.find(board => board.id==this.board_id);
        }
      }
    );
  }

  private fetchProject() {
    this.api.getProject(this.project_id.toString()).subscribe(
      project => {
        this.project = project;
      }
    );
  }

  selectBoard(board:any) {
    this.selectedBoard = board;
  }

  isEndingDateBiggerThanToday(date:Date) {
    const today = new Date();
    const endingDate = new Date(date);
    return endingDate > today;
  }

  getDiferenceInDays(endingDate:Date) {
    const days = Math.abs(Math.floor((+new Date() - +new Date(endingDate)) / (1000 * 3600 * 24)));
    return days;
  }

  downloadImportTasksCSV() {
    const columns = this.columnsTemplateImportTasks.join(this.separatorCSV);
    const definition = this.columnsTemplateImportTasksDefinition.join(this.separatorCSV);
    var link = document.createElement("a");
    link.href = encodeURI("data:text/csv;charset=utf-8," + columns + "\n" + definition);
    link.download = "template_import_tasks.csv";
    link.click();
  }

  openBoardSettings() {
    const dialogRef = this.dialog.open(EditProjectPhaseBoardDialogComponent, {
      width: '1000px',
      data: {
        'board' : this.selectedBoard,
        'project_id': this.project_id,
        'company_id': this.project.company_id
      }
    });
  }

  private initIsProductManager() {
    this.isProductManager = this.permissions.isWorkerWithProjectRole(this.project_id.toString(), 'product-manager');
  }

}
