<h1 mat-dialog-title>Añadir nuevo usuario</h1>
<mat-dialog-content [formGroup]="userForm">
  <div class="container">
    <div class="row">
      <div class="col-12 col-lg-4">
        <mat-form-field appearance="outline">
          <mat-label>Nombre</mat-label>
          <input type="text" matInput formControlName="name">
        </mat-form-field>
      </div>
      <div class="col-12 col-lg-4">
        <mat-form-field appearance="outline">
          <mat-label>Apellidos</mat-label>
          <input type="text" matInput formControlName="surnames">
        </mat-form-field>
      </div>
      <div class="col-12 col-lg-4">
        <mat-form-field appearance="outline">
          <mat-label>Correo electrónico</mat-label>
          <input type="email" matInput formControlName="email">
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-lg-4">
        <mat-form-field appearance="outline">
          <mat-label>Sexo</mat-label>
          <mat-select formControlName="sex">
            <mat-option value="M">Masculino</mat-option>
            <mat-option value="F">Femenino</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-12 col-lg-4">
        <mat-form-field appearance="outline">
          <mat-label>Fecha de nacimiento</mat-label>
          <input matInput [matDatepicker]="picker" formControlName="birthday">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="col-12 col-lg-4">
        <mat-form-field appearance="outline">
          <mat-label>Teléfono móvil</mat-label>
          <input type="tel" matInput placeholder="+34.666 555 444" formControlName="phone">
          <mat-icon matSuffix>phone</mat-icon>
          <mat-hint>Formato deseado: +xx.xxx xxx xxx</mat-hint>
        </mat-form-field>
      </div>
      <div class="col-12 col-lg-4 mt-2">
        <mat-checkbox formControlName="with_password">Con contraseña</mat-checkbox>
      </div>
    </div>

    @if (userForm.value.with_password) {
      <div>
        <h2 class="dialog-section">Contraseña</h2>
        <div class="row">
          <div class="col-12 col-lg-4">
            <mat-form-field appearance="outline">
              <mat-label>Contraseña</mat-label>
              <input type="password" matInput formControlName="password">
            </mat-form-field>
          </div>
          <div class="col-12 col-lg-4">
            <mat-form-field appearance="outline">
              <mat-label>Repite la contraseña</mat-label>
              <input type="password" matInput formControlName="password_confirmation">
            </mat-form-field>
          </div>
        </div>
      </div>
    }

    @if (company_id) {
      <p>Se está creando un usuario para la empresa #{{ company_id }}</p>
    }
    @if (!company_id) {
      <div id="rol-section">
        <h2 class="dialog-section">Rol y departamento</h2>
        <div class="row">
          <div class="col-12 col-lg-4">
            <mat-form-field appearance="outline">
              <mat-label>Rol</mat-label>
              <mat-select formControlName="role">
                @for (r of roles; track r) {
                  <mat-option [value]="r.slug">{{ r.name }}</mat-option>
                }
              </mat-select>
            </mat-form-field>
          </div>
          @if (userForm.value.role === 'client') {
            <div class="col-12 col-lg-4">
              <mat-form-field appearance="outline">
                <mat-label>Empresa</mat-label>
                <input type="text" matInput [formControl]="clientSelectControl" [matAutocomplete]="client_select_auto">
                <mat-autocomplete #client_select_auto="matAutocomplete" [displayWith]="displayFn">
                  @for (c of available_customers; track c) {
                    <mat-option [value]="c" (click)="selectCustomer(c)">{{ c.fact_name }}</mat-option>
                  }
                </mat-autocomplete>
                @if (customerSelected != null) {
                  <button matSuffix mat-icon-button aria-label="Clear" (click)="resetCustomer()">
                    <mat-icon>close</mat-icon>
                  </button>
                }
                @if (customerSelected == null) {
                  <mat-icon matSuffix>expand_more</mat-icon>
                }
              </mat-form-field>
            </div>
          }
          @if (userForm.value.role === 'client') {
            <div class="col-12 col-lg-4">
              <mat-form-field appearance="outline">
                <mat-label>Rol de cliente</mat-label>
                <mat-select formControlName="client_role">
                  <mat-option value="business">Business</mat-option>
                  <mat-option value="developer">Developer</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          }
        </div>
      </div>
    }
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button (click)="onNoClick()" cdkFocusInitial type="button">Cancelar</button>
  <button mat-flat-button color="accent" (click)="submit()" [disabled]="!userForm.valid" type="submit">
    <mat-icon style="margin-right: 10px">add_user</mat-icon>
    Crear usuario
  </button>
</mat-dialog-actions>
