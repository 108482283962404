import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Domain } from 'src/app/models/domain.model';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-domain-detail',
  templateUrl: './domain-detail.component.html',
  styleUrls: ['./domain-detail.component.css']
})
export class DomainDetailComponent implements OnInit {

  domain:Domain = null as any;
  domain_id:string = null as any;

  activeLinkIndex = 0; 
  navLinks: any[] = [];

  constructor(private router:Router,
              private api:ApiService,
              private activatedRoute:ActivatedRoute) { }

  ngOnInit(): void {
    this.domain_id = this.activatedRoute.snapshot.paramMap.get('id') as string;
    this.initNavLinks();
    this.listenRouterEvents();
    this.fetchDomain();
  }

  private listenRouterEvents() {
    this.router.events.subscribe((res) => {
      this.activeLinkIndex = this.navLinks.indexOf(this.navLinks.find(tab => tab.link.indexOf(this.router.url) != -1));
    });
  }

  private fetchDomain() {
    this.api.getDomainComplete(this.domain_id).subscribe(
      data => {
        this.domain = data;
      }
    );
  }

  private initNavLinks() {
    const snapshot = this.activatedRoute.snapshot;
    const base_route = '/' + snapshot.url[0].path + "/" + snapshot.url[1].path + "/";
    this.navLinks = [
        {
            label: 'Resumen',
            link: base_route + "resume",
            index: 0
        }, 
        {
            label: 'Facturación',
            link: base_route + "billing",
            index: 1
        }, 
        {
            label: 'Credenciales',
            link: base_route + "credentials",
            index: 2
        }, 
        {
            label: 'Editar',
            link: base_route + "edit",
            index: 3
        }, 
        {
            label: 'Registros DNS',
            link: base_route + "dns",
            index: 4
        } 
    ];
    this.activeLinkIndex = this.navLinks.indexOf(this.navLinks.find(tab => tab.link.indexOf(this.router.url) != -1));
  }

}
