import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Company } from 'src/app/models/company.model';
import { Provider } from 'src/app/models/provider.model';
import { ApiService } from 'src/app/services/api.service';
import { RegexService } from 'src/app/services/regex.service';

@Component({
  selector: 'app-add-domain-dialog',
  templateUrl: './add-domain-dialog.component.html',
  styleUrls: ['./add-domain-dialog.component.css']
})

export class AddDomainDialogComponent implements OnInit {

  domainForm:UntypedFormGroup;
  propietarioControl:UntypedFormControl = new UntypedFormControl('');

  providers:Provider[] = [];
  companies_filtered:Company[] = [];

  // godaddy
  agreement:any = null as any;
  availability:any = null as any;

  // loading stack
  loadingStack:any[] = [];

  constructor(private fb:UntypedFormBuilder,
              public dialogRef: MatDialogRef<AddDomainDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data:any,
              private api:ApiService,
              private regex:RegexService) {

    const regexPatterns = this.regex.getRegex();

    this.domainForm = this.fb.group({
      provider_id: ['', Validators.required],
      is_fact_external: [false],
      quantity: ['', Validators.required],
      domain: ['', Validators.compose([Validators.required, Validators.pattern(new RegExp(regexPatterns['domain']))])],
      consent_key: [''],
      company_id: [''],
      period_years: [1, Validators.compose([Validators.required, Validators.min(1), Validators.max(10)])],
      trial_enabled: [true],
      validGodaddy: [false, Validators.requiredTrue],
      godaddyTerms: [false]
    });

    this.providers = this.data.providers;

  }

  ngOnInit(): void {
    this.listenForm();
    this.listenAutocompletes();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  isGodaddyEnabled(data:any = null) {
    data = data!=null ? data : this.domainForm.value;
    if(data['provider_id']!='') {
      let p:Provider = this.getProvider(data['provider_id']);
      return p!=null && p.slug=="godaddy" && data['is_fact_external']===false && this.domainForm.controls['domain'].valid;
    }
    else return false;
  }

  selectPropietario(company:Company) {
    this.domainForm.patchValue({ company_id: company.id });
  }

  private listenForm() {
    this.domainForm.valueChanges.subscribe(
      async data => {
        if(this.isGodaddyEnabled()) {
          // es godaddy
          if(this.agreement==null) {
            // si no te els agreements, obtenlos
            this.loadingStack.push(true);
            const splitted = data['domain'].split('.');
            const tld = splitted[splitted.length-1];
            const agreement = await this.api.getGodaddyAgreements(tld).toPromise();
            this.agreement = agreement[0];
            this.domainForm.patchValue({consent_key: this.agreement.agreementKey}, {emitEvent: false});
            this.loadingStack.pop();
          }

          // obten la disponibilitat si el domini ha canviat
          if(this.availability==null || this.availability.domain!=data['domain']) {
            this.loadingStack.push(true);
            const availability = await this.api.checkGodaddyDomainAvailability(data['domain']).toPromise();
            this.availability = availability;
            this.loadingStack.pop();
          }

          // valorem si es valid tot - comprovem checks i altres camps
          const termsAccepted:boolean = this.domainForm.controls['godaddyTerms'].value;
          this.domainForm.patchValue({
            validGodaddy: termsAccepted && this.availability.available==true
          }, {emitEvent: false});
        }
        else {
          this.domainForm.patchValue({validGodaddy:true, consent_key:''}, {emitEvent:false});
          this.availability = null;
          this.agreement = null;
        }
      }
    );
  }

  private listenAutocompletes() {
    this.propietarioControl.valueChanges.subscribe(
      data => {
        if(this.propietarioControl.valid) {
          this.api.getCompanies({search: data}).subscribe(
            companies => {
              this.companies_filtered = companies.data;
            }
          );
        }
        else {
          this.companies_filtered = [];
          this.domainForm.patchValue({ company_id: '' }, { emitEvent: false });
        }
      }
    );
  }

  private getProvider(provider_id:number):Provider {
    let res:Provider = null as any;
    for(let p of this.providers) {
      if(p.id == provider_id) {
        res = p;
        break;
      }
    }
    return res;
  }

}
