import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Provider } from 'src/app/models/provider.model';
import { ApiService } from 'src/app/services/api.service';
import { PermissionsService } from 'src/app/services/permissions.service';
import { UtilsService } from 'src/app/services/utils.service';
import { AddProviderDialogComponent } from './add-provider-dialog/add-provider-dialog.component';

@Component({
  selector: 'app-providers',
  templateUrl: './providers.component.html',
  styleUrls: ['./providers.component.css']
})
export class ProvidersComponent implements OnInit {

  displayedColumns: string[] = ['name', 'country', 'contasimple', 'actions'];
  dataSource:MatTableDataSource<Provider> = new MatTableDataSource();

  // dades per el paginator
  length:number = 0;
  pageSize:number = 10;
  pageSizeOptions:number[] = [5,10,20,50];
  pageIndex:number = 1;

  filterForm:UntypedFormGroup;
  private formPersistence:any;

  constructor(private api:ApiService,
              private fb:UntypedFormBuilder,
              private router:Router,
              private activatedRoute:ActivatedRoute,
              private utils:UtilsService,
              public dialog: MatDialog,
              public permissions:PermissionsService) {

    this.filterForm = this.fb.group({
      search: [this.activatedRoute.snapshot.queryParamMap.get('search')!=null ? this.activatedRoute.snapshot.queryParamMap.get('search') : '', Validators.compose([Validators.minLength(3)])],
      per_page: [this.activatedRoute.snapshot.queryParamMap.get('per_page')!=null ? +(this.activatedRoute.snapshot.queryParamMap.get('per_page') as any) : this.pageSize, Validators.required],
      page: [this.activatedRoute.snapshot.queryParamMap.get('page')!=null ? +(this.activatedRoute.snapshot.queryParamMap.get('page') as any) : this.pageIndex, Validators.required],
      total: [this.activatedRoute.snapshot.queryParamMap.get('total')!=null ? +(this.activatedRoute.snapshot.queryParamMap.get('total') as any) : this.length],
    });

  }

  ngOnInit(): void {
    this.initFilterFormListener();
    this.listenQueryParameters();
  }

  changePage(event:PageEvent) {
    this.filterForm.patchValue({
      page: event.pageIndex,
      per_page: event.pageSize
    });
  }

  deleteProvider(index:number) {
    const provider = this.dataSource.data[index];
    if(confirm(`¿Estás seguro que quieres eliminar el proveedor "${provider.fact_name}"?`)) {
      this.api.deleteProvider(provider.id).subscribe(
        data => {
          this.fetchProviders();
        }
      );
    }
  }

  addProvider() {
    const dialogRef = this.dialog.open(AddProviderDialogComponent, {
      width: '750px',
    });

    dialogRef.afterClosed().subscribe((provider:Provider) => {
      if(provider != null) {
        this.api.createProvider(provider).subscribe(data => {
          this.fetchProviders();
        });
      }
    });
  }

  private fetchProviders() {
    this.api.getProvidersPaged(this.filterForm.value).subscribe(
      data => {
        this.dataSource.data = data.data;

        this.length = +data.meta.total;
        this.pageIndex = +data.meta.current_page-1;
        this.pageSize = +data.meta.per_page;
      }
    );
  }

  private initFilterFormListener() {
    this.filterForm.valueChanges.subscribe(
      data => {
        data.per_page = +data.per_page;
        if(this.formPersistence==null || JSON.stringify(this.formPersistence)!=JSON.stringify(data)) {
          if(this.formPersistence.per_page!=data.per_page) data.page = 0;
          this.router.navigate(['/employees', 'direction', 'users-management', 'providers'], { queryParams: data });
        }
      }
    );
  }

  private listenQueryParameters() {
    this.activatedRoute.queryParams.subscribe(
      params => {
        if(JSON.stringify(params)!==JSON.stringify(this.filterForm.value)) { //si no ve de filtre s'ha de setejar el form
          let params_temp = this.utils.cloneObj(params); //params es read_only
          Object.keys(params_temp).forEach(param_key => {
            if(params_temp[param_key]!=null && params_temp[param_key]!="" && !isNaN(+params_temp[param_key])) params_temp[param_key] = +params_temp[param_key]; // si es numero, el transformem
          });
          this.filterForm.patchValue(params_temp, { emitEvent: false });
        }
        this.formPersistence = params;
        this.fetchProviders();
      }
    );
  }

}
