import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-change-password-dialog',
  templateUrl: './change-password-dialog.component.html',
  styleUrls: ['./change-password-dialog.component.css']
})
export class ChangePasswordDialogComponent implements OnInit {

  passwordForm:UntypedFormGroup;

  constructor(private fb:UntypedFormBuilder,
              public dialogRef: MatDialogRef<ChangePasswordDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data:any) {
    this.passwordForm = this.fb.group({
      password: ['', Validators.compose([Validators.required, Validators.minLength(8)])],
      repeat: ['', Validators.compose([Validators.required])],
      valid: [false, Validators.requiredTrue]
    });
  }

  ngOnInit(): void {
    this.listenForm();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  private listenForm() {
    this.passwordForm.valueChanges.subscribe(
      data => {
        let valid:boolean = false;
        if(data['password'] == data['repeat']) valid = true;
        else valid = false;

        this.passwordForm.patchValue({ valid: valid }, { emitEvent: false});
      }
    );
  }

}
