<div id="content">
  <div class="page-title">
    <h1>Configuración</h1>

    <button mat-flat-button color="accent" (click)="save()" [disabled]="save_disabled">Guardar configuración</button>
  </div>

  <div class="card" *ngFor="let ssr of settings">
    <div class="card-header">
      <div class="card-title">
          <h2>{{ ssr.section | bisualSettingSectionKeyToTitle }}</h2>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-12 col-lg-6" *ngFor="let set of ssr.settings" [ngSwitch]="set.setting.type">
          <label [for]="set.setting.key">{{ set.setting.name }}</label>
          <p class="setting-description">{{ set.setting.description }}</p>
          <mat-form-field appearance="outline">
            <input matInput *ngSwitchCase="'email'" type="email" [ngModel]="set.setting.value" (ngModelChange)="onInputChange($event, set)" [name]="set.setting.key" [id]="set.setting.key" />
            <input matInput *ngSwitchCase="'number'" type="number" [ngModel]="set.setting.value" (ngModelChange)="onInputChange($event, set)" [name]="set.setting.key" [id]="set.setting.key" />
            <input matInput *ngSwitchDefault type="text" [ngModel]="set.setting.value" (ngModelChange)="onInputChange($event, set)" [name]="set.setting.key" [id]="set.setting.key" />

            <mat-hint align="end" *ngIf="set.setting.regex_pattern_id!=null">Regex: {{ set.setting.regex_pattern_id }}</mat-hint>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>

</div>
