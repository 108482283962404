<div id="content">
  <div class="page-title">
    <h1>Festividades</h1>
  </div>

  <div class="bisual-table">
    <form class="card paginator" [formGroup]="filterForm" style="padding-top: 20px !important">
      <mat-form-field appearance="outline">
        <mat-label>Año</mat-label>
        <mat-select formControlName="year">
          @for (year of years; track year) {
            <mat-option [value]="year">{{ year }}</mat-option>
          }
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>País</mat-label>
        <mat-select formControlName="country">
          @for (country of first_level_country_zones; track country) {
            <mat-option [value]="country.code">{{ country.name }}</mat-option>
          }
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="outline" [ngStyle]="{'width': '300px', 'max-width': 'unset'}">
        <mat-label>Zona a consultar</mat-label>
        <input type="text" matInput [formControl]="country_zone_search" [matAutocomplete]="auto">
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayCountryZone" (optionSelected)="onSelectCountryZone($event.option.value)">
          @for (cz of second_and_third_level_country_zones_filtered; track cz) {
            <mat-option [value]="cz">{{ cz.name }} (Level: {{ cz.level }})</mat-option>
          }
        </mat-autocomplete>
        @if (filterForm.value.country_zone_id != '') {
          <button mat-icon-button matSuffix (click)="clearCountryZone()">
            <mat-icon>clear</mat-icon>
          </button>
        }
      </mat-form-field>
    </form>

    @if (usersWithData.length) {
      <div class="card">
        <p style="margin: 0">
          @for (row of usersWithData; track row) {
            {{ row.user.name }} - {{ row.working_contract_year_condition!=null ? row.working_contract_year_condition.holidays_calendar_country_zone.name : '??' }},
          }
        </p>
      </div>
    }

    <div class="bisual-table-container">
      <table mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>Festividad</th>
          <td mat-cell *matCellDef="let h">
            {{ h.name }}
          </td>
        </ng-container>

        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef>Fecha</th>
          <td mat-cell *matCellDef="let h">
            {{ h.date | date:'shortDate' }}
          </td>
        </ng-container>

        <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef>Tipo</th>
          <td mat-cell *matCellDef="let h">
            {{ h.type }}
          </td>
        </ng-container>

        <ng-container matColumnDef="approved">
          <th mat-header-cell *matHeaderCellDef>¿Aprobado?</th>
          <td mat-cell *matCellDef="let h">
            <mat-checkbox [ngModel]="h.approved" (ngModelChange)="onToggleApproved(h, $event)"></mat-checkbox>
          </td>
        </ng-container>


        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
  </div>
</div>
