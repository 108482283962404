<div id="content">
  <div class="page-title">
    <h1>{{ isCreate ? 'Crear nueva aplicación' : 'Editar aplicación #' + app_id }}</h1>

    @if (!isCreate && isGithubRepo) {
      <button mat-flat-button (click)="renewTokenGithub()">Renovar token a Github</button>
    }
    @if (!isCreate && isGithubRepo && app?.github_token_set===false) {
      <button mat-flat-button (click)="checkGithub()">Comprobar conexión a Github</button>
    }
    <button mat-flat-button color="accent" [disabled]="!form.valid" (click)="submit()">
      <mat-icon style="margin-right: 10px">check</mat-icon>
      Guardar
    </button>
  </div>

  <div class="card" [formGroup]="form">
    <div class="card-header">
      <div class="card-title">
        <h2>Datos de la aplicación</h2>
      </div>
    </div>

    <div class="container">
      <div class="row">
        <div class="col-12 col-lg-6 col-xl-4">
          <mat-form-field appearance="outline">
            <mat-label>Nombre</mat-label>
            <input matInput formControlName="name" />
          </mat-form-field>
        </div>

        <div class="col-12 col-lg-6 col-xl-8">
          <mat-form-field appearance="outline">
            <mat-label for="git_url">URL del repositorio</mat-label>
            <input matInput formControlName="git_url" />
          </mat-form-field>
        </div>

        <div class="col-12">
          <mat-form-field appearance="outline">
            <mat-label>Tipos de aplicación</mat-label>
            <mat-chip-grid #chipList>
              @for (type of form.value['application_types']; track type; let i = $index) {
                <mat-chip-row
                  (removed)="removeAppType(i)">
                  {{type.name}}
                  <button matChipRemove>
                    <mat-icon>cancel</mat-icon>
                  </button>
                </mat-chip-row>
              }
              <input
                placeholder="Escribe un nuevo tipo..."
                #typeInput
                [formControl]="appTypeAutocompleteCtrl"
                [matAutocomplete]="autoAppType"
                [matChipInputFor]="chipList">
            </mat-chip-grid>
            <mat-autocomplete #autoAppType="matAutocomplete" (optionSelected)="addAppType($event)">
              @for (type of filtered_app_types | async; track type) {
                <mat-option [value]="type">
                  {{ type.name }}
                </mat-option>
              }
            </mat-autocomplete>
          </mat-form-field>
        </div>

        <div class="col-12">
          <mat-form-field appearance="outline">
            <mat-label>Tecnologías</mat-label>
            <mat-chip-grid #chipListProgrammingTag>
              @for (tag of form.value['programming_tags']; track tag; let i = $index) {
                <mat-chip-row
                  (removed)="removeProgrammingTag(i)">
                  {{tag.name}}
                  <button matChipRemove>
                    <mat-icon>cancel</mat-icon>
                  </button>
                </mat-chip-row>
              }
              <input
                placeholder="Escribe un nuevo tipo..."
                #typeInput
                [formControl]="appProgrammingTagAutocompleteCtrl"
                [matAutocomplete]="autoProgrammingTag"
                [matChipInputFor]="chipListProgrammingTag">
            </mat-chip-grid>
            <mat-autocomplete #autoProgrammingTag="matAutocomplete" (optionSelected)="addProgrammingTag($event)">
              @for (tag of filtered_programming_tags | async; track tag) {
                <mat-option [value]="tag">
                  {{ tag.name }}
                </mat-option>
              }
            </mat-autocomplete>
          </mat-form-field>
        </div>

      </div>
    </div>
  </div>

  @if (!isCreate && environments != null) {
    <div class="card">
      <div class="card-header">
        <div class="card-title">
          <h2>Entornos de aplicación</h2>
          <p class="subtitle">Configura los entornos de tu aplicación</p>
        </div>
        <button mat-stroked-button color="accent" (click)="addEnv()">
          <mat-icon matPrefix>add</mat-icon>
          Crear entorno
        </button>
      </div>
      <div class="container">
        <div class="row">
          <div class="col">
            <div class="bisual-table">
              <div class="bisual-table-row-header">
                <div class="bisual-table-col">Entorno</div>
                <div class="bisual-table-col">URL</div>
                <div class="bisual-table-col">Git Ref</div>
                <div class="bisual-table-col"></div>
              </div>
              @for (record of environments; track record; let i = $index) {
                <div class="bisual-table-row">
                  <div class="bisual-table-col">
                    @if (!record.editing) {
                      {{ record.role }}
                    }
                    @if (record.editing) {
                      <mat-form-field appearance="outline">
                        <mat-select [(ngModel)]="record.role">
                          <mat-option value="production">Producción</mat-option>
                          <mat-option value="staging">Staging</mat-option>
                          <mat-option value="development">Development</mat-option>
                          <mat-option value="other">Otro</mat-option>
                        </mat-select>
                      </mat-form-field>
                    }
                  </div>
                  <div class="bisual-table-col">
                    @if (!record.editing) {
                      <a [href]="record.url" target="_blank">{{ record.url }}</a>
                    }
                    @if (record.editing) {
                      <mat-form-field appearance="outline">
                        <input matInput [(ngModel)]="record.url" />
                      </mat-form-field>
                    }
                  </div>
                  <div class="bisual-table-col">
                    @if (!record.editing) {
                      {{ record.git_ref }}
                    }
                    @if (record.editing) {
                      <mat-form-field appearance="outline">
                        <input matInput [(ngModel)]="record.git_ref" />
                      </mat-form-field>
                    }
                  </div>
                  <div class="bisual-table-col">
                    @if (record.editing) {
                      <button mat-icon-button color="success" (click)="saveEnv(i)" [disabled]="!validEnv(record)"><mat-icon>check_circle</mat-icon></button>
                    }
                    @if (!record.editing) {
                      <button mat-icon-button (click)="record.editing = true"><mat-icon>edit</mat-icon></button>
                    }
                    <button mat-icon-button color="warn" (click)="deleteEnv(i)"><mat-icon>delete</mat-icon></button>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  }
</div>
