<div class="page-title">
  <h1>Dominios</h1>
  <button mat-flat-button color="accent" (click)="add()" [disabled]="!permissions.validateDepartment(['direction', 'sysadmin'])">
    <mat-icon style="margin-right: 10px">add</mat-icon>
    Añadir dominio
  </button>
</div>

<form class="bisual-table" [formGroup]="filterForm">
  <div class="card paginator">
    <mat-paginator
      [length]="length"
      [pageSize]="pageSize"
      [pageSizeOptions]="pageSizeOptions"
      [pageIndex]="pageIndex"
      (page)="changePage($event)"
      style="margin-right: auto;"
    ></mat-paginator>

    <mat-form-field>
      <input matInput type="text" formControlName="domain">
      <button matSuffix mat-icon-button aria-label="Clear">
        <mat-icon>search</mat-icon>
      </button>
    </mat-form-field>
  </div>

  <div class="bisual-table-two-columns">

    <div class="card">
      <div class="field">
        <mat-label>Propietario</mat-label>
        <mat-form-field appearance="outline">
          <input [formControl]="companies_property_control" type="text" placeholder="Escribe un nombre..." matInput [matAutocomplete]="autoCompany">
          <mat-autocomplete #autoCompany="matAutocomplete">
            @for (company of companies_filtered; track company) {
              <mat-option [value]="company.fact_name" (click)="selectCompanyFilter(company)">{{company.fact_name}}</mat-option>
            }
          </mat-autocomplete>
        </mat-form-field>
      </div>

      <div class="field">
        <mat-label>Proveedor</mat-label>
        <mat-radio-group formControlName="provider_id">
          <mat-radio-button [value]="''">Todos</mat-radio-button>
          @for (p of providers; track p) {
            <mat-radio-button [value]="p.id">{{ p.name }}</mat-radio-button>
          }
        </mat-radio-group>
      </div>

      <div class="field">
        <mat-label>Estado</mat-label>
        <mat-radio-group formControlName="status">
          <mat-radio-button [value]="''">Todos</mat-radio-button>
          <mat-radio-button [value]="'active'" matTooltip="Sigue en activo">Activo</mat-radio-button>
          <mat-radio-button [value]="'cancelled'" matTooltip="El cliente ha cancelado y se eliminará próximamente cuando caduque">Cancelado</mat-radio-button>
          <mat-radio-button [value]="'expired'" matTooltip="Ha expirado, y el cliente no ha informado de su estado nuevo">Expirado</mat-radio-button>
          <mat-radio-button [value]="'deleted'" matTooltip="El cliente lo canceló y ha expirado, eliminado de GoDaddy">Eliminado</mat-radio-button>
        </mat-radio-group>
      </div>
    </div>

    <div class="bisual-table-container">
      <table mat-table [dataSource]="dataSource">

        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let domain">
            <div [matTooltip]="getDomainStatusTooltip(domain.status)" class="status-point" [ngClass]="domain.status"></div>
          </td>
        </ng-container>

        <ng-container matColumnDef="domain">
          <th mat-header-cell *matHeaderCellDef>Dominio</th>
          <td mat-cell *matCellDef="let domain">
            {{ domain.domain }}
          </td>
        </ng-container>

        <ng-container matColumnDef="provider">
          <th mat-header-cell *matHeaderCellDef>Proveedor</th>
          <td mat-cell *matCellDef="let domain">
            {{ domain.provider.name }}
          </td>
        </ng-container>

        <ng-container matColumnDef="company">
          <th mat-header-cell *matHeaderCellDef>Propietario</th>
          <td mat-cell *matCellDef="let domain">
            {{ domain.company_id==null ? 'Bisual' : domain.company.fact_name }}
          </td>
        </ng-container>

        <ng-container matColumnDef="quantity">
          <th mat-header-cell *matHeaderCellDef>Precio</th>
          <td mat-cell *matCellDef="let domain">
            {{ domain.quantity!=null ? (domain.quantity | currency) + ' / ' + (domain.renewal_days_period | daysToPeriod) : 'Sin precio' }}
          </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let domain; let i = index;">
            <button mat-icon-button [matMenuTriggerFor]="menu">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item [routerLink]="['/employees', 'development', 'services', 'domains', domain.id, 'resume']">
                <mat-icon matPrefix>visibility</mat-icon>
                Ver dominio
              </button>
              <button mat-menu-item (click)="cancelDomain(domain)" matTooltip="Cancelará el dominio y esperará a eliminarlo a que expire." [disabled]="!permissions.validateDepartment(['direction', 'sysadmin'])">
                <mat-icon matPrefix>block</mat-icon>
                Cancelar dominio
              </button>
            </mat-menu>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
  </div>

</form>
