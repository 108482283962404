import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'projectTaskPriorityFieldPipe'
})
export class ProjectTaskPriorityFieldPipePipe implements PipeTransform {

  transform(value: string): string {
    switch(value) {
      case 'critical':
        return 'Crítica';
      case 'high':
        return 'Alta';
      case 'medium':
        return 'Media';
      case 'low':
        return 'Baja';
      default:
        return '';
    }
  }

}
