import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Department } from 'src/app/models/department.model';
import { DepartmentCategory } from 'src/app/models/department_category.model';
import { ApiService } from 'src/app/services/api.service';
import { DepartmentService } from 'src/app/services/department.service';
import { PermissionsService } from 'src/app/services/permissions.service';
import { UtilsService } from 'src/app/services/utils.service';
import { AddDepartmentCategoryDialogComponent } from './add-department-category-dialog/add-department-category-dialog.component';

@Component({
  selector: 'app-department-detail',
  templateUrl: './department-detail.component.html',
  styleUrls: ['./department-detail.component.css']
})
export class DepartmentDetailComponent implements OnInit {

  department_id:string = null as any;

  departments:Department[] = [];

  inheritIdContent:boolean = false;

  departmentForm:UntypedFormGroup;
  departmentcategoriesForm:UntypedFormGroup;



  departmentCategories: DepartmentCategory[] = [];
  dataSource:MatTableDataSource<Department> = new MatTableDataSource<Department>();
  dataSourceCategories:MatTableDataSource<DepartmentCategory> = new MatTableDataSource<DepartmentCategory>();




  tableData:any[] = [];
  displayedColumns: string[] = ['image', 'name', 'description', 'min_salary', 'max_salary', 'actions'];


  titleDepartment = 'Departamento';

  //paginator
  length:number = 0;
  pageSize:number = 10;
  pageSizeOptions:number[] = [5,10,20,50];
  pageIndex:number = 1;
  private formPersistence:any;



  constructor(public router:Router,
              private fb:UntypedFormBuilder,
              public activatedRoute:ActivatedRoute,
              public permissions:PermissionsService,
              public dialog: MatDialog,
              private api:ApiService,
              private utils:UtilsService,
              private departmentService:DepartmentService,
              ) {

    this.departmentForm = this.fb.group({

      name: ['', Validators.compose([Validators.required])],
      description: ['', Validators.compose([Validators.required])],

    })

    this.departmentcategoriesForm = this.fb.group({
      search: [this.activatedRoute.snapshot.queryParamMap.get('search')!=null ? this.activatedRoute.snapshot.queryParamMap.get('search') : '', Validators.compose([Validators.minLength(3)])],
      per_page: [this.activatedRoute.snapshot.queryParamMap.get('per_page')!=null ? +(this.activatedRoute.snapshot.queryParamMap.get('per_page') as any) : this.pageSize, Validators.required],
      page: [this.activatedRoute.snapshot.queryParamMap.get('page')!=null ? +(this.activatedRoute.snapshot.queryParamMap.get('page') as any) : this.pageIndex, Validators.required],
      total: [this.activatedRoute.snapshot.queryParamMap.get('total')!=null ? +(this.activatedRoute.snapshot.queryParamMap.get('total') as any) : this.length],
    })
  }


  ngOnInit(): void {
    // obtenir id de la url
    this.inheritIdContent = this.router.url.includes("/rrhh/departments/");
    this.department_id = (this.activatedRoute.snapshot.paramMap.get('id') ?? this.departmentService.getCurrentDepartment().id) as string;
    this.fetchDepartment();
    this.fetchGetDepartment();
    this.fetchDepartmentCategories();
    this.initFilterFormListener();
    this.listenQueryParameters();
  }

  private fetchGetDepartment(){
    if(this.activatedRoute.snapshot.paramMap.get('id') !=null){
      this.api.getDepartment(String(this.activatedRoute.snapshot.paramMap.get('id'))).subscribe(
        data => {
          this.titleDepartment = data.name;
        })
    }
  }


  delete() {
    if(confirm("¿Estás seguro que quieres eliminar este departamento?")) {
      this.api.deleteDepartment(+this.department_id).subscribe(
        data => {
          this.router.navigate(['/employees', 'rrhh', 'departments']);
        }
      );
    }
  }

  deleteDepartmentCategory(index:number) {
    const department = this.dataSourceCategories.data[index];
    if(confirm(`¿Estás seguro que quieres eliminar la Categoría "${department.name}"?`)) {
      this.api.deleteDepartmentCategory(department.id).subscribe(
        data => {
          this.fetchDepartmentCategories();
        }
      );
    }
  }

  private fetchDepartmentCategories() {
    const params = this.departmentcategoriesForm.value;
    params['department_id'] = this.department_id;
    this.api.getDepartmentCategoriesPaged(params).subscribe(
      data => {
        this.departmentForm.patchValue(data);
        this.dataSourceCategories.data = data.data;
        this.length = +data.meta.total;
        this.pageIndex = +data.meta.current_page-1;
        this.pageSize = +data.meta.per_page;
      }
    );
  }

  changePage(event:PageEvent) {
    this.departmentForm.patchValue({
      page: event.pageIndex,
      per_page: event.pageSize
    });
  }

  save() {
    if(confirm("¿Estás seguro que quieres guardar este departamento?")){
    this.api.updateDepartment(this.department_id, this.departmentForm.value).subscribe();
    }
  }

  updateDepartment() {
      this.api.updateDepartment(this.department_id, this.departmentForm.value).subscribe();
  }

  addCategory() {
    const dialogRef = this.dialog.open(AddDepartmentCategoryDialogComponent, {
      width: '750px',
      data: {
        department_id: this.department_id,
      }
    });

    dialogRef.afterClosed().subscribe((department_category:DepartmentCategory) => {
      if(department_category != null) {
        this.api.createDepartmentCategory(department_category).subscribe(data => {
          this.fetchDepartmentCategories();
        });
      }
    });
  }

  private fetchDepartment() {
    this.api.getDepartment(this.department_id ).subscribe(
      data => {
        this.departmentForm.patchValue(data);
      }
    )
  }

  fetchGetDepartmentCategories(){
    if(this.activatedRoute.snapshot.paramMap.get('department_id') !=null){
      this.api.getDepartmentCategories().subscribe()
    }
  }


  private initFilterFormListener() {
    this.departmentcategoriesForm.valueChanges.subscribe(
      data => {
        data.per_page = +data.per_page;
        if(this.formPersistence==null || JSON.stringify(this.formPersistence)!=JSON.stringify(data)) {
          if(this.formPersistence.per_page!=data.per_page) data.page = 0;
          this.router.navigate(['/employees', 'rrhh', 'departments','id'], { queryParams: data });
        }
      }
    );
  }

  private listenQueryParameters() {
    this.activatedRoute.queryParams.subscribe(
      params => {
        if(JSON.stringify(params)!==JSON.stringify(this.departmentcategoriesForm.value)) { //si no ve de filtre s'ha de setejar el form
          let params_temp = this.utils.cloneObj(params); //params es read_only
          Object.keys(params_temp).forEach(param_key => {
            if(params_temp[param_key]!=null && params_temp[param_key]!="" && !isNaN(+params_temp[param_key])) params_temp[param_key] = +params_temp[param_key]; // si es numero, el transformem
          });
          this.departmentcategoriesForm.patchValue(params_temp, { emitEvent: false });
        }
        this.formPersistence = params;
        this.fetchDepartmentCategories();
      }
    );
  }

}
