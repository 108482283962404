import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { WeeklyWorkingTimeBudget } from 'src/app/models/weekly_working_time_budget.model';
import { WeeklyWorkingTimeBudgetEmployee } from 'src/app/models/weekly_working_time_budget_employee.model';

@Component({
  selector: 'app-project-assignment-dialog',
  templateUrl: './project-assignment-dialog.component.html',
  styleUrls: ['./project-assignment-dialog.component.css']
})
export class ProjectAssignmentDialogComponent implements OnInit {

  from:Date = null as any;
  to:Date = null as any;
  savedResults:{[key:number]:WeeklyWorkingTimeBudgetEmployee} = {}

  constructor(
    public dialogRef: MatDialogRef<ProjectAssignmentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any,) {
      this.from = data.from;
      this.to = data.to;
    }

  ngOnInit(): void {
  }

  onSave(evt:WeeklyWorkingTimeBudgetEmployee[]) {
    evt.forEach(wwtb => {
      this.savedResults[wwtb.id] = wwtb;
    });
  }

  close() {
    this.dialogRef.close(this.savedResults);
  }

}

