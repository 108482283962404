import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Company } from 'src/app/models/company.model';
import { Domain } from 'src/app/models/domain.model';
import { Provider } from 'src/app/models/provider.model';
import { ApiService } from 'src/app/services/api.service';
import { CustomerService } from 'src/app/services/customer.service';
import { PermissionsService } from 'src/app/services/permissions.service';
import { UtilsService } from 'src/app/services/utils.service';
import { AddDomainDialogComponent } from './add-domain-dialog/add-domain-dialog.component';

@Component({
  selector: 'app-domains',
  templateUrl: './domains.component.html',
  styleUrls: ['./domains.component.css']
})
export class DomainsComponent implements OnInit {

  displayedColumns:string[] = ["status", "domain", "provider", "company", "quantity", "actions"];
  dataSource:MatTableDataSource<Domain> = new MatTableDataSource();

  // dades per el paginator
  length:number = 0;
  pageSize:number = 10;
  pageSizeOptions:number[] = [5,10,20,50];
  pageIndex:number = 1;

  filterForm:UntypedFormGroup;
  private formPersistence:any;

  companies_filtered:Company[] = [];
  companies_property_control:UntypedFormControl = new UntypedFormControl('', Validators.compose([Validators.minLength(3)]));

  providers:Provider[] = [];

  constructor(private fb:UntypedFormBuilder,
              private activatedRoute:ActivatedRoute,
              private api:ApiService,
              private router:Router,
              private utils:UtilsService,
              private customer:CustomerService,
              public dialog: MatDialog,
              public permissions:PermissionsService) {

    const domain_param = this.activatedRoute.snapshot.queryParamMap.get('domain');
    const per_page_param = this.activatedRoute.snapshot.queryParamMap.get('per_page');
    const page_param = this.activatedRoute.snapshot.queryParamMap.get('page');
    const total_param = this.activatedRoute.snapshot.queryParamMap.get('total');
    const provider_id_param = this.activatedRoute.snapshot.queryParamMap.get('provider_id');
    const status_param = this.activatedRoute.snapshot.queryParamMap.get('status');
    const company_id_param = this.activatedRoute.snapshot.queryParamMap.get('company_id');
    this.filterForm = this.fb.group({
      domain: [domain_param!=null ? domain_param : '', Validators.compose([Validators.minLength(3)])],
      per_page: [per_page_param!=null ? +(per_page_param) : this.pageSize, Validators.required],
      page: [page_param!=null ? +(page_param) : this.pageIndex, Validators.required],
      total: [total_param!=null ? +(total_param) : this.length],
      provider_id: [provider_id_param!=null ? +(provider_id_param) : ''],
      status: [status_param!=null ? +(status_param) : ''],
      company_id: [company_id_param!=null ? +(company_id_param) : ''],
    });

  }

  ngOnInit(): void {
    this.listenCustomerService();
    this.fetchProviders();
    this.listenAutocompleteControls();
    this.initFilterFormListener();
    this.listenQueryParameters();
  }

  changePage(event:PageEvent) {
    this.filterForm.patchValue({
      page: event.pageIndex,
      per_page: event.pageSize
    });
  }

  getDomainStatusTooltip(status:string) {
    return this.utils.getStatusTooltip(status);
  }

  selectCompanyFilter(company:Company) {
    this.filterForm.patchValue({ company_id: company.id });
  }

  cancelDomain(domain:Domain) {
    if(confirm("¿Estás seguro que quieres cancelar este dominio?")) {
      this.api.cancelDomain(domain.id).subscribe(
        data => {
          this.fetchDomains();
        }
      );
    }
  }

  add() {
    const dialogRef = this.dialog.open(AddDomainDialogComponent, {
      width: '800px',
      data: {
        providers: this.providers
      }
    });

    dialogRef.afterClosed().subscribe((data:any) => {
      if(data != null) {
        this.api.createDomain(data).subscribe(data => {
          this.fetchDomains();
        });
      }
    });
  }

  private initFilterFormListener() {
    this.filterForm.valueChanges.subscribe(
      data => {
        data.per_page = +data.per_page;
        if(this.formPersistence==null || JSON.stringify(this.formPersistence)!=JSON.stringify(data)) {
          if(this.formPersistence.per_page!=data.per_page) data.page = 0;
          this.router.navigate(['/employees', 'development', 'services', 'domains'], { queryParams: data });
        }
      }
    );
  }

  private fetchDomains() {
    this.api.getDomainsPaged(this.filterForm.value).subscribe(
      data => {
        this.dataSource.data = data.data;

        this.length = +data.meta.total;
        this.pageIndex = +data.meta.current_page-1;
        this.pageSize = +data.meta.per_page;
      }
    );
  }

  private listenQueryParameters() {
    this.activatedRoute.queryParams.subscribe(
      params => {
        if(JSON.stringify(params)!==JSON.stringify(this.filterForm.value)) { //si no ve de filtre s'ha de setejar el form
          let params_temp = this.utils.cloneObj(params); //params es read_only
          Object.keys(params_temp).forEach(param_key => {
            if(params_temp[param_key]!=null && params_temp[param_key]!="" && !isNaN(+params_temp[param_key])) params_temp[param_key] = +params_temp[param_key]; // si es numero, el transformem
          });
          this.filterForm.patchValue(params_temp, { emitEvent: false });
        }
        this.formPersistence = params;
        this.fetchDomains();
      }
    );
  }

  private fetchProviders() {
    this.api.getProviders({ is_domain_provider: true }).subscribe(
      data => {
        this.providers = data;
      }
    );
  }

  private listenCustomerService() {
    this.customer.customerObservable.subscribe(
      data => {
        if(data==null) {
          this.companies_property_control.enable();
          this.filterForm.patchValue({ company_id: '' });
        }
        else {
          this.companies_property_control.disable();
          this.filterForm.patchValue({ company_id: data.id });
        }
      }
    );
  }

  private listenAutocompleteControls() {
    // companies autocomplete
    this.companies_property_control.valueChanges.subscribe(
      (data:string) => {
        if(this.companies_property_control.valid && data.length > 0) {
          let params:any = {};
          params['search'] = data;
          this.api.getCompanies(params).subscribe(
            companies => {
              this.companies_filtered = companies.data;
            }
          );
        }
        else {
          this.filterForm.patchValue({ company_id: '' });
          this.companies_filtered = [];
        }

      }
    );
  }

}
