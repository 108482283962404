<div id="content" style="width: 100%;">
  <div class="row text-center">
    <div class="col-md">
      <div class="date-controller">
        <h3>
          <button mat-icon-button mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate" (viewDateChange)="changeWeek()"><mat-icon>chevron_left</mat-icon></button>
          {{ viewDate | CalendarDateToCustomFormat}}
          <button mat-icon-button mwlCalendarNextView [view]="view"[(viewDate)]="viewDate" (viewDateChange)="changeWeek()" ><mat-icon>chevron_right</mat-icon></button>
        </h3>
      </div>
    </div>
    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item (click)="openDialog()" ngxClipboard [cbContent]="calendarUrl">
        <mat-icon>sync</mat-icon>
        <span>Sincronizar calendario</span>
      </button>
      <button mat-menu-item (click)="openCalendars()" ngxClipboard [cbContent]="calendarUrl">
        <mat-icon>calendar_today</mat-icon>
        <span>Gestionar calendario</span>
      </button>
    </mat-menu>
  </div>
  <div>
    <ng-template
      #weekViewHourSegmentTemplate
      let-segment="segment"
      let-locale="locale"
      let-segmentHeight="segmentHeight"
      let-isTimeLabel="isTimeLabel"
      >
      <div
        #segmentElement
        class="cal-hour-segment"
        [style.height.px]="segmentHeight"
        [class.cal-hour-start]="segment.isStart"
        [class.cal-after-hour-start]="!segment.isStart"
        [ngClass]="segment.cssClass"
        >
        @if (isTimeLabel) {
          <div class="cal-time">
            {{ segment.date | calendarDate:'weekViewHour':locale }}
          </div>
        }
      </div>
    </ng-template>

    <mwl-calendar-week-view
      [locale]="'es-ES'"
      [viewDate]="viewDate"
      [events]="events"
      [refresh]="refresh"
      [hourSegments]="hour_segments"
      [hourSegmentHeight]="hour_segmented_height"
      [hourSegmentTemplate]="weekViewHourSegmentTemplate"
      precision="minutes"
      [weekStartsOn]="1"
      (beforeViewRender)="beforeWeekViewRender($event)"
      (eventClicked)="eventClicked($event)"
      [tooltipTemplate]="tooltipTemplate"
      [minimumEventHeight]="0"
      [hourDuration]="60"
    ></mwl-calendar-week-view>
    <ng-template #tooltipTemplate let-event="event">
      <div id="event-tooltip" [ngStyle]="{'background-color': event.color.primary}">
        @if (event.title) {
          <span class="title">{{event.title}}</span>
        }
        @if (event.title) {
          <br>
          }
          @if (event.subtitle) {
            <span class="subtitle">{{event.subtitle}}</span>
          }
          @if (event.subtitle) {
            <br>
            }
            @if (event.project_phase_name) {
              <span class="project_phase_name">{{event.project_phase_name}}</span>
            }
            <!-- from to dates -->
            <br>
              @if (event.start) {
                <span>{{event.start | date:'HH:mm'}} - {{event.end | date:'HH:mm'}}</span>
              }
            </div>
          </ng-template>
          <!-- <ng-template #eventActionsTemplate let-event="event">
          <div [attr.data-event-height]="event.height"></div>
        </ng-template> -->
      </div>
    </div>
