<div id="content">

  <div class="page-title">
    <h1>Peticion de vacaciones</h1>
  </div>

  <div class="bisual-table">
    <form class="card paginator" [formGroup]="filterForm">
      <mat-paginator [length]="length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" [pageIndex]="pageIndex"
        (page)="changePage($event)" style="margin-right: auto;"></mat-paginator>
      <mat-form-field appearance="outline" class="bisual-paginator-select">
        <mat-label>Estado</mat-label>
        <mat-select formControlName="status">
          <mat-option [value]="''">Todos</mat-option>
          <mat-option [value]="'pending'">Pendiente</mat-option>
          <mat-option [value]="'accepted'">Aceptado</mat-option>
          <mat-option [value]="'rejected'">Rechazado</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <input matInput type="text" formControlName="search">
        <button matSuffix mat-icon-button aria-label="Clear">
          <mat-icon>search</mat-icon>
        </button>
      </mat-form-field>
    </form>

    <div class="bisual-table-container">
      <table mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef>Id</th>
          <td mat-cell *matCellDef="let vacationRequest">
            {{vacationRequest.id}}
          </td>
        </ng-container>
        <ng-container matColumnDef="nombre">
          <th mat-header-cell *matHeaderCellDef>Nombre</th>
          <td mat-cell *matCellDef="let vacationRequest">
            {{vacationRequest.user.name + ' ' +
            vacationRequest.user.surnames}}
          </td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef>Estado</th>
          <td mat-cell *matCellDef="let vacationRequest">{{
            vacationRequest.status == 'pending' ? 'Pendiente' :
            vacationRequest.status == 'accepted' ? 'Aceptado' :
            'Rechazado'
          }}</td>
        </ng-container>

        <ng-container matColumnDef="dias solicitados">
          <th mat-header-cell *matHeaderCellDef>Horas Solicitadas</th>
          <td mat-cell *matCellDef="let vacationRequest">{{vacationRequest.count_hours_of_vacations}} horas</td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let vacationRequest; let i = index;">
            <button mat-icon-button [matMenuTriggerFor]="menu">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item [routerLink]="['/employees/rrhh/absences-management/manage-vacation-request',vacationRequest.id]"
                [disabled]="!permissions.validateDepartment(['direction', 'rrhh'])">
                <mat-icon matPrefix>visibility</mat-icon>
                Visualizar peticion
              </button>
              <button mat-menu-item [disabled]="true || !permissions.validateDepartment(['direction', 'rrhh'])" (click)="delete(vacationRequest.id)">
                <mat-icon matPrefix>delete</mat-icon>
                Eliminar peticion
              </button>
            </mat-menu>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
  </div>
</div>
