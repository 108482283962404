import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {MatSnackBar} from '@angular/material/snack-bar';
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
})
export class SnackbarInterceptor implements HttpInterceptor {
  constructor(private snack:MatSnackBar) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const tractable:boolean = (request.url.indexOf(environment.laravel_url) != -1) && request.url.indexOf("auth")==-1;
    //tractem response
    return next.handle(request).pipe(
        catchError((error:HttpErrorResponse) => {
            if(tractable) {

                /*let title:string;
                switch(request.method) {
                    case 'GET':
                        title = "Error obteniendo datos";
                        break;
                    case 'POST':
                        title = "Error creando";
                        break;
                    case 'PUT':
                        title = "Error actualizando";
                        break;
                    case 'DELETE':
                        title = "Error borrando";
                        break;
                    default:
                        title = "Error";
                        break;
                }*/

                const message = error.error ? (`Error ${error.status}: ${error.error.error ? error.error.error : error.error.message}`) : (`Error ${error.status}: ${error.message}`)

                this.snack.open(message, "Ok", { duration: 3000 });
            }
            return throwError(error);
        }),
        map((event:HttpEvent<any>) => {
            if(event instanceof HttpResponse && tractable && request.headers.get('avoid-snackbar-notification') === null) {
                switch (request.method) {
                    case "POST":
                        this.snack.open("Se ha creado correctamente.", "Ok", { duration: 3000 });
                        break;
                    case "PUT":
                        this.snack.open("Se ha actualizado correctamente", "Ok", { duration: 3000 });
                        break;
                    case "DELETE":
                        this.snack.open("Se ha borrado correctamente", "Ok", { duration: 3000 });
                        break;
                }
            }
            return event;
        })
    );
  }
}
