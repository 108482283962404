import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Company } from 'src/app/models/company.model';
import { Role } from 'src/app/models/role.model';
import { User } from 'src/app/models/user.model';
import { ApiService } from 'src/app/services/api.service';
import { PermissionsService } from 'src/app/services/permissions.service';
import { UtilsService } from 'src/app/services/utils.service';
import { AddUserDialogComponent } from '../users/add-user-dialog/add-user-dialog.component';
import { AddCompanyDialogComponent } from './add-company-dialog/add-company-dialog.component';

@Component({
  selector: 'app-companies',
  templateUrl: './companies.component.html',
  styleUrls: ['./companies.component.css']
})
export class CompaniesComponent implements OnInit {

  displayedColumns: string[] = ['name', 'fact', 'stripe', 'actions'];
  dataSource:MatTableDataSource<Company> = new MatTableDataSource();

  // dades per el paginator
  length:number = 0;
  pageSize:number = 10;
  pageSizeOptions:number[] = [5,10,20,50];
  pageIndex:number = 1;
  roles:Role[] = [];

  filterForm:UntypedFormGroup;
  private formPersistence:any;

  constructor(private api:ApiService,
              private fb:UntypedFormBuilder,
              private router:Router,
              private activatedRoute:ActivatedRoute,
              private utils:UtilsService,
              public dialog: MatDialog,
              public permissions:PermissionsService) {

    this.filterForm = this.fb.group({
      search: [this.activatedRoute.snapshot.queryParamMap.get('search')!=null ? this.activatedRoute.snapshot.queryParamMap.get('search') : '', Validators.compose([Validators.minLength(3)])],
      per_page: [this.activatedRoute.snapshot.queryParamMap.get('per_page')!=null ? +(this.activatedRoute.snapshot.queryParamMap.get('per_page') as any) : this.pageSize, Validators.required],
      page: [this.activatedRoute.snapshot.queryParamMap.get('page')!=null ? +(this.activatedRoute.snapshot.queryParamMap.get('page') as any) : this.pageIndex, Validators.required],
      total: [this.activatedRoute.snapshot.queryParamMap.get('total')!=null ? +(this.activatedRoute.snapshot.queryParamMap.get('total') as any) : this.length],
    });

  }

  ngOnInit(): void {
    this.fetchRoles();
    this.initFilterFormListener();
    this.listenQueryParameters();
  }

  changePage(event:PageEvent) {
    this.filterForm.patchValue({
      page: event.pageIndex,
      per_page: event.pageSize
    });
  }

  deleteCompany(index:number) {
    const company = this.dataSource.data[index];
    if(confirm(`¿Estás seguro que quieres eliminar el cliente "${company.fact_name}"?`)) {
      this.api.deleteCompany(company.id).subscribe(
        data => {
          this.fetchCompanies();
        }
      );
    }
  }

  addCompany() {
    const dialogRef = this.dialog.open(AddCompanyDialogComponent, {
      width: '750px',
    });

    dialogRef.afterClosed().subscribe((company:any) => {
      if(company != null) {
        this.api.createCompany(company).subscribe(data => {
          this.fetchCompanies();
          if(company.create_user) {
            this.addCompanyUser(data.id);
          }
        });
      }
    });
  }

  addCompanyUser(company_id:number) {
    const dialogRef = this.dialog.open(AddUserDialogComponent, {
      width: '750px',
      data: {
        company_id: company_id,
      }
    });

    dialogRef.afterClosed().subscribe((user:User) => {
      if(user != null) {

      }
    });
  }

  private fetchRoles() {
    this.permissions.rolesObservable.subscribe(
      data => {
        if(data != null) {
          this.roles = Object.keys(data).map(k => data[k]);
        }
      }
    );
  }


  private fetchCompanies() {
    this.api.getCompaniesPaged(this.filterForm.value).subscribe(
      data => {
        this.dataSource.data = data.data;

        this.length = +data.meta.total;
        this.pageIndex = +data.meta.current_page-1;
        this.pageSize = +data.meta.per_page;
      }
    );
  }

  private initFilterFormListener() {
    this.filterForm.valueChanges.subscribe(
      data => {
        data.per_page = +data.per_page;
        if(this.formPersistence==null || JSON.stringify(this.formPersistence)!=JSON.stringify(data)) {
          if(this.formPersistence.per_page!=data.per_page) data.page = 0;
          this.router.navigate(['/employees', 'direction', 'users-management', 'companies'], { queryParams: data });
        }
      }
    );
  }

  private listenQueryParameters() {
    this.activatedRoute.queryParams.subscribe(
      params => {
        if(JSON.stringify(params)!==JSON.stringify(this.filterForm.value)) { //si no ve de filtre s'ha de setejar el form
          let params_temp = this.utils.cloneObj(params); //params es read_only
          Object.keys(params_temp).forEach(param_key => {
            if(params_temp[param_key]!=null && params_temp[param_key]!="" && !isNaN(+params_temp[param_key])) params_temp[param_key] = +params_temp[param_key]; // si es numero, el transformem
          });
          this.filterForm.patchValue(params_temp, { emitEvent: false });
        }
        this.formPersistence = params;
        this.fetchCompanies();
      }
    );
  }

}
