<h1 mat-dialog-title>Crear nuevo ingreso</h1>

<mat-dialog-content [formGroup]="formGroup">
  <div class="container">
    <div class="row">
      <div class="col-12 col-lg-4">
        <mat-label>Número de factura</mat-label>
        <mat-form-field appearance="outline">
          <input matInput formControlName="invoice_number" />
        </mat-form-field>
      </div>
      <div class="col-12 col-lg-4">
        <mat-label>Fecha de factura</mat-label>
        <mat-form-field appearance="outline">
          <input matInput [matDatepicker]="picker" formControlName="invoice_date">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="col-12 col-lg-4">
        <mat-label>Cliente</mat-label>
        <mat-form-field appearance="outline">
          <input [formControl]="companies_property_control" type="text" placeholder="Escribe un nombre..." matInput [matAutocomplete]="autoCompany">
          <mat-autocomplete #autoCompany="matAutocomplete">
            @for (company of companies_filtered; track company) {
              <mat-option [value]="company.fact_name" (click)="selectCompanyFilter(company)">{{company.fact_name}}</mat-option>
            }
          </mat-autocomplete>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-lg-4">
        <mat-label>Fecha de vencimiento</mat-label>
        <mat-form-field appearance="outline">
          <input matInput [matDatepicker]="picker_due_date" formControlName="due_date">
          <mat-datepicker-toggle matSuffix [for]="picker_due_date"></mat-datepicker-toggle>
          <mat-datepicker #picker_due_date></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="col-12 col-lg-4">
        <mat-label>IVA</mat-label>
        <mat-form-field appearance="outline">
          <mat-select formControlName="tax_prcnt">
            @for (iva of iva_types; track iva) {
              <mat-option [value]="iva">{{ iva }}%</mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-12 col-lg-4" style="display: flex; align-items: center;">
        <mat-checkbox formControlName="send_email">Enviar correo electrónico</mat-checkbox>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-lg-4"></div>
      <div class="col-12 col-lg-4">
        @if (formGroup.value['payed']==true) {
          <mat-label>Método de pago</mat-label>
        }
        @if (formGroup.value['payed']==true) {
          <mat-form-field appearance="outline">
            <mat-select formControlName="payment_type_id">
              @for (pt of payment_types; track pt) {
                <mat-option [value]="pt.id">{{ pt.name }}</mat-option>
              }
            </mat-select>
          </mat-form-field>
        }
      </div>
      <div class="col-12 col-lg-4" style="display: flex; align-items: center;">
        <mat-checkbox formControlName="payed">Marcar como pagado</mat-checkbox>
      </div>
    </div>
    @if (formGroup.value['company_id']!=null && formGroup.value['company_id']) {
      <div class="row">
        <div class="col-12">
          <div class="container">
            <h2 class="dialog-section">Detalle</h2>
            <div class="row">
              <div class="col-12">
                <mat-accordion>
                  @for (line of lines.controls; track line; let i = $index) {
                    <mat-expansion-panel [formGroup]="getLineFormGroup(i)">
                      <mat-expansion-panel-header>
                        <mat-panel-title> <!-- concepto -->
                          {{ ('#' + (i+1) + '. ') + (line.value['notes']!=null && line.value['notes']!='' ? line.value['notes'] : 'Sin descripción') }}
                        </mat-panel-title>
                        <mat-panel-description>
                          <div class="container">
                            <div class="row">
                              <div class="col-12 col-lg-12" style="text-align: right;">
                                {{ 'Total: ' + ((line.value['total'] != null ? line.value['total'] : 0)| currency) }}
                              </div>
                              <!--<div class="col-12 col-lg-4">
                              {{ 'IVA: ' + (line.value['tax_prcnt']!=null ? line.value['tax_prcnt'] : 0) + '%' }}
                            </div>
                            <div class="col-12 col-lg-4">
                              {{ 'Total IVA: ' + ((line.value['tax_amount']!=null ? line.value['tax_amount'] : 0) | currency) }}
                            </div>-->
                          </div>
                        </div>
                      </mat-panel-description>
                    </mat-expansion-panel-header>
                    <div class="container">
                      <div class="row">
                        <div class="col-12 col-lg-5">
                          <mat-label>Concepto</mat-label>
                          <mat-form-field appearance="outline">
                            <input matInput type="text" formControlName="notes" />
                          </mat-form-field>
                        </div>
                        <div class="col-12 col-lg-3">
                          <mat-label>Base imponible</mat-label>
                          <mat-form-field appearance="outline">
                            <input matInput type="number" formControlName="subtotal" step="0.05" />
                            <span matSuffix>€</span>
                          </mat-form-field>
                        </div>
                        <div class="col-12 col-lg-2">
                          <mat-label>IVA</mat-label>
                          <mat-form-field appearance="outline">
                            <input matInput type="text" formControlName="tax_amount" />
                            <span matSuffix>€</span>
                          </mat-form-field>
                        </div>
                        <div class="col-12 col-lg-2">
                          <mat-label>Total</mat-label>
                          <mat-form-field appearance="outline">
                            <input matInput type="text" formControlName="total" step="0.05" />
                            <span matSuffix>€</span>
                          </mat-form-field>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12 col-lg-3">
                          <mat-label>Tipo de línea</mat-label>
                          <mat-form-field appearance="outline">
                            <mat-select formControlName="type">
                              <mat-option [value]="''">Ninguno</mat-option>
                              <mat-option [value]="'service'">Servicio</mat-option>
                              <mat-option [value]="'project'">Proyecto</mat-option>
                              <mat-option [value]="'hosting'">Hosting</mat-option>
                              <mat-option [value]="'dominio'">Dominio</mat-option>
                              <mat-option [value]="'email'">Correo electrónico</mat-option>
                              <mat-option [value]="'subscription'">Suscripción VIP</mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>
                        @if (line.value['type']!=null && line.value['type']!='') {
                          @if (line.value['type']=='service') {
                            <!-- servicio -->
                            <div class="col-12 col-lg-9">
                              <mat-label>Servicio</mat-label>
                              <mat-form-field appearance="outline">
                                <input formControlName="service_search" type="text" placeholder="Escribe un nombre..." matInput [matAutocomplete]="autoService">
                                <mat-autocomplete #autoService="matAutocomplete">
                                  @for (service of services_filtered; track service) {
                                    <mat-option [value]="service.description" (click)="selectServiceFilter(i, service)">{{service.description}}</mat-option>
                                  }
                                </mat-autocomplete>
                              </mat-form-field>
                            </div>
                          }
                          @if (line.value['type']=='project') {
                            <!-- proyecto -->
                            <div class="col-12 col-lg-3"><!-- proyecto -->
                              <mat-label>Proyecto</mat-label>
                              <mat-form-field appearance="outline">
                                <input formControlName="project_search" type="text" placeholder="Escribe un nombre..." matInput [matAutocomplete]="autoProject">
                                <mat-autocomplete #autoProject="matAutocomplete">
                                  @for (project of projects_filtered; track project) {
                                    <mat-option [value]="project.title" (click)="selectProjectFilter(i, project)">{{project.title}}</mat-option>
                                  }
                                </mat-autocomplete>
                              </mat-form-field>
                            </div>
                            @if (line.value['project_id']!=null && line.value['project_id']!='') {
                              <div class="col-12 col-lg-3"><!-- fase -->
                                <mat-label>Fase</mat-label>
                                <mat-form-field appearance="outline">
                                  <mat-select formControlName="project_phase_id">
                                    <mat-option [value]="''"></mat-option>
                                    @for (phase of line.value['project_phases']; track phase) {
                                      <mat-option [value]="phase.id">{{ phase.title }}</mat-option>
                                    }
                                  </mat-select>
                                </mat-form-field>
                              </div>
                            }
                            @if (line.value['project_id']!=null && line.value['project_id']!='' && line.value['project_phase_id']!=null && line.value['project_phase_id']!='') {
                              <div class="col-12 col-lg-3"><!-- estado de la fase -->
                                <mat-label>Estado de la fase</mat-label>
                                <mat-form-field appearance="outline">
                                  <mat-select formControlName="project_phase_stage_id">
                                    <mat-option [value]="''"></mat-option>
                                    @for (stage of project_phase_stages; track stage) {
                                      <mat-option [value]="stage.id">{{ stage.title }}</mat-option>
                                    }
                                  </mat-select>
                                </mat-form-field>
                              </div>
                            }
                          }
                          @if (line.value['type']=='hosting') {
                            <!-- hosting -->
                            <div class="col-12 col-lg-9">
                              <mat-label>Hosting</mat-label>
                              <mat-form-field appearance="outline">
                                <input formControlName="hosting_search" type="text" placeholder="Escribe un nombre..." matInput [matAutocomplete]="autoHosting">
                                <mat-autocomplete #autoHosting="matAutocomplete">
                                  @for (hosting of hostings_filtered; track hosting) {
                                    <mat-option [value]="hosting.hosting_fqdn" (click)="selectHostingFilter(i, hosting)">{{hosting.hosting_fqdn}}</mat-option>
                                  }
                                </mat-autocomplete>
                              </mat-form-field>
                            </div>
                          }
                          @if (line.value['type']=='dominio') {
                            <!-- dominio -->
                            <div class="col-12 col-lg-9">
                              <mat-label>Dominio</mat-label>
                              <mat-form-field appearance="outline">
                                <input formControlName="domain_search" type="text" placeholder="Escribe un nombre..." matInput [matAutocomplete]="autoDomain">
                                <mat-autocomplete #autoDomain="matAutocomplete">
                                  @for (domain of domains_filtered; track domain) {
                                    <mat-option [value]="domain.domain" (click)="selectDomainFilter(i, domain)">{{domain.domain}}</mat-option>
                                  }
                                </mat-autocomplete>
                              </mat-form-field>
                            </div>
                          }
                          @if (line.value['type']=='email') {
                            <!-- correo electronico -->
                            <div class="col-12 col-lg-9">
                              <mat-label>Correo electrónico</mat-label>
                              <mat-form-field appearance="outline">
                                <input formControlName="business_mail_search" type="text" placeholder="Escribe un nombre..." matInput [matAutocomplete]="autoBusinessMail">
                                <mat-autocomplete #autoBusinessMail="matAutocomplete">
                                  @for (bm of business_mails_filtered; track bm) {
                                    <mat-option [value]="bm.email" (click)="selectBusinessMailFilter(i, bm)">{{ bm.email }}</mat-option>
                                  }
                                </mat-autocomplete>
                              </mat-form-field>
                            </div>
                          }
                          @if (line.value['type']=='subscription') {
                            <!-- suscripcion -->
                            <div class="col-12 col-lg-9">
                              <mat-label>Suscripción VIP</mat-label>
                              <mat-form-field appearance="outline">
                                <input formControlName="subscription_search" type="text" placeholder="Escribe un nombre..." matInput [matAutocomplete]="autoSubscription">
                                <mat-autocomplete #autoSubscription="matAutocomplete">
                                  @for (subscription of subscriptions_filtered; track subscription) {
                                    <mat-option [value]="subscription.id + ' - ' + subscription.name" (click)="selectSubscriptionFilter(i, subscription)">{{ subscription.id + ' - ' + subscription.name}}</mat-option>
                                  }
                                </mat-autocomplete>
                              </mat-form-field>
                            </div>
                          }
                        }
                      </div>
                      @if (lines.controls.length>1) {
                        <div class="row">
                          <div class="col-12">
                            <button mat-flat-button color="warn" (click)="deleteLine(i)">Eliminar línea</button>
                          </div>
                        </div>
                      }
                    </div>
                  </mat-expansion-panel>
                }
              </mat-accordion>
            </div>
          </div>
          <div style="margin-top: 15px; display: flex; flex-direction: column; align-items: flex-end;">
            <button mat-flat-button color="accent" (click)="addLine()">Añadir línea</button>
            <p class="total-resume">
              <span><b>Subtotal</b>: {{ formGroup.value['subtotal'] | currency }}</span>
              <span><b>IVA</b>: {{ formGroup.value['tax_amount'] | currency }}</span>
              <span><b>Total</b>: {{ formGroup.value['total'] | currency }}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  }
</div>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button (click)="onNoClick()" cdkFocusInitial type="button">Cancelar</button>
  <button mat-flat-button color="accent" (click)="save()" [disabled]="!formGroup.valid" type="submit">
    <mat-icon style="margin-right: 10px">add</mat-icon>
    Crear ingreso
  </button>
</mat-dialog-actions>
