import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { ProjectTeam } from 'src/app/models/project_team.model';
import { ApiService } from 'src/app/services/api.service';
import { PermissionsService } from 'src/app/services/permissions.service';
import { ProjectTeamCreditDialogComponent } from './project-team-credit-dialog/project-team-credit-dialog.component';

@Component({
  selector: 'app-project-teams',
  templateUrl: './project-teams.component.html',
  styleUrls: ['./project-teams.component.css']
})
export class ProjectTeamsComponent implements OnInit {

  displayedColumns: string[] = ["name", "users", "actions"];
  dataSource:MatTableDataSource<ProjectTeam> = new MatTableDataSource();

  private project_id:string = null as any;

  constructor(private activatedRoute:ActivatedRoute,
              private api:ApiService,
              public permissions:PermissionsService,
              private dialog:MatDialog) { }

  ngOnInit(): void {
    this.initProjectIdParam();
    this.fetchTeams();
  }

  add() {
    this.openCreditDialog();
  }

  edit(pt:ProjectTeam) {
    this.openCreditDialog(pt);
  }

  delete(pt:ProjectTeam) {
    this.api.deleteProjectTeam(pt.id.toString()).subscribe(
      data => {
        this.fetchTeams();
      }
    );
  }

  private initProjectIdParam() {
    this.project_id = this.activatedRoute.snapshot.parent?.parent?.paramMap.get("id") as string;
  }

  private fetchTeams() {
    this.api.getProjectProjectTeams(this.project_id, {with: 'users'}).subscribe(
      data => {
        this.dataSource.data = data;
      }
    );
  }

  private openCreditDialog(pt:ProjectTeam|undefined = undefined) {
    const dialogRef = this.dialog.open(ProjectTeamCreditDialogComponent, {
      width: '600px',
      data: {
        project_team: pt ?? {project_id: this.project_id}
      }
    });

    dialogRef.afterClosed().subscribe(
      data => {
        if(data != null) {
          this.fetchTeams();
        }
      }
    );
  }
}
