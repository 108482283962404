import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProjectPhase } from 'src/app/models/project_phase.model';
import { ProjectPhaseStage } from 'src/app/models/project_phase_stage.model';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-project-phase-edit-dialog',
  templateUrl: './project-phase-edit-dialog.component.html',
  styleUrls: ['./project-phase-edit-dialog.component.css']
})
export class ProjectPhaseEditDialogComponent implements OnInit {
  form: UntypedFormGroup;
  project_phase:ProjectPhase = this.data.project_phase;
  project_phase_stages:ProjectPhaseStage[] = [];

  constructor(
    private api: ApiService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ProjectPhaseEditDialogComponent>,
    private fb: UntypedFormBuilder,) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      title: [this.project_phase.title, []],
      description: [this.project_phase.description, []],
      project_id: [this.project_phase.project_id, []],
      project_phase_stage_id: [this.project_phase.project_phase_stage_id, []],
      contract_id: [this.project_phase.contract_id, []],
      needs_hours_story: [this.project_phase.needs_hours_story, []],
      needs_hours_task: [this.project_phase.needs_hours_task, []],
      needs_hours_issue: [this.project_phase.needs_hours_issue, []],
      can_client_business_add_approval_tasks: [this.project_phase.can_client_business_add_approval_tasks, []],
      can_client_dev_manage_backlog: [this.project_phase.can_client_dev_manage_backlog, []]
    });
    this.fetchProjectPhaseStages();
  }

  fetchProjectPhaseStages() {
    this.api.getProjectPhaseStages().subscribe(data => {
      this.project_phase_stages = data;
    });
  }

  save() {
    this.dialogRef.close(this.form.value);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onCancel() {
    this.dialogRef.close();
  }

}
