import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { PermissionsService } from 'src/app/services/permissions.service';
import { UtilsService } from 'src/app/services/utils.service';
import { UserService } from 'src/app/services/user.service';
import { ProjectPhase } from 'src/app/models/project_phase.model';
import { ProjectPhaseEditDialogComponent } from './project-phase-edit-dialog/project-phase-edit-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ProjectPhaseStage } from 'src/app/models/project_phase_stage.model';
import { ProjectPhaseDeployConfigurationDialogComponent } from './project-phase-deploy-configuration-dialog/project-phase-deploy-configuration-dialog.component';
import { SendBoardStatusDialogComponent } from './send-board-status-dialog/send-board-status-dialog.component';

@Component({
  selector: 'app-project-phases',
  templateUrl: './project-phases.component.html',
  styleUrls: ['./project-phases.component.css']
})
export class ProjectPhasesComponent implements OnInit {

  displayedColumns: string[] = ['title', 'state', 'boards', 'deploy', 'actions'];
  dataSource:MatTableDataSource<ProjectPhase> = new MatTableDataSource<ProjectPhase>();

  // dades per el paginator
  length:number = 0;
  pageSize:number = 5;
  pageSizeOptions:number[] = [5,10];
  pageIndex:number = 1;
  project_id:string;
  filterForm:UntypedFormGroup;
  userRole = this.userService.getCurrentUser().role.slug;
  project:any = null as any;
  private formPersistence:any;
  project_phase_stages:ProjectPhaseStage[] = [];

  constructor(private fb:UntypedFormBuilder,
              private activatedRoute:ActivatedRoute,
              private api:ApiService,
              private router:Router,
              private utils:UtilsService,
              private userService:UserService,
              public permissions:PermissionsService,
              private dialog:MatDialog) {
    this.filterForm = this.fb.group({
      search: [this.activatedRoute.snapshot.queryParamMap.get('search')!=null ? this.activatedRoute.snapshot.queryParamMap.get('search') : '', Validators.compose([Validators.minLength(3)])],
      per_page: [this.activatedRoute.snapshot.queryParamMap.get('per_page')!=null ? +(this.activatedRoute.snapshot.queryParamMap.get('per_page') as any) : this.pageSize, Validators.required],
      page: [this.activatedRoute.snapshot.queryParamMap.get('page')!=null ? +(this.activatedRoute.snapshot.queryParamMap.get('page') as any) : this.pageIndex, Validators.required],
      total: [this.activatedRoute.snapshot.queryParamMap.get('total')!=null ? +(this.activatedRoute.snapshot.queryParamMap.get('total') as any) : this.length],
      project_phase_stage_id: [this.activatedRoute.snapshot.queryParamMap.get('project_phase_stage_id')!=null ? +(this.activatedRoute.snapshot.queryParamMap.get('project_phase_stage_id') as any) : ''],
    });

  }

  ngOnInit(): void {
    this.initFilterFormListener();

    if(this.activatedRoute.snapshot.paramMap.get('project_id')!=null) this.project_id = this.activatedRoute.snapshot.paramMap.get('project_id') as string;
    else if(this.activatedRoute.parent?.snapshot.paramMap.get('project_id')) this.project_id = this.activatedRoute.parent?.snapshot.paramMap.get('project_id') as string;

    this.listenQueryParameters();
    this.fetchProject();
    this.fetchProjectPhaseStages();
  }

  changePage(event:PageEvent) {
    this.filterForm.patchValue({
      page: event.pageIndex,
      per_page: event.pageSize
    });
  }

  openDeployNotificationsDialog(project_phase_id:number) {
    const dialogRef = this.dialog.open(ProjectPhaseDeployConfigurationDialogComponent, {
      width: '600px',
      data: {
        project_phase_id: project_phase_id,
        company_id: this.project.company_id
      }
    });

    dialogRef.afterClosed().subscribe(
      data => {
        this.fetchProjectPhases();
      }
    );
  }

  private fetchProjectPhases() {
    this.api.getProjectPhasesPaged({...this.filterForm.value, project_id: this.project_id, with: 'contract,project_phase_stage,project_deploy_weekly_notification_configuration..user_notifiables,project_deploy_weekly_notification_configuration..company_notification_channels', boards: true}).subscribe(
      data => {
        this.dataSource.data = data.data;

        this.length = +data.meta.total;
        this.pageIndex = +data.meta.current_page-1;
        this.pageSize = +data.meta.per_page;
      }
    );
  }

  private fetchProject() {
    this.api.getProject(this.project_id).subscribe(
      data => {
        this.project = data;
      }
    );
  }

  private initFilterFormListener() {
    this.project_id = this.activatedRoute.parent?.snapshot.paramMap.get('id') as string;
    this.filterForm.valueChanges.subscribe(
      data => {
        data.per_page = +data.per_page;
        if(this.formPersistence==null || JSON.stringify(this.formPersistence)!=JSON.stringify(data)) {
          if(this.formPersistence.per_page!=data.per_page) data.page = 0;
          if(this.userRole == "worker"){
            this.router.navigate(['/employees', 'development', 'projects', this.project_id, 'phases'], { queryParams: data });
          }
          else if(this.userRole == "client"){
            this.router.navigate(['/clients', 'projects', this.project_id, 'phases'], { queryParams: data });
          }

        }
      }
    );
  }

  private listenQueryParameters() {
    this.activatedRoute.queryParams.subscribe(
      params => {
        if(JSON.stringify(params)!==JSON.stringify(this.filterForm.value)) { //si no ve de filtre s'ha de setejar el form
          let params_temp = this.utils.cloneObj(params); //params es read_only
          Object.keys(params_temp).forEach(param_key => {
            if(params_temp[param_key]!=null && params_temp[param_key]!="" && !isNaN(+params_temp[param_key])) params_temp[param_key] = +params_temp[param_key]; // si es numero, el transformem
          });
          this.filterForm.patchValue(params_temp, { emitEvent: false });
        }
        this.formPersistence = params;
        this.fetchProjectPhases();
      }
    );
  }

  fetchProjectPhaseStages() {
    this.api.getProjectPhaseStages().subscribe(data => {
      this.project_phase_stages = data;
    });
  }

  openEditProjectPhaseDialog(projectPhase:ProjectPhase) {
    const dialogRef = this.dialog.open(ProjectPhaseEditDialogComponent, {
      width: '600px',
      data: {
        'project_phase' : projectPhase
      }
    });

    dialogRef.afterClosed().subscribe((data:any) => {
      if(data!=null) {
        this.updateProjectPhase(projectPhase.id, data);
      }
    });
  }

  updateProjectPhase(projectPhaseId:number, data:any) {
    this.api.updateProjectPhase(projectPhaseId, data).subscribe(
      data => {
        this.fetchProjectPhases();
      }
    );
  }

  openSendBoardStatusDialog(project_phase_id: number) {
    this.dialog.open(SendBoardStatusDialogComponent, {
      width: '600px',
      data: {
        project_phase_id: project_phase_id,
        company_id: this.project.company_id
      }
    });
  }

}
