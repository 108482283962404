<h1 mat-dialog-title>Añadir nueva empresa</h1>
<mat-dialog-content [formGroup]="companyForm">
  <div class="container">
    <div class="row">
      <div class="col-12 col-lg-4">
        <mat-form-field appearance="outline">
          <mat-label>Correo electrónico</mat-label>
          <input type="email" matInput formControlName="email">
        </mat-form-field>
      </div>
      <div class="col-12 col-lg-4">
        <mat-form-field appearance="outline">
          <mat-label>Teléfono móvil</mat-label>
          <input type="tel" matInput placeholder="+34.666 555 444" formControlName="phone">
          <mat-icon matSuffix>phone</mat-icon>
          <mat-hint>Formato deseado: +xx.xxx xxx xxx</mat-hint>
        </mat-form-field>
      </div>
      <div class="col-12 col-lg-4 mt-2">
        <mat-checkbox formControlName="create_user">Crear usuario</mat-checkbox>
      </div>

    </div>


    <h2 class="dialog-section">Información de facturación</h2>
    <div class="row">
      <div class="col-12 col-lg-4">
        <mat-form-field appearance="outline">
          <mat-label>Nombre de facturación</mat-label>
          <input type="text" matInput formControlName="fact_name">
        </mat-form-field>
      </div>
      <div class="col-12 col-lg-4">
        <mat-form-field appearance="outline">
          <mat-label>NIF/CIF</mat-label>
          <input type="text" matInput formControlName="vat">
        </mat-form-field>
      </div>
      <div class="col-12 col-lg-4">
        <mat-form-field appearance="outline">
          <mat-label>Dirección de facturación</mat-label>
          <input type="text" matInput formControlName="fact_address">
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-lg-4">
        <mat-form-field appearance="outline">
          <mat-label>Población/Ciudad</mat-label>
          <input type="text" matInput formControlName="fact_city">
        </mat-form-field>
      </div>
      <div class="col-12 col-lg-4">
        <mat-form-field appearance="outline">
          <mat-label>Código postal</mat-label>
          <input type="text" matInput formControlName="fact_zipcode">
        </mat-form-field>
      </div>
      <div class="col-12 col-lg-4">
        <mat-form-field appearance="outline">
          <mat-label>Província</mat-label>
          <input type="text" matInput formControlName="fact_county">
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-lg-4">
        <mat-form-field appearance="outline">
          <mat-label>País</mat-label>
          <mat-select formControlName="fact_country">
            @for (c of countries; track c) {
              <mat-option [value]="c.name">{{ c.name }}</mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button (click)="onNoClick()" cdkFocusInitial type="button">Cancelar</button>
  <button mat-button [mat-dialog-close]="companyForm.value" [disabled]="!companyForm.valid" type="submit">
    <mat-icon matPrefix>add_user</mat-icon>
    Crear empresa
  </button>
</mat-dialog-actions>
