<div class="container">
  <div class="row">
    <div class="col-12">
      @for (a of alerts; track a) {
        <div class="alert alert-danger" role="alert">
          Recuerda entrar la factura de {{a.provider.name}}
        </div>
      }
    </div>
  </div>
</div>


<ng-container [formGroup]="filterForm">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="page-title">
          <h1>Resumen de facturación</h1>

          <mat-form-field appearance="outline">
            <mat-label>Período</mat-label>
            <mat-select formControlName="period">
              @for (period of periods; track period) {
                <mat-option [value]="period">{{ period }}</mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>
</ng-container>
