<div class="page-title">
  <h1>{{ business_mail!=null ? 'OVH: ' + business_mail.email : 'Cargando...' }}</h1>
</div>

<h2>Servidor Exchange</h2>
@if (serverFormGroup!=null) {
  <div class="card" [formGroup]="serverFormGroup">
    <div class="container">
      <div class="row">
        <div class="col-12 col-lg-3">
          <mat-form-field appearance="outline">
            <mat-label>Nombre</mat-label>
            <input matInput formControlName="displayName" />
          </mat-form-field>
        </div>
        <div class="col-12 col-lg-3">
          <mat-checkbox formControlName="complexityEnabled">Complejidad</mat-checkbox>
        </div>
        <div class="col-12 col-lg-3">
          <mat-form-field appearance="outline">
            <mat-label>Lockout duration</mat-label>
            <input matInput formControlName="lockoutDuration" />
          </mat-form-field>
        </div>
        <div class="col-12 col-lg-3">
          <mat-form-field appearance="outline">
            <mat-label>Lockout Observation Window</mat-label>
            <input matInput formControlName="lockoutObservationWindow" />
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-lg-3">
          <mat-form-field appearance="outline">
            <mat-label>Lockout Threshold</mat-label>
            <input matInput formControlName="lockoutThreshold" />
          </mat-form-field>
        </div>
        <div class="col-12 col-lg-3">
          <mat-form-field appearance="outline">
            <mat-label>Longitud mínima de contraseña</mat-label>
            <input matInput formControlName="minPasswordLength" />
          </mat-form-field>
        </div>
        <div class="col-12 col-lg-3">
          <mat-form-field appearance="outline">
            <mat-label>Edad mínima de contraseña</mat-label>
            <input matInput formControlName="minPasswordAge" />
          </mat-form-field>
        </div>
        <div class="col-12 col-lg-3">
          <mat-form-field appearance="outline">
            <mat-label>Edad máxima de contraseña</mat-label>
            <input matInput formControlName="maxPasswordAge" />
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-lg-3">
          <mat-form-field appearance="outline">
            <mat-label>Tamaño máximo de los mensajes entrantes</mat-label>
            <input matInput formControlName="maxReceiveSize" />
          </mat-form-field>
        </div>
        <div class="col-12 col-lg-3">
          <mat-form-field appearance="outline">
            <mat-label>Tamaño máximo de los mensajes salientes</mat-label>
            <input matInput formControlName="maxSendSize" />
          </mat-form-field>
        </div>
        <div class="col-12 col-lg-3">
          <mat-form-field appearance="outline">
            <mat-label>Contador de versiones de contraseñas</mat-label>
            <input matInput formControlName="passwordHistoryCount" />
          </mat-form-field>
        </div>
      </div>
      <ng-container [formGroup]="serverVirusForm">
        <div class="row">
          <div class="col-12 col-lg-3">
            <mat-checkbox formControlName="checkDKIM">Comprobar DKIM</mat-checkbox>
          </div>
          <div class="col-12 col-lg-3">
            <mat-checkbox formControlName="checkSPF">Comprobar SPF</mat-checkbox>
          </div>
          <div class="col-12 col-lg-3">
            <mat-checkbox formControlName="deleteSpam">Eliminar Spam</mat-checkbox>
          </div>
          <div class="col-12 col-lg-3">
            <mat-checkbox formControlName="deleteVirus">Eliminar Virus</mat-checkbox>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-lg-3">
            <mat-checkbox formControlName="putInJunk">Trasladar a Spam</mat-checkbox>
          </div>
          <div class="col-12 col-lg-3">
            <mat-checkbox formControlName="tagSpam">Etiquetar spam</mat-checkbox>
          </div>
          <div class="col-12 col-lg-3">
            <mat-checkbox formControlName="tagVirus">Etiquetar virus</mat-checkbox>
          </div>
        </div>
      </ng-container>
      <div class="row" style="margin-top: 20px;">
        <div class="col-12">
          <button mat-flat-button [disabled]="!serverFormGroup.valid && !permissions.validateDepartment(['direction', 'sysadmin'])" color="accent" (click)="submitExchangeServer()">
            <mat-icon matPrefix>save</mat-icon>
            Guardar servidor Exchange
          </button>
        </div>
      </div>
    </div>
  </div>
}

<h2>Cuenta de Exchange</h2>
<div class="card" [formGroup]="accountFormGroup">
  <div class="container">
    <div class="row">
      <div class="col-12 col-lg-3">
        <mat-form-field appearance="outline">
          <mat-label>Licencia*</mat-label>
          <mat-select formControlName="accountLicense">
            <mat-option value="basic">Basic</mat-option>
            <mat-option value="enterprise">Enterprise</mat-option>
            <mat-option value="standard">Standard</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-12 col-lg-3">
        <mat-form-field appearance="outline">
          <mat-label>Nombre a mostrar</mat-label>
          <input matInput formControlName="displayName">
        </mat-form-field>
      </div>
      <div class="col-12 col-lg-3">
        <mat-form-field appearance="outline">
          <mat-label>Descripción</mat-label>
          <input matInput formControlName="description">
        </mat-form-field>
      </div>
      <div class="col-12 col-lg-3">
        <mat-form-field appearance="outline">
          <mat-label>Login</mat-label>
          <input matInput formControlName="login">
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-lg-3">
        <mat-form-field appearance="outline">
          <mat-label>Dominio</mat-label>
          <input matInput formControlName="domain">
        </mat-form-field>
      </div>
      <div class="col-12 col-lg-3">
        <mat-form-field appearance="outline">
          <mat-label>Compañia</mat-label>
          <input matInput formControlName="company">
        </mat-form-field>
      </div>
      <div class="col-12 col-lg-3">
        <mat-form-field appearance="outline">
          <mat-label>Nombre</mat-label>
          <input matInput formControlName="firstName">
        </mat-form-field>
      </div>
      <div class="col-12 col-lg-3">
        <mat-form-field appearance="outline">
          <mat-label>Apellidos</mat-label>
          <input matInput formControlName="lastName">
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-lg-3">
        <mat-form-field appearance="outline">
          <mat-label>Dirección</mat-label>
          <input matInput formControlName="streetAddress">
        </mat-form-field>
      </div>
      <div class="col-12 col-lg-3">
        <mat-form-field appearance="outline">
          <mat-label>Código postal</mat-label>
          <input matInput formControlName="postalCode">
        </mat-form-field>
      </div>
      <div class="col-12 col-lg-3">
        <mat-form-field appearance="outline">
          <mat-label>Oficina</mat-label>
          <input matInput formControlName="office">
        </mat-form-field>
      </div>
      <div class="col-12 col-lg-3">
        <mat-form-field appearance="outline">
          <mat-label>Ciudad</mat-label>
          <input matInput formControlName="city">
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-lg-3">
        <mat-form-field appearance="outline">
          <mat-label>Móvil</mat-label>
          <input matInput formControlName="mobile">
        </mat-form-field>
      </div>
      <div class="col-12 col-lg-3">
        <mat-form-field appearance="outline">
          <mat-label>Teléfono</mat-label>
          <input matInput formControlName="phone">
        </mat-form-field>
      </div>
      <div class="col-12 col-lg-3">
        <mat-form-field appearance="outline">
          <mat-label>Fax</mat-label>
          <input matInput formControlName="fax">
        </mat-form-field>
      </div>
      <div class="col-12 col-lg-3">
        <mat-form-field appearance="outline">
          <mat-label>País</mat-label>
          <mat-select formControlName="countryCode">
            @for (c of countries; track c) {
              <mat-option [value]="c.alpha_2">{{ c.name }}</mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-lg-3">
        <mat-form-field appearance="outline">
          <mat-label>Departamento de trabajo</mat-label>
          <input matInput formControlName="jobDepartment">
        </mat-form-field>
      </div>
      <div class="col-12 col-lg-3">
        <mat-form-field appearance="outline">
          <mat-label>Posición de trabajo</mat-label>
          <input matInput formControlName="jobTitle">
        </mat-form-field>
      </div>
      <div class="col-12 col-lg-3">
        <mat-checkbox formControlName="hiddenFromGAL">No mostrado en GAL</mat-checkbox>
      </div>
      <div class="col-12 col-lg-3">
        <mat-checkbox formControlName="litigation">Litigación</mat-checkbox>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-lg-3">
        <mat-form-field appearance="outline">
          <mat-label>Período de litigación</mat-label>
          <input matInput formControlName="litigationPeriod">
        </mat-form-field>
      </div>
      <div class="col-12 col-lg-3">
        <mat-form-field appearance="outline">
          <mat-label>Filtros de correo activados</mat-label>
          <mat-select formControlName="mailingFilter" multiple>
            <mat-option value="vaderetro">Vaderetro</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-12 col-lg-3">
        <mat-checkbox formControlName="outlookLicense">Licencia Outlook</mat-checkbox>
      </div>
      <div class="col-12 col-lg-3">
        <mat-form-field appearance="outline">
          <mat-label>Cuota (en GB)</mat-label>
          <input matInput formControlName="quota">
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-lg-3">
        <mat-checkbox formControlName="deleteAtExpiration">Eliminar al expirar</mat-checkbox>
      </div>
      <div class="col-12 col-lg-3">
        <mat-checkbox formControlName="deleteOutlookAtExpiration">Eliminar Outlook al expirar</mat-checkbox>
      </div>
      <div class="col-12 col-lg-3">
        <mat-form-field appearance="outline">
          <mat-label>Período de renovación</mat-label>
          <mat-select formControlName="renewPeriod">
            <mat-option value="">-</mat-option>
            <mat-option value="monthly">Mensual</mat-option>
            <mat-option value="yearly">Anual</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-12 col-lg-3">
        <mat-form-field appearance="outline">
          <mat-label>Período de renovación (Outlook)</mat-label>
          <mat-select formControlName="renewOutlookPeriod">
            <mat-option value="">-</mat-option>
            <mat-option value="monthly">Mensual</mat-option>
            <mat-option value="yearly">Anual</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <ng-container [formGroup]="accountVirusForm">
      <div class="row">
        <div class="col-12 col-lg-3">
          <mat-checkbox formControlName="checkDKIM">Comprobar DKIM</mat-checkbox>
        </div>
        <div class="col-12 col-lg-3">
          <mat-checkbox formControlName="checkSPF">Comprobar SPF</mat-checkbox>
        </div>
        <div class="col-12 col-lg-3">
          <mat-checkbox formControlName="deleteSpam">Eliminar Spam</mat-checkbox>
        </div>
        <div class="col-12 col-lg-3">
          <mat-checkbox formControlName="deleteVirus">Eliminar Virus</mat-checkbox>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-lg-3">
          <mat-checkbox formControlName="putInJunk">Trasladar a Spam</mat-checkbox>
        </div>
        <div class="col-12 col-lg-3">
          <mat-checkbox formControlName="tagSpam">Etiquetar spam</mat-checkbox>
        </div>
        <div class="col-12 col-lg-3">
          <mat-checkbox formControlName="tagVirus">Etiquetar virus</mat-checkbox>
        </div>
      </div>
    </ng-container>
    <div class="row">
      <div class="col-12">
        <button mat-flat-button color="accent" [disabled]="!accountFormGroup.valid && !permissions.validateDepartment(['direction', 'sysadmin'])" (click)="submitExchangeAccount()">
          <mat-icon matPrefix>save</mat-icon>
          Guardar cuenta exchange
        </button>
      </div>
    </div>
  </div>
</div>