<div id="content">

  <div class="page-title">
    <h1>{{ titleCategory }}</h1>
    <button mat-flat-button color="accent" (click)="save()" [disabled]="!categoryForm.valid">
      <mat-icon style="margin-right: 10px">save</mat-icon>
      Guardar
    </button>
  </div>

  <form class="card mb-5" [formGroup]="categoryForm">
    <div class="container" style="margin-top: 20px">
      <div class="row">
        <div class="col-12 col-lg-4">
          <mat-form-field appearance="outline">
            <mat-label>Nombre</mat-label>
            <input type="text" matInput formControlName="name">
          </mat-form-field>
        </div>
        <div class="col-12 col-lg-8">
          <mat-form-field appearance="outline">
            <mat-label>Descripción</mat-label>
              <input type="text" matInput formControlName="description">
          </mat-form-field>
        </div>
        <div class="col-12 col-lg-4">
          <mat-label style="padding-left: 10px;">Salario Mínimo Anual:</mat-label>
          <mat-form-field appearance="outline">
            <input type="number" matInput formControlName="min_salary">
            <span matSuffix>€</span>
          </mat-form-field>
        </div>

        <div class="col-12 col-lg-4">
          <mat-label style="padding-left: 10px;">Salario Máximo Anual:</mat-label>
          <mat-form-field appearance="outline">
            <input type="number" matInput formControlName="max_salary">
            <span matSuffix>€</span>
          </mat-form-field>
        </div>

      </div>
    </div>
  </form>

</div>
