import { Component } from '@angular/core';
import { UtilsService } from './services/utils.service';

import * as moment from 'node_modules/moment';
import 'moment/min/locales';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'bisual-workers';
  public loading:boolean = false;

  constructor(private utils:UtilsService) {
    this.startListeningLoadingStatus();
    this.initMomentLocale();
  }

  private startListeningLoadingStatus() {
    this.utils.loadingObservable.subscribe(
      data => {
        this.loading = data;
      }
    );
  }

  private initMomentLocale() {
    moment().locale('es');
  }

}
