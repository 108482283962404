<div class="page-title">
  <h1>{{ domain!=null ? domain.domain : 'Cargando...' }}</h1>

  @if (domain != null) {
    <button mat-stroked-button [matMenuTriggerFor]="menuOther">
      Acciones
      <mat-icon matSuffix>arrow_drop_down</mat-icon>
    </button>
  }
  <mat-menu #menuOther="matMenu">
    @if (domain!=null && domain.status=='active') {
      <button mat-menu-item (click)="cancelDomain(domain)" matTooltip="Cancelará el dominio y esperará a eliminarlo a que expire." [disabled]="!permissions.validateDepartment(['direction', 'sysadmin'])">
        <mat-icon style="margin-right: 10px">block</mat-icon>
        Cancelar dominio
      </button>
    }
    @if (domain!=null && domain.cloudflare_zone!=null) {
      <button mat-menu-item (click)="openInCloudflare()" [disabled]="!permissions.validateDepartment(['direction', 'sysadmin'])">
        <mat-icon matPrefix>visibility</mat-icon>
        Ver en Cloudflare
      </button>
    }
    @if (domain!=null && domain.godaddy_domain!=null && !domain.is_fact_external) {
      <button mat-menu-item (click)="openInGodaddy()" [disabled]="!permissions.validateDepartment(['direction', 'sysadmin'])">
        <mat-icon matPrefix>visibility</mat-icon>
        Ver en Godaddy
      </button>
    }
  </mat-menu>
</div>

@if (domain!=null) {
  <div class="container">
    <div class="row">
      <div class="col-12 col-lg-4">
        <div class="card action-card">
          <mat-icon>info</mat-icon>
          <div class="card-header">
            <div class="card-title">
              <h2>Estado del dominio</h2>
              <div class="subtitle">
                <div class="status-point" [ngClass]="domain.status" style="margin-right: 5px;"></div>
                {{ getDomainStatusTooltip(domain.status) }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-4">
        <div class="card action-card">
          <mat-icon>dns</mat-icon>
          <div class="card-header">
            <div class="card-title">
              <h2>Proveedor de registro</h2>
              <p class="subtitle">{{ domain.provider.name }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-4">
        <div class="card action-card">
          <mat-icon>maps_home_work</mat-icon>
          <div class="card-header">
            <div class="card-title">
              <h2>Propiedad</h2>
              <div class="subtitle">
                @if (domain.company_id != null) {
                  <a [routerLink]="['/employees', 'direction', 'users-management', 'companies', domain.company.id]">{{ domain.company.fact_name }}</a>
                }
                @if (domain.company_id==null) {
                  <span>Bisual</span>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
}

@if (domain!=null) {
  <div class="bisual-two-col-masonry" style="margin-top: 50px;">
    @if (domain.stripe_id != null) {
      <div class="card">
        <div class="card-header">
          <div class="card-title">
            <h2>Suscripción Stripe</h2>
            <p class="subtitle">Información de la suscripción a Stripe con la que se factura al cliente.</p>
          </div>
        </div>
        <div class="container">
          <div class="row">
            <div class="col-12 col-md-6">
              <b>Estado</b>: {{ domain.status }}
            </div>
            <div class="col-12 col-md-6">
              <b>Fecha de expiración</b>: {{ domain.expiration_date | date:'shortDate' }}
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-6">
              <b>Período de renovación</b>: {{ domain.renewal_days_period + ' días' }}
            </div>
            <div class="col-12 col-md-6">
              <b>Período de prueba</b>: {{ domain.trial_ends_at!=null ? ('Hasta ' + (domain.trial_ends_at | date:'shortDate')) : '-' }}
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-6">
              <b>Facturado externamente</b>: {{ domain.is_fact_external ? 'Sí' : 'No' }}
            </div>
            <div class="col-12 col-md-6">
              <b>Fecha de cancelación</b>: {{ domain.ends_at!=null ? ('Hasta el ' + (domain.ends_at | date:'shortDate')) : '-' }}
            </div>
          </div>
        </div>
      </div>
    }
    @if (domain.godaddy_domain != null) {
      <div class="card">
        <div class="card-header">
          <div class="card-title">
            <h2>Información de Godaddy</h2>
            <p class="subtitle">Información recibida de Godaddy ahora mismo.</p>
          </div>
        </div>
        <div class="container">
          <div class="row">
            <div class="col-12 col-md-6">
              <b>Auth Code</b>: {{ domain.godaddy_domain.authCode }}
            </div>
            <div class="col-12 col-md-6">
              <b>Creado en</b>: {{ domain.godaddy_domain.createdAt | date:'short' }}
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-6">
              <b>Identificador</b>: #{{ domain.godaddy_domain.domainId }}
            </div>
            <div class="col-12 col-md-6">
              <b>Expira</b>: {{ domain.godaddy_domain.expires | date:'short' }}
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-6">
              <b>Bloqueado</b>: {{ domain.godaddy_domain.locked ? 'Sí' : 'No' }}
            </div>
            <div class="col-12 col-md-6">
              <b>Nombre de servidores</b>: {{ domain.godaddy_domain.nameServers | concat }}
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-6">
              <b>Privacidad</b>: {{ domain.godaddy_domain.privacy ? 'Sí' : 'No' }}
            </div>
            <div class="col-12 col-md-6">
              <b>Estado</b>: {{ domain.godaddy_domain.status | titlecase }}
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-6">
              <b>Autorrenovación</b>: {{ domain.godaddy_domain.renewAuto ? 'Sí' : 'No' }}
            </div>
            <div class="col-12 col-md-6">
              <b>Autorrenovación límite</b>: {{ domain.godaddy_domain.renewDeadline | date:'short' }}
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-6">
              <b>Protegido de transferencias</b>: {{ domain.godaddy_domain.transferProtected ? 'Sí' : 'No' }}
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-6">
              <b>Contacto Admin</b>: {{ domain.godaddy_domain.contactAdmin!=null ? domain.godaddy_domain.contactAdmin.nameFirst + ' ' + domain.godaddy_domain.contactAdmin.nameLast : '-' }}
            </div>
            <div class="col-12 col-md-6">
              <b>Contacto Facturación</b>: {{ domain.godaddy_domain.contactBilling!=null ? domain.godaddy_domain.contactBilling.nameFirst + ' ' + domain.godaddy_domain.contactBilling.nameLast : '-' }}
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-6">
              <b>Contacto Tech</b>: {{ domain.godaddy_domain.contactTech!=null ? domain.godaddy_domain.contactTech.nameFirst + ' ' + domain.godaddy_domain.contactTech.nameLast : '-' }}
            </div>
            <div class="col-12 col-md-6">
              <b>Contacto Registrador</b>: {{ domain.godaddy_domain.contactRegistrant!=null ? domain.godaddy_domain.contactRegistrant.nameFirst + ' ' + domain.godaddy_domain.contactRegistrant.nameLast : '-' }}
            </div>
          </div>
        </div>
      </div>
    }
    @if (domain.godaddy_domain != null) {
      <div class="card">
        <div class="card-header">
          <div class="card-title">
            <h2>Información de Cloudflare</h2>
            <p class="subtitle">Información recibida de Cloudflare ahora mismo.</p>
          </div>
        </div>
        @if (domain.cloudflare_zone == null) {
          <div class="container">
            <div class="row">
              <div class="col-12">No tienes el dominio conectado a Cloudflare. Puedes conectarlo desde la <a [routerLink]="['/employees', 'development', 'services', 'domains', domain.id, 'dns']">pestaña de DNS</a>.</div>
            </div>
          </div>
        }
        @if (domain.cloudflare_zone != null) {
          <div class="container">
            <div class="row">
              <div class="col-12 col-md-6">
                <b>Identificador</b>: #{{ domain.cloudflare_zone.id }}
              </div>
              <div class="col-12 col-md-6">
                <b>Creado en</b>: {{ domain.cloudflare_zone.created_on | date:'shortDate' }}
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-6">
                <b>Modificado en</b>: #{{ domain.cloudflare_zone.modified_on | date:'shortDate' }}
              </div>
              <div class="col-12 col-md-6">
                <b>Activado en</b>: {{ domain.cloudflare_zone.activated_on | date:'shortDate' }}
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-6">
                <b>DNS originales</b>: #{{ domain.cloudflare_zone.original_name_servers | concat }}
              </div>
              <div class="col-12 col-md-6">
                <b>DNS</b>: {{ domain.cloudflare_zone.name_servers | concat }}
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-6">
                <b>Plan</b>: #{{ domain.cloudflare_zone.plan.name + ' (' + (domain.cloudflare_zone.plan.price | currency:domain.cloudflare_zone.plan.currency) + ')' }}
              </div>
              <div class="col-12 col-md-6">
                <b>Estado</b>: {{ domain.cloudflare_zone.status | titlecase }}
              </div>
            </div>
          </div>
        }
      </div>
    }
    <div class="card">
      <div class="card-header">
        <div class="card-title">
          <h2>Proyectos</h2>
          <p class="subtitle">Proyectos asociados a este dominio</p>
        </div>
        @if (domain.projects.length==0) {
          <p>No hay proyectos asociados a este dominio.</p>
        }
        @if (domain.projects.length>0) {
          <div class="bisual-table">
            <div class="bisual-table-row-header">
              <div class="bisual-table-col">Nombre</div>
              <div class="bisual-table-col">Propietario</div>
              <div class="bisual-table-col">Responsable</div>
              <div class="bisual-table-col">Estado</div>
            </div>
            @for (p of domain.projects; track p) {
              <div class="bisual-table-row" >
                <div class="bisual-table-col">
                  <a [routerLink]="['/employees', 'development', 'projects', p.id]">{{ p.title }}</a>
                </div>
                <div class="bisual-table-col">{{ p.company.fact_name }}</div>
                <div class="bisual-table-col">{{ '?' }}</div>
                <div class="bisual-table-col">{{ p.active_project_phase.project_phase_stage.title }} ({{ p.active_project_phase.title }})</div>
              </div>
            }
          </div>
        }
      </div>
    </div>
  </div>
}
