import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { debounceTime, map, Observable, startWith } from 'rxjs';
import { CountryZone } from 'src/app/models/country_zone.model';
import { User } from 'src/app/models/user.model';
import { WorkingContractYearCondition } from 'src/app/models/working_contract_year_condition.model';
import { ApiService } from 'src/app/services/api.service';
import { UserService } from 'src/app/services/user.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-employee-calendar-festivities-dialog',
  templateUrl: './employee-calendar-festivities-dialog.component.html',
  styleUrl: './employee-calendar-festivities-dialog.component.css'
})
export class EmployeeCalendarFestivitiesDialogComponent implements OnInit {

  currentYear: number = (new Date()).getFullYear();

  dataSource:MatTableDataSource<WorkingContractYearCondition> = new MatTableDataSource();
  displayedColumns: string[] = ['working_contract_id', 'year', 'holidays_calendar_country_zone_id'];

  private initial_country_zones:CountryZone[] = [];
  country_zones:CountryZone[] = [];

  autocompleteSearchInputControls:FormControl[] = [];

  constructor(
    public dialogRef: MatDialogRef<EmployeeCalendarFestivitiesDialogComponent>,
    private _api: ApiService,
    private _utils: UtilsService,
    private _userService:UserService,
    @Inject(MAT_DIALOG_DATA) public data:{user_id:number|undefined},
    private _snack:MatSnackBar,
    private _fb: FormBuilder,
    private cd: ChangeDetectorRef
    ) {

    }

  ngOnInit(): void {
    this.initUserId();
    this.fetchMyWorkingContractYearConditions();
  }

  onAutocompleteClickCountryZone(evt:MatAutocompleteSelectedEvent, idx:number) {
    this.dataSource.data[idx].holidays_calendar_country_zone_id = (evt.option.value as CountryZone).id;
    this.dataSource.data[idx].holidays_calendar_country_zone = (evt.option.value as CountryZone);

    this._api.updateWorkingContractYearCondition(this.dataSource.data[idx].id, { holidays_calendar_country_zone_id: (evt.option.value as CountryZone).id }).subscribe(
      data => {

      }
    );
  }

  displayFn(cz: CountryZone): string {
    return cz.name ?? '';
  }

  private initUserId() {
    if(!this.data.user_id) {
      this.data.user_id = this._userService.getCurrentUser().id;
    }
  }

  private fetchMyWorkingContractYearConditions() {
    this._api.getUserWorkingContractYearConditions(this.data.user_id!, { order_by: 'year', order_by_direction: 'desc', with: 'holidays_calendar_country_zone' }).subscribe(
      async data => {
        this.autocompleteSearchInputControls = data.map(item => {
          const fc = new FormControl(item.holidays_calendar_country_zone.name);
          fc.patchValue(item.holidays_calendar_country_zone.name);
          if(item.year < this.currentYear || (item.year == this.currentYear && item.holidays_calendar_country_zone_id != null)) fc.disable();
          return fc;
        });
        this.dataSource.data = data;

        this.initial_country_zones = data.map(item => item.holidays_calendar_country_zone);

        this.initOnChangeInputAutocomplete();

        await this._utils.sleep(1000);
        this.cd.detectChanges();
      }
    );
  }

  private initOnChangeInputAutocomplete() {
    this.autocompleteSearchInputControls.forEach((fc:FormControl) => {
      fc.valueChanges.pipe(debounceTime(500)).subscribe(
        (val:string) => {
          if(val.length > 3) {
            // busquem contra API
            this._api.getCountryZones({name: val}).subscribe(
              data => {
                this.country_zones = data;
              }
            );
          }
          else {
            this.country_zones = this.initial_country_zones.slice();
          }
        }
      )
    });
  }

}
