<ng-container [formGroup]="filterForm">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="page-title">
          <h1>Ingresos</h1>

          <button mat-flat-button color="accent" (click)="addOrder()">
            <mat-icon matPrefix>add_user</mat-icon>
            Añadir nuevo ingreso
          </button>

          <mat-form-field appearance="outline">
            <mat-label>Período</mat-label>
            <mat-select formControlName="period">
              @for (period of periods; track period) {
                <mat-option [value]="period">{{ period }}</mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>

  <div class="bisual-table">
    <div class="card paginator">
      <mat-paginator
        [length]="length"
        [pageSize]="pageSize"
        [pageSizeOptions]="pageSizeOptions"
        [pageIndex]="pageIndex"
        (page)="changePage($event)"
        style="margin-right: auto;"
      ></mat-paginator>

      <mat-form-field>
        <input matInput type="text" formControlName="search" placeholder="Número pedido">
        <button matSuffix mat-icon-button aria-label="Clear">
          <mat-icon>search</mat-icon>
        </button>
      </mat-form-field>
    </div>

    <div class="bisual-table-two-columns">

      <div class="card">
        <div class="field">
          <mat-label>Cliente</mat-label>
          <mat-form-field appearance="outline">
            <input [formControl]="companies_property_control" type="text" placeholder="Escribe un nombre..." matInput [matAutocomplete]="autoCompany">
            <mat-autocomplete #autoCompany="matAutocomplete">
              @for (company of companies_filtered; track company) {
                <mat-option [value]="company.fact_name" (click)="selectCompanyFilter(company)">{{company.fact_name}}</mat-option>
              }
            </mat-autocomplete>
          </mat-form-field>
        </div>

        <div class="field">
          <mat-label>Proyecto</mat-label>
          <mat-form-field appearance="outline">
            <input [formControl]="projects_property_control" type="text" placeholder="Escribe un nombre..." matInput [matAutocomplete]="autoProjects">
            <mat-autocomplete #autoProjects="matAutocomplete">
              @for (project of projects_filtered; track project) {
                <mat-option [value]="project.title" (click)="selectProjectFilter(project)">{{project.title}}</mat-option>
              }
            </mat-autocomplete>
          </mat-form-field>
        </div>

        <div class="field">
          <mat-label>Estado del pago</mat-label>
          <mat-radio-group formControlName="payment_status">
            <mat-radio-button [value]="''">Todos</mat-radio-button>
            <mat-radio-button [value]="'payed'">Pagado</mat-radio-button>
            <mat-radio-button [value]="'not-payed'">No pagado</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>

      <div class="bisual-table-container">
        <table mat-table [dataSource]="dataSource">

          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef>#</th>
            <td mat-cell *matCellDef="let order">
              <a [routerLink]="[order.id]">#{{ order.id }}</a>
            </td>
          </ng-container>

          <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef>Fecha factura</th>
            <td mat-cell *matCellDef="let order">
              {{ order.invoice.emitted_date | date:'shortDate' }}
            </td>
          </ng-container>

          <ng-container matColumnDef="payment_status">
            <th mat-header-cell *matHeaderCellDef>Estado</th>
            <td mat-cell *matCellDef="let order">
              {{ order.payment.payed_at!=null ? 'Pagado' : (order.due_date!=null && order.due_date > now ? 'Expirado' : 'No pagado') }}
            </td>
          </ng-container>

          <ng-container matColumnDef="invoice">
            <th mat-header-cell *matHeaderCellDef>Núm. factura</th>
            <td mat-cell *matCellDef="let order">
              {{ order.invoice!=null ? ('#' + order.invoice.invoice_number) : 'Sin factura' }}

              @if (order.refunded_by_id!=null) {
                <a class="refund-icon" [routerLink]="['/employees','direction', 'billing', 'orders', order.refunded_by_id]" [matTooltip]="'Se ha creado una factura rectificativa de este pedido. Haz click para verlo.'">R</a>
              }
            </td>
          </ng-container>

          <ng-container matColumnDef="company">
            <th mat-header-cell *matHeaderCellDef>Cliente</th>
            <td mat-cell *matCellDef="let order">
              <a [routerLink]="['/direction', 'users-management', 'companies', order.company.id]">
                {{ order.company.fact_name }}
              </a>
            </td>
          </ng-container>

          <ng-container matColumnDef="type">
            <th mat-header-cell *matHeaderCellDef>Tipo</th>
            <td mat-cell *matCellDef="let order">
              {{ getOrderType(order) }}
            </td>
          </ng-container>

          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let order; let i = index;">
              <button mat-icon-button [matMenuTriggerFor]="menu">
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button mat-menu-item [routerLink]="[order.id]">
                  <mat-icon matPrefix>visibility</mat-icon>
                  Ver pedido
                </button>
                <button mat-menu-item (click)="downloadInvoice(order.id)">
                  <mat-icon matPrefix>file_download</mat-icon>
                  Descargar factura
                </button>
                @if (order.payment.payed_at==null) {
                  <button mat-menu-item (click)="markAsPayed(order)">
                    <mat-icon matPrefix>paid</mat-icon>
                    Marcar como pagado
                  </button>
                }
                @if (order.total > 0 && order.payment.payed_at!=null && order.payment.has_stripe_identifier && order.refunded_by_id==null) {
                  <button mat-menu-item>
                    <mat-icon matPrefix>keyboard_return</mat-icon>
                    Reembolsar
                  </button>
                }
              </mat-menu>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      </div>
    </div>

  </div>
</ng-container>
