import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hourDecimalToHoursMinutes'
})
export class HourDecimalToHoursMinutesPipe implements PipeTransform {

  transform(value:number): string {
    // // 8.5 --> 8h 30m
    // // 10 --> 10h
    // // > 24 error

    let hour = Math.floor(Math.abs(value));
    let min = Math.floor((Math.abs(value) * 60) % 60);
    if(min == 0) return `${hour}h`;
    else return `${hour}h ${min}m`;
  }

}
