@if (form && tags) {
  <div class="row">
    <div class="col-12">
      <p class="section-title">{{ title != null ? title : 'Distribución de producto' }} - {{ (mockRealTime*4) | hourDecimalToHoursMinutes }}/mes</p>
    </div>
    @for (formGroup of nonFixedControls | keyvalue; track formGroup) {
      <ng-container [formGroup]="castToFormGroup(formGroup.value)">
        @if (!castToFormGroup(formGroup.value).controls['is_fixed'].value) {
          <div class="col-12 col-lg-3 mb-3">
            <label [for]="formGroup.key">{{ tags[formGroup.key] }}</label>
            <mat-form-field appearance="outline" [ngClass]="{'readonly': isReadonly(formGroup.key)}">
              <input matInput type="number" formControlName="prcent" [readonly]="isReadonly(formGroup.key)" min="0" max="100" />
              <mat-icon matSuffix>percent</mat-icon>
              @if (castToFormGroup(formGroup.value).controls['prcent'].hasError('sum_100')) {
                <mat-error>Todo debe sumar 100</mat-error>
              }
              @if (castToFormGroup(formGroup.value).value.prcent > 0) {
                <mat-hint>{{ (mockRealTime * (castToFormGroup(formGroup.value).value.prcent/100)) | hourDecimalToHoursMinutes }}/semana</mat-hint>
              }
            </mat-form-field>
          </div>
        }
      </ng-container>
    }
    @for (formGroup of fixedControls | keyvalue; track formGroup) {
      <ng-container [formGroup]="castToFormGroup(formGroup.value)">
        @if (castToFormGroup(formGroup.value).controls['is_fixed'].value) {
          <div class="col-12 mb-3">
            <div class="row">
              <div class="col-3">
                <label [for]="formGroup.key">{{ tags[formGroup.key] }}</label>
                <mat-form-field appearance="outline" [ngClass]="{'readonly': isReadonly(formGroup.key)}">
                  <input matInput type="number" formControlName="fixed" [readonly]="isReadonly(formGroup.key)" min="0" max="100" />
                  <span matSuffix>h</span>
                  @if (castToFormGroup(formGroup.value).controls['fixed'].hasError('sum_100')) {
                    <mat-error>Todo debe sumar 100</mat-error>
                  }
                  @if (castToFormGroup(formGroup.value).value.fixed > 0) {
                    <mat-hint>{{ (mockRealTime * (castToFormGroup(formGroup.value).value.prcent/100)) | hourDecimalToHoursMinutes }}/semana</mat-hint>
                  }
                </mat-form-field>
              </div>

              @if(!formGroup.key.startsWith("project_team")) {
                <div class="col-3 mt-4">
                  <mat-form-field appearance="outline" [ngClass]="{'readonly': isReadonly(formGroup.key)}">
                    <mat-select formControlName="periodicity">
                      @for (periodicity of periodicities; track periodicity) {
                        <mat-option [value]="periodicity.value">{{periodicity.label}}</mat-option>
                      }
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="col-3 mt-4">
                  <mat-form-field appearance="outline" [ngClass]="{'readonly': isReadonly(formGroup.key)}">
                    <input matInput type="number" formControlName="n_of_members" [readonly]="isReadonly(formGroup.key)" min="0" max="100" />
                    <span matSuffix>miembros</span>
                  </mat-form-field>
                </div>
                <!-- date picker for period_start_date -->
                <div class="col-3 mt-4">
                  <mat-form-field appearance="outline" [ngClass]="{'readonly': isReadonly(formGroup.key)}">
                    <input matInput [matDatepicker]="picker" formControlName="period_start_date" [readonly]="isReadonly(formGroup.key)" />
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                    <mat-hint>Inicio del periodo</mat-hint>
                  </mat-form-field>
                </div>
              }
              @else {
                <div class="col-3 mt-4">
                  <button mat-icon-button (click)="deleteProjectTeam(formGroup.key)">
                    <mat-icon>delete</mat-icon>
                  </button>
                </div>
              }
            </div>
          </div>
        }
      </ng-container>
    }
  </div>
  @for (formGroup of form.controls | keyvalue; track formGroup) {
    @if (castToFormGroup(formGroup.value).controls && castToFormGroup(formGroup.value).controls['children'] != null) {
      <app-project-distribution-time-dialog-level
        [form]="castToFormGroup(castToFormGroup(formGroup.value).controls['children'])"
        (formChange)="handleFormChange($event, formGroup.key)"
        [tags]="tags"
        [title]="tags[castToString(formGroup.key)]"
        [mockRealTime]="castToFormGroup(formGroup.value).value.prcent==0 ? 0 : (mockRealTime * (castToFormGroup(formGroup.value).value.prcent/100))"
      ></app-project-distribution-time-dialog-level>
    }
  }
}
