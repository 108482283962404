<h1 mat-dialog-title>Añadir nuevo Departamento</h1>
<mat-dialog-content [formGroup]="departmentForm">
  <div class="container">
    <div class="row">
      <div class="col-12 col-lg-4">
        <mat-form-field appearance="outline">
          <mat-label>Nombre</mat-label>
          <input type="text" matInput formControlName="name">
        </mat-form-field>
      </div>
      <div class="col-12 col-lg-8">
        <mat-form-field appearance="outline">
          <mat-label>Descripción</mat-label>
          <input type="text" matInput formControlName="description">
        </mat-form-field>
      </div>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button (click)="cancel()" cdkFocusInitial type="button">Cancelar</button>
  <button mat-flat-button color="accent" [mat-dialog-close]="departmentForm.value" [disabled]="!departmentForm.valid" type="submit">
      <mat-icon style="margin-right: 10px">supervised_user_circle</mat-icon>
      Crear Departamento
  </button>
</mat-dialog-actions>
