import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ContasimpleAttachment } from 'src/app/models/contasimple_attachment.model';
import { ProviderOrder } from 'src/app/models/provider_order.model';
import { ApiService } from 'src/app/services/api.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-provider-order-attachments-dialog',
  templateUrl: './provider-order-attachments-dialog.component.html',
  styleUrls: ['./provider-order-attachments-dialog.component.css']
})
export class ProviderOrderAttachmentsDialogComponent implements OnInit {

  provider_order:ProviderOrder;
  attachments:ContasimpleAttachment[] = [];

  constructor(private fb:UntypedFormBuilder,
              public dialogRef: MatDialogRef<ProviderOrderAttachmentsDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data:any,
              private api:ApiService,
              private utils:UtilsService,
              private snack:MatSnackBar) {

    this.provider_order = this.data.provider_order;

  }

  ngOnInit(): void {
    this.fetchAttachments();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  download(at:ContasimpleAttachment) {
    this.api.downloadProviderOrderAttachment(this.provider_order.id, at.id).subscribe(
      data => {
        this.utils.downloadPdfFromBlobResponse(data);
      }
    );
  }

  uploadFile(event:EventTarget|null) {
    if(event!=null) {
      const file:File|null = (event as any).files.item(0);
      if(file!=null) {
        this.api.uploadProviderOrderAttachment(this.provider_order.id, file).subscribe(
          (atts:ContasimpleAttachment[]) => {
            let newAtts = this.getUniqueFilesFromContasimpleResponse(atts);
            newAtts.forEach(newAtt => { this.attachments.push(newAtt); });
          },
          (error:HttpErrorResponse) => {
            if(error.status===413) {
              //file too large
              this.snack.open("Archivo demasiado grande", "OK", { duration: 3000 });
            }
            else this.snack.open("No se ha podido subir el archivo", "OK", { duration: 3000 })
          }
        );
      }
    }
  }

  delete(at:ContasimpleAttachment, index:number) {
    if(confirm("¿Estás seguro que quieres eliminar este archivo?")) {
      this.api.deleteProviderOrderAttachment(this.provider_order.id, at.id).subscribe(
        data => {
          this.attachments.splice(index, 1);
        }
      );
    }
  }

  private fetchAttachments() {
    this.api.getProviderOrderAttachments(this.provider_order.id).subscribe(
      data => {
        this.attachments = this.getUniqueFilesFromContasimpleResponse(data);
      }
    );
  }

  private getUniqueFilesFromContasimpleResponse(response:ContasimpleAttachment[]) {
        // n'hi poden haver de repetits... hem de filtrar
        let attachments:ContasimpleAttachment[] = [];
        let atts:{[key:number]:ContasimpleAttachment} = {};

        response.forEach(at => {
          atts[at.id] = at;
        });

        Object.keys(atts).forEach((atIndex:any) => {
          attachments.push(atts[atIndex]);
        });
        
        return attachments;
  }

}
