<h1 mat-dialog-title>Añadir nueva alerta</h1>
<mat-dialog-content [formGroup]="form">
  <div class="container">
    <div class="row">
      <div class="col-12 col-lg-6">
        <mat-form-field appearance="outline">
          <mat-label>Proveedor</mat-label>
          <input type="text" matInput [formControl]="providerSelectControl" [matAutocomplete]="provider_select_auto">
          <mat-autocomplete #provider_select_auto="matAutocomplete" [displayWith]="displayFn">
            @for (p of available_providers; track p) {
              <mat-option [value]="p" (click)="selectProvider(p)">{{ p.fact_name }}</mat-option>
            }
          </mat-autocomplete>
          <mat-icon matSuffix>expand_more</mat-icon>
        </mat-form-field>
      </div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button (click)="onNoClick()" cdkFocusInitial type="button">Cancelar</button>
  <button mat-flat-button color="accent" [mat-dialog-close]="form.value" [disabled]="!form.valid" type="submit">
    <mat-icon style="margin-right: 10px">add_user</mat-icon>
    Crear alerta
  </button>
</mat-dialog-actions>
