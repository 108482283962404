import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'projectTaskHistoryLogFieldPipe'
})
export class ProjectTaskHistoryLogFieldPipePipe implements PipeTransform {

  transform(value: string): string {
    switch(value) {
      case 'work_register':
        return 'ha registrado trabajo';
      case 'change_state':
        return 'ha cambiado el estado';
      case 'change_priority':
        return 'ha cambiado la prioridad';
      case 'change_story_points':
        return 'ha actualizado la estimación de tiempo';
      case 'change_description':
        return 'ha actualizado la descripción';
      case 'change_title':
        return 'ha actualizado el título';
      case 'change_assignee':
        return 'ha reasignado la tarea';
      case 'change_reporter':
        return 'ha cambiado el informador';
      case 'change_due_date':
        return 'ha actualizado la fecha de fin';
      case 'change_tags':
        return 'ha actualizado los tags';
      case 'add_comment':
        return 'ha añadido un comentario';
      case 'delete_comment':
        return 'ha borrado un comentario';
      case 'update_comment':
        return 'ha actualizado un comentario';
      case 'change_time_estimation':
        return 'ha actualizado la estimación de tiempo';
      case 'change_revision_time_estimation':
        return 'ha actualizado la estimación de tiempo de revisión';
      case 'create_task':
        return 'ha creado la tarea';
      case 'delete_task':
        return 'ha borrado la tarea';
      case 'change_archived':
        return 'ha actualizado el estado de archivado';
      default:
        return '';
    }
  }

}
