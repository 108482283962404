import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { ActivatedRoute, Router } from '@angular/router';
import { ClockRegister } from 'src/app/models/clock-register.model';
import { User } from 'src/app/models/user.model';
import { ApiService } from 'src/app/services/api.service';
import { PermissionsService } from 'src/app/services/permissions.service';
import { UserService } from 'src/app/services/user.service';
import { ClockRegisterService } from 'src/app/services/clock-register.service';
import { UtilsService } from 'src/app/services/utils.service';
import { AddNewClockRegisterReviewRequestDialogComponent } from './add-new-clock-register-review-request-dialog/add-new-clock-register-review-request-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-clock-registers',
  templateUrl: './clock-registers.component.html',
  styleUrls: ['./clock-registers.component.css']
})
export class ClockRegistersComponent implements OnInit {

  filterForm:UntypedFormGroup;
  private formPersistence:any;
  periods:string[];

  userControl:UntypedFormControl = new UntypedFormControl();
  userAutocompleteControl:UntypedFormControl = new UntypedFormControl();
  usersFiltered:User[] = [];

  clockRegisters:ClockRegister[] = [];

  constructor(private api:ApiService,
              private utils:UtilsService,
              private fb:UntypedFormBuilder,
              private activatedRoute:ActivatedRoute,
              private router:Router,
              private user:UserService,
              public permissions:PermissionsService,
              private clock:ClockRegisterService,
              private dialog:MatDialog,
              private datePipe: DatePipe) {

    this.filterForm = this.fb.group({
      period: [this.activatedRoute.snapshot.queryParamMap.get('period')!=null ? (this.activatedRoute.snapshot.queryParamMap.get('period') as any) : this.getCurrentPeriod()],
      user_id: [this.activatedRoute.snapshot.queryParamMap.get('user_id')!=null ? (this.activatedRoute.snapshot.queryParamMap.get('user_id') as any) : '', Validators.required],
    });

    this.periods = [this.filterForm.value['period']];
  }

  ngOnInit(): void {
    this.fetchPeriods();
    this.initFilterFormListener();
    this.listenQueryParameters();

    this.listenUserControl();
    this.listenUserAutocompleteControl();
    this.listenClockRegisterService();

    if(this.filterForm.value['user_id']!='') this.fetchUser(this.filterForm.value['user_id']);
    else this.initCurrentUser();
  }

  onUserAutocompleteSelected(event:MatAutocompleteSelectedEvent) {
    this.selectUser(event.option.value);
  }

  selectUser(user:User) {
    this.userControl.setValue(user);
  }

  displayFnUser(user:User): string {
    return user ? user.name + ' ' + user.surnames : '';
  }

  private getCurrentPeriod() {
    return new Date().getFullYear() + "/" + (new Date().getMonth()+1);
  }

  private fetchPeriods() {
    this.api.getBillingPeriods().subscribe(
      data => {
        this.periods = data;
      }
    );
  }

  private initFilterFormListener() {
    this.filterForm.valueChanges.subscribe(
      data => {
        if(this.formPersistence==null || JSON.stringify(this.formPersistence)!=JSON.stringify(data)) {
          this.router.navigate(['/employees', 'my-zone', 'clock-registers'], { queryParams: data });
        }
      }
    );
  }

  private listenQueryParameters() {
    this.activatedRoute.queryParams.subscribe(
      params => {
        if(JSON.stringify(params)!==JSON.stringify(this.filterForm.value)) { //si no ve de filtre s'ha de setejar el form
          let params_temp = this.utils.cloneObj(params); //params es read_only
          Object.keys(params_temp).forEach(param_key => {
            if(params_temp[param_key]!=null && params_temp[param_key]!="" && !isNaN(+params_temp[param_key])) params_temp[param_key] = +params_temp[param_key]; // si es numero, el transformem
          });
          this.filterForm.patchValue(params_temp, { emitEvent: false });
        }
        this.formPersistence = params;
        this.fetchClockRegisters();
      }
    );
  }

  private fetchClockRegisters() {
    if(this.filterForm.value['user_id']!=null && this.filterForm.value['user_id']!='') {
      let params:any = this.utils.cloneObj(this.filterForm.value);
      const user_id:any = params.user_id;
      delete params['user_id'];
      this.api.getClockRegistersByUserId(user_id, params).subscribe(
        data => {
          this.clockRegisters = data;
        }
      );
    }
  }

  private initCurrentUser() {
    this.user.currentUser.subscribe(
      data => {
        if(this.filterForm.value['user_id']==null || this.filterForm.value['user_id']=='') {
          this.selectUser(data);
          this.usersFiltered = [data];
          this.userAutocompleteControl.setValue(data);
        }
      }
    );
  }

  private listenUserControl() {
    this.userControl.valueChanges.subscribe(
      user => {
        this.userAutocompleteControl.setValue(user);
        this.filterForm.patchValue({user_id: user.id});
      }
    );
  }

  private listenUserAutocompleteControl() {
    this.userAutocompleteControl.valueChanges.subscribe(
      data => {
        if(data.id==null) {
          const params:any = {search: data};
          this.api.getUsers(params).subscribe(
            users => {
              this.usersFiltered = users.data;
            }
          );
        }
      }
    );
  }

  private fetchUser(id:string) {
    this.api.getUser(id).subscribe(
      data => {
        this.selectUser(data);
        this.usersFiltered = [data];
        this.userAutocompleteControl.setValue(data);

        if(!this.permissions.validateDepartment(['direction', 'rrhh'])) {
          this.userAutocompleteControl.disable();
          this.userControl.disable();
        }
      }
    );
  }

  private listenClockRegisterService() {
    this.clock.statusObservable.subscribe(
      data => {
        this.fetchClockRegisters();
      }
    );
  }

  public requestReview(id:number) {
    const dialogRef = this.dialog.open(AddNewClockRegisterReviewRequestDialogComponent, {
      width: '400px',
      height: '350px',
      data: {
        clock_register_id: id
      }
    });

    dialogRef.afterClosed().toPromise().then(
      clock_register_review_request => {
        if(clock_register_review_request !== null && clock_register_review_request !== undefined) {
          this.api.createClockRegisterReviewRequest(clock_register_review_request).subscribe( res => {
            this.fetchClockRegisters();
          });
        }
      }
    );
  }

  transformDate(date:Date) {
    return this.datePipe.transform(date, 'dd/MM/yyyy H:mm');
  }
}
