import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-options-dialog',
  templateUrl: './options-dialog.component.html',
  styleUrl: './options-dialog.component.css'
})
export class OptionsDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<OptionsDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data:OptionsDialogData) {}

  ngOnInit(): void {

  }

  submit() {
    this.dialogRef.close(this.data.data);
  }

  close() {
    this.dialogRef.close();
  }
}

export interface OptionsDialogData {
  dialog_title: string;
  dialog_ok_button: string;
  dialog_cancel_button: string;
  data:OptionsDialogDataRow[];
}

export interface OptionsDialogDataRow {
  value: string;
  textToShow: string;
  checked: boolean;
}
