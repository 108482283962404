<h1 mat-dialog-title>{{ isCreate ? 'Crear equipo de proyecto' : 'Editar equipo de proyecto' }}</h1>

<mat-dialog-content [formGroup]="form">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <mat-form-field appearance="outline">
          <mat-label>Nombre del equipo</mat-label>
          <input matInput type="text" formControlName="name" />
        </mat-form-field>
      </div>
      <div class="col-12">
        <mat-form-field appearance="outline">
          <mat-label>Usuarios en el equipo</mat-label>
          <mat-chip-grid #chipGridUsers>
            @for (user of form.value.users; track user; let i = $index) {
              <mat-chip-row (removed)="removeUser(i)">
                {{ user.name + ' ' + user.surnames }}
                <button matChipRemove>
                  <mat-icon>cancel</mat-icon>
                </button>
              </mat-chip-row>
            }
            <input placeholder="Buscar usuarios..." #usersInput [formControl]="usersSearchCtrl"
              [matChipInputFor]="chipGridUsers" [matAutocomplete]="autoUsers"/>
            <mat-autocomplete #autoUsers="matAutocomplete" (optionSelected)="selectedUser($event.option.value)">
              @for (user of filteredUsers | async; track user) {
                <mat-option [value]="user">
                  {{ user.name + ' ' + user.surnames }}
                </mat-option>
              }
            </mat-autocomplete>
          </mat-chip-grid>
        </mat-form-field>
      </div>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button (click)="onCancel()">Cancelar</button>
  <button mat-flat-button color="accent" [disabled]="!form.valid" (click)="submit()">
    {{ isCreate ? 'Crear equipo' : 'Actualizar equipo' }}
  </button>
</mat-dialog-actions>
