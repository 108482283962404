<h1 mat-dialog-title>Fiestas y vacaciones</h1>

<mat-dialog-content>
  @if (ta.holidays.length > 0) {
    <ul>
      @for (h of ta.holidays; track h) {
        <li>
          <b>{{ h.name }} ({{ h.date | date:'shortDate' }})</b>: {{ h.description }}. Zonas que celebran la festividad: <b>{{ getZonesString(h) }}</b></li>
        }
      </ul>
    }

    @if (ta.vacations.length > 0) {
      <ul>
        @for (v of ta.vacations; track v) {
          <li>
            @if (v.all_day) {
              <b>{{ v.from_date | date:'short' }}</b> durante todo el día
            }
            @if (!v.all_day) {
              De las <b>{{ v.from_date | date:'short' }}</b> a las <b>{{ v.to_date | date:'short' }}</b>
            }
          </li>
        }
      </ul>
    }
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button mat-button color="accent" (click)="close()" cdkFocusInitial type="button">Cerrar</button>
  </mat-dialog-actions>
