import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTooltip } from '@angular/material/tooltip';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Application } from 'src/app/models/application.model';
import { ApplicationEnvironment } from 'src/app/models/application_environment.model';
import { ApplicationType } from 'src/app/models/application_type.model';
import { ProgrammingTag } from 'src/app/models/programming_tag.model';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-project-application-credit',
  templateUrl: './project-application-credit.component.html',
  styleUrls: ['./project-application-credit.component.css']
})
export class ProjectApplicationCreditComponent implements OnInit {

  isGithubRepo:boolean = false;
  app_id:string|undefined|null;
  private project_id:string;

  form:FormGroup;
  appTypeAutocompleteCtrl:FormControl = new FormControl();
  appProgrammingTagAutocompleteCtrl:FormControl = new FormControl();

  private application_types:ApplicationType[] = [];
  filtered_app_types:Observable<ApplicationType[]>;
  private programming_tags:ProgrammingTag[] = [];
  filtered_programming_tags:Observable<ProgrammingTag[]>;

  app:Application|undefined;
  environments:ApplicationEnvironmentEditable[];

  get isCreate() {
    return this.app_id == null;
  }

  constructor(private activatedRoute:ActivatedRoute,
              private fb:FormBuilder,
              private api:ApiService,
              private router:Router,
              private snack:MatSnackBar) {

    this.form = this.fb.group({
      name: ['', Validators.compose([Validators.required, Validators.minLength(5)])],
      git_url: ['', Validators.compose([Validators.required, Validators.pattern(/[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/)])],
      application_types: [[], Validators.compose([Validators.required, Validators.minLength(1)])],
      programming_tags: [[], Validators.compose([Validators.required, Validators.minLength(1)])],
    });

  }

  ngOnInit(): void {
    this.initPageCondition();
    if(!this.isCreate) this.fetchApp();
    this.fetchApplicationTypes();
    this.fetchProgrammingTags();
    this.listenAutocompleteControls();
  }

  removeAppType(index:number) {
    const app_types:ApplicationType[] = this.form.value['application_types'];
    app_types.splice(index, 1);
    this.form.patchValue({ application_types: app_types });
  }

  addAppType(event:MatAutocompleteSelectedEvent) {
    const type:ApplicationType = event.option.value
    const types:ApplicationType[] = this.form.value['application_types'];
    types.push(type)
    this.form.patchValue({ application_types: types });
    this.appTypeAutocompleteCtrl.reset();
  }

  removeProgrammingTag(index:number) {
    const tags:ApplicationType[] = this.form.value['programming_tags'];
    tags.splice(index, 1);
    this.form.patchValue({ programming_tags: tags });
  }

  addProgrammingTag(event:MatAutocompleteSelectedEvent) {
    const tag:ProgrammingTag = event.option.value
    const tags:ProgrammingTag[] = this.form.value['programming_tags'];
    tags.push(tag)
    this.form.patchValue({ programming_tags: tags });
    this.appProgrammingTagAutocompleteCtrl.reset();
  }

  submit() {
    let body:any = { ...this.form.value };

    body.application_type_ids = body.application_types.map((type:ApplicationType) => type.id)
    delete body.application_types;

    body.programming_tag_ids = body.programming_tags.map((tag:ProgrammingTag) => tag.id)
    delete body.programming_tags;

    body.project_id = this.project_id;
    let obs:Observable<Application> = null as any;
    if(this.isCreate) {
      obs = this.api.createApplication(body)
    }
    else {
      obs = this.api.updateApplication(this.app_id as string, body)
    }

    obs.subscribe(
      data => {
        this.router.navigate(['../'], { relativeTo: this.activatedRoute });
      }
    );
  }

  checkGithub() {
    this.api.checkApplicationGithubConnection((this.app_id as string).toString()).subscribe(
      data => {
        this.snack.open("Conexión correcta.", 'Ok')
      },
      error => {
        const snackRef = this.snack.open("No hay acceso.", 'Instalar Bisual App en Github');
        snackRef.onAction().subscribe(
          data => {

            window.open("https://github.com/apps/bisual/installations/select_target", "_blank")
          }
        );
      }
    );
  }

  renewTokenGithub() {
    this.api.renewApplicationGithubToken((this.app_id as string)).subscribe(
      data => {
        this.app = data;
        this.snack.open("Token renovado correctamente.", 'Ok')
      },
      error => {
        this.snack.open("No se ha podido renovar el token", "Ok");
      }
    );
  }

  saveEnv(index:number) {
    this.environments[index].editing = false;
    const env = this.environments[index];

    let promiseEnv:Observable<ApplicationEnvironment> = null as any;
    env.application_id = this.app_id as string;
    if(env.id != null) {
      // és editar
      promiseEnv = this.api.updateApplicationEnvironment(env.id, env);
    }
    else {
      promiseEnv = this.api.createApplicationEnvironment(env);
    }

    promiseEnv.subscribe(
      data => {
        // ha anat ok...
      }
    );
  }

  deleteEnv(index:number) {
    if(confirm("¿Estás seguro que quieres eliminar este entorno de aplicación?")) {
      let env:ApplicationEnvironmentEditable = this.environments.splice(index, 1)[0];
      if(env.id != null) {
        this.api.deleteApplicationEnvironment(env.id).subscribe(data => {}, error => { this.environments.push(env) })
      }
    }
  }

  addEnv() {
    this.environments.push({
      id: undefined as any,
      application_id: "",
      role: "" as any,
      git_ref: "",
      url: "",
      editing: true
    } as any)
  }

  validEnv(item:ApplicationEnvironmentEditable): boolean {
    return item.git_ref != "" && item.role as any != "" && item.url != ""
  }

  private initPageCondition() {
    this.app_id = this.activatedRoute.snapshot.paramMap.get("app_id");
    this.project_id = this.activatedRoute.snapshot.parent?.parent?.paramMap.get("id") as string;
  }

  private fetchApp() {
    this.api.getApplication(this.app_id as string, { with: 'application_types,programming_tags,environments' }).subscribe(
      data => {
        this.app = data;
        this.form.patchValue(data)
        this.isGithubRepo = data.git_url.includes("github.com");
        this.environments = (data.environments as ApplicationEnvironmentEditable[]).map(e => { e.editing = false; return e; })
      }
    );
  }

  private fetchApplicationTypes() {
    this.api.getApplicationTypes().subscribe(
      data => {
        this.application_types = data;
      }
    );
  }

  private fetchProgrammingTags() {
    this.api.getProgrammingTags().subscribe(
      data => {
        this.programming_tags = data;
      }
    );
  }

  private listenAutocompleteControls() {
    this.filtered_app_types = this.appTypeAutocompleteCtrl.valueChanges.pipe(
      startWith(null),
      map(
        (search_str: string | null) => (
          this.application_types.filter(type => (this.form.value['application_types'] as ApplicationType[]).findIndex(type2 => type2.id == type.id)==-1)
            .filter(type => !search_str || (typeof search_str)!=="string" || type.name.toLowerCase().includes(search_str.toLowerCase()))
        )
      ),
    );

    this.filtered_programming_tags = this.appProgrammingTagAutocompleteCtrl.valueChanges.pipe(
      startWith(null),
      map(
        (search_str: string | null) => (
          this.programming_tags.filter(tag => (this.form.value['programming_tags'] as ProgrammingTag[]).findIndex(tag2 => tag2.id == tag.id)==-1)
            .filter(tag => !search_str || (typeof search_str)!=="string" || tag.name.toLowerCase().includes(search_str.toLowerCase()))
        )
      ),
    );

  }

}

interface ApplicationEnvironmentEditable extends ApplicationEnvironment {
  editing:boolean;
}
