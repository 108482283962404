import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { DevelopmentAvailability } from 'src/app/models/development_availability.model';
import { DevelopmentRoadmapEntity, DevelopmentRoadmapGroup, DevelopmentRoadmapResponse } from 'src/app/models/development_roadmap.model';
import { ApiService } from 'src/app/services/api.service';
import { UtilsService } from 'src/app/services/utils.service';
import { AddContractToRoadmapDialogComponent } from './add-contract-to-roadmap-dialog/add-contract-to-roadmap-dialog.component';
import { EditWeeklyWorkingTimeBudgetDistributionDistributableDialogComponent } from './edit-weekly-working-time-budget-distribution-distributables-dialog/edit-weekly-working-time-budget-distribution-distributables-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ProjectAssignmentDialogComponent } from './project-assignment-dialog/project-assignment-dialog.component';
import { WeeklyWorkingTimeBudget } from 'src/app/models/weekly_working_time_budget.model';
import { WeeklyWorkingTimeBudgetEmployee } from 'src/app/models/weekly_working_time_budget_employee.model';
import { WeeklyWorkingTimeBudgetDistributionDistributable } from 'src/app/models/weekly_working_time_budget_distribution_distributable.model';
import { Contract } from 'src/app/models/contract.model';
import { OptionsDialogComponent, OptionsDialogDataRow } from '../../../shared/options-dialog/options-dialog.component';
import { DatePickerDialogComponent } from '../../../shared/date-picker-dialog/date-picker-dialog.component';

@Component({
  selector: 'app-planning-roadmap',
  templateUrl: './planning-roadmap.component.html',
  styleUrls: ['./planning-roadmap.component.css']
})
export class PlanningRoadmapComponent implements OnInit {

  filterForm:FormGroup = null as any;
  private formPersistence:any;
  now:moment.Moment = moment();

  roadmap:any[];
  availability:DevelopmentAvailability[];
  daysOfWeek:DayOfWeek[];

  showSaveButton:boolean = false;
  isSaving:boolean = false;

  test_activated:boolean = false;

  private original_fetch_response:DevelopmentRoadmapResponse;

  constructor(private fb:FormBuilder,
              private activatedRoute:ActivatedRoute,
              private utils:UtilsService,
              private router:Router,
              private api:ApiService,
              private dialog:MatDialog,
              private snack:MatSnackBar) {
    this.filterForm = this.fb.group({
      from:[(this.activatedRoute.snapshot.queryParamMap.get('from')!=null ? this.utils.stringToDate(this.activatedRoute.snapshot.queryParamMap.get('from') as string) : this.getDefaultInitialDate()), Validators.required],
      to:[(this.activatedRoute.snapshot.queryParamMap.get('to')!=null ? this.utils.stringToDate(this.activatedRoute.snapshot.queryParamMap.get('to') as string) : this.getDefaultEndDate()), Validators.required],
    });
  }

  ngOnInit(): void {
    this.initFilterFormListener();
    this.listenQueryParameters();
    this.listenBeforeUnload();
  }

  onChangeInput(newVal:number, indexEntity:number, indexWeek:number) {
    const oldVal = this.roadmap[indexEntity].weeks[indexWeek].hours;
    const diff = oldVal - newVal;

    // Actualitzem registre
    this.roadmap[indexEntity].weeks[indexWeek].hours = newVal;
    this.roadmap[indexEntity].weeks[indexWeek].changed = true;

    // Actualitzem disponibilitat
    this.availability[indexWeek].budgetable_availability_remaining += diff;

    // Actualitzem hores per assignar a la setmana
    this.roadmap[indexEntity].hours_remaining += diff;

    // Actualitzem distribució
    if(this.roadmap[indexEntity].weeks[indexWeek].distributable_status != null && this.roadmap[indexEntity].weeks[indexWeek].distributable_status.distributable_categories.length) {
      // calculem distribució actual %
      let total:number = 0;
      for(let cat of this.roadmap[indexEntity].weeks[indexWeek].distributable_status.distributable_categories) {
        total += cat.total_hours_to_do;
      }

      let prcnts:number[] = [];
      for(let cat of this.roadmap[indexEntity].weeks[indexWeek].distributable_status.distributable_categories) {
        prcnts.push(cat.total_hours_to_do / total);
      }

      this.roadmap[indexEntity].weeks[indexWeek].distributable_status.distributable_categories.forEach((cat:any, idx:number) => {
        this.roadmap[indexEntity].weeks[indexWeek].distributable_status.distributable_categories[idx].total_hours_to_do = newVal*prcnts[idx];
      })
    }

    // Mostrem botó
    this.showSaveButton = true;
  }

  openDistributablesPopup(indexEntity:number, indexWeek:number) {
    let budget = this.roadmap[indexEntity].weeks[indexWeek];
    if (budget.weekly_working_time_budget_id!=null) {
      if(!budget.changed) {
        //this.api.getWeeklyWorkingTimeBudgetDistributionDistributable(budget.weekly_working_time_budget_id).subscribe((data) => {
        const dialogRef = this.dialog.open(EditWeeklyWorkingTimeBudgetDistributionDistributableDialogComponent, {
          width: '1200px',
          data: {
            budget_id: budget.weekly_working_time_budget_id,
           // root_id: this.roadmap[indexEntity].id,
            title: `Distribución de horas de ${this.roadmap[indexEntity].company.fact_name}`,
            first_label_title: 'Distribución de Proyecto',
            second_label_title: 'Distribución de Fases',
          },
        });

        dialogRef.afterClosed().subscribe(
          data => {
            this.isSaving = true;
            this.api.getWeeklyWorkingTimeBudget(budget.weekly_working_time_budget_id, { append: 'distributable_status' }).subscribe(
              data => {
                this.roadmap[indexEntity].weeks[indexWeek].distributable_status = data.distributable_status;
                this.isSaving = false;
              }
            );
          }
        );
      }
      else this.snack.open("Has modificado este registro. Primero deberías guardarlo.", "OK");
    }
  }

  openProjectAssignmentDialog(indexEntity:number, indexWeek:number) {
    let budget = this.roadmap[indexEntity].weeks[indexWeek];

    if (budget.weekly_working_time_budget_id!=null) {
      const dialogRef = this.dialog.open(ProjectAssignmentDialogComponent, {
        width: '95%',
        height: 'auto',
        maxWidth: '95vw',
        maxHeight: '95vh',
        disableClose: true,
        data: {
          from: (budget.from as moment.Moment).toDate(),
          to: (budget.to as moment.Moment).toDate(),
        },
      });

      dialogRef.afterClosed().subscribe(
        (data:{[key:string]:WeeklyWorkingTimeBudgetEmployee}) => {
          // actualitzem tota la setmana amb la nova assignació que acabem de tancar en el popup
          this.isSaving = true;
          this.roadmap.forEach((entity, indexEntity) => {
            if(entity.weeks[indexWeek].weekly_working_time_budget_id != null) {
              this.api.getWeeklyWorkingTimeBudget(entity.weeks[indexWeek].weekly_working_time_budget_id, { append: 'distributable_status' }).subscribe(
                data => {
                  this.roadmap[indexEntity].weeks[indexWeek].distributable_status = data.distributable_status;
                  this.isSaving = false;
                }
              );
            }
          });
        }
      );
    }
  }

  save() {
    if(confirm("¿Estás seguro que quieres guardar los cambios?")) {
      this.isSaving = true;

      let res:any[] = [];
      for(let item of this.roadmap) {
        for(let week of item.weeks) {
          if(week.changed) {
            let obj:any = week;
            obj.from = (week.from as moment.Moment).format('YYYY-MM-DD');
            obj.to = (week.to as moment.Moment).format('YYYY-MM-DD');
            obj.contract_id = item.id;
            res.push(obj);
          }
        }
      }

      if(res.length > 0) {
        // Guardem
        this.api.saveBulkWeeklyWorkingTimeBudgets(res).subscribe(
          data => {
            this.showSaveButton = false;
            this.isSaving = false;

            this.fetchRoadmap();
          },
          error => {
            this.showSaveButton = true;
            this.isSaving = false;
          },
        );
      }

    }
  }

  propagateDistributionAndAssignation(indexEntity:number, indexWeek:number) {
    const dialogRef = this.dialog.open(OptionsDialogComponent, {
      width: '400px',
      data: {
        data: [
          { value: 'project_distribution', textToShow: 'Distribución (horas a la semana y distribución)', checked: false },
          { value: 'assignation', textToShow: 'Asignación (asignación y calendarios)', checked: false },
          { value: 'all_projects', textToShow: 'Todos los proyectos', checked: false },
          { value: 'custom_date', textToShow: 'Hasta fecha personalizada', checked: false },
        ],
        dialog_title: "¿Qué quieres propagar?",
        dialog_ok_button: "Propagar",
      }
    });

    dialogRef.afterClosed().subscribe(
      (data:undefined|OptionsDialogDataRow[]) => {
        let contract = this.roadmap[indexEntity];
        let budget = contract.weeks[indexWeek];
        this.isSaving = true;

        if (data?.find(d => d.value=='custom_date')?.checked) {
          const dialogRef2 = this.dialog.open(DatePickerDialogComponent, {
            width: '400px',
            data: {
              dialog_title: "¿Hasta qué semana quieres propagar?",
              dialog_ok_button: "Propagar",
              dialog_cancel_button: "Cancelar",
              data: { filter: "monday" }
            },
          });

          dialogRef2.afterClosed().subscribe(
            (date:Date|undefined) => {
              if (date!=undefined) {
                this.copyRoadMapWeeks(
                  data?.find(d => d.value=='all_projects')?.checked ? null : contract.id,
                  (budget.from as moment.Moment).format('YYYY-MM-DD'),
                  data?.find(d => d.value=='project_distribution')?.checked || false,
                  data?.find(d => d.value=='assignation')?.checked || false,
                  this.utils.dateToStringYYYYMMDD(date)
                );
              }
            }
          );
        } else if(data != undefined) {
          this.copyRoadMapWeeks(
            data?.find(d => d.value=='all_projects')?.checked ? null : contract.id,
            (budget.from as moment.Moment).format('YYYY-MM-DD'),
            data?.find(d => d.value=='project_distribution')?.checked || false,
            data?.find(d => d.value=='assignation')?.checked || false,
            null
          );
        }
      }
    );
  }

  copyRoadMapWeeks(contract_id:number|null, current_week:string, propagate_project_distribution:boolean, propagate_assignation:boolean, to_date:string|null) {
    this.api.copyRoadmapToYear({
      contract_id: contract_id,
      current_week: current_week,
      propagate_project_distribution: propagate_project_distribution,
      propagate_assignation: propagate_assignation,
      to_date: to_date,
    }).subscribe(
      data => {
        this.fetchRoadmap();
      }
    );

  }

  weekTooltip(avail:any) {
    if(avail.detail != null) {
      let res:string = "";
      for(let user of Object.values(avail.detail)) {
        let u:any = user;
        res += `${u.user.name} ${u.user.surnames}: ${Math.round(u.budgetable_availability)}h\n`;
      }
      return res;
    }
    else return '';
  }

  getEntityWeekClasses(entity:any, week:any) {
    if(typeof week.from === 'string') {
      week.from = moment(week.from, "YYYY-MM-DD");
      week.to = moment(week.to, "YYYY-MM-DD");
    }
    const currentContract = (week.from as moment.Moment).isSameOrAfter(entity.starts_at as moment.Moment, 'week') && (week.to as moment.Moment).isSameOrBefore(entity.ends_at as moment.Moment, 'week');
    const firstWeekContract = (week.from as moment.Moment).isSame(entity.starts_at, 'week');
    const lastWeekContract = (week.to as moment.Moment).isSame(entity.ends_at, 'week');

    return {
      'filled': week.hours > 0,
      'current-week': week.from.isSame(this.now, 'week'),
      'current-contract': currentContract,
      'first-week-contract': firstWeekContract,
      'last-week-contract': lastWeekContract,
      'changed': week.changed,
      'distributable-empty': week.distributable_status?.status=='empty',
      'distributable-incomplete': week.distributable_status?.status=='incomplete',
      'distributable-overbudget': week.distributable_status?.status=='overbudget',
      'distributable-complete': week.distributable_status?.status=='complete'
    };
  }

  scrollToCurrentWeek() {
    setTimeout(() => {
      const roadmap = document.getElementById('roadmap');
      const table:any = roadmap?.firstChild;
      if(roadmap && table) {
        roadmap.scrollTo({
          left: table.offsetWidth / 2 - 5200,
          behavior: 'smooth'
        });
      }
    }, 1000);
  }

  onChangeEsbozo(val:boolean) {
    this.test_activated = val;
    this.processRoadmap(this.original_fetch_response.roadmap);
  }

  openAddContractDialog() {
    const dialogRef = this.dialog.open(AddContractToRoadmapDialogComponent, {
      width: '400px',
      data: {},
    });

    dialogRef.afterClosed().subscribe(
      data => {
        if(data.refresh) {
          this.fetchRoadmap(); // activa modo esbozo (perquè es vegin tots els projectes), i fa fetch de nou
        }
      }
    );
  }

  saveAndChargeDefaults(indexEntity:number, indexWeek:number) {
    if(confirm("Esto eliminará cualquier asignación y calendarios que hayas realizado. ¿Quieres continuar?")) {
      this.api.updateWeeklyWorkingTimeBudgetAndChargeDefaults(this.roadmap[indexEntity].weeks[indexWeek].weekly_working_time_budget_id, this.roadmap[indexEntity].weeks[indexWeek].hours).subscribe(
        data => {
          this.roadmap[indexEntity].weeks[indexWeek].changed = false;
          this.roadmap[indexEntity].weeks[indexWeek].distributable_status = data.distributable_status;
        }
      );
    }
  }

  private initFilterFormListener() {
    this.filterForm.valueChanges.subscribe(
      data => {
        if(this.formPersistence==null || JSON.stringify(this.formPersistence)!=JSON.stringify(data)) {
          const params = this.utils.cloneObj(data);
          if(params.from!=null && params.from!="") params.from = this.utils.dateToString(new Date(params.from));
          if(params.to!=null && params.to!="") params.to = this.utils.dateToString(new Date(params.to));
          this.router.navigate(['/employees', 'development', 'roadmap'], { queryParams: params });
        }
      }
    );
  }

  private listenQueryParameters() {
    this.activatedRoute.queryParams.subscribe(
      params => {
        if(JSON.stringify(params)!==JSON.stringify(this.filterForm.value)) { //si no ve de filtre s'ha de setejar el form
          let params_temp = this.utils.cloneObj(params); //params es read_only
          Object.keys(params_temp).forEach(param_key => {
            if(params_temp[param_key]!=null && params_temp[param_key]!="" && !isNaN(+params_temp[param_key])) params_temp[param_key] = +params_temp[param_key]; // si es numero, el transformem
            else if(params_temp[param_key]!=null && (params_temp[param_key] as string).indexOf("-")>-1) params_temp[param_key] = this.utils.stringToDate(params_temp[param_key]);
          });
          this.filterForm.patchValue(params_temp, { emitEvent: false });
        }
        this.formPersistence = params;
        this.fetchRoadmap();
      }
    );
  }

  private fetchRoadmap() {
    this.api.getEmployeesDevelopmentRoadmap(this.filterForm.value['from'], this.filterForm.value['to']).toPromise().then(
      data => {
        if(data != undefined) {
          this.original_fetch_response = data;
          this.showSaveButton = false;
          this.isSaving = false;
          this.processAvailability(data.availability);
          this.processRoadmap(data.roadmap);
          this.scrollToCurrentWeek();
        }
      }
    );
  }

  private getDefaultInitialDate() {
    let d = new Date();
    d.setMonth(new Date().getMonth() - 1);
    return d;
  }

  private getDefaultEndDate() {
    let d = new Date();
    d.setMonth(new Date().getMonth() + 12);
    return d;
  }

  private processRoadmap(roadmap:DevelopmentRoadmapGroup[]) {
    const roadmap_response = roadmap;
    let res:any[] = [];
    for(let company_row of roadmap_response) {
      let company_rowspan:number = 0;
      for(let type of Object.values(company_row.rows)) {
        Object.values(type.contracts).forEach(c => {
          company_rowspan += this.test_activated || !c.is_test ? 1 : 0;
        });
      }

      Object.values(company_row.rows).forEach((row:DevelopmentRoadmapEntity, index:number) => {
        let project_or_contract_rowspan:number = 0;
        Object.values(row.contracts).forEach(c => {
          project_or_contract_rowspan += this.test_activated || !c.is_test ? 1 : 0;
        });

        Object.values(row.contracts).forEach((c, index2:number) => {

          if(this.test_activated || !c.is_test) {
            let item:any = { ...c, ...row };
            item.company = company_row.company;
            item.company_rowspan = company_rowspan;
            item.project_or_contract_rowspan = project_or_contract_rowspan;
            item.is_first_project = this.test_activated ? index2==0 : this.getIsFirstProject(item, row);
            item.is_last_project = index2==Object.values(row.contracts).length-1;
            item.is_last_company = index==Object.keys(company_row.rows).length-1 && item.is_last_project;
            item.starts_at = moment(item.starts_at);
            item.ends_at = moment(item.ends_at);
            item.hours_remaining = item.hours;

            delete item.contracts;
            delete item.client_or_project;
            delete item.client_or_project_id;
            delete item.client_or_project_type;

            // Calculem availability total per setmana
            c.weeks.forEach((w, i:number) => {
              this.availability[i].budgetable_availability_remaining -= w.hours;
              item.hours_remaining -= w.hours;

              // Inicialitzem
              item.weeks[i].from = moment(w.from, "YYYY-MM-DD");
              item.weeks[i].to = moment(w.to, "YYYY-MM-DD");
              item.weeks[i].changed = false;
              item.weeks[i].distributable_status = w.distributable_status;
            });

            res.push(item);
          }
        });
      });
    }

    this.roadmap = res;
  }

  private processAvailability(data:DevelopmentAvailability[]) {
    this.daysOfWeek = [];
    this.availability = data.map((a:DevelopmentAvailability) => {
      a.budgetable_availability_remaining = a.budgetable_availability;

      a.days.forEach(day => {
        this.daysOfWeek.push({
          day: moment(day.day),
          has_holidays: day.holidays!=null && day.holidays.length > 0,
          has_non_working_times: day.non_working_times!=null && day.non_working_times.length > 0,
          avail: a
        });
      });

      return a;
    });
  }

  private listenBeforeUnload() {
  //   const self = this;
  //   window.addEventListener("beforeunload", function(event){
  //     if(self.showSaveButton) {
  //       event.preventDefault();
  //       return event.returnValue = "¿Estás seguro que quieres salir? Perderás los cambios realizados.";
  //     }
  //     return null;
  //  }, false);
  }

  private getIsFirstProject(item:any, row:DevelopmentRoadmapEntity) {
    for(let c of Object.values(row.contracts)) {
      if(!c.is_test) {
        if(item.id==c.id) return true;
        else return false;
      }
    }

    return false;
  }

}

interface DayOfWeek {
  day:moment.Moment;
  has_holidays:boolean;
  has_non_working_times:boolean;
  avail:DevelopmentAvailability;
}
