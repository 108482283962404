import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-provider-order-files-dialog',
  templateUrl: './provider-order-files-dialog.component.html',
  styleUrls: ['./provider-order-files-dialog.component.css']
})
export class ProviderOrderFilesDialogComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
