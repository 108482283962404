<div [style.width.px]="size" [style.height.px]="size">
  @if (user!=null && userClicked.observers.length>0) {
    <div class="click-element" [style.cursor]="userClicked.observers.length>0 ? 'pointer' : ''" (click)="onUserClick()">
      <mat-icon>close</mat-icon>
    </div>
  }
  <div [style.width.px]="size" [style.height.px]="size" class="assignee-container">
    <img class="assignee-image" [src]="profile_pic" [style.width.px]="size" [style.height.px]="size" [style.border-color]="project_user_member?.department_category_slug | departmentCategoryToColorPipe"/>
    <div class="role-icon-container" [style.top]="-size/2 + 'px'" [style.background-color]="project_user_member?.department_category_slug| departmentCategoryToColorPipe" [style.width.px]="size/2" [style.height.px]="size/2">
      <mat-icon [style.font-size]="size/4 + 'px'" [style.width.px]="size/2" [style.height.px]="size/2">{{ project_user_member?.department_category_slug | departmentCategoryToIconPipe }}</mat-icon>
    </div>
  </div>
</div>
