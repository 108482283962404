import { Component, Input, OnInit } from '@angular/core';
import { Hosting } from 'src/app/models/hosting.model';
import { ApiService } from 'src/app/services/api.service';
import { PermissionsService } from 'src/app/services/permissions.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-hosting-detail-resume',
  templateUrl: './hosting-detail-resume.component.html',
  styleUrls: ['./hosting-detail-resume.component.css']
})
export class HostingDetailResumeComponent implements OnInit {

  public children:Hosting[] = [];
  @Input() hosting:Hosting = null as any;

  constructor(private utils:UtilsService,
              private api:ApiService,
              public permissions:PermissionsService) { }

  ngOnInit(): void {

  }

  getHostingStatusTooltip(status:string) {
    return this.utils.getStatusTooltip(status);
  }

  cancelHosting(hosting:Hosting) {
    if(confirm("¿Estás seguro que quieres cancelar este hosting?")) {
      this.api.cancelHosting(hosting.id).subscribe(
        data => {
          this.hosting.ended_by = data.ended_by;
          this.hosting.ended_by_id = data.ended_by_id;
          this.hosting.expiration_date = data.expiration_date;
          this.hosting.status = data.status;
          this.hosting.trial_ends_at = data.trial_ends_at;
        }
      );
    }
  }

  deleteHosting(hosting:Hosting) {
    if(confirm("¿Estás seguro que quieres eliminar este hosting?")) {
      this.api.deleteHosting(hosting.id).subscribe(
        data => {
          this.hosting.ended_by = data.ended_by;
          this.hosting.ended_by_id = data.ended_by_id;
          this.hosting.expiration_date = data.expiration_date;
          this.hosting.status = data.status;
          this.hosting.trial_ends_at = data.trial_ends_at;
        }
      );
    }
  }

  startServerHetzner() {
    if(confirm("¿Estás seguro que quieres iniciar el servidor?")) {
      this.api.startServerHetzner(this.hosting.hetzner_id).subscribe(
        data => {

        }
      );
    }
  }

  stopServerHetzner() {
    if(confirm("¿Estás seguro que quieres parar el servidor?")) {
      this.api.stopServerHetzner(this.hosting.hetzner_id).subscribe(
        data => {

        }
      );
    }
  }

  restartServerHetzner() {
    if(confirm("¿Estás seguro que quieres reiniciar el servidor?")) {
      this.api.restartServerHetzner(this.hosting.hetzner_id).subscribe(
        data => {

        }
      );
    }
  }

  enableBackupsHetzner() {
    if(confirm("¿Estás seguro que quieres habilitar las copias de seguridad en el servidor?")) {
      this.api.enableBackupsHetzner(this.hosting.hetzner_id).subscribe(
        data => {

        }
      );
    }
  }

  disableBackupsHetzner() {
    if(confirm("¿Estás seguro que quieres deshabilitar las copias de seguridad el servidor?")) {
      this.api.disableBackupsHetzner(this.hosting.hetzner_id).subscribe(
        data => {

        }
      );
    }
  }

  resetRootPasswordHetzner() {
    if(confirm("¿Estás seguro que quieres restablecer la contraseña del usuario root del servidor?")) {
      this.api.resetRootPasswordHetzner(this.hosting.hetzner_id).subscribe(
        data => {
          this.hosting.credentials.push(data);
        }
      );
    }
  }

  changeServerTypeHetzner() {
    if(confirm("¿Estás seguro que quieres cambiar el tipo de servidor?")) {
      this.api.changeServerTypeHetzner(this.hosting.hetzner_id, true, '').subscribe(
        data => {

        }
      );
    }
  }

  openServerInHetzner() {
    window.open('https://console.hetzner.cloud/projects/576960/servers/' + this.hosting.hetzner_id + '/overview', '_blank');
  }


}
