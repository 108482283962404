<div class="card">
  <h2>{{title}}</h2>
  @for (pair of projects | keyvalue; track pair) {
    <div class="group-statics">
      <span class="col-3">{{ pair.value.name }}</span>
    <mat-progress-bar mode="determinate" [value]="getProgress(pair.value)" [ngClass]="{
      'danger-bar': pair.value.flag == 'error',
      'warn-bar': pair.value.flag == 'warn',
      'ok-bar': pair.value.flag == 'ok'
    }">
      </mat-progress-bar>
      <span class="resume">{{ (pair.value.done/(60*60)).toFixed(2) + 'h/' + (pair.value.expected/(60*60)).toFixed(2) + 'h' }}</span>
    </div>
  }
  @for (pair of categories | keyvalue; track pair) {
    <div class="group-statics">
      <span class="col-3">{{ pair.value.name }}</span>
    <mat-progress-bar mode="determinate" [value]="getProgress(pair.value)" [ngClass]="{
      'danger-bar': pair.value.flag == 'error',
      'warn-bar': pair.value.flag == 'warn',
      'ok-bar': pair.value.flag == 'ok'
    }">
      </mat-progress-bar>
      <span class="resume">{{ (pair.value.done/(60*60)).toFixed(2) + 'h/' + (pair.value.expected/(60*60)).toFixed(2) + 'h' }}</span>
    </div>
  }
</div>
