<div id="content">
  <div class="page-title">
    <h1>Administrar peticion de vacaciones</h1>
    <button mat-fab color="warn" aria-label="Reject" (click)="declineVacation()">
      <mat-icon>thumb_down</mat-icon>
    </button>
    <button mat-fab color="accent" aria-label="Accept" (click)="acceptVacation()">
      <mat-icon>thumb_up</mat-icon>
    </button>
  </div>

  @if (vacation_request!=null) {
    <div class="card">
      <div class="row">
        <div class="col-12 col-md-3">
          <label>Nombre</label>
          <p>{{vacation_request.user.name+ ' '+ vacation_request.user.surnames}}</p>
        </div>
        <div class="col-12 col-md-3">
          <label>Dias de vacaciones restantes</label>
          <p>
            {{ working_contract!=null ?
            (((working_contract.vacation_days_info.vacations_total_days_available.days_total - working_contract.vacation_days_info.vacations_days_spent.days_total) | number:'1.0-2') + ' días') :
            'Sin contrato'
            }}
          </p>
        </div>
        <div class="col-12 col-md-3">
          <label>Dias de vacaciones solicitados</label>
          <p>{{vacation_request.count_hours_of_vacations}} horas</p>
        </div>
      </div>
    </div>
  }


  <div class="bisual-table-container">
    <table mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef>Id</th>
        <td mat-cell *matCellDef="let vacationRequestPeriod" class="col-3">
          #{{vacationRequestPeriod.id}}
        </td>
      </ng-container>
      <ng-container matColumnDef="day">
        <th mat-header-cell *matHeaderCellDef>Dia</th>
        <td mat-cell *matCellDef="let vacationRequestPeriod" class="col-3">
          {{vacationRequestPeriod.day | date:'d/M/yy, EEEE'}}
        </td>
      </ng-container>
      <ng-container matColumnDef="hours">
        <th mat-header-cell *matHeaderCellDef>Horas</th>
        <td mat-cell *matCellDef="let vacationRequestPeriod" class="col-3">{{ getHoursOfWorkPerDay(vacationRequestPeriod.day) }} horas</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>

</div>
