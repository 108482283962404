<div [id]="inheritIdContent ? '' : 'content'" class = "inherit-content" >

  <div class="page-title">
    <h1>{{router.url.includes('/users-management') ? 'Editar Usuario' : 'Editar mi perfil'}}</h1>
    @if (user != null && user.role.slug === 'worker') {
      <button mat-flat-button [routerLink]="['/employees', 'direction', 'users-management', 'users']" type="button">
        <mat-icon matPrefix>arrow_back</mat-icon>
        Volver
      </button>
    }
    @if (permissions.validateDepartment(['direction', 'rrhh'])) {
      <button mat-flat-button color="warn" (click)="delete()" type="button">
        <mat-icon matPrefix>delete</mat-icon>
        Eliminar
      </button>
    }
  </div>

  <form class="card mb-5" [formGroup]="userForm">
    <div class="container" style="margin-top: 20px">
      <div class="row">
        <div class="col-12 col-md-6 col-xl-3" style="margin-top: 10px" style="margin-bottom: 10px">
          <mat-form-field class="nobottom" appearance="outline">
            <mat-label>Nombre</mat-label>
            <input type="text" matInput formControlName="name">
          </mat-form-field>
        </div>
        <div class="col-12 col-md-6 col-xl-3">
          <mat-form-field class="nobottom" appearance="outline">
            <mat-label>Apellidos</mat-label>
            <input type="text" matInput formControlName="surnames">
          </mat-form-field>
        </div>
        <div class="col-12 col-md-6 col-xl-3">
          <mat-form-field class="nobottom" appearance="outline">
            <mat-label>Correo electrónico</mat-label>
            <input type="email" matInput formControlName="email">
          </mat-form-field>
        </div>
        <div class="col-12 col-md-6 col-xl-3">
          @if (itsMe) {
            <button mat-stroked-button style="padding:10.5px;" (click)="changePassword()" [disabled]="!canEdit()">
              <mat-icon>lock</mat-icon>
              Cambiar contraseña
            </button>
          }
          @if (!itsMe) {
            <button mat-stroked-button (click)="changePassword()" [disabled]="!canEdit()">
              <mat-icon>lock</mat-icon>
              Regenerar contraseña
            </button>
          }
        </div>


        <div class="col-12 col-md-6 col-xl-3">
          <mat-form-field class="nobottom" appearance="outline">
            <mat-label>Sexo</mat-label>
            <mat-select formControlName="sex">
              <mat-option value="M">Masculino</mat-option>
              <mat-option value="F">Femenino</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-12 col-md-6 col-xl-3">
          <mat-form-field class="nobottom" appearance="outline">
            <mat-label>Fecha de nacimiento</mat-label>
            <input matInput [matDatepicker]="picker" formControlName="birthday">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="col-12 col-md-6 col-xl-3">
          <mat-form-field class="nobottom" appearance="outline">
            <mat-label>Teléfono móvil</mat-label>
            <input type="tel" matInput placeholder="+34.666 555 444" formControlName="phone">
            <mat-icon matSuffix>phone</mat-icon>
            <mat-hint>F: +XX.XXX XXX XXX</mat-hint>
          </mat-form-field>
        </div>
      </div>

      @if (userForm.value['role_id'] === 1) {
        <div class="row">
          <h2>Roles y departamento</h2>
          <div class="col-12 col-md-6 col-lg-6">
            <mat-form-field class="nobottom" appearance="outline">
              <mat-label>Departamento</mat-label>
              <mat-select formControlName="department_id" [disabled]="!permissions.validateDepartment(['direction', 'rrhh']) || itsMe">
                @for (d of departments; track d) {
                  <mat-option [value]="d.id">{{ d.name }}</mat-option>
                }
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-12 col-md-6 col-lg-6">
            @if (availableCategories.length > 0) {
              <mat-form-field class="nobottom" appearance="outline">
                <mat-label>Categoria</mat-label>
                <mat-select formControlName="department_category_id" [disabled]="!permissions.validateDepartment(['direction', 'rrhh']) || itsMe" >
                  @for (dc of availableCategories; track dc) {
                    <mat-option [value]="dc.id">{{ dc.name }}</mat-option>
                  }
                </mat-select>
              </mat-form-field>
            }
          </div>
          <div class="col-12 col-md-6 col-lg-6">
            <mat-form-field class="nobottom" appearance="outline">
              <mat-label>Rol</mat-label>
              <mat-select formControlName="role_id" [disabled]="!permissions.validateDepartment(['direction', 'rrhh']) || itsMe">
                @for (r of roles; track r) {
                  <mat-option [value]="r.id">{{ r.name }}</mat-option>
                }
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-12 col-md-6 col-lg-6">
            <mat-form-field class="nobottom" appearance="outline">
              <mat-label>Empresa</mat-label>
              <input type="text" matInput [formControl]="clientSelectControl" [matAutocomplete]="client_select_auto" [disabled]="!permissions.validateDepartment(['direction', 'rrhh']) || itsMe">
              <mat-autocomplete #client_select_auto="matAutocomplete" [displayWith]="displayFn">
                @for (c of available_customers; track c) {
                  <mat-option [value]="c" (click)="selectCustomer(c)">{{ c.fact_name }}</mat-option>
                }
              </mat-autocomplete>
              @if (customerSelected != null) {
                <button mat-button class= "field" matSuffix mat-icon-button aria-label="Clear" (click)="resetCustomer()" [disabled]="!permissions.validateDepartment(['direction', 'rrhh'])">
                  <mat-icon>close</mat-icon>
                </button>
              }
              @if (customerSelected == null) {
                <mat-icon matSuffix>expand_more</mat-icon>
              }
            </mat-form-field>
          </div>
        </div>
      }
      @if (router.url.includes('/users-management')) {
        <button class="mb-4" mat-flat-button color="accent" (click)="saveUser()" type="submit" [disabled]="!(userForm.valid && canEdit())">
          <mat-icon matPrefix>save</mat-icon>
          Guardar
        </button>
      }
      @if (!router.url.includes('/users-management')) {
        <button class="mb-4" mat-flat-button color="accent" (click)="saveUser()" type="submit" [disabled]="!(userForm.valid)">
          <mat-icon matPrefix>save</mat-icon>
          Guardar
        </button>
      }
    </div>
  </form>

  @if (activeContract!=null && userForm.value['role_id'] === 1) {
    <div class="contract-active">
      <div class="page-title">
        <h1>Contrato activo</h1>
        <button mat-flat-button [routerLink]="['/employees', 'rrhh/working-contracts']" type="button">
          Todos los contratos
        </button>
      </div>
      <form class="card" [formGroup]="userContractForm">
        <div class="container">
          <div class="row">
            <div class="col-12 col-md-6 col-xl-3">
              <mat-form-field class="nobottom" appearance="outline">
                <mat-label>Tipo de contrato</mat-label>
                <input type="text" matInput formControlName="type_contract" [disabled]="true">
              </mat-form-field>
            </div>
            <div class="col-12 col-md-6 col-xl-3">
              <mat-form-field class="nobottom" appearance="outline">
                <mat-label>Precio hora</mat-label>
                <input type="text" matInput formControlName="price_hour" [disabled]="true">
              </mat-form-field>
            </div>
            <div class="col-12 col-md-6 col-xl-3">
              <mat-form-field class="nobottom" appearance="outline">
                <mat-label>Neto anual</mat-label>
                <input type="text" matInput formControlName="net_price_year" [disabled]="true">
              </mat-form-field>
            </div>
            <div class="col-12 col-md-6 col-xl-3">
              <mat-form-field class="nobottom" appearance="outline">
                <mat-label>Pagos anuales</mat-label>
                <input type="text" matInput formControlName="payment_year" [disabled]="true">
              </mat-form-field>
            </div>
            <div class="col-12 col-md-6 col-xl-3">
              <mat-form-field class="nobottom" appearance="outline">
                <mat-label>Horas estimadas semanales</mat-label>
                <input type="text" matInput formControlName="hour_week" [disabled]="true">
              </mat-form-field>
            </div>
            <div class="col-12 col-md-6 col-xl-3">
              <mat-form-field class="nobottom" appearance="outline">
                <mat-label>Inicio contrato</mat-label>
                <input type="text" matInput formControlName="start_date_contract" [disabled]="true">
              </mat-form-field>
            </div>
            <div class="col-12 col-md-6 col-xl-3">
              <mat-form-field class="nobottom" appearance="outline">
                <mat-label>Final contrato</mat-label>
                <input type="text" matInput formControlName="end_date_contract" [disabled]="true">
              </mat-form-field>
            </div>
            <div class="col-12 col-md-6 col-xl-3">
              <mat-form-field class="nobottom" appearance="outline">
                <mat-label>Dias vacaciones</mat-label>
                <input type="text" matInput formControlName="vacations_days" [disabled]="true">
              </mat-form-field>
            </div>
            <div class="col-12">
              <mat-form-field class="nobottom" appearance="outline" class="w-100">
                <mat-label>Descripción de trabajo</mat-label>
                <textarea matInput formControlName="job_description" [disabled]="true" cdkTextareaAutosize cdkAutosizeMinRows="5"
                cdkAutosizeMaxRows="15"></textarea>
              </mat-form-field>
            </div>
            <div class="col-12">
              <div class="bisual-table-container">
                <table mat-table [dataSource]="tableData">
                  <div>
                    <ng-container matColumnDef="position">
                      <th mat-header-cell *matHeaderCellDef></th>
                      <td mat-cell *matCellDef="let element"> <b>{{element.position}}</b> </td>
                    </ng-container>
                    <ng-container matColumnDef="Lunes">
                      <th mat-header-cell *matHeaderCellDef> Lunes </th>
                      <td mat-cell *matCellDef="let element"> {{element.monday_entry_time | hourDecimalToHoursMinutes }} - {{element.monday_exit_time | hourDecimalToHoursMinutes }} </td>
                    </ng-container>
                    <ng-container matColumnDef="Martes">
                      <th mat-header-cell *matHeaderCellDef> Martes </th>
                      <td mat-cell *matCellDef="let element"> {{element.tuesday_entry_time | hourDecimalToHoursMinutes }} - {{element.tuesday_exit_time | hourDecimalToHoursMinutes }} </td>
                    </ng-container>
                    <ng-container matColumnDef="Miércoles">
                      <th mat-header-cell *matHeaderCellDef> Miércoles </th>
                      <td mat-cell *matCellDef="let element"> {{element.wednesday_entry_time | hourDecimalToHoursMinutes }} - {{element.wednesday_exit_time | hourDecimalToHoursMinutes }} </td>
                    </ng-container>
                    <ng-container matColumnDef="Jueves">
                      <th mat-header-cell *matHeaderCellDef> Jueves </th>
                      <td mat-cell *matCellDef="let element"> {{element.thursday_entry_time | hourDecimalToHoursMinutes }} - {{element.thursday_exit_time | hourDecimalToHoursMinutes }} </td>
                    </ng-container>
                    <ng-container matColumnDef="Viernes">
                      <th mat-header-cell *matHeaderCellDef> Viernes </th>
                      <td mat-cell *matCellDef="let element"> {{element.friday_entry_time | hourDecimalToHoursMinutes }} - {{element.friday_exit_time | hourDecimalToHoursMinutes }} </td>
                    </ng-container>
                    <ng-container matColumnDef="Sabado">
                      <th mat-header-cell *matHeaderCellDef> Sabado </th>
                      <td mat-cell *matCellDef="let element"> {{element.saturday_entry_time | hourDecimalToHoursMinutes}} - {{element.saturday_exit_time | hourDecimalToHoursMinutes}} </td>
                    </ng-container>
                    <ng-container matColumnDef="Domingo">
                      <th mat-header-cell *matHeaderCellDef> Domingo </th>
                      <td mat-cell *matCellDef="let element"> {{element.sunday_entry_time | hourDecimalToHoursMinutes}} - {{element.sunday_exit_time | hourDecimalToHoursMinutes}} </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                  </div>
                </table>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12" id="vacations-info">
              <div class="vacations-section">
                <h2>Vacaciones totales</h2>
                <p class="subtitle">Por contrato, en el año actual</p>
                <p class="value">
                  {{ activeContract.vacation_days_info.vacations_total_days_available.days_total }} días
                  <span class="small">{{ activeContract.vacation_days_info.vacations_total_days_available.hours_total }} horas</span>
                </p>
              </div>
              <div class="vacations-section">
                <h2>Vacaciones restantes</h2>
                <p class="subtitle">Por contrato, en el año actual</p>
                <p class="value">
                  {{ (activeContract.vacation_days_info.vacations_total_days_available.days_total - activeContract.vacation_days_info.vacations_days_spent.days_total) | number:'1.0-2' }} días
                  <span class="small">{{ (activeContract.vacation_days_info.vacations_total_days_available.hours_total - activeContract.vacation_days_info.vacations_days_spent.hours_total) | number:'1.0-2' }} h.</span>
                </p>
              </div>
              <div class="vacations-section">
                <h2>Próximas vacaciones</h2>
                <p class="subtitle">Aceptadas</p>
                <p class="value">
                  - días
                  <span class="small">-</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  }

  @if (user != null && user.role.slug === 'client' && user.client_role === 'business') {
    <div class="company-data">
      <div class="page-title">
        <h1>Datos de empresa</h1>
      </div>
      <form class="card" [formGroup]="companyForm">
        <div class="container">
          <div class="row">
            <div class="column">
              <div class="container">
                <div class="row mt-4">
                  <div class="col-12 col-md-6 col-xl-3">
                    <mat-form-field class="nobottom" appearance="outline">
                      <mat-label>Nombre</mat-label>
                      <input type="text" matInput formControlName="fact_name">
                    </mat-form-field>
                  </div>
                  <div class="col-12 col-md-6 col-xl-3">
                    <mat-form-field class="nobottom" appearance="outline">
                      <mat-label>Email de contacto</mat-label>
                      <input type="text" matInput formControlName="email">
                    </mat-form-field>
                  </div>
                  <div class="col-12 col-md-6 col-xl-3">
                    <mat-form-field class="nobottom" appearance="outline">
                      <mat-label>Teléfono de contacto</mat-label>
                      <input type="text" matInput formControlName="phone">
                    </mat-form-field>
                  </div>
                  <div class="col-12 col-md-6 col-xl-3">
                    <mat-form-field class="nobottom" appearance="outline">
                      <mat-label>NIF/CIF</mat-label>
                      <input type="text" matInput formControlName="vat">
                    </mat-form-field>
                  </div>
                  <div class="col-12 col-md-6 col-xl-6">
                    <mat-form-field class="nobottom" appearance="outline">
                      <mat-label>Dirección de facturación</mat-label>
                      <input type="text" matInput formControlName="fact_address">
                    </mat-form-field>
                  </div>
                  <div class="col-12 col-md-6 col-xl-3">
                    <mat-form-field class="nobottom" appearance="outline">
                      <mat-label>Ciudad</mat-label>
                      <input type="text" matInput formControlName="fact_city">
                    </mat-form-field>
                  </div>
                  <div class="col-12 col-md-6 col-xl-3">
                    <mat-form-field class="nobottom" appearance="outline">
                      <mat-label>Código postal</mat-label>
                      <input type="text" matInput formControlName="fact_zipcode">
                    </mat-form-field>
                  </div>
                  <div class="col-12 col-md-6 col-xl-3">
                    <mat-form-field class="nobottom" appearance="outline">
                      <mat-label>Província</mat-label>
                      <input type="text" matInput formControlName="fact_county">
                    </mat-form-field>
                  </div>
                  <div class="col-12 col-md-6 col-xl-3">
                    <mat-form-field class="nobottom" appearance="outline">
                      <mat-label>País</mat-label>
                      <mat-select formControlName="fact_country">
                        @for (c of countries; track c) {
                          <mat-option [value]="c.name">{{ c.name }}</mat-option>
                        }
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
                <button mat-flat-button color="accent" class="mb-1" (click)="saveCompany()" type="submit" [disabled]="!(companyForm.valid)">
                  <mat-icon matPrefix>save</mat-icon>
                  Guardar
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  }

</div>
