import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { ProviderOrderAttachmentsDialogComponent } from 'src/app/components/utils/provider-order-attachments-dialog/provider-order-attachments-dialog.component';
import { ProviderOrderDialogComponent } from 'src/app/components/utils/provider-order-dialog/provider-order-dialog.component';
import { ProviderOrderAlert } from 'src/app/models/alert.model';
import { Provider } from 'src/app/models/provider.model';
import { ProviderOrder } from 'src/app/models/provider_order.model';
import { ProviderOrderCategory } from 'src/app/models/provider_order_category.model';
import { ApiService } from 'src/app/services/api.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-billing-provider-orders',
  templateUrl: './billing-provider-orders.component.html',
  styleUrls: ['./billing-provider-orders.component.css']
})
export class BillingProviderOrdersComponent implements OnInit {

  displayedColumns: string[] = ['id', 'date', 'payment_status', 'invoice', 'provider', 'type', 'actions'];
  dataSource:MatTableDataSource<ProviderOrder> = new MatTableDataSource<ProviderOrder>();

  // dades per el paginator
  length:number = 0;
  pageSize:number = 10;
  pageSizeOptions:number[] = [5,10,20,50];
  pageIndex:number = 1;

  filterForm:UntypedFormGroup;
  private formPersistence:any;
  periods:string[];

  providers_filtered:Provider[] = [];
  providers_property_control:UntypedFormControl = new UntypedFormControl('', Validators.compose([Validators.minLength(3)]));
  provider_order_categories:ProviderOrderCategory[] = [];

  now:Date = new Date();

  alerts:ProviderOrderAlert[] = [];

  constructor(private api:ApiService,
              private fb:UntypedFormBuilder,
              private router:Router,
              private activatedRoute:ActivatedRoute,
              private utils:UtilsService,
              public dialog: MatDialog) {

    this.filterForm = this.fb.group({
      search: [this.activatedRoute.snapshot.queryParamMap.get('search')!=null ? this.activatedRoute.snapshot.queryParamMap.get('search') : '', Validators.compose([Validators.minLength(3)])],
      per_page: [this.activatedRoute.snapshot.queryParamMap.get('per_page')!=null ? +(this.activatedRoute.snapshot.queryParamMap.get('per_page') as any) : this.pageSize, Validators.required],
      page: [this.activatedRoute.snapshot.queryParamMap.get('page')!=null ? +(this.activatedRoute.snapshot.queryParamMap.get('page') as any) : this.pageIndex, Validators.required],
      total: [this.activatedRoute.snapshot.queryParamMap.get('total')!=null ? +(this.activatedRoute.snapshot.queryParamMap.get('total') as any) : this.length],
      provider_id: [this.activatedRoute.snapshot.queryParamMap.get('provider_id')!=null ? +(this.activatedRoute.snapshot.queryParamMap.get('provider_id') as any) : ''],
      provider_order_category_id: [this.activatedRoute.snapshot.queryParamMap.get('provider_order_category_id')!=null ? +(this.activatedRoute.snapshot.queryParamMap.get('provider_order_category_id') as any) : ''],
      period: [this.activatedRoute.snapshot.queryParamMap.get('period')!=null ? (this.activatedRoute.snapshot.queryParamMap.get('period') as any) : this.getCurrentPeriod()],
      payment_status: [''],
    });

    this.periods = [this.filterForm.value['period']];
  }

  ngOnInit(): void {
    this.fetchAlerts();
    this.fetchPeriods();
    this.listenAutocompleteControls();
    this.initFilterFormListener();
    this.listenQueryParameters();
    this.fetchProviderOrderCategories();
  }

  fetchAlerts() {
    this.api.getProviderOrderAlerts({'monthly': true}).subscribe( data => {
      this.alerts = data;
    })
  }

  changePage(event:PageEvent) {
    this.filterForm.patchValue({
      page: event.pageIndex,
      per_page: event.pageSize
    });
  }

  selectProviderFilter(provider:Provider) {
    this.filterForm.patchValue({
      provider_id: provider.id,
      provider_order_category_id: provider.provider_order_category_id
    });
  }

  selectProviderOrderCategoryFilter(cat:ProviderOrderCategory) {
    this.filterForm.patchValue({ provider_category_id: cat.id });
  }

  addProviderOrder() {
    const dialogRef = this.dialog.open(ProviderOrderDialogComponent, {
      width: '800px'
    });

    dialogRef.afterClosed().subscribe((data:ProviderOrder) => {
      this.fetchProviderOrders();
      if(this.alerts.length>0) this.fetchAlerts(); // per eliminar alertes
    });
  }

  markAsPayed(order:ProviderOrder) {
    if(confirm("¿Estás seguro que quieres marcar este pedido como pagado?")) {
      this.api.markProviderOrderAsPayed(order.id.toString()).subscribe(
        data => {
          this.fetchProviderOrders();
        }
      );
    }
  }

  openProviderOrderFilesDialog(po:ProviderOrder) {
    const dialogRef = this.dialog.open(ProviderOrderAttachmentsDialogComponent, {
      width: '750px',
      data: {provider_order: po}
    });

    /*dialogRef.afterClosed().subscribe(result => {

    });*/
  }

  delete(i:number) {
    if(confirm("¿Estás seguro que quieres eliminar esta factura? También se eliminará en Contasimple.")) {
      this.api.deleteProviderOrder(this.dataSource.data[i].id).subscribe(
        data => {
          this.fetchProviderOrders();
        }
      );
    }
  }

  private getCurrentPeriod() {
    const date:Date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth()+1;
    const quarter = month < 10 ? (month < 7 ? (month < 4 ? 1 : 2) : 3) : 4;
    const period = year + "-" + quarter + "T";
    return period;
  }

  private fetchPeriods() {
    this.api.getBillingPeriods().subscribe(
      data => {
        this.periods = data;
      }
    );
  }

  private fetchProviderOrders() {
    this.api.getProviderOrdersPaged(this.filterForm.value).subscribe(
      data => {
        this.dataSource.data = data.data;

        this.length = +data.meta.total;
        this.pageIndex = +data.meta.current_page-1;
        this.pageSize = +data.meta.per_page;
      }
    );
  }

  private initFilterFormListener() {
    this.filterForm.valueChanges.subscribe(
      data => {
        data.per_page = +data.per_page;
        if(this.formPersistence==null || JSON.stringify(this.formPersistence)!=JSON.stringify(data)) {
          if(this.formPersistence.per_page!=data.per_page) data.page = 0;
          this.router.navigate(['/employees', 'direction', 'billing', 'provider-orders'], { queryParams: data });
        }
      }
    );
  }

  private listenQueryParameters() {
    this.activatedRoute.queryParams.subscribe(
      params => {
        if(JSON.stringify(params)!==JSON.stringify(this.filterForm.value)) { //si no ve de filtre s'ha de setejar el form
          let params_temp = this.utils.cloneObj(params); //params es read_only
          Object.keys(params_temp).forEach(param_key => {
            if(params_temp[param_key]!=null && params_temp[param_key]!="" && !isNaN(+params_temp[param_key])) params_temp[param_key] = +params_temp[param_key]; // si es numero, el transformem
          });
          this.filterForm.patchValue(params_temp, { emitEvent: false });
        }
        this.formPersistence = params;
        this.fetchProviderOrders();
      }
    );
  }

  private listenAutocompleteControls() {
    // providers autocomplete
    this.providers_property_control.valueChanges.subscribe(
      (data:string) => {
        if(this.providers_property_control.valid && data.length > 0) {
          let params:any = {};
          params['search'] = data;
          this.api.getProviders(params).subscribe(
            providers => {
              this.providers_filtered = providers;
            }
          );
        }
        else {
          this.filterForm.patchValue({ provider_id: '' });
          this.providers_filtered = [];
        }

      }
    );
  }

  private fetchProviderOrderCategories() {
    this.api.getProviderOrderCategories().subscribe(
      data => {
        this.provider_order_categories = data;
      }
    );
  }

}
