import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { CompanyNotificationChannel } from 'src/app/models/company_notification_channel.model';
import { ProjectPhaseBoardColRole } from 'src/app/models/project_phase_board_col_role.model';
import { User } from 'src/app/models/user.model';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-send-board-status-dialog',
  templateUrl: './send-board-status-dialog.component.html',
  styleUrls: ['./send-board-status-dialog.component.css']
})
export class SendBoardStatusDialogComponent implements OnInit {
  form:FormGroup;
  column_roles:{[key:string]:ProjectPhaseBoardColRole} = null as any;
  get column_roles_arr() { return Object.keys(this.column_roles) }

  company_users:User[] = [];
  company_notification_channels:CompanyNotificationChannel[] = [];

  usersSearchCtrl = new FormControl('');
  filteredUsers: Observable<User[]>;

  channelsSearchCtrl = new FormControl('');
  filteredChannels: Observable<CompanyNotificationChannel[]>;

  previewText:string = null as any;

  constructor(
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: {project_phase_id:number, company_id: number},
    private api:ApiService,
    private fb:FormBuilder
  ) {
    this.form = this.fb.group({
      col_roles: [[]],
      user_notifiables: [[]],
      company_notification_channels: [[]],
      from_date: [''],
      to_date: [''],
    });
  }

  ngOnInit(): void {
    this.initColRoles();
    this.initFilteredUsers();
    this.initFilteredChannels();
    this.fetchCompanyUsers();
    this.fetchCompanyNotificationChannels();
    this.listenAndGetPreview();
  }

  removeUser(idx:number) {
    const users:User[] = this.form.value.user_notifiables;
    users.splice(idx, 1);
    this.form.controls['user_notifiables'].patchValue(users);
  }

  removeChannel(i:number) {
    const channels:CompanyNotificationChannel[] = this.form.value.company_notification_channels;
    channels.splice(i, 1);
    this.form.controls['company_notification_channels'].patchValue(channels);
  }

  selectedUser(user:User) {
    const users:User[] = this.form.value.user_notifiables;
    users.push(user);
    this.form.controls['user_notifiables'].patchValue(users);
    this.usersSearchCtrl.setValue('');
  }

  selectedChannel(channel:CompanyNotificationChannel) {
    const channels:CompanyNotificationChannel[] = this.form.value.company_notification_channels;
    channels.push(channel);
    this.form.controls['company_notification_channels'].patchValue(channels);
    this.channelsSearchCtrl.setValue('');
  }

  close(): void {
    this.dialogRef.close();
  }

  submit() {
    const body:any = this.generateSubmitBody();
    this.api.sendBoardStatus(this.data.project_phase_id, body).subscribe(
      data => {
        this.close();
      }
    );
  }

  copyPreviewText() {
    navigator.clipboard.writeText(this.previewText);
  }

  private fetchCompanyUsers() {
    this.api.getCompanyUsers(this.data.company_id).subscribe(
      data => {
        this.company_users = data;
      }
    );
  }

  private fetchCompanyNotificationChannels() {
    this.api.getCompanyCompanyNotificationChannels(this.data.company_id).subscribe(
      data => {
        this.company_notification_channels = data;
      }
    );
  }

  private initColRoles() {
    this.column_roles = {};
    this.api.getProjectPhaseBoardColRoles().subscribe(
      data => {
        data.forEach(rol => {
          this.column_roles[rol.slug] = rol;
        });
        this.form.controls['col_roles'].patchValue(data.map(rol => rol.slug));

        this.fetchPreviewText();
      }
    );
  }

  private initFilteredUsers() {
    this.filteredUsers = this.usersSearchCtrl.valueChanges.pipe(
      startWith(null),
      map((user: string | null) => (user && (typeof user === "string") ? this._filterUsers(user) : this.company_users.slice())),
    );
  }

  private _filterUsers(user: string): User[] {
    const filterValue = user.toLowerCase();

    return this.company_users.filter(user => user.name.toLowerCase().includes(filterValue));
  }

  private initFilteredChannels() {
    this.filteredChannels = this.channelsSearchCtrl.valueChanges.pipe(
      startWith(null),
      map((channel: string | null) => (channel && (typeof channel === "string") ? this._filterChannels(channel) : this.company_notification_channels.slice())),
    );
  }

  private _filterChannels(channel: string): CompanyNotificationChannel[] {
    const filterValue = channel.toLowerCase();

    return this.company_notification_channels.filter(channel => channel.email?.toLowerCase().includes(filterValue) || channel.slack_channel_name?.toLowerCase().includes(filterValue));
  }

  private listenAndGetPreview() {
    const func = (data:any) => {
      if(this.form.value['col_roles'].length > 0 && (
        (this.form.value['from_date'] != '' && this.form.value['to_date'] != '') ||
        (this.form.value['from_date'] == '' && this.form.value['to_date'] == '')
      )) {
        this.fetchPreviewText()
      }
      else this.previewText = null as any;
    };

    this.form.controls['col_roles'].valueChanges.subscribe(func);
    this.form.controls['from_date'].valueChanges.subscribe(func);
    this.form.controls['to_date'].valueChanges.subscribe(func);

  }

  private generateSubmitBody(include_notifiables:boolean = true) {
    const body:any = {...this.form.value};
    if(include_notifiables) {
      body.user_notifiables = body.user_notifiables.map((item:User) => item.id);
      body.company_notification_channels = body.company_notification_channels.map((item:CompanyNotificationChannel) => item.id);
    }
    else {
      delete body.user_notifiables;
      delete body.company_notification_channels;
    }
    return body;
  }

  private fetchPreviewText() {
    const body:any = this.generateSubmitBody(false);
    this.api.getBoardStatus(this.data.project_phase_id, body, true).subscribe(
      data => {
        this.previewText = data.text;
      }
    );
  }
}
