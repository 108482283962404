import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Company } from 'src/app/models/company.model';
import { Department } from 'src/app/models/department.model';
import { Role } from 'src/app/models/role.model';
import { ApiService } from 'src/app/services/api.service';
import { PermissionsService } from 'src/app/services/permissions.service';
import { RegexService } from 'src/app/services/regex.service';

@Component({
  selector: 'app-add-user-dialog',
  templateUrl: './add-user-dialog.component.html',
  styleUrls: ['./add-user-dialog.component.css']
})
export class AddUserDialogComponent implements OnInit {

  userForm:UntypedFormGroup;
  roles:Role[];
  available_customers:Company[] = [];
  company_id:number;

  clientSelectControl = new UntypedFormControl();
  customerSelected:Company = null as any;

  constructor(private fb:UntypedFormBuilder,
              public dialogRef: MatDialogRef<AddUserDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data:any,
              private regex:RegexService,
              private api:ApiService,
              private permissions:PermissionsService) {

    const regexPatterns = this.regex.getRegex();

    this.company_id = data.company_id;

    this.userForm = this.fb.group({
      name: ['', Validators.compose([Validators.required])],
      surnames: ['', Validators.compose([Validators.required])],
      email: ['', Validators.compose([Validators.required, Validators.email])],
      sex: ['', Validators.compose([Validators.required])],
      birthday: ['', Validators.compose([])],
      with_password: [false],
      phone: ['', Validators.compose([Validators.pattern(regexPatterns['phone'])])],
      role: ['', Validators.compose([Validators.required])],
      company_id: [''],
      password: ['', Validators.compose([Validators.minLength(8)])],
      password_confirmation: [''],
      password_valid: [false, Validators.requiredTrue],
      client_role: ['']
    });

  }

  ngOnInit(): void {
    this.initRoles();
    this.listenForm();
    this.listenControlSearch();
    if(this.company_id){
      this.userForm.patchValue({company_id: this.company_id, role: 'client' });
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  selectCustomer(c:Company) {
    this.userForm.patchValue({company_id: c.id});
  }

  resetCustomer() {
    this.available_customers = [];
    this.userForm.patchValue({company_id: null});
  }

  displayFn(company:Company): string {
    return company ? company.fact_name : '';
  }

  submit() {
    const body:any = { ...this.userForm.value };
    delete body.password_valid;
    delete body.with_password;
    if(body.password === "") {
      delete body.password;
      delete body.password_confirmation;
    }

    this.api.createUser(body).subscribe(data => {
      this.dialogRef.close(data);
    });
  }

  private listenForm() {
    this.userForm.valueChanges.subscribe(
      data => {
        // password valid
        let valid:boolean = false;

        if(data['password'] == data['password_confirmation']) valid = true;
        else valid = false;

        this.userForm.patchValue({ password_valid: valid }, { emitEvent: false});
      }
    );
  }

  private listenControlSearch() {
    this.clientSelectControl.valueChanges.subscribe(
      (search_string:string) => {
        if(search_string != null && search_string.length >= 3) {
          const params = {
            search: search_string
          };
          this.api.getCompanies(params).subscribe(
            data => {
              this.available_customers = data.data;
            }
          );
        }
        else this.available_customers = [];
      }
    );
  }

  private initRoles() {
    this.permissions.rolesObservable.subscribe(
      data => {
        if(data != null) {
          this.roles = Object.keys(data).map(k => data[k]);
        }
      }
    );
  }

}
