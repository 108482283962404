import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ProviderOrderAttachmentsDialogComponent } from 'src/app/components/utils/provider-order-attachments-dialog/provider-order-attachments-dialog.component';
import { BusinessMail } from 'src/app/models/business_mail.model';
import { Order } from 'src/app/models/order.model';
import { ProviderOrder } from 'src/app/models/provider_order.model';
import { ApiService } from 'src/app/services/api.service';
import { PermissionsService } from 'src/app/services/permissions.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-business-mail-detail-billing',
  templateUrl: './business-mail-detail-billing.component.html',
  styleUrls: ['./business-mail-detail-billing.component.css']
})
export class BusinessMailDetailBillingComponent implements OnInit {

  private _business_mail:BusinessMail = null as any;
  get business_mail() { return this._business_mail; };
  @Input() set business_mail(bm:BusinessMail) {
    if(bm!=null) {
      this._business_mail = bm;
      this.initDataSources();
    }
  }

  ordersDataSource:MatTableDataSource<Order> = new MatTableDataSource<Order>([]);
  providerOrdersDataSource:MatTableDataSource<ProviderOrder> = new MatTableDataSource<ProviderOrder>([]);

  displayedColumns:string[] = ['date', 'total', 'payment_status', 'invoice_number', 'actions'];

  constructor(private api:ApiService,
              private utils:UtilsService,
              public dialog:MatDialog,
              public permissions:PermissionsService) { }

  ngOnInit(): void {
  }

  downloadOrderInvoice(order:Order) {
    this.api.downloadOrderInvoice(order.id.toString()).subscribe(
      response => {
        this.utils.downloadPdfFromBlobResponse(response);
      }
    );
  }

  refund(o:Order) {
    if(confirm("¿Estás seguro que quieres devolver este pago? Se devolverá el importe total y se creará una factura rectificativa.")) {
      this.api.refundOrder(o.id.toString()).subscribe(
        data => {
          let datasource = this.ordersDataSource.data;
          datasource.push(data);
          this.ordersDataSource = new MatTableDataSource(datasource);
        }
      );
    }
  }

  openProviderOrderFilesDialog(po:ProviderOrder) {
    const dialogRef = this.dialog.open(ProviderOrderAttachmentsDialogComponent, {
      width: '750px',
      data: {provider_order: po}
    });

    /*dialogRef.afterClosed().subscribe(result => {
      
    });*/
  }

  private initDataSources() {
    this.ordersDataSource.data = this.business_mail.orders;
    this.providerOrdersDataSource.data = this.business_mail.provider_orders;
  }

}
