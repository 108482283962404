<h1 mat-dialog-title>Asignar guardia</h1>

<mat-dialog-content [formGroup]="form">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <mat-form-field appearance="outline">
          <mat-label>Trabajador</mat-label>
          <mat-select formControlName="user_id">
            @for (user of users; track user) {
              <mat-option [value]="user.id">{{ user.name + ' ' + user.surnames }}</mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button (click)="onNoClick()" cdkFocusInitial type="button">Cancelar</button>
  <button mat-flat-button color="accent" (click)="submit()" [disabled]="!form.valid" type="submit">
    Asignar guardia
  </button>
</mat-dialog-actions>
