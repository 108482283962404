import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/models/user.model';
import { WorkingTimeSummary } from 'src/app/models/working-time-summary.model';
import { ApiService } from 'src/app/services/api.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  today_data: any[] = null as any;
  active_employees: User[] = null as any;
  this_week_summary:WorkingTimeSummary;
  prev_week_summary:WorkingTimeSummary;
  this_month_summary:WorkingTimeSummary;
  prev_month_summary:WorkingTimeSummary;

  constructor(
    private api: ApiService,
    private userService: UserService
  ) { }

  ngOnInit(): void {
    this.fetchData();
    this.fetchActiveEmployees();
    this.fetchWorkingTimeSummary();
  }

  fetchData() {
    this.api.getUsersTodayStatus().subscribe((res: any) => {
      this.today_data = res;
    });
  }

  fetchActiveEmployees() {
    this.api.getActiveEmployees({'with': 'current_working_contract'}).subscribe((res: any) => {
      this.active_employees = res;
    });
  }

  fetchWorkingTimeSummary() {
    // * ---- SEMANA ---- *

    // Obtenemos la fecha actual
    let today = new Date();

    // Obtenemos el número del día de la semana (0-6)
    let dayOfWeek = today.getDay();

    // Calculamos la fecha del lunes de esta semana
    let mondayThisWeek = new Date(today);
    mondayThisWeek.setDate(today.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1));

    // Calculamos la fecha del domingo de esta semana
    let sundayThisWeek = new Date(today);
    sundayThisWeek.setDate(today.getDate() - dayOfWeek + 7);

    // Calculamos la fecha del lunes de la semana anterior
    let mondayLastWeek = new Date(today);
    mondayLastWeek.setDate(today.getDate() - dayOfWeek - 6);

    // Calculamos la fecha del domingo de la semana anterior
    let sundayLastWeek = new Date(today);
    sundayLastWeek.setDate(today.getDate() - dayOfWeek);

    this.api.getWorkingTimeSummary(this.userService.getCurrentUser().id.toString(), mondayThisWeek, sundayThisWeek).toPromise().then(
      data => {
        if(data != undefined) {
          this.this_week_summary = data;
        }
      }
    );

    this.api.getWorkingTimeSummary(this.userService.getCurrentUser().id.toString(), mondayLastWeek, sundayLastWeek).toPromise().then(
      data => {
        if(data != undefined) this.prev_week_summary = data;
      }
    );

    // * ---- MES ---- *

    // Obtenemos el primer día del mes actual
    let firstDayThisMonth = new Date(today.getFullYear(), today.getMonth(), 1);

    // Obtenemos el último día del mes actual
    let lastDayThisMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);

    // Obtenemos el primer día del mes anterior
    let firstDayLastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);

    // Obtenemos el último día del mes anterior
    let lastDayLastMonth = new Date(today.getFullYear(), today.getMonth(), 0);


    this.api.getWorkingTimeSummary(this.userService.getCurrentUser().id.toString(), firstDayThisMonth, lastDayThisMonth).toPromise().then(
      data => {
        if(data != undefined) this.this_month_summary = data;
      }
    );

    this.api.getWorkingTimeSummary(this.userService.getCurrentUser().id.toString(), firstDayLastMonth, lastDayLastMonth).toPromise().then(
      data => {
        if(data != undefined) this.prev_month_summary = data;
      }
    );

  }

  isToday(date: Date) {
    const today = new Date();
    const d = new Date(date);
    return d.getDate() === today.getDate() &&
      d.getMonth() === today.getMonth();
  }
}
