import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-my-zone',
  templateUrl: './my-zone.component.html',
  styleUrls: ['./my-zone.component.css']
})
export class MyZoneComponent implements OnInit {

  navLinks: any[] = [
    {
      label: 'Resumen',
      link: './resume',
      index: 0
    },
    {
      label: 'Registro de trabajo',
      link: './history',
      index: 1
    },
    {
      label: 'Control horario',
      link: './clock-registers',
      index: 2
    },
    {
      label: 'Calendario',
      link: './calendar',
      index: 3
    },
    {
      label: 'Nóminas',
      link: './payrolls',
      index: 4
    },
    {
      label: 'Contratos',
      link: './my-working-contracts',
      index: 5
    },
    {
      label: 'Vacaciones',
      link: './absences',
      index: 6
    }
  ];

  queryParams:any = {};

  constructor(private activatedRoute:ActivatedRoute) {}

  ngOnInit(): void {
    this.listenQueryParams();
  }

  private listenQueryParams() {
    this.activatedRoute.queryParamMap.subscribe((data:any) => {
      const params = data.params;

      if(params.from!=null) delete params.from;
      if(params.to!=null) delete params.to;

      this.queryParams = params;
    });
  }

}
