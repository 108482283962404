<h1 mat-dialog-title>Pasar tarea a en progreso</h1>

<mat-dialog-content>
  @if(project_task_board != undefined && users != undefined) {
    <form class="container" [formGroup]="form">
      <div class="row">
        <div class="col-12">
          <mat-form-field appearance="outline" style="margin-top: 5px">
            <mat-label>Escoge qué se va a hacer:</mat-label>
            <mat-select formControlName="active_project_task_board_time_budget_id">
              @for(time_budget of project_task_board.project_task_board_time_budgets; track time_budget;) {
                @if(isTimeBudgetSelectable(time_budget)) {
                  <mat-option [value]="time_budget.id">{{ time_budget.budgetable.name }}</mat-option>
                }
              }
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-12">
          <mat-form-field appearance="outline">
            <mat-label>¿Quién lo va a hacer?</mat-label>
            <mat-select formControlName="assignee_id">
              @for(user of users; track user;) {
                <mat-option [value]="user.id">{{ user.name + ' ' + user.surnames }}</mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </form>
  }
  @else {
    Cargando...
  }
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-flat-button color="accent" (click)="submit()" [disabled]="!form.valid">
    <mat-icon>check_circle</mat-icon>
    Pasar a producción
  </button>
</mat-dialog-actions>
