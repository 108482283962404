import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { BehaviorSubject, Observable } from 'rxjs';
import { User } from '../models/user.model';
import { WorkingContract } from '../models/working_contract.model';
import { ApiService } from './api.service';
import { UtilsService } from './utils.service';
import { EmployeeCalendarRegisterWithRelationsSerialized } from '../models/employee_calendar_register.model';
import { MatDialog } from '@angular/material/dialog';
import { CalendarAssignmentDialogComponent } from '../components/main/employees/project-management/project-assignment/calendar-assignment-dialog/calendar-assignment-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  // Current user session
  private localstorageUserKey:string = "bisual-user";
  private currentUserSubject: BehaviorSubject<User> = new BehaviorSubject(null as any);
  public currentUser: Observable<User> = this.currentUserSubject.asObservable();

  // Ongoing task variable
  private todaysTasks:CalendarRegisterLocalItem[] = [];
  private currentTaskSubject: BehaviorSubject<{ current:CalendarRegisterLocalItem, next:CalendarRegisterLocalItem }> = new BehaviorSubject({current: null as any, next:null as any});
  public currentTask : Observable<{ current: CalendarRegisterLocalItem, next: CalendarRegisterLocalItem }> = this.currentTaskSubject.asObservable();

  // Current working contract from user
  private currentWorkingContractActiveSubject: BehaviorSubject<WorkingContract> = new BehaviorSubject(null as any);
  public workingContractActive : Observable<WorkingContract> = this.currentWorkingContractActiveSubject.asObservable();

  // Guard status
  private currentGuardStatusSubject: BehaviorSubject<{active:boolean; project_names:string|undefined}> = new BehaviorSubject({active: false, project_names: undefined} as any);
  public currentGuardStatus : Observable<{active:boolean; project_names:string|undefined}> = this.currentGuardStatusSubject.asObservable();

  constructor(
    private apiService : ApiService,
    private utils:UtilsService,
    private dialog:MatDialog
  ) { }

  public getCurrentUser(): User {
    return this.currentUserSubject.value;
  }

  public setUser(user:User, keepAlive:boolean = true) {
    if(user!=null && user!=undefined) {
      if(keepAlive) {
        localStorage.setItem(this.localstorageUserKey, JSON.stringify(user));
      }
      this.currentUserSubject.next(user);
      this.loadCurrentActiveProjects(user.id);
      if(user.role.slug==="worker") {
        this.loadCurrentWorkingContract();
        this.loadCurrentGuardianStatus();
        this.loadRequiredActions();
      }
    }
  }

  public deleteUser() {
    localStorage.removeItem(this.localstorageUserKey);
    this.currentUserSubject.next(null as any);
  }

  public getCurrentTask(): any {
    return this.currentTaskSubject.value;
  }

  private loadRequiredActions() {
    this.apiService.getMyActionsRequired().subscribe(
      data => {
        if(data['has_to_set_employee_calendar_registers'] != null && data['has_to_set_employee_calendar_registers'] === true) {
          this.openAssignationCalendar();
        }
      }
    );
  }

  private loadTodaysTasks() {
    let today = new Date();
     this.apiService.getEmployeeCalendarRegistersForUserSerialized(this.getCurrentUser().id.toString(), new Date(today.setHours(0,0,0)), new Date(today.setHours(23,59,59))).subscribe(
        (data: any) => {
          this.todaysTasks = data.map((item: EmployeeCalendarRegisterWithRelationsSerialized): CalendarRegisterLocalItem => {
            let title:string = "";
            if(item.project_id != null) {
              title = item.project_title + " - " + item.ecr_wtc_name;
              if(item.project_phase_name != null) title += " (" + item.project_phase_name + ")"
            }
            else title = item.wtc_name as string;

            return {
              id: item.id,
              title: title,
              start: new Date(item.from_date),
              end: new Date(item.to_date),
              color:  item.project_color ?? item.ecr_wtc_color ?? item.wtc_color ?? '#ad2121',
            };
          }).sort((a:CalendarRegisterLocalItem,b:CalendarRegisterLocalItem) => a.start < b.start);

          this.updateCurrentTask();
        }
     );
  }

  private async updateCurrentTask() {
    // Inicialitzem
    let duoCurrentTasks = this.currentTaskSubject.value;
    if(duoCurrentTasks == null || duoCurrentTasks == undefined) { // si no han estat inicialitzats... Això mai hauria de passar
      duoCurrentTasks = { current: null as any, next: null as any };
    }

    if(this.todaysTasks.length > 0) {
      // if(duoCurrentTasks.next != null) {
      //   duoCurrentTasks.current = duoCurrentTasks.next;
      //   duoCurrentTasks.next = this.todaysTasks[0];
      //   this.todaysTasks.shift();
      // } else {
      //   while(this.todaysTasks[0].end.getTime() < new Date().getTime()) {
      //     this.todaysTasks.shift();
      //   }
      //   duoCurrentTasks.current = this.todaysTasks[0];
      //   this.todaysTasks.shift();
      //   duoCurrentTasks.next = this.todaysTasks[0];
      //   this.todaysTasks.shift();
      // }

      const current_task_index = this.getCurrentTaskIndex();
      if(current_task_index != null) { // Si està en una tasca actualment, obtenim tasca
        duoCurrentTasks.current = this.todaysTasks[current_task_index];
        const nextIndex = current_task_index + 1;
        duoCurrentTasks.next = nextIndex < this.todaysTasks.length ? this.todaysTasks[nextIndex] : null as any;
      }
      else if(this.isWorkingTimeNow()) { // Està en hora lliure, i després li vindrà una altra tasca
        duoCurrentTasks.current = {
          id: null as any,
          title: 'Hora libre',
          start: null as any,
          end: null as any,
          color: null as any
        };
        duoCurrentTasks.next = null as any;
      }
      else { // Si no, que no mostri res
        duoCurrentTasks = {
          current: null as any,
          next: null as any
        };
      }

    }

    this.currentTaskSubject.next(duoCurrentTasks);

    const sleep_ms = duoCurrentTasks.current!=null && duoCurrentTasks.current.end!=null ? Math.max(duoCurrentTasks.current.end.getTime() - new Date().getTime(), 1000) : 30000;
    await this.utils.sleep(sleep_ms);

    this.updateCurrentTask();
  }

  private getCurrentTaskIndex() {
    const now = new Date();
    let i:number = 0;
    let trobat:boolean = false;
    let fi:boolean = false;
    let res_index:number = null as any;

    // Ordenem per data d'inici
    this.todaysTasks.sort((a: CalendarRegisterLocalItem, b: CalendarRegisterLocalItem) => {
      return a.start.getTime() - b.start.getTime();
    });

    while(!trobat && !fi) {
      if(this.todaysTasks[i].start <= now && this.todaysTasks[i].end > now) {
        trobat = true;
        res_index = i;
      }
      else if(this.todaysTasks[i].start > now) {
        trobat = true; // no és ben bé trobat... Vol dir que ens trobem en un buit entre tasques...
      }

      i++;
      fi = i>=this.todaysTasks.length;
    }

    return res_index;
  }

  private loadCurrentWorkingContract() {
    this.apiService.getActiveWorkingContract(this.currentUserSubject.value.id).subscribe(
      data => {
        this.currentWorkingContractActiveSubject.next(data);
        this.loadTodaysTasks();
      }
    );
  }

  private loadCurrentGuardianStatus() {
    this.apiService.getMyGuardianStatus().subscribe(
      data => {
        this.currentGuardStatusSubject.next(data);
      }
    );
  }

  private loadCurrentActiveProjects(id:number) {
    this.apiService.getUserProjectsActiveRoles(id).subscribe(
      data => {
       this.currentUserSubject.value.active_projects = data;
      }
    );
  }

  private isWorkingTimeNow() {
    const now = moment();
    const hour_with_decimal:number = now.hours() + (now.minutes()/60);

    type WorkingContractKey = keyof typeof this.currentWorkingContractActiveSubject.value;
    const morning_entry_time:WorkingContractKey = `${now.format('dddd').toLowerCase()}_morning_entry_time` as WorkingContractKey;
    const morning_exit_time:WorkingContractKey = `${now.format('dddd').toLowerCase()}_morning_exit_time` as WorkingContractKey;
    const afternoon_entry_time:WorkingContractKey = `${now.format('dddd').toLowerCase()}_afternoon_entry_time` as WorkingContractKey;
    const afternoon_exit_time:WorkingContractKey = `${now.format('dddd').toLowerCase()}_afternoon_exit_time` as WorkingContractKey;

    const wc = this.currentWorkingContractActiveSubject.value;

    return ( // si està entre les hores de mati
      wc[morning_entry_time]!=null && (wc[morning_entry_time] as any) <= hour_with_decimal &&
      wc[morning_exit_time]!=null && (wc[morning_exit_time] as any) > hour_with_decimal
    ) || ( // o bé si està entre les hores de tarda
      wc[afternoon_entry_time]!=null && (wc[afternoon_entry_time] as any) <= hour_with_decimal &&
      wc[afternoon_exit_time]!=null && (wc[afternoon_exit_time] as any) > hour_with_decimal
    );
  }

  private openAssignationCalendar() {
    const dialogRef = this.dialog.open(CalendarAssignmentDialogComponent, {
      width: '90%',
      disableClose: true,
      data: {
        from: moment().startOf('week').toDate(),
        to: moment().endOf('week').toDate(),
        user_id: this.currentUserSubject.value.id
      }
    });

  }
}

interface CalendarRegisterLocalItem {
  id:number;
  title:string;
  start:Date;
  end:Date;
  color:string;
}
