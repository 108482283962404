import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'projectTaskTypeFieldPipe'
})
export class ProjectTaskTypeFieldPipePipe implements PipeTransform {

  transform(value: string): string {
    switch(value) {
      case 'issue':
        return 'Bug';
      case 'task':
        return 'Tarea';
      case 'story':
        return 'Story';
      default:
        return '';
    }
  }

}
