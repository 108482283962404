<h1 mat-dialog-title>{{ is_edit ? 'Editar cliente potencial' : 'Añadir cliente potencial'}}</h1>

<mat-dialog-content [formGroup]="form">
  <div class="container">
    <div class="row">
      <div class="col-12 col-lg-4">
        <mat-form-field appearance="outline">
          <mat-label>Nombre de la persona</mat-label>
          <input type="text" matInput formControlName="full_name" />
        </mat-form-field>
      </div>
      <div class="col-12 col-lg-4">
        <mat-form-field appearance="outline">
          <mat-label>Empresa</mat-label>
          <input type="text" matInput formControlName="company_name" />
        </mat-form-field>
      </div>
      <div class="col-12 col-lg-4">
        <mat-form-field appearance="outline">
          <mat-label>Email</mat-label>
          <input type="email" matInput formControlName="email" />
        </mat-form-field>
      </div>
      <div class="col-12 col-lg-4">
        <mat-form-field appearance="outline">
          <mat-label>Teléfono</mat-label>
          <input type="text" matInput formControlName="phone" />
        </mat-form-field>
      </div>
      <div class="col-12 col-lg-4">
        <mat-form-field appearance="outline">
          <mat-label>Estado</mat-label>
          <mat-select matInput formControlName="potential_client_status_slug">
            @for (item of potential_client_statuses; track item) {
              <mat-option [value]="item.slug">{{ item.name }}</mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-12 col-lg-4">
        <mat-form-field appearance="outline">
          <mat-label>Estrategia de contacto</mat-label>
          <mat-select matInput formControlName="contact_strategy">
            <mat-option [value]="'volume'">Volumen</mat-option>
            <mat-option [value]="'quality'">Calidad</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-12 col-lg-4">
        <mat-form-field appearance="outline">
          <mat-label>Canal de contacto</mat-label>
          <mat-select matInput formControlName="contact_channel">
            <mat-option [value]="'email'">Email</mat-option>
            <mat-option [value]="'phone'">Teléfono</mat-option>
            <mat-option [value]="'linkedin'">LinkedIn</mat-option>
            <mat-option [value]="'other'">Otro</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-12 col-lg-4">
        <mat-form-field appearance="outline">
          <mat-label>Comercial</mat-label>
          <input type="text" matInput [formControl]="comercialSelectControl" [matAutocomplete]="comercial_select_auto">
          <mat-autocomplete #comercial_select_auto="matAutocomplete" [displayWith]="displayFnComercial">
            @for (c of available_comercials; track c) {
              <mat-option [value]="c" (click)="selectComercial(c)">{{ c.name + ' ' + c.surnames }}</mat-option>
            }
          </mat-autocomplete>
          <mat-icon matSuffix>expand_more</mat-icon>
        </mat-form-field>
      </div>
      <div class="col-12 col-lg-4">
        <mat-form-field appearance="outline">
          <mat-label>Product Owner</mat-label>
          <input type="text" matInput [formControl]="productOwnerSelectControl" [matAutocomplete]="product_owner_select_auto">
          <mat-autocomplete #product_owner_select_auto="matAutocomplete" [displayWith]="displayFnProductOwner">
            @for (po of available_product_owners; track po) {
              <mat-option [value]="po" (click)="selectProductOwner(po)">{{ po.name + ' ' + po.surnames }}</mat-option>
            }
          </mat-autocomplete>
          <mat-icon matSuffix>expand_more</mat-icon>
        </mat-form-field>
      </div>
      <div class="col-12">
        <mat-form-field appearance="outline">
          <mat-label>Descripción</mat-label>
          <textarea matInput formControlName="description"></textarea>
        </mat-form-field>
      </div>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button (click)="close()" type="button">Cancelar</button>
  <button mat-flat-button color="accent" [mat-dialog-close]="form.value" [disabled]="!form.valid" type="submit">
    @if (is_edit) {
      <mat-icon style="margin-right: 10px">edit</mat-icon>
      Actualizar cliente potencial
    }
    @if (!is_edit) {
      <mat-icon style="margin-right: 10px">add_user</mat-icon>
      Crear cliente potencial
    }
  </button>
</mat-dialog-actions>
