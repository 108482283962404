import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { VacationRequest } from 'src/app/models/vacation_request';
import { WorkingContract } from 'src/app/models/working_contract.model';
import { ApiService } from 'src/app/services/api.service';
import { UserService } from 'src/app/services/user.service';
import { ModalMyAbsencesComponent } from './modal-my-absences/modal-my-absences.component';
import { VacationRequestPeriod } from 'src/app/models/vacation_request_period';
import { DatePipe } from '@angular/common';
import { EmployeeCalendarFestivitiesDialogComponent } from './employee-calendar-festivities-dialog/employee-calendar-festivities-dialog.component';

@Component({
  selector: 'app-my-absences',
  templateUrl: './my-absences.component.html',
  styleUrls: ['./my-absences.component.css'],
})
export class MyAbsencesComponent implements OnInit {

  now:Date = new Date();
  vacation_requests:VacationRequest[] = [];

  contract:WorkingContract = null as any;

  constructor(
    private api: ApiService,
    private dialog:MatDialog,
    private user:UserService,
    private activatedRoute:ActivatedRoute,
  ) {

  }

  ngOnInit(): void {
    this.fetchMyAbsences();
    this.fetchActiveWorkingContract();
  }

  open() {
    const dialogRef = this.dialog.open(ModalMyAbsencesComponent, {
      width: '1400px',
      data: {
        vacations_hours_remaining_accepted:
          this.contract?.vacation_days_info?.vacations_total_days_available.hours_total - this.contract?.vacation_days_info?.vacations_days_spent.hours_total,
      }
    });

    dialogRef.afterClosed().toPromise().then(
      (data:any) => {
        if(data!=null && data.refresh===true) {
          this.fetchMyAbsences();
        }
      }
    );
  }

  openCalendarFestivities() {
    const dialogRef = this.dialog.open(EmployeeCalendarFestivitiesDialogComponent, {
      width: '500px',
      data: {}
    });

    dialogRef.afterClosed().toPromise().then(
      (data:any) => {
        if(data!=null && data.refresh===true) {
          this.fetchMyAbsences();
        }
      }
    );
  }

  public eliminateVacationRequest(id: any) {
    this.api.deleteVacationRequest(id).subscribe(
      (data) => {
        this.fetchMyAbsences();
        this.fetchActiveWorkingContract();
      }
    );
  }

  private fetchMyAbsences() {
    this.api.getVacationRequests({ user_id: this.user.getCurrentUser().id, with: 'user,vacation_request_periods' }).subscribe(
      data => {
        this.vacation_requests = data.data;
      }
    );
  }

  private fetchActiveWorkingContract() {
    this.api.getActiveWorkingContract(this.user.getCurrentUser().id).subscribe(data => {
      this.contract = data;
    })

  }

  nextTitle(next_day: any) {
    let date = new Date();
    return date.setDate(date.getDate() + next_day);
  }

  showAllDays(vacationRequest: VacationRequest) {
    var datePipe = new DatePipe('es-ES');
    let allDays = '';
    vacationRequest.vacation_request_periods.forEach((p: VacationRequestPeriod) => {
      allDays += datePipe.transform(p.day, "dd/MM/yyyy") + ' - ';
    });
    return allDays.slice(0, -3);

  }
}
