<h1 mat-dialog-title>Mover</h1>
<mat-dialog-content>
    <div class="container">

        <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="bisual-tree">
          <mat-nested-tree-node *matTreeNodeDef="let node;">
            <div class="mat-tree-node" [ngClass]="{'selected': selectedNode!=null && node.id === selectedNode.id}">
                <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.name">
                  <mat-icon class="mat-icon-rtl-mirror">
                    {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                  </mat-icon>
                </button>
                <button mat-button (click)="selectItem(node)" class="title-node">
                  <mat-icon>{{node.icon ?? 'folder'}}</mat-icon>
                  {{node.name}}
                </button>
              </div>
              <div [class.bisual-tree-invisible]="!treeControl.isExpanded(node)"
                  role="group">
                <ng-container matTreeNodeOutlet></ng-container>
              </div>
          </mat-nested-tree-node>
        </mat-tree>
    </div>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-button (click)="close()">Cancelar</button>
    <button mat-flat-button color="accent" [disabled]="!selectedNode" (click)="move()">
        <mat-icon style="margin-right: 10px">add_user</mat-icon>
        Mover
    </button>
</mat-dialog-actions>
