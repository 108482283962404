import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-restore-password',
  templateUrl: './restore-password.component.html',
  styleUrls: ['./restore-password.component.css']
})
export class RestorePasswordComponent implements OnInit {

  form:FormGroup;
  token:string = "";
  token_provided:boolean = false;

  constructor(private fb:FormBuilder,
              private router:Router,
              private api:ApiService,
              private snack:MatSnackBar,
              private activatedRoute:ActivatedRoute) {
    this.form = this.fb.group({
      token: ['', Validators.compose([Validators.required])],
      password: ['', Validators.compose([Validators.required, Validators.minLength(4)])],
      repeat_password: ['', Validators.compose([Validators.required, Validators.minLength(4)])],
    }, { validator: this.checkPasswords });
  }

  ngOnInit(): void {
    this.token = this.activatedRoute.snapshot.queryParamMap.get("token")!;
    this.token_provided = this.token ? true : false;
    this.form.patchValue({token: this.token});
  }

  submit() {
    this.api.resetPassword(this.form.value).subscribe(
      data => {
        this.snack.open("Se ha actualizado su contraseña correctamente", "Ok", { duration: 3000 });
        this.router.navigate(['/auth/login']);
      },
      (error:any) => {
        if(error instanceof HttpErrorResponse) {
          if(error.status == 401) {
            this.snack.open("El correo y/o la contraseña son incorrectos", "Ok", { duration: 3000 });
          }
          else {
            this.snack.open("Error al conectar con el servidor", "Ok", { duration: 3000 });
          }
        }
        else {
          this.snack.open(error, "Ok", { duration: 3000 });
        }
      }
    );
  }

  checkPasswords(group: FormGroup) {
    let pass = group.controls.password.value;
    let confirmPass = group.controls.repeat_password.value;
    return pass === confirmPass ? null : { notSame: true }
  }

}
