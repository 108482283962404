import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Project } from 'src/app/models/project.model';
import { ApiService } from 'src/app/services/api.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-project-detail',
  templateUrl: './project-detail.component.html',
  styleUrls: ['./project-detail.component.css']
})
export class ProjectDetailComponent implements OnInit {

  navLinks: any[];

  activeLinkIndex = 0;


  constructor(private activatedRoute:ActivatedRoute,
              private api:ApiService,
              private userService:UserService,
              private router:Router) {
  }

  queryParams:any = {};



  ngOnInit(): void {
    this.setNavLinks();
    this.router.events.subscribe((res) => {
      this.activeLinkIndex = this.navLinks.indexOf(this.navLinks.find(tab => tab.link === '.' + this.router.url));
    });
    this.listenQueryParams();
  }

  private setNavLinks() {
    if(this.userService.getCurrentUser().role.slug == 'worker') {
      this.navLinks = [
        {
          label: 'Resumen',
          link: './resume',
          index: 0
        },
        {
          label: 'Fases',
          link: './phases',
          index: 1
        },
        {
          label: 'Reuniones',
          link: './meeting-records',
          index: 2
        },
        {
          label: 'Aplicaciones',
          link: './applications',
          index: 3
        },
        {
          label: 'Guardias',
          link: './guardian-scheduled-weeks',
          index: 4
        },
        {
          label: 'Equipos',
          link: './project-teams',
          index: 5
        }
        // {
        //   label: 'Contratos',
        //   link: './contracts',
        //   index: 3
        // },
        // {
        //   label: 'Presupuestos',
        //   link: './budgets',
        //   index: 4
        // }

      ];
    } else if(this.userService.getCurrentUser().role.slug == 'client') {
        this.navLinks = [
          {
            label: 'Fases',
            link: './phases',
            index: 0
          },
          // {
          //   label: 'Contratos',
          //   link: './contracts',
          //   index: 1
          // },
          // {
          //   label: 'Presupuestos',
          //   link: './budgets',
          //   index: 2
          // }
        ];
      }
  }

  private listenQueryParams() {
    this.activatedRoute.queryParamMap.subscribe((data:any) => {
      const params = data.params;

      if(params.from!=null) delete params.from;
      if(params.to!=null) delete params.to;

      this.queryParams = params;
    });
  }

}
