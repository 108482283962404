<div class="page-title">
    <h1>Alertas de proveedores</h1>
    <button mat-flat-button color="accent" (click)="add()">
        <mat-icon style="margin-right: 10px">person_add</mat-icon>
        Añadir alerta
    </button>
</div>

<div class="bisual-table">
    <form class="card paginator" [formGroup]="filterForm">
        <mat-paginator
            [length]="length"
            [pageSize]="pageSize"
            [pageSizeOptions]="pageSizeOptions"
            [pageIndex]="pageIndex"
            (page)="changePage($event)"
            style="margin-right: auto;"
        ></mat-paginator>
        <mat-form-field>
            <input matInput type="text" formControlName="search">
            <button matSuffix mat-icon-button aria-label="Clear">
                <mat-icon>search</mat-icon>
            </button>
        </mat-form-field>
    </form>

    <div class="bisual-table-container">
        <table mat-table [dataSource]="dataSource">

            <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef>Id</th>
                <td mat-cell *matCellDef="let alert">
                    #{{alert.id}}
                </td>
            </ng-container>

            <ng-container matColumnDef="provider">
                <th mat-header-cell *matHeaderCellDef>Proveedor</th>
                <td mat-cell *matCellDef="let alert">
                    {{alert.provider.fact_name}}
                </td>
            </ng-container>

            <ng-container matColumnDef="frequency">
                <th mat-header-cell *matHeaderCellDef>Frecuencia</th>
                <td mat-cell *matCellDef="let alert">
                    Mensual
                </td>
            </ng-container>

            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let user; let i = index;">
                    <button mat-icon-button [matMenuTriggerFor]="menu">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item (click)="delete(i)">
                            <mat-icon matPrefix>delete</mat-icon>
                            Borrar alerta
                        </button>
                    </mat-menu>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>
</div>
