import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-add-department-dialog',
  templateUrl: './add-department-dialog.component.html',
  styleUrls: ['./add-department-dialog.component.css']
})
export class AddDepartmentDialogComponent implements OnInit {

  departmentForm:UntypedFormGroup;

  constructor(private fb:UntypedFormBuilder,
              public dialogRef: MatDialogRef<AddDepartmentDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data:any,
              private api:ApiService,
  ) {

    this.departmentForm = this.fb.group({
      name: ['', Validators.compose([Validators.required])],
      description: ['', Validators.compose([Validators.required])],
    })
  }

  ngOnInit(): void {
  }


  cancel(): void {
    this.dialogRef.close();
  }




}
