import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Role } from '../models/role.model';
import { UserService } from '../services/user.service';
import { User } from '../models/user.model';

@Injectable()
export class ClientsGuard  {

  private role:Role = null as any;

  constructor(private userService:UserService) { }

  async canActivate(route:ActivatedRouteSnapshot) {
    const user:User = this.userService.getCurrentUser();
    if(user != null && user.role.slug != "client") {
      return false;
    }
    return true;
  }

}
