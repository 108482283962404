<h1 mat-dialog-title>Sincronizalo con el siguiente enlace: </h1>
<mat-dialog-content>
  <input [readonly]="true"
    type="text"
    formControlName="reporteeName"
    class="form-control"
    value="{{this.calendarUrl}}"
    (click)="copyLink()"
    ngxClipboard [cbContent]="calendarUrl">
</mat-dialog-content>
<mat-dialog-actions id="dialog-icons">
  <button mat-flat-button color="black" id="local-calendar-sincronize" (click)="localSincronize()">
    Sincronizar local
  </button>
  <button mat-flat-button color="accent" id="google-calendar-sincronize" (click)="googleSincronize()">
    Google calendar
  </button>
  <button mat-flat-button (click)="onNoClick()">Hecho</button>
</mat-dialog-actions>
