import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { ActivatedRoute, Router } from '@angular/router';
import { Project } from 'src/app/models/project.model';
import { User } from 'src/app/models/user.model';
import { ApiService } from 'src/app/services/api.service';
import { ClockRegisterService } from 'src/app/services/clock-register.service';
import { PermissionsService } from 'src/app/services/permissions.service';
import { UserService } from 'src/app/services/user.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-working-times-resume',
  templateUrl: './working-times-resume.component.html',
  styleUrls: ['./working-times-resume.component.css']
})
export class WorkingTimesResumeComponent implements OnInit {

  private _chartContainer:ElementRef = null as any;
  get chartContainer() {return this._chartContainer;};
  @ViewChild('chartContainer') set chartContainer(data:ElementRef) {
    this._chartContainer = data;
    if(this._chartContainer!=null) {
      if(window.innerWidth > 992) {
        this.mainChartView[0] = this._chartContainer.nativeElement.offsetWidth - 470;
      }
      else this.mainChartView[0] = this._chartContainer.nativeElement.offsetWidth - 70;
      // this.pieChartsView[0] = this._chartContainer.nativeElement.offsetWidth/3 - 70;
    }
  }
  mainChartView:[number,number] = [undefined as any, 250];
  pieChartsView:[number,number] = [300, 300];
  todayPieChartView:[number,number] = [undefined as any, 150];
  pieChartsLegendPosition:any = "below";
  colorScheme = this.utils.getChartsColorScheme();
  chartData:any;

  filterForm:UntypedFormGroup;
  private formPersistence:any;
  periods:string[];

  userControl:UntypedFormControl = new UntypedFormControl();
  userAutocompleteControl:UntypedFormControl = new UntypedFormControl();
  usersFiltered:User[] = [];

  projectControl:UntypedFormControl = new UntypedFormControl();
  projectAutocompleteControl:UntypedFormControl = new UntypedFormControl();
  projectsFiltered:Project[] = [];

  constructor(private api:ApiService,
              private utils:UtilsService,
              private fb:UntypedFormBuilder,
              private activatedRoute:ActivatedRoute,
              private router:Router,
              private user:UserService,
              public permissions:PermissionsService,
              private clock:ClockRegisterService,) {

    this.filterForm = this.fb.group({
      period: [this.activatedRoute.snapshot.queryParamMap.get('period')!=null ? (this.activatedRoute.snapshot.queryParamMap.get('period') as any) : this.getCurrentPeriod()],
      user_id: [this.activatedRoute.snapshot.queryParamMap.get('user_id')!=null ? (this.activatedRoute.snapshot.queryParamMap.get('user_id') as any) : '', Validators.required],
      project_id: [this.activatedRoute.snapshot.queryParamMap.get('project_id')!=null ? (this.activatedRoute.snapshot.queryParamMap.get('project_id') as any) : '']
    });

    this.periods = [this.filterForm.value['period']];

  }

  ngOnInit(): void {
    this.fetchPeriods();
    this.initFilterFormListener();
    this.listenQueryParameters();

    this.listenUserControl();
    this.listenUserAutocompleteControl();
    this.listenProjectControl();
    this.listenProjectAutocompleteControl();
    this.listenClockRegisterService();

    if(this.filterForm.value['user_id']!='') this.fetchUser(this.filterForm.value['user_id']);
    else this.initCurrentUser();

    if(this.filterForm.value['project_id']!='') this.fetchProject(this.filterForm.value['project_id']);
  }

  onUserAutocompleteSelected(event:MatAutocompleteSelectedEvent) {
    this.selectUser(event.option.value);
  }

  selectUser(user:User) {
    this.userControl.setValue(user);
  }

  displayFnUser(user:User): string {
    return user ? user.name + ' ' + user.surnames : '';
  }

  onProjectAutocompleteSelected(event:MatAutocompleteSelectedEvent) {
    this.selectProject(event.option.value);
  }

  selectProject(project:Project) {
    this.projectControl.setValue(project);
  }

  displayFnProject(project:Project): string {
    return project ? project.title : '';
  }

  resetProject() {
    this.filterForm.patchValue({project_id: ''});
    this.projectControl.setValue(null);
    this.projectAutocompleteControl.setValue(null);
  }

  private getCurrentPeriod() {
    return new Date().getFullYear() + "/" + (new Date().getMonth()+1);
  }

  private fetchPeriods() {
    this.api.getBillingPeriods().subscribe(
      data => {
        this.periods = data;
      }
    );
  }

  private initFilterFormListener() {
    this.filterForm.valueChanges.subscribe(
      data => {
        if(this.formPersistence==null || JSON.stringify(this.formPersistence)!=JSON.stringify(data)) {
          this.router.navigate(['/employees', 'my-zone', 'resume'], { queryParams: data });
        }
      }
    );
  }

  private listenQueryParameters() {
    this.activatedRoute.queryParams.subscribe(
      params => {
        if(JSON.stringify(params)!==JSON.stringify(this.filterForm.value)) { //si no ve de filtre s'ha de setejar el form
          let params_temp = this.utils.cloneObj(params); //params es read_only
          Object.keys(params_temp).forEach(param_key => {
            if(params_temp[param_key]!=null && params_temp[param_key]!="" && !isNaN(+params_temp[param_key])) params_temp[param_key] = +params_temp[param_key]; // si es numero, el transformem
          });
          this.filterForm.patchValue(params_temp, { emitEvent: false });
        }
        this.formPersistence = params;
        this.fetchChartData();
      }
    );
  }

  private fetchChartData() {
    if(this.filterForm.value['user_id']!=null && this.filterForm.value['user_id']!='') {
      let params:any = this.utils.cloneObj(this.filterForm.value);
      const period:any = params.period;
      delete params['period'];
      this.api.getWorkingTimeChartData(period, params).subscribe(
        data => {
          this.chartData = data;
        }
      );
    }
  }

  private initCurrentUser() {
    this.user.currentUser.subscribe(
      data => {
        if(this.filterForm.value['user_id']==null || this.filterForm.value['user_id']=='') {
          this.selectUser(data);
          this.usersFiltered = [data];
          this.userAutocompleteControl.setValue(data);

          if(!this.permissions.validateDepartment(['direction', 'rrhh'])) {
            this.userAutocompleteControl.disable();
            this.userControl.disable();
          }
        }
      }
    );
  }

  private listenUserControl() {
    this.userControl.valueChanges.subscribe(
      user => {
        this.userAutocompleteControl.setValue(user);
        this.filterForm.patchValue({user_id: user.id, project_id: ''});
        this.projectControl.setValue(null);
        this.projectAutocompleteControl.setValue(null);
      }
    );
  }

  private listenUserAutocompleteControl() {
    this.userAutocompleteControl.valueChanges.subscribe(
      data => {
        if(data.id==null) {
          const params:any = {search: data};
          this.api.getUsers(params).subscribe(
            users => {
              this.usersFiltered = users.data;
            }
          );
        }
      }
    );
  }

  private fetchUser(id:string) {
    this.api.getUser(id).subscribe(
      data => {
        this.selectUser(data);
        this.usersFiltered = [data];
        this.userAutocompleteControl.setValue(data);
      }
    );
  }

  private listenProjectControl() {
    this.projectControl.valueChanges.subscribe(
      project => {
        if(project!=null) {
          this.projectAutocompleteControl.setValue(project);
          this.filterForm.patchValue({project_id: project.id});
        }
      }
    );
  }

  private listenProjectAutocompleteControl() {
    this.projectAutocompleteControl.valueChanges.subscribe(
      data => {
        if(data!=null && data.id==null) {
          const params:any = {search: data};
          this.api.getProjects(params).subscribe(
            projects => {
              this.projectsFiltered = projects.data;
            }
          );
        }
      }
    );
  }

  private fetchProject(id:string) {
    this.api.getProject(id).subscribe(
      data => {
        this.selectProject(data);
        this.projectsFiltered = [data];
        this.projectAutocompleteControl.setValue(data);
      }
    );
  }

  private listenClockRegisterService() {
    this.clock.statusObservable.subscribe(
      data => {
        this.fetchChartData();
      }
    );
  }
}
