import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RegexService } from 'src/app/services/regex.service';
import * as Countries from 'src/assets/json/countries.json';

@Component({
  selector: 'app-add-provider-dialog',
  templateUrl: './add-provider-dialog.component.html',
  styleUrls: ['./add-provider-dialog.component.css']
})
export class AddProviderDialogComponent implements OnInit {

  providerForm:UntypedFormGroup;
  countries:any[] = (Countries as any).default;

  constructor(private fb:UntypedFormBuilder,
              public dialogRef: MatDialogRef<AddProviderDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data:any,
              private regex:RegexService) {
                
    const regexPatterns = this.regex.getRegex();

    this.providerForm = this.fb.group({
      name: ['', Validators.compose([Validators.required])],
      email: ['', Validators.compose([Validators.required, Validators.email])],
      phone: ['', Validators.compose([Validators.required, Validators.pattern(regexPatterns['phone'])])],
      website: [''],
      is_hosting_provider: [false],
      is_domain_provider: [false],
      is_business_mail_provider: [false],
      slug: ['', Validators.compose([Validators.required, Validators.minLength(3)])],
      fact_name: ['', Validators.compose([Validators.required])],
      fact_address: ['', Validators.compose([Validators.required])],
      fact_city: ['', Validators.compose([Validators.required])],
      fact_zipcode: ['', Validators.compose([Validators.required, Validators.minLength(5), Validators.maxLength(5)])],
      fact_county: ['', Validators.compose([Validators.required])],
      fact_country: ['', Validators.compose([Validators.required])],
      fact_country_alpha2: ['', Validators.compose([Validators.required])],
      vat: ['', Validators.compose([Validators.required])],
    });

  }

  ngOnInit(): void {
    this.listenCountryChanges();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  private listenCountryChanges() {
    this.providerForm.controls['fact_country'].valueChanges.subscribe(
      data => {
        for(let c of this.countries) {
          if(c.name==data) {
            this.providerForm.patchValue({ fact_country_alpha2: c.alpha_2 });
            break;
          }
        }
      }
    );
  }

}
