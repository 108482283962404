import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import { ProjectPhaseBoard } from 'src/app/models/project_phase_board.model';
import { ProjectPhaseBoardCol } from 'src/app/models/project_phase_board_col.model';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-complete-project-phase-board-dialog',
  templateUrl: './complete-project-phase-board-dialog.component.html',
  styleUrls: ['./complete-project-phase-board-dialog.component.css']
})
export class CompleteProjectPhaseBoardDialogComponent implements OnInit {
  board:ProjectPhaseBoard = this.data.board;
  pendingIssues: number = 0;
  doneIssues: number = 0;
  form:FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {board:ProjectPhaseBoard; },
    public dialogRef: MatDialogRef<CompleteProjectPhaseBoardDialogComponent>,
    private fb:FormBuilder,
    private api:ApiService
  ) {
    this.form = this.fb.group({
      start_date: [moment().add(1, 'week').startOf('week').toDate(), Validators.required],
      end_date: [moment().add(2, 'week').endOf('week').toDate(), Validators.required],
      title: ['Sprint #X', Validators.required],
    });
  }

  ngOnInit(): void {
    this.countPendingAndDoneIssues();
  }

  countPendingAndDoneIssues() {
    this.board.project_phase_board_cols!.forEach((col:ProjectPhaseBoardCol) => {
      if(col.project_phase_board_col_role != null && (col.project_phase_board_col_role.slug==='done' || col.project_phase_board_col_role.slug==='test')){
        this.doneIssues += col.project_task_boards.length;
      } else {
        this.pendingIssues += col.project_task_boards.length;
      }
    });
  }


  save() {
    const body = {
      new_board: this.form.value
    };
    this.api.completeProjectPhaseBoard(this.board.id as string, body).subscribe(
      data => {
        this.dialogRef.close(data);
      }
    );
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onCancel() {
    this.dialogRef.close();
  }

}
