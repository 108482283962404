import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Department } from '../models/department.model';
import { DepartmentCategory } from '../models/department_category.model';

@Injectable({
  providedIn: 'root'
})
export class DepartmentService {

  private localstorageDepartmentKey:string = "bisual-department";
  private currentDepartmentSubject: BehaviorSubject<any> = new BehaviorSubject(null);
  public currentDepartment: Observable<Department> = this.currentDepartmentSubject.asObservable();

/*   private localstorageDepartmentCategoryKey:string = "bisual-department-category";
  private currentDepartmentCategorySubject: BehaviorSubject<any> = new BehaviorSubject(null);
  public currentDepartmentCategory: Observable<DepartmentCategory> = this.currentDepartmentCategorySubject.asObservable();
 */
  constructor() { }

  public getCurrentDepartment(): Department {
    return this.currentDepartmentSubject.value;
  }

  public setDepartment(department:Department, keepAlive:boolean = true) {
    if(department!=null && department!=undefined ) {
      if(keepAlive) {
        localStorage.setItem('bisual-department', JSON.stringify(department));
      }
      this.currentDepartmentSubject.next(department);
    }
  }

  public deleteDepartment() {
    localStorage.removeItem('bisual-department');
    this.currentDepartmentSubject.next(null);
  }

/*   public getCurrentDepartmentCategory(): DepartmentCategory {
    return this.currentDepartmentCategorySubject.value;
  }

  public setDepartmentCategory(departmentCategory:DepartmentCategory, keepAlive:boolean = true) {
    if(departmentCategory!=null && departmentCategory!=undefined) {
      if(keepAlive) {
        localStorage.setItem('bisual-department-category', JSON.stringify(departmentCategory));
      }
      this.currentDepartmentCategorySubject.next(departmentCategory);
    }
  }

  public deleteDepartmentCategory() {
    localStorage.removeItem('bisual-department-category');
    this.currentDepartmentCategorySubject.next(null);
  } */

}
