<h1 mat-dialog-title>{{ isCreate ? 'Crear nuevo canal de comunicación' : 'Editar canal de comunicación' }}</h1>
<mat-dialog-content [formGroup]="form">
  <div class="container">
    <div class="row">
      <div class="col-12 col-lg-4">
        <mat-form-field appearance="outline">
          <mat-label>Tipo</mat-label>
          <mat-select formControlName="type">
            <mat-option value="email">Email</mat-option>
            <mat-option value="slack">Slack</mat-option>
            <mat-option value="teams">Microsoft Teams</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      @if (form.value.type==='email') {
        <div class="col-12 col-lg-8">
          <mat-form-field appearance="outline">
            <mat-label>Email</mat-label>
            <input matInput type="email" formControlName="email" />
          </mat-form-field>
        </div>
      }
      @if (form.value.type==='slack') {
        <div class="col-12 col-lg-8">
          <mat-form-field appearance="outline">
            <mat-label>Nombre del canal de Slack</mat-label>
            <input matInput type="text" formControlName="slack_channel_name" />
          </mat-form-field>
        </div>
      }
      @if (form.value.type==='slack') {
        <div class="col-12 col-lg-12">
          <mat-form-field appearance="outline">
            <mat-label>Slack Webhook Url</mat-label>
            <input matInput type="text" formControlName="slack_webhook_url" />
          </mat-form-field>
        </div>
      }
      @if (form.value.type==='teams') {
        <div class="col-12 col-lg-8">
          <mat-form-field appearance="outline">
            <mat-label>Nombre del canal de Teams</mat-label>
            <input matInput type="text" formControlName="teams_channel_name" />
          </mat-form-field>
        </div>
      }
      @if (form.value.type==='teams') {
        <div class="col-12 col-lg-12">
          <mat-form-field appearance="outline">
            <mat-label>Teams Webhook Url</mat-label>
            <input matInput type="text" formControlName="teams_webhook_url" />
          </mat-form-field>
        </div>
      }
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button (click)="onNoClick()" cdkFocusInitial type="button">Cancelar</button>
  <button mat-flat-button color="accent" (click)="submit()" [disabled]="!form.valid" type="submit">
    <mat-icon style="margin-right: 10px">check</mat-icon>
    {{ isCreate ? 'Crear canal de comunicación' : 'Actualizar canal de comunicación' }}
  </button>
</mat-dialog-actions>
