import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-add-new-clock-register-review-request-dialog',
  templateUrl: './add-new-clock-register-review-request-dialog.component.html',
  styleUrls: ['./add-new-clock-register-review-request-dialog.component.css']
})
export class AddNewClockRegisterReviewRequestDialogComponent implements OnInit {

  form:UntypedFormGroup;

  constructor(public dialogRef: MatDialogRef<AddNewClockRegisterReviewRequestDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any,
    private fb:UntypedFormBuilder,
    private api:ApiService) {
      this.form = this.fb.group({
        clock_register_id: [data.clock_register_id, Validators.required],
        proposed_date: ['', Validators.required],
        justification: ['', Validators.compose([Validators.required, Validators.minLength(20)])],
        status: ['pending', Validators.required]
      });
  }

  ngOnInit() {
  }

  onNoClick() {
    this.dialogRef.close(null);
  }

  save() {
    this.form.value['proposed_date'] = new Date(this.form.value['proposed_date']);
    this.dialogRef.close(this.form.value);
  }

}
