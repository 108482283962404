import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-clock-registers-management',
  templateUrl: './clock-registers-management.component.html',
  styleUrls: ['./clock-registers-management.component.css']
})
export class ClockRegistersManagementComponent implements OnInit {

  navLinks: any[] = [
    {
      label: 'Resumen del control horario',
      link: './clock-register-resume',
      index: 0
    },
    {
      label: 'Peticiones de cambio',
      link: './clock-register-review-requests',
      index: 1
    },
  ];

  queryParams:any = {};

  constructor(private activatedRoute:ActivatedRoute,
              private router:Router) { }

  ngOnInit(): void {
    this.listenQueryParams();
    this.router.navigate(['/employees', 'rrhh', 'clock-registers','clock-register-resume'], { queryParams: this.queryParams });
  }

  private listenQueryParams() {
    this.activatedRoute.queryParamMap.subscribe((data:any) => {
      const params = data.params;
      this.queryParams = params;
    });
  }
}
