<ul>
  <li routerLinkActive="active">
    <a mat-button [routerLink]="['/employees','dashboard']">
      <mat-icon>home</mat-icon>
      <span>Dashboard</span>
    </a>
  </li>
  <li routerLinkActive="active">
    <a mat-button [routerLink]="['/employees','my-zone']">
      <mat-icon>account_circle</mat-icon>
      <span>Mi zona</span>
    </a>
  </li>

  @if (permissions.isDepartment('development') || permissions.isDepartment('designers')) {
    <li class="menu-separator">Desarrollo</li>
    <li routerLinkActive="active">
      <a mat-button [routerLink]="['/employees','development', 'projects']">
        <mat-icon>apps</mat-icon>
        <span>Proyectos</span>
      </a>
    </li>
    <!-- <li routerLinkActive="active">
    <a mat-button [routerLink]="['/employees','development', 'services']">
      <mat-icon>design_services</mat-icon>
      <span>Servicios Cloud</span>
    </a>
  </li> -->
  @if (permissions.isDepartment('rrhh')) {
    <li routerLinkActive="active">
      <a mat-button [routerLink]="['/employees', 'development', 'roadmap']">
        <mat-icon>view_timeline</mat-icon>
        <span>Planificación y hoja de ruta</span>
      </a>
    </li>
  }
  <li routerLinkActive="active" >
    <a mat-button [routerLink]="['/employees', 'development', 'assignment']">
      <mat-icon>diversity_2</mat-icon>
      <span>Asignación de proyectos</span>
    </a>
  </li>
}

@if (permissions.isDepartment('marketing')) {
  <li class="menu-separator">Marketing</li>
  <li routerLinkActive="active">
    <a mat-button [routerLink]="['/employees','marketing', 'blogposts']">
      <mat-icon>rss_feed</mat-icon>
      <span>Blog</span>
    </a>
  </li>
}

@if (permissions.isDepartment('support')) {
  <li class="menu-separator">Soporte</li>
  <li routerLinkActive="active">
    <a mat-button [routerLink]="['/employees','support', 'tickets']">
      <mat-icon>support_agent</mat-icon>
      <span>Tickets</span>
    </a>
  </li>
}

@if (permissions.isDepartment('rrhh')) {
  <li class="menu-separator">RRHH</li>
  <li routerLinkActive="active">
    <a mat-button [routerLink]="['/employees', 'rrhh', 'workers-planning']">
      <mat-icon>next_plan</mat-icon>
      <span>Planificación de plantilla</span>
    </a>
  </li>
  <li routerLinkActive="active">
    <a mat-button [routerLink]="['/employees','rrhh', 'absences-management']">
      <mat-icon>flight_takeoff</mat-icon>
      <span>Vacaciones</span>
    </a>
  </li>
  <li routerLinkActive="active">
    <a mat-button [routerLink]="['/employees','rrhh', 'clock-registers']">
      <mat-icon>timer</mat-icon>
      <span>Control Horario</span>
    </a>
  </li>
  <li routerLinkActive="active">
    <a mat-button [routerLink]="['/employees','rrhh', 'payrolls']">
      <mat-icon>request_quote</mat-icon>
      <span>Nóminas</span>
    </a>
  </li>
  <li routerLinkActive="active">
    <a mat-button [routerLink]="['/employees','rrhh', 'working-contracts']">
      <mat-icon>file_open</mat-icon>
      <span>Contratos de trabajo</span>
    </a>
  </li>
  <li routerLinkActive="active">
    <a mat-button [routerLink]="['/employees','rrhh', 'workers']">
      <mat-icon>engineering</mat-icon>
      <span>Gestión de trabajadores</span>
    </a>
  </li>
  <li routerLinkActive="active">
    <a mat-button [routerLink]="['/employees','rrhh', 'departments']">
      <mat-icon>supervised_user_circle</mat-icon>
      <span>Departamentos</span>
    </a>
  </li>
  <li routerLinkActive="active">
    <a mat-button [routerLink]="['/employees','rrhh', 'holidays']">
      <mat-icon>event_upcoming</mat-icon>
      <span>Festividades</span>
    </a>
  </li>
}

@if (permissions.isDepartment('comercial')) {
  <li class="menu-separator">Comercial</li>
  @if (permissions.isDepartment('direction')) {
    <li routerLinkActive="active">
      <a mat-button [routerLink]="['/employees', 'comercial', 'dashboard']">
        <mat-icon>insights</mat-icon>
        <span>Dashboard & KPIs</span>
      </a>
    </li>
  }
  <li routerLinkActive="active">
    <a mat-button [routerLink]="['/employees', 'comercial', 'potential-clients']">
      <mat-icon>emoji_people</mat-icon>
      <span>Clientes potenciales</span>
    </a>
  </li>
  <li routerLinkActive="active">
    <a mat-button [routerLink]="['/employees', 'comercial', 'potential-clients-status-changes']">
      <mat-icon>connect_without_contact</mat-icon>
      <span>Acciones comerciales</span>
    </a>
  </li>
}

@if (permissions.isDepartment('direction')) {
  <li class="menu-separator">Dirección</li>
  <li routerLinkActive="active">
    <a mat-button [routerLink]="['/employees','direction', 'billing']">
      <mat-icon>account_balance</mat-icon>
      <span>Facturación</span>
    </a>
  </li>
  <li routerLinkActive="active">
    <a mat-button [routerLink]="['/employees','direction', 'users-management']">
      <mat-icon>manage_accounts</mat-icon>
      <span>Gestión de usuarios</span>
    </a>
  </li>
  <li routerLinkActive="active">
    <a mat-button [routerLink]="['/employees','direction', 'configuration']">
      <mat-icon>settings</mat-icon>
      <span>Configuración</span>
    </a>
  </li>
  <li routerLinkActive="active">
    <a mat-button [routerLink]="['/employees','direction', 'legal']">
      <mat-icon>policy</mat-icon>
      <span>Documentación legal</span>
    </a>
  </li>
}

<li class="menu-separator">Información de interés</li>
<li routerLinkActive="active">
  <a mat-button [routerLink]="['/employees','wiki']">
    <mat-icon>import_contacts</mat-icon>
    <span>Wiki</span>
  </a>
</li>
</ul>
