<h2>Iniciar sesión</h2>
<p class="subtitle">¿No tienes credenciales? <a href="#">Contacta con nosotros</a></p>

<form [formGroup]="form">
  <div class="field">
    <label for="email">Correo electrónico</label>
    <mat-form-field appearance="outline" class="nobottom">
      <input matInput type="email" placeholder="Correo electrónico" formControlName="email">
      @if (form.value['email'].invalid) {
        <mat-error>{{ 'Correo electrónico inválido' }}</mat-error>
      }
    </mat-form-field>
  </div>

  <div class="field">
    <label for="password">Contraseña</label>
    <mat-form-field appearance="outline" class="nobottom">
      <input matInput type="password" placeholder="Contraseña" formControlName="password">
      @if (form.value['password'].invalid) {
        <mat-error>{{ 'Contraseña inválida' }}</mat-error>
      }
    </mat-form-field>
  </div>

  <div class="field">
    <mat-checkbox formControlName="keepAlive">Mantenerme conectado en este dispositivo</mat-checkbox>
  </div>

  <button mat-flat-button color="black" type="submit" (click)="submit()" [disabled]="!form.valid">
    {{ logging ? 'Iniciando sesión...' : 'Iniciar sesión' }}
  </button>
</form>
