import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'priorityImg'
})
export class PriorityImgPipe implements PipeTransform {

  transform(priority:string|null): string {
    switch(priority) {
      case 'critical':
        return '/assets/img/icons/priority/critical.png';
        break;
      case 'high':
        return '/assets/img/icons/priority/high.png';
        break;
      case 'low':
        return '/assets/img/icons/priority/low.png';
        break;
      default:
        return '';
        break;
    }

  }

}
