<div class="page-title">
  <h1>Solicitudes de cambio</h1>
</div>

<div class="bisual-table">
  <form class="card paginator" [formGroup]="filterForm">

    <mat-form-field appearance="outline" class="bisual-paginator-select">
      <mat-label>Estado</mat-label>
      <mat-select formControlName="status">
        <mat-option [value]="''">Todos</mat-option>
        @for (s of st; track s) {
          <mat-option [value]="s.status">
            {{s.name}}
          </mat-option>
        }
      </mat-select>
    </mat-form-field>

    <mat-paginator
      [length]="length"
      [pageSize]="pageSize"
      [pageSizeOptions]="pageSizeOptions"
      [pageIndex]="pageIndex"
      (page)="changePage($event)"
      style="margin-left: auto;">
    </mat-paginator>
  </form>

  <div class="bisual-table-container">
    <table mat-table [dataSource]="dataSource">

      <ng-container matColumnDef="clock_type">
        <th mat-header-cell *matHeaderCellDef>Tipo</th>
        <td mat-cell *matCellDef="let crrr">
          <mat-icon [ngStyle]="crrr.meta_data.type =='out' ? { 'transform': 'rotate(180deg)', 'color': 'var(--warn-color)' } : { 'color': 'var(--green)' }">trending_flat</mat-icon>
        </td>
      </ng-container>

      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let crrr">
          <mat-icon>{{crrr.id}}</mat-icon>
        </td>
      </ng-container>

      <ng-container matColumnDef="user_name">
        <th mat-header-cell *matHeaderCellDef>Nombre</th>
        <td mat-cell *matCellDef="let crrr">
          {{crrr.meta_data.user_name}}
          <!-- <a [routerLink]="['rrhh', 'workers', crrr.meta_data.id]">{{crrr.meta_data.user_name}}</a> -->
        </td>
      </ng-container>

      <ng-container matColumnDef="justification">
        <th mat-header-cell *matHeaderCellDef>Justificación</th>
        <td mat-cell *matCellDef="let crrr">{{crrr.justification}}</td>
      </ng-container>

      <ng-container matColumnDef="current_date">
        <th mat-header-cell *matHeaderCellDef>Fecha actual</th>
        <td mat-cell *matCellDef="let crrr">{{crrr.meta_data.current_date | date:'dd-MM-YY HH:mm'}}</td>
      </ng-container>

      <ng-container matColumnDef="proposed_date">
        <th mat-header-cell *matHeaderCellDef>Fecha propuesta</th>
        <td mat-cell *matCellDef="let crrr">{{crrr.proposed_date | date:'dd-MM-YY HH:mm'}}</td>
      </ng-container>

      <ng-container matColumnDef="difference">
        <th mat-header-cell *matHeaderCellDef>Diferencia</th>
        <td mat-cell *matCellDef="let crrr">{{differenceBetweeDates(crrr.meta_data.current_date,crrr.proposed_date)}}</td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let crrr; let i = index;">
          <button mat-icon-button [matMenuTriggerFor]="menu">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="approve(crrr.id)" [disabled]="!permissions.validateDepartment(['direction', 'rrhh'])">
              <mat-icon matPrefix>check</mat-icon>
              Aprobar solicitud
            </button>
            <button mat-menu-item (click)="reject(crrr.id)" [disabled]="!permissions.validateDepartment(['direction', 'rrhh'])">
              <mat-icon matPrefix>close</mat-icon>
              Rechazar solicitud
            </button>
          </mat-menu>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
</div>
