import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { AddNewOrderDialogComponent } from 'src/app/components/utils/add-new-order-dialog/add-new-order-dialog.component';
import { Company } from 'src/app/models/company.model';
import { Order } from 'src/app/models/order.model';
import { Project } from 'src/app/models/project.model';
import { ApiService } from 'src/app/services/api.service';
import { CustomerService } from 'src/app/services/customer.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-billing-orders',
  templateUrl: './billing-orders.component.html',
  styleUrls: ['./billing-orders.component.css']
})
export class BillingOrdersComponent implements OnInit {

  displayedColumns: string[] = ['id', 'date', 'payment_status', 'invoice', 'company', 'type', 'actions'];
  dataSource:MatTableDataSource<Order> = new MatTableDataSource<Order>();

  // dades per el paginator
  length:number = 0;
  pageSize:number = 10;
  pageSizeOptions:number[] = [5,10,20,50];
  pageIndex:number = 1;

  filterForm:UntypedFormGroup;
  private formPersistence:any;
  periods:string[];

  companies_filtered:Company[] = [];
  companies_property_control:UntypedFormControl = new UntypedFormControl('', Validators.compose([Validators.minLength(3)]));
  projects_filtered:Project[] = [];
  projects_property_control:UntypedFormControl = new UntypedFormControl('', Validators.compose([Validators.minLength(3)]));

  now:Date = new Date();

  constructor(private api:ApiService,
              private fb:UntypedFormBuilder,
              private router:Router,
              private activatedRoute:ActivatedRoute,
              private utils:UtilsService,
              public dialog: MatDialog,
              private customer:CustomerService) {

    this.filterForm = this.fb.group({
      search: [this.activatedRoute.snapshot.queryParamMap.get('search')!=null ? this.activatedRoute.snapshot.queryParamMap.get('search') : '', Validators.compose([Validators.minLength(3)])],
      per_page: [this.activatedRoute.snapshot.queryParamMap.get('per_page')!=null ? +(this.activatedRoute.snapshot.queryParamMap.get('per_page') as any) : this.pageSize, Validators.required],
      page: [this.activatedRoute.snapshot.queryParamMap.get('page')!=null ? +(this.activatedRoute.snapshot.queryParamMap.get('page') as any) : this.pageIndex, Validators.required],
      total: [this.activatedRoute.snapshot.queryParamMap.get('total')!=null ? +(this.activatedRoute.snapshot.queryParamMap.get('total') as any) : this.length],
      company_id: [this.activatedRoute.snapshot.queryParamMap.get('company_id')!=null ? +(this.activatedRoute.snapshot.queryParamMap.get('company_id') as any) : ''],
      period: [this.activatedRoute.snapshot.queryParamMap.get('period')!=null ? (this.activatedRoute.snapshot.queryParamMap.get('period') as any) : this.getCurrentPeriod()],
      payment_status: [''],
    });

    this.periods = [this.filterForm.value['period']];
  }

  ngOnInit(): void {
    this.listenCustomerService();
    this.fetchPeriods();
    this.listenAutocompleteControls();
    this.initFilterFormListener();
    this.listenQueryParameters();
  }

  changePage(event:PageEvent) {
    this.filterForm.patchValue({
      page: event.pageIndex,
      per_page: event.pageSize
    });
  }

  selectCompanyFilter(company:Company) {
    this.filterForm.patchValue({ company_id: company.id });
  }

  selectProjectFilter(project:Project) {
    this.filterForm.patchValue({ project_id: project.id });
  }

  getOrderType(order:Order) {
    if(order.project_id!=null) return "Proyecto";
    else {
      let type:string = "";

      order.lines.forEach(l => {
        const will_be_varios = type!="";

        if(l.business_mail_id != null) {
          if(will_be_varios) type = "Varios";
          else type = "Correo corporativo";
        }
        else if(l.domain_id != null) {
          if(will_be_varios) type = "Varios";
          else type = "Dominio";
        }
        else if(l.hosting_id != null) {
          if(will_be_varios) type = "Varios";
          else type = "Servidor";
        }
        else if(l.subscription_id != null) {
          if(will_be_varios) type = "Varios";
          else type = "Suscripción VIP";
        }
      });

      return type;
    }
  }

  addOrder() {
    const dialogRef = this.dialog.open(AddNewOrderDialogComponent, {
      width: '800px'
    });

    dialogRef.afterClosed().subscribe((data:Order) => {
      this.fetchOrders();
    });
  }

  downloadInvoice(id:number) {
    this.api.downloadOrderInvoice(id.toString()).subscribe(
      response => {
        this.utils.downloadPdfFromBlobResponse(response);
      }
    );
  }

  markAsPayed(order:Order) {
    this.api.markOrderAsPayed(order.id.toString()).subscribe(
      data => {
        this.fetchOrders();
      }
    );
  }

  refund(o:Order) {
    if(confirm("¿Estás seguro que quieres devolver este pago? Se devolverá el importe total y se creará una factura rectificativa.")) {
      this.api.refundOrder(o.id.toString()).subscribe(
        (data:Order) => {

        }
      );
    }
  }

  private getCurrentPeriod() {
    const date:Date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth()+1;
    const quarter = month < 10 ? (month < 7 ? (month < 4 ? 1 : 2) : 3) : 4;
    const period = year + "-" + quarter + "T";
    return period;
  }

  private fetchPeriods() {
    this.api.getBillingPeriods().subscribe(
      data => {
        this.periods = data;
      }
    );
  }

  private fetchOrders() {
    this.api.getOrdersPaged(this.filterForm.value).subscribe(
      data => {
        this.dataSource.data = data.data;

        this.length = +data.meta.total;
        this.pageIndex = +data.meta.current_page-1;
        this.pageSize = +data.meta.per_page;
      }
    );
  }

  private initFilterFormListener() {
    this.filterForm.valueChanges.subscribe(
      data => {
        data.per_page = +data.per_page;
        if(this.formPersistence==null || JSON.stringify(this.formPersistence)!=JSON.stringify(data)) {
          if(this.formPersistence.per_page!=data.per_page) data.page = 0;
          this.router.navigate(['/employees', 'direction', 'billing', 'orders'], { queryParams: data });
        }
      }
    );
  }

  private listenQueryParameters() {
    this.activatedRoute.queryParams.subscribe(
      params => {
        if(JSON.stringify(params)!==JSON.stringify(this.filterForm.value)) { //si no ve de filtre s'ha de setejar el form
          let params_temp = this.utils.cloneObj(params); //params es read_only
          Object.keys(params_temp).forEach(param_key => {
            if(params_temp[param_key]!=null && params_temp[param_key]!="" && !isNaN(+params_temp[param_key])) params_temp[param_key] = +params_temp[param_key]; // si es numero, el transformem
          });
          this.filterForm.patchValue(params_temp, { emitEvent: false });
        }
        this.formPersistence = params;
        this.fetchOrders();
      }
    );
  }

  private listenAutocompleteControls() {
    // companies autocomplete
    this.companies_property_control.valueChanges.subscribe(
      (data:string) => {
        if(this.companies_property_control.valid && data.length > 0) {
          let params:any = {};
          params['search'] = data;
          this.api.getCompanies(params).subscribe(
            companies => {
              this.companies_filtered = companies.data;
            }
          );
        }
        else {
          this.filterForm.patchValue({ company_id: '' });
          this.companies_filtered = [];
        }

      }
    );

    // projects autocomplete
    this.projects_property_control.valueChanges.subscribe(
      (data:string) => {
        if(this.projects_property_control.valid && data.length > 0) {
          let params:any = {};
          params['search'] = data;
          this.api.getProjects(params).subscribe(
            projects => {
              this.projects_filtered = projects.data;
            }
          );
        }
        else {
          this.filterForm.patchValue({ company_id: '' });
          this.projects_filtered = [];
        }

      }
    );
  }

  private listenCustomerService() {
    this.customer.customerObservable.subscribe(
      data => {
        if(data==null) {
          this.companies_property_control.enable();
          this.filterForm.patchValue({ company_id: '' });
        }
        else {
          this.companies_property_control.disable();
          this.filterForm.patchValue({ company_id: data.id });
        }
      }
    );
  }

}
