<div>
  <form #myForm="ngForm">
    <mat-form-field>
      <mat-label>Data a copiar</mat-label>
      <input matInput [matDatepicker]="startDatePicker" (dateInput)="onDateInputChange($event, 'start')" name="startDate">
      <mat-datepicker-toggle matIconSuffix [for]="startDatePicker"></mat-datepicker-toggle>
      <mat-datepicker #startDatePicker></mat-datepicker>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Data final</mat-label>
      <input matInput [matDatepicker]="endDatePicker" (dateInput)="onDateInputChange($event, 'end')" name="endDate">
      <mat-datepicker-toggle matIconSuffix [for]="endDatePicker"></mat-datepicker-toggle>
      <mat-datepicker #endDatePicker></mat-datepicker>
    </mat-form-field>

    <div class="mt-2">
      <button class="ml-6" mat-raised-button color="primary" (click)="sendDate()" [disabled]="!myForm.valid">Aceptar</button>
      <button mat-raised-button (click)="closeDialog()">Cancelar</button>
    </div>
  </form>
</div>
