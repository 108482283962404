<div mat-dialog-title>
  <img src="assets/img/complete_sprint.svg"/>
  <h1>Completar "{{ board.title }}"</h1>
</div>

<mat-dialog-content [formGroup]="form">
  <p>El tablero contiene:</p>
  <ul>
    <!-- count not ended tasks -->
    <li>{{ doneIssues }} tareas finalizadas</li>
    <li>{{ pendingIssues }} tareas abiertas</li>
  </ul>

  <p>Traspasar las tareas pendientes a:</p>

  <div class="row">
    <div class="col-12">
      <mat-form-field appearance="outline">
        <mat-label>Título del tablero</mat-label>
        <input type="text" matInput formControlName="title" />
      </mat-form-field>
    </div>
    <div class="col-12">
      <mat-form-field appearance="outline">
        <mat-label>Duración del tablero</mat-label>
        <mat-date-range-input [rangePicker]="picker">
          <input matStartDate formControlName="start_date" placeholder="Desde...">
          <input matEndDate formControlName="end_date" placeholder="Hasta...">
        </mat-date-range-input>
        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
      </mat-form-field>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-flat-button (click)="onCancel()">Cancelar</button>
  <button mat-flat-button color="accent" (click)="save()" [disabled]="!form.valid">Completar sprint</button>
</mat-dialog-actions>


