import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { Application } from 'src/app/models/application.model';
import { ApiService } from 'src/app/services/api.service';
import { PermissionsService } from 'src/app/services/permissions.service';

@Component({
  selector: 'app-project-applications',
  templateUrl: './project-applications.component.html',
  styleUrls: ['./project-applications.component.css']
})
export class ProjectApplicationsComponent implements OnInit {

  displayedColumns: string[] = ["name", "git", "actions"];
  dataSource:MatTableDataSource<Application> = new MatTableDataSource();

  private project_id:string = null as any;

  constructor(private activatedRoute:ActivatedRoute,
              private api:ApiService,
              public permissions:PermissionsService) { }

  ngOnInit(): void {
    this.initProjectIdParam();
    this.fetchApplications();
  }

  delete(app:Application) {
    this.api.deleteApplication(app.id.toString()).subscribe(
      data => {
        this.fetchApplications();
      }
    );
  }

  private initProjectIdParam() {
    this.project_id = this.activatedRoute.snapshot.parent?.parent?.paramMap.get("id") as string;
  }

  private fetchApplications() {
    this.api.getProjectApplications(this.project_id).subscribe(
      data => {
        this.dataSource.data = data;
      }
    );
  }

}
