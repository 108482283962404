<h1 mat-dialog-title>Gestión de calendarios</h1>

<mat-dialog-content>
  <div id="calendar-container" #scrollContainer>
    <ng-template
      #weekViewHourSegmentTemplate
      let-segment="segment"
      let-locale="locale"
      let-segmentHeight="segmentHeight"
      let-isTimeLabel="isTimeLabel"
      >
      <div
        #segmentElement
        class="cal-hour-segment"
        [style.height.px]="segmentHeight"
        [class.cal-hour-start]="segment.isStart"
        [class.cal-after-hour-start]="!segment.isStart"
        [ngClass]="segment.cssClass"
        (mousedown)="startDragToCreate(segment, $event, segmentElement)"
        >
        @if (isTimeLabel) {
          <div class="cal-time">
            {{ segment.date | calendarDate:'weekViewHour':locale }}
          </div>
        }
      </div>
    </ng-template>

    <mwl-calendar-week-view
      [viewDate]="from"
      [events]="events"
      [hourSegments]="hour_segments"
      [refresh]="refresh"
      [weekStartsOn]="weekStartsOn"
      [hourSegmentTemplate]="weekViewHourSegmentTemplate"
      (eventTimesChanged)="checkPermissions(events,$event)  ? eventTimesChanged($event) : null"
      (beforeViewRender)="beforeWeekViewRender($event)"
      [dayStartHour]="5"
      [dayEndHour]="20"
      [hourSegmentHeight]="hour_segmented_height"
      (eventClicked)="eventClicked($event.event)"
      [minimumEventHeight]="0"
      [hourDuration]="60"
      >
    </mwl-calendar-week-view>
  </div>
  @if (userOptions.length) {
    <div id="resume">
      <span>Presupuesto de horas asignadas a <b>{{ userOptions[user_selected_index].name }}</b>:</span>
      <ul>
        @for (avail of availability | keyvalue; track avail) {
          <li (click)="avail_key_selected = avail.key" [ngClass]="{'selected': avail_key_selected == avail.key}">
            <span class="color" [ngStyle]="{'background-color': avail.value.color}"></span>
            <span class="text">
              {{ avail.value.name.title }}
              @if (avail.value.name.project_category != null) {
                <span class="subtitle">
                  {{ avail.value.name.project_category }}
                  @if (avail.value.name.project_phase_name != null) {
                    <span class="subtitle-phase">{{ avail.value.name.project_phase_name }}</span>
                  }
                </span>
              }
            </span>
            <span class="time">{{ avail.value.hours_assigned | number:'1.0-2' }}h / {{ avail.value.hours_to_do | number:'1.0-2' }}h</span>
          </li>
        }
      </ul>
      @if (active_contract != null) {
        <div id="hours-resume">
          <p id="total-time">
            <span>{{ total_hours_created | number:'1.0-2' }}h</span> / {{ active_contract.current_working_contract_variable_condition.hours_per_week_expected| number:'1.0-2' }}h
          </p>
          <p id="time-assigned">Horas planeadas: {{ total_hours_assigned | number:'1.0-2' }}h</p>
        </div>
      }
    </div>
  }
</mat-dialog-content>

<mat-dialog-actions align="end">
  <mat-form-field appearance="outline" style="margin-right: 20px">
    <mat-select [ngModel]="user_selected_index" (ngModelChange)="selectUser($event)">
      @for (user of userOptions; track user; let i = $index) {
        <mat-option [value]="i">{{ user.name + ' ' + user.surnames }}</mat-option>
      }
    </mat-select>
  </mat-form-field>
  <mat-form-field appearance="outline" style="margin-right: auto">
    <mat-select [ngModel]="hour_segmented_height" (ngModelChange)="changeHourSegmentedHeight($event)">
      @for (s of segmentation_options; track s; let i = $index) {
        <mat-option [value]="s.value">{{ s.name }}</mat-option>
      }
    </mat-select>
  </mat-form-field>

  <button mat-button cdkFocusInitial (click)="close()">
    <mat-icon>close</mat-icon>
    Cerrar
  </button>
  <button mat-flat-button color="success"  (click)="save()" [disabled]="!changesAvailableToSave" >
    <mat-icon>save</mat-icon>
    Guardar calendario
  </button>
</mat-dialog-actions>
