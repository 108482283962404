import { Component, Input, OnInit } from '@angular/core';
import { WorkingTimeSummaryResume } from 'src/app/models/working-time-summary.model';

@Component({
  selector: 'app-progress-bar-card',
  templateUrl: './progress-bar-card.component.html',
  styleUrls: ['./progress-bar-card.component.css']
})
export class ProgressBarCardComponent implements OnInit {
  @Input() title:string;
  @Input() projects:{[key:number]:WorkingTimeSummaryResume};
  @Input() categories:{[key:number]:WorkingTimeSummaryResume};

  constructor() { }

  ngOnInit(): void {
  }

  getProgress(d:WorkingTimeSummaryResume) {
    return d.done>0 ? (d.done / d.expected) * 100 : 0;
  }

}
