<h1 mat-dialog-title>
  {{event.title}}{{(event.subtitle!=null ? (', ' + event.subtitle) : '')}}{{(event.project_phase_name!=null ? (', ' + event.project_phase_name) : '')}}
</h1>

<mat-dialog-content [formGroup]="form">
  <!-- start date and end date form -->
  <div class="row">
    <div class="col-12 col-lg-6">
      <mat-form-field appearance="outline" class="w-100">
          <mat-label>Fecha de inicio</mat-label>
          <input matInput type="datetime-local" formControlName="start">
      </mat-form-field>
    </div>
    <div class="col-12 col-lg-6">
      <mat-form-field appearance="outline" class="w-100">
          <mat-label>Fecha final</mat-label>
          <input matInput type="datetime-local" formControlName="end">
      </mat-form-field>
    </div>
  </div>
  <div class="row">
    <div class="col d-flex justify-content-between">
      <button mat-button (click)="addMinutes(5)">+5m</button>
      <button mat-button (click)="addMinutes(10)">+10m</button>
      <button mat-button (click)="addMinutes(30)">+30m</button>
      <button mat-button (click)="addMinutes(60)">+1h</button>
      <button mat-button (click)="addMinutes(90)">+1h 30m</button>
      <button mat-button (click)="addMinutes(120)">+2h</button>
      <button mat-button (click)="addMinutes(180)">+3h</button>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button cdkFocusInitial (click)="close()">
    <mat-icon>close</mat-icon>
    Cerrar
  </button>
  <button mat-flat-button color="success"  (click)="save()">
    <mat-icon>save</mat-icon>
    Actualizar
  </button>
</mat-dialog-actions>
