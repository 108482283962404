<div class="page-title">
    <h1>{{ business_mail!=null ? business_mail.email : 'Cargando...' }}</h1>
    <button mat-flat-button color="accent" (click)="save()" [disabled]="!formGroup.valid && !permissions.validateDepartment(['direction', 'sysadmin'])">
        <mat-icon matPrefix>save</mat-icon>
        Guardar correo corporativo
    </button>
</div>

<p>Importante: No se muestran datos de la suscripción.</p>

<div class="card" [formGroup]="formGroup">
    <div class="container">
        <div class="row">
            <div class="col-12 col-md-6 col-lg-3">
                <mat-form-field appearance="outline">
                    <mat-label>Email</mat-label>
                    <input matInput formControlName="email">
                </mat-form-field>
            </div>
            <div class="col-12 col-md-6 col-lg-3">
                <mat-form-field appearance="outline">
                    <mat-label>Proveedor</mat-label>
                    <input matInput formControlName="provider_id">
                </mat-form-field>
            </div>
            <div class="col-12 col-md-6 col-lg-3">
                <mat-form-field appearance="outline">
                    <mat-label>Propietario</mat-label>
                    <input matInput formControlName="company_id">
                </mat-form-field>
            </div>
            <div class="col-12 col-md-6 col-lg-3">
                <mat-form-field appearance="outline">
                    <mat-label>Fecha de expiración</mat-label>
                    <input matInput [matDatepicker]="expirationPicker" formControlName="expiration_date">
                    <mat-datepicker-toggle matSuffix [for]="expirationPicker"></mat-datepicker-toggle>
                    <mat-datepicker #expirationPicker></mat-datepicker>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-md-6 col-lg-3">
                <mat-form-field appearance="outline">
                    <mat-label>Identificador de Ovh</mat-label>
                    <input matInput formControlName="ovh_id">
                </mat-form-field>
            </div>

            <div class="col-12 col-md-6 col-lg-3">
                <mat-form-field appearance="outline">
                    <mat-label>Período de renovación</mat-label>
                    <mat-select formControlName="renewal_days_period">
                        <mat-option [value]="15">Quincenal</mat-option>
                        <mat-option [value]="30">Mensual</mat-option>
                        <mat-option [value]="60">Bimensual</mat-option>
                        <mat-option [value]="90">Trimestral</mat-option>
                        <mat-option [value]="180">Semestral</mat-option>
                        <mat-option [value]="365">Anual</mat-option>
                        <mat-option [value]="730">Bianual</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-12 col-md-6 col-lg-3">
                <mat-checkbox formControlName="is_fact_external">Facturado externamente</mat-checkbox>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-md-4">
                <mat-form-field appearance="outline">
                    <mat-label>Servidor IMAP</mat-label>
                    <input matInput formControlName="imap_server">
                </mat-form-field>
            </div>
            <div class="col-12 col-md-4">
                <mat-form-field appearance="outline">
                    <mat-label>Puerto IMAP</mat-label>
                    <input matInput formControlName="imap_port">
                </mat-form-field>
            </div>
            <div class="col-12 col-md-4">
                <mat-form-field appearance="outline">
                    <mat-label>Encriptación IMAP</mat-label>
                    <mat-select formControlName="imap_encryption">
                        <mat-option value="no_auth">No encriptado</mat-option>
                        <mat-option value="start_tls">STARTTLS</mat-option>
                        <mat-option value="ssl">SSL</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-md-4">
                <mat-form-field appearance="outline">
                    <mat-label>Servidor SMTP</mat-label>
                    <input matInput formControlName="smtp_server">
                </mat-form-field>
            </div>
            <div class="col-12 col-md-4">
                <mat-form-field appearance="outline">
                    <mat-label>Puerto SMTP</mat-label>
                    <input matInput formControlName="smtp_port">
                </mat-form-field>
            </div>
            <div class="col-12 col-md-4">
                <mat-form-field appearance="outline">
                    <mat-label>Encriptación SMTP</mat-label>
                    <mat-select formControlName="smtp_encryption">
                        <mat-option value="no_auth">No encriptado</mat-option>
                        <mat-option value="start_tls">STARTTLS</mat-option>
                        <mat-option value="ssl">SSL</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-md-8">
                <mat-form-field appearance="outline">
                    <mat-label>Dirección Webmail</mat-label>
                    <input matInput formControlName="webmail_url">
                </mat-form-field>
            </div>
        </div>
    </div>
</div>