<div class="container">
  <div class="row">
    <div class="col-12">
      <div class="page-title">
        <h1>Control horario</h1>

        <div class="date-controller card" style="padding: 5px 10px;">
          <button mat-icon-button (click)="changeWeek(-1)"><mat-icon>chevron_left</mat-icon></button>
          <span class="date">{{ filterForm.value['from'] | date:'shortDate' }} - {{ filterForm.value['to'] | date:'shortDate' }}</span>
          <button mat-icon-button (click)="changeWeek(1)"><mat-icon>chevron_right</mat-icon></button>
        </div>
      </div>
    </div>
  </div>

  @if (showLegend) {
    <div class="row">
      <div class="col-12">
        <div class="card" id="legend-card">
          <div class="card-header">
            <div class="card-title">
              <h2>Ayuda</h2>
            </div>
          </div>
          <div class="card-body">
            <p>
              <b>X h</b>: Muestra las horas registradas en Jira y en el sistema de Bisual. El color va en función del descuadre entre las horas registradas y las fichadas durante ese día.
            </p>
            <ul>
              <li>
                <span class="ok">X h: </span>
                <span>≤ 30 minutos</span>
              </li>
              <li>
                <span class="far">X h: </span>
                <span>> 30 minutos y ≤ 2 horas</span>
              </li>
              <li>
                <span class="far-away">X h: </span>
                <span>≤ 2 horas</span>
              </li>
            </ul>
            <p style="margin-top: 30px">
              <b><mat-icon class="clock-register-flag">timer</mat-icon></b>: Muestra el encaje del fichaje según las horas que se esperaban del contrato. No tiene porqué significar nada malo un fichaje en rojo.
            </p>
            <ul>
              <li>
                <mat-icon class="clock-register-flag ok">timer</mat-icon>
                <span>≤ 15 minutos</span>
              </li>
              <li>
                <mat-icon class="clock-register-flag far">timer</mat-icon>
                <span>> 15 minutos y ≤ 2 horas</span>
              </li>
              <li>
                <mat-icon class="clock-register-flag far-away">timer</mat-icon>
                <span>> 2 horas</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  }

  <div class="row">
    <div class="col-12">
      <div class="bisual-table-container">
        <table mat-table [dataSource]="dataSource">

          <ng-container matColumnDef="worker">
            <th mat-header-cell *matHeaderCellDef>Trabajador</th>
            <td mat-cell *matCellDef="let ews; let i = index"><b>{{ ews.user.name + ' ' + ews.user.surnames }}</b></td>
          </ng-container>

          @for (day of daysColumns; track day; let id = $index) {
            <ng-container [matColumnDef]="getColumnKeyByDate(day.day)">
              <th mat-header-cell *matHeaderCellDef>{{ getDayColumnHeader(day.day) }}</th>
              <td mat-cell class="working-time-slot" *matCellDef="let ews; let i = index" (click)="ews.days[id].day > tomorrow ? null : showSlotDetail(ews.days[id])" [ngClass]="{'disabled': ews.days[id].day > tomorrow}">
                <span class="working-time-amount" [ngClass]="[ews.days[id].working_times_flag ?? '']" [matTooltip]="ews.days[id].day > tomorrow ? '' : (ews.days[id].working_times_flag==='ok' || ews.days[id].working_times_flag==='modified' ? '' : ('Descuadrado por: ' + (ews.days[id].working_times_flag_diff_in_hours | number:'1.0-2') + ' horas'))">
                  {{ ews.days[id].working_time_hours | number:'1.0-2' }}h
                </span>
                <mat-icon class="clock-register-flag" [ngClass]="ews.days[id].clock_registers_flag">timer</mat-icon>
              </td>
            </ng-container>
          }

          <ng-container matColumnDef="mood">
            <th mat-header-cell *matHeaderCellDef>Mood</th>
            <td mat-cell *matCellDef="let ews; let i = index">
              @for (cr of getClockRegistersFromEws(ews); track cr) {
                <span class="emoji" [ngClass]="cr.clock_register_mood.mood_type">
                  {{ cr.clock_register_mood.emoji }}
                </span>
              }
            </td>
          </ng-container>

          <ng-container matColumnDef="resume">
            <th mat-header-cell *matHeaderCellDef style="text-align: right">
              <button mat-icon-button (click)="showLegend = !showLegend" matTooltip="Mostrar ayuda">
                <mat-icon class="clock-register-flag" [color]="showLegend ? 'accent' : ''">help</mat-icon>
              </button>
            </th>
            <td mat-cell *matCellDef="let ews; let i = index;" style="text-align: right">
              <b matTooltip="Registradas / Esperadas por contrato" style="cursor: pointer">
                {{ getWorklogsRegisteredTimeInHours(i) | number:'1.0-2' }}h
                /
                {{ getContractWeeklyTimeInHours(i) | number:'1.0-2' }}h
              </b>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let ews; let i = index; columns: displayedColumns;"></tr>
        </table>
      </div>
    </div>
  </div>

</div>
