<h1 mat-dialog-title>{{ data.dialog_title }}</h1>

<mat-dialog-content>
  <div *ngFor="let row of data.data;">
    <mat-checkbox [(ngModel)]="row.checked">{{ row.textToShow }}</mat-checkbox>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button (click)="close()">{{ data.dialog_cancel_button }}</button>
  <button mat-flat-button color="accent" (click)="submit()">{{ data.dialog_ok_button }}</button>
</mat-dialog-actions>
