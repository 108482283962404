<div class="page-title">
  <h1>{{ business_mail!=null ? business_mail.email : 'Cargando...' }}</h1>

  @if (business_mail != null) {
    <button mat-stroked-button [matMenuTriggerFor]="menuOther">
      Acciones
      <mat-icon matSuffix>arrow_drop_down</mat-icon>
    </button>
  }
  <mat-menu #menuOther="matMenu">
    @if (business_mail!=null && business_mail.status=='active') {
      <button mat-menu-item (click)="cancelBusinessMail()" matTooltip="Cancelará el correo corporativo y esperará a eliminarlo a que expire." [disabled]="!permissions.validateDepartment(['direction', 'sysadmin'])">
        <mat-icon style="margin-right: 10px">block</mat-icon>
        Cancelar correo
      </button>
    }
    @if (business_mail!=null && business_mail.ovh_id!=null && !business_mail.is_fact_external) {
      <button mat-menu-item (click)="openInOvh()">
        <mat-icon matPrefix>visibility</mat-icon>
        Ver en Ovh
      </button>
    }
  </mat-menu>
</div>

@if (business_mail!=null) {
  <div class="container">
    <div class="row">
      <div class="col-12 col-lg-4">
        <div class="card action-card">
          <mat-icon>info</mat-icon>
          <div class="card-header">
            <div class="card-title">
              <h2>Estado del correo</h2>
              <div class="subtitle">
                <div class="status-point" [ngClass]="business_mail.status" style="margin-right: 5px;"></div>
                {{ getBusinessMailStatusTooltip(business_mail.status) }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-4">
        <div class="card action-card">
          <mat-icon>dns</mat-icon>
          <div class="card-header">
            <div class="card-title">
              <h2>Proveedor de correo</h2>
              <p class="subtitle">{{ business_mail.provider.name }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-4">
        <div class="card action-card">
          <mat-icon>maps_home_work</mat-icon>
          <div class="card-header">
            <div class="card-title">
              <h2>Propiedad</h2>
              <div class="subtitle">
                @if (business_mail.company_id==null) {
                  <span>Bisual</span>
                }
                @if (business_mail.company_id != null) {
                  <a [routerLink]="['/employees', 'direction', 'users-management', 'companies', business_mail.company.id]">{{ business_mail.company.fact_name }}</a>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
}

@if (business_mail!=null) {
  <div class="bisual-two-col-masonry" style="margin-top: 50px;">
    <div class="card">
      <div class="card-header">
        <div class="card-title">
          <h2>Información general</h2>
          <p class="subtitle">Información principal de la entidad.</p>
        </div>
      </div>
      <div class="container">
        <div class="row">
          <div class="col-12 col-md-6">
            <b>Dirección</b>: {{ business_mail.email }}
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-6">
            <b>Servidor IMAP</b>: {{ business_mail.imap_server }}
          </div>
          <div class="col-12 col-md-6">
            <b>Puerto IMAP</b>: {{ business_mail.imap_port }}
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-6">
            <b>Encriptación IMAP</b>: {{ business_mail.imap_encryption }}
          </div>
          <div class="col-12 col-md-6">
            <b>Servidor SMTP</b>: {{ business_mail.smtp_server }}
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-6">
            <b>Puerto SMTP</b>: {{ business_mail.smtp_port }}
          </div>
          <div class="col-12 col-md-6">
            <b>Encriptación SMTP</b>: {{ business_mail.smtp_encryption }}
          </div>
        </div>
      </div>
    </div>
    @if (business_mail.stripe_id != null) {
      <div class="card">
        <div class="card-header">
          <div class="card-title">
            <h2>Suscripción Stripe</h2>
            <p class="subtitle">Información de la suscripción a Stripe con la que se factura al cliente.</p>
          </div>
        </div>
        <div class="container">
          <div class="row">
            <div class="col-12 col-md-6">
              <b>Estado</b>: {{ business_mail.status | titlecase }}
            </div>
            <div class="col-12 col-md-6">
              <b>Fecha de expiración</b>: {{ business_mail.expiration_date | date:'shortDate' }}
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-6">
              <b>Período de renovación</b>: {{ business_mail.renewal_days_period + ' días' }}
            </div>
            <div class="col-12 col-md-6">
              <b>Período de prueba</b>: {{ business_mail.trial_ends_at!=null ? ('Hasta ' + (business_mail.trial_ends_at | date:'shortDate')) : '-' }}
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-6">
              <b>Facturado externamente</b>: {{ business_mail.is_fact_external ? 'Sí' : 'No' }}
            </div>
            <div class="col-12 col-md-6">
              <b>Fecha de cancelación</b>: {{ business_mail.ends_at!=null ? ('Hasta el ' + (business_mail.ends_at | date:'shortDate')) : '-' }}
            </div>
          </div>
        </div>
      </div>
    }
    @if (business_mail.ovh_exchange_server != null) {
      <div class="card">
        <div class="card-header">
          <div class="card-title">
            <h2>Información de OVH (Servidor)</h2>
            <p class="subtitle">Información recibida de OVH ahora mismo.</p>
          </div>
        </div>
        <div class="container">
          <div class="row">
            <div class="col-12 col-md-6">
              <b>Estado</b>: {{ business_mail.ovh_exchange_server.state | titlecase }}
            </div>
            <div class="col-12 col-md-6">
              <b>Última actualización</b>: {{ business_mail.ovh_exchange_server.lastUpdateDate | date:'short' }}
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-6">
              <b>Nombre</b>: {{ business_mail.ovh_exchange_server.displayName }}
            </div>
            <div class="col-12 col-md-6">
              <b>Webmail</b>: <a [href]="business_mail.ovh_exchange_server.webUrl">{{ business_mail.ovh_exchange_server.webUrl }}</a>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-6">
              <b>Hostname</b>: {{ business_mail.ovh_exchange_server.hostname }}
            </div>
            <div class="col-12 col-md-6">
              <b>Dominio</b>: {{ business_mail.ovh_exchange_server.domain }}
            </div>
          </div>
        </div>
      </div>
    }
    @if (business_mail.ovh_exchange_server_account != null) {
      <div class="card">
        <div class="card-header">
          <div class="card-title">
            <h2>Información de OVH (Cuenta)</h2>
            <p class="subtitle">Información recibida de OVH ahora mismo.</p>
          </div>
        </div>
        <div class="container">
          <div class="row">
            <div class="row">
              <div class="col-12 col-md-6">
                <b>Dirección</b>: {{ business_mail.ovh_exchange_server_account.primaryEmailAddress }}
              </div>
              <div class="col-12 col-md-6">
                <b>Licencia</b>: {{ business_mail.ovh_exchange_server_account.accountLicense | titlecase }}
              </div>
            </div>
            <div class="col-12 col-md-6">
              <b>Estado</b>: {{ business_mail.ovh_exchange_server_account.state | titlecase }}
            </div>
            <div class="col-12 col-md-6">
              <b>Fecha de expiración</b>: {{ business_mail.ovh_exchange_server_account.expirationDate | date:'short' }}
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-6">
              <b>Nombre a mostrar</b>: {{ business_mail.ovh_exchange_server_account.displayName }}
            </div>
            <div class="col-12 col-md-6">
              <b>Cuota</b>: {{ business_mail.ovh_exchange_server_account.currentUsage }} KiB / {{ business_mail.ovh_exchange_server_account.quota }} GiB
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-6">
              <b>Login</b>: {{ business_mail.ovh_exchange_server_account.login }}
            </div>
            <div class="col-12 col-md-6">
              <b>Dominio</b>: {{ business_mail.ovh_exchange_server_account.domain }}
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-6">
              <b>Período de renovación</b>: {{ business_mail.ovh_exchange_server_account.renewPeriod!=null ? (business_mail.ovh_exchange_server_account.renewPeriod | titlecase) : '-' }}
            </div>
            <div class="col-12 col-md-6">
              <b>Se eliminará al expirar</b>: {{ business_mail.ovh_exchange_server_account.deleteAtExpiration ? 'Sí' : 'No' }}
            </div>
          </div>
        </div>
      </div>
    }
  </div>
}
