import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BusinessMail } from 'src/app/models/business_mail.model';
import { ApiService } from 'src/app/services/api.service';
import { PermissionsService } from 'src/app/services/permissions.service';
import * as Countries from 'src/assets/json/countries.json';

@Component({
  selector: 'app-business-mail-detail-ovh',
  templateUrl: './business-mail-detail-ovh.component.html',
  styleUrls: ['./business-mail-detail-ovh.component.css']
})
export class BusinessMailDetailOvhComponent implements OnInit {
  countries:any[] = (Countries as any).default;
  primaryMailAddress:string = null as any;

  private _business_mail:BusinessMail = null as any;
  get business_mail() { return this._business_mail; };
  @Input() set business_mail(bm:BusinessMail) {
    if(bm!=null) {
      this._business_mail = bm;
      this.serverFormGroup.patchValue(bm.ovh_exchange_server);
      this.accountFormGroup.patchValue(bm.ovh_exchange_server_account);
      this.serverVirusForm.patchValue(bm.ovh_exchange_server.spamAndVirusConfiguration);
      this.accountVirusForm.patchValue(bm.ovh_exchange_server_account.spamAndVirusConfiguration);
    }
  }

  serverFormGroup:UntypedFormGroup;
  accountFormGroup:UntypedFormGroup;
  serverVirusForm:UntypedFormGroup;
  accountVirusForm:UntypedFormGroup;
  
  constructor(private fb:UntypedFormBuilder,
              private api:ApiService,
              public permissions:PermissionsService) {
    this.serverFormGroup = this.fb.group({
      displayName: [''],
      complexityEnabled: [''],
      lockoutDuration: ['', Validators.min(0)],
      lockoutObservationWindow: ['', Validators.min(0)],
      lockoutThreshold: ['', Validators.min(0)],
      minPasswordLength: ['', Validators.min(0)],
      minPasswordAge: ['', Validators.min(0)],
      maxPasswordAge: ['', Validators.min(0)],
      maxReceiveSize: ['', Validators.min(0)],
      maxSendSize: ['', Validators.min(0)],
      passwordHistoryCount: ['', Validators.min(0)]
    });

    this.accountFormGroup = this.fb.group({
      accountLicense: ['', Validators.required],
      displayName: [''],
      description: [''],
      login: [''],
      domain: [''],
      company: [''],
      firstName: [''],
      lastName: [''],
      streetAddress: [''],
      postalCode: [''],
      office: [''],
      city: [''],
      mobile: [''],
      phone: [''],
      fax: [''],
      region: [''],
      countryCode: [''],
      jobDepartment: [''],
      jobTitle: [''],
      hiddenFromGAL: [''],
      initial: [''],
      litigation: [''],
      litigationPeriod: [''],
      mailingFilter: [[]],
      outlookLicense: [''],
      quota: [''],
      deleteAtExpiration: [''],
      deleteOutlookAtExpiration: [''],
      renewOutlookPeriod: [''],
      renewPeriod: [''],
    });

    this.serverVirusForm = this.fb.group({
      checkDKIM: [''],
      checkSPF: [''],
      deleteSpam: [''],
      deleteVirus: [''],
      putInJunk: [''],
      tagSpam: [''],
      tagVirus: [''],
    });

    this.accountVirusForm = this.fb.group({
      checkDKIM: [''],
      checkSPF: [''],
      deleteSpam: [''],
      deleteVirus: [''],
      putInJunk: [''],
      tagSpam: [''],
      tagVirus: [''],
    });

  }

  ngOnInit(): void {
  }

  submitExchangeAccount() {
    let data = this.accountFormGroup.value;
    data.spamAndVirusConfiguration = this.accountVirusForm;

    this.api.updateOvhExchangeAccount(this.business_mail.ovh_id, this.primaryMailAddress, data).subscribe(
      data => {
        this.primaryMailAddress = `${this.accountFormGroup.value['login']}@${this.accountFormGroup.value['domain']}`;
      }
    );
  }

  submitExchangeServer() {
    let data = this.serverFormGroup.value;
    data.spamAndVirusConfiguration = this.serverVirusForm;

    this.api.updateOvhExchangeServer(this.business_mail.ovh_id, data).subscribe(
      data => {

      }
    );
  }

}
