import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Department } from 'src/app/models/department.model';
import { Company } from 'src/app/models/company.model';
import { Role } from 'src/app/models/role.model';
import { User } from 'src/app/models/user.model';
import { ApiService } from 'src/app/services/api.service';
import { PermissionsService } from 'src/app/services/permissions.service';
import { UtilsService } from 'src/app/services/utils.service';
import { AddUserDialogComponent } from './add-user-dialog/add-user-dialog.component';
import { DepartmentCategory } from 'src/app/models/department_category.model';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {

  private _workers_mode:boolean = false;
  get workers_mode() { return this._workers_mode; };
  @Input() set workers_mode(val:boolean) {
    this.setWorkersMode(val);
  }

  displayedColumns: string[] = ['image', 'name', 'email', 'role', 'company', 'actions'];
  dataSource:MatTableDataSource<User> = new MatTableDataSource<User>();

  // dades per el paginator
  length:number = 0;
  pageSize:number = 10;
  pageSizeOptions:number[] = [5,10,20,50];
  pageIndex:number = 1;

  filterForm:UntypedFormGroup;
  private formPersistence:any;

  roles:Role[] = [];
  departments:Department[] = [];
  departmentCategories:DepartmentCategory[] = [];
  company: Company[] = [];

  constructor(private api:ApiService,
              private fb:UntypedFormBuilder,
              public router:Router,
              private activatedRoute:ActivatedRoute,
              public permissions:PermissionsService,
              private utils:UtilsService,
              public dialog: MatDialog) {
    this.filterForm = this.fb.group({
      search: [this.activatedRoute.snapshot.queryParamMap.get('search')!=null ? this.activatedRoute.snapshot.queryParamMap.get('search') : '', Validators.compose([Validators.minLength(3)])],
      per_page: [this.activatedRoute.snapshot.queryParamMap.get('per_page')!=null ? +(this.activatedRoute.snapshot.queryParamMap.get('per_page') as any) : this.pageSize, Validators.required],
      page: [this.activatedRoute.snapshot.queryParamMap.get('page')!=null ? +(this.activatedRoute.snapshot.queryParamMap.get('page') as any) : this.pageIndex, Validators.required],
      total: [this.activatedRoute.snapshot.queryParamMap.get('total')!=null ? +(this.activatedRoute.snapshot.queryParamMap.get('total') as any) : this.length],
      role_id: [this.activatedRoute.snapshot.queryParamMap.get('role_id')!=null ? +(this.activatedRoute.snapshot.queryParamMap.get('role_id') as any) : ''],
      // department_id: [this.activatedRoute.snapshot.queryParamMap.get('department_id')!=null ? +(this.activatedRoute.snapshot.queryParamMap.get('department_id') as any) : ''],
      company_id: [this.activatedRoute.snapshot.queryParamMap.get('company_id') != null ? +(this.activatedRoute.snapshot.queryParamMap.get('company_id') as any) : '', ],
      // department_category_id: [this.activatedRoute.snapshot.queryParamMap.get('department_category_id')!=null ? +(this.activatedRoute.snapshot.queryParamMap.get('department_category_id') as any) : ''],
    });
  }

  ngOnInit(): void {
    this.fetchRolesAndDepartments();
    this.initFilterFormListener();
    this.listenQueryParameters();
    this.fetchCompanies();
  }

  changePage(event:PageEvent) {
    this.filterForm.patchValue({
      page: event.pageIndex,
      per_page: event.pageSize
    });
  }

  deleteUser(index:number) {
    const user = this.dataSource.data[index];
    if(confirm(`¿Estás seguro que quieres eliminar el usuario "${user.name + ' ' + user.surnames}"?`)) {
      this.api.deleteUser(user.id).subscribe(
        data => {
          this.fetchUsers();
        }
      );
    }
  }

  addUser() {
    const dialogRef = this.dialog.open(AddUserDialogComponent, {
      width: '750px',
      data: {
        roles: this.roles,
      }
    });

    dialogRef.afterClosed().subscribe((user:User) => {
      if(user != null) {
        this.fetchUsers();
      }
    });
  }

  private fetchUsers() {
    const body = { ...this.filterForm.value, with: 'role,current_working_contract..current_working_contract_variable_condition..department_category' }
    this.api.getUsersPaged(body).subscribe(
      data => {
        this.dataSource.data = data.data;

        this.length = +data.meta.total;
        this.pageIndex = +data.meta.current_page-1;
        this.pageSize = +data.meta.per_page;
      }
    );
  }

  private initFilterFormListener() {
    this.filterForm.valueChanges.subscribe(
      data => {
        data.per_page = +data.per_page;
        if(this.formPersistence==null || JSON.stringify(this.formPersistence)!=JSON.stringify(data)) {
          if(this.formPersistence.per_page!=data.per_page) data.page = 0;
          const route = this.workers_mode ? ['/employees', 'rrhh', 'workers'] : ['/employees', 'direction', 'users-management', 'users'];
          this.router.navigate(route, { queryParams: data });
        }
      }
    );
  }

  private listenQueryParameters() {
    this.activatedRoute.queryParams.subscribe(
      params => {
        if(JSON.stringify(params)!==JSON.stringify(this.filterForm.value)) { //si no ve de filtre s'ha de setejar el form
          let params_temp = this.utils.cloneObj(params); //params es read_only
          Object.keys(params_temp).forEach(param_key => {
            if(params_temp[param_key]!=null && params_temp[param_key]!="" && !isNaN(+params_temp[param_key])) params_temp[param_key] = +params_temp[param_key]; // si es numero, el transformem
          });
          this.filterForm.patchValue(params_temp, { emitEvent: false });
        }
        this.formPersistence = params;
        this.fetchUsers();
      }
    );
  }

  private fetchRolesAndDepartments() {
    this.permissions.rolesObservable.subscribe(
      data => {
        if(data != null) {
          this.roles = Object.keys(data).map(k => data[k]);
        }
      }
    );

    this.permissions.departmentsObservable.subscribe(
      data => {
        if(data != null) {
          this.departments = Object.keys(data).map(k => data[k]);
        }
      }
    );

    this.permissions.departmentCategoriesObservable.subscribe(
      data => {
        if(data != null) {
          this.departmentCategories = Object.keys(data).map(k => data[k]);
        }
      }
    );
  }

  private fetchCompanies() {
    this.api.getCompaniesPaged().subscribe((data) => {
      if (data != null) {
        this.company = data.data;
      }
    });
  }

  private setWorkersMode(val:boolean) {
    if(val!=null) {
      // setegem valor
      this._workers_mode = val;

      if(val) {
        // modifiquem form
        this.filterForm.patchValue({ role_id: 1 });
        // modifiquem columnes
        this.displayedColumns = ['image', 'name', 'department', 'actions'];
      }
      else {
        // modifiquem form
        this.filterForm.patchValue({ role_id: null });
        // modifiquem columnes
        this.displayedColumns = ['image', 'name', 'role', 'department', 'company', 'actions'];
      }
    }
  }

}
