@if (resume!=null) {
  <div class="row">
    <div class="col-12">
      <div class="bisual-table-container">
        <table mat-table #table [dataSource]="dataSource">
          <!-- FIXED -->
          <ng-container matColumnDef="type">
            <th mat-header-cell *matHeaderCellDef>
              <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="openCalendars()" [disabled]="calendar_button_disabled">
                  <mat-icon>event_upcoming</mat-icon>
                  <span>{{ permissions.validateDepartment(['direction', 'rrhh']) ? 'Gestionar calendarios' : 'Ver calendarios'}}</span>
                </button>
                @if (permissions.validateDepartment(['direction', 'rrhh'])) {
                  <button mat-menu-item (click)="copyLastWeek()" [disabled]="copy_button_disabled">
                    <mat-icon>copy_all</mat-icon>
                    <span>Copiar semana anterior</span>
                  </button>
                }
                @if (permissions.validateDepartment(['direction', 'rrhh'])) {
                  <button mat-menu-item (click)="copyWeekFromTo()" [disabled]="copy_button_disabled">
                    <mat-icon>copy_all</mat-icon>
                    <span>Copiar rango</span>
                  </button>
                }
              </mat-menu>
            </th>
            <ng-container *matCellDef="let row;">
              @if (row.is_first) {
                <td mat-cell [attr.rowspan]="row.rowspan">
                  <span>{{ (row.type ==='wtc') ? 'No Desarrollo' : 'Desarrollo' }}</span>
                </td>
              }
            </ng-container>
            <td mat-footer-cell *matFooterCellDef></td>
          </ng-container>
          <ng-container matColumnDef="category">
            <th mat-header-cell *matHeaderCellDef>
              @if (showSaveButton) {
                <button mat-flat-button color="success" [disabled]="isSaving" (click)="save()">
                  <mat-icon>done</mat-icon>
                  {{ isSaving ? 'Guardando...' : 'Guardar cambios' }}
                </button>
              }
            </th>
            <td mat-cell *matCellDef="let row;" class="td-item">
              @if (row.deep == 0 || row.type ==='wtc') {
                <div style="display: flex; flex-direction: row;">
                  <span class="color" [style.background-color]="row.color" [ngClass]="{'partner': row.type=='partner-contract'}" [matTooltip]="row.type=='partner-contract' ? 'Contrato de partner' : ''"></span>
                  <div style="display: flex; flex-direction: row;">
                    <span [ngStyle]="{'line-height': !row.company_name ? '27px' : 'initial'}">
                      <strong style="overflow: initial; white-space: nowrap;">{{ row.name }}</strong>
                      <br>
                        <span class="subtitle">{{ row.company_name ? row.company_name : '' }}</span>
                      </span>
                      @if (row.type != 'wtc' && row.child_length > 0) {
                        <div style="margin-left: 5px; align-self: flex-end; overflow: initial; white-space: nowrap; margin-right: 10px;" mat-stroked-button type="button" (click)="hideChilds(row, row.deep, $event)">
                          <mat-icon class="hide" [@expandCollapse]="dataSource.data[dataSource.data.indexOf(row) + 1].hidden ? 'collapsed' : 'expanded'">expand_more</mat-icon>
                        </div>
                      }
                    </div>
                  </div>
                }
              </td>
              <td mat-footer-cell *matFooterCellDef #cell>
                @if (isFooterRow('horas-desarrollo', cell) && permissions.validateDepartment(['direction', 'rrhh'])) {
                  <b>Horas de desarrollo</b>
                }
                @if (isFooterRow('horas-no-desarrollo', cell) && permissions.validateDepartment(['direction', 'rrhh'])) {
                  <b>Horas de NO desarrollo</b>
                }
                @if (isFooterRow('production_prcnt', cell) && permissions.validateDepartment(['direction', 'rrhh'])) {
                  <b>% de producción</b>
                }
                @if (isFooterRow('totals', cell)) {
                  <b>Totales</b>
                }
              </td>
            </ng-container>
            @for (item of [].constructor(1); track trackByIndexOnly(i); let i = $index) {
              <div class="month">
                <ng-container matColumnDef="child1">
                  <th mat-header-cell *matHeaderCellDef></th>
                  <td mat-cell *matCellDef="let row;">
                    @if ((row.type !='wtc' && row.type !='company')) {
                      <div class="main-item" [style.padding-left.px]="row.deep * 15">
                        <span>{{ row.name }}</span>
                        @if (row.type !='wtc' && row.child_length > 0) {
                          <div style="margin-left: 10px; align-self: flex-end;" mat-stroked-button type="button" (click)="hideChilds(row, row.deep,$event)"><mat-icon class="show" [@expandCollapse]="dataSource.data[dataSource.data.indexOf(row) +1].hidden ? 'collapsed' : 'expanded'">expand_more</mat-icon></div>
                        }
                      </div>
                    }
                  </td>
                  <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>
              </div>
            }
            <ng-container matColumnDef="general">
              <th mat-header-cell *matHeaderCellDef>General</th>
              <td mat-cell *matCellDef="let row;">
                @if (row.is_schedulable || row.type == 'company') {
                  <span class="circle" [matTooltip]="row.type==='wtc' ? 'Horas totales que realizaran los empleados' : 'Horas totales firmadas por contrato a realizar esta semana (ponderadas, aplicando el % de producción)'">{{ row.hours_assigned | number:'1.0-2' }}h</span>
                }
                @if (row.hours_unassigned !=null && row.is_schedulable ) {
                  <span class="circle pending" [ngClass]="{'ok': row.hours_unassigned<=1, 'overflow': row.hours_unassigned > 5 }" [matTooltip]="'Horas que faltan por asignar (ponderadas, aplicando el % de producción)'">{{ row.hours_unassigned | number:'1.0-2' }}h</span>
                }
                @if (row.hours_unassigned !=null && !row.is_schedulable && row.deep == 0 ) {
                  <span class="circle pending" [ngClass]="{'ok': row.hours_assigned - row.hours_unassigned <=1, 'overflow': (row.hours_unassigned - row.hours_assigned > 1 ) }" [matTooltip]="'Horas asignadas al proyecto'">{{ row.hours_unassigned | number:'1.0-2' }}h</span>
                }
              </td>
              <td mat-footer-cell *matFooterCellDef #cell>
                @if (isFooterRow('horas-desarrollo', cell)  && permissions.validateDepartment(['direction', 'rrhh'])) {
                  <span class="circle" [matTooltip]="'Horas totales disponibles para desarrollo (reales, no ponderadas con el % de producción). Horas ponderadas: ' + (resume.general.development_hours.to_do_budgetable | number:'1.0-2') + 'h'">{{ resume.general.development_hours.to_do | number:'1.0-2' }}h</span>
                  <span class="circle pending" [matTooltip]="'Horas totales asignadas a los empleados (reales, no ponderadas con el % de producción)'" [ngClass]="{'ok': resume.general.development_hours.assigned >= resume.general.development_hours.to_do }">{{ resume.general.development_hours.assigned | number:'1.0-2' }}h</span>
                }
                @if (isFooterRow('horas-no-desarrollo', cell)  && permissions.validateDepartment(['direction', 'rrhh'])) {
                  <span class="circle" [matTooltip]="'Horas a realizar en total de no desarrollo'">{{ resume.general.non_development_hours.to_do | number:'1.0-2' }}h</span>
                  <span class="circle pending" [matTooltip]="'Horas totales asignadas en NO desarrollo'" [ngClass]="{'ok': resume.general.non_development_hours.assigned >= resume.general.non_development_hours.to_do }">{{ resume.general.non_development_hours.assigned | number:'1.0-2' }}h</span>
                }
                @if (isFooterRow('totals', cell)) {
                  <span class="circle" [matTooltip]="'Horas totales disponibles en todo el equipo.'">{{ resume.general.totals.to_do | number:'1.0-2' }}h</span>
                  <span class="circle pending" [matTooltip]="'Horas totales asignadas a nivel de equipo'" [ngClass]="{'ok': resume.general.totals.assigned >= resume.general.totals.to_do }">{{ resume.general.totals.assigned | number:'1.0-2' }}h</span>
                }
              </td>
            </ng-container>
            <!-- USERS -->
            @for (u of resume.users; track trackByIndexUsers(userIndex); let userIndex = $index) {
              <ng-container [matColumnDef]="createColumnKey(u.id)" >
                <th mat-header-cell *matHeaderCellDef class="mat-column-user" [matTooltip]="u.has_holidays ? 'Tiene festivos' : (u.has_vacations ? 'Tiene vacaciones' : '')" style="cursor: pointer;">
                  {{ u.has_holidays ? '🟡 ' : '' }}
                  {{ u.has_vacations ? '🔵 ' : '' }}
                  {{ u.name + ' ' + (u.surnames.split(' ')[0])[0] + '.' }}
                  {{ u.has_vacations ? ' 🔵' : '' }}
                  {{ u.has_holidays ? ' 🟡' : '' }}
                </th>
                <td mat-cell *matCellDef="let row; let rowIndex = index;" class="mat-column-user">
                  @if (row.is_schedulable && checkUserPermissions(row, userIndex)) {
                    <input
                      type="number"
                      min="0"
                      [ngModel]="row.users[userIndex].hours"
                      (ngModelChange)="onChangeInput(rowIndex, userIndex, $event)" class="user-input"
                      [ngClass]="{'changed': row.users[userIndex].changed, 'not-filled': row.users[userIndex].hours==null || row.users[userIndex].hours==0}"
                      (dblclick)="onDblClickInput(rowIndex, userIndex)"
                      >
                  }
                  @if (row.is_schedulable && !checkUserPermissions(row, userIndex)) {
                    <span>{{row.users[userIndex].hours}}</span>
                  }
                </td>
                <td mat-footer-cell *matFooterCellDef #cell class="mat-column-user">
                  @if (isFooterRow('horas-desarrollo', cell)  && permissions.validateDepartment(['direction', 'rrhh'])) {
                    <span class="circle" [matTooltip]="'Horas a realizar de desarrollo por este empleado (reales, no ponderadas por el % de producción)'">{{ resume.users[userIndex].development_hours.to_do | number:'1.0-2' }}h</span>
                    <span class="circle pending" [matTooltip]="'Horas asignadas de desarrollo a este empleado'" [ngClass]="{ 'ok': acceptableHoursAssigned(resume.users[userIndex].development_hours.assigned,resume.users[userIndex].development_hours.to_do), 'overflow': overflowHoursAssigned(resume.users[userIndex].development_hours.to_do, resume.users[userIndex].development_hours.assigned) }">{{ resume.users[userIndex].development_hours.assigned | number:'1.0-2' }}h</span>
                  }
                  @if (isFooterRow('horas-no-desarrollo', cell)  && permissions.validateDepartment(['direction', 'rrhh'])) {
                    <span class="circle" [matTooltip]="'Horas a realizar por el empleado de NO desarrollo'">{{ resume.users[userIndex].non_development_hours.to_do | number:'1.0-2' }}h</span>
                    <span class="circle pending" [matTooltip]="'Horas asignadas de NO desarrollo a este empleado'" [ngClass]="{ 'ok': acceptableHoursAssigned(resume.users[userIndex].non_development_hours.assigned,resume.users[userIndex].non_development_hours.to_do), 'overflow': overflowHoursAssigned(resume.users[userIndex].non_development_hours.to_do, resume.users[userIndex].non_development_hours.assigned) }">{{ resume.users[userIndex].non_development_hours.assigned | number:'1.0-2' }}h</span>
                  }
                  @if (isFooterRow('production_prcnt', cell)  && permissions.validateDepartment(['direction', 'rrhh'])) {
                    {{ resume.users[userIndex].productivity_prcnt | number:'1.0-2' }}%
                  }
                  @if (isFooterRow('totals', cell)) {
                    <span class="circle" [matTooltip]="'Horas totales que tiene el empleado por contrato. Se contemplan vacaciones y festivos.'">{{ resume.users[userIndex].totals.to_do | number:'1.0-2' }}h</span>
                    <span class="circle pending" [matTooltip]="'Horas asignadas para este empleado esta semana'" [ngClass]="{ 'ok': acceptableHoursAssigned(resume.users[userIndex].totals.assigned,resume.users[userIndex].totals.to_do), 'overflow': overflowHoursAssigned(resume.users[userIndex].totals.to_do, resume.users[userIndex].totals.assigned) }">{{ resume.users[userIndex].totals.assigned | number:'1.0-2' }}h</span>
                  }
                </td>
              </ng-container>
            }
            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <ng-container *matRowDef="let row; let i = index; columns: displayedColumns;" >
              <tr mat-row [ngClass]="{ 'first-contract-row': row.type.indexOf('contract')!=-1 && row.is_first }" [hidden]="row.hidden" ></tr>
            </ng-container>
            @if (permissions.validateDepartment(['direction', 'rrhh'])) {
              <div>
                <tr mat-footer-row *matFooterRowDef="displayedColumns; sticky: true" id="horas-desarrollo"></tr>
                <tr mat-footer-row *matFooterRowDef="displayedColumns; sticky: true" id="horas-no-desarrollo"></tr>
                <tr mat-footer-row *matFooterRowDef="displayedColumns; sticky: true" id="production_prcnt"></tr>
              </div>
            }
            <tr mat-footer-row *matFooterRowDef="displayedColumns; sticky: true" id="totals"></tr>
          </table>
        </div>
      </div>
    </div>
  }
