import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Department } from 'src/app/models/department.model';
import { Role } from 'src/app/models/role.model';
import { ApiService } from 'src/app/services/api.service';
import { PermissionsService } from 'src/app/services/permissions.service';
import { TokenService } from 'src/app/services/token.service';
import { ChangePasswordDialogComponent } from './change-password-dialog/change-password-dialog.component';
import { RegexService } from 'src/app/services/regex.service';
import { Company } from 'src/app/models/company.model';
import { UserService } from 'src/app/services/user.service';
import { User } from 'src/app/models/user.model';
import { WorkingContract } from 'src/app/models/working_contract.model';
import { DepartmentCategory } from 'src/app/models/department_category.model';
import * as Countries from 'src/assets/json/countries.json';
import * as moment from 'moment';

@Component({
  selector: 'app-users-detail',
  templateUrl: './users-detail.component.html',
  styleUrls: ['./users-detail.component.css']
})
export class UsersDetailComponent implements OnInit {

  user_id:string = null as any;

  inheritIdContent:boolean = false;

  userForm:UntypedFormGroup;
  userContractForm:UntypedFormGroup;
  companyForm:UntypedFormGroup;
  activeContract:WorkingContract = null as any;

  roles:Role[] = [];
  departments:Department[] = [];
  available_customers:Company[] = [];

  clientSelectControl = new UntypedFormControl();
  customerSelected:Company = null as any;
  availableCategories: DepartmentCategory[] = [];
  countries:any[] = (Countries as any).default;
  user:User = null as any;

  itsMe:boolean = false;
  tableData:any[] = [];
  displayedColumns: string[] = ['position', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sabado', 'Domingo'];
  me:User = null as any;

  constructor(private activatedRoute:ActivatedRoute,
              private api:ApiService,
              private fb:UntypedFormBuilder,
              public permissions:PermissionsService,
              public router:Router,
              public dialog: MatDialog,
              private tokenService:TokenService,
              private regex:RegexService,
              private userService:UserService) {

    const regexPatterns = this.regex.getRegex();

    this.userForm = this.fb.group({
      name: ['', Validators.compose([Validators.required])],
      surnames: ['', Validators.compose([Validators.required])],
      email: ['', Validators.compose([Validators.required, Validators.email])],
      sex: ['', Validators.compose([Validators.required])],
      birthday: ['', Validators.compose([Validators.required])],
      phone: ['', Validators.compose([Validators.required, Validators.pattern(regexPatterns['phone'])])],
      role_id: ['', Validators.compose([Validators.required])],
      department_category_id:[''],
      department_id: [''],
      company_id: [''],
    });

    this.userForm.controls['email'].disable();

    this.userContractForm = this.fb.group({
      type_contract: '',
      price_hour: 0,
      net_price_year: 0,
      payment_year: 0,
      hour_week: 0,
      start_date_contract: '',
      end_date_contract: '',
      vacations_days: 0,
      job_description: '',
    });

    this.userContractForm.disable();

    this.companyForm = this.fb.group({
      fact_name: ['', Validators.compose([Validators.required])],
      email: ['', Validators.compose([Validators.required])],
      phone: ['', Validators.compose([Validators.required])],
      fact_address: '',
      fact_city: '',
      fact_zipcode: '',
      fact_county: '',
      fact_country: '',
      vat: ['', Validators.compose([Validators.required])],
    });
  }

  ngOnInit(): void {
    this.inheritIdContent = this.router.url.includes("/direction/users-management/users");
    this.fetchRolesAndDepartments();
    this.user_id = (this.activatedRoute.snapshot.paramMap.get('id') ?? this.userService.getCurrentUser().id) as string;
    this.fetchUser();
    this.listenControlSearch();
    this.listenMe();
    this.fetchContractUser();

    this.userForm.controls['department_id'].valueChanges.subscribe(value => {
      var d = this.departments.find(department => department.id === value);
      this.availableCategories = d?.department_categories ?? [];
    });
  }

  canEdit() {
    return this.itsMe || (this.permissions.validateDepartment(['direction', 'rrhh']));
  }

  saveUser() {
    const body = { ...this.userForm.value };
    if (body.birthday != null) body.birthday = moment(body.birthday).format('YYYY-MM-DD');

    this.api.updateUser(this.user_id, body).subscribe(
      data => {

      }
    );
  }

  saveCompany() {
    this.api.updateCompany(this.customerSelected.id.toString(), this.companyForm.value).subscribe(
      data => {

      }
    );
  }

  delete() {
    if(confirm("¿Estás seguro que quieres eliminar este usuario?")) {
      this.api.deleteUser(+this.user_id).subscribe(
        data => {
          this.router.navigate(['/employees', 'direction', 'users-management', 'users']);
        }
      );
    }
  }

  changePassword(): void {
    const dialogRef = this.dialog.open(ChangePasswordDialogComponent, {
      width: '450px',
    });

    dialogRef.afterClosed().subscribe((password:string) => {
      if(password != null) {
        this.api.changeUserPassword(this.user_id, password).subscribe(
          data => {

          }
        );
      }
    });
  }

  selectCustomer(c:Company) {
    this.userForm.patchValue({company_id: c.id});
  }

  resetCustomer() {
    this.available_customers = [];
    this.userForm.patchValue({company_id: null});
  }

  displayFn(company:Company): string {
    return company ? company.fact_name : '';
  }

  private fetchUser() {
    this.api.getUser(this.user_id, { with: 'current_working_contract.current_working_contract_variable_condition.department_category,role' }).subscribe(
      data => {
        this.user = data;
        this.userForm.patchValue(data);
        if(data.role.slug === "worker" && data.current_working_contract.current_working_contract_variable_condition.department_category != null) {
          this.userForm.patchValue({"department_id": data.current_working_contract.current_working_contract_variable_condition.department_category.department_id});
        }
        this.refreshItsMe();

        if(data.company_id!=null) {
          this.fetchCompany();
        }
      }
    );
  }

  private fetchContractUser() {
    this.api.getContractUser(this.user_id, { with: 'current_working_contract_variable_condition' }).subscribe(data => {
      if(data.id!=null) {
        this.activeContract = data;
        this.userContractForm.patchValue(data);

        this.tableData = [
          {
            position: 'Mañana',
            monday_entry_time: data.current_working_contract_variable_condition.monday_morning_entry_time,
            monday_exit_time: data.current_working_contract_variable_condition.monday_morning_exit_time,
            tuesday_entry_time: data.current_working_contract_variable_condition.tuesday_morning_entry_time,
            tuesday_exit_time: data.current_working_contract_variable_condition.tuesday_morning_exit_time,
            wednesday_entry_time: data.current_working_contract_variable_condition.wednesday_morning_entry_time,
            wednesday_exit_time: data.current_working_contract_variable_condition.wednesday_morning_exit_time,
            thursday_entry_time: data.current_working_contract_variable_condition.thursday_morning_entry_time,
            thursday_exit_time: data.current_working_contract_variable_condition.thursday_morning_exit_time,
            friday_entry_time: data.current_working_contract_variable_condition.friday_morning_entry_time,
            friday_exit_time: data.current_working_contract_variable_condition.friday_morning_exit_time,
            saturday_entry_time: data.current_working_contract_variable_condition.saturday_morning_entry_time,
            saturday_exit_time: data.current_working_contract_variable_condition.saturday_morning_exit_time,
            sunday_entry_time: data.current_working_contract_variable_condition.sunday_morning_entry_time,
            sunday_exit_time: data.current_working_contract_variable_condition.sunday_morning_exit_time
          },
          {
            position: 'Tarde',
            monday_entry_time: data.current_working_contract_variable_condition.monday_afternoon_entry_time,
            monday_exit_time: data.current_working_contract_variable_condition.monday_afternoon_exit_time,
            tuesday_entry_time: data.current_working_contract_variable_condition.tuesday_afternoon_entry_time,
            tuesday_exit_time: data.current_working_contract_variable_condition.tuesday_afternoon_exit_time,
            wednesday_entry_time: data.current_working_contract_variable_condition.wednesday_afternoon_entry_time,
            wednesday_exit_time: data.current_working_contract_variable_condition.wednesday_afternoon_exit_time,
            thursday_entry_time: data.current_working_contract_variable_condition.thursday_afternoon_entry_time,
            thursday_exit_time: data.current_working_contract_variable_condition.thursday_afternoon_exit_time,
            friday_entry_time: data.current_working_contract_variable_condition.friday_afternoon_entry_time,
            friday_exit_time: data.current_working_contract_variable_condition.friday_afternoon_exit_time,
            saturday_entry_time: data.current_working_contract_variable_condition.saturday_afternoon_entry_time,
            saturday_exit_time: data.current_working_contract_variable_condition.saturday_afternoon_exit_time,
            sunday_entry_time: data.current_working_contract_variable_condition.sunday_afternoon_entry_time,
            sunday_exit_time: data.current_working_contract_variable_condition.sunday_afternoon_exit_time
          }
        ]
      }
    });
  }

  private fetchCompany() {
    this.api.getCompany(this.userForm.value['company_id']).subscribe(
      data => {
        this.customerSelected = data;
        this.clientSelectControl.setValue(data);
        this.companyForm.patchValue(data);
      }
    );
  }

  private fetchRolesAndDepartments() {
    this.permissions.rolesObservable.subscribe(
      data => {
        if(data != null) {
          this.roles = Object.keys(data).map(k => data[k]);
        }
      }
    );

    this.permissions.departmentsObservable.subscribe(
      data => {
        if(data != null) {
          this.departments = Object.keys(data).map(k => data[k]);
        }
      }
    );
  }

  private listenControlSearch() {
    this.clientSelectControl.valueChanges.subscribe(
      (search_string:string) => {
        if(search_string != null && search_string.length >= 3) {
          const params = {
            search: search_string
          };
          this.api.getCompanies(params).subscribe(
            data => {
              this.available_customers = data.data;
            }
          );
        }
        else this.available_customers = [];
      }
    );
  }

  private listenMe() {
    this.userService.currentUser.subscribe(
      data => {
        this.me = data;
        this.refreshItsMe();
      }
    );
  }

  private refreshItsMe() {
    if(this.userForm.value['name']!='' && this.me != null) {
      this.itsMe = (+this.user_id) == this.me.id || this.activatedRoute.snapshot.url.map(seg => seg.path).indexOf('my-profile') > -1;
    }
  }

}
