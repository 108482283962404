<div class="row text-center">
  <div class="col-md-4">
    <div class="btn-group">
      <div
        class="btn btn-primary"
        mwlCalendarPreviousView
        [view]="view"
        [(viewDate)]="viewDate"
        (viewDateChange)="onViewDateChange()"
      >
        Anterior
      </div>
      <div
        class="btn btn-outline-secondary"
        mwlCalendarToday
        [(viewDate)]="viewDate"
      >
        Hoy
      </div>
      <div
        class="btn btn-primary"
        mwlCalendarNextView
        [view]="view"
        [(viewDate)]="viewDate"
        (viewDateChange)="onViewDateChange()"
      >
        Siguiente
      </div>
    </div>
  </div>
  <div class="col-md-4">
    <h3>{{ viewDate | calendarDate:(view + 'ViewTitle'):'es' }}</h3>
  </div>
</div>

<mwl-calendar-month-view
  [viewDate]="viewDate"
  [events]="events"
  [locale]="locale"
  [weekStartsOn]="weekStartsOn"
  [weekendDays]="weekendDays"
  [refresh]="refresh"
  [cellTemplate]="customMonthCell"
>
</mwl-calendar-month-view>

<ng-template #customMonthCell let-day="day">
  <div
    class="cal-day-cell"
    [ngClass]="{
      'cal-out-month': !day.inMonth,
      'cal-in-month': day.inMonth,
      'cal-weekend': day.isWeekend
    }"
  >
    <!-- Muestra el número del día -->
    <div class="cal-day-number">
      {{ day.date | date: 'd' }}
    </div>

    <!-- Itera sobre los eventos del día -->
    <div class="custom-event row">
      <div *ngFor="let event of day.events" class="col">
        @if (event.meta.type == 'holiday') {
          <span class="event" [matTooltip]="event.title">🎉</span>
        } @else if (event.meta.type == 'vacation') {
          <img *ngIf="event.meta.imageUrl" [src]="event.meta.imageUrl" alt="Event image" class="event" [matTooltip]="event.title"/>
        } @else if (event.meta.type == 'birthday') {
          <span class="event" [matTooltip]="event.title">🎁</span>
        }
      </div>
    </div>
  </div>
</ng-template>
