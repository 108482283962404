<div class="container">
  <div class="row">
    <div class="col-12">
      <div class="page-title">
        <h1>Mis vacaciones</h1>

        <button mat-flat-button (click)="openCalendarFestivities()">Configurar calendario festivo</button>
        <button mat-flat-button color="accent" (click)="open()">Solicitar vacaciones</button>
      </div>
    </div>
  </div>

  @if (contract!=null) {
    <div class="row">
      <div class="col-12 col-md-4">
        <div class="card">
          <h2>Vacaciones totales</h2>
          <p class="subtitle">Por tu contrato</p>
          <p class="price" matTooltip="Tienes {{this.contract.vacation_days_info.vacations_total_days_available.vacations_days_per_month}} días de vacaciones por mes trabajado.">
            {{this.contract.vacation_days_info.vacations_total_days_available.days_total}} días <small class="total_hours">({{this.contract.vacation_days_info.vacations_total_days_available.hours_total}} h.)</small>
          </p>
        </div>
      </div>
      <div class="col-12 col-md-4">
        <div class="card">
          <h2>Vacaciones restantes</h2>
          <p class="subtitle">Durante {{ now.getFullYear() }}</p>
          <p class="price">
            {{ (this.contract.vacation_days_info.vacations_total_days_available.days_total - this.contract.vacation_days_info.vacations_days_spent.days_total) | number:'1.0-2' }} días
            <small class="total_hours">({{ (this.contract.vacation_days_info.vacations_total_days_available.hours_total - this.contract.vacation_days_info.vacations_days_spent.hours_total) | number:'1.0-2' }} h.)</small>
          </p>
        </div>
      </div>
      <div class="col-12 col-md-4">
        <div class="card">
          <h2>Próximas vacas</h2>
          @if (this.contract!.vacation_day_next_available != 0) {
            <p class="subtitle"></p>
          } @else {
            <p class="subtitle">-</p>
          }
          @if (this.contract!.vacation_day_next_available != null) {
            <p class="price">Quedan {{this.contract.vacation_day_next_available}} días</p>
          } @else {
            <p class="price">-</p>
          }
        </div>
      </div>
    </div>
  }

  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="bisual-table">
          <div class="bisual-table-row-header">
            <div class="bisual-table-col">Id</div>
            <div class="bisual-table-col">Estado</div>
            <div class="bisual-table-col">Días solicitados</div>
            <div class="bisual-table-col">Horas solicitadas</div>
            <div class="bisual-table-col">Fechas</div>
            <div class="bisual-table-col">Año de solicitud</div>
            <div class="bisual-table-col"></div>
          </div>
          @for (req of vacation_requests; track req) {
            <div class="bisual-table-row">
              <div class="bisual-table-col">#{{ req.id }}</div>
              <div class="bisual-table-col">
                {{ req.status=='pending' ? 'Pendiente' : (req.status=='rejected' ? 'Declinado' : (req.status=='accepted' ? 'Aceptado' : 'Desconocido')) }}
              </div>
              <div class="bisual-table-col">{{ (req.count_hours_of_vacations / 8) | number: '1.1-1' }} días</div>
              <div class="bisual-table-col">{{ req.count_hours_of_vacations }} horas</div>
              <!-- Show all days in the tool tip -->
              <div class="bisual-table-col" [matTooltip]="showAllDays(req)">
                {{
                req.vacation_request_periods.length===0 ? '-' : (
                req.vacation_request_periods.length===1 ? (req.vacation_request_periods[0].day | date:'shortDate') : (
                'Entre ' + (req.vacation_request_periods[0].day | date:'shortDate') + ' y ' + (req.vacation_request_periods[req.vacation_request_periods.length-1].day | date:'shortDate')
                )
                )
                }}
              </div>
              <div class="bisual-table-col">{{ req.created_at | date:'YYYY' }}</div>
              <div class="bisual-table-col" style="text-align: right;">
                <button mat-icon-button [matMenuTriggerFor]="menu">
                  <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                  <button mat-menu-item (click)="eliminateVacationRequest(req.id)" [disabled]="req.status != 'pending'">
                    <mat-icon style="margin-right: 10px">delete</mat-icon>
                    Eliminar solicitud
                  </button>
                </mat-menu>
              </div>
            </div>
          }
        </div>
      </div>
    </div>
  </div>
