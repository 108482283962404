import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Company } from '../models/company.model';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  private customerSubject:BehaviorSubject<Company> = new BehaviorSubject(null as any);
  public customerObservable:Observable<Company> = this.customerSubject.asObservable();

  constructor() { }

  selectCustomer(customer:Company) {
    this.customerSubject.next(customer);
  }

  reset() {
    this.customerSubject.next(null as any);
  }

}
