import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'projectTaskTypeImage'
})
export class ProjectTaskTypeImagePipe implements PipeTransform {

  transform(value: string): string {
    switch(value) {
      case 'task':
        return 'assets/img/icons/task_icon.svg';
      case 'issue':
        return 'assets/img/icons/issue_icon.svg';
      case 'story':
        return 'assets/img/icons/story_icon.svg';
      default:
        return '';
    }
  }

}
