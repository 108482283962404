<h1 mat-dialog-title>Configuración de la columna "{{projectPhaseBoardCol.title}}"</h1>

<mat-dialog-content [formGroup]="form" class="pb-3">
  <div class="container">
    <div class="row mt-3">
      <div class="col-12 col-lg-6">
        <mat-form-field appearance="outline" style="width: 100%;">
          <mat-label>Roles</mat-label>
          <mat-select formControlName="project_phase_board_col_role_id">
            @for (role of projectPhaseBoardColRoles | keyvalue; track role) {
              <mat-option [value]="role.value.id">{{role.value.name}}</mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-12 col-lg-6">
        <p>
          {{description}}
        </p>
      </div>
    </div>
    @if (project_phase != null && project_phase.project_deploy_weekly_notification_configuration_id != null) {
      <div class="row">
        <div class="col-12 col-lg-6">
          <mat-form-field appearance="outline" style="width: 100%">
            <mat-label>Configuración de deploy</mat-label>
            <mat-select formControlName="environment_id">
              <mat-option value="">Sin seleccionar</mat-option>
              @for (env of environments; track env) {
                <mat-option [value]="env.id">{{ env.name }}</mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-12 col-lg-6">
          <p>
            Si tienes una opción seleccionada en este dropdown, quiere decir que cuando arrastres una tarea aquí, habrá sido desplegada en el entorno seleccionado. Esto permitirá enviar informes automáticos al cliente de los despliegues.
          </p>
        </div>
      </div>
    }
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button (click)="onCancel()" cdkFocusInitial type="button">Cancelar</button>
  <button mat-flat-button color="accent" [disabled]="!form.valid" type="submit" (click)="onSubmit()">Actualizar configuración</button>
</mat-dialog-actions>
