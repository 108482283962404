import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Domain } from 'src/app/models/domain.model';
import { ApiService } from 'src/app/services/api.service';
import { PermissionsService } from 'src/app/services/permissions.service';
import { RegexService } from 'src/app/services/regex.service';

@Component({
  selector: 'app-domain-detail-edit',
  templateUrl: './domain-detail-edit.component.html',
  styleUrls: ['./domain-detail-edit.component.css']
})
export class DomainDetailEditComponent implements OnInit {

  formGroup:UntypedFormGroup;

  private _domain:Domain = null as any;
  get domain() { return this._domain; };
  @Input() set domain(d:Domain) {
    if(d!=null) {
      this._domain = d;
      this.formGroup.patchValue(d);
    }
  }

  constructor(private fb:UntypedFormBuilder,
              private api:ApiService,
              private regex:RegexService,
              public permissions:PermissionsService) {

    const regexParams = this.regex.getRegex();

    this.formGroup = this.fb.group({
      id: [''],
      domain: ['', Validators.compose([Validators.required, Validators.pattern(new RegExp(regexParams['domain']))])],
      provider_id: ['', Validators.required],
      user_id: [''],
      expiration_date: [''],
      is_fact_external: ['', Validators.required],
      renewal_days_period: ['', Validators.required],
      godaddy_id: [''],
      cloudflare_id: [''],
      quantity: [''],
    });
  }

  ngOnInit(): void {
  }

  save() {
    this.api.updateDomain(this.domain.id, this.formGroup.value).subscribe(
      data => {

      }
    );
  }

}
