<form [formGroup]="form">
  <div class="custom-form-field">
    <mat-icon>search</mat-icon>
    <input type="text" matInput formControlName="search" #searchInput (keydown)="onKeyDown($event)">
  </div>
  @if (suggestions.length>0) {
    <hr>
    }

    <div class="suggestions-container" #suggestionsContainer tabindex="-1" (keydown)="onKeyDown($event)">
      @for (suggestion of suggestions; track suggestion; let i = $index) {
        <div class="suggestion"
          [ngClass]="{ 'selected': currentIndex === i }">
          @if (suggestion.model=='project') {
            <div (click)="goToProject(suggestion)">
              <div class="project-infobox">
                <div class="color-square" [ngStyle]="{'background-color': suggestion.color ?? 'black'}"></div>
                <p class="code">PROYECTO</p>
                <p class="title">
                  {{ suggestion . title }}
                </p>
              </div>
            </div>
          }
          @if (suggestion.model=='wiki') {
            <div (click)="goToWiki(suggestion)">
              <div class="project-infobox">
                <mat-icon>import_contacts</mat-icon>
                <p class="code">WIKI</p>
                <p class="title">
                  {{ suggestion . wiki_article_version . title }}
                  <span>{{ ' > ' + suggestion.wiki_node.name }}</span>
                </p>
                @if (suggestion.project) {
                  <div class="wiki color-square" [ngStyle]="{'background-color': suggestion.project.color}">
                    {{suggestion.project.title}}
                  </div>
                }
              </div>
            </div>
          }
          @if (suggestion.model=='intranet') {
            <div (click)="goToIntranet(suggestion)">
              <div class="project-infobox">
                <mat-icon>{{ findSuggestionIcon(suggestion) }}</mat-icon>
                <p class="code">INTRANET</p>
                <p class="title">
                  {{ suggestion . name }}
                </p>
              </div>
            </div>
          }
          @if (suggestion.model=='task') {
            <div class="task-infobox" (click)="goToTask(suggestion)">
              <img [src]="suggestion.type | projectTaskTypeImage" />
              <p class="code">{{ suggestion . code }}</p>
              <p class="title">
                {{ suggestion . title }}
              </p>
              @if (suggestion.tags && suggestion.tags.length) {
                <div class="w-100 tag-list">
                  @for (tag of suggestion.tags.slice(0,2); track tag) {
                    <mat-chip class="preview-tag">
                      #{{ tag . tag . title }}
                    </mat-chip>
                  }
                </div>
              }
            </div>
          }
        </div>
      }
    </div>

  </form>
