import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-commercial-dashboard',
  templateUrl: './commercial-dashboard.component.html',
  styleUrls: ['./commercial-dashboard.component.css']
})
export class CommercialDashboardComponent implements OnInit {

  filterForm:UntypedFormGroup;
  private formPersistence:any;
  displayedColumns:string[] = [ "user", "hours", "first_contact", "first_meeting_scheduled", "proposal_to_be_made" ];

  dataSource:MatTableDataSource<ComercialKpiPeriod> = new MatTableDataSource();

  constructor(private api:ApiService,
              private fb:UntypedFormBuilder,
              private activatedRoute:ActivatedRoute,
              private utils:UtilsService,
              private router:Router) {
    this.filterForm = this.fb.group({
      from: [this.activatedRoute.snapshot.queryParamMap.get('from')!=null ? this.utils.stringToDate(this.activatedRoute.snapshot.queryParamMap.get('from') as string) : this.getInitOfWeekDate(), Validators.required],
      to: [this.activatedRoute.snapshot.queryParamMap.get('to')!=null ? this.utils.stringToDate(this.activatedRoute.snapshot.queryParamMap.get('to') as string) : this.getEndOfWeekDate(), Validators.required]
    });
  }

  ngOnInit(): void {
    this.initFilterFormListener();
    this.listenQueryParameters();
  }

  changeWeek(num:number) {
    const old_from:Date = this.filterForm.value['from'];
    const new_from:Date = new Date(new Date(old_from).setDate(old_from.getDate() + num*7));
    const new_to:Date = new Date(new Date(new_from).setDate(new_from.getDate() + 6));

    this.filterForm.patchValue({
      from: new_from,
      to: new_to
    });
  }

  getHoursRegisteredNgClass(item:ComercialKpiPeriod) {
    return {
      positive: item.hours.registered!=0 && item.hours.expected!=0 && item.hours.registered + 3 > item.hours.expected,
      negative: item.hours.registered!=0 && item.hours.expected!=0 && item.hours.registered + 3 < item.hours.expected
    };
  }

  private initFilterFormListener() {
    this.filterForm.valueChanges.subscribe(
      data => {
        if(this.formPersistence==null || JSON.stringify(this.formPersistence)!=JSON.stringify(data)) {
          const params = this.utils.cloneObj(data);
          if(params.from!=null && params.from!="") params.from = this.utils.dateToString(new Date(params.from));
          if(params.to!=null && params.to!="") params.to = this.utils.dateToString(new Date(params.to));
          this.router.navigate([], { queryParams: params });
        }
      }
    );
  }

  private listenQueryParameters() {
    this.activatedRoute.queryParams.subscribe(
      params => {
        if(JSON.stringify(params)!==JSON.stringify(this.filterForm.value)) { //si no ve de filtre s'ha de setejar el form
          let params_temp = this.utils.cloneObj(params); //params es read_only
          Object.keys(params_temp).forEach(param_key => {
            if(params_temp[param_key]!=null && params_temp[param_key]!="" && !isNaN(+params_temp[param_key])) params_temp[param_key] = +params_temp[param_key]; // si es numero, el transformem
            else if(params_temp[param_key]!=null && (params_temp[param_key] as string).indexOf("-")>-1) params_temp[param_key] = this.utils.stringToDate(params_temp[param_key]);
          });
          this.filterForm.patchValue(params_temp, { emitEvent: false });
        }
        this.formPersistence = params;
        this.fetchKpis();
      }
    );
  }

  private fetchKpis() {
    this.api.getPotentialClientsKpis(this.filterForm.value['from'], this.filterForm.value['to']).subscribe(
      (data:ComercialKpiPeriod[]) => {
        this.dataSource.data = data;
      }
    );
  }

  private getInitOfWeekDate(now?:Date) {
    const d = now!=null ? now : new Date();
    var day = d.getDay();
    var diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
    return new Date(d.setDate(diff));
  }

  private getEndOfWeekDate(now?:Date) {
    const d = now!=null ? now : new Date();
    var day = d.getDay();
    var diff = d.getDate() + 7 - day; // adjust when day is sunday
    return new Date(d.setDate(diff));
  }

}

interface ComercialKpiPeriod {
  commercial_id:number;
  commercial_name:string;
  commercial_surnames:string;
  average_period:{
    proposal_to_be_made:number;
    first_contact:number;
    first_meeting_scheduled:number;
  };
  current_period:{
    proposal_to_be_made:number;
    first_contact:number;
    first_meeting_scheduled:number;
  };
  prcnt_average_period_changed:{
    proposal_to_be_made:number;
    first_contact:number;
    first_meeting_scheduled:number;
  };
  hours:{
    expected:number;
    registered:number;
  };
}
