<h1 mat-dialog-title>Configurar calendario festivo</h1>
<mat-dialog-content>
  <table mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="working_contract_id">
      <th mat-header-cell *matHeaderCellDef>Contrato</th>
      <td mat-cell *matCellDef="let item">#{{ item.working_contract_id }}</td>
    </ng-container>

    <ng-container matColumnDef="year">
      <th mat-header-cell *matHeaderCellDef>Año</th>
      <td mat-cell *matCellDef="let item">{{ item.year }}</td>
    </ng-container>

    <ng-container matColumnDef="holidays_calendar_country_zone_id">
      <th mat-header-cell *matHeaderCellDef>Población</th>
      <td mat-cell *matCellDef="let item; let i = index;">
        @if(item.year < currentYear || (item.year == currentYear && item.holidays_calendar_country_zone_id != null)) {
          {{ item.holidays_calendar_country_zone.name }}
        }
        @else {
          <mat-form-field appearance="outline" class="nobottom">
            <input type="text" matInput [value]="item.holidays_calendar_country_zone.name" [formControl]="autocompleteSearchInputControls[i]" [matAutocomplete]="auto">
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="onAutocompleteClickCountryZone($event, i)">
              @for (cz of country_zones; track cz) {
                <mat-option [value]="cz">{{cz.name}}</mat-option>
              }
            </mat-autocomplete>
          </mat-form-field>
        }
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-flat-button color="accent" mat-dialog-close>Cerrar</button>
</mat-dialog-actions>
