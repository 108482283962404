import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Company } from 'src/app/models/company.model';
import { HetznerImage } from 'src/app/models/hetzner_image.model';
import { HetznerLocation } from 'src/app/models/hetzner_location.model';
import { HetznerServerType } from 'src/app/models/hetzner_server_type.model';
import { Hosting } from 'src/app/models/hosting.model';
import { Provider } from 'src/app/models/provider.model';
import { ApiService } from 'src/app/services/api.service';
import { RegexService } from 'src/app/services/regex.service';

@Component({
  selector: 'app-add-hosting-dialog',
  templateUrl: './add-hosting-dialog.component.html',
  styleUrls: ['./add-hosting-dialog.component.css']
})
export class AddHostingDialogComponent implements OnInit {

  hostingForm:UntypedFormGroup;
  propietarioControl:UntypedFormControl = new UntypedFormControl('');
  hostingControl:UntypedFormControl = new UntypedFormControl('');

  parent_hostings_filtered:Hosting[] = [];
  providers:Provider[] = [];
  hetzner_server_types:HetznerServerType[] = [];
  hetzner_locations:HetznerLocation[] = [];
  hetzner_images:HetznerImage[] = [];
  companies_filtered:Company[] = [];

  constructor(private fb:UntypedFormBuilder,
              public dialogRef: MatDialogRef<AddHostingDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data:any,
              private api:ApiService,
              private regex:RegexService) {

    const regexPatterns = this.regex.getRegex();

    this.hostingForm = this.fb.group({
      company_id: [''],
      hosting_fqdn: ['', Validators.compose([Validators.required, Validators.pattern(new RegExp(regexPatterns['fqdn']))])],
      parent_hosting_id: [''],
      ip: ['', Validators.pattern(new RegExp(regexPatterns['ipv4']))],
      provider_id: ['', Validators.required],
      expiration_date: [''],
      is_fact_external: [false, Validators.required],
      renewal_days_period: [365, Validators.compose([Validators.required, Validators.min(0)])],
      is_shared: [false, Validators.required],
      quantity: [''],
      hetzner_location: [''],
      hetzner_server_type: [''],
      hetzner_enable_backups: [true],
      hetzner_image: [''],
      trial_enabled: [true],
      valid: [false, Validators.requiredTrue],
      cores: ['', Validators.min(1)],
      memory: ['', Validators.min(1)],
      disk: ['', Validators.min(1)]
    });

    this.providers = this.data.providers;

  }

  ngOnInit(): void {
    this.listenForm();
    this.listenAutocompletes();
    this.fetchHetznerData();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  selectPropietario(company:Company) {
    this.hostingForm.patchValue({ company_id: company.id });
  }

  selectParentHosting(hosting:Hosting) {
    this.hostingForm.patchValue({
      parent_hosting_id: hosting.id,
      cores: hosting.cores,
      memory: hosting.memory,
      disk: hosting.disk
    });
  }

  isHetznerEnabled() {
    const provider_id = this.hostingForm.value['provider_id'];
    const parent_hosting_id = this.hostingForm.value['parent_hosting_id'];
    const is_fact_external = this.hostingForm.value['is_fact_external'];

    if(provider_id == null) return false;
    else {
      const provider = this.getProvider(provider_id);
      if(provider!=null && provider.slug=='hetzner' && (parent_hosting_id==null || parent_hosting_id=='') && !is_fact_external) return true;
      else return false;
    }
  }

  private listenForm() {
    this.hostingForm.valueChanges.subscribe(
      data => {
        // check valid attribute
        let valid:boolean = false;
        if(this.isHetznerEnabled()) {
          valid = data.hetzner_location!=null && data.hetzner_location!="" &&
                  data.hetzner_server_type!=null && data.hetzner_server_type!="" &&
                  data.hetzner_enable_backups!=null &&
                  data.hetzner_image!=null && data.hetzner_image!="" &&
                  data.trial_enabled!=null;
        }
        else {
          valid = data.ip!=null && data.ip!="" &&
                  data.cores!=null && data.cores!="" &&
                  data.memory!=null && data.memory!="" &&
                  data.disk!=null && data.disk!="";
        }

        this.hostingForm.patchValue({ valid: valid }, { emitEvent: false });
      }
    );
  }

  private fetchHetznerData() {
    this.api.getHetznerServerTypes().subscribe(
      data => {
        this.hetzner_server_types = data;
      }
    );

    this.api.getHetznerImages().subscribe(
      data => {
        this.hetzner_images = data;
      }
    );

    this.api.getHetznerLocations().subscribe(
      data => {
        this.hetzner_locations = data;
      }
    );
  }

  private listenAutocompletes() {
    this.propietarioControl.valueChanges.subscribe(
      data => {
        if(this.propietarioControl.valid) {
          this.api.getCompanies({search: data}).subscribe(
            companies => {
              this.companies_filtered = companies.data;
            }
          );
        }
        else {
          this.companies_filtered = [];
          this.hostingForm.patchValue({ company_id: '' }, { emitEvent: false });
        }
      }
    );

    this.hostingControl.valueChanges.subscribe(
      data => {
        if(this.hostingControl.valid) {
          this.api.getHostings({parent_hosting_id: "null", hosting_fqdn: data}).subscribe(
            hostings => {
              this.parent_hostings_filtered = hostings;
            }
          );
        }
        else {
          this.parent_hostings_filtered = [];
          this.hostingForm.patchValue({ parent_hosting_id: '' }, { emitEvent: false });
        }
      }
    );
  }

  private getProvider(provider_id:number):Provider {
    let res:Provider = null as any;
    for(let p of this.providers) {
      if(p.id == provider_id) {
        res = p;
        break;
      }
    }
    return res;
  }

}
