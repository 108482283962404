import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-add-company-notification-channel-dialog',
  templateUrl: './add-company-notification-channel-dialog.component.html',
  styleUrls: ['./add-company-notification-channel-dialog.component.css']
})
export class AddCompanyNotificationChannelDialogComponent implements OnInit {

  form:FormGroup

  get isCreate() {
    return this.data.company_notification_channel == null;
  }

  constructor(private fb:FormBuilder,
              public dialogRef: MatDialogRef<AddCompanyNotificationChannelDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data:any,
              private api:ApiService) {

    this.form = this.fb.group({
      type: ['', Validators.compose([Validators.required])],
      email: ['', Validators.compose([Validators.email])],
      slack_webhook_url: ['', Validators.compose([])],
      slack_channel_name: ['', Validators.compose([])],
      teams_webhook_url: ['', Validators.compose([])],
      teams_channel_name: ['', Validators.compose([])],
    });

  }

  ngOnInit(): void {
    this.listenTypeControl();
    if(!this.isCreate) {
      this.form.patchValue(this.data.company_notification_channel);
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  submit() {
    if(this.isCreate) {
      const body:any = this.form.value;
      body.company_id = this.data.company_id;
      this.api.createCompanyNotificationChannel(body).subscribe(
        data => {
          this.dialogRef.close(data);
        }
      );
    }
    else {
      const body:any = this.form.value;
      this.api.updateCompanyNotificationChannel(this.data.company_notification_channel.id, body).subscribe(
        data => {
          this.dialogRef.close(data);
        }
      );
    }
  }

  private listenTypeControl() {
    this.form.controls['type'].valueChanges.subscribe(
      value => {
        if(value === 'email') {
          this.form.controls['email'].addValidators(Validators.required);
          this.form.controls['slack_webhook_url'].removeValidators(Validators.required);
          this.form.controls['slack_webhook_url'].patchValue('');
          this.form.controls['slack_channel_name'].removeValidators(Validators.required);
          this.form.controls['slack_channel_name'].patchValue('');
          this.form.controls['teams_webhook_url'].removeValidators(Validators.required);
          this.form.controls['teams_webhook_url'].patchValue('');
          this.form.controls['teams_channel_name'].removeValidators(Validators.required);
          this.form.controls['teams_channel_name'].patchValue('');
        }
        else if(value === 'slack') {
          this.form.controls['slack_webhook_url'].addValidators(Validators.required);
          this.form.controls['slack_channel_name'].addValidators(Validators.required);
          this.form.controls['email'].removeValidators(Validators.required);
          this.form.controls['email'].patchValue('');
          this.form.controls['teams_webhook_url'].removeValidators(Validators.required);
          this.form.controls['teams_webhook_url'].patchValue('');
          this.form.controls['teams_channel_name'].removeValidators(Validators.required);
          this.form.controls['teams_channel_name'].patchValue('');
        }
        else if(value === 'teams') {
          this.form.controls['teams_webhook_url'].addValidators(Validators.required);
          this.form.controls['teams_channel_name'].addValidators(Validators.required);
          this.form.controls['email'].removeValidators(Validators.required);
          this.form.controls['email'].patchValue('');
          this.form.controls['slack_webhook_url'].removeValidators(Validators.required);
          this.form.controls['slack_webhook_url'].patchValue('');
          this.form.controls['slack_channel_name'].removeValidators(Validators.required);
          this.form.controls['slack_channel_name'].patchValue('');
        }
      }
    );
  }

}
