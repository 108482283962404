<h1 mat-dialog-title>Añadir nuevo dominio</h1>
<mat-dialog-content [formGroup]="domainForm">
  <div class="container">
    <div class="row">
      <div class="col-12 col-lg-4">
        <mat-form-field appearance="outline">
          <mat-label>Dominio*</mat-label>
          <input matInput formControlName="domain">
          @if (domainForm.controls['domain'].invalid) {
            <mat-error>No es correcto el dominio</mat-error>
          }
          <mat-hint align="end">Mensajes extra</mat-hint>
        </mat-form-field>
      </div>
      <div class="col-12 col-lg-4">
        <mat-form-field appearance="outline">
          <mat-label>Proveedor*</mat-label>
          <mat-select formControlName="provider_id">
            @for (p of providers; track p) {
              <mat-option [value]="p.id">{{p.name}}</mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-12 col-lg-4">
        <mat-form-field appearance="outline">
          <mat-label>Propietario</mat-label>
          <input type="text" placeholder="Escribe un nombre" matInput
            [formControl]="propietarioControl"
            [matAutocomplete]="auto">
          <mat-autocomplete #auto="matAutocomplete">
            @for (company of companies_filtered; track company) {
              <mat-option [value]="company.fact_name" (click)="selectPropietario(company)">{{ company.fact_name }}</mat-option>
            }
          </mat-autocomplete>
          <mat-hint>Dejar en blanco si es de Bisual</mat-hint>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-lg-4">
        <mat-form-field appearance="outline">
          <mat-label>Período de renovación (años)*</mat-label>
          <input matInput formControlName="period_years">
        </mat-form-field>
      </div>
      <div class="col-12 col-lg-4">
        <mat-form-field appearance="outline">
          <mat-label>Precio (€)*</mat-label>
          <input matInput type="text" formControlName="quantity">
          <span matSuffix>€</span>
          <mat-hint>Precio que se le cobra al cliente</mat-hint>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-lg-4">
        <mat-checkbox formControlName="is_fact_external">Facturado externamente</mat-checkbox>
      </div>
      @if (domainForm.value['company_id']!=null && domainForm.value['company_id']!='') {
        <div class="col-12 col-lg-8">
          <mat-checkbox formControlName="trial_enabled">Empezar con período de prueba</mat-checkbox>
          <span style="display: block; font-size: 12px;">(Útil cuando el 1r pago ha sido pagado con un proyecto)</span>
        </div>
      }
    </div>
  </div>
  @if (isGodaddyEnabled()) {
    <div class="container">
      <h2 class="dialog-section">Godaddy</h2>
      <div class="row">
        <div class="col-12 col-lg-5">
          <ul>
            <li><b>Disponibilidad:</b> {{ availability==null ? 'Comprobando...' : (availability.available ? 'Sí' : 'No') }}</li>
            @if (availability!=null && availability.available) {
              <li>
                <b>Precio total:</b>
                {{  availability==null ? 'Comprobando...' : (
                ((availability.price/1000000) | currency:'USD') + '/' + availability.period + ' ' + (availability.period==1 ? 'año' : 'años') + ' (tasas y comisiones excluidas)'
                )
                }}
              </li>
            }
          </ul>
        </div>
        @if (agreement!=null && availability!=null && availability.available) {
          <div class="col-12 col-lg-7">
            <div id="agreement" [innerHTML]="agreement.content"></div>
            <mat-checkbox formControlName="godaddyTerms" style="display: block; margin-top: 20px;">He leído y acepto los <a [href]="agreement.url" target="_blank">términos y condiciones</a></mat-checkbox>
          </div>
        }
      </div>
    </div>
  }
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button (click)="onNoClick()" cdkFocusInitial type="button">Cancelar</button>
  <button mat-stroked-button color="accent" [mat-dialog-close]="domainForm.value" [disabled]="!domainForm.valid || loadingStack.length>0" type="submit">
    <mat-icon matPrefix>add</mat-icon>
    {{
    !isGodaddyEnabled() || !domainForm.valid ?
    'Crear dominio' :
    'Contratar ' + domainForm.value['domain'] + ' por ' + domainForm.value['period_years'] + ' ' + (domainForm.value['period_years']==1 ? 'año' : 'años')
    }}
  </button>
</mat-dialog-actions>
