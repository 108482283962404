<div id="content">
  <div class="page-title">
    <h1>Wiki</h1>
  </div>

  <div class="row g-2">
    @for (element of wikiTree; track element) {
      <div class="col col-sm-4 col-md-6">
        <div class="card folder" [routerLink]="['/employees','wiki', element.id]">
          <mat-icon>{{ element.icon!=null ? element.icon : 'folder' }}</mat-icon>
          <mat-icon class="icon-background">{{ element.icon!=null ? element.icon : 'folder' }}</mat-icon>
          <div class="title">{{element.name}}</div>
        </div>
      </div>
    }
  </div>
</div>
