import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ProviderOrderAlert } from '../models/alert.model';
import { ArrayResponse } from '../models/array_response.interface';
import { BusinessMail } from '../models/business_mail.model';
import { ClockRegisterReviewRequest } from '../models/clock-register-review-request.model';
import { ClockRegister } from '../models/clock-register.model';
import { CloudflareZoneDns } from '../models/cloudflare_zone_dns.model';
import { Company } from '../models/company.model';
import { ContasimpleAttachment } from '../models/contasimple_attachment.model';
import { Credential } from '../models/credential.model';
import { Department } from '../models/department.model';
import { DepartmentCategory } from '../models/department_category.model';
import { Domain } from '../models/domain.model';
import { EmployeeWorkingSheet } from '../models/employee-working-sheet.model';
import { EmployeeMonthWorkingCapacity } from '../models/employee_month_working_capacity.model';
import { EmployeeCalendarRegister, EmployeeCalendarRegisterWithRelationsSerialized } from '../models/employee_calendar_register.model';
import { HetznerImage } from '../models/hetzner_image.model';
import { HetznerLocation } from '../models/hetzner_location.model';
import { HetznerServerType } from '../models/hetzner_server_type.model';
import { Hosting } from '../models/hosting.model';
import { Order } from '../models/order.model';
import { OvhCart } from '../models/ovh_cart.model';
import { OvhExchangeServer } from '../models/ovh_exchange_server.model';
import { OvhExchangeServerAccount } from '../models/ovh_exchange_server_account.model';
import { OvhGenericProduct } from '../models/ovh_generic_product.model';
import { OvhGenericProductOption } from '../models/ovh_generic_product_option.model';
import { PaymentType } from '../models/payment_type.model';
import { Project } from '../models/project.model';
import { ProjectPhaseStage } from '../models/project_phase_stage.model';
import { ProjectService } from '../models/project_service.model';
import { Provider } from '../models/provider.model';
import { ProviderOrder } from '../models/provider_order.model';
import { ProviderOrderCategory } from '../models/provider_order_category.model';
import { Role } from '../models/role.model';
import { Subscription } from '../models/subscription.model';
import { User } from '../models/user.model';
import { VacationRequest } from '../models/vacation_request';
import { VacationRequestPeriod } from '../models/vacation_request_period';
import { WorkingTimeSummary } from '../models/working-time-summary.model';
import { WorkingContract } from '../models/working_contract.model';
import { WorkingContractType } from '../models/working_contract_type.model';
import { WorkingTime } from '../models/working_time.model';
import { WorkingTimeCategory } from '../models/working_time_category.model';
import { UtilsService } from './utils.service';
import { ProjectPhase } from '../models/project_phase.model';
import { DevelopmentRoadmapResponse } from '../models/development_roadmap.model';
import { DevelopmentAvailability } from '../models/development_availability.model';
import * as moment from 'moment';
import { WeeklyWorkingTimeBudget, WeeklyWorkingTimeBudgetInfo } from '../models/weekly_working_time_budget.model';
import { WeeklyWorkingTimeBudgetEmployee, WeeklyWorkingTimeBudgetEmployeeSerialized } from '../models/weekly_working_time_budget_employee.model';
import { ProjectPhaseBoard } from '../models/project_phase_board.model';
import { ProjectPhaseTasks } from '../models/project_phase_tasks.model';
import { ProjectTask } from '../models/project_task.model';
import { ProjectPhaseBoardCol } from '../models/project_phase_board_col.model';
import { ProjectTaskComment } from '../models/project_task_comment.model';
import { ProjectTaskTagCategory } from '../models/project_task_tag_category.model';
import { ProjectTaskTag } from '../models/project_task_tag.model';
import { ClockRegisterMood } from '../models/clock-register-mood.model';
import { Holiday } from '../models/holiday.model';
import { BillingPlannedRow } from '../models/billing-planned-row.model';
import { PlannedExpense } from '../models/planned-expense.model';
import { PlannedIncome } from '../models/planned-income.model';
import { PlannedExpenseCategory } from '../models/planned-expense-category.model';
import { PotentialClient, PotentialClientComment, PotentialClientStatus, PotentialClientStatusChangeRecord } from '../models/potential-client.interface';
import { BisualSetting } from '../models/bisual-setting.model';
import { WikiTree } from '../models/wiki-tree.model';
import { WikiNode } from '../models/wiki-node.model';
import { WikiArticle, WikiArticleVersion } from '../models/wiki-article.model';
import { MeetingRecord } from '../models/meeting-record.interface';
import { ProjectPhaseBoardColRole } from '../models/project_phase_board_col_role.model';
import { Application } from '../models/application.model';
import { ApplicationType } from '../models/application_type.model';
import { ProgrammingTag } from '../models/programming_tag.model';
import { ApplicationEnvironment } from '../models/application_environment.model';
import { ProjectUserMember } from '../models/project_user_member.model';
import { WeeklyWorkingTimeBudgetDistributionDistributable } from '../models/weekly_working_time_budget_distribution_distributable.model';
import { ProjectDefaultTimeDistribution } from '../models/project_default_time_distribution.model';
import { Contract } from '../models/contract.model';
import { ProjectTaskMember } from '../models/project_task_member.model';
import { CountryZone } from '../models/country_zone.model';
import { WorkingContractYearCondition } from '../models/working_contract_year_condition.model';
import { Environment } from '../models/environment.model';
import { CompanyNotificationChannel } from '../models/company_notification_channel.model';
import { MyProjectRoles } from '../models/my_project_roles.model';
import { ProjectDeployWeeklyNotificationConfiguration } from '../models/project_deploy_weekly_notification_configuration.model';
import { GuardianScheduledWeek } from '../models/guardian_scheduled_week.model';
import { ProjectTeam } from '../models/project_team.model';
import { ProjectTaskBoard } from '../models/project_task_board.model';
import { ProjectTaskBoardTimeBudget } from '../models/project_task_board_time_budget.model';
import { ProjectDefaultTimeDistributionDistributable } from '../models/project_default_time_distribution_distributable.model';
import { ProjectTaskDistribution } from '../components/main/shared/projects/project-task-detail/project-task-detail.component';
import { Birthday } from '../models/birthday.model';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  private host:string = environment.laravel_url + "/api/v1";

  constructor(private http:HttpClient,
              private utils:UtilsService) { }

  public getHost() {
    return this.host;
  }

  private setParams(filters:any, params:HttpParams = new HttpParams()) {
    if(filters==null) filters = {};
    Object.entries(filters).forEach((val:[string,any]) => {
      if(val[1]!=undefined && val[1]!=null) {
        if(val[1] instanceof Date /*|| isMoment(val[1])*/) val[1] = val[1].toISOString();
        else if(typeof val[1] === "boolean") val[1] = val[1] ? 1 : 0;
        params = params.set(val[0], val[1]);
      }
    });

    return params;
  }

  /**
   * -------------------------------------------------- AUTHENTICATION --------------------------------------------------
   */
  refreshToken() {
    return this.http.post<{access_token: string; user:User}>(`${this.host}/auth/refresh`, {});
  }

  login(email:string, password:string) {
    return this.http.post<{access_token: string; user:User}>(`${this.host}/auth/login`, { email: email, password: password });
  }

  resetPassword(body:any) {
    return this.http.post<any>(`${this.host}/auth/reset-password`, body);
  }

  /**
   * -------------------------------------------------- ME --------------------------------------------------
   */
  me() {
    return this.http.get<User>(`${this.host}/me`);
  }

  myClockRegisterStatus() {
    return this.http.get<any>(`${this.host}/me/clock-register-status`);
  }

  clickClockRegister(data:any) {
    let queryParams = new HttpParams();
    if(data.justification!=null || data.clock_register_mood_id!=null) {
      delete data.force;
      queryParams = queryParams.append('force', '1');
    }

    return this.http.post<any>(`${this.host}/me/click`, data, { params: queryParams });
  }

  clickClockRestRegister() {
    return this.http.post<any>(`${this.host}/me/click/rest`, {});
  }

  getMyAbsences(from:Date, to:Date) {
    return this.http.get<{
      vacation_requests:VacationRequest[];
      holidays:Holiday[];
      employee_calendar_registers:EmployeeCalendarRegister[]
    }>(`${this.host}/me/absences`, { params: { from: this.utils.dateToStringYYYYMMDD(from), to: this.utils.dateToStringYYYYMMDD(to) } }).pipe(
      map(absences => {
        absences.vacation_requests = absences.vacation_requests.map(vr => {
          vr.vacation_request_periods = vr.vacation_request_periods.map(vrp => {
            vrp.day = new Date(vrp.day);
            return vrp;
          });
          return vr;
        });
        absences.holidays = absences.holidays.map(h => {
          h.date = new Date(h.date);
          return h;
        });
        absences.employee_calendar_registers = absences.employee_calendar_registers.map(ecr => {
          ecr.from_date = new Date(ecr.from_date);
          ecr.to_date = new Date(ecr.to_date);
          return ecr;
        });
        return absences;
      })
    );
  }

  getMyProjectRoles(user_id:number) {
    return this.http.get<MyProjectRoles>(`${this.host}/me/project-roles`);
  }

  getMyGuardianStatus() {
    return this.http.get<{active:boolean; project_names:string|undefined}>(`${this.host}/me/guardian-status`);
  }

  getMyProjectTeams() {
    return this.http.get<ProjectTeam[]>(`${this.host}/me/project-teams`);
  }

  getMyActionsRequired() {
    return this.http.get<{[key:string]:boolean}>(`${this.host}/me/actions-required`);
  }


  /**
   * -------------------------------------------------- BLOGPOSTS --------------------------------------------------
   */
  getAllBlogposts() {
    return this.http.get<any>(`${this.host}/blogposts`);
  }

  /**
   * -------------------------------------------------- WORKING CONTRACTS TYPES --------------------------------------------------
   */

  getWorkingContractsTypes(params:any = null) {
    const http_params:HttpParams = this.setParams(params);
    return this.http.get<WorkingContractType[]>(`${this.host}/working-contracts-types`, { params: http_params });
  }

  /**
   * -------------------------------------------------- WORKING CONTRACTS --------------------------------------------------
   */
  getWorkingContractsPaged(params:any = null) {
    const http_params:HttpParams = this.setParams(params);
    return this.http.get<ArrayResponse<WorkingContract>>(`${this.host}/working-contracts`, { params: http_params });
  }

  getWorkingContract(id: number, params:any = {}) {
    return this.http.get<WorkingContract>(`${this.host}/working-contracts/${id}`, { params: params });
  }

  updateWorkingContract(id:string, body:any) {
    return this.http.put<WorkingContract>(`${this.host}/working-contracts/${id}`, body);
  }

  createWorkingContract(body:WorkingContract) {
    return this.http.post<WorkingContract>(`${this.host}/working-contracts`, body);
  }

  /**
   * -------------------------------------------------- WORKING CONTRACT YEAR CONDITION --------------------------------------------------
   */
  updateWorkingContractYearCondition(id:number, body:any) {
    return this.http.put<WorkingContractYearCondition>(`${this.host}/working-contract-year-conditions/${id}`, body);
  }

  /**
   * -------------------------------------------------- USERS --------------------------------------------------
   */
  getUsers(params:any = null) {
    const http_params:HttpParams = this.setParams(params);
    return this.http.get<ArrayResponse<User>>(`${this.host}/users`, { params: http_params });
  }

  getUsersPaged(params:any = null) {
    const http_params:HttpParams = this.setParams(params);
    return this.http.get<ArrayResponse<User>>(`${this.host}/users`, { params: http_params });
  }

  getUser(id:string, params:any = {}) {
    return this.http.get<User>(`${this.host}/users/${id}`, { params: params });
  }

  getContractUser(id: string, params:any = {}) {
    return this.http.get<WorkingContract>(`${this.host}/users/${id}/current-working-contract`, { params: params });
  }

  createUser(body:User) {
    return this.http.post<User>(`${this.host}/users`, body);
  }

  updateUser(id:string, body:any) {
    return this.http.put<User>(`${this.host}/users/${id}`, body);
  }

  deleteUser(id:number) {
    return this.http.delete<User>(`${this.host}/users/${id}`);
  }

  changeUserPassword(id:string, password:string) {
    return this.http.put<User>(`${this.host}/users/${id}/change-password`, { password: password });
  }

  getUserWorkingTimeCategories(id:string, params:any = null) {
    return this.http.get<WorkingTimeCategory[]>(`${this.host}/users/${id}/working-time-categories`, { params: params });
  }

  getUserAssignableWorkingTimeCategories(id:string, params:any = null) {
    return this.http.get<WorkingTimeCategory[]>(`${this.host}/users/${id}/working-time-assignable-categories`, { params: params });
  }

  getTaskWorkingTimeCategories() {
    return this.http.get<WorkingTimeCategory[]>(`${this.host}/working-time-categories/task-time`);
  }

  getAllWorkingTimeCategories(id:string, params:any = null) {
    return this.http.get<WorkingTimeCategory[]>(`${this.host}/working-time-categories`, { params: params });
  }

  getWorkingTimeCategoryChildren(id:string, params:any = null) {
    return this.http.get<WorkingTimeCategory[]>(`${this.host}/working-time-categories/${id}/children`, { params: params });
  }

  getEmployeesPlanningByYear(year:number) {
    return this.http.get<User[]>(`${this.host}/users/employees/planning/${year}`).pipe(
      map((users:User[]) => {
        return users.map(u => {
          u.working_contracts = u.working_contracts.map(wc => {
            wc.start_date = new Date(wc.start_date);
            if(wc.end_date!=null) wc.end_date = new Date(wc.end_date);
            return wc;
          })
          return u;
        });
      })
    );
  }

  getEmployeesDevelopmentRoadmap(from:Date, to:Date) {
    let params:HttpParams = new HttpParams();
    params = params.set('from', this.utils.dateToString(from));
    params = params.set('to', this.utils.dateToString(to));
    return this.http.get<DevelopmentRoadmapResponse>(`${this.host}/users/employees/development/roadmap`, { params: params }).pipe(
      map((response:DevelopmentRoadmapResponse) => {
        response.availability = response.availability.map(
          (item:DevelopmentAvailability) => {
            item.from = moment(item.from);
            item.to = moment(item.to);
            return item;
          }
        );
        return response;
      })
    );
  }

  /**
   *
   * @param params
   * - contract_id: number|null
   * - current_week: string (YYYY-MM-DD)
   * - propagate_project_distribution: boolean
   * - propagate_assignation: boolean
   * - to_date: string (YYYY-MM-DD) | null
   * @returns
   */
  copyRoadmapToYear(params:any) {
    return this.http.post(`${this.host}/users/employees/development/roadmap/copy-to-year`, params)
  }

  getDevelopmentMonthlyTimeOccupiedByYear(year:number) {
    return this.http.get<number[]>(`${this.host}/users/employees/development/${year}/monthly-time-occupied`);
  }

  getEmployeesProjectAssignment(from:Date, to:Date) {
    let params:HttpParams = new HttpParams();
    const from_string = this.utils.dateToStringYYYYMMDD(from);
    const to_string = this.utils.dateToStringYYYYMMDD(to);

    return this.http.get<any>(`${this.host}/users/employees/project-assignment/${from_string}/${to_string}`)
  }

  getActiveEmployees(params:any = null) {
    const http_params:HttpParams = this.setParams(params);
    return this.http.get<User[]>(`${this.host}/users/employees/active`, { params: http_params });
  }

  getVacations(from:Date, to:Date) {
    let params:HttpParams = new HttpParams();
    params = params.set('from', this.utils.dateToStringYYYYMMDD(from));
    params = params.set('to', this.utils.dateToStringYYYYMMDD(to));

    return this.http.get<EmployeeCalendarRegister[]>(`${this.host}/users/employees/planning/vacations`, { params: params}).pipe(
      map(items => {
        items = items.map(item => {
          item.from_date = new Date(item.from_date);
          item.to_date = new Date(item.to_date);
          return item;
        })
        return items;
      })
    );
  }

  getHolidaysFromActiveWorkingContracts(from:Date, to:Date) {
    let params:HttpParams = new HttpParams();
    params = params.set('from', this.utils.dateToStringYYYYMMDD(from));
    params = params.set('to', this.utils.dateToStringYYYYMMDD(to));

    return this.http.get<Holiday[]>(`${this.host}/users/employees/planning/holidays`, { params: params}).pipe(
      map(items => {
        items = items.map(item => {
          item.date = new Date(item.date);
          return item;
        })
        return items;
      })
    );
  }

  getBirthdays(from:Date, to:Date) {
    let params:HttpParams = new HttpParams();
    params = params.set('from', this.utils.dateToStringYYYYMMDD(from));
    params = params.set('to', this.utils.dateToStringYYYYMMDD(to));

    return this.http.get<Birthday[]>(`${this.host}/users/employees/birthdays`, { params: params });
  }

  getUsersTodayStatus() {
    return this.http.get<any[]>(`${this.host}/users/employees/today-status`);
  }

  getEmployeeAbsences(user_id:number, from:Date, to:Date) {
    let params:HttpParams = new HttpParams();
    params = params.set('from', this.utils.dateToStringYYYYMMDD(from));
    params = params.set('to', this.utils.dateToStringYYYYMMDD(to));

    return this.http.get<{
      holidays:Holiday[];
      vacation_requests:VacationRequest[];
      employee_calendar_registers:EmployeeCalendarRegister[];
    }>(`${this.host}/users/${user_id}/absences`, { params: params });
  }

  addProjectToFavorite(user_id: number, project_id: number) {
    return this.http.post(`${this.host}/users/${user_id}/projects/${project_id}/favorite`, {});
  }

  removeProjectFromFavorite(user_id: number, project_id: number) {
    return this.http.delete(`${this.host}/users/${user_id}/projects/${project_id}/favorite`);
  }

  getTimeWithoutRegister(user_id: number) {
    return this.http.get<any>(`${this.host}/users/${user_id}/time-without-register`);
  }

  getEmployeesWorkingContractYearConditions(year:string) {
    return this.http.get<{user:User; working_contract_year_condition: WorkingContractYearCondition|undefined}[]>(`${this.host}/users/employees/working-contract-year-conditions`, { params: { year: year } });
  }

  getUserWorkingContractYearConditions(user_id:number, params:any = {}) {
    return this.http.get<WorkingContractYearCondition[]>(`${this.host}/users/${user_id}/working-contract-year-conditions`, { params: params });
  }

  /**
   * -------------------------------------------------- EMPLOYEE MONTH WORKING CAPACITY --------------------------------------------------
   */
  saveBulkEmployeeMonthWorkingCapacity(items:any[]) {
    return this.http.post<EmployeeMonthWorkingCapacity[]>(`${this.host}/employee-month-working-capacities/bulk`, items);
  }

  recalcPlanningMonthByEmwcId(id:number, productivityPrcnt:number, nonProductiveHours:number, seguridadSocialPrcnt:number, horasChicle:number) {
    return this.http.post<EmployeeMonthWorkingCapacity>(`${this.host}/employee-month-working-capacities/${id}/recalc-month`, {
      'productivity_prcnt': productivityPrcnt,
      'non_productive_hours': nonProductiveHours,
      'seguridad_social_prcnt': seguridadSocialPrcnt,
      'chicle_hours': horasChicle
    });
  }

  copyEmployeeMonthWorkingCapacityFromLastMonth(month:number, year:number) {
    return this.http.post<EmployeeMonthWorkingCapacity[]>(`${this.host}/employee-month-working-capacities/copy-last-month/${month}/${year}`, {});
  }

  /**
   * -------------------------------------------------- ROLES --------------------------------------------------
   */
  getRoles() {
    return this.http.get<Role[]>(`${this.host}/roles`);
  }

  /**
   * -------------------------------------------------- DEPARTMENT CATEGORIES --------------------------------------------------
   */
     getDepartmentCategories() {
      return this.http.get<DepartmentCategory[]>(`${this.host}/department-categories`);
    }

    getDepartmentCategory(id:string) {
      return this.http.get<DepartmentCategory>(`${this.host}/department-categories/${id}`);
    }

    getDepartmentCategoriesPaged(params:any = null) {
      const http_params:HttpParams = this.setParams(params);
      return this.http.get<ArrayResponse<DepartmentCategory>>(`${this.host}/department-categories`, { params: http_params });
    }

    createDepartmentCategory(body:DepartmentCategory) {
      return this.http.post<DepartmentCategory>(`${this.host}/department-categories`, body);
    }

    updateDepartmentCategory(id:string, body:any) {
      return this.http.put<DepartmentCategory>(`${this.host}/department-categories/${id}`, body);
    }

    deleteDepartmentCategory(id: number){
      return this.http.delete<DepartmentCategory>(`${this.host}/department-categories/${id}`);
    }

  /**
   * -------------------------------------------------- DEPARTMENTS --------------------------------------------------
  */

  getDepartments() {
    return this.http.get<ArrayResponse<Department>>(`${this.host}/departments`);
  }

  getDepartment(id:string) {
    return this.http.get<Department>(`${this.host}/departments/${id}`);
  }


  getDepartmentsPaged(params:any = null) {
    const http_params:HttpParams = this.setParams(params);
    return this.http.get<ArrayResponse<Department>>(`${this.host}/departments`, { params: http_params });
  }

  deleteDepartment(id: number){
    return this.http.delete<Department>(`${this.host}/departments/${id}`);
  }

  updateDepartment(id:string, body:any) {
    return this.http.put<Department>(`${this.host}/departments/${id}`, body);
  }


  createDepartment(body:Department) {
    return this.http.post<Department>(`${this.host}/departments`, body);
  }
  /**
   * -------------------------------------------------- PROJECTS --------------------------------------------------
   */
  getProjectsPaged(params:any = null) {
    const http_params:HttpParams = this.setParams(params);
    return this.http.get<ArrayResponse<Project>>(`${this.host}/projects`, { params: http_params });
  }

  getProjects(params:any = null) {
    const http_params:HttpParams = this.setParams(params);
    return this.http.get<ArrayResponse<Project>>(`${this.host}/projects`, { params: http_params });
  }

  getProject(id:string, query:any = {}) {
    return this.http.get<Project>(`${this.host}/projects/${id}`, { params: query });
  }

  getProjectWorkingTimeChartData(project_id:string, params:any) {
    const http_params:HttpParams = this.setParams(params);
    return this.http.get<any>(`${this.host}/projects/${project_id}/working-times/chart-data`, { params: http_params });
  }

  getUserProjects(user_id:any, params:any = null) {
    const http_params:HttpParams = this.setParams(params);
    return this.http.get<ArrayResponse<Project>>(`${this.host}/me/projects`, { params: http_params });
  }

  getUserProjectsActiveRoles(user_id:any, params:any = null) {
    const http_params:HttpParams = this.setParams(params);
    return this.http.get<any>(`${this.host}/projects/${user_id}/active-roles`, { params: http_params });
  }

  getProjectMembers(project_id:string) {
    return this.http.get<ProjectUserMember[]>(`${this.host}/projects/${project_id}/users`);
  }

  getProjectActiveProductionTeam(project_id:string) {
    return this.http.get<{ bisual_users: ProjectUserMember[]; company_users: User[] }>(`${this.host}/projects/${project_id}/users/production-team`);
  }

  getGroupedMembers(project_id:string) {
    return this.http.get<any>(`${this.host}/projects/${project_id}/users/grouped`);
  }

  getActiveProjectMembers(project_id:string, params:any = null) {
    const http_params:HttpParams = this.setParams(params);
    return this.http.get<ProjectUserMember[]>(`${this.host}/projects/${project_id}/users/active`, { params: http_params });
  }

  getActiveProjectMembersWithBusiness(project_id:string) {
    return this.http.get<{bisual_users: ProjectUserMember[]; company_users: User[]}>(`${this.host}/projects/${project_id}/users/active-with-business`,);
  }

  addProjectMember(project_id:string, user_id:string) {
    return this.http.post<Project>(`${this.host}/projects/${project_id}/users/${user_id}/member`, {});
  }

  deleteProjectUserMember(user_member_id:string) {
    return this.http.delete<ProjectUserMember>(`${this.host}/project-user-member/${user_member_id}`);
  }

  removeProjectMember(project_id:string, user_id:string) {
    return this.http.delete<Project>(`${this.host}/projects/${project_id}/users/${user_id}/member`);
  }

  getProjectApplications(project_id:string) {
    return this.http.get<Application[]>(`${this.host}/projects/${project_id}/applications`);
  }

  getProjectApplicationEnvironments(project_id:string) {
    return this.http.get<ApplicationEnvironment[]>(`${this.host}/projects/${project_id}/application-environments`);
  }

  updateProjectUserMember(user_member_id:string, body:any) {
    return this.http.put<ProjectUserMember>(`${this.host}/project-user-member/${user_member_id}`, body);
  }

  getProjectUserMember(project_id:string, user_id:string) {
    return this.http.get<ProjectUserMember>(`${this.host}/projects/${project_id}/users/${user_id}/member`);
  }

  getProjectCurrentDefaultTimeDistribution(project_id:string) {
    return this.http.get<ProjectDefaultTimeDistribution>(`${this.host}/projects/${project_id}/project-default-time-distributions/current`);
  }

  getCurrentDefaultTimeDistributionTaskTimeForProjectPhase(project_id:number, project_phase_id:number) {
    return this.http.get<ProjectDefaultTimeDistributionDistributable[]>(`${this.host}/projects/${project_id}/project-default-time-distributions/${project_phase_id}/current/task-time`);
  }

  updateProjectDefaultDistributionTime(project_id:string, body:any) {
    return this.http.post<ProjectDefaultTimeDistribution>(`${this.host}/projects/${project_id}/project-default-time-distributions`, body);
  }

  getProjectVacations(project_id: string, from: Date, to: Date) {
    let params:HttpParams = new HttpParams();
    params = params.set('from', this.utils.dateToStringYYYYMMDD(from));
    params = params.set('to', this.utils.dateToStringYYYYMMDD(to));

    return this.http.get<EmployeeCalendarRegister[]>(`${this.host}/projects/${project_id}/vacations`, { params: params }).pipe(
      map(items => {
        items = items.map(item => {
          item.from_date = new Date(item.from_date);
          item.to_date = new Date(item.to_date);
          return item;
        })
        return items;
      })
    );
  }

  getProjectHolidays(project_id: string, from: Date, to: Date) {
    let params:HttpParams = new HttpParams();
    params = params.set('from', this.utils.dateToStringYYYYMMDD(from));
    params = params.set('to', this.utils.dateToStringYYYYMMDD(to));

    return this.http.get<Holiday[]>(`${this.host}/projects/${project_id}/holidays`, { params: params }).pipe(
      map(items => {
        items = items.map(item => {
          item.date = new Date(item.date);
          return item;
        })
        return items;
      })
    );
  }

  getProjectGuardianContracts(project_id:string, from: Date, to: Date) {
    let params:HttpParams = new HttpParams();
    params = params.set('from', this.utils.dateToStringYYYYMMDD(from));
    params = params.set('to', this.utils.dateToStringYYYYMMDD(to));
    params = params.set('type', "guardian");
    return this.http.get<Contract[]>(`${this.host}/projects/${project_id}/contracts`, { params: params }).pipe(
      map((contracts:Contract[], index:number) => {
        for(let contract of contracts) {
          contract.starts_at = new Date(contract.starts_at)
          contract.ends_at = new Date(contract.ends_at)
        }
        return contracts;
      })
    );
  }

  assignProjectGuardianScheduledWeek(project_id:string, user_id:string, dateYYYYMMDD:string) {
    return this.http.post<GuardianScheduledWeek>(`${this.host}/projects/${project_id}/guardian-scheduled-weeks`, { user_id: user_id, date: dateYYYYMMDD });
  }

  getProjectProjectTeams(project_id:string, params:any = {}) {
    return this.http.get<ProjectTeam[]>(`${this.host}/projects/${project_id}/project-teams`, { params: params });
  }

  /**
   * -------------------------------------------------- GUARDIAN SCHEDULED WEEKS --------------------------------------------------
   */
  getGuardianScheduledWeeks(body:any) {
    return this.http.get<GuardianScheduledWeek[]>(`${this.host}/guardian-scheduled-weeks`, { params: body }).pipe(
      map((gsws:GuardianScheduledWeek[]) => {
        gsws.forEach(gsw => {
          gsw.start_week = new Date(gsw.start_week);
          gsw.to_week = new Date(gsw.to_week);
        });
        return gsws;
      })
    );
  }

  deleteGuardianScheduledWeek(id:string) {
    return this.http.delete<GuardianScheduledWeek>(`${this.host}/guardian-scheduled-weeks/${id}`);
  }

  /**
   * -------------------------------------------------- PROJECT SERVICE --------------------------------------------------
   */
  getProjectServices(params:any = null) {
    const http_params:HttpParams = this.setParams(params);
    return this.http.get<ProjectService[]>(`${this.host}/project-services`, { params: http_params });
  }

  /**
   * -------------------------------------------------- SUBSCRIPTIONS --------------------------------------------------
   */

   getSubscriptions(params:any = null) {
    const http_params:HttpParams = this.setParams(params);
    return this.http.get<Subscription[]>(`${this.host}/subscriptions`, { params: http_params });
  }

  /**
   * -------------------------------------------------- PROJECT PHASE STAGE --------------------------------------------------
   */
  getProjectPhaseStages() {
    return this.http.get<ProjectPhaseStage[]>(`${this.host}/project-phase-stages`);
  }

  /**
   * -------------------------------------------------- PAYMENT TYPES --------------------------------------------------
   */
  getPaymentTypes() {
    return this.http.get<PaymentType[]>(`${this.host}/payment-types`);
  }

  /**
   * -------------------------------------------------- PROJECT PHASES --------------------------------------------------
   * */

  getProjectPhasesPaged(params:any = null) {
    const http_params:HttpParams = this.setParams(params);
    return this.http.get<ArrayResponse<ProjectPhase>>(`${this.host}/project-phases`, { params: http_params }).pipe(
      map(data => {
        data.data = data.data.map((pp:ProjectPhase) => {
          pp.contract.starts_at = new Date(pp.contract.starts_at);
          pp.contract.ends_at = new Date(pp.contract.ends_at);
          return pp;
        });
        return data;
      })
    );
  }

  getProjectPhases(params:any = null) {
    const http_params:HttpParams = this.setParams(params);
    return this.http.get<ArrayResponse<ProjectPhase>>(`${this.host}/project-phases`, { params: http_params });
  }

  getProjectPhase(id: number) {
    return this.http.get<ProjectPhase>(`${this.host}/project-phases/${id}`);
  }

  updateProjectPhase(id:number, body:any) {
    return this.http.put<ProjectPhase>(`${this.host}/project-phases/${id}`, body);
  }

  getProjectDeployWeeklyNotificationConfiguration(project_phase_id:number, query:any) {
    return this.http.get<ProjectDeployWeeklyNotificationConfiguration>(`${this.host}/project-phases/${project_phase_id}/project-deploy-weekly-notification-configuration`, { params: query });
  }

  createProjectDeployWeeklyNotificationConfiguration(project_phase_id:number, body:any) {
    return this.http.post<ProjectDeployWeeklyNotificationConfiguration>(`${this.host}/project-phases/${project_phase_id}/project-deploy-weekly-notification-configuration`, body);
  }

  updateProjectDeployWeeklyNotificationConfiguration(project_phase_id:number, body:any) {
    return this.http.put<ProjectDeployWeeklyNotificationConfiguration>(`${this.host}/project-phases/${project_phase_id}/project-deploy-weekly-notification-configuration`, body);
  }

  deleteProjectDeployWeeklyNotificationConfiguration(project_phase_id:number) {
    return this.http.delete<ProjectDeployWeeklyNotificationConfiguration>(`${this.host}/project-phases/${project_phase_id}/project-deploy-weekly-notification-configuration`);
  }

  getBoardStatus(project_phase_id:number, body:{ col_roles:string[]; from_date:Date|undefined; to_date:Date|undefined; }, avoid_snackbar:boolean = false) {
    const headers:any = {};
    if(avoid_snackbar) headers['avoid-snackbar-notification'] = 'true';
    return this.http.post<{text: string}>(`${this.host}/project-phases/${project_phase_id}/board-status/preview-text`, body, { headers: headers });
  }

  sendBoardStatus(project_phase_id:number, body:{ col_roles:string[]; user_notifiables:number[]; company_notification_channels:number[]; from_date:Date|undefined; to_date:Date|undefined; }) {
    return this.http.post<any>(`${this.host}/project-phases/${project_phase_id}/board-status`, body);
  }

  /**
   * -------------------------------------------------- HOSTINGS --------------------------------------------------
   */
  getHostings(params:any = null) {
    const http_params:HttpParams = this.setParams(params);
    return this.http.get<Hosting[]>(`${this.host}/hostings`, { params: http_params });
  }

  getHostingsPaged(params:any = null) {
    const http_params:HttpParams = this.setParams(params);
    return this.http.get<ArrayResponse<Hosting>>(`${this.host}/hostings`, { params: http_params });
  }

  getHosting(id:string) {
    return this.http.get<Hosting>(`${this.host}/hostings/${id}`);
  }

  getHostingComplete(id:string) {
    return this.http.get<Hosting>(`${this.host}/hostings/${id}/complete`);
  }

  createHosting(request:any) {
    return this.http.post<Hosting>(`${this.host}/hostings`, request);
  }

  updateHosting(id:number, request:any) {
    return this.http.put<Hosting>(`${this.host}/hostings/${id}`, request);
  }

  cancelHosting(id:number) {
    return this.http.delete<Hosting>(`${this.host}/hostings/${id}/cancel`);
  }

  deleteHosting(id:number) {
    return this.http.delete<Hosting>(`${this.host}/hostings/${id}/delete`);
  }


  /**
   * -------------------------------------------------- HETZNER --------------------------------------------------
   */
  getHetznerServerTypes() {
    return this.http.get<HetznerServerType[]>(`${this.host}/hetzner/server-types`);
  }

  getHetznerImages() {
    return this.http.get<HetznerImage[]>(`${this.host}/hetzner/images`);
  }

  getHetznerLocations() {
    return this.http.get<HetznerLocation[]>(`${this.host}/hetzner/locations`);
  }

  startServerHetzner(hetzner_id:string) {
    return this.http.post<any>(`${this.host}/hetzner/${hetzner_id}/start-server`, {});
  }

  stopServerHetzner(hetzner_id:string) {
    return this.http.post<any>(`${this.host}/hetzner/${hetzner_id}/stop-server`, {});
  }

  restartServerHetzner(hetzner_id:string) {
    return this.http.post<any>(`${this.host}/hetzner/${hetzner_id}/restart-server`, {});
  }

  enableBackupsHetzner(hetzner_id:string) {
    return this.http.post<any>(`${this.host}/hetzner/${hetzner_id}/enable-backups`, {});
  }

  disableBackupsHetzner(hetzner_id:string) {
    return this.http.post<any>(`${this.host}/hetzner/${hetzner_id}/disable-backups`, {});
  }

  resetRootPasswordHetzner(hetzner_id:string) {
    return this.http.post<Credential>(`${this.host}/hetzner/${hetzner_id}/reset-root-password`, {});
  }

  changeServerTypeHetzner(hetzner_id:string, upgrade_disk:boolean, server_type:string) {
    return this.http.post<any>(`${this.host}/hetzner/${hetzner_id}/change-server-type`, { upgrade_disk: upgrade_disk, server_type: server_type });
  }

  /**
   * ORDERS
   */
   getOrders(params:any = null) {
    const http_params:HttpParams = this.setParams(params);
    return this.http.get<Order[]>(`${this.host}/orders`, { params: http_params });
  }

  getOrdersPaged(params:any = null) {
    const http_params:HttpParams = this.setParams(params);
    return this.http.get<ArrayResponse<Order>>(`${this.host}/orders`, { params: http_params });
  }

  getOrder(id:string) {
    return this.http.get<Order>(`${this.host}/orders/${id}`);
  }

  createOrder(body:Order) {
    return this.http.post<Order>(`${this.host}/orders`, body);
  }

  updateOrder(id:string, body:any) {
    return this.http.put<Order>(`${this.host}/orders/${id}`, body);
  }

  deleteOrder(id:number) {
    return this.http.delete<Order>(`${this.host}/orders/${id}`);
  }

  getOrdersChartData(period:string, params:any = null) {
    const http_params:HttpParams = this.setParams(params);
    return this.http.get<any>(`${this.host}/orders/charts/${period}`, { params: http_params });
  }

  refundOrder(id:string) {
    return this.http.put<Order>(`${this.host}/orders/${id}/refund`, {});
  }

  downloadOrderInvoice(id:string) {
    return this.http.get(`${this.host}/orders/${id}/download-invoice`, { observe: 'response', responseType: 'blob' });
  }

  nextInvoiceNumber() {
    return this.http.get<any>(`${this.host}/orders/next-invoice-number`);
  }

  markOrderAsPayed(id:string) {
    return this.http.post(`${this.host}/orders/${id}/mark-as-payed`, {});
  }

  getBillingChartData(period:string, params:any = null) {
    const http_params:HttpParams = this.setParams(params);
    return this.http.get<any>(`${this.host}/orders/charts/resume/${period}`, { params: http_params });
  }

  /**
   * CREDENTIAL
   */
  createCredential(body:Credential) {
    return this.http.post<Credential>(`${this.host}/credentials`, body);
  }

  updateCredential(id:number, body:Credential) {
    return this.http.put<Credential>(`${this.host}/credentials/${id}`, body);
  }

  deleteCredential(id:number) {
    return this.http.delete<Credential>(`${this.host}/credentials/${id}`);
  }

  /**
   * -------------------------------------------------- DOMAINS --------------------------------------------------
   */
   getDomains(params:any = null) {
    const http_params:HttpParams = this.setParams(params);
    return this.http.get<Domain[]>(`${this.host}/domains`, { params: http_params });
  }

  getDomainsPaged(params:any = null) {
    const http_params:HttpParams = this.setParams(params);
    return this.http.get<ArrayResponse<Domain>>(`${this.host}/domains`, { params: http_params });
  }

  getDomain(id:string) {
    return this.http.get<Domain>(`${this.host}/domains/${id}`);
  }

  createDomain(request:any) {
    return this.http.post<Domain>(`${this.host}/domains`, request);
  }

  updateDomain(id:number, request:any) {
    return this.http.put<Domain>(`${this.host}/domains/${id}`, request);
  }

  getDomainComplete(id:string) {
    return this.http.get<Domain>(`${this.host}/domains/${id}/complete`);
  }

  cancelDomain(id:number) {
    return this.http.delete<Domain>(`${this.host}/domains/${id}/cancel`);
  }

  connectDns(id:number) {
    return this.http.put<Domain>(`${this.host}/domains/${id}/connect-dns`, {  });
  }

  changeGodaddyDns(id:number, nameservers:string[]) {
    return this.http.put<Domain>(`${this.host}/domains/${id}/godaddy/change-dns`, { name_servers: nameservers });
  }

  createCloudflareDns(id:number, dns_register:CloudflareZoneDns) {
    return this.http.post<Domain>(`${this.host}/domains/${id}/cloudflare/dns`, dns_register);
  }

  updateCloudflareDns(id:number, dns_register:CloudflareZoneDns) {
    return this.http.put<Domain>(`${this.host}/domains/${id}/cloudflare/dns/${dns_register.id}`, dns_register);
  }

  deleteCloudflareDns(id:number, dns_register:CloudflareZoneDns) {
    return this.http.delete<Domain>(`${this.host}/domains/${id}/cloudflare/dns/${dns_register.id}`);
  }

  /**
   * -------------------------------------------------- GODADDY --------------------------------------------------
   */
  getGodaddyAgreements(tld:string) {
    let params:HttpParams = new HttpParams();
    params = params.set('tld', tld);
    return this.http.get<any>(`${this.host}/godaddy/agreements`, { params: params });
  }

  checkGodaddyDomainAvailability(domain:string) {
    let params:HttpParams = new HttpParams();
    params = params.set('domain', domain);
    return this.http.get<any>(`${this.host}/godaddy/check-domain-availability`, { params: params });
  }

  /**
   * -------------------------------------------------- BUSINESS MAIL --------------------------------------------------
   */
   getBusinessMails(params:any = null) {
    const http_params:HttpParams = this.setParams(params);
    return this.http.get<BusinessMail[]>(`${this.host}/business-mails`, { params: http_params });
  }

  getBusinessMailsPaged(params:any = null) {
    const http_params:HttpParams = this.setParams(params);
    return this.http.get<ArrayResponse<BusinessMail>>(`${this.host}/business-mails`, { params: http_params });
  }

  getBusinessMail(id:string) {
    return this.http.get<BusinessMail>(`${this.host}/business-mails/${id}`);
  }

  createBusinessMail(request:any) {
    return this.http.post<BusinessMail>(`${this.host}/business-mails`, request);
  }

  updateBusinessMail(id:number, request:any) {
    return this.http.put<BusinessMail>(`${this.host}/business-mails/${id}`, request);
  }

  getBusinessMailComplete(id:string) {
    return this.http.get<BusinessMail>(`${this.host}/business-mails/${id}/complete`);
  }

  cancelBusinessMail(id:number) {
    return this.http.delete<BusinessMail>(`${this.host}/business-mails/${id}/cancel`);
  }

  /**
   * -------------------------------------------------- OVH --------------------------------------------------
   */
  createOvhCart() {
    return this.http.get<OvhCart>(`${this.host}/ovh/create-cart`);
  }

  getOvhExchangeOffers(cart_id:string) {
    return this.http.get<OvhGenericProduct[]>(`${this.host}/ovh/${cart_id}/exchange/offers`);
  }

  getOvhExchangeOptions(cart_id:string, planCode:string) {
    return this.http.get<OvhGenericProductOption[]>(`${this.host}/ovh/${cart_id}/exchange/${planCode}/options`);
  }

  updateOvhExchangeServer(ovh_id:string, data:any){
    return this.http.put<OvhExchangeServer>(`${this.host}/ovh/exchange/${ovh_id}`, data);
  }

  updateOvhExchangeAccount(ovh_id:string, account:string, data:any){
    return this.http.put<OvhExchangeServerAccount>(`${this.host}/ovh/exchange/${ovh_id}/${account}`, data);
  }

  /**
   * -------------------------------------------------- PROVIDER ORDERS --------------------------------------------------
   */
   getProviderOrders(params:any = null) {
    const http_params:HttpParams = this.setParams(params);
    return this.http.get<ProviderOrder[]>(`${this.host}/provider-orders`, { params: http_params });
  }

  getProviderOrdersPaged(params:any = null) {
    const http_params:HttpParams = this.setParams(params);
    return this.http.get<ArrayResponse<ProviderOrder>>(`${this.host}/provider-orders`, { params: http_params });
  }

  getProviderOrder(id:string) {
    return this.http.get<ProviderOrder>(`${this.host}/provider-orders/${id}`);
  }

  createProviderOrder(body:ProviderOrder) {
    return this.http.post<ProviderOrder>(`${this.host}/provider-orders`, body);
  }

  updateProviderOrder(id:string, body:any) {
    return this.http.put<ProviderOrder>(`${this.host}/provider-orders/${id}`, body);
  }

  deleteProviderOrder(id:number) {
    return this.http.delete<ProviderOrder>(`${this.host}/provider-orders/${id}`);
  }

  getProviderOrdersChartData(period:string, params:any = null) {
    const http_params:HttpParams = this.setParams(params);
    return this.http.get<any>(`${this.host}/provider-orders/charts/${period}`, { params: http_params });
  }

  getProviderOrderAttachments(id:number) {
    return this.http.get<ContasimpleAttachment[]>(`${this.host}/provider-orders/${id}/attachments`);
  }

  downloadProviderOrderAttachment(id:number, file_id:number) {
    return this.http.get(`${this.host}/provider-orders/${id}/attachments/${file_id}/download`, { observe: 'response', responseType: 'blob' });
  }

  uploadProviderOrderAttachment(id:number, file: File) {
    const formData = new FormData();
    formData.append("file", file);
    return this.http.post<ContasimpleAttachment[]>(`${this.host}/provider-orders/${id}/attachments`, formData);
  }

  deleteProviderOrderAttachment(id:number, file_id:number) {
    return this.http.delete<any>(`${this.host}/provider-orders/${id}/attachments/${file_id}`);
  }

  markProviderOrderAsPayed(id:string) {
    return this.http.post(`${this.host}/provider-orders/${id}/mark-as-payed`, {});
  }

  /**
   * -------------------------------------------------- UTILS --------------------------------------------------
   */
  getRegex() {
    return this.http.get<any>(`${this.host}/utils/regex-patterns`);
  }

  getBillingPeriods() {
    return this.http.get<string[]>(`${this.host}/utils/billing-periods`);
  }

  /**
   * -------------------------------------------------- ALERTS --------------------------------------------------
   */
  getProviderOrderAlerts(params:any = null) {
    const http_params:HttpParams = this.setParams(params);
    return this.http.get<ProviderOrderAlert[]>(`${this.host}/provider-order-alerts`, { params: http_params });
  }

  getProviderOrderAlertsPaged(params:any = null) {
    const http_params:HttpParams = this.setParams(params);
    return this.http.get<ArrayResponse<ProviderOrderAlert>>(`${this.host}/provider-order-alerts`, { params: http_params });
  }

  getProviderOrderAlert(id:string) {
    return this.http.get<ProviderOrderAlert>(`${this.host}/provider-order-alerts/${id}`);
  }

  createProviderOrderAlert(body:ProviderOrderAlert) {
    return this.http.post<ProviderOrderAlert>(`${this.host}/provider-order-alerts`, body);
  }

  updateProviderOrderAlert(id:string, body:any) {
    return this.http.put<ProviderOrderAlert>(`${this.host}/provider-order-alerts/${id}`, body);
  }

  deleteProviderOrderAlert(id:number) {
    return this.http.delete<ProviderOrderAlert>(`${this.host}/provider-order-alerts/${id}`);
  }

  /**
   * -------------------------------------------------- COMPANIES --------------------------------------------------
   */
   getCompanies(params:any = null) {
    const http_params:HttpParams = this.setParams(params);
    return this.http.get<ArrayResponse<Company>>(`${this.host}/companies`, { params: http_params });
  }

  getCompaniesPaged(params:any = null) {
    const http_params:HttpParams = this.setParams(params);
    return this.http.get<ArrayResponse<Company>>(`${this.host}/companies`, { params: http_params });
  }

  getCompany(id:string, params:any = {}) {
    return this.http.get<Company>(`${this.host}/companies/${id}`, { params: params });
  }

  createCompany(body:Company) {
    return this.http.post<Company>(`${this.host}/companies`, body);
  }

  updateCompany(id:string, body:any) {
    return this.http.put<Company>(`${this.host}/companies/${id}`, body);
  }

  deleteCompany(id:number) {
    return this.http.delete<Company>(`${this.host}/companies/${id}`);
  }

  getCompanyUsers(id:number, query:any = {}) {
    return this.http.get<User[]>(`${this.host}/companies/${id}/users`, { params: query });
  }

  getCompanyCompanyNotificationChannels(id:number, query:any = {}) {
    return this.http.get<CompanyNotificationChannel[]>(`${this.host}/companies/${id}/company-notification-channels`, { params: query });
  }

  /**
   * -------------------------------------------------- PROVIDERS --------------------------------------------------
   */
  getProviders(params:any = null) {
    const http_params:HttpParams = this.setParams(params);
    return this.http.get<Provider[]>(`${this.host}/providers`, { params: http_params });
  }

  getProvidersPaged(params:any = null) {
    const http_params:HttpParams = this.setParams(params);
    return this.http.get<ArrayResponse<Provider>>(`${this.host}/providers`, { params: http_params });
  }

  getProvider(id:string) {
    return this.http.get<Provider>(`${this.host}/providers/${id}`);
  }

  createProvider(body:Provider) {
    return this.http.post<Provider>(`${this.host}/providers`, body);
  }

  updateProvider(id:string, body:any) {
    return this.http.put<Provider>(`${this.host}/providers/${id}`, body);
  }

  deleteProvider(id:number) {
    return this.http.delete<Provider>(`${this.host}/providers/${id}`);
  }

  /**
   * -------------------------------------------------- PROVIDER ORDER CATEGORIES --------------------------------------------------
   */
  getProviderOrderCategories(params:any = null) {
    const http_params:HttpParams = this.setParams(params);
    return this.http.get<ProviderOrderCategory[]>(`${this.host}/provider-order-categories`, { params: http_params });
  }

  /**
   * CONTASIMPLE
   */
  getContasimpleResume(period:string) {
    return this.http.get<any>(`${this.host}/contasimple/resume/${period}`);
  }

  /**
   * -------------------------------------------------- WORKING TIME --------------------------------------------------
   */
  getWorkingTimeSummary(user_id:string, from:Date, to:Date) {
    let params:HttpParams = new HttpParams();
    params = params.set('from', this.utils.dateToString(from));
    params = params.set('to', this.utils.dateToString(to));

    return this.http.get<WorkingTimeSummary>(`${this.host}/working-times/summary/${user_id}`, {params: params}).pipe(
      map((wts:WorkingTimeSummary) => {
        wts.days = wts.days.map(d => new Date(d));
        return wts;
      })
    );
  }

  createWorkingTime(body:WorkingTime) {
    return this.http.post<WorkingTime>(`${this.host}/working-times`, body);
  }

  updateWorkingTime(id:string, body:any) {
    return this.http.put<WorkingTime>(`${this.host}/working-times/${id}`, body);
  }

  deleteWorkingTime(id:number) {
    return this.http.delete<WorkingTime>(`${this.host}/working-times/${id}`);
  }

  getWorkingTimeChartData(period:string, params:any) {
    const http_params:HttpParams = this.setParams(params);
    return this.http.get<any>(`${this.host}/working-times/charts/${period}`, { params: http_params });
  }

  getWorkingTimeCSV(params:any) {
    const http_params:HttpParams = this.setParams(params);
    return this.http.get(`${this.host}/working-times/csv`, { params: http_params, observe: 'response', responseType: 'blob' });
  }

  getWorkingTimeReportBusinessPartnerPdf(project_id:string, from:string, to:string) {
    return this.http.get(`${this.host}/projects/${project_id}/working-times-report`, { params: { from: from, to: to }, observe: 'response', responseType: 'blob' });
  }

  /**
   * -------------------------------------------------- WORKING TIME CATEGORY --------------------------------------------------
   */
  getWorkingTimeCategories() {
    return this.http.get<WorkingTimeCategory[]>(`${this.host}/working-time-categories`);
  }

  getWorkingTimeCategoriesSchedulables() {
    return this.http.get<WorkingTimeCategory[]>(`${this.host}/working-time-categories/schedulables`);
  }

  getWorkingTimeCategory(id_or_slug:string) {
    return this.http.get<WorkingTimeCategory>(`${this.host}/working-time-categories/${id_or_slug}`);
  }

  /**
   * -------------------------------------------------- CLOCK REGISTERS --------------------------------------------------
   */
  getClockRegistersByUserId(user_id:string, params:any) {
    const query:HttpParams = this.setParams(params);
    return this.http.get<ClockRegister[]>(`${this.host}/users/${user_id}/clock-registers`, { params: query });
  }

  /**
   * -------------------------------------------------- CLOCK REGISTER MOODS --------------------------------------------------
   */
   getClockRegisterMoods() {
    return this.http.get<ClockRegisterMood[]>(`${this.host}/clock-register-moods`);
  }

  /**
   * -------------------------------------------------- VACATION REQUEST --------------------------------------------------
   */

  getVacationRequests(params:any = null) {
    const http_params:HttpParams = this.setParams(params);
    return this.http.get<ArrayResponse<VacationRequest>>(`${this.host}/vacation-requests`, { params: http_params });
  }

  getVacationRequestsPaged(params:any = {}) {
    return this.http.get<ArrayResponse<VacationRequest>>(`${this.host}/vacation-requests`, { params: params });
  }

  getVacationRequest(id:string, params:any = {}) {
    return this.http.get<VacationRequest>(`${this.host}/vacation-requests/${id}`, { params: params });
  }

  updateVacationRequest(id:string, body:any) {
    return this.http.put<VacationRequest>(`${this.host}/vacation-requests/${id}`, body);
  }

  deleteVacationRequest(id:any) {
    return this.http.delete<VacationRequest>(`${this.host}/vacation-requests/${id}`);
  }

  createVacationRequest(body:VacationRequest) {
    return this.http.post<WorkingTime>(`${this.host}/vacation-requests`, body);
  }

  /**
   * -------------------------------------------------- VACATION REQUEST PERIOD --------------------------------------------------
   */

   getVacationRequestPeriod(id:string) {
    return this.http.get<VacationRequest>(`${this.host}/vacation-request-periods/${id}`);
  }

  getVacationRequestPeriodPaged(params:any) {
    const query:HttpParams = this.setParams(params);
    return this.http.get<ArrayResponse<VacationRequestPeriod>>(`${this.host}/vacation-request-periods`, { params: query }).pipe(
      map(vrps => {
        vrps.data = vrps.data.map((v,i) => {
          v.day = new Date(v.day);
          return v;
        });
        return vrps;
      })
    );
  }



  /**
   * -------------------------------------------------- WORKING CONTRACT --------------------------------------------------
   */
   getActiveWorkingContract(user_id:number, params:any = null) {
    const query:HttpParams = this.setParams(params);

    return this.http.get<WorkingContract>(`${this.host}/users/${user_id}/working-contracts/active`, { params: query }).pipe(
      map(wc => {
        wc.start_date = new Date(wc.start_date);
        if(wc.end_date!=null) wc.end_date = new Date(wc.end_date);
        return wc;
      })
    );
  }

  getActiveSchedule(id:string) {
    return this.http.get<WorkingContract>(`${this.host}/users/${id}/working-contracts/active/schedule`);
  }

  getUserExpectedHoursByDay(id:string) {
    return this.http.get<number[]>(`${this.host}/users/${id}/working-contracts/expected-hours-by-day`);
  }

  /*
   * BUSINESS INTELLIGENCE - RRHH
   */
  getWeeklyUsersWorkingSheet(params:any) {
    const query:HttpParams = this.setParams(params);
    return this.http.get<EmployeeWorkingSheet[]>(`${this.host}/business-intelligence/rrhh/employees-working-sheet`, { params: query }).pipe(
      map(ewss => {
        return ewss.map(ews => {
          ews.days = ews.days.map(d => {
            d.day = new Date(d.day);
            return d;
          });
          return ews;
        });
      })
    );
  }

  /**
   * -------------------------------------------------- CLOCK REGISTER REVIEW REQUESTS --------------------------------------------------
   */
  getClockRegisterReviewRequestsPaged(params:any) {
    const query:HttpParams = this.setParams(params);
    return this.http.get<ArrayResponse<ClockRegisterReviewRequest>>(`${this.host}/clock-register-review-requests`, { params: query });
  }

  createClockRegisterReviewRequest(c: ClockRegisterReviewRequest) {
    return this.http.post<ClockRegisterReviewRequest>(`${this.host}/clock-register-review-requests`, c);
  }

  updateClockRegisterReviewRequest(id:string, body:any) {
    return this.http.put<WorkingTime>(`${this.host}/clock-register-review-requests/${id}`, body);
  }

  /**
   * -------------------------------------------------- EMPLOYEE CALENDAR REGISTERS --------------------------------------------------
   * */
  getEmployeeCalendarRegistersForUser(id:string, from:Date, to:Date) {
    let params:HttpParams = new HttpParams();

    params = params.set('from', this.utils.dateToString(from));
    params = params.set('to', this.utils.dateToString(to));

    return this.http.get<EmployeeCalendarRegister[]>(`${this.host}/users/${id}/employee-calendar-registers`, { params: params }).pipe(
      map(ecr_array => {
        return ecr_array.map(ecr => {
          ecr.from_date = new Date(ecr.from_date);
          ecr.to_date = new Date(ecr.to_date);
          return ecr;
        })
      })
    );
  }

  getEmployeeCalendarRegistersForUserSerialized(id:string, from:Date, to:Date) {
    let params:HttpParams = new HttpParams();

    params = params.set('from', this.utils.dateToString(from));
    params = params.set('to', this.utils.dateToString(to));

    return this.http.get<EmployeeCalendarRegisterWithRelationsSerialized[]>(`${this.host}/users/${id}/employee-calendar-registers/serialized`, { params: params }).pipe(
      map(ecr_array => {
        return ecr_array.map(ecr => {
          ecr.from_date = new Date(ecr.from_date);
          ecr.to_date = new Date(ecr.to_date);
          return ecr;
        })
      })
    );
  }

  saveBulkEmployeeCalendarRegisters(body:any) {
    return this.http.post<EmployeeCalendarRegister[]>(`${this.host}/employee-calendar-registers/bulk`, body);
  }


  /**
   * -------------------------------------------------- WEEKLY WORKING TIME BUDGET --------------------------------------------------
   * */
  saveBulkWeeklyWorkingTimeBudgets(data:any[]) {
    return this.http.post<WeeklyWorkingTimeBudget[]>(`${this.host}/weekly-working-time-budgets/bulk`, data);
  }

  getWeeklyWorkingTimeBudget(id:string, params:any = null) {
    const query:HttpParams = this.setParams(params);
    return this.http.get<WeeklyWorkingTimeBudget>(`${this.host}/weekly-working-time-budgets/${id}`, { params: query });
  }

  getWeeklyWorkingTimeBudgetDistributionDistributable(id:string, params:any = null) {
    const query:HttpParams = this.setParams(params);
    return this.http.get<WeeklyWorkingTimeBudgetDistributionDistributable[]>(`${this.host}/weekly-working-time-budgets/${id}/distributables`, { params: query });
  }

  updateWeeklyWorkingTimeBudgetAndChargeDefaults(id:string, hours:number = null as any) {
    const body:any = {};
    if(hours != null) body.hours = hours;

    return this.http.put<WeeklyWorkingTimeBudget>(`${this.host}/weekly-working-time-budgets/${id}/update-and-charge-defaults`, body);
  }

  /**
   * -------------------------------------------------- CONTRACTS --------------------------------------------------
   * */
  getContracts(params:any = {}) {
    return this.http.get<Contract[]>(`${this.host}/contracts`, { params: params });
  }

  addContractToRoadmap(contract_id:number) {
    return this.http.post<WeeklyWorkingTimeBudget[]>(`${this.host}/contracts/${contract_id}/add-to-roadmap`, {});
  }

  getWeeklyWorkingTimeBudgetInfo(id:string, idDistributable:string) {
    return this.http.get<WeeklyWorkingTimeBudgetInfo>(`${this.host}/weekly-working-time-budgets/${id}/weekly-working-time-budget-distributables/${idDistributable}`);
  }

  updateWeeklyWorkingTimeBudgetDistributionDistributableBulk(body:any) {
    return this.http.put<WeeklyWorkingTimeBudgetDistributionDistributable[]>(`${this.host}/weekly-working-time-budget-distributables/bulk`, body);
  }

  /**
   * -------------------------------------------------- WEEKLY WORKING TIME BUDGET EMPLOYEE --------------------------------------------------
   * */
  saveBulkWeeklyWorkingTimeBudgetEmployee(data:any[]) {
    return this.http.post<WeeklyWorkingTimeBudgetEmployee[]>(`${this.host}/weekly-working-time-budget-employees/bulk`, data);
  }

  getWeeklyWorkingTimeBudgetEmployeeEmployees(from:Date, to:Date) {
    let params:HttpParams = new HttpParams();
    params = params.set('from', this.utils.dateToStringYYYYMMDD(from));
    params = params.set('to', this.utils.dateToStringYYYYMMDD(to));

    return this.http.get<User[]>(`${this.host}/weekly-working-time-budget-employees/employees`, { params: params });
  }

  getWeeklyWorkingTimeBudgetEmployees(params:any) {
    const query:HttpParams = this.setParams(params);

    return this.http.get<WeeklyWorkingTimeBudgetEmployee[]>(`${this.host}/weekly-working-time-budget-employees`, { params: params });
  }

  getWeeklyWorkingTimeBudgetEmployeesSerialized(params:any) {
    const query:HttpParams = this.setParams(params);

    return this.http.get<WeeklyWorkingTimeBudgetEmployeeSerialized[]>(`${this.host}/weekly-working-time-budget-employees/serialized`, { params: params });
  }

// ------------------------------------------------------ PHASE BOARDS --------------------------------------------------

  getPhaseBoards(phaseId: any, params:any = null) {
    const http_params:HttpParams = this.setParams(params);
    return this.http.get<ProjectPhaseBoard[]>(`${this.host}/project-phases/${phaseId}/phase-boards`, { params: http_params }).pipe(
      map(val => {
        val.forEach(board => {
          if(board.start_date != null) board.start_date = new Date(board.start_date);
          if(board.end_date != null) board.end_date = new Date(board.end_date);
        });
        return val;
      })
    );
  }

  getProjectPhaseTasks(phaseId: string, params:any = null) {
    const http_params:HttpParams = this.setParams(params);
    return this.http.get<ProjectPhaseTasks>(`${this.host}/project-phase-boards/${phaseId}/tasks`, { params: http_params });
  }

  updateProjectPhaseBoard(id: string, body: any) {
    return this.http.put<ProjectPhaseBoard>(`${this.host}/project-phase-boards/${id}`, body, { headers:
      new HttpHeaders().set('avoid-snackbar-notification', 'true').set('avoid-loading', 'true')
    });
  }

  completeProjectPhaseBoard(id: string, body:any) {
    return this.http.post<ProjectPhaseBoard>(`${this.host}/project-phase-boards/${id}/complete`, body, { headers:
      new HttpHeaders().set('avoid-snackbar-notification', 'true').set('avoid-loading', 'true')
    });
  }

  createProjectPhaseBoard(body: any) {
    return this.http.post<ProjectPhaseBoard>(`${this.host}/project-phase-boards`, body, { headers:
      new HttpHeaders().set('avoid-snackbar-notification', 'true').set('avoid-loading', 'true')
    });
  }

  deleteProjectPhaseBoard(id: string) {
    return this.http.delete<ProjectPhaseBoard>(`${this.host}/project-phase-boards/${id}`, { headers:
      new HttpHeaders().set('avoid-snackbar-notification', 'true').set('avoid-loading', 'true')
    });
  }

  archiveBoard(id: string) {
    return this.http.put<ProjectPhaseBoard>(`${this.host}/project-phase-boards/${id}/archive`, { headers:
      new HttpHeaders().set('avoid-snackbar-notification', 'true').set('avoid-loading', 'true')
    });
  }

  // ------------------------------------------------------ PROJECT PHASE BOARDS --------------------------------------------------
  getProjectPhaseBoard(board_id:number, params:any = {}) {
    return this.http.get<ProjectPhaseBoard>(`${this.host}/project-phase-boards/${board_id}`, {
      params: params
    });
  }

  getProjectPhaseBoardStructure(board_id: number, params:any = {}) {
    return this.http.get<{project_phase_board:ProjectPhaseBoard, columns: {column: ProjectPhaseBoardCol, project_task_boards:ProjectTaskBoard[]}[]}>(`${this.host}/project-phase-boards/${board_id}/board-structure`, {
      params: params
    });
  }

  changeBoardColumnsOrder(board_id: number, columns: any) {
    return this.http.put<ProjectPhaseBoardCol>(`${this.host}/project-phase-boards/${board_id}/change-columns-order`, columns);
  }



  changeBoardColumnTasksOrder(board_id: number, project_task_board_ids: number[]) {
    return this.http.put<ProjectTaskBoard>(`${this.host}/project-phase-boards/${board_id}/change-tasks-order`, project_task_board_ids);
  }

  moveTaskFromColumnToColumn(board_id:number, project_task_board_id:number, colDestination:ProjectPhaseBoardCol, newIndex:number, query:any = {}) {
    return this.http.put<ProjectTaskBoard>(`${this.host}/project-phase-boards/${board_id}/change-task-column`, {
      column_id: colDestination.id,
      project_task_board_id:project_task_board_id,
      index: newIndex,
    }, {
      params: query
    });
  }

  createBoardColumn(column: any) {
    return this.http.post<ProjectPhaseBoardCol>(`${this.host}/project-phase-board-cols`, column, { headers:
      new HttpHeaders().set('avoid-snackbar-notification', 'true').set('avoid-loading', 'true')
    });
  }

  getTaskByCode(code: string, params:any = {}) {
    return this.http.get<ProjectTask>(`${this.host}/project-tasks/code/${code}`, { params: params, headers:
      new HttpHeaders().set('avoid-snackbar-notification', 'true').set('avoid-loading', 'true')
    });
  }

  createTask(task: any) {
    return this.http.post<ProjectTask>(`${this.host}/project-tasks`, task);
  }

  saveBulkProjectTasks(tasks: any[]) {
    return this.http.post<ProjectTask>(`${this.host}/project-tasks/bulk`, tasks);
  }

  updateTask(task: any) {
    return this.http.put<ProjectTask>(`${this.host}/project-tasks/${task.id}`, task);
  }

  updateTaskAssignMe(task_id: string) {
    return this.http.post<ProjectTask>(`${this.host}/project-tasks/${task_id}/assign-me`, {});
  }

  removeTaskAssignedUser(task_id: string) {
    return this.http.post<ProjectTask>(`${this.host}/project-tasks/${task_id}/remove-assigned-user`, {});
  }

  attachTaskFile(task_id: number, file: any) {
    const formData = new FormData();
    formData.append("file", file);
    return this.http.post<ProjectTask>(`${this.host}/project-tasks/${task_id}/files`, formData);
  }

  attachTaskFiles(task_id: number, files: any[]) {
    const formData = new FormData();
    files.forEach(f => formData.append("files[]", f));
    return this.http.post<ProjectTask>(`${this.host}/project-tasks/${task_id}/files/bulk`, formData);
  }

  deleteAttachedTaskFile(task_id: number, file_id: number) {
    return this.http.delete<ProjectTask>(`${this.host}/project-tasks/${task_id}/files/${file_id}`);
  }

  downloadAttachedTaskFile(task_id: number, file_id: number) {
    return this.http.get(`${this.host}/project-tasks/${task_id}/files/${file_id}`, { observe: 'response', responseType: 'blob' });
  }

  getTask(task_id: number, params:any = {}) {
    return this.http.get<ProjectTask>(`${this.host}/project-tasks/${task_id}`, { params: params});
  }

  getTaskMembers(task_id: number) {
    return this.http.get<ProjectTaskMember>(`${this.host}/project-tasks/${task_id}/members`);
  }

  deleteTask(task_id: number) {
    return this.http.delete<ProjectTask>(`${this.host}/project-tasks/${task_id}`);
  }

  deleteMultipleTasks(data: any){
    return this.http.post<ProjectTask>(`${this.host}/project-tasks/bulk-delete`, data);
  }

  deleteBoardColumn(column_id: number) {
    return this.http.delete<ProjectPhaseBoard>(`${this.host}/project-phase-board-cols/${column_id}`);
  }

  updateBoardColumn(column: any) {
    return this.http.put<ProjectPhaseBoard>(`${this.host}/project-phase-board-cols/${column.id}`, column);
  }

  archiveTasks(data:any) {
    return this.http.put<ProjectTask>(`${this.host}/project-tasks/archive`, data);
  }

  listenTask($task_id: number, $user_id: number) {
    return this.http.post<ProjectTask>(`${this.host}/project-tasks/${$task_id}/listen/${$user_id}`, {});
  }

  unlistenTask($task_id: number, $user_id: number) {
    return this.http.delete<ProjectTask>(`${this.host}/project-tasks/${$task_id}/listen/${$user_id}`, {});
  }

  addReviewer($task_id: number, $user_id: number) {
    return this.http.post<ProjectTask>(`${this.host}/project-tasks/${$task_id}/review/${$user_id}`, {});
  }

  dropReviewer($task_id: number, $user_id: number) {
    return this.http.delete<ProjectTask>(`${this.host}/project-tasks/${$task_id}/review/${$user_id}`, {});
  }

  requireClientApproveTask(task_id:number) {
    return this.http.post<ProjectTask>(`${this.host}/project-tasks/${task_id}/require-client-approve`, {})
  }

  approveTask(task_id:number) {
    return this.http.post<ProjectTask>(`${this.host}/project-tasks/${task_id}/client-approve`, {})
  }

  declineTask(task_id:number) {
    return this.http.post<ProjectTask>(`${this.host}/project-tasks/${task_id}/client-decline`, {})
  }

  reaskApprovalTask(task_id:number) {
    return this.http.post<ProjectTask>(`${this.host}/project-tasks/${task_id}/client-reask-for-approval`, {})
  }

  updateProjectTaskTimeAndWorkDistribution(project_task_id:number, data:ProjectTaskDistribution) {
    return this.http.post<ProjectTask>(`${this.host}/project-tasks/${project_task_id}/time-work-distribution`, data);
  }

  addToBoard(project_task_id:number, project_phase_board_id:number) {
    return this.http.post<ProjectTaskBoard>(`${this.host}/project-tasks/${project_task_id}/add-to-board/${project_phase_board_id}`, {});
  }

  setProjectTaskTimeEstimation(id:number, time_in_seconds:number) {
    return this.http.put<ProjectTask>(`${this.host}/project-tasks/${id}/time-estimation`, { time_in_seconds: time_in_seconds });
  }

  // ------------------------------------------------------ PROJECT TASK BOARD --------------------------------------------------
  getProjectTaskBoard(id:number, params:any = {}) {
    return this.http.get<ProjectTaskBoard>(`${this.host}/project-task-boards/${id}`, { params: params });
  }

  updateProjectTaskBoard(id:number, body:any = {}) {
    return this.http.put<ProjectTaskBoard>(`${this.host}/project-task-boards/${id}`, body);
  }

  deleteProjectTaskBoard(id:number) {
    return this.http.delete<ProjectTaskBoard>(`${this.host}/project-task-boards/${id}`);
  }

  setProjectTaskBoardTimeEstimation(id:number, time_in_seconds:number) {
    return this.http.put<ProjectTaskBoard>(`${this.host}/project-task-boards/${id}/time-estimation`, { time_in_seconds: time_in_seconds });
  }

  // ------------------------------------------------------ PROJECT PHASE BOARD COL ROLE --------------------------------------------------

  getProjectPhaseBoardColRoles() {
    return this.http.get<ProjectPhaseBoardColRole[]>(`${this.host}/project-phase-board-col-roles`);
  }

  // ------------------------------------------------------ ENVIRONMENTS --------------------------------------------------

  getEnvironments() {
    return this.http.get<Environment[]>(`${this.host}/environments`);
  }

  // ------------------------------------------------------ PROJECT TASK COMMENTS --------------------------------------------------

  createComment(comment: any) {
    return this.http.post<ProjectTaskComment>(`${this.host}/project-task-comments`, comment, { headers:
      new HttpHeaders().set('avoid-snackbar-notification', 'true').set('avoid-loading', 'true')
    });
  }

  updateComment(comment: any) {
    return this.http.put<ProjectTaskComment>(`${this.host}/project-task-comments/${comment.id}`, comment, { headers:
      new HttpHeaders().set('avoid-snackbar-notification', 'true').set('avoid-loading', 'true')
    });
  }

  deleteComment(comment_id: number) {
    return this.http.delete<ProjectTaskComment>(`${this.host}/project-task-comments/${comment_id}`, { headers:
      new HttpHeaders().set('avoid-snackbar-notification', 'true').set('avoid-loading', 'true')
    });
  }


  // ------------------------------------------------------ PROJECT TASK TAG --------------------------------------------------

  getTaskTagCategories() {
    return this.http.get<ProjectTaskTagCategory[]>(`${this.host}/project-task-tag-categories`);
  }

  createTaskTagCategory(category: any) {
    return this.http.post<ProjectTaskTagCategory>(`${this.host}/project-task-tag-categories`, category, { headers:
      new HttpHeaders().set('avoid-snackbar-notification', 'true').set('avoid-loading', 'true')
    });
  }

  createTaskTag(tag: any) {
    return this.http.post<ProjectTaskTag>(`${this.host}/project-task-tags`, tag, { headers:
      new HttpHeaders().set('avoid-snackbar-notification', 'true').set('avoid-loading', 'true')
    });
  }

  deleteTaskTag(tag_id: number) {
    return this.http.delete<ProjectTaskTag>(`${this.host}/project-task-tags/${tag_id}`, { headers:
      new HttpHeaders().set('avoid-snackbar-notification', 'true').set('avoid-loading', 'true')
    });
  }

  updateProjectTask(task: ProjectTask) {
    return this.http.put<ProjectTask>(`${this.host}/project-tasks/${task.id}`, task, { headers:
      new HttpHeaders().set('avoid-snackbar-notification', 'true').set('avoid-loading', 'true')
    });
  }

  copyLastWeekAssignation(day:moment.Moment) {
    return this.http.post<WeeklyWorkingTimeBudgetEmployee[]>(`${this.host}/weekly-working-time-budget-employees/copy-last-week/${day.format('YYYY-MM-DD')}`, {  });
  }

  copyWeekFromTo(from:moment.Moment, to:moment.Moment) {
    return this.http.post<WeeklyWorkingTimeBudgetEmployee[]>(`${this.host}/weekly-working-time-budget-employees/copy-weeks/${from.format('YYYY-MM-DD')}/${to.format('YYYY-MM-DD')}`,{ });
  }

  /**
   * -------------------------------------------------- BILLING --------------------------------------------------
   * */
  getBillingPlanning(from:Date, to:Date) {
    const params = new HttpParams().set("from", this.utils.dateToStringYYYYMMDD(from)).set("to", this.utils.dateToStringYYYYMMDD(to));
    return this.http.get<BillingPlannedRow[]>(`${this.host}/billing/planned`, { params: params });
  }

  postBillingPlanningMonth(data:any) {
    return this.http.post<PlannedExpense|PlannedIncome>(`${this.host}/billing/planned/month`, data);
  }

  /**
   * -------------------------------------------------- PLANNED EXPENSE CATEGORY --------------------------------------------------
   */
  getPlannedExpenseCategories() {
    return this.http.get<PlannedExpenseCategory[]>(`${this.host}/planned-expense-categories`);
  }

  changePhaseTasksOrder(board: any) {
    return this.http.put(`${this.host}/project-tasks/change-phase-order`, board, { headers:
      new HttpHeaders().set('avoid-snackbar-notification', 'true').set('avoid-loading', 'true')
    });
  }

  changePhaseTaskBoard(boards: any) {
    return this.http.put<ProjectPhaseBoardCol>(`${this.host}/project-tasks/change-phase-task-board/`, boards, { headers:
      new HttpHeaders().set('avoid-snackbar-notification', 'true').set('avoid-loading', 'true')
    });
  }
  /**
   * -------------------------------------------------- POTENTIAL CLIENTS --------------------------------------------------
   */
  getPotentialClientsPaged(params:any = null) {
    const http_params:HttpParams = this.setParams(params);
    return this.http.get<ArrayResponse<PotentialClient>>(`${this.host}/potential-clients`, { params: http_params });
  }

  getPotentialClientsKpis(from:Date, to:Date) {
    const params = new HttpParams().set("from", this.utils.dateToStringYYYYMMDD(from)).set("to", this.utils.dateToStringYYYYMMDD(to));
    return this.http.get<any[]>(`${this.host}/potential-clients/kpis`, { params: params });
  }

  getPotentialClient(id:string, params:any = {}) {
    const http_params:HttpParams = this.setParams(params);
    return this.http.get<PotentialClient>(`${this.host}/potential-clients/${id}`, { params: http_params });
  }

  createPotentialClient(data:any) {
    return this.http.post<PotentialClient>(`${this.host}/potential-clients`, data);
  }

  updatePotentialClient(id:string, data:any) {
    return this.http.put<PotentialClient>(`${this.host}/potential-clients/${id}`, data);
  }

  /**
   * -------------------------------------------------- POTENTIAL CLIENT STATUS --------------------------------------------------
   */
  getPotentialClientStatus() {
    return this.http.get<PotentialClientStatus[]>(`${this.host}/potential-client-statuses` );
  }

  /**
   * -------------------------------------------------- POTENTIAL CLIENT COMMENT --------------------------------------------------
   */
  createPotentialClientComment(data:any) {
    return this.http.post<PotentialClientComment>(`${this.host}/potential-client-comments`, data);
  }

  /**
   * -------------------------------------------------- MEETING RECORD --------------------------------------------------
   */
  getMeetingRecord(id:string) {
    return this.http.get<MeetingRecord>(`${this.host}/meeting-records/${id}`);
  }

  createMeetingRecord(data:any) {
    return this.http.post<MeetingRecord>(`${this.host}/meeting-records`, data);
  }

  updateMeetingRecord(id:string, data:any) {
    return this.http.put<MeetingRecord>(`${this.host}/meeting-records/${id}`, data);
  }

  /**
   * -------------------------------------------------- POTENTIAL CLIENT STATUS CHANGE RECORDS --------------------------------------------------
   */
  getPotentialClientStatusChangeRecords(params:any = null) {
    const http_params:HttpParams = this.setParams(params);
    return this.http.get<ArrayResponse<PotentialClientStatusChangeRecord>>(`${this.host}/potential-client-status-change-records`, { params: http_params });
  }

  /**
   * -------------------------------------------------- BISUAL SETTINGS --------------------------------------------------
   */
  getBisualSettings() {
    return this.http.get<BisualSetting[]>(`${this.host}/bisual-settings`);
  }

  getBisualSetting(key:string) {
    return this.http.get<BisualSetting>(`${this.host}/bisual-settings/${key}`);
  }

  updateBulkBisualSettings(data:any) {
    return this.http.put<BisualSetting[]>(`${this.host}/bisual-settings/bulk`, data);
  }

  /**
   * -------------------------------------------------- GENERAL SEARCH --------------------------------------------------
   */
  generalSearch(params:any = null) {
    const http_params:HttpParams = this.setParams(params);
    return this.http.get<any[]>(`${this.host}/general-search`, { params: http_params });
  }

  /**
   * -------------------------------------------------- WIKI NODE  --------------------------------------------------
   */
  getWikiTree(initial_node_id:number = null as any) {
    var http_params:HttpParams = new HttpParams();

    if(initial_node_id!=null) {
      http_params = this.setParams({ initial_node_id: initial_node_id });
    }

    return this.http.get<WikiTree[]>(`${this.host}/wiki-nodes/tree`, { params: http_params });
  }

  getWikiNode(id:number, params:any = null) {
    const http_params:HttpParams = this.setParams(params);
    return this.http.get<WikiNode>(`${this.host}/wiki-nodes/${id}`, { params: http_params });
  }

  createWikiNode(body:WikiNode|any) {
    return this.http.post<WikiNode>(`${this.host}/wiki-nodes`, body);
  }

  updateWikiNode(id:number, body:any) {
    return this.http.put<WikiNode>(`${this.host}/wiki-nodes/${id}`, body);
  }

  deleteWikiNode(id:number) {
    return this.http.delete<WikiNode>(`${this.host}/wiki-nodes/${id}`);
  }

  getWikiNodeApplicationEnvironments(id:number, params:any = {}) {
    return this.http.get<ApplicationEnvironment[]>(`${this.host}/wiki-nodes/${id}/application-environments`, { params: params });
  }

  /**
   * -------------------------------------------------- WIKI ARTICLE  --------------------------------------------------
   */
  getWikiArticle(id:string, params:any = null) {
    const http_params:HttpParams = this.setParams(params);
    return this.http.get<WikiArticle>(`${this.host}/wiki-articles/${id}`, { params: http_params });
  }

  getWikiGithubArticle(filename:string, params:any = null) {
    const http_params:HttpParams = this.setParams(params);
    return this.http.get<{metadata:any;content:string}>(`${this.host}/wiki-articles/github/${filename}`, { params: http_params });
  }

  updateWikiArticle(id:number, body:any) {
    return this.http.put<WikiArticle>(`${this.host}/wiki-articles/${id}`, body);
  }

  createWikiArticle(body:WikiArticle|any) {
    return this.http.post<WikiArticle>(`${this.host}/wiki-articles`, body);
  }

  deleteWikiArticle(id:number) {
    return this.http.delete<WikiArticle>(`${this.host}/wiki-articles/${id}`);
  }

  /**
   * -------------------------------------------------- WIKI ARTICLE VERSION  --------------------------------------------------
   */
  createWikiArticleVersion(body:WikiArticleVersion) {
    return this.http.post<WikiArticleVersion>(`${this.host}/wiki-article-versions`, body, { headers:
      new HttpHeaders().set('avoid-snackbar-notification', 'true') });
  }

  /**
   * -------------------------------------------------- PROJECT MEETING RECORDS  --------------------------------------------------
   */
  getProjectMeetingRecords(params:any = null) {
    const http_params:HttpParams = this.setParams(params);
    return this.http.get<ArrayResponse<MeetingRecord>>(`${this.host}/meeting-records`, { params: http_params });
  }

  /**
   * -------------------------------------------------- APPLICATIONS --------------------------------------------------
   */
  getApplication(app_id:string, params:any = {}) {
    return this.http.get<Application>(`${this.host}/applications/${app_id}`, { params: params });
  }

  createApplication(body:any) {
    return this.http.post<Application>(`${this.host}/applications`, body);
  }

  updateApplication(app_id:string, body:any) {
    return this.http.put<Application>(`${this.host}/applications/${app_id}`, body);
  }

  deleteApplication(app_id:string) {
    return this.http.delete<Application>(`${this.host}/applications/${app_id}`);
  }

  checkApplicationGithubConnection(app_id:string) {
    return this.http.get<{status:boolean, message:string|undefined}>(`${this.host}/applications/${app_id}/check-github`);
  }

  renewApplicationGithubToken(app_id:string) {
    return this.http.post<Application>(`${this.host}/applications/${app_id}/renew-github`, {});
  }

  /**
   * -------------------------------------------------- APPLICATION TYPES --------------------------------------------------
   */
  getApplicationTypes(params:any = {}) {
    return this.http.get<ApplicationType[]>(`${this.host}/application-types`, { params: params });
  }

  /**
   * -------------------------------------------------- PROGRAMMING TAGS --------------------------------------------------
   */
  getProgrammingTags(params:any = {}) {
    return this.http.get<ProgrammingTag[]>(`${this.host}/programming-tags`, { params: params });
  }

  createApplicationEnvironment(body:any) {
    return this.http.post<ApplicationEnvironment>(`${this.host}/application-environments`, body);
  }

  updateApplicationEnvironment(id:number, body:any) {
    return this.http.put<ApplicationEnvironment>(`${this.host}/application-environments/${id}`, body);
  }

  deleteApplicationEnvironment(id:number) {
    return this.http.delete<ApplicationEnvironment>(`${this.host}/application-environments/${id}`);
  }

  /**
   * -------------------------------------------------- COUNTRY ZONES --------------------------------------------------
   */
  getCountryZones(params:any = {}) {
    return this.http.get<CountryZone[]>(`${this.host}/country-zones`, { params: params });
  }

  getCountryZoneHolidays(country_zone_id:string, params:any = {}) {
    return this.http.get<Holiday[]>(`${this.host}/country-zones/${country_zone_id}/holidays`, { params: params });
  }

  /**
   * -------------------------------------------------- HOLIDAYS --------------------------------------------------
   */
  updateHoliday(id:string, body:any) {
    return this.http.put<Holiday>(`${this.host}/holidays/${id}`, body);
  }

  /**
   * -------------------------------------------------- COMPANY NOTIFICATION CHANNELS --------------------------------------------------
   */
  getCompanyNotificationChannel(id:string) {
    return this.http.get<CompanyNotificationChannel>(`${this.host}/company-notification-channels/${id}`);
  }

  createCompanyNotificationChannel(body:CompanyNotificationChannel) {
    return this.http.post<CompanyNotificationChannel>(`${this.host}/company-notification-channels`, body);
  }

  updateCompanyNotificationChannel(id:string, body:CompanyNotificationChannel) {
    return this.http.put<CompanyNotificationChannel>(`${this.host}/company-notification-channels/${id}`, body);
  }

  deleteCompanyNotificationChannel(id:string) {
    return this.http.delete<CompanyNotificationChannel>(`${this.host}/company-notification-channels/${id}`);
  }

  /**
   * -------------------------------------------------- PROJECT TEAMS --------------------------------------------------
   */
  getProjectTeam(id:string) {
    return this.http.get<ProjectTeam>(`${this.host}/project-teams/${id}`);
  }

  createProjectTeam(body:ProjectTeam) {
    return this.http.post<ProjectTeam>(`${this.host}/project-teams`, body);
  }

  updateProjectTeam(id:string, body:CompanyNotificationChannel) {
    return this.http.put<ProjectTeam>(`${this.host}/project-teams/${id}`, body);
  }

  deleteProjectTeam(id:string) {
    return this.http.delete<ProjectTeam>(`${this.host}/project-teams/${id}`);
  }

  /**
   * -------------------------------------------------- PROJECT TASK BOARD TIME BUDGET --------------------------------------------------
   */
  updateProjectTaskBoardTimeBudget(id:number, body:any) {
    return this.http.put<ProjectTaskBoardTimeBudget>(`${this.host}/project-task-board-time-budgets/${id}`, body);
  }

  deleteProjectTaskBoardTimeBudget(id:number) {
    return this.http.delete<ProjectTaskBoardTimeBudget>(`${this.host}/project-task-board-time-budgets/${id}`);
  }
}
