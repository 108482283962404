import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { User } from 'src/app/models/user.model';
import { FormControl } from '@angular/forms';
import { ApiService } from 'src/app/services/api.service';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/environments/environment';
import { ProjectPhaseBoardCol } from 'src/app/models/project_phase_board_col.model';
import { SecondsToStringTimePipe } from 'src/app/pipes/seconds-to-string-time.pipe';
import { WorkingTimeCategory } from 'src/app/models/working_time_category.model';
import { WorkingTime } from 'src/app/models/working_time.model';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UtilsService } from 'src/app/services/utils.service';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {MatChipInputEvent} from '@angular/material/chips';
import { Observable } from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import { ProjectTaskTagCategory } from 'src/app/models/project_task_tag_category.model';
import { HourStringToSecondsPipe } from 'src/app/pipes/hour-string-to-seconds.pipe';
import { AddNewWorkingTimeDialogComponent } from '../../../employees/my-zone/working-times-history/add-new-working-time-dialog/add-new-working-time-dialog.component';
import { PermissionsService } from 'src/app/services/permissions.service';
import { ProjectTaskComment } from 'src/app/models/project_task_comment.model';
import { TinymceService } from 'src/app/services/tinymce.service';
import { ProjectPhaseBoard } from 'src/app/models/project_phase_board.model';
import { ProjectTask } from 'src/app/models/project_task.model';
import { ProjectTaskBoard } from 'src/app/models/project_task_board.model';
import { ProjectTaskAttachment } from 'src/app/models/project_task_attachments.model';
import { ProjectTaskBoardTimeBudget } from 'src/app/models/project_task_board_time_budget.model';
import { ProjectTeam } from 'src/app/models/project_team.model';
import { ProjectTaskTimeBudget } from 'src/app/models/project_task_time_budget.model';
import { ProjectUserMember } from 'src/app/models/project_user_member.model';
import { ProjectTaskBudgetDetailDialogComponent } from './project-task-budget-detail-dialog/project-task-budget-detail-dialog.component';
import { ProjectPhaseBoardsComponent } from '../project-detail/project-phases/project-phase-detail/project-phase-boards/project-phase-boards.component';
import { DecimalPipe } from '@angular/common';
import { MatSelectChange } from '@angular/material/select';
@Component({
  selector: 'app-project-task-detail',
  templateUrl: './project-task-detail.component.html',
  styleUrls: ['./project-task-detail.component.css']
})
export class ProjectTaskDetailComponent implements OnInit {

  task:ProjectTaskCustom;
  project_task_board:ProjectTaskBoardCustom|undefined;
  project_id:number = this.data.project_id;
  activeActivityMenu:'all'|'comments'|'history'|'worklog'|'distribution' = 'comments';
  me: UserProject = null as any;

  storyPointEstimationParsed:string = '0h';
  revisionStoryPointEstimationParsed:string = '0h';
  designStoryPointEstimationParsed:string = '0h';
  currentBoard:ProjectPhaseBoard|undefined;

  editingField:string = '';
  userControl:UntypedFormControl = new UntypedFormControl();
  production_team_users:UserProject[] = [];
  all_team_users:{[key:number]:UserProject} = {};
  project_teams:{[key:number]:ProjectTeam} = {};

  newComment:string = '';
  editingComment:any = null as any;
  files: File[] = [];
  hoveringAttachedFile:any = null as any;

  isTaskLoaded:boolean = false;
  imFollowingTask:boolean = false;

  isProjectManagerOrProductManager: boolean = false;
  isProductManager: boolean = false;
  imDeveloping: boolean = false;
  isClientDeveloper: boolean = false;
  isClientBusiness:boolean = false;

  distribution_table:ProjectTaskDistribution = null as any;

  tinyMceInit = {
    automatic_uploads: true,
    statusbar: false,
    menubar: false,
    block_unsupported_drop: false
  };

  //Task tasks
  separatorKeysCodes: number[] = [ENTER, COMMA];
  tagsCntrl = new FormControl('');
  filteredTags: Observable<string[]>;
  tags: string[] = [];
  allStringTagsCategories: string[] = [];
  tagCategories: ProjectTaskTagCategory[] = [];
  @ViewChild('tagsInput') tagsInput: ElementRef<HTMLInputElement>;

  // Listeners
  listenersCntrl = new FormControl('');
  filteredUsersListeners: Observable<User[]>;
  @ViewChild('usersInput') usersInput: ElementRef<HTMLInputElement>;

  //Autofocus when edit field
  @ViewChild('editingInput', { static: false })
  set editingInput(element: ElementRef<HTMLTextAreaElement>) {
    if(element) {
      element.nativeElement.focus()
    }
  }

  board_general_estimation_template_data:SpinnerGroupData|undefined;
  board_category_estimation_template_data:SpinnerGroupData[]|undefined;
  general_estimation_template_data:SpinnerGroupData|undefined;
  task_category_estimation_template_data:SpinnerGroupData[]|undefined;

  constructor(private dialogRef: MatDialogRef<ProjectTaskDetailComponent>,
              private userService:UserService,
              private dialog: MatDialog,
              @Inject(MAT_DIALOG_DATA) public data:{task_code_id:number|string,project_phase_board_id:number|string,project_id:number},
              private snack:MatSnackBar,
              private api:ApiService,
              private permissions:PermissionsService,
              public tinymce:TinymceService,
              private secondsToStringTime:SecondsToStringTimePipe,
              private decimal:DecimalPipe,
              private hourStringToSecondsPipe:HourStringToSecondsPipe
  ) {
    this.initFilteredTagsListeners();
    this.initFilterUsersListeners();
  }

  ngOnInit(): void {
    this.checkInputData();
    this.me = this.userService.getCurrentUser() as UserProject;
    this.fetchProjectTeams();
    this.fetchMembers();
  }

  private initFilterUsersListeners() {
    this.filteredUsersListeners = this.listenersCntrl.valueChanges.pipe(
      startWith(null),
      map((w: string | null) => (this._filterListeners(w ?? ''))),
    );
  }

  private initFilteredTagsListeners() {
    this.filteredTags = this.tagsCntrl.valueChanges.pipe(
      startWith(null),
      map((tag: string | null) => (tag ? this._filter(tag) : this.allStringTagsCategories.slice())),
    );
  }

  close(): void {
    this.dialogRef.close();
  }

  async setEdittingComment(comment:ProjectTaskComment) {
    this.editingComment = comment;
  }

  private fetchTaskTagCategories() {
    this.api.getTaskTagCategories().subscribe(
      data => {
        this.tagCategories = data;
        this.allStringTagsCategories = data.map((category:ProjectTaskTagCategory) => category.title);
        this.tags = this.task.tags.map((tag:any) => tag.tag.title);
      }
    );
  }

  private async fetchMembers() {
    this.api.getProjectActiveProductionTeam(this.project_id.toString()).subscribe(
      users => {
        this.production_team_users = users.bisual_users.map((obj:ProjectUserMember) => {
          const u:UserProject = obj.user as UserProject;
          const project_user_member:ProjectUserMember = {...obj};
          delete (project_user_member as any).user;
          u.project_user_member = project_user_member;
          return u;
        });
        users.company_users.forEach(cu => this.production_team_users.push(cu as UserProject));
      }
    );

    this.api.getActiveProjectMembersWithBusiness(this.project_id.toString()).subscribe(
      data => {
        this.all_team_users = {};
        data.bisual_users.forEach(bu => {
          const u:UserProject = bu.user as UserProject;
          const project_user_member:ProjectUserMember = {...bu};
          delete (project_user_member as any).user;
          u.project_user_member = project_user_member;

          this.all_team_users[bu.user.id] = u;
        });
        data.company_users.forEach(cu => {
          this.all_team_users[cu.id] = cu as UserProject;
        });

        this.loadFullTaskData();
      }
    );
  }

  handleSelectorInputFocusout(event: any){
    if(event.target.className.includes("mat-option")
      || event.target.className.includes("profile-image")
      || event.target.parentElement.className.includes("mat-option-text")) {
      //dont close
    } else {
      this.editingField = '';
    }
  }

  async setEditingField(field:string) {
    if(
      this.permissions.isWorker() ||
      (this.isClientDeveloper && ((this.currentBoard != null && this.currentBoard.can_client_dev_manage_board) || (this.task.project_phase.can_client_dev_manage_backlog))) ||
      (this.isClientBusiness && this.task.project_phase.can_client_business_add_approval_tasks && this.task.is_draft)
    ) {
      this.editingField = field;
    }
  }

  chooseNewAssignedUser(time_budget:ProjectTaskBoardTimeBudgetCustom, user:UserProject|null, evt:MatSelectChange|undefined = undefined) {
    if(evt != null) {
      const matFormField = evt.source._elementRef.nativeElement.closest('mat-form-field');
      if(matFormField) {
        matFormField.classList.remove('mat-focused');
      }
    }
    time_budget.assignee = user;
    time_budget.assignee_id = user != null ? user.id : null;
    this.api.updateProjectTaskBoardTimeBudget(time_budget.id, { assignee_id: time_budget.assignee_id }).subscribe(
      data => {

      }
    );
  }

  chooseNewPriority(priority:string){
    this.task.priority = priority;
    this.updateTask();
  }

  chooseTaskType(type:string){
    this.task.type = type;
    this.updateTask();
  }

  removeFromBoard() {
    if(confirm(`¿Estás seguro que quieres eliminar la tarea "${this.task.title}" del board "${this.currentBoard!.title ?? ('#' + this.currentBoard!.id)}"? Si la tarea existe en otro tablero, no se movera al backlog. Si no existe en ningún otro tablero, ésta será movida al backlog.`)) {
      this.api.deleteProjectTaskBoard(this.project_task_board!.id).subscribe(
        data => {
          this.dialogRef.close(data);
        }
      );
    }
  }

  async changeColumn(project_phase_board_col_id:number) {
    const colOrigin:ProjectPhaseBoardCol = this.project_task_board!.project_phase_board_col;
    const colDestination:ProjectPhaseBoardCol = this.currentBoard!.project_phase_board_cols!.find(col => col.id == project_phase_board_col_id) as ProjectPhaseBoardCol;
    this.project_task_board!.project_phase_board_col_id = project_phase_board_col_id;

    const valid:boolean = ProjectPhaseBoardsComponent.checkAndAskIfTaskMoveIsValid(this.project_task_board as ProjectTaskBoard, colOrigin, colDestination);

    if(valid) {
      await this.updateProjectBoardTask({ project_phase_board_col_id: project_phase_board_col_id });
      const dialogRef = ProjectPhaseBoardsComponent.handleTaskFromColumnToColumnDialogs(this.dialog, this.project_task_board!.id, colOrigin, colDestination, this.project_id, this.project_task_board!.project_task_id);
      if(dialogRef) {
        dialogRef.afterClosed().subscribe(
          data => {
            if(data) {
              this.loadFullTaskData();
            }
          }
        );
      }
    }
  }

  private async updateProjectBoardTask(body:any = null, refresh: boolean = false) {
    body = body ?? this.project_task_board;
    const data = await this.api.updateProjectTaskBoard(this.project_task_board!.id, body).toPromise();
    if(refresh) this.loadFullTaskData();
  }

  publishTask() {
    this.updateTask({ id: this.task.id, is_draft: false });
  }

  updateTask(body:any = null) {
    body = body ?? this.task;
    this.api.updateTask(body).subscribe(
      data => {
        this.editingField = '';
        this.loadFullTaskData();
      }
    );
  }

  openTimeTrackingDialog(workingTime:WorkingTime|null = null, project_task_board_time_budget:ProjectTaskBoardTimeBudgetCustom|undefined = undefined): void {
    const user = this.userService.getCurrentUser();
    if(this.imDeveloping || (user.role.slug==='client' && user.client_role==='developer')) {
      if(workingTime != null) {
        project_task_board_time_budget = this.project_task_board!.project_task_board_time_budgets.find(budget => budget.budgetable_type === "App\\Models\\WorkingTimeCategory" && budget.budgetable_id == workingTime.working_time_category_id);
      }
      const timeTrackingDialog = this.dialog.open(AddNewWorkingTimeDialogComponent, {
        width: '650px',
        data: {
          task: this.task,
          project_task_board_time_budget: project_task_board_time_budget,
          workingTime: workingTime,
          is_editing: workingTime != null
        }
      });

      timeTrackingDialog.afterClosed().toPromise().then(
        (data) => {
          if(data!=null) {
            data.project_id = this.project_id;
            data.task_id = this.task.id;
            data.type = 'work_register';
            if(workingTime!=null) {
              this.api.updateWorkingTime(workingTime.id.toString(), data).toPromise().then(
                data => {
                  this.loadFullTaskData();
                }
              );
            } else {
              this.api.createWorkingTime(data).toPromise().then(
                data => {
                  this.loadFullTaskData();
                }
              );
            }
          }
        }
      );
    }
  }

  deleteWorkingTime(working_time:WorkingTime) {
    if(confirm(`¿Estás seguro que quieres eliminar el registro de tiempo "${working_time.description}"?`)) {
      this.api.deleteWorkingTime(working_time.id).subscribe(
        data => {
          this.loadFullTaskData();
        }
      );
    }
  }

  imAssigned() {
    if(this.project_task_board != null) {
      for(let budget of this.project_task_board.project_task_board_time_budgets) {
        if(budget.assignee_id == this.me.id) return true;
      }
      return false;
    }
    else return false;
  }

  private loadFullTaskData() {
    const params = {
      with: 'project_task_time_budgets..budgetable,project_task_boards..project_phase_board_col..project_phase_board,project_task_boards..project_phase_board_col..project_phase_board_col_role,project_task_boards..project_task_board_time_budgets..budgetable,project_task_boards..project_task_board_time_budgets..assignee,project_task_boards..project_task_board_time_budgets..working_times..user,comments..user..current_working_contract..current_working_contract_variable_condition..department_category,history_logs,attachments,tags,project_task_listeners,project_phase..project_phase_boards',
    };

    let promise:Observable<ProjectTask> = null as any;
    if(typeof this.data.task_code_id === 'string') {
      promise = this.api.getTaskByCode(this.data.task_code_id, params);
    }
    else promise = this.api.getTask(this.data.task_code_id, params);

    promise.subscribe(
      data => {
        this.onGetTaskData(data);
      }
    );
  }

  createComment() {
    const comment = {
      project_task_id: this.task.id,
      user_id: this.me.id,
      content: this.newComment
    };
    this.api.createComment(comment).subscribe(
      data => {
        this.newComment = '';
        this.editingField = '';
        this.loadFullTaskData();
      }
    );
  }

  deleteComment(comment:any) {
    if(confirm(`¿Estás seguro que quieres eliminar el comentario?`)) {
      this.api.deleteComment(comment.id).subscribe(
        data => {
          this.loadFullTaskData();
        }
      );
    }
  }

  updateComment(comment:any) {
    this.api.updateComment(comment).subscribe(
      data => {
        this.editingComment = null;
        this.loadFullTaskData();
      }
    );
  }

  private uploadFiles(files: File[]) {
    if(files.length>0) {
      this.api.attachTaskFiles(this.task.id, files).subscribe(
        data =>{
          this.loadFullTaskData();
        },
        (error:HttpErrorResponse) => {
          if(error.status===413) {
            //file too large
            this.snack.open("Archivo demasiado grande", "OK", { duration: 3000 });
          }
          else this.snack.open("No se ha podido subir el archivo", "OK", { duration: 3000 })
        }
      );
    }
  }

  onSelect(event: any) {
    if(!event) return;
    if(
      this.permissions.isWorker() ||
      (this.isClientDeveloper && this.currentBoard != null && this.currentBoard.can_client_dev_manage_board) ||
      (this.isClientBusiness && this.task.project_phase.can_client_business_add_approval_tasks && this.task.is_draft)
    ) {
      this.files.push(...event.addedFiles);
      this.uploadFiles(event.addedFiles);
    }
  }

  deleteAttachedFile(file:any) {
    if(
      (
        this.permissions.isWorker() ||
        (this.isClientDeveloper && this.currentBoard != null && this.currentBoard.can_client_dev_manage_board) ||
        (this.isClientBusiness && this.task.project_phase.can_client_business_add_approval_tasks && this.task.is_draft)
      ) &&
      confirm(`¿Estás seguro que quieres eliminar el archivo adjunto "${file.file_name}"?`)
    ) {
      this.api.deleteAttachedTaskFile(this.task.id, file.id).subscribe(
        data => {
          this.loadFullTaskData();
        }
      );
    }
  }

  checkIfFileIsImage(file_name:any) {
    return file_name.match(/\.(jpg|jpeg|png|gif)$/i);
  }

  checkIfFileCanBeDisplayed(file_name:any) {
    return file_name.match(/\.(jpg|jpeg|png|gif)$/i);
  }

  checkIfFileIsExcel(file_name:any) {
    return file_name.match(/\.(xls|xlsx)$/i);
  }

  checkIfFileIsCSV(file_name:any) {
    return file_name.match(/\.(csv)$/i);
  }

  checkIfFileIsPDF(file_name:any) {
    return file_name.match(/\.(pdf)$/i);
  }

  getFileNameExtension(file_name:any) {
    return file_name.split('.').pop().toLowerCase();
  }

  showAttachment(file:any) {
    window.open(file.file_path, "_blank");
  }

  addTag(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    //if value already exist a tag with title in tags, do nothing
    const tagCategory = this.tagCategories.find((t:any) => t.title === value);
    if (value && !tagCategory) {

      const tagCategory = {
        title: value
      };
      this.api.createTaskTagCategory(tagCategory).subscribe(
        taskTagCategory => {
          const tag = {
            task_id: this.task.id,
            tag_id: taskTagCategory.id
          };
          this.api.createTaskTag(tag).subscribe(
            taskTag => {
              this.tags.push(taskTagCategory.title);
              this.loadFullTaskData();
              this.fetchTaskTagCategories();
            }
            );
        });
      }
    event.chipInput!.clear();
    this.tagsCntrl.setValue(null);
  }

  removeTag(tag: string): void {
    const index = this.tags.indexOf(tag);
    if (index >= 0) {
      const indexInTask = this.task.tags.findIndex((t:any) => t.tag.title === tag);
      if(indexInTask>=0) {
        const tagId = this.task.tags[indexInTask].id;
        this.api.deleteTaskTag(tagId).subscribe(
            data => {
              this.tags.splice(index, 1);
            }
        );
      }
    }
  }

  selectedTag(event: MatAutocompleteSelectedEvent): void {
    if(!this.tags.includes(event.option.viewValue)){
      this.tags.push(event.option.viewValue);

      const tagCategory = this.tagCategories.find((t:any) => t.title === event.option.viewValue);
      if(tagCategory!=null) {
        const project_task_tag = {
          task_id: this.task.id,
          tag_id: tagCategory.id
        };
        this.api.createTaskTag(project_task_tag).subscribe(
          data => {
            this.loadFullTaskData();
          }
        );
      }
    }

    this.tagsInput.nativeElement.value = '';
    this.tagsCntrl.setValue(null);
  }

  private getProgressSpinnerClassListByBudget(budget:ProjectTaskBoardTimeBudgetCustom|ProjectTaskTimeBudgetCustom): any {
    if((budget as ProjectTaskBoardTimeBudgetCustom).project_task_board_id != null) {
      const b:ProjectTaskBoardTimeBudgetCustom = budget as ProjectTaskBoardTimeBudgetCustom;
      return {
        disabled: b.time_in_seconds == null || b.time_in_seconds <= 0,
        start: b.time_in_seconds != null && b.time_in_seconds > 0 && b.time_registered_in_seconds_for_client <= b.time_in_seconds && b.completed_by_user_id == null && b.time_registered_in_seconds_for_client == 0,
        inprogress: b.time_in_seconds != null && b.time_in_seconds > 0 && b.time_registered_in_seconds_for_client <= b.time_in_seconds && b.completed_by_user_id == null && b.time_registered_in_seconds_for_client > 0,
        finished: b.time_in_seconds != null && b.time_in_seconds > 0 && b.time_registered_in_seconds_for_client <= b.time_in_seconds && b.completed_by_user_id != null,
        overtime: b.time_in_seconds != null && b.time_in_seconds > 0 && b.time_registered_in_seconds_for_client > b.time_in_seconds
      };
    }
    else if((budget as ProjectTaskTimeBudgetCustom).project_task_id != null) {
      const b:ProjectTaskTimeBudgetCustom = budget as ProjectTaskTimeBudgetCustom;
      return {
        disabled: b.time_in_seconds == null || b.time_in_seconds <= 0,
        start: b.time_in_seconds != null && b.time_in_seconds > 0 && b.time_registered_in_seconds_for_client <= b.time_in_seconds && b.completed_by_user_id == null && b.time_registered_in_seconds_for_client == 0,
        inprogress: b.time_in_seconds != null && b.time_in_seconds > 0 && b.time_registered_in_seconds_for_client <= b.time_in_seconds && b.completed_by_user_id == null && b.time_registered_in_seconds_for_client > 0,
        finished: b.time_in_seconds != null && b.time_in_seconds > 0 && b.time_registered_in_seconds_for_client <= b.time_in_seconds && b.completed_by_user_id != null,
        overtime: b.time_in_seconds != null && b.time_in_seconds > 0 && b.time_registered_in_seconds_for_client > b.time_in_seconds
      };
    }
  }

  getProgressSpinnerValueByBudget(budget:ProjectTaskBoardTimeBudgetCustom|ProjectTaskTimeBudgetCustom): number {
    if((budget as ProjectTaskBoardTimeBudgetCustom).project_task_board_id != null) {
      const b:ProjectTaskBoardTimeBudgetCustom = budget as ProjectTaskBoardTimeBudgetCustom;
      if(b.time_in_seconds != null && b.time_in_seconds >= 0) {
        const prcnt = b.time_registered_in_seconds_for_client / b.time_in_seconds;
        if(prcnt > 1 || prcnt == 0) return 100;
        else return prcnt * 100;
      }
      else return 100;
    }
    else if((budget as ProjectTaskTimeBudgetCustom).project_task_id != null) {
      const b:ProjectTaskTimeBudgetCustom = budget as ProjectTaskTimeBudgetCustom;
      if(b.time_in_seconds != null && b.time_in_seconds >= 0) {
        const prcnt = b.time_registered_in_seconds_for_client / b.time_in_seconds;
        if(prcnt > 1 || prcnt == 0) return 100;
        else return prcnt * 100;
      }
      else return 100;
    }
    else return 100;
  }

  private getProgressSpinnerClassListGeneral(): any {
    return {
      disabled: this.task.ro_time_estimation <= 0,
      start: this.task.ro_time_estimation > 0 && this.task.time_registered_in_seconds_for_client <= this.task.ro_time_estimation && !this.task.is_completed && this.task.time_registered_in_seconds_for_client == 0,
      inprogress: this.task.ro_time_estimation > 0 && this.task.time_registered_in_seconds_for_client <= this.task.ro_time_estimation && !this.task.is_completed && this.task.time_registered_in_seconds_for_client > 0,
      finished: this.task.ro_time_estimation > 0 && this.task.time_registered_in_seconds_for_client <= this.task.ro_time_estimation && this.task.is_completed,
      overtime: this.task.ro_time_estimation > 0 && this.task.time_registered_in_seconds_for_client > this.task.ro_time_estimation
    };
  }

  private getProgressSpinnerValueGeneral(): number {
    if(this.task.ro_time_estimation <= 0) return 100;
    else {
      const prcnt = this.task.time_registered_in_seconds_for_client / this.task.ro_time_estimation;
      if(prcnt > 1 || prcnt == 0) return 100;
      else return prcnt * 100;
    }
  }

  private getProgressSpinnerClassListSprint(): any {
    return {
      disabled: this.project_task_board!.ro_time_estimation <= 0,
      start: this.project_task_board!.ro_time_estimation > 0 && this.project_task_board!.time_registered_in_seconds_for_client <= this.project_task_board!.ro_time_estimation && !this.project_task_board!.is_completed && this.project_task_board!.time_registered_in_seconds_for_client == 0,
      inprogress: this.project_task_board!.ro_time_estimation > 0 && this.project_task_board!.time_registered_in_seconds_for_client <= this.project_task_board!.ro_time_estimation && !this.project_task_board!.is_completed && this.project_task_board!.time_registered_in_seconds_for_client > 0,
      finished: this.project_task_board!.ro_time_estimation > 0 && this.project_task_board!.time_registered_in_seconds_for_client <= this.project_task_board!.ro_time_estimation && this.project_task_board!.is_completed,
      overtime: this.project_task_board!.ro_time_estimation > 0 && this.project_task_board!.time_registered_in_seconds_for_client > this.project_task_board!.ro_time_estimation
    };
  }

  private getProgressSpinnerValueSprint(): number {
    if(this.project_task_board!.ro_time_estimation <= 0) return 100;
    else {
      const prcnt = this.project_task_board!.time_registered_in_seconds_for_client / this.project_task_board!.ro_time_estimation;
      if(prcnt > 1 || prcnt == 0) return 100;
      else return prcnt * 100;
    }
  }

  getProgressSpinnerDistributionTableValue(body:ProjectTaskDistributionTableColumn) {
    if(body.time_to_do <= 0) return 100;
    else {
      const prcnt = body.time_done / body.time_to_do;
      if(prcnt > 1 || prcnt == 0) return 100;
      else return prcnt * 100;
    }
  }

  getProgressSpinnerDistributionTableClassList(body:ProjectTaskDistributionTableColumn) {
    return {
      disabled: body.time_to_do <= 0,
      start: body.time_to_do > 0 && body.time_done <= body.time_to_do && !body.completed && body.time_done == 0,
      inprogress: body.time_to_do > 0 && body.time_done <= body.time_to_do && !body.completed && body.time_done > 0,
      finished: body.time_to_do > 0 && body.time_done <= body.time_to_do && body.completed,
      overtime: body.time_to_do > 0 && body.time_done > body.time_to_do
    };
  }

  markCategoryAsActive(idx:number) {
    const time_budget = this.project_task_board!.project_task_board_time_budgets[idx];
    this.api.updateProjectTaskBoard(this.project_task_board!.id, { active_project_task_board_time_budget_id: time_budget.id }).subscribe(
      data => {
        this.project_task_board!.active_project_task_board_time_budget_id = time_budget.id;
        this.project_task_board!.active_project_task_board_time_budget = time_budget;
        this.initSpinnerData
      }
    );
  }

  markCategoryAsInactive(idx:number) {
    const time_budget = this.project_task_board!.project_task_board_time_budgets[idx];
    this.api.updateProjectTaskBoard(this.project_task_board!.id, { active_project_task_board_time_budget_id: null }).subscribe(
      data => {
        this.project_task_board!.active_project_task_board_time_budget_id = undefined;
        this.project_task_board!.active_project_task_board_time_budget = undefined;
        this.initSpinnerData
      }
    );
  }

  markCategoryAsCompleted(time_budget:ProjectTaskBoardTimeBudgetCustom) {
    this.api.updateProjectTaskBoardTimeBudget(time_budget.id, { completed_by_user_id: this.me.id }).subscribe(
      data => {
        time_budget.completed_by_user_id = this.me.id;
        this.initSpinnerData
      }
    );
  }

  markCategoryAsIncomplete(time_budget:ProjectTaskBoardTimeBudgetCustom) {
    this.api.updateProjectTaskBoardTimeBudget(time_budget.id, { completed_by_user_id: null }).subscribe(
      data => {
        time_budget.completed_by_user_id = undefined;
        this.initSpinnerData
      }
    );
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.allStringTagsCategories.filter(fruit => fruit.toLowerCase().includes(filterValue));
  }

  private _filterListeners(value: string): User[] {
    const filterValue = value.toLowerCase();

    return Object.values(this.all_team_users).filter(
      user => (user.name + ' ' + user.surnames).toLowerCase().includes(filterValue)
        && !this.task.project_task_listeners.find((w:User) => (w.id) === (user.id)));
  }

  removeListener(listener:User) {
    this.api.unlistenTask(this.task.id, listener.id).subscribe(
      data => {
        const idx = this.task.project_task_listeners.findIndex(l => l.id == listener.id);
        if(idx >= 0) {
          this.task.project_task_listeners.splice(idx, 1);
        }
        if(listener.id == this.me.id) {
          this.imFollowingTask = false;
        }
      }
    );
  }

  addListener(user:User) {
    this.api.listenTask(this.task.id, user!.id).subscribe(
      data => {
        this.task.project_task_listeners.push(user)
        this.listenersCntrl.setValue(null);

        if(user.id == this.me.id) {
          this.imFollowingTask = true;
        }
      }
    );
  }

  getAssigneeFromBudget(user:User|null): User {
    return user as User;
  }

  private getBudgetableName(budgetable:WorkingTimeCategory|ProjectTeam): string {
    if((budgetable as WorkingTimeCategory).short_name != null) return (budgetable as WorkingTimeCategory).short_name;
    else return budgetable.name;
  }

  getBudgetableSlug(budgetable:WorkingTimeCategory|ProjectTeam) {
    if((budgetable as WorkingTimeCategory).slug != null) return (budgetable as WorkingTimeCategory).slug;
    else return '';
  }

  openBudgetDetailDialog() {
    const dialogRef = this.dialog.open(ProjectTaskBudgetDetailDialogComponent, {
      width: '1000px',
      data: {
        data: this.distribution_table,
        project_task: this.task,
        project_id: this.project_id
      }
    });

    dialogRef.afterClosed().subscribe(
      (data:ProjectTaskDistribution) => {
        if(data != null) {
          this.loadFullTaskData();
        }
      }
    );
  }

  setQuickTaskTime(type:'project-task-board'|'project-task' = 'project-task-board') {
    const time:string|null = prompt("Introduce el tiempo de tarea (formato estilo '1h 30m'). Nota: Asume un rendimiento del 100%, sin revisión, sin horas de descanso, sin documentación, sin testing, sin horas extras.");
    if(time) {
      const time_in_seconds:number = this.hourStringToSecondsPipe.transform(time);
      if(time_in_seconds > 0) {
        let promise:any;
        if(type === 'project-task-board') {
          promise = this.api.setProjectTaskBoardTimeEstimation(this.project_task_board!.id, time_in_seconds);
        }
        else if(type === 'project-task') {
          promise = this.api.setProjectTaskTimeEstimation(this.task.id, time_in_seconds)
        }

        if(promise != undefined) {
          promise.subscribe(
            (data:any) => {
              this.loadFullTaskData();
            }
          );
        }
      }
    }
  }

  deleteTaskBudget(time_budget:ProjectTaskBoardTimeBudget) {
    if(confirm(`¿Estás seguro que quieres que no se va a realizar trabajo de "${time_budget.budgetable.name}" en este board?`)) {
      this.api.deleteProjectTaskBoardTimeBudget(time_budget.id).subscribe(
        data => {
          this.loadFullTaskData();
        }
      );
    }
  }

  private initPMorPO() {
    this.isProductManager = this.permissions.isWorkerWithProjectRole(this.project_id.toString(), 'product-manager');
    this.isProjectManagerOrProductManager = this.permissions.isWorkerWithProjectRole(this.project_id.toString(), 'project-manager') || this.permissions.isWorkerWithProjectRole(this.project_id.toString(), 'product-manager');
    this.imDeveloping = this.permissions.isProductionTeam(this.project_id.toString());
    this.isClientDeveloper = this.permissions.isClientDeveloper();
    this.isClientBusiness = this.permissions.isClientBusiness();
  }

  private initCurrentBoardAttribute() {
    const project_phase_board_id:number|string = this.data.project_phase_board_id;
    if(typeof project_phase_board_id !== 'string') { // no és 'backlog'
      this.api.getProjectPhaseBoard(project_phase_board_id, {with: 'project_phase_board_cols'}).subscribe(
        data => {
          this.currentBoard = data;
        }
      );
    }
  }

  private initProjectTaskBoardAttribute() {
    const project_phase_board_id:number|string = this.data.project_phase_board_id;
    if(typeof project_phase_board_id !== 'string') {
      // busquem el task board dintre task
      for(let project_task_board of this.task.project_task_boards) {
        if(project_task_board.project_phase_board_col.project_phase_board_id == project_phase_board_id) {
          this.project_task_board = project_task_board;
          break;
        }
      }
    }
  }

  private checkInputData() {
    if(this.data == null) {
      this.snack.open("No se ha proporcionado los inputs necesarios. Cerrando popup...", "Ok", { duration: 5000 });
      this.close();
    }
    if(this.data.task_code_id == null) {
      this.snack.open("No se ha proporcionado el parámetro task_id. Cerrando popup...", "Ok", { duration: 5000 });
      this.close();
    }
    if(this.data.project_phase_board_id == null) {
      this.snack.open("No se ha proporcionado el parámetro project_phase_board_id. Cerrando popup...", "Ok", { duration: 5000 });
      this.close();
    }
    if(this.data.project_id == null) {
      this.snack.open("No se ha proporcionado el parámetro project_id. Cerrando popup...", "Ok", { duration: 5000 });
      this.close();
    }
  }

  private onGetTaskData(data:ProjectTask) {
    this.task = data as ProjectTaskCustom;
    this.fetchTaskTagCategories();
    this.initPMorPO();
    this.initCurrentBoardAttribute();
    this.processWorkingTimes();
    this.processBudgets();
    this.initProjectTaskBoardAttribute();
    this.processProjectUserMembers();
    this.isTaskLoaded = true;
    this.imFollowingTask = this.task.project_task_listeners.find(u => u.id == this.me.id) !== undefined;
    this.initDistributionTable();
    this.initSpinnerData();
  }

  private processWorkingTimes() {
    // inicialitzem
    this.task.time_registered_in_seconds_for_client = 0;
    this.task.time_registered_in_seconds_real = 0;
    this.task.ro_working_times = [];
    for(let budget of this.task.project_task_time_budgets) {
      budget.time_registered_in_seconds_for_client = 0;
      budget.time_registered_in_seconds_real = 0;
    }

    // processem
    for(let project_task_board of this.task.project_task_boards) {
      project_task_board.time_registered_in_seconds_for_client = 0;
      project_task_board.time_registered_in_seconds_real = 0;

      for(let budget of project_task_board.project_task_board_time_budgets) {
        budget.time_registered_in_seconds_for_client = 0;
        budget.time_registered_in_seconds_real = 0;

        const general_budget:ProjectTaskTimeBudgetCustom|undefined = this.task.project_task_time_budgets.find(b => b.budgetable_id===budget.budgetable_id && b.budgetable_type===budget.budgetable_type);

        budget.working_times.forEach(wt => {
          budget.time_registered_in_seconds_for_client += wt.duration_in_seconds_for_client;
          budget.time_registered_in_seconds_real += wt.duration_in_seconds_real;

          project_task_board.time_registered_in_seconds_for_client += wt.duration_in_seconds_for_client;
          project_task_board.time_registered_in_seconds_real += wt.duration_in_seconds_real;

          this.task.time_registered_in_seconds_for_client += wt.duration_in_seconds_for_client;
          this.task.time_registered_in_seconds_real += wt.duration_in_seconds_real;

          if(general_budget != null) {
            general_budget.time_registered_in_seconds_for_client += wt.duration_in_seconds_for_client;
            general_budget.time_registered_in_seconds_real += wt.duration_in_seconds_real;

            if(general_budget.completed_by_user_id == null && budget.completed_by_user_id != null) general_budget.completed_by_user_id = budget.completed_by_user_id;
          }

          this.task.ro_working_times.push(wt);
      });
      }
    }
  }

  private processBudgets() {
    this.task.is_completed = true;
    for(let board of this.task.project_task_boards) {
      board.is_completed = true;
      for(let budget of board.project_task_board_time_budgets) {
        budget.project_task_time_budget = this.task.project_task_time_budgets.find(b => b.budgetable_type === budget.budgetable_type && b.budgetable_id === budget.budgetable_id)

        this.task.is_completed = this.task.is_completed && budget.completed_by_user_id != null;
        board.is_completed = board.is_completed && budget.completed_by_user_id != null;
      }
    }
  }

  private async initDistributionTable() {
    this.distribution_table = {} as ProjectTaskDistribution;
    const budget_categories:ProjectTaskTimeBudgetCustom[] = this.task.project_task_time_budgets;

    // general
    const general_categories = budget_categories.map(time_budget => {
      return {
        title: this.getBudgetableName(time_budget.budgetable),
        time_to_do: time_budget.time_in_seconds,
        time_done: time_budget.time_registered_in_seconds_for_client,
        completed: time_budget.completed_by_user_id != null,
        editable: true,
        budgetable_type: time_budget.budgetable_type as "App\\Models\\WorkingTimeCategory"|"App\\Models\\ProjectTeam"|undefined,
        budgetable_id: time_budget.budgetable_id as number|undefined,
        budgetable_slug: (time_budget.budgetable as WorkingTimeCategory).slug != null ? (time_budget.budgetable as WorkingTimeCategory).slug : undefined,
      };
    });
    general_categories.unshift({
      title: 'General',
      time_to_do: this.task.ro_time_estimation,
      time_done: this.task.time_registered_in_seconds_for_client,
      completed: this.task.is_completed,
      editable: false,
      budgetable_type: undefined,
      budgetable_id: undefined,
      budgetable_slug: undefined
    });
    this.distribution_table.general = {
      title: 'General',
      from: undefined,
      to: undefined,
      bold_title: true,
      categories: general_categories,
      project_phase_board_id: null,
      status: null
    };

    // asignado
    const asignado_categories = budget_categories.map(time_budget => {
      return {
        title: this.getBudgetableName(time_budget.budgetable),
        time_to_do: 0, // numero d'hores que s'ha de fer per cada categoria als sprints o al llarg dels sprints (Time Budget)
        time_done: 0, // numero d'hores assignades per cada categoria al llarg dels sprints (sumatori)
        completed: false,
        editable: false,
        budgetable_type: time_budget.budgetable_type as "App\\Models\\WorkingTimeCategory"|"App\\Models\\ProjectTeam"|undefined,
        budgetable_id: time_budget.budgetable_id as number|undefined,
        budgetable_slug: (time_budget.budgetable as WorkingTimeCategory).slug != null ? (time_budget.budgetable as WorkingTimeCategory).slug : undefined
      };
    });
    asignado_categories.unshift({
      title: 'General',
      time_to_do: 0,
      time_done: 0,
      completed: false,
      editable: false,
      budgetable_type: undefined,
      budgetable_id: undefined,
      budgetable_slug: undefined
    });
    this.distribution_table.assigned = {
      title: 'Asignado',
      from: undefined,
      to: undefined,
      bold_title: true,
      categories: asignado_categories,
      project_phase_board_id: null,
      status: null
    };

    // sprints
    this.distribution_table.boards = [];
    const boards = this.task.project_phase.project_phase_boards;
    if(this.project_task_board != null && this.project_task_board.project_phase_board_col != null) {
      const found = boards.find(board => board.id == this.project_task_board?.project_phase_board_col.project_phase_board_id);
      if(!found) {
        const board = await this.api.getProjectPhaseBoard(this.project_task_board.project_phase_board_col.project_phase_board_id).toPromise();
        if(board) {
          boards.push(board);
        }
      }
    }

    boards.sort((a,b) => {
      if(a.start_date == undefined) return 0;
      else if(b.start_date == undefined) return 1;
      else return a.start_date < b.start_date ? 1 : 0;
    });

    boards.forEach(board => {
      const project_task_board:ProjectTaskBoardCustom|undefined = this.task.project_task_boards.find(b => b.project_phase_board_col.project_phase_board_id == board.id);
      const categories = budget_categories.map(time_budget => {
        const project_task_board_time_budget:ProjectTaskBoardTimeBudgetCustom|undefined = project_task_board != null ? project_task_board.project_task_board_time_budgets.find(ptbtb => ptbtb.budgetable_type === time_budget.budgetable_type && ptbtb.budgetable_id === time_budget.budgetable_id) : undefined;
        return {
          title: this.getBudgetableName(time_budget.budgetable),
          time_to_do: project_task_board_time_budget != null && project_task_board_time_budget.time_in_seconds != null ? project_task_board_time_budget.time_in_seconds : 0,
          time_done: project_task_board_time_budget != null ? project_task_board_time_budget.time_registered_in_seconds_for_client : 0,
          completed: project_task_board_time_budget != null && project_task_board_time_budget.completed_by_user_id != null,
          editable: true,
          budgetable_type: time_budget.budgetable_type as "App\\Models\\WorkingTimeCategory"|"App\\Models\\ProjectTeam"|undefined,
          budgetable_id: time_budget.budgetable_id as number|undefined,
          budgetable_slug: (time_budget.budgetable as WorkingTimeCategory).slug != null ? (time_budget.budgetable as WorkingTimeCategory).slug : undefined
        };
      });
      categories.unshift({
        title: 'General',
        time_to_do: project_task_board?.ro_time_estimation ?? 0,
        time_done: project_task_board?.time_registered_in_seconds_for_client ?? 0,
        completed: project_task_board != null && project_task_board.completed_by_user_id != null,
        editable: false,
        budgetable_type: undefined,
        budgetable_id: undefined,
        budgetable_slug: undefined
      });
      this.distribution_table.boards.push({
        title: board.title ?? 'Tablero #' + board.id,
        from: board.start_date,
        to: board.end_date,
        bold_title: false,
        categories: categories,
        project_phase_board_id: board.id,
        status: board.status
      });
    });
  }

  private getGeneralTaskSpinnerData(): SpinnerData {
    return {
      tooltip: 'Se han registrado un total de ' + this.secondsToStringTime.transform(this.task.time_registered_in_seconds_for_client) + ' de ' + this.secondsToStringTime.transform(this.task.ro_time_estimation) + ' estimadas para el total de la tarea.',
      class: this.getProgressSpinnerClassListGeneral(),
      value: this.getProgressSpinnerValueGeneral(),
      text: this.decimal.transform(this.task.time_registered_in_seconds_for_client/3600,'1.0-0') + '/' + this.decimal.transform(this.task.ro_time_estimation/3600,'1.0-0'),
      can_register: false,
      can_set_quick_task_time: this.isProjectManagerOrProductManager && this.task.project_task_boards.length == 0 ? 'project-task' : undefined
    };
  }

  private getGeneralCategorySpinnerData(time_budget:ProjectTaskTimeBudgetCustom): SpinnerData {
    return {
      tooltip: 'Se han registrado en ' + this.getBudgetableName(time_budget.budgetable) + ' ' + this.secondsToStringTime.transform(time_budget.time_registered_in_seconds_for_client) + ' ' + (time_budget.time_in_seconds > 0 ? (' de ' + this.secondsToStringTime.transform(time_budget.time_in_seconds) + ' estimadas para el total de la tarea.') : '.'),
      class: this.getProgressSpinnerClassListByBudget(time_budget),
      value: this.getProgressSpinnerValueByBudget(time_budget),
      text: this.decimal.transform(time_budget.time_registered_in_seconds_for_client/3600,'1.0-0') + '/' + this.decimal.transform(time_budget.time_in_seconds != null ? time_budget.time_in_seconds/3600 : 0,'1.0-0'),
      can_register: false,
      can_set_quick_task_time: undefined
    };
  }

  private initSpinnerData() {
    this.board_general_estimation_template_data = this.board_category_estimation_template_data = this.general_estimation_template_data = this.task_category_estimation_template_data = undefined;

    if(this.project_task_board != null) {

      this.board_general_estimation_template_data = {
        title: "General",
        project_task_board_time_budget: undefined,
        idx: undefined,
        assignee: undefined,
        show_assignee: false,
        available_assignees: [],
        show_delete_time_budget: false,
        spinners: [
          {
            tooltip: 'Se han registrado en total de ' + this.secondsToStringTime.transform(this.project_task_board!.time_registered_in_seconds_for_client) + ' de ' + this.secondsToStringTime.transform(this.project_task_board!.ro_time_estimation) + ' estimadas para este tablero.',
            class: this.getProgressSpinnerClassListSprint(),
            value: this.getProgressSpinnerValueSprint(),
            text: this.decimal.transform(this.project_task_board!.time_registered_in_seconds_for_client/3600,'1.0-0') + '/' + this.decimal.transform(this.project_task_board!.ro_time_estimation/3600,'1.0-0'),
            can_register: false,
            can_set_quick_task_time:
              (
                this.project_task_board.project_phase_board_col.project_phase_board_col_role.slug === "todo" &&
                (this.isProjectManagerOrProductManager || (this.permissions.isClientDeveloper() && this.project_task_board.project_phase_board_col.project_phase_board.can_client_dev_manage_board))
              ) ? 'project-task-board' : undefined
          },
          this.getGeneralTaskSpinnerData()
        ]
      };

      this.board_category_estimation_template_data = this.project_task_board.project_task_board_time_budgets.map((time_budget, idx) => {
        const can_register:boolean = time_budget.completed_by_user_id == null && (
          ( // Sóc desenvolupador del client
            time_budget.budgetable_type === "App\\Models\\ProjectTeam" &&
            this.permissions.isDeveloperInProjectTeam(time_budget.budgetable_id)
          ) ||
          ( // o la categoria és review time
            time_budget.budgetable_type === "App\\Models\\WorkingTimeCategory" &&
            (time_budget.budgetable as WorkingTimeCategory).slug === "review-task" &&
            this.permissions.isProductionTeam(this.project_id.toString())
          ) ||
          ( // sóc desenvolupador de Bisual amb permissos per registrar
            time_budget.budgetable_type === "App\\Models\\WorkingTimeCategory" &&
            this.permissions.isEmployeeInProject(this.project_id.toString())
          ));

        const spinners:SpinnerData[] = [];

        // afegim el de sprint
        spinners.push({
          tooltip: 'Se han registrado en ' + this.getBudgetableName(time_budget.budgetable) + ' ' + this.secondsToStringTime.transform(time_budget.time_registered_in_seconds_for_client) + ' ' + (time_budget.time_in_seconds != null ? (' de ' + this.secondsToStringTime.transform(time_budget.time_in_seconds) + ' estimadas para este tablero.') : '.'),
          class: this.getProgressSpinnerClassListByBudget(time_budget),
          value: this.getProgressSpinnerValueByBudget(time_budget),
          text: this.decimal.transform(time_budget.time_registered_in_seconds_for_client/3600,'1.0-0') + '/' + this.decimal.transform((time_budget.time_in_seconds != null ? time_budget.time_in_seconds/3600 : 0),'1.0-0'),
          can_register: can_register,
          can_set_quick_task_time: undefined
        });

        // afegim el de general
        if(time_budget.project_task_time_budget != null) {
          spinners.push(this.getGeneralCategorySpinnerData(time_budget.project_task_time_budget));
        }

        const show_delete_time_budget: boolean =
          ((time_budget.budgetable as WorkingTimeCategory).is_working_time == null || (time_budget.budgetable as WorkingTimeCategory).slug !== "review-task") &&
          time_budget.assignee == null &&
          (time_budget.time_in_seconds == null || time_budget.time_in_seconds == 0) &&
          time_budget.time_registered_in_seconds_for_client == 0;

        return {
          title: this.getBudgetableName(time_budget.budgetable),
          project_task_board_time_budget: time_budget,
          idx: idx,
          assignee: time_budget.assignee_id != null ? this.production_team_users.find(u => u.id == time_budget.assignee_id) : null,
          show_assignee: true,
          available_assignees: this.production_team_users.filter(user => {
            if(time_budget.budgetable_type === "App\\Models\\WorkingTimeCategory") {
              if((time_budget.budgetable as WorkingTimeCategory).slug === "review-task") return true;
              else {
                // potser fer un possible filtre per mapejar rols i categories...
                return user.role.slug === "worker";
              }
            }
            else {
              const team:ProjectTeam|undefined = this.project_teams[time_budget.budgetable_id];
              if(team) {
                return team.users.find(team_user => team_user.id === user.id);
              }
              else return false;
            }
          }),
          can_register: can_register,
          show_delete_time_budget: show_delete_time_budget,
          spinners: spinners
        } as SpinnerGroupData;
      });
    }
    else {
      this.general_estimation_template_data = {
        title: "General",
        project_task_board_time_budget: undefined,
        idx: undefined,
        assignee: undefined,
        show_assignee: false,
        available_assignees: [],
        show_delete_time_budget: false,
        spinners: [ this.getGeneralTaskSpinnerData() ]
      };

      this.task_category_estimation_template_data = this.task.project_task_time_budgets.map((time_budget, idx) => {
        return {
          title: this.getBudgetableName(time_budget.budgetable),
          project_task_board_time_budget: undefined,
          idx: idx,
          assignee: undefined,
          show_assignee: false,
          spinners: [ this.getGeneralCategorySpinnerData(time_budget) ]
        } as SpinnerGroupData;
      });
    }
  }

  private processProjectUserMembers() {
    if(this.project_task_board != null) {
      this.project_task_board.project_task_board_time_budgets.forEach(time_budget => {
        if(time_budget.assignee != null) {
          const user = this.production_team_users.find(up => up.id == time_budget.assignee_id);
          if(user) {
            time_budget.assignee.project_user_member = user.project_user_member;
          }
        }
      })
    }
  }

  private fetchProjectTeams() {
    this.api.getProjectProjectTeams(this.project_id.toString(), {with: 'users'}).subscribe(
      data => {
        data.forEach(team => {
          this.project_teams[team.id] = team;
        })
      }
    );
  }
}

interface SpinnerGroupData {
  title: string;
  project_task_board_time_budget: ProjectTaskBoardTimeBudgetCustom|undefined;
  idx:number|undefined;
  assignee: User|undefined;
  show_assignee: boolean;
  available_assignees:User[];
  show_delete_time_budget: boolean;
  spinners:SpinnerData[];
}

interface SpinnerData {
  tooltip: string|undefined
  class: any
  value: number
  text: string
  can_register: boolean
  can_set_quick_task_time: 'project-task'|'project-task-board'|undefined;
}


interface ProjectTaskAttachmentCustom extends ProjectTaskAttachment {
  file_path_sanitized: string;
}

interface ProjectTaskCustom extends ProjectTask {
  attachments: ProjectTaskAttachmentCustom[];
  project_task_boards:ProjectTaskBoardCustom[];
  time_registered_in_seconds_for_client: number;
  time_registered_in_seconds_real: number;
  ro_working_times:WorkingTime[];
  project_task_time_budgets:ProjectTaskTimeBudgetCustom[];
  is_completed:boolean;
}

interface ProjectTaskBoardTimeBudgetCustom extends ProjectTaskBoardTimeBudget {
  time_registered_in_seconds_real: number;
  time_registered_in_seconds_for_client: number;
  project_task_time_budget:ProjectTaskTimeBudgetCustom|undefined;
  assignee:UserProject|null;
}

interface ProjectTaskBoardCustom extends ProjectTaskBoard {
  project_task_board_time_budgets: ProjectTaskBoardTimeBudgetCustom[];
  time_registered_in_seconds_real: number;
  time_registered_in_seconds_for_client: number;
  is_completed:boolean;
}

interface ProjectTaskTimeBudgetCustom extends ProjectTaskTimeBudget {
  time_registered_in_seconds_real: number;
  time_registered_in_seconds_for_client: number;
  completed_by_user_id: number|undefined;
}

interface UserProject extends User {
  project_user_member:ProjectUserMember|undefined;
  client_role:"developer"|"business";
}

export interface ProjectTaskDistributionTableColumn {
  title: string;
  time_to_do: number;
  time_done: number;
  completed: boolean;
  editable: boolean;
  budgetable_type:"App\\Models\\WorkingTimeCategory"|"App\\Models\\ProjectTeam"|undefined;
  budgetable_id:number|undefined;
  budgetable_slug: string|undefined;
}

export interface ProjectTaskDistributionTableRow {
  title:string;
  project_phase_board_id: number|string|null;
  from:Date|undefined;
  to:Date|undefined;
  bold_title:boolean;
  categories:ProjectTaskDistributionTableColumn[];
  status?: "finished"|"active"|"not_started"|null;
}

export interface ProjectTaskDistribution {
  general: ProjectTaskDistributionTableRow;
  assigned: ProjectTaskDistributionTableRow;
  boards: ProjectTaskDistributionTableRow[];
}
