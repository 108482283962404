import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-billing-contasimple-dashboard',
  templateUrl: './billing-contasimple-dashboard.component.html',
  styleUrls: ['./billing-contasimple-dashboard.component.css']
})
export class BillingContasimpleDashboardComponent implements OnInit {

  filterForm:UntypedFormGroup;
  private formPersistence:any;
  periods:string[];

  contasimple_data:any = {};

  constructor(private api:ApiService,
              private utils:UtilsService,
              private fb:UntypedFormBuilder,
              private activatedRoute:ActivatedRoute,
              private router:Router) {
    this.filterForm = this.fb.group({
      period: [this.activatedRoute.snapshot.queryParamMap.get('period')!=null ? (this.activatedRoute.snapshot.queryParamMap.get('period') as any) : this.getCurrentPeriod()],
    });

    this.periods = [this.filterForm.value['period']];
  }

  ngOnInit(): void {
    this.fetchPeriods();
    this.initFilterFormListener();
    this.listenQueryParameters();
  }

  private getCurrentPeriod() {
    const date:Date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth()+1;
    const quarter = month < 10 ? (month < 7 ? (month < 4 ? 1 : 2) : 3) : 4;
    const period = year + "-" + quarter + "T";
    return period;
  }

  private fetchPeriods() {
    this.api.getBillingPeriods().subscribe(
      data => {
        this.periods = data.filter(period => period.indexOf("/")==-1);
      }
    );
  }

  private initFilterFormListener() {
    this.filterForm.valueChanges.subscribe(
      data => {
        if(this.formPersistence==null || JSON.stringify(this.formPersistence)!=JSON.stringify(data)) {
          this.router.navigate(['/employees', 'direction', 'billing', 'contasimple'], { queryParams: data });
        }
      }
    );
  }

  private listenQueryParameters() {
    this.activatedRoute.queryParams.subscribe(
      params => {
        if(JSON.stringify(params)!==JSON.stringify(this.filterForm.value)) { //si no ve de filtre s'ha de setejar el form
          let params_temp = this.utils.cloneObj(params); //params es read_only
          Object.keys(params_temp).forEach(param_key => {
            if(params_temp[param_key]!=null && params_temp[param_key]!="" && !isNaN(+params_temp[param_key])) params_temp[param_key] = +params_temp[param_key]; // si es numero, el transformem
          });
          this.filterForm.patchValue(params_temp, { emitEvent: false });
        }
        this.formPersistence = params;
        this.fetchContasimpleData();
      }
    );
  }

  private fetchContasimpleData() {
    this.api.getContasimpleResume(this.filterForm.value['period']).subscribe(
      data => {
        this.contasimple_data = data;
      }
    );
  }

}
