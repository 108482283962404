<div class="page-title">
  <h1>{{ business_mail!=null ? business_mail.email : 'Cargando...' }}</h1>
</div>

<h2>Pedidos de clientes</h2>
<p>Pedidos que han supuesto un <b>ingreso</b> para Bisual.</p>
@if (business_mail != null) {
  <div class="bisual-table">
    <div class="bisual-table-container">
      <table mat-table [dataSource]="ordersDataSource">
        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef>Fecha</th>
          <td mat-cell *matCellDef="let element">{{element.created_at | date:'medium'}}</td>
        </ng-container>
        <ng-container matColumnDef="total">
          <th mat-header-cell *matHeaderCellDef>Importe</th>
          <td mat-cell *matCellDef="let element">{{element.total | currency}}</td>
        </ng-container>
        <ng-container matColumnDef="payment_status">
          <th mat-header-cell *matHeaderCellDef>Estado</th>
          <td mat-cell *matCellDef="let element">{{element.payment.payed_at!=null ? 'Pagado' : 'No pagado'}}</td>
        </ng-container>
        <ng-container matColumnDef="invoice_number">
          <th mat-header-cell *matHeaderCellDef>Núm. factura</th>
          <td mat-cell *matCellDef="let element">#{{ element.invoice.invoice_number }}</td>
        </ng-container>
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element">
            <button mat-icon-button [matMenuTriggerFor]="menu">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item [routerLink]="['/employees', 'direction', 'billing', 'issued', element.id]" [disabled]="!permissions.validateDepartment(['direction', 'finance'])">
                <mat-icon matPrefix>visibility</mat-icon>
                Ver pedido
              </button>
              @if (element.invoice.contasimple_id != null) {
                <button mat-menu-item (click)="downloadOrderInvoice(element)" [disabled]="!permissions.validateDepartment(['direction', 'finance'])">
                  <mat-icon matPrefix>file_download</mat-icon>
                  Descargar factura
                </button>
              }
              @if (element.total > 0 && element.payment.payed_at!=null && element.payment.has_stripe_identifier && element.refunded_by_id==null) {
                <button mat-menu-item (click)="refund(element)" [disabled]="!permissions.validateDepartment(['direction', 'finance'])">
                  <mat-icon matPrefix>restart_alt</mat-icon>
                  Devolver pago
                </button>
              }
            </mat-menu>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
  </div>
}

<h2>Pedidos de proveedores</h2>
<p>Pedidos que han supuesto un <b>gasto</b> para Bisual.</p>
@if (business_mail != null) {
  <div class="bisual-table">
    <div class="bisual-table-container">
      <table mat-table [dataSource]="providerOrdersDataSource">
        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef>Fecha</th>
          <td mat-cell *matCellDef="let element">{{element.created_at | date:'medium'}}</td>
        </ng-container>
        <ng-container matColumnDef="total">
          <th mat-header-cell *matHeaderCellDef>Importe</th>
          <td mat-cell *matCellDef="let element">{{element.total | currency}}</td>
        </ng-container>
        <ng-container matColumnDef="payment_status">
          <th mat-header-cell *matHeaderCellDef>Estado</th>
          <td mat-cell *matCellDef="let element">{{ element.refunded_by_id!=null ? 'Devuelto' : element.payment.payed_at!=null ? 'Pagado' : 'No pagado'}}</td>
        </ng-container>
        <ng-container matColumnDef="invoice_number">
          <th mat-header-cell *matHeaderCellDef>Núm. factura</th>
          <td mat-cell *matCellDef="let element">#{{ element.invoice.invoice_number }}</td>
        </ng-container>
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element">
            <button mat-icon-button [matMenuTriggerFor]="providerMenu">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #providerMenu="matMenu" [routerLink]="['/employees', 'direction', 'billing', 'received', element.id]">
              <button mat-menu-item [disabled]="!permissions.validateDepartment(['direction', 'finance'])">
                <mat-icon matPrefix>visibility</mat-icon>
                Ver pedido
              </button>
              @if (element.invoice.contasimple_id != null) {
                <button mat-menu-item (click)="openProviderOrderFilesDialog(element)" [disabled]="!permissions.validateDepartment(['direction', 'finance'])">
                  <mat-icon matPrefix>description</mat-icon>
                  Ver archivos
                </button>
              }
            </mat-menu>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
  </div>
}
