import { Component, OnInit } from '@angular/core';
import { ProviderOrderAlert } from 'src/app/models/alert.model';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-billing',
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.css']
})
export class BillingComponent implements OnInit {

  navLinks: any[] = [
    {
      label: 'Resumen',
      link: './resume',
      index: 0
    },
    {
      label: 'Ingresos',
      link: './orders',
      index: 1
    },
    {
      label: 'Gastos',
      link: './provider-orders',
      index: 2
    },
    {
      label: 'Contasimple',
      link: './contasimple',
      index: 3
    },
    {
      label: 'Alertas',
      link: './alerts',
      index: 4
    },
    {
      label: 'Planificación',
      link: './planning',
      index: 5
    }
  ];

  constructor() { }

  ngOnInit(): void {

  }

}
