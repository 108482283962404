import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { PotentialClient } from 'src/app/models/potential-client.interface';
import { ApiService } from 'src/app/services/api.service';
import { AddPotentialClientDialogComponent } from '../add-potential-client-dialog/add-potential-client-dialog.component';
import { PotentialClientChangeStatusDialogComponent } from './potential-client-change-status-dialog/potential-client-change-status-dialog.component';

@Component({
  selector: 'app-potential-client-detail',
  templateUrl: './potential-client-detail.component.html',
  styleUrls: ['./potential-client-detail.component.css']
})
export class PotentialClientDetailComponent implements OnInit {

  potential_client_id:string = null as any;
  potential_client:PotentialClient = null as any;

  addCommentFormGroup:FormGroup;
  queryParamBackObject:any = {};

  constructor(private activatedRoute:ActivatedRoute,
              private api:ApiService,
              private fb:FormBuilder,
              private dialog:MatDialog) {
    this.potential_client_id = this.activatedRoute.snapshot.paramMap.get('id') as string;

    this.addCommentFormGroup = this.fb.group({
      potential_client_id: [this.potential_client_id, Validators.required],
      content: ['', Validators.compose([Validators.required, Validators.minLength(10)])]
    });
  }

  ngOnInit(): void {
    this.fetchPotentialClient();
    this.readBackParameter();
  }

  addComment() {
    this.api.createPotentialClientComment(this.addCommentFormGroup.value).subscribe(
      data => {
        this.fetchPotentialClient();
        this.addCommentFormGroup.patchValue({content: null});
        this.addCommentFormGroup.markAsUntouched();
      }
    );
  }

  openChangeStatusDialog() {
    const dialogRef = this.dialog.open(PotentialClientChangeStatusDialogComponent, {
      width: '700px',
      data: {
        potential_client: this.potential_client
      }
    });

    dialogRef.afterClosed().subscribe(
      data => {
        if(data != null) {
          this.api.updatePotentialClient(this.potential_client_id, data).subscribe(
            data => {
              this.fetchPotentialClient();
            }
          );
        }
      }
    );
  }

  openEditDialog() {
    const dialogRef = this.dialog.open(AddPotentialClientDialogComponent, {
      data: {
        potential_client: this.potential_client
      },
      width: '700px'
    });

    dialogRef.afterClosed().subscribe(
      data => {
        if(data != null) {
          this.api.updatePotentialClient(this.potential_client_id, data).subscribe(
            data => {
              this.fetchPotentialClient();
            }
          );
        }
      }
    );

  }

  private fetchPotentialClient() {
    this.api.getPotentialClient(this.potential_client_id, { with: 'meeting_records,potential_client_status,potential_client_status_change_records,commercial,product_owner,potential_client_comments' }).subscribe(
      data => {
        this.potential_client = data;
      }
    );
  }

  private readBackParameter() {
    const back_string_param = this.activatedRoute.snapshot.queryParamMap.get('b');
    if(back_string_param != null) {
      this.queryParamBackObject = JSON.parse(back_string_param);
    }
  }

}
