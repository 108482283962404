<h1 mat-dialog-title>{{ 'Cambiar de estado' }}</h1>

<mat-dialog-content [formGroup]="form">
  <p>Estado actual: {{ current_potential_client.potential_client_status.name }}</p>
  <div class="container">
    <div class="row">
      <div class="col-12 col-lg-6">
        <mat-form-field appearance="outline">
          <mat-label>Nuevo estado</mat-label>
          <mat-select matInput formControlName="potential_client_status_slug">
            @for (item of potential_client_statuses; track item) {
              <mat-option [value]="item.slug" [disabled]="item.slug === current_potential_client.potential_client_status_slug">{{ item.name }}</mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button (click)="close()" type="button">Cancelar</button>
  <button mat-flat-button color="accent" [mat-dialog-close]="form.value" [disabled]="!form.valid" type="submit">
    <mat-icon style="margin-right: 10px">refresh</mat-icon>
    Actualizar estado
  </button>
</mat-dialog-actions>
