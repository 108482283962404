import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Company } from 'src/app/models/company.model';
import { OvhGenericPricing } from 'src/app/models/ovh_generic_pricing.model';
import { OvhGenericProduct } from 'src/app/models/ovh_generic_product.model';
import { OvhGenericProductOption } from 'src/app/models/ovh_generic_product_option.model';
import { Provider } from 'src/app/models/provider.model';
import { ApiService } from 'src/app/services/api.service';
import { RegexService } from 'src/app/services/regex.service';

@Component({
  selector: 'app-add-business-mail-dialog',
  templateUrl: './add-business-mail-dialog.component.html',
  styleUrls: ['./add-business-mail-dialog.component.css']
})
export class AddBusinessMailDialogComponent implements OnInit {

  mailForm:UntypedFormGroup;
  propietarioControl:UntypedFormControl = new UntypedFormControl('');

  providers:Provider[] = [];
  companies_filtered:Company[] = [];

  // ovh
  offers:OvhGenericProduct[] = [];
  options:OvhGenericProductOption[] = [];
  selectedExchange:OvhGenericProduct = null as any;
  selectedExchangePricing:OvhGenericPricing = null as any;
  selectedExchangeOption:OvhGenericProductOption = null as any;
  selectedExchangeOptionPricing:OvhGenericPricing = null as any;

  // loading stack
  loadingStack:any[] = [];

  constructor(private fb:UntypedFormBuilder,
              public dialogRef: MatDialogRef<AddBusinessMailDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data:any,
              private api:ApiService,
              private regex:RegexService) {

    const regexPatterns = this.regex.getRegex();

    this.mailForm = this.fb.group({
      provider_id: ['', Validators.required],
      is_fact_external: [false, Validators.required],
      company_id: [''],
      email: ['', Validators.compose([Validators.required, Validators.email])],
      trial_enabled: [true],
      quantity: ['', Validators.required],
      renewal_days_period: ['', Validators.required],

      // obligatoris si isOvhEnabled()
      ovh_cart_id: [''],
      ovh_duration: [''],
      ovh_plan_code: [''],
      ovh_pricing_mode: [''],
      ovh_option_duration: [''],
      ovh_option_plan_code: [''],
      ovh_option_pricing_mode: [''],

      // obligatoris si !isOvhEnabled()
      expiration_date: [''],
      imap_server: [''],
      imap_port: [''],
      imap_encryption: [''],
      smtp_server: [''],
      smtp_port: [''],
      smtp_encryption: [''],
      webmail_url: [''],
    });

    this.providers = this.data.providers;

  }

  ngOnInit(): void {
    this.listenForm();
    this.listenAutocompletes();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  isOvhEnabled(data:any = null) {
    data = data!=null ? data : this.mailForm.value;
    if(data['provider_id']!='' && data['is_fact_external']===false && this.mailForm.controls['email'].valid) {
      let p:Provider = this.getProvider(data['provider_id']);
      return p!=null && p.slug=="ovh";
    }
    else return false;
  }

  selectPropietario(company:Company) {
    this.mailForm.patchValue({ company_id: company.id });
  }

  selectExchange(exchange:OvhGenericProduct) {
    this.selectedExchange = exchange;
    this.mailForm.patchValue({
      ovh_plan_code: exchange.planCode
    });

    this.api.getOvhExchangeOptions(this.mailForm.value['ovh_cart_id'], exchange.planCode).subscribe(
      data => {
        this.options = data;
      }
    );
  }

  selectExchangePricing(pr:OvhGenericPricing) {
    this.selectedExchangePricing = pr;
    this.mailForm.patchValue({
      ovh_duration: pr.duration,
      ovh_pricing_mode: pr.pricingMode
    });
  }

  selectExchangeOption(option:OvhGenericProductOption) {
    this.selectedExchangeOption = option;
    this.mailForm.patchValue({
      ovh_option_plan_code: option.planCode
    });
  }

  selectExchangeOptionPricing(pr:OvhGenericPricing) {
    this.selectedExchangeOptionPricing = pr;

    let days:number = 365;
    switch(pr.interval) {
      case 1:
        days = 30;
        break;
      case 2:
        days = 60;
        break;
      case 3:
        days = 90;
        break;
      case 6:
        days = 180;
        break;
      case 12:
        days = 365;
        break;
      case 24:
        days = 730;
        break;
    }

    this.mailForm.patchValue({
      ovh_option_duration: pr.duration,
      ovh_option_pricing_mode: pr.pricingMode,
      renewal_days_period: days
    });
  }

  private listenForm() {
    this.mailForm.valueChanges.subscribe(
      data => {
        if(this.isOvhEnabled()) {
          // habilitem camps de ovh com a required
          this.mailForm.controls['ovh_cart_id'].setValidators([Validators.required]);
          this.mailForm.controls['ovh_duration'].setValidators([Validators.required]);
          this.mailForm.controls['ovh_plan_code'].setValidators([Validators.required]);
          this.mailForm.controls['ovh_pricing_mode'].setValidators([Validators.required]);
          this.mailForm.controls['ovh_option_duration'].setValidators([Validators.required]);
          this.mailForm.controls['ovh_option_plan_code'].setValidators([Validators.required]);
          this.mailForm.controls['ovh_option_pricing_mode'].setValidators([Validators.required]);

          // deshabilitem altres camps com a no required
          this.mailForm.controls['expiration_date'].setValidators([]);
          this.mailForm.controls['imap_server'].setValidators([]);
          this.mailForm.controls['imap_port'].setValidators([]);
          this.mailForm.controls['imap_encryption'].setValidators([]);
          this.mailForm.controls['smtp_server'].setValidators([]);
          this.mailForm.controls['smtp_port'].setValidators([]);
          this.mailForm.controls['smtp_encryption'].setValidators([]);
          this.mailForm.controls['webmail_url'].setValidators([]);

          // fetch extra ovh data
          if(data['ovh_cart_id']==null || data['ovh_cart_id']=='') {
            this.fetchOvhData();
          }
        }
        else {
          // deshabilitem camps de ovh com a no required
          this.mailForm.controls['ovh_cart_id'].setValidators([]);
          this.mailForm.controls['ovh_duration'].setValidators([]);
          this.mailForm.controls['ovh_plan_code'].setValidators([]);
          this.mailForm.controls['ovh_pricing_mode'].setValidators([]);
          this.mailForm.controls['ovh_option_duration'].setValidators([]);
          this.mailForm.controls['ovh_option_plan_code'].setValidators([]);
          this.mailForm.controls['ovh_option_pricing_mode'].setValidators([]);

          // habilitem altres camps com a required
          this.mailForm.controls['expiration_date'].setValidators([Validators.required]);
          this.mailForm.controls['imap_server'].setValidators([Validators.required]);
          this.mailForm.controls['imap_port'].setValidators([Validators.required]);
          this.mailForm.controls['imap_encryption'].setValidators([Validators.required]);
          this.mailForm.controls['smtp_server'].setValidators([Validators.required]);
          this.mailForm.controls['smtp_port'].setValidators([Validators.required]);
          this.mailForm.controls['smtp_encryption'].setValidators([Validators.required]);
          this.mailForm.controls['webmail_url'].setValidators([Validators.required]);
        }
      }
    );
  }

  private listenAutocompletes() {
    this.propietarioControl.valueChanges.subscribe(
      data => {
        if(this.propietarioControl.valid) {
          this.api.getCompanies({search: data}).subscribe(
            companies => {
              this.companies_filtered = companies.data;
            }
          );
        }
        else {
          this.companies_filtered = [];
          this.mailForm.patchValue({ company_id: '' }, { emitEvent: false });
        }
      }
    );
  }

  private getProvider(provider_id:number):Provider {
    let res:Provider = null as any;
    for(let p of this.providers) {
      if(p.id == provider_id) {
        res = p;
        break;
      }
    }
    return res;
  }

  private fetchOvhData() {
    // 1. Get Cart Id
    this.api.createOvhCart().subscribe(
      data => {
        const cart_id:string = data.cartId;
        this.mailForm.patchValue({ ovh_cart_id: cart_id }, { emitEvent: false });

        // 2. Get Exchange Servers
        this.api.getOvhExchangeOffers(cart_id).subscribe(
          data => {
            this.offers = data;
          }
        );
      }
    );
  }

}
