import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class RegexService {

  private regexSubject:BehaviorSubject<{[key:string]:string}> = new BehaviorSubject(null as any);
  public regexObservable:Observable<{[key:string]:string}> = this.regexSubject.asObservable();

  constructor(private api:ApiService) {
    this.fetchRegex();
  }

  public getRegex() {
    return this.regexSubject.value;
  }

  private fetchRegex() {
    this.api.getRegex().subscribe(
      data => {
        this.regexSubject.next(data);
      }
    );
  }


}
