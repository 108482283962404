import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { PotentialClient, PotentialClientStatus } from 'src/app/models/potential-client.interface';
import { User } from 'src/app/models/user.model';
import { ApiService } from 'src/app/services/api.service';
import { PermissionsService } from 'src/app/services/permissions.service';
import { UtilsService } from 'src/app/services/utils.service';
import { AddPotentialClientDialogComponent } from './add-potential-client-dialog/add-potential-client-dialog.component';

@Component({
  selector: 'app-potential-clients',
  templateUrl: './potential-clients.component.html',
  styleUrls: ['./potential-clients.component.css']
})
export class PotentialClientsComponent implements OnInit {

  displayedColumns: string[] = ['name', 'company', 'updated_at', 'status', 'comercial', 'actions'];
  dataSource:MatTableDataSource<PotentialClient> = new MatTableDataSource<PotentialClient>();

  //paginator
  length:number = 0;
  pageSize:number = 10;
  pageSizeOptions:number[] = [5,10,20,50];
  pageIndex:number = 1;

  filterForm:UntypedFormGroup;
  private formPersistence:any;

  constructor(private fb:UntypedFormBuilder,
              public router:Router,
              private activatedRoute:ActivatedRoute,
              public permissions:PermissionsService,
              private api:ApiService,
              private utils:UtilsService,
              private dialog:MatDialog) {
                this.filterForm = this.fb.group({
                  search: [this.activatedRoute.snapshot.queryParamMap.get('search')!=null ? this.activatedRoute.snapshot.queryParamMap.get('search') : '', Validators.compose([Validators.minLength(3)])],
                  per_page: [this.activatedRoute.snapshot.queryParamMap.get('per_page')!=null ? +(this.activatedRoute.snapshot.queryParamMap.get('per_page') as any) : this.pageSize, Validators.required],
                  page: [this.activatedRoute.snapshot.queryParamMap.get('page')!=null ? +(this.activatedRoute.snapshot.queryParamMap.get('page') as any) : this.pageIndex, Validators.required],
                  total: [this.activatedRoute.snapshot.queryParamMap.get('total')!=null ? +(this.activatedRoute.snapshot.queryParamMap.get('total') as any) : this.length],
                });
              }

  ngOnInit(): void {
    this.initFilterFormListener();
    this.listenQueryParameters();
  }

  changePage(event:PageEvent) {
    this.filterForm.patchValue({
      page: event.pageIndex,
      per_page: event.pageSize
    });
  }

  openAddPotentialClientDialog() {
    const dialogRef = this.dialog.open(AddPotentialClientDialogComponent, {
      width: '700px'
    });

    dialogRef.afterClosed().subscribe(
      data => {
        if(data != null) {
          this.api.createPotentialClient(data).subscribe(
            data => {
              this.fetchPotentialClients();
            }
          );
        }
      }
    );
  }

  getDetailQueryParams() {
    return {
      b: JSON.stringify(this.filterForm.value)
    }
  }

  selectComercial(c:User) {
    this.filterForm.controls['commercial_id'].setValue(c!=null ? c.id : null);
  }

  clearComercial(event:any) {
    event.stopPropagation();
    this.selectComercial(null as any);
  }

  selectPotentialClientStatus(pcs:PotentialClientStatus) {
    this.filterForm.controls['potential_client_status_slug'].setValue(pcs!=null ? pcs.slug : null);
  }

  clearPotentialClientStatus(event:any) {
    event.stopPropagation();
    this.selectPotentialClientStatus(null as any);
  }

  private initFilterFormListener() {
    this.filterForm.valueChanges.subscribe(
      data => {
        data.per_page = +data.per_page;
        if(this.formPersistence==null || JSON.stringify(this.formPersistence)!=JSON.stringify(data)) {
          if(this.formPersistence.per_page!=data.per_page) data.page = 0;
          this.router.navigate([], { queryParams: data });
        }
      }
    );
  }

  private listenQueryParameters() {
    this.activatedRoute.queryParams.subscribe(
      params => {
        if(JSON.stringify(params)!==JSON.stringify(this.filterForm.value)) { //si no ve de filtre s'ha de setejar el form
          let params_temp = this.utils.cloneObj(params); //params es read_only
          Object.keys(params_temp).forEach(param_key => {
            if(params_temp[param_key]!=null && params_temp[param_key]!="" && !isNaN(+params_temp[param_key])) params_temp[param_key] = +params_temp[param_key]; // si es numero, el transformem
          });
          this.filterForm.patchValue(params_temp, { emitEvent: false });
        }
        this.formPersistence = params;
        this.fetchPotentialClients();
      }
    );
  }

  private fetchPotentialClients() {
    this.api.getPotentialClientsPaged(this.filterForm.value).subscribe(
      data => {
        this.dataSource.data = data.data;

        this.length = +data.meta.total;
        this.pageIndex = +data.meta.current_page-1;
        this.pageSize = +data.meta.per_page;
      }
    );
  }

}
