<ng-container [formGroup]="filterForm">

  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="page-title">
          <h1>Contasimple</h1>

          <mat-form-field appearance="outline">
            <mat-label>Período</mat-label>
            <mat-select formControlName="period">
              @for (period of periods; track period) {
                <mat-option [value]="period">{{ period }}</mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>

  @if (contasimple_data!=null) {
    <div class="container">
      <div class="row">
        <div class="col-12">
          <mat-accordion>
            @if (contasimple_data.summary!=null && contasimple_data.summary.issuedInvoicesSummary!=null && contasimple_data.summary.issuedInvoicesSummary.length>0) {
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    Facturas emitidas
                  </mat-panel-title>
                  <mat-panel-description>
                    Resumen de las facturas emitidas en Contasimple
                  </mat-panel-description>
                </mat-expansion-panel-header>
                <div class="bisual-table">
                  <div class="bisual-table-row-header" [ngStyle]="{'grid-template-columns': 'repeat(' + (contasimple_data.summary.issuedInvoicesSummary.length+1) + ', 1fr)'}">
                    <div class="bisual-table-col"></div>
                    @for (month of contasimple_data.summary.issuedInvoicesSummary; track month) {
                      <div class="bisual-table-col">Mes {{ month.month }}</div>
                    }
                  </div>
                  <div class="bisual-table-row" [ngStyle]="{'grid-template-columns': 'repeat(' + (contasimple_data.summary.issuedInvoicesSummary.length+1) + ', 1fr)'}">
                    <div class="bisual-table-col">Nº de facturas</div>
                    @for (month of contasimple_data.summary.issuedInvoicesSummary; track month) {
                      <div class="bisual-table-col">{{ month.numberOfInvoices }}</div>
                    }
                  </div>
                  <div class="bisual-table-row" [ngStyle]="{'grid-template-columns': 'repeat(' + (contasimple_data.summary.issuedInvoicesSummary.length+1) + ', 1fr)'}">
                    <div class="bisual-table-col">Base imponible</div>
                    @for (month of contasimple_data.summary.issuedInvoicesSummary; track month) {
                      <div class="bisual-table-col">{{ month.totalBaseAmount | currency }}</div>
                    }
                  </div>
                  <div class="bisual-table-row" [ngStyle]="{'grid-template-columns': 'repeat(' + (contasimple_data.summary.issuedInvoicesSummary.length+1) + ', 1fr)'}">
                    <div class="bisual-table-col">Base imp. imputable</div>
                    @for (month of contasimple_data.summary.issuedInvoicesSummary; track month) {
                      <div class="bisual-table-col">{{ month.totalBaseAmountComputable | currency }}</div>
                    }
                  </div>
                  <div class="bisual-table-row" [ngStyle]="{'grid-template-columns': 'repeat(' + (contasimple_data.summary.issuedInvoicesSummary.length+1) + ', 1fr)'}">
                    <div class="bisual-table-col">IVA</div>
                    @for (month of contasimple_data.summary.issuedInvoicesSummary; track month) {
                      <div class="bisual-table-col">{{ month.totalVatAmount | currency }}</div>
                    }
                  </div>
                  <div class="bisual-table-row" [ngStyle]="{'grid-template-columns': 'repeat(' + (contasimple_data.summary.issuedInvoicesSummary.length+1) + ', 1fr)'}">
                    <div class="bisual-table-col">IVA imputable</div>
                    @for (month of contasimple_data.summary.issuedInvoicesSummary; track month) {
                      <div class="bisual-table-col">{{ month.totalVatAmountComputable | currency }}</div>
                    }
                  </div>
                  <div class="bisual-table-row" [ngStyle]="{'grid-template-columns': 'repeat(' + (contasimple_data.summary.issuedInvoicesSummary.length+1) + ', 1fr)'}">
                    <div class="bisual-table-col">Total</div>
                    @for (month of contasimple_data.summary.issuedInvoicesSummary; track month) {
                      <div class="bisual-table-col">{{ month.totalAmount | currency }}</div>
                    }
                  </div>
                  <div class="bisual-table-row" [ngStyle]="{'grid-template-columns': 'repeat(' + (contasimple_data.summary.issuedInvoicesSummary.length+1) + ', 1fr)'}">
                    <div class="bisual-table-col">Total imputable</div>
                    @for (month of contasimple_data.summary.issuedInvoicesSummary; track month) {
                      <div class="bisual-table-col">{{ month.totalComputableAmount | currency }}</div>
                    }
                  </div>
                </div>
              </mat-expansion-panel>
            }
            @if (contasimple_data.summary!=null && contasimple_data.summary.receivedInvoicesSummary!=null && contasimple_data.summary.receivedInvoicesSummary.length>0) {
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    Facturas recibidas
                  </mat-panel-title>
                  <mat-panel-description>
                    Resumen de las facturas recibidas en Contasimple
                  </mat-panel-description>
                </mat-expansion-panel-header>
                <div class="bisual-table">
                  <div class="bisual-table-row-header" [ngStyle]="{'grid-template-columns': 'repeat(' + (contasimple_data.summary.receivedInvoicesSummary.length+1) + ', 1fr)'}">
                    <div class="bisual-table-col"></div>
                    @for (month of contasimple_data.summary.receivedInvoicesSummary; track month) {
                      <div class="bisual-table-col">Mes {{ month.month }}</div>
                    }
                  </div>
                  <div class="bisual-table-row" [ngStyle]="{'grid-template-columns': 'repeat(' + (contasimple_data.summary.receivedInvoicesSummary.length+1) + ', 1fr)'}">
                    <div class="bisual-table-col">Nº de facturas</div>
                    @for (month of contasimple_data.summary.receivedInvoicesSummary; track month) {
                      <div class="bisual-table-col">{{ month.numberOfInvoices }}</div>
                    }
                  </div>
                  <div class="bisual-table-row" [ngStyle]="{'grid-template-columns': 'repeat(' + (contasimple_data.summary.receivedInvoicesSummary.length+1) + ', 1fr)'}">
                    <div class="bisual-table-col">Base imponible</div>
                    @for (month of contasimple_data.summary.receivedInvoicesSummary; track month) {
                      <div class="bisual-table-col">{{ month.totalBaseAmount | currency }}</div>
                    }
                  </div>
                  <div class="bisual-table-row" [ngStyle]="{'grid-template-columns': 'repeat(' + (contasimple_data.summary.receivedInvoicesSummary.length+1) + ', 1fr)'}">
                    <div class="bisual-table-col">Base imp. imputable</div>
                    @for (month of contasimple_data.summary.receivedInvoicesSummary; track month) {
                      <div class="bisual-table-col">{{ month.totalBaseAmountComputable | currency }}</div>
                    }
                  </div>
                  <div class="bisual-table-row" [ngStyle]="{'grid-template-columns': 'repeat(' + (contasimple_data.summary.receivedInvoicesSummary.length+1) + ', 1fr)'}">
                    <div class="bisual-table-col">IVA</div>
                    @for (month of contasimple_data.summary.receivedInvoicesSummary; track month) {
                      <div class="bisual-table-col">{{ month.totalVatAmount | currency }}</div>
                    }
                  </div>
                  <div class="bisual-table-row" [ngStyle]="{'grid-template-columns': 'repeat(' + (contasimple_data.summary.receivedInvoicesSummary.length+1) + ', 1fr)'}">
                    <div class="bisual-table-col">IVA imputable</div>
                    @for (month of contasimple_data.summary.receivedInvoicesSummary; track month) {
                      <div class="bisual-table-col">{{ month.totalVatAmountComputable | currency }}</div>
                    }
                  </div>
                  <div class="bisual-table-row" [ngStyle]="{'grid-template-columns': 'repeat(' + (contasimple_data.summary.receivedInvoicesSummary.length+1) + ', 1fr)'}">
                    <div class="bisual-table-col">Total</div>
                    @for (month of contasimple_data.summary.receivedInvoicesSummary; track month) {
                      <div class="bisual-table-col">{{ month.totalAmount | currency }}</div>
                    }
                  </div>
                  <div class="bisual-table-row" [ngStyle]="{'grid-template-columns': 'repeat(' + (contasimple_data.summary.receivedInvoicesSummary.length+1) + ', 1fr)'}">
                    <div class="bisual-table-col">Total imputable</div>
                    @for (month of contasimple_data.summary.receivedInvoicesSummary; track month) {
                      <div class="bisual-table-col">{{ month.totalComputableAmount | currency }}</div>
                    }
                  </div>
                </div>
              </mat-expansion-panel>
            }
            @if (contasimple_data.summary!=null && contasimple_data.summary.amortizedInvoicesSummary!=null && contasimple_data.summary.amortizedInvoicesSummary.length>0) {
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    Facturas amortizadas
                  </mat-panel-title>
                  <mat-panel-description>
                    Resumen de las facturas amortizadas en Contasimple
                  </mat-panel-description>
                </mat-expansion-panel-header>
                <div class="bisual-table">
                  <div class="bisual-table-row-header" [ngStyle]="{'grid-template-columns': 'repeat(' + (contasimple_data.summary.amortizedInvoicesSummary.length+1) + ', 1fr)'}">
                    <div class="bisual-table-col"></div>
                    @for (month of contasimple_data.summary.amortizedInvoicesSummary; track month) {
                      <div class="bisual-table-col">Mes {{ month.month }}</div>
                    }
                  </div>
                  <div class="bisual-table-row" [ngStyle]="{'grid-template-columns': 'repeat(' + (contasimple_data.summary.amortizedInvoicesSummary.length+1) + ', 1fr)'}">
                    <div class="bisual-table-col">Nº de facturas</div>
                    @for (month of contasimple_data.summary.amortizedInvoicesSummary; track month) {
                      <div class="bisual-table-col">{{ month.numberOfInvoices }}</div>
                    }
                  </div>
                  <div class="bisual-table-row" [ngStyle]="{'grid-template-columns': 'repeat(' + (contasimple_data.summary.amortizedInvoicesSummary.length+1) + ', 1fr)'}">
                    <div class="bisual-table-col">Base imponible</div>
                    @for (month of contasimple_data.summary.amortizedInvoicesSummary; track month) {
                      <div class="bisual-table-col">{{ month.totalBaseAmount | currency }}</div>
                    }
                  </div>
                  <div class="bisual-table-row" [ngStyle]="{'grid-template-columns': 'repeat(' + (contasimple_data.summary.amortizedInvoicesSummary.length+1) + ', 1fr)'}">
                    <div class="bisual-table-col">Base imp. imputable</div>
                    @for (month of contasimple_data.summary.amortizedInvoicesSummary; track month) {
                      <div class="bisual-table-col">{{ month.totalBaseAmountComputable | currency }}</div>
                    }
                  </div>
                  <div class="bisual-table-row" [ngStyle]="{'grid-template-columns': 'repeat(' + (contasimple_data.summary.amortizedInvoicesSummary.length+1) + ', 1fr)'}">
                    <div class="bisual-table-col">IVA</div>
                    @for (month of contasimple_data.summary.amortizedInvoicesSummary; track month) {
                      <div class="bisual-table-col">{{ month.totalVatAmount | currency }}</div>
                    }
                  </div>
                  <div class="bisual-table-row" [ngStyle]="{'grid-template-columns': 'repeat(' + (contasimple_data.summary.amortizedInvoicesSummary.length+1) + ', 1fr)'}">
                    <div class="bisual-table-col">IVA imputable</div>
                    @for (month of contasimple_data.summary.amortizedInvoicesSummary; track month) {
                      <div class="bisual-table-col">{{ month.totalVatAmountComputable | currency }}</div>
                    }
                  </div>
                  <div class="bisual-table-row" [ngStyle]="{'grid-template-columns': 'repeat(' + (contasimple_data.summary.amortizedInvoicesSummary.length+1) + ', 1fr)'}">
                    <div class="bisual-table-col">Total</div>
                    @for (month of contasimple_data.summary.amortizedInvoicesSummary; track month) {
                      <div class="bisual-table-col">{{ month.totalAmount | currency }}</div>
                    }
                  </div>
                  <div class="bisual-table-row" [ngStyle]="{'grid-template-columns': 'repeat(' + (contasimple_data.summary.amortizedInvoicesSummary.length+1) + ', 1fr)'}">
                    <div class="bisual-table-col">Total imputable</div>
                    @for (month of contasimple_data.summary.amortizedInvoicesSummary; track month) {
                      <div class="bisual-table-col">{{ month.totalComputableAmount | currency }}</div>
                    }
                  </div>
                </div>
              </mat-expansion-panel>
            }
            @if (contasimple_data.summary!=null && contasimple_data.summary.expensesSummary!=null && contasimple_data.summary.expensesSummary.length>0) {
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    Gastos
                  </mat-panel-title>
                  <mat-panel-description>
                    Resumen de los gastos en Contasimple
                  </mat-panel-description>
                </mat-expansion-panel-header>
                <div class="bisual-table">
                  <div class="bisual-table-row-header" [ngStyle]="{'grid-template-columns': 'repeat(' + (contasimple_data.summary.expensesSummary.length+1) + ', 1fr)'}">
                    <div class="bisual-table-col"></div>
                    @for (month of contasimple_data.summary.expensesSummary; track month) {
                      <div class="bisual-table-col">Mes {{ month.month }}</div>
                    }
                  </div>
                  <div class="bisual-table-row" [ngStyle]="{'grid-template-columns': 'repeat(' + (contasimple_data.summary.expensesSummary.length+1) + ', 1fr)'}">
                    <div class="bisual-table-col">Nº de recibos</div>
                    @for (month of contasimple_data.summary.expensesSummary; track month) {
                      <div class="bisual-table-col">{{ month.numberOfExpenses }}</div>
                    }
                  </div>
                  <div class="bisual-table-row" [ngStyle]="{'grid-template-columns': 'repeat(' + (contasimple_data.summary.expensesSummary.length+1) + ', 1fr)'}">
                    <div class="bisual-table-col">Total</div>
                    @for (month of contasimple_data.summary.expensesSummary; track month) {
                      <div class="bisual-table-col">{{ month.totalAmount | currency }}</div>
                    }
                  </div>
                  <div class="bisual-table-row" [ngStyle]="{'grid-template-columns': 'repeat(' + (contasimple_data.summary.expensesSummary.length+1) + ', 1fr)'}">
                    <div class="bisual-table-col">Total imputable</div>
                    @for (month of contasimple_data.summary.expensesSummary; track month) {
                      <div class="bisual-table-col">{{ month.totalComputableAmount | currency }}</div>
                    }
                  </div>
                </div>
              </mat-expansion-panel>
            }
            <mat-expansion-panel id="emitted">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Detalle facturas emitidas
                </mat-panel-title>
                <mat-panel-description>
                  Detalle de las facturas emitidas en Contasimple
                </mat-panel-description>
              </mat-expansion-panel-header>
              <div class="bisual-table">
                <div class="bisual-table-row-header">
                  <div class="bisual-table-col">Nº factura</div>
                  <div class="bisual-table-col">Fecha</div>
                  <div class="bisual-table-col">Estado</div>
                  <div class="bisual-table-col">Cliente</div>
                  <div class="bisual-table-col">Importe</div>
                </div>
                @for (invoice of contasimple_data.issued_invoices; track invoice) {
                  <div class="bisual-table-row">
                    <div class="bisual-table-col">#{{ invoice.number }}</div>
                    <div class="bisual-table-col">{{ invoice.invoiceDate | date:'shortDate' }}</div>
                    <div class="bisual-table-col">{{ invoice.status }}</div>
                    <div class="bisual-table-col">{{ invoice.entityString }}</div>
                    <div class="bisual-table-col">{{ invoice.totalAmount | currency }}</div>
                  </div>
                }
              </div>
            </mat-expansion-panel>
            <mat-expansion-panel id="received">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Detalle facturas recibidas
                </mat-panel-title>
                <mat-panel-description>
                  Detalle de las facturas recibidas en Contasimple
                </mat-panel-description>
              </mat-expansion-panel-header>
              <div class="bisual-table">
                <div class="bisual-table-row-header">
                  <div class="bisual-table-col">Nº factura</div>
                  <div class="bisual-table-col">Fecha</div>
                  <div class="bisual-table-col">Estado</div>
                  <div class="bisual-table-col">Cliente</div>
                  <div class="bisual-table-col">Importe</div>
                </div>
                @for (invoice of contasimple_data.received_invoices; track invoice) {
                  <div class="bisual-table-row">
                    <div class="bisual-table-col">#{{ invoice.number }}</div>
                    <div class="bisual-table-col">{{ invoice.invoiceDate | date:'shortDate' }}</div>
                    <div class="bisual-table-col">{{ invoice.status }}</div>
                    <div class="bisual-table-col">{{ invoice.entityString }}</div>
                    <div class="bisual-table-col">{{ invoice.totalAmount | currency }}</div>
                  </div>
                }
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </div>
    </div>
  }

</ng-container>