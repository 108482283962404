import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { DepartmentCategory } from 'src/app/models/department_category.model';
import { ProjectUserMember } from 'src/app/models/project_user_member.model';
import { Role } from 'src/app/models/role.model';
import { User } from 'src/app/models/user.model';
import { ApiService } from 'src/app/services/api.service';
import { PermissionsService } from 'src/app/services/permissions.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-project-manage-members-dialog',
  templateUrl: './project-manage-members-dialog.component.html',
  styleUrls: ['./project-manage-members-dialog.component.css']
})
export class ProjectManageMembersDialogComponent implements OnInit {
  members:User[] = [];
  activeMembers:User[] = [];
  users:User[] = [];
  me: User = null as any;

  separatorKeysCodes: number[] = [ENTER, COMMA];
  projectUserMembers: ProjectUserMember[][] = [];
  projectActiveMembers: ProjectUserMember[] = [];
  membersCntrl = new FormControl('');
  allStringUsers: string[] = [];
  filteredUsers: Observable<string[]>;
  departmentCategory:DepartmentCategory[] = [];
  @ViewChild('usersInput') usersInput: ElementRef<HTMLInputElement>;

  constructor(
    public dialogRef: MatDialogRef<ProjectManageMembersDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public permissions:PermissionsService,
    private api:ApiService) {


    this.filteredUsers = this.membersCntrl.valueChanges.pipe(
      startWith(null),
      map((w: string | null) => (this._filter(w ?? ''))),
    );
  }

  ngOnInit(): void {
    this.fetchMembers();
    this.fetchUsers();
    this.fetchDepartmentCategory();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  fetchMembers() {
    this.api.getGroupedMembers(this.data.project.id).subscribe((data) => {
      this.projectUserMembers = [];
      let projectMembers = Object.keys(data);
      for(let i=0; i< projectMembers.length; i++){
        this.projectUserMembers.push(data[projectMembers[i]]);
      }
    });

    this.api.getActiveProjectMembers(this.data.project.id).subscribe((data) => {
      this.members = data.map((obj:any) => obj.user);
      this.members = this.members.filter((v,i,a)=>a.findIndex(t=>(t.id === v.id))===i);
      this.projectActiveMembers = data;
      this.projectActiveMembers.forEach((member:ProjectUserMember) => {
        member.department_category_name = this.departmentCategory.find((dc:DepartmentCategory) => dc.id === member.department_category_id)?.name;
      });
    });
  }

  fetchUsers() {
    const params: any = {};
    this.api.getUsers(params).subscribe((users) => {
      this.users = users.data;
      this.allStringUsers = users.data.map((user:User) => user.name + ' ' + user.surnames);
    });
  }

  removeMember(member:User) {
    this.api.removeProjectMember(this.data.project.id, member!.id.toString()).subscribe((data) => {
      this.fetchMembers();
      this.usersInput.nativeElement.value = '';
      this.membersCntrl.setValue(null);
    });
  }

  deleteProjectUserMember(user:ProjectUserMember) {
    this.api.deleteProjectUserMember(user.id.toString()).subscribe((data) => {
      this.fetchMembers();
    });
  }

  addNewMember(userFullName:String) {
    const user = this.users.find((w:any) => (w.name + ' ' + w.surnames) === userFullName);
    this.api.addProjectMember(this.data.project.id, user!.id.toString()).subscribe((data) => {
      this.fetchMembers();
      this.usersInput.nativeElement.value = '';
      this.membersCntrl.setValue(null);
    });
  }

  updateProjectUserMember(user:ProjectUserMember) {
    // set alert if the user.end_date is less than today
    if(user.end_date != null && moment(user.end_date).isBefore(moment())){
      alert('Cuidado, recuerda que la participación del usuario abrá terminado ya que la fecha de fin es menor a la fecha actual');
    }
    const body = {
      project_id: this.data.project.id,
      user_id: user.user!.id,
      department_category_id: user.department_category_id,
      start_date: user.end_date != null ? moment(user.start_date).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
      end_date: user.end_date != null ? moment(user.end_date).format('YYYY-MM-DD') : null,
    };
    this.api.updateProjectUserMember(user.id.toString(), body).subscribe((data) => {
    });
  }


  private fetchDepartmentCategory() {
    this.permissions.departmentCategoriesObservable.subscribe(
      data => {
        if(data != null) {
          this.departmentCategory = Object.keys(data).map(key => data[key]).filter((dc:DepartmentCategory) => dc.is_included_in_project);
        }
      }
    );
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.allStringUsers.filter(element => element.toLowerCase().includes(filterValue) && !this.members.find((m:any) => (m.name + ' ' + m.surnames) === element));
  }

}
