@if (project) {
  <div id="content">
    <div class="page-title">
      <!-- nombre del proyecto -->
      <span>{{ project.title }}</span>
      <h1>Fases del proyecto</h1>
    </div>
    <div class="bisual-table">
      <form class="card paginator" [formGroup]="filterForm">
        <mat-paginator
          [length]="length"
          [pageSize]="pageSize"
          [pageSizeOptions]="pageSizeOptions"
          [pageIndex]="pageIndex"
          (page)="changePage($event)"
          style="margin-right: auto;"
        ></mat-paginator>
        <mat-form-field appearance="outline" class="bisual-paginator-select">
          <mat-label>Estado</mat-label>
          <mat-select formControlName="project_phase_stage_id">
            <mat-option [value]="''">Todos</mat-option>
            @for (s of project_phase_stages; track s) {
              <mat-option [value]="s.id">
                {{s.title}}
              </mat-option>
            }
          </mat-select>
        </mat-form-field>
        <mat-form-field>
          <input matInput type="text" formControlName="search">
          <button matSuffix mat-icon-button aria-label="Clear">
            <mat-icon>search</mat-icon>
          </button>
        </mat-form-field>
      </form>
      <div class="bisual-table-container">
        <table mat-table [dataSource]="dataSource">
          <ng-container matColumnDef="title">
            <th mat-header-cell *matHeaderCellDef>Nombre</th>
            <td mat-cell *matCellDef="let projectPhase">
                      <span ><a [routerLink]="
                        this.userRole != 'client'
                          ? [ '/employees', 'development', 'projects', projectPhase.project_id, 'phases', projectPhase.id ]
                          : [ '/clients', 'projects', projectPhase.project_id, 'phases', projectPhase.id ]
                        ">{{ projectPhase.title }}</a></span>
            </td>
          </ng-container>
          <ng-container matColumnDef="state">
            <th mat-header-cell *matHeaderCellDef>Estado</th>
            <td mat-cell *matCellDef="let ProjectPhase">{{ ProjectPhase.project_phase_stage.title }}</td>
          </ng-container>
          <ng-container matColumnDef="boards">
            <th mat-header-cell *matHeaderCellDef>Numero Tableros</th>
            <td mat-cell *matCellDef="let ProjectPhase">
              <span>{{ ProjectPhase.project_phase_boards_count ?? 0 }}</span>
            </td>
          </ng-container>
          <ng-container matColumnDef="deploy">
            <th mat-header-cell *matHeaderCellDef>Notificación de deploys</th>
            <td mat-cell *matCellDef="let ProjectPhase">
              <span>
                {{
                ProjectPhase.project_deploy_weekly_notification_configuration == null ?
                '-' :
                ('Los ' + (ProjectPhase.project_deploy_weekly_notification_configuration.day_of_week_iso | dayOfWeekPlural ) + ' a las ' + ProjectPhase.project_deploy_weekly_notification_configuration.local_time + ' (+' + (ProjectPhase.project_deploy_weekly_notification_configuration.user_notifiables.length + ProjectPhase.project_deploy_weekly_notification_configuration.company_notification_channels.length) + ')')
                }}
              </span>
            </td>
          </ng-container>
          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let ProjectPhase; let i = index;">
              @if (permissions.isWorkerWithProjectRole(project_id, 'product-manager')) {
                <button mat-icon-button [matMenuTriggerFor]="menu">
                  <mat-icon>more_vert</mat-icon>
                </button>
              }
              <mat-menu #menu="matMenu">
                @if (permissions.isWorkerWithProjectRole(project_id, 'product-manager')) {
                  <button mat-menu-item (click)="openEditProjectPhaseDialog(ProjectPhase)">
                    <mat-icon style="margin-right: 10px">edit</mat-icon>
                    Editar fase
                  </button>
                }
                @if (permissions.isWorkerWithProjectRole(project_id, 'product-manager')) {
                  <button mat-menu-item (click)="openDeployNotificationsDialog(ProjectPhase.id)">
                    <mat-icon style="margin-right: 10px">rocket</mat-icon>
                    Notificaciones de deploy
                  </button>
                }
                @if (permissions.isWorkerWithProjectRole(project_id, 'product-manager')) {
                  <button mat-menu-item (click)="openSendBoardStatusDialog(ProjectPhase.id)">
                    <mat-icon style="margin-right: 10px">send</mat-icon>
                    Enviar estado de tablero
                  </button>
                }
              </mat-menu>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      </div>
    </div>
  </div>
}
