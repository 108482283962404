<h1 mat-dialog-title>{{ data.dialog_title }}</h1>

<mat-dialog-content>
  <mat-form-field appearance="fill">
    <mat-label>Seleccione un lunes</mat-label>
    <input matInput [matDatepicker]="picker" [matDatepickerFilter]="filter" [(ngModel)]="selectedDate">
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button (click)="close()">{{ data.dialog_cancel_button }}</button>
  <button mat-flat-button color="accent" (click)="submit()">{{ data.dialog_ok_button }}</button>
</mat-dialog-actions>
