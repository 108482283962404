import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ProjectTaskDistribution, ProjectTaskDistributionTableColumn, ProjectTaskDistributionTableRow } from '../project-task-detail.component';
import { ProjectTask } from 'src/app/models/project_task.model';
import { UtilsService } from 'src/app/services/utils.service';
import { ApiService } from 'src/app/services/api.service';
import { WorkingTimeCategory } from 'src/app/models/working_time_category.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SelectorDialogComponent, SelectorDialogData, SelectorDialogDataRow } from '../../../selector-dialog/selector-dialog.component';
import { ProjectTeam } from 'src/app/models/project_team.model';
import { ProjectDefaultTimeDistribution } from 'src/app/models/project_default_time_distribution.model';
import { ProjectDefaultTimeDistributionDistributable } from 'src/app/models/project_default_time_distribution_distributable.model';

@Component({
  selector: 'app-project-task-budget-detail-dialog',
  templateUrl: './project-task-budget-detail-dialog.component.html',
  styleUrl: './project-task-budget-detail-dialog.component.css',
})
export class ProjectTaskBudgetDetailDialogComponent implements OnInit {

  project_task_distribution:ProjectTaskDistribution = null as any;
  all_project_task_distribution:ProjectTaskDistribution;
  is_initiated: boolean = false;
  static defaultTaskWtcsPrcnts:{[key:string]:number} = {
    'task-time-tracking': 70,
    'product-design': 20,
    'review-task': 10
  };
  wtcsEnabled:{[key:string]:boolean} = {};
  blockWtcsAndPropagate:boolean = true;
  multiplicadores:{[key:string]:{
    name:string;
    description: string;
    prcnt:number;
    checked:boolean;
    disabled: boolean;
  }} = {
    horas_muertas: {
      name: "Horas muertas",
      description: "Horas que se van en el descanso, a la hora de ir al baño, por bajada de rendimiento (no siempre se está al 100%), etc.",
      prcnt: 20,
      checked: true,
      disabled: false,
    },
    documentation: {
      name: "Documentación",
      description: "Horas dedicadas a la documentación.",
      prcnt: 10,
      checked: true,
      disabled: true,
    },
    testing: {
      name: "Testing",
      description: "Horas dedicadas a realizar testing.",
      prcnt: 50,
      checked: false,
      disabled: true,
    }
  }
  plainHoursPerBoard:{
    general: number;
    boards: number[];
  } = {
    general: 0,
    boards: []
  };

  constructor(public dialogRef: MatDialogRef<ProjectTaskBudgetDetailDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data:{data:ProjectTaskDistribution, project_task:ProjectTask, project_id:number},
              private utils:UtilsService,
              private api:ApiService,
              private snack:MatSnackBar,
              private dialog:MatDialog,
            ) {
    this.transformSecondsToHours();
  }

  async ngOnInit() {
    await this.fetchTaskWtcs();
    await this.fetchTaskWtcsDistribution();
    this.initPlainHoursPerBoard();
    this.initMultiplicadores();
    this.showAndHideBoards();
  }

  showAndHideBoards() {
    // Show only boards with time assigned or status == 'not_started'
    this.project_task_distribution = this.utils.cloneObj(this.all_project_task_distribution);
    this.project_task_distribution.boards = this.project_task_distribution.boards.filter(b => {
      if (b.status === 'not_started' || b.status === 'active') return true;
      for (let cat of b.categories) {
        if (cat.time_to_do > 0) return true;
      }
      return false;
    });

    this.project_task_distribution.boards.sort((a, b) => {
      if (a.status === 'active' && b.status !== 'active') return -1;
      if (a.status !== 'active' && b.status === 'active') return 1;
      if (a.status === 'not_started' && b.status === 'finished') return -1;
      if (a.status === 'finished' && b.status === 'not_started') return 1;
      return 0;
    });
  }

  propagateDevPlainCategories(type:'general'|'boards', rowIdx:number|undefined = undefined) {
    const hoursPlain = type==='general' ? this.plainHoursPerBoard.general : this.plainHoursPerBoard.boards[rowIdx as number];
    let generalHoursSumFinal:number = 0;
    const collection = type==='general' ? this.project_task_distribution.general.categories : this.project_task_distribution.boards[rowIdx as number].categories;
    collection.forEach((col, idx) => {
      if(idx > 0) { // evitem general
        const slug:string = col.budgetable_slug as string;
        const proportional_wtc_prcnt = this.getProportionalWtcPrcntDependingOnEnablings(slug);
        const proportional_val:number = hoursPlain*proportional_wtc_prcnt/100;

        const horas_muertas = this.multiplicadores['horas_muertas'].checked ? this.multiplicadores['horas_muertas'].prcnt/100 : 0;
        const documentation = this.multiplicadores['documentation'].checked ? this.multiplicadores['documentation'].prcnt/100 : 0;
        const testing = this.multiplicadores['testing'].checked ? this.multiplicadores['testing'].prcnt/100 : 0;

        if(slug === 'task-time-tracking') {
          col.time_to_do = proportional_val + proportional_val*horas_muertas + proportional_val*documentation + proportional_val*testing;
        }
        else if(slug === 'product-design') {
          col.time_to_do = proportional_val + proportional_val*horas_muertas + proportional_val*documentation;
        }
        else if(slug === 'review-task') {
          col.time_to_do = proportional_val + proportional_val*horas_muertas;
        }
        else {
          col.time_to_do = proportional_val + proportional_val*horas_muertas;
        }

        generalHoursSumFinal += col.time_to_do;
      }
    });

    // propaguem-ho a general també
    collection[0].time_to_do = generalHoursSumFinal;
  }

  autofillGeneral() {
    // l'objectiu es repassar tots els boards i actualitzar general
    const sumatoris = this.project_task_distribution.general.categories.map(c => 0);
    this.project_task_distribution.boards.forEach((board) => {
      board.categories.forEach((col, idx) => {
        sumatoris[idx] += col.time_to_do;
      })
    });

    this.project_task_distribution.general.categories.forEach((cat, idx) => {
      cat.time_to_do = sumatoris[idx];
    })
  }

  autofillBoardRow(rowIdx:number) {
    const sumatoris = this.project_task_distribution.general.categories.map(c => 0);
    this.project_task_distribution.boards.forEach((board) => {
      board.categories.forEach((col, idx) => {
        sumatoris[idx] += col.time_to_do;
      })
    });

    this.project_task_distribution.boards[rowIdx].categories.forEach((cat, idx) => {
      const val = this.project_task_distribution.general.categories[idx].time_to_do - sumatoris[idx];
      cat.time_to_do = val > 0 ? val : 0;
    });
  }

  autofillBoardCell(rowIdx:number,colIdx:number) {
    let sumatori:number = 0
    this.project_task_distribution.boards.forEach((b, idx) => {
      if(idx != rowIdx) {
        sumatori += b.categories[colIdx].time_to_do;
      }
    });

    let newVal:number = this.project_task_distribution.general.categories[colIdx].time_to_do - sumatori;
    if(newVal < 0) newVal = 0;

    const diff = newVal - this.project_task_distribution.boards[rowIdx].categories[colIdx].time_to_do;

    // assignem valor
    this.project_task_distribution.boards[rowIdx].categories[colIdx].time_to_do = newVal;

    // posem al general
    this.project_task_distribution.boards[rowIdx].categories[0].time_to_do += diff;
  }

  openProjectTeamsDialog() {
    this.api.getProjectProjectTeams(this.data.project_task.project_phase.project_id.toString()).subscribe(
      data => {
        let dialogData:SelectorDialogData = {
          dialog_title: "Escoge un equipo de proyecto",
          dialog_ok_button: "Escoger este equipo",
          dialog_cancel_button: "Cancelar",
          data: [],
        };
        data.filter(pt => this.project_task_distribution.general.categories.find(cat => cat.budgetable_type === "App\\Models\\ProjectTeam" && cat.budgetable_id === pt.id) === undefined).forEach(pt => {
          dialogData.data.push({ value: pt, textToShow: pt.name });
        });

        if(data.length) {
          const dialogRef = this.dialog.open(SelectorDialogComponent, {
            width: '400px',
            data: dialogData
          });

          dialogRef.afterClosed().subscribe(
            (data:ProjectTeam|undefined) => {
              if(data != undefined) {
                this.onProjectTeamSelectedToBeAdded(data);
              }
            }
          );
        }
        else {
          this.snack.open("No hay equipos de proyecto que puedas añadir a esta tarea. Crea uno.", 'Ok', { duration: 5000 });
        }
      }
    );
  }

  submit() {
    const valid = this.validate();
    if(valid) {
      const body = this.transformDistributionToApiJson();
      this.api.updateProjectTaskTimeAndWorkDistribution(this.data.project_task.id, body).subscribe(
        data => {
          this.dialogRef.close(body);
        }
      );
    }
  }

  close() {
    this.dialogRef.close();
  }

  getDefaultTaskWtcsPrcnts(slug:string) {
    return ProjectTaskBudgetDetailDialogComponent.defaultTaskWtcsPrcnts[slug];
  }

  onGeneralFieldChange(idx:number, val:number) {
    const diff = val - this.project_task_distribution.general.categories[idx].time_to_do;
    this.project_task_distribution.general.categories[idx].time_to_do = val;
    this.project_task_distribution.general.categories[0].time_to_do += diff;
  }

  onBoardFieldChange(board_idx:number, col_idx:number, val:number) {
    const diff = val - this.project_task_distribution.boards[board_idx].categories[col_idx].time_to_do;
    this.project_task_distribution.boards[board_idx].categories[col_idx].time_to_do = val;
    this.project_task_distribution.boards[board_idx].categories[0].time_to_do += diff;
  }

  private validate(): boolean {
    let valid:boolean = true;
    this.project_task_distribution.general.categories.forEach((cat,idx) => {
      const sumatori = this.project_task_distribution.boards.map(b => b.categories[idx].time_to_do).reduce((carry, curr) => carry + curr);
      if(this.project_task_distribution.general.categories[idx].time_to_do < sumatori) {
        this.snack.open('La categoría ' + cat.title + ' tiene más tiempo asignado que el inicial. Esto quiere decir que no se ha completado a tiempo.', 'Ok', { duration: 5000 });
        // valid = false;
      }
    });

    return valid;
  }

  private transformSecondsToHours() {
    this.all_project_task_distribution = this.utils.cloneObj(this.data.data);
    this.all_project_task_distribution.general = this.transformSecondsToHoursRow(this.all_project_task_distribution.general);
    for(let row of this.all_project_task_distribution.boards) {
      row = this.transformSecondsToHoursRow(row);
    }
  }

  private transformSecondsToHoursRow(row: ProjectTaskDistributionTableRow): ProjectTaskDistributionTableRow {
    for(let col of row.categories) {
      col.time_done /= 3600;
      col.time_to_do /= 3600;
    }
    return row;
  }

  private async fetchTaskWtcs() {
    const data = await this.api.getTaskWorkingTimeCategories().toPromise();
    const hasBeenReordered = this.addPendingWtcs(data!);
    if(hasBeenReordered) {
      this.reorderColumns();
    }
    this.is_initiated = true;
  }

  private async fetchTaskWtcsDistribution() {
    const data:ProjectDefaultTimeDistributionDistributable[] = await this.api.getCurrentDefaultTimeDistributionTaskTimeForProjectPhase(this.data.project_id, this.data.project_task.project_phase_id).toPromise() as ProjectDefaultTimeDistributionDistributable[];

    if(data.length > 0) {
      ProjectTaskBudgetDetailDialogComponent.defaultTaskWtcsPrcnts = {};
      data!.forEach(distributable => {
        ProjectTaskBudgetDetailDialogComponent.defaultTaskWtcsPrcnts[(distributable.distributable as WorkingTimeCategory).slug] = distributable.time_prcnt;
        this.wtcsEnabled[(distributable.distributable as WorkingTimeCategory).slug] = true;
      });
    }
    else {
      for(let key of Object.keys(this.wtcsEnabled)) {
        this.wtcsEnabled[key] = true;
      }
    }
  }

  private addPendingWtcs(data:WorkingTimeCategory[]): boolean {
    let hasBeenAdded:boolean = false;

    loop1:
    for(let wtc of data) {
      let found:boolean = false;

      loop2:
      for(let col of this.all_project_task_distribution.general.categories) {
        if(col.budgetable_type === "App\\Models\\WorkingTimeCategory" && col.budgetable_id === wtc.id) {
          found = true;
          break loop2;
        }
      }

      if(!found) {
        hasBeenAdded = true;
        // hem d'afegir wtc com a columna

        // general
        this.all_project_task_distribution.general.categories.push({
          title: wtc.short_name ?? wtc.name,
          time_to_do: 0,
          time_done: 0,
          completed: false,
          editable: true,
          budgetable_type: "App\\Models\\WorkingTimeCategory",
          budgetable_id: wtc.id,
          budgetable_slug: wtc.slug
        });

        // boards
        for(let row of this.all_project_task_distribution.boards) {
          row.categories.push({
            title: wtc.short_name ?? wtc.name,
            time_to_do: 0,
            time_done: 0,
            completed: false,
            editable: true,
            budgetable_type: "App\\Models\\WorkingTimeCategory",
            budgetable_id: wtc.id,
            budgetable_slug: wtc.slug
          });
        }

      }

      // inicialitzem wtcsEnabled
      this.wtcsEnabled[wtc.slug] = found;
    }

    return hasBeenAdded;
  }

  private reorderColumns() {

  }

  private initPlainHoursPerBoard() {
    const rowDevIdx = this.all_project_task_distribution.general.categories.findIndex(col => col.budgetable_slug === "task-time-tracking");

    this.all_project_task_distribution.boards.forEach((row, idx) => {
      if(idx == 0) {
        // general
        if(!rowDevIdx) {
          this.plainHoursPerBoard.general = 0;
        }
        else this.plainHoursPerBoard.general = this.calcPlainHour(row.categories[rowDevIdx].time_to_do);
      }
      else {
        // boards
        if(!rowDevIdx) {
          this.plainHoursPerBoard.boards.push(0);
        }
        else {
          const val = this.calcPlainHour(row.categories[rowDevIdx].time_to_do);
          this.plainHoursPerBoard.boards.push(val);
        }
      }
    });
  }

  private calcPlainHour(devTime:number) {
    return 0;
  }

  private getProportionalWtcPrcntDependingOnEnablings(slug:string):number {
    if(this.wtcsEnabled[slug]) {
      let enabledPrcntTotal:number = 0;
      Object.keys(ProjectTaskBudgetDetailDialogComponent.defaultTaskWtcsPrcnts).forEach(key => {
        if(this.wtcsEnabled[key]) enabledPrcntTotal += ProjectTaskBudgetDetailDialogComponent.defaultTaskWtcsPrcnts[key];
      });

      const currentPrcnt = ProjectTaskBudgetDetailDialogComponent.defaultTaskWtcsPrcnts[slug];
      return 100*currentPrcnt/enabledPrcntTotal;
    }
    else return 0;
  }

  private initMultiplicadores() {
    this.multiplicadores['documentation'].checked = this.data.project_task.needs_documentation;
    this.multiplicadores['testing'].checked = this.data.project_task.needs_testing;
  }

  private transformDistributionToApiJson() {
    const body:ProjectTaskDistribution = this.utils.cloneObj(this.project_task_distribution);
    body.general.categories.splice(0, 1);
    body.boards.forEach(b => {
      b.categories.splice(0,1);
    });

    // transformar a segons
    body.general.categories.forEach(cat => {
      cat.time_to_do *= 3600;
    });

    body.boards.forEach(b => {
      b.categories.forEach(cat => {
        cat.time_to_do *= 3600;
      });
    });

    return body;
  }

  private onProjectTeamSelectedToBeAdded(project_team:ProjectTeam) {
    // 1. Afegir a project_task_distribution
    const body:ProjectTaskDistributionTableColumn = {
      title: project_team.name,
      time_to_do: 0,
      time_done: 0,
      completed: false,
      editable: true,
      budgetable_type: "App\\Models\\ProjectTeam",
      budgetable_id: project_team.id,
      budgetable_slug: undefined,
    };

    this.project_task_distribution.general.categories.push({...body});

    this.project_task_distribution.boards.forEach(b => {
      b.categories.push({...body});
    })
  }

  addBoard() {
    // open dialog
    const dialogRef = this.dialog.open(SelectorDialogComponent, {
      width: '400px',
      data: {
        width: '400px',
        dialog_title: "Escoge un tablero",
        dialog_ok_button: "Escoger este tablero",
        dialog_cancel_button: "Cancelar",
        data: this.all_project_task_distribution.boards.filter(b => this.project_task_distribution.boards.find(pb => pb.title === b.title) === undefined).map((b) => {
          return {
            value: b.title,
            textToShow: b.title
          }
        })
      }
    });

    // on close, find the selected board and add it to the project_task_distribution
    dialogRef.afterClosed().subscribe(
      body => {
        if(body!=null) {
          const board:ProjectTaskDistributionTableRow|undefined = this.all_project_task_distribution.boards.find(b => b.title === body);
          if(board!==undefined) this.project_task_distribution.boards.push(board);
        }
      }
    );
  }
}
