<div mat-dialog-title class="dialog-title-container">
  <h1>Facturas de pedidos</h1>
  <button mat-flat-button color="accent" (click)="uploader.click()">
    <mat-icon matPrefix>cloud_upload</mat-icon>
    Subir archivo
  </button>
  <input #uploader style="display: none;" type="file" accept="application/pdf" (change)="uploadFile($event.target)" />
</div>

<mat-dialog-content class="container">
  <div class="row">
    <div class="col-12">
      <div class="bisual-table">
        <div class="bisual-table-row-header">
          <div class="bisual-table-col">Tipo</div>
          <div class="bisual-table-col">Nombre</div>
          <div class="bisual-table-col">Fecha</div>
          <div class="bisual-table-col">Tamaño</div>
          <div class="bisual-table-col"></div>
        </div>
        @for (at of attachments; track at; let i = $index) {
          <div class="bisual-table-row">
            <div class="bisual-table-col">{{ at.extension | uppercase }}</div>
            <div class="bisual-table-col">{{ at.displayFullFilename }}</div>
            <div class="bisual-table-col">{{ at.date | date:'short' }}</div>
            <div class="bisual-table-col">{{ at.size | bytes:0:'B' }}</div>
            <div class="bisual-table-col">
              <button mat-icon-button (click)="download(at)">
                <mat-icon>cloud_download</mat-icon>
              </button>
              <button mat-icon-button (click)="delete(at, i)">
                <mat-icon>delete</mat-icon>
              </button>
            </div>
          </div>
        }
      </div>
    </div>
  </div>
</mat-dialog-content>

<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()" cdkFocusInitial type="button">Cerrar</button>
</div>
