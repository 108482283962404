<div [id]="user != null ? '' : 'content'">
  <div class="page-title">
    <h1>{{ user != null ? 'Mis Contratos' : 'Contratos'}} </h1>
    @if (user == null) {
      <button mat-flat-button color="accent" [routerLink]="['create']">
        <mat-icon style="margin-right: 10px">person_add</mat-icon>
        Crear contrato
      </button>
    }
  </div>

  <div class="bisual-table">

    <form [formGroup]="usersContractForm" class="card paginator" >
      <mat-paginator
        [length]="length"
        [pageSize]="pageSize"
        [pageSizeOptions]="pageSizeOptions"
        [pageIndex]="pageIndex"
        (page)="changePage($event)"
        style="margin-right: auto;">
      </mat-paginator>

      @if (user == null) {
        <mat-form-field appearance="outline" >
          <mat-label>Buscar usuario</mat-label>
          <input type="text" matInput formControlName="user-name">
        </mat-form-field>
      }

      <mat-form-field appearance="outline">
        <mat-label>Tipo contrato</mat-label>
        <mat-select formControlName="working_contract_type_id">
          <mat-option [value]="''">Todos</mat-option>
          @for (contractType of workingContractsTypes; track contractType) {
            <mat-option [value]="contractType.id">{{ contractType.name }}</mat-option>
          }
        </mat-select>
      </mat-form-field>

    </form>


    <div class="bisual-table-container">

      <table mat-table [dataSource]="tableContracts">
        <ng-container matColumnDef="ID">
          <th mat-header-cell *matHeaderCellDef>ID</th>
          <td mat-cell *matCellDef="let element">{{element.id}}</td>
        </ng-container>

        <ng-container matColumnDef="Nombre">
          <th mat-header-cell *matHeaderCellDef>Nombre</th>
          <td mat-cell *matCellDef="let element">{{element.user.name + ' ' + element.user.surnames}}</td>
        </ng-container>

        <ng-container matColumnDef="Contrato">
          <th mat-header-cell *matHeaderCellDef>Contrato</th>
          <td mat-cell *matCellDef="let element">{{element.working_contract_type.name}}</td>
        </ng-container>

        <ng-container matColumnDef="Fecha-inicio">
          <th mat-header-cell *matHeaderCellDef>Fecha inicio</th>
          <td mat-cell *matCellDef="let element">{{element.start_date | date}}</td>
        </ng-container>

        <ng-container matColumnDef="Fecha-final">
          <th mat-header-cell *matHeaderCellDef>Fecha final</th>
          <td mat-cell *matCellDef="let element">{{element.end_date | date}}</td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element; let i = index;">
            <button mat-icon-button [matMenuTriggerFor]="menu">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              @if (user == null) {
                <button mat-menu-item [routerLink]="[element.id]">
                  <mat-icon matPrefix>edit_note</mat-icon>
                  Editar contrato
                </button>
              }
              @if (user != null) {
                <button mat-menu-item [routerLink]="[element.id]">
                  <mat-icon matPrefix>edit_note</mat-icon>
                  Ver detalles...
                </button>
              }
            </mat-menu>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>

    </div>
  </div>
</div>
