<div id="content" class="extensible">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="page-title">
          <h1>Asignación de proyectos</h1>

          <div class="date-controller card">
            <button mat-icon-button (click)="changeWeek(-1)"><mat-icon>chevron_left</mat-icon></button>
            <span class="date">{{ filterForm.value['from'] | date:'shortDate' }} - {{ filterForm.value['to'] | date:'shortDate' }}</span>
            <button mat-icon-button (click)="changeWeek(1)"><mat-icon>chevron_right</mat-icon></button>
          </div>
        </div>
      </div>
    </div>

    <app-project-assignment-table [from]="filterForm.value['from']" [to]="filterForm.value['to']"></app-project-assignment-table>
  </div>
</div>
