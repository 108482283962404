<h1 mat-dialog-title>Cambiar contraseña</h1>
<mat-dialog-content [formGroup]="passwordForm">
    <div class="container">
        <div class="row">
            <div class="col-12 col-md-6">
                <mat-form-field appearance="outline">
                    <mat-label>Contraseña</mat-label>
                    <input type="password" matInput formControlName="password">
                </mat-form-field>
            </div>
            <div class="col-12 col-md-6">
                <mat-form-field appearance="outline">
                    <mat-label>Repite la contraseña</mat-label>
                    <input type="password" matInput formControlName="repeat">
                </mat-form-field>
            </div>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-button (click)="onNoClick()" cdkFocusInitial type="button">Cancelar</button>
    <button mat-button [mat-dialog-close]="passwordForm.value['password']" [disabled]="!passwordForm.valid" type="submit">
        <mat-icon style="margin-right: 10px">lock</mat-icon>
        Cambiar contraseña
    </button>
</mat-dialog-actions>
