import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RegexService } from 'src/app/services/regex.service';
import * as Countries from 'src/assets/json/countries.json';

@Component({
  selector: 'app-add-company-dialog',
  templateUrl: './add-company-dialog.component.html',
  styleUrls: ['./add-company-dialog.component.css']
})
export class AddCompanyDialogComponent implements OnInit {

  companyForm:UntypedFormGroup;
  countries:any[] = (Countries as any).default;

  constructor(private fb:UntypedFormBuilder,
              public dialogRef: MatDialogRef<AddCompanyDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data:any,
              private regex:RegexService) {

    const regexPatterns = this.regex.getRegex();

    this.companyForm = this.fb.group({
      email: ['', Validators.compose([Validators.required, Validators.email])],
      phone: ['', Validators.compose([Validators.required, Validators.pattern(regexPatterns['phone'])])],
      fact_name: ['', Validators.compose([Validators.required])],
      fact_address: ['', Validators.compose([Validators.required])],
      fact_city: ['', Validators.compose([Validators.required])],
      fact_zipcode: ['', Validators.compose([Validators.required, Validators.minLength(5), Validators.maxLength(5)])],
      fact_county: ['', Validators.compose([Validators.required])],
      fact_country: ['', Validators.compose([Validators.required])],
      fact_country_alpha2: ['', Validators.compose([Validators.required])],
      create_user: [false],
      vat: ['', Validators.compose([Validators.required])],
    });
  }

  ngOnInit(): void {
    this.listenCountryChanges();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  private listenCountryChanges() {
    this.companyForm.controls['fact_country'].valueChanges.subscribe(
      data => {
        for(let c of this.countries) {
          if(c.name==data) {
            this.companyForm.patchValue({ fact_country_alpha2: c.alpha_2 });
            break;
          }
        }
      }
    );
  }

}
