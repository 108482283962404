import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'bisualSettingSectionKeyToTitle'
})
export class BisualSettingSectionKeyToTitlePipe implements PipeTransform {

  transform(bisual_setting_key:string): string {
    switch(bisual_setting_key) {
      case 'billing':
        return "Facturación";
        break;
      case 'rrhh':
        return "Recursos humanos";
        break;
      case 'contact':
        return "Contacto";
        break;
      case 'technical':
        return "Técnico";
        break;
      case 'other':
        return "Otros";
        break;
      default:
        return '';
    }
  }

}
