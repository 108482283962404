<h1 mat-dialog-title>{{title}}</h1>
@if (from && to) {
  <p>{{ from }} a {{ to }}</p>
}

<mat-dialog-content>
  <div class="row">
    <div class="active-members mb-3">
      @for (member of projectActiveMembers; track member) {
        <mat-chip>
          <img class="profile-image" [src]="member.user!.profile_picture_url" />
          {{member.user!.name}}
          (@if (member.department_category_name) {
          <span class="owner">{{ member.department_category_name }} - </span>
        }
        <span>{{ (member.availability ?? 0) | hourDecimalToHoursMinutes }}</span>)
      </mat-chip>
    }
  </div>

  <div class="col-md-6">
    <p class="section-title">{{ firstLabelTitle }}</p>
    <table>
      <thead>
        <tr>
          <th>Categoría</th>
          <th>Semana anterior</th>
          <th>Esta semana</th>
        </tr>
      </thead>
      <tbody>
        @for (entity of firstDistribution; track entity; let i = $index) {
          <tr>
            <th>
              {{ entity.title }}
            </th>
            <th>
              <p class="number-content">
                {{ entity.last_week_value }}h
              </p>
            </th>
            <th>
              <div class="row">
                <div class="col">
                  <mat-form-field appearance="outline" class="input-hour" [ngClass]="{'changed': entity.changed, 'readonly': entity.is_readonly}">
                    <input matInput type="number" min="0" [ngModel]="firstDistribution[i].value" (ngModelChange)="changeValue(true, $event, i)" [readonly]="isReadonly(entity.is_readonly)"/>
                    <span matSuffix>h</span>
                  </mat-form-field>
                </div>
                <div class="col d-flex justify-content-center align-items-center">
                  <p class="number-content">
                    {{ entity.default!=null ? (entity.default + ' h') : '' }}
                  </p>
                </div>
              </div>
            </th>
          </tr>
        }
      </tbody>
      <tfoot>
        <tr>
          <th>Total</th>
          <th>
            <p class="number-content">
              {{ firstDistributionPreviousTotal}}h
            </p>
          </th>
          <th>
            <p class="number-content">
              <span class="incorrect" [ngClass]="{'ok': nearby(firstDistributionTotalToCompare,firstDistributionTotal)}">{{ firstDistributionTotal }}h</span> / {{ firstDistributionTotalToCompare }}h
            </p>
          </th>
        </tr>
      </tfoot>
    </table>

  </div>
  <div class="col-md-6">
    <p class="section-title">{{ secondLabelTitle }}</p>
    <table>
      <thead>
        <tr>
          <th>Fase</th>
          <th>Semana anterior</th>
          <th>Esta semana</th>
        </tr>
      </thead>
      <tbody>
        @for (entity of secondDistribution; track entity; let i = $index) {
          <tr>
            <th>
              {{ entity.title }} @if (!entity.active_phase) {
              <mat-icon class="no-active-phase" [matTooltip]="'Actualmente esta fase no está activa'" >error</mat-icon>
            }
          </th>
          <th>
            <p class="number-content">
              {{ entity.last_week_value }}h
            </p>
          </th>
          <th>
            <div class="row">
              <div class="col">
                <mat-form-field appearance="outline" class="input-hour" [ngClass]="{'readonly': entity.is_readonly}">
                  <input matInput type="number" min="0" [ngModel]="entity.value" (ngModelChange)="changeValue(false, $event, i)" [readonly]="isReadonly(entity.is_readonly)"/>
                  <span matSuffix>h</span>
                </mat-form-field>
              </div>
              <div class="col d-flex justify-content-center align-items-center">
                <p class="number-content">
                  {{ entity.default!=null ? (entity.default + ' h') : '' }}
                </p>
              </div>
              <div class="col">
                @if (entity.has_children) {
                  <button mat-icon-button (click)="openChild(i)"><mat-icon>settings_b_roll</mat-icon></button>
                }
              </div>
            </div>
          </th>
        </tr>
      }
    </tbody>
    <tfoot>
      <tr>
        <th>Total</th>
        <th>
          <p class="number-content">
          </p>
        </th>
        <th>
          <p class="number-content">
            <span class="incorrect" [ngClass]="{'ok': nearby(secondDistributionTotalToCompare,secondDistributionTotal)}">{{ secondDistributionTotal }}h</span> / {{ secondDistributionTotalToCompare }}h
          </p>
        </th>
      </tr>
    </tfoot>
  </table>
</div>
</div>
</mat-dialog-content>

<mat-dialog-actions>
  @if (root_id==null) {
    <button mat-button color="accent" (click)="loadDefaults()">
      Cargar defectos
    </button>
  }
  <div>
    <button mat-button cdkFocusInitial (click)="close()">
      <mat-icon>close</mat-icon>
      Cerrar
    </button>
    <button mat-flat-button color="accent" (click)="update()">
      Actualizar
    </button>
  </div>
</mat-dialog-actions>
