<div class="page-title">
  <h1>{{ hosting!=null ? hosting.hosting_fqdn : 'Cargando...' }}</h1>

  @if (hosting != null) {
    <button mat-stroked-button [matMenuTriggerFor]="menuOther">
      Otras acciones
      <mat-icon matSuffix>arrow_drop_down</mat-icon>
    </button>
  }
  <mat-menu #menuOther="matMenu">
    @if (hosting!=null && hosting.stripe_id != null) {
      <button mat-menu-item (click)="cancelHosting(hosting)" matTooltip="Cancelará el hosting y esperará a eliminarlo a que expire." [disabled]="!permissions.validateDepartment(['direction', 'sysadmin'])">
        <mat-icon style="margin-right: 10px">block</mat-icon>
        Cancelar hosting
      </button>
    }
    @if (hosting != null) {
      <button mat-menu-item (click)="deleteHosting(hosting)" matTooltip="Si es de Hetzner, lo eliminará y marcará como tal. Sino lo marcará como inactivo." [disabled]="!permissions.validateDepartment(['direction', 'sysadmin'])">
        <mat-icon style="margin-right: 10px">delete</mat-icon>
        Eliminar hosting
      </button>
    }
  </mat-menu>

  @if (hosting!=null && hosting.hetzner_server!=null && hosting.hetzner_server != null) {
    <button mat-stroked-button [matMenuTriggerFor]="menu">
      Acciones Hetzner
      <mat-icon matSuffix>arrow_drop_down</mat-icon>
    </button>
  }
  <mat-menu #menu="matMenu">
    @if (hosting!=null && hosting.hetzner_server!=null && hosting.hetzner_server.status=='off') {
      <button mat-menu-item (click)="startServerHetzner()" [disabled]="!permissions.validateDepartment(['direction', 'sysadmin'])">
        <mat-icon matPrefix>play_arrow</mat-icon>
        Iniciar servidor
      </button>
    }
    @if (hosting!=null && hosting.hetzner_server!=null && hosting.hetzner_server.status=='running') {
      <button mat-menu-item (click)="stopServerHetzner()" [disabled]="!permissions.validateDepartment(['direction', 'sysadmin'])">
        <mat-icon matPrefix>stop</mat-icon>
        Apagar servidor
      </button>
    }
    @if (hosting!=null && hosting.hetzner_server!=null && hosting.hetzner_server.status=='running') {
      <button mat-menu-item (click)="restartServerHetzner()" [disabled]="!permissions.validateDepartment(['direction', 'sysadmin'])">
        <mat-icon matPrefix>restart_alt</mat-icon>
        Reiniciar servidor
      </button>
    }
    @if (hosting!=null && hosting.hetzner_server!=null && hosting.hetzner_server.backup_window==null) {
      <button mat-menu-item (click)="enableBackupsHetzner()" [disabled]="!permissions.validateDepartment(['direction', 'sysadmin'])">
        <mat-icon matPrefix>cloud_upload</mat-icon>
        Activar backups
      </button>
    }
    @if (hosting!=null && hosting.hetzner_server!=null && hosting.hetzner_server.backup_window!=null) {
      <button mat-menu-item (click)="disableBackupsHetzner()" [disabled]="!permissions.validateDepartment(['direction', 'sysadmin'])">
        <mat-icon matPrefix>cloud_off</mat-icon>
        Desactivar backups
      </button>
    }
    @if (hosting!=null && hosting.hetzner_server!=null && hosting.hetzner_server.status=='running') {
      <button mat-menu-item (click)="resetRootPasswordHetzner()" [disabled]="!permissions.validateDepartment(['direction', 'sysadmin'])">
        <mat-icon matPrefix>password</mat-icon>
        Restablecer contraseña root
      </button>
    }
    @if (hosting!=null && hosting.hetzner_server!=null && hosting.hetzner_server.status=='running') {
      <button mat-menu-item (click)="changeServerTypeHetzner()" [disabled]="!permissions.validateDepartment(['direction', 'sysadmin'])">
        <mat-icon matPrefix>dns</mat-icon>
        Cambiar tipo de servidor
      </button>
    }
    <button mat-menu-item (click)="openServerInHetzner()" [disabled]="!permissions.validateDepartment(['direction', 'sysadmin'])">
      <mat-icon matPrefix>open_in_new</mat-icon>
      Acceder a Hetzner
    </button>
  </mat-menu>

  <button mat-flat-button color="accent" [disabled]="!permissions.validateDepartment(['direction', 'sysadmin', 'support'])">
    <mat-icon style="margin-right: 10px">visibility</mat-icon>
    Ver tickets
  </button>
</div>

@if (hosting!=null) {
  <div class="container">
    <div class="row">
      <div class="col-12 col-lg-4">
        <div class="card action-card">
          <mat-icon>info</mat-icon>
          <div class="card-header">
            <div class="card-title">
              <h2>Estado del servidor</h2>
              <div class="subtitle">
                <div class="status-point" [ngClass]="hosting.status" style="margin-right: 5px;"></div>
                {{ getHostingStatusTooltip(hosting.status) }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-4">
        <div class="card action-card">
          <mat-icon>dns</mat-icon>
          <div class="card-header">
            <div class="card-title">
              <h2>Proveedor de alojamiento</h2>
              <p class="subtitle">{{ hosting.provider.name }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-4">
        <div class="card action-card">
          <mat-icon>maps_home_work</mat-icon>
          <div class="card-header">
            <div class="card-title">
              <h2>Propiedad</h2>
              <div class="subtitle">
                @if (hosting.company_id != null) {
                  <a [routerLink]="['/employees', 'direction', 'users-management', 'companies', hosting.company_id]">{{ hosting.company.fact_name }}</a>
                }
                @if (hosting.company_id==null) {
                  <span>Bisual</span>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
}

@if (hosting!=null) {
  <div class="bisual-two-col-masonry" style="margin-top: 50px;">
    @if (hosting.stripe_id != null) {
      <div class="card specs">
        <div class="card-header">
          <div class="card-title">
            <h2>Suscripción</h2>
            <p class="subtitle">Datos de la suscripción del hosting</p>
          </div>
          <div class="container">
            <div class="row">
              <div class="col-12 col-md-4">
                <div class="wrapper">
                  <mat-icon>paid</mat-icon>
                  <span class="title">{{ hosting.quantity | currency:'EUR':'symbol':'1.0-0' }}</span>
                  <p class="subtitle">cada {{ hosting.renewal_days_period | daysToPeriod }}</p>
                </div>
              </div>
              <div class="col-12 col-md-4">
                @if (hosting.ends_at==null) {
                  <div class="wrapper">
                    <mat-icon>autorenew</mat-icon>
                    <span class="title">{{ hosting.expiration_date | date:'shortDate' }}</span>
                    <p class="subtitle">Próxima renovación</p>
                  </div>
                }
                @if (hosting.ends_at!=null) {
                  <div class="wrapper">
                    <mat-icon>do_not_disturb_on</mat-icon>
                    <span class="title">{{ hosting.ends_at | date:'shortDate' }}</span>
                    <p class="subtitle">Fecha de cancelación</p>
                  </div>
                }
              </div>
              <div class="col-12 col-md-4">
                <div class="wrapper">
                  <mat-icon>account_balance</mat-icon>
                  <span class="title">{{ hosting.orders.length }}</span>
                  <p class="subtitle">pedidos totales</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    }
    <div class="card specs">
      <div class="card-header">
        <div class="card-title">
          <h2>Especificaciones</h2>
          <p class="subtitle">Especificaciones del servidor</p>
        </div>
      </div>
      <div class="container">
        <div class="row">
          <div class="col-12 col-md-4">
            <div class="wrapper">
              <mat-icon>memory</mat-icon>
              <span class="title">{{ hosting.cores }}</span>
              <span class="subtitle">vCPUs</span>
            </div>
          </div>
          <div class="col-12 col-md-4">
            <div class="wrapper">
              <mat-icon>sd_card</mat-icon>
              <span class="title">{{ hosting.memory }}GB</span>
              <span class="subtitle">Memoria RAM</span>
            </div>
          </div>
          <div class="col-12 col-md-4">
            <div class="wrapper">
              <mat-icon>storage</mat-icon>
              <span class="title">{{ hosting.disk }}GB</span>
              <span class="subtitle">Memoria SSD</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    @if (hosting.hetzner_server != null) {
      <div class="card">
        <div class="card-header">
          <div class="card-title">
            <h2>Información de Hetzner</h2>
            <p class="subtitle">Información recibida de Hetzner ahora mismo</p>
          </div>
        </div>
        <div class="container">
          <div class="row">
            <div class="col-12 col-md-6">
              <b>Identificador</b>: #{{ hosting.hetzner_server.id }}
            </div>
            <div class="col-12 col-md-6">
              <b>Nombre</b>: {{ hosting.hetzner_server.name }}
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-6">
              <b>Estado</b>: {{ hosting.hetzner_server.status | titlecase }}
            </div>
            <div class="col-12 col-md-6">
              <b>Servidor</b>: {{ hosting.hetzner_server.server_type.description }}
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-6">
              <b>IPv4</b>: {{ hosting.hetzner_server.public_net.ipv4.ip }}
            </div>
            <div class="col-12 col-md-6">
              <b>IPv6</b>: {{ hosting.hetzner_server.public_net.ipv6.ip }}
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-6">
              <b>Espacio SSD</b>: {{ hosting.hetzner_server.server_type.disk }}GB
            </div>
            <div class="col-12 col-md-6">
              <b>RAM</b>: {{ hosting.hetzner_server.server_type.memory }}GB
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-6">
              <b>Cores</b>: {{ hosting.hetzner_server.server_type.cores }} vCPUs
            </div>
            <div class="col-12 col-md-6">
              <b>Localización</b>: {{ hosting.hetzner_server.datacenter.description }} ({{ hosting.hetzner_server.datacenter.location.country }})
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-6">
              <b>Imagen</b>: {{ hosting.hetzner_server.image.description }}
            </div>
            <div class="col-12 col-md-6">
              <b>Bloqueado</b>: {{ hosting.hetzner_server.locked ? 'Sí' : 'No' }}
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-6">
              <b>Tráfico entrante</b>: {{ hosting.hetzner_server.ingoing_traffic | bytes:0:'TB' }}
            </div>
            <div class="col-12 col-md-6">
              <b>Tráfico saliente</b>: {{ hosting.hetzner_server.outgoing_traffic | bytes:0:'TB' }}
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-6">
              <b>Backup Window</b>: {{ hosting.hetzner_server.backup_window!=null ? 'Activado, ' + hosting.hetzner_server.backup_window : 'Desactivado' }}
            </div>
            <div class="col-12 col-md-6">
              <b>Precio</b>: {{ hosting.hetzner_server.server_type.prices[0].price_monthly.gross | currency }} / mes
            </div>
          </div>
        </div>
      </div>
    }
    @if (hosting.parent_hosting_id!=null) {
      <div class="card">
        <div class="card-header">
          <div class="card-title">
            <h2>Hosting padre</h2>
            <a class="subtitle" [routerLink]="['/employees', 'development', 'services', 'hostings', hosting.parent_hosting.id, 'resume']">{{ hosting.parent_hosting.hosting_fqdn }}</a>
          </div>
          <button mat-stroked-button [routerLink]="['/employees', 'development', 'services', 'hostings', hosting.parent_hosting.id, 'resume']">Ver servidor</button>
        </div>
        <div class="container">
          <div class="row">
            <div class="col-12 col-md-6"><b>FQDN</b>: {{ hosting.parent_hosting.hosting_fqdn }}</div>
            <div class="col-12 col-md-6"><b>Responsable</b>: {{ hosting.parent_hosting.company!=null ? hosting.parent_hosting.company.fact_name : 'Bisual' }}</div>
          </div>
        </div>
      </div>
    }
    @if (hosting.children.length > 0) {
      <div class="card">
        <div class="card-header">
          <div class="card-title">
            <h2>Servidores ficticios</h2>
            <p class="subtitle">Servidores ficticios alojados dentro de él.</p>
          </div>
        </div>
        <div class="bisual-table">
          <div class="bisual-table-row-header">
            <div class="bisual-table-col">FQDN</div>
            <div class="bisual-table-col">Propietario</div>
            <div class="bisual-table-col">Suscripción</div>
            <div class="bisual-table-col">Estado</div>
          </div>
          @for (h of hosting.children; track h) {
            <div class="bisual-table-row" >
              <div class="bisual-table-col">
                <a [routerLink]="['/employees', 'development', 'services', 'hostings', h.id, 'resume']">{{ h.hosting_fqdn }}</a>
              </div>
              <div class="bisual-table-col">{{ h.company_id != null ? h.company.fact_name : 'Bisual' }}</div>
              <div class="bisual-table-col">{{ h.stripe_id != null && h.quantity!=null && h.quantity>0 ? ((h.quantity | currency) + "/" + (h.renewal_days_period | daysToPeriod)) :  '-'}}</div>
              <div class="bisual-table-col">
                <div class="status-point" [ngClass]="h.status" style="margin-right: 5px;"></div>
                {{ getHostingStatusTooltip(h.status) }}
              </div>
            </div>
          }
        </div>
      </div>
    }
    <div class="card">
      <div class="card-header">
        <div class="card-title">
          <h2>Proyectos</h2>
          <p class="subtitle">Proyectos alojados en este servidor</p>
        </div>
        @if (hosting.projects.length==0) {
          <p>No hay proyectos asociados a este servidor.</p>
        }
        @if (hosting.projects.length>0) {
          <div class="bisual-table">
            <div class="bisual-table-row-header">
              <div class="bisual-table-col">Nombre</div>
              <div class="bisual-table-col">Propietario</div>
              <div class="bisual-table-col">Responsable</div>
              <div class="bisual-table-col">Estado</div>
            </div>
            @for (p of hosting.projects; track p) {
              <div class="bisual-table-row" >
                <div class="bisual-table-col">
                  <a [routerLink]="['/employees', 'development', 'projects', p.id]">{{ p.title }}</a>
                </div>
                <div class="bisual-table-col">{{ p.company.fact_name }}</div>
                <div class="bisual-table-col">{{ '-' }}</div>
                <div class="bisual-table-col">{{ p.active_project_phase.project_phase_stage.title }} ({{ p.active_project_phase.title }})</div>
              </div>
            }
          </div>
        }
      </div>
    </div>
  </div>
}
