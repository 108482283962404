import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Department } from 'src/app/models/department.model';
import { ApiService } from 'src/app/services/api.service';
import { ActivatedRoute } from '@angular/router';
import { DepartmentService } from 'src/app/services/department.service';


@Component({
  selector: 'app-add-department-category-dialog',
  templateUrl: './add-department-category-dialog.component.html',
  styleUrls: ['./add-department-category-dialog.component.css']
})
export class AddDepartmentCategoryDialogComponent implements OnInit {

  categoryForm:UntypedFormGroup;
  departments:Department[];

  constructor(private fb:UntypedFormBuilder,
              public dialogRef: MatDialogRef<AddDepartmentCategoryDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data:any,
              private api:ApiService,
              private activatedRoute:ActivatedRoute,
              private departmentService:DepartmentService) {

  this.departments = data.departments;
  this.categoryForm = this.fb.group({
    name: ['', Validators.compose([Validators.required])],
    description: ['', Validators.compose([Validators.required])],
    //num_employees: ['', Validators.compose([Validators.required])],
    min_salary: ['', Validators.compose([Validators.required])],
    max_salary: ['', Validators.compose([Validators.required])],
    department_id: [data.department_id, Validators.compose([Validators.required])],
  });

  }

  ngOnInit(): void {
  }

  cancel(): void {
    this.dialogRef.close();
  }


  checkValue(event: any) {
  }
}


