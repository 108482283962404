<h1 mat-dialog-title>Enviar estado de tablero</h1>

<mat-dialog-content [formGroup]="form">
  <div class="container">
    <div class="row">
      <div class="col-12 col-lg-6">
        <mat-form-field appearance="outline">
          <mat-label>Tipo de columnas</mat-label>
          <mat-select formControlName="col_roles" multiple>
            <mat-select-trigger>
              {{ form.value.col_roles.length == column_roles_arr.length ? 'Todas' : (form.value.col_roles.length + ' columnas') }}
            </mat-select-trigger>
            @for (col of column_roles | keyvalue; track col) {
              <mat-option [value]="col.key">
                {{ col.value.name }}
              </mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-12 col-lg-6">
        <mat-form-field appearance="outline">
          <mat-label>Filtrar por fechas de movimientos:</mat-label>
          <mat-date-range-input [rangePicker]="picker">
            <input matStartDate formControlName="from_date" placeholder="Desde...">
            <input matEndDate formControlName="to_date" placeholder="Hasta...">
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
      </div>
    </div>
    <div class="row" style="margin-top: 10px">
      <div class="col-12">
        <mat-form-field appearance="outline">
          <mat-label>Usuarios a notificar</mat-label>
          <mat-chip-grid #chipGridUsers>
            @for (user of form.value.user_notifiables; track user; let i = $index) {
              <mat-chip-row (removed)="removeUser(i)">
                {{ user.name + ' ' + user.surnames }}
                <button matChipRemove>
                  <mat-icon>cancel</mat-icon>
                </button>
              </mat-chip-row>
            }
          </mat-chip-grid>
          <input placeholder="Buscar usuarios..." #usersInput [formControl]="usersSearchCtrl"
            [matChipInputFor]="chipGridUsers" [matAutocomplete]="autoUsers"/>
          <mat-autocomplete #autoUsers="matAutocomplete" (optionSelected)="selectedUser($event.option.value)">
            @for (user of filteredUsers | async; track user) {
              <mat-option [value]="user">
                {{ user.name + ' ' + user.surnames }}
              </mat-option>
            }
          </mat-autocomplete>
        </mat-form-field>
      </div>
    </div>

    <div class="row" style="margin-top: 10px">
      <div class="col-12">
        <mat-form-field appearance="outline">
          <mat-label>Canales de comunicación de empresa</mat-label>
          <mat-chip-grid #chipGridChannels>
            @for (channel of form.value.company_notification_channels; track channel; let i = $index) {
              <mat-chip-row (removed)="removeChannel(i)">
                {{ channel.email != null ? channel.email : (channel.slack_channel_name != null ? (channel.slack_channel_name + ' (Slack)') : (channel.teams_channel_name!=null ? (channel.teams_channel_name + ' (Teams)') : '?')) }}
                <button matChipRemove>
                  <mat-icon>cancel</mat-icon>
                </button>
              </mat-chip-row>
            }
          </mat-chip-grid>
          <input placeholder="Buscar canales..." #channelsInput [formControl]="channelsSearchCtrl"
            [matChipInputFor]="chipGridChannels" [matAutocomplete]="autoChannels"/>
          <mat-autocomplete #autoChannels="matAutocomplete" (optionSelected)="selectedChannel($event.option.value)">
            @for (channel of filteredChannels | async; track channel) {
              <mat-option [value]="channel">
                {{ channel.email != null ? (channel.email + ' (Email)') : (channel.slack_channel_name != null ? (channel.slack_channel_name + ' (Slack)') : '?') }}
              </mat-option>
            }
          </mat-autocomplete>
        </mat-form-field>
      </div>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-flat-button (click)="close()">Cancelar</button>
  @if (previewText != null) {
    <button mat-flat-button (click)="copyPreviewText()">
      <mat-icon>content_copy</mat-icon>
      Copiar texto
    </button>
  }
  <button mat-flat-button color="accent" [disabled]="!form.valid" (click)="submit()">
    <mat-icon>send</mat-icon>
    Enviar notificación
  </button>
</mat-dialog-actions>
