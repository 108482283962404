<h1 mat-dialog-title>Registrar tiempo</h1>

<mat-dialog-content [formGroup]="form">
  <div class="container">
    <div class="row">
      <div class="col-12 col-lg-5">
        <div class="row">
          <div class="col-12">
            <mat-label>Fecha</mat-label>
            <mat-form-field appearance="outline" class="nobottom">
              <input [max]="maxDate" matInput [matDatepicker]="picker" [formControl]="dateControl">
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-lg-6">
            <mat-label>Tiempo</mat-label>
            <mat-form-field appearance="outline" class="nobottom">
              <input type="text" matInput [formControl]="timeControl" placeholder="3h 30m" />
            </mat-form-field>
          </div>
          <div class="col-12 col-lg-6">
            <mat-label>Inicio</mat-label>
            <mat-form-field appearance="outline" class="nobottom">
              <input type="time" matInput [formControl]="startTimeControl" />
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <mat-label>Categoria</mat-label>
            <mat-form-field appearance="outline" class="nobottom">
              <mat-select [formControlName]="is_project_category ? null : 'working_time_category_id'" (selectionChange)="onCategoryChange($event,-1)">
                @for (wtc of working_time_categories; track wtc) {
                  <mat-option [value]="wtc.id">
                    {{ wtc.name }}
                  </mat-option>
                }
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        @if (is_project_category) {
          <div class="row">
            <div class="col-12">
              <mat-label>Proyecto</mat-label>
              <mat-form-field appearance="outline" class="nobottom">
                <input type="text" matInput [formControl]="projectControl" [matAutocomplete]="auto" placeholder="Buscar proyectos...">
                <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectProject($event.option.value)" [displayWith]="displayFnProject">
                  @for (p of filtered_projects; track p) {
                    <mat-option [value]="p">{{ p.title }} </mat-option>
                  }
                </mat-autocomplete>
                @if (form.value['project_id']!='') {
                  <button matSuffix mat-icon-button aria-label="Clear" (click)="resetProject()">
                    <mat-icon>close</mat-icon>
                  </button>
                }
              </mat-form-field>
            </div>
          </div>
        }

        @for (subcategory_available_level of available_working_time_categories; track subcategory_available_level; let i = $index) {
          <div class="row">
            <div class="col-12">
              <mat-label>{{ 'Sub. de ' + selected_working_time_categories[i].name }}</mat-label>
              <mat-form-field appearance="outline" class="nobottom">
                <mat-select formControlName="" (selectionChange)="onCategoryChange($event, i)">
                  @for (category of subcategory_available_level; track category) {
                    <mat-option [value]="category.id">
                      {{ category.name }}
                    </mat-option>
                  }
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        }

        @if (is_project_phase_category) {
          <div class="row">
            <div class="col-12">
              <mat-label>Fase</mat-label>
              <mat-form-field appearance="outline" class="nobottom">
                <input type="text" matInput [formControl]="projectPhaseControl" [matAutocomplete]="auto" placeholder="Buscar fases...">
                <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectProjectPhase($event.option.value)" [displayWith]="displayFnProjectPhase">
                  @for (p of filtered_project_phases; track p) {
                    <mat-option [value]="p">{{ p.title }} </mat-option>
                  }
                </mat-autocomplete>
                @if (form.value['project_phase_id']!='') {
                  <button matSuffix mat-icon-button aria-label="Clear" (click)="resetProjectPhase()">
                    <mat-icon>close</mat-icon>
                  </button>
                }
              </mat-form-field>
            </div>
          </div>
        }
      </div>

      <div class="col-12 col-lg-7">
        <mat-label>Descripción</mat-label>
        <mat-form-field appearance="outline" style="width: 100%;" class="nobottom">
          <textarea matInput formControlName="description" rows="14"></textarea>
        </mat-form-field>
      </div>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button (click)="onNoClick()" cdkFocusInitial type="button">Cancelar</button>
  <button mat-flat-button color="accent" (click)="save()" [disabled]="!form.valid || !checkCategoryAssignability()" type="submit">
    <mat-icon style="margin-right: 10px">add</mat-icon>
    Registrar tiempo
  </button>
</mat-dialog-actions>
