import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { ComponentCanDeactivate } from 'src/app/guards/pending-changes.guard';
import { MeetingRecordComponent } from '../../../../meeting-record/meeting-record.component';

@Component({
  selector: 'app-project-meeting-record-credit',
  templateUrl: './project-meeting-record-credit.component.html',
  styleUrls: ['./project-meeting-record-credit.component.css']
})
export class ProjectMeetingRecordCreditComponent implements OnInit, ComponentCanDeactivate {
  @ViewChild(MeetingRecordComponent) childComponent: MeetingRecordComponent;
  meetingableType:string = 'App\\Models\\Project';
  meetingableId:string;
  meetingRecordId:string;

  constructor( private activatedRoute:ActivatedRoute  ) {
    this.meetingableId = this.activatedRoute.parent?.snapshot.parent?.paramMap.get('id') as string; // This is the project id
    this.meetingRecordId = this.activatedRoute.snapshot.paramMap.get('meeting_id') as string; // This is the meeting record id
  }

  ngOnInit(): void {
  }

  canDeactivate(): Observable<boolean> | boolean {
    return this.childComponent.canDeactivate();
    return false;
  }

}
