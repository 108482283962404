import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { CompanyNotificationChannel } from 'src/app/models/company_notification_channel.model';
import { User } from 'src/app/models/user.model';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-project-phase-deploy-configuration-dialog',
  templateUrl: './project-phase-deploy-configuration-dialog.component.html',
  styleUrls: ['./project-phase-deploy-configuration-dialog.component.css']
})
export class ProjectPhaseDeployConfigurationDialogComponent implements OnInit {
  form:FormGroup;

  company_users:User[] = [];
  company_notification_channels:CompanyNotificationChannel[] = [];

  private project_phase_deploy_configuration_dialog_id:number = null as any;

  usersSearchCtrl = new FormControl('');
  filteredUsers: Observable<User[]>;

  channelsSearchCtrl = new FormControl('');
  filteredChannels: Observable<CompanyNotificationChannel[]>;

  get isCreate() { return this.project_phase_deploy_configuration_dialog_id == null; }

  constructor(
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: {project_phase_id:number, company_id: number},
    private api:ApiService,
    private fb:FormBuilder
  ) {
    this.form = this.fb.group({
      day_of_week_iso: ['', Validators.compose([Validators.required, Validators.min(1), Validators.max(7)])],
      local_time: ['', Validators.required],
      user_notifiables: [[]],
      company_notification_channels: [[]],
    });

    this.initFilteredUsers();
    this.initFilteredChannels();
  }

  ngOnInit(): void {
    this.fetchProjectPhase();
    this.fetchCompanyUsers();
    this.fetchCompanyNotificationChannels();
  }

  submit() {
    const body = {...this.form.value};
    body.user_notifiables = body.user_notifiables.map((item:User) => item.id);
    body.company_notification_channels = body.company_notification_channels.map((item:CompanyNotificationChannel) => item.id);
    if(this.isCreate) {
      this.api.createProjectDeployWeeklyNotificationConfiguration(this.data.project_phase_id, body).subscribe(
        data => {
          this.close();
        }
      );
    }
    else {
      this.api.updateProjectDeployWeeklyNotificationConfiguration(this.data.project_phase_id, body).subscribe(
        data => {
          this.close();
        }
      );
    }
  }

  close(): void {
    this.dialogRef.close();
  }

  deleteConfiguration() {
    this.api.deleteProjectDeployWeeklyNotificationConfiguration(this.data.project_phase_id).subscribe(
      data => {
        this.close();
      }
    );
  }

  removeUser(idx:number) {
    const users:User[] = this.form.value.user_notifiables;
    users.splice(idx, 1);
    this.form.controls['user_notifiables'].patchValue(users);
  }

  removeChannel(i:number) {
    const channels:CompanyNotificationChannel[] = this.form.value.company_notification_channels;
    channels.splice(i, 1);
    this.form.controls['company_notification_channels'].patchValue(channels);
  }

  selectedUser(user:User) {
    const users:User[] = this.form.value.user_notifiables;
    users.push(user);
    this.form.controls['user_notifiables'].patchValue(users);
    this.usersSearchCtrl.setValue('');
  }

  selectedChannel(channel:CompanyNotificationChannel) {
    const channels:CompanyNotificationChannel[] = this.form.value.company_notification_channels;
    channels.push(channel);
    this.form.controls['company_notification_channels'].patchValue(channels);
    this.channelsSearchCtrl.setValue('');
  }

  private fetchProjectPhase() {
    this.api.getProjectDeployWeeklyNotificationConfiguration(this.data.project_phase_id, { with: 'user_notifiables,company_notification_channels' }).subscribe(
      data => {
        this.form.patchValue(data);
        this.project_phase_deploy_configuration_dialog_id = data.id;
      }
    );
  }

  private fetchCompanyUsers() {
    this.api.getCompanyUsers(this.data.company_id).subscribe(
      data => {
        this.company_users = data;
      }
    );
  }

  private fetchCompanyNotificationChannels() {
    this.api.getCompanyCompanyNotificationChannels(this.data.company_id).subscribe(
      data => {
        this.company_notification_channels = data;
      }
    );
  }

  private initFilteredUsers() {
    this.filteredUsers = this.usersSearchCtrl.valueChanges.pipe(
      startWith(null),
      map((user: string | null) => (user && (typeof user === "string") ? this._filterUsers(user) : this.company_users.slice())),
    );
  }

  private _filterUsers(user: string): User[] {
    const filterValue = user.toLowerCase();

    return this.company_users.filter(user => user.name.toLowerCase().includes(filterValue));
  }

  private initFilteredChannels() {
    this.filteredChannels = this.channelsSearchCtrl.valueChanges.pipe(
      startWith(null),
      map((channel: string | null) => (channel && (typeof channel === "string") ? this._filterChannels(channel) : this.company_notification_channels.slice())),
    );
  }

  private _filterChannels(channel: string): CompanyNotificationChannel[] {
    const filterValue = channel.toLowerCase();

    return this.company_notification_channels.filter(channel => channel.email?.toLowerCase().includes(filterValue) || channel.slack_channel_name?.toLowerCase().includes(filterValue));
  }

}
