import { Pipe, PipeTransform } from '@angular/core';
import { Contract } from '../models/contract.model';
import { ProjectPhase } from '../models/project_phase.model';

@Pipe({
  name: 'contractToState'
})
export class ContractToStatePipe implements PipeTransform {

  transform(value:ProjectPhase):string {
    let today = new Date();
    if(value.contract.starts_at > today){
      return "Por empezar";
    } else if(value.contract.starts_at <= today && value.contract.ends_at > today) {
      return "En curso";
    } else if (value.contract.ends_at < today && value.active_boards != 0) {
      return "Overflow";
    }else if(value.contract.ends_at < today && value.active_boards == 0){
      return "Finalizado";
    } else {
      return "";
    }
  }

}
