import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { CloudflareZoneDns } from 'src/app/models/cloudflare_zone_dns.model';
import { Domain } from 'src/app/models/domain.model';
import { ApiService } from 'src/app/services/api.service';
import { PermissionsService } from 'src/app/services/permissions.service';
import { RegexService } from 'src/app/services/regex.service';

@Component({
  selector: 'app-domain-detail-dns',
  templateUrl: './domain-detail-dns.component.html',
  styleUrls: ['./domain-detail-dns.component.css']
})
export class DomainDetailDnsComponent implements OnInit {

  private _domain:Domain = null as any;
  get domain() { return this._domain; };
  @Input() set domain(d:Domain) {
    if(d!=null) {
      this._domain = d;

      if(this.domain.godaddy_domain != null) {
        this.setGodaddyDnsFields(this._domain.godaddy_domain.nameServers);
        this.setCloudflareDnsFields(this._domain.cloudflare_zone_dns);
      }
    }
  }

  godaddyForm:UntypedFormArray;
  dnsForm:UntypedFormArray;

  constructor(private fb:UntypedFormBuilder,
              private regex:RegexService,
              private api:ApiService,
              public permissions:PermissionsService) {

    this.godaddyForm = this.fb.array([]);
    this.dnsForm = this.fb.array([]);

  }

  ngOnInit(): void {
  }

  connectDns() {
    if(confirm("¿Estás seguro que quieres conectar Godaddy con Cloudflare? Se migrarán tus DNS actuales a Cloudflare y podrás modificarlas desde aquí.")) {
      this.api.connectDns(this.domain.id).subscribe(
        data => {
          this.domain = data;
          this.copyCloudflareDns();
        }
      );
    } 
  }

  /**
   * GODADDY FORM
   */
  addGodaddyDnsField() {
    const regex = this.regex.getRegex();
    this.godaddyForm.push(new UntypedFormControl('', Validators.compose([Validators.required, Validators.pattern(new RegExp(regex['fqdn']))])))
  }

  setGodaddyDnsFields(nameservers:string[]) {
    const regex = this.regex.getRegex();
    this.godaddyForm.clear();
    nameservers.forEach(n => {
      this.godaddyForm.push(new UntypedFormControl(n, Validators.compose([Validators.required, Validators.pattern(new RegExp(regex['fqdn']))])));
    });
  }

  removeGodaddyDnsField(index:number) {
    this.godaddyForm.removeAt(index);
  }

  copyCloudflareDns() {
    this.setGodaddyDnsFields(this.domain.cloudflare_zone.name_servers);
  }

  getGodaddyControl(i:number) {
    return this.godaddyForm.controls[i] as UntypedFormControl;
  }

  changeGodaddyDns() {
    if(confirm("¿Estás seguro que quieres actualizar los nombres de servidores de Godaddy?")) {
      this.api.changeGodaddyDns(this.domain.id, this.godaddyForm.value).subscribe(
        data => {
          this.domain.godaddy_domain.nameServers = this.godaddyForm.value as string[];
        }
      );
    }
  }

  /**
   * CLOUDFLARE FORM
   */
   addCloudflareDnsField() {
    const regex = this.regex.getRegex();
    this.dnsForm.push(this.createCloudflareDnsRegisterFormGroup());
  }

  setCloudflareDnsFields(dns_registers:CloudflareZoneDns[]) {
    const regex = this.regex.getRegex();
    this.dnsForm.clear();
    dns_registers.forEach(dns => {
      const formGroup = this.createCloudflareDnsRegisterFormGroup();
      formGroup.patchValue(dns);
      this.dnsForm.push(formGroup);
    });
  }

  removeCloudflareDnsField(index:number) {
    this.dnsForm.removeAt(index);
  }

  getCloudflareFormGroup(i:number) {
    return this.dnsForm.controls[i] as UntypedFormGroup;
  }

  saveCloudflareDns(index:number) {
    const control = this.dnsForm.controls[index];
    control.disable();
    if(control.value['id']!=null && control.value['id']!="") { // update
      this.api.updateCloudflareDns(this.domain.id, control.value).subscribe(
        data => {
          control.enable();
        },
        error => {
          control.enable();
        }
      );
    }
    else { // create
      this.api.createCloudflareDns(this.domain.id, control.value).subscribe(
        data => {
          control.enable();
        },
        error => {
          control.enable();
        }
      );
    }
  }

  deleteCloudflareDns(index:number) {
    if(confirm("¿Estás seguro que quieres eliminar este registro DNS?")) {
      const control = this.dnsForm.controls[index];
      control.disable();
      this.api.deleteCloudflareDns(this.domain.id, control.value).subscribe(
        data => {
          this.dnsForm.removeAt(index);
        },
        error => {
          control.enable();
        }
      );
    }
  }

  private createCloudflareDnsRegisterFormGroup() {
    return this.fb.group({
      id: [''],
      type: ['', Validators.required],
      name: ['', Validators.required],
      content: ['', Validators.required],
      ttl: [1, Validators.compose([Validators.required, Validators.min(1)])],
      priority: [10, Validators.compose([Validators.required, Validators.min(0), Validators.max(65535)])],
      proxied: [false, Validators.required],
      proxiable: [false]
    });
  }

}
