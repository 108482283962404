import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'HourStringToSeconds'
})
export class HourStringToSecondsPipe implements PipeTransform {

  transform(time:string):number {
    let res:number[] = [0,0];
    let split = time.split("h");
    let restant = "";
    if(split.length>1) { // te hora
      res[0] = +split[0];
      restant = split[1];
    }
    else restant = split[0];

    const split_minutes = restant.split("m"); // busquem minuts
    if(split_minutes.length>0 && !isNaN(+split_minutes[0])) res[1] = +split_minutes[0];

    return res[0]*3600 + res[1]*60;
  }

}
