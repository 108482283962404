<div class="container">
  <div class="row">
    <div class="col-12">
      <div class="page-title">
        <h1>Planificación de facturación</h1>

        @if (filterForm.value['year']!=null) {
          <div class="date-controller card">
            <button mat-icon-button (click)="changeYear(-1)" [disabled]="dataSource.data.length==0"><mat-icon>chevron_left</mat-icon></button>
            <span class="date">Año {{ filterForm.value['year'] }}</span>
            <button mat-icon-button (click)="changeYear(1)" [disabled]="dataSource.data.length==0"><mat-icon>chevron_right</mat-icon></button>
          </div>
        }

        @if (filterForm.value['year']!=null) {
          <div class="month-selector card">
            <mat-form-field appearance="outline">
              <mat-select [formControl]="monthControl" multiple [disabled]="dataSource.data.length==0">
                @for (month of months; track month) {
                  <mat-option [value]="month.key">
                    {{ month.value }}
                  </mat-option>
                }
              </mat-select>
            </mat-form-field>
          </div>
        }
      </div>
    </div>
  </div>
</div>

<div class="container">
  <div class="row">
    <div class="col-12">
      <div class="bisual-table-container">
        @if (dataSource.data.length > 0) {
          <table mat-table [dataSource]="dataSource">
            <ng-container matColumnDef="concept">
              <th mat-header-cell *matHeaderCellDef>
                <button mat-icon-button>
                  <mat-icon [matTooltip]="'Los importes son netos, es decir, sin IVA. En el caso de la plantilla, se coge el coste total con SS.'">info</mat-icon>
                </button>
                <button mat-icon-button color="accent" (click)="openAddDialog()">
                  <mat-icon>add</mat-icon>
                </button>
              </th>
              <td mat-cell *matCellDef="let row;" [matTooltip]="row.name">{{ row.name }}</td>
              <th mat-footer-cell *matFooterCellDef>
                <p id="warn-message">
                  <mat-icon color="red" style="margin-left: 10px">error</mat-icon>
                  Todos los importes son netos, sin IVA
                </p>
              </th>
            </ng-container>
            @for (month of months; track month; let i = $index) {
              <ng-container [matColumnDef]="month.key">
                <th mat-header-cell *matHeaderCellDef [ngClass]="'mat-column-month'">{{ month.value }}</th>
                <td mat-cell *matCellDef="let row;" [ngClass]="'mat-column-month'">
                  <mat-form-field appearance="outline">
                    <input matInput [ngModel]="row[month.key]" type="number" min="0" [disabled]="row.name==='Plantilla'" (focusout)="changeInputEvt(row, i, $event)" />
                  </mat-form-field>
                </td>
                <th mat-footer-cell *matFooterCellDef [ngClass]="{'green-text': totals[i] > 0, 'red-text': totals[i] < 0}">{{ totals[i] | currency }}</th>
              </ng-container>
            }
            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="row.type"></tr>
            <tr mat-footer-row *matFooterRowDef="displayedColumns; sticky: true;"></tr>
          </table>
        }
      </div>
    </div>
  </div>
</div>
