import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { UserService } from '../services/user.service';
import { Role } from '../models/role.model';
import { User } from '../models/user.model';

@Injectable()
export class EmployeeGuard  {

  constructor(private router: Router,
              private userService:UserService) {
  }

  async canActivate(route:ActivatedRouteSnapshot) {
    const user:User = this.userService.getCurrentUser();
    if(user != null && user.role.slug != "worker") {
      return false;
    }
    return true;
  }
}
