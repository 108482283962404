<h1 mat-dialog-title>Añadir registro de previsión de facturación</h1>
<mat-dialog-content [formGroup]="form">
  <div class="container">
    <div class="row">
      <div class="col-6">
        <mat-form-field appearance="outline" cdkFocusInitial>
          <mat-label>Tipo de registro</mat-label>
          <mat-select formControlName="type">
            <mat-option value="income">Ingreso</mat-option>
            <mat-option value="expense">Gasto</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-6">
        <mat-form-field appearance="outline">
          <mat-label>Selecciona el mes</mat-label>
          <mat-select formControlName="month">
            @for (month of months; track month; let i = $index) {
              <mat-option [value]="i+1">{{ month }}</mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-6">
        @if (form.value['type']==='expense') {
          <mat-form-field appearance="outline">
            <mat-label>Categoria de gasto</mat-label>
            <mat-select formControlName="planned_expense_category_slug">
              @for (c of categories; track c) {
                <mat-option [value]="c.slug">{{ c.name }}</mat-option>
              }
            </mat-select>
          </mat-form-field>
        }

        @if (form.value['type']==='income') {
          <mat-form-field appearance="outline">
            <mat-label>Proyecto</mat-label>
            <input type="text" matInput [formControl]="projectsControl" [matAutocomplete]="auto">
            <mat-autocomplete #auto="matAutocomplete">
              @for (project of filteredProjects; track project) {
                <mat-option [value]="project.id" (click)="selectProject(project)">
                  {{project.title}}
                </mat-option>
              }
            </mat-autocomplete>
          </mat-form-field>
        }
      </div>

      <div class="col-6">
        <mat-form-field appearance="outline">
          <mat-label>Importe</mat-label>
          <input matInput type="number" min="0" formControlName="value" />
          <mat-icon matSuffix>euro</mat-icon>
        </mat-form-field>
      </div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button (click)="onNoClick()" type="button">Cancelar</button>
  <button mat-flat-button color="accent" [mat-dialog-close]="form.value" [disabled]="!form.valid" type="submit">
    <mat-icon style="margin-right: 10px">add</mat-icon>
    Añadir
  </button>
</mat-dialog-actions>
