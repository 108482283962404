import { Component, OnInit } from '@angular/core';
import { CalendarEvent, CalendarView, DAYS_OF_WEEK } from 'angular-calendar';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-vacations',
  templateUrl: './vacations.component.html',
  styleUrls: ['./vacations.component.css']
})
export class VacationsComponent implements OnInit {

  events:CalendarEvent[] = [];
  viewDate: Date = new Date();

  locale: string = 'es';
  weekStartsOn: number = DAYS_OF_WEEK.MONDAY;
  weekendDays: number[] = [DAYS_OF_WEEK.SATURDAY, DAYS_OF_WEEK.SUNDAY];
  refresh = new Subject<void>();
  view: CalendarView = CalendarView.Month;

  constructor(private api:ApiService) {

  }

  ngOnInit(): void {
    this.fetchVacations();
    this.fetchHolidays();
    this.fetchBirthdays();
  }

  onViewDateChange() {
    this.events = [];
    this.fetchVacations();
    this.fetchHolidays();
    this.fetchBirthdays();
  }

  private fetchVacations() {
    const from = moment(this.viewDate).startOf('month').toDate();
    const to = moment(this.viewDate).endOf('month').toDate();
    this.api.getVacations(from, to).subscribe(
      data => {
        this.events.push(...data.map(item => {
          return {
            title: item.user.name + ' ' + item.user.surnames,
            color: {
              primary: this.stringToColour(item.user.name + ' ' + item.user.surnames),
              secondary: '#FAE3E3',
            },
            start: item.from_date,
            end: item.to_date,
            allDay: item.all_day,
            meta: {
              id: item.id,
              imageUrl: item.user.profile_picture_url,
              type: 'vacation'
            }
          };
        }));
        this.refresh.next();
      }
    );
  }

  private stringToColour(str: string): string {
    let hash = 0;

    str = str + 'ivitheboss';

    // Generar un hash a partir del string
    for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }

    // Convertir el hash a un color hexadecimal
    let colour = '#';
    for (let i = 0; i < 6; i++) {
        const value = (hash >> (i * 4)) & 0xF; // Tomamos 4 bits en lugar de 8
        colour += value.toString(16).padStart(1, '0');
    }

    // Normalizar el color a un formato RGB extendido
    if (colour.length === 4) {
        colour = '#' + colour[1] + colour[1] + colour[2] + colour[2] + colour[3] + colour[3];
    }

    return colour;
  }


  private fetchHolidays() {
    const from = moment(this.viewDate).startOf('month').toDate();
    const to = moment(this.viewDate).endOf('month').toDate();
    this.api.getHolidaysFromActiveWorkingContracts(from, to).subscribe(
      data => {
        let grouped: any[] = [];

        data.forEach(item => {
          const index = grouped.findIndex((i:any) => i.slug == item.slug);
          if(index==-1) {
            grouped.push({
              name: item.name,
              slug: item.slug,
              users: item.username + (item.surnames ? ' ' + item.surnames : ''),
              from_date: item.date,
              to_date: item.date,
              all_day: true,
            });
          } else {
            grouped[index].users += ', ' + item.username + (item.surnames ? ' ' + item.surnames : '');
          }
        });

        this.events.push(...grouped.map(item => {
          return {
            title: item.name + ' (' + item.users + ')',
            color: {
              primary: '#FFD700',
              secondary: '#FAE3E3',
            },
            start: item.from_date,
            end: item.to_date,
            allDay: true,
            meta: {
              id: item.id,
              type: 'holiday'
            }
          };
        }));

        this.refresh.next();
      }
    );
  }

  private fetchBirthdays() {
    const from = moment(this.viewDate).startOf('month').toDate();
    const to = moment(this.viewDate).endOf('month').toDate();
    this.api.getBirthdays(from, to).subscribe(
      data => {
        this.events.push(...data.map(item => {
          return {
            title: item.user.name + ' ' + item.user.surnames + ' (+' + item.age + ')',
            color: {
              primary: '#FF4500',
              secondary: '#FAE3E3',
            },
            start: moment(moment(this.viewDate).year().toString() + item.user.birthday, 'YYYY-MM-DD').toDate(),
            end: moment(moment(this.viewDate).year().toString() + item.user.birthday, 'YYYY-MM-DD').toDate(),
            allDay: true,
            meta: {
              id: item.user.id,
              type: 'birthday'
            }
          };
        }));
        this.refresh.next();
      }
    );

  }

}
