import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { UtilsService } from "../services/utils.service";

@Injectable({
    providedIn: 'root'
})
export class LoadingInterceptor implements HttpInterceptor {
  constructor(private utilsService:UtilsService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const tractable:boolean = (request.url.indexOf(environment.laravel_url) != -1);

    //tractem request
    if(tractable && request.headers.get('avoid-loading') === null) {
        this.utilsService.enableLoading();
    }

    //tractem response
    return next.handle(request).pipe(
        catchError((error:HttpErrorResponse) => {
            if(tractable) {
                this.utilsService.disableLoading();
            }
            return throwError(error);
        }),
        map((event:HttpEvent<any>) => {
            if(event instanceof HttpResponse && tractable) {
                this.utilsService.disableLoading();
            }
            return event;
        })
    );
  }
}
