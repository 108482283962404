import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-users-management',
  templateUrl: './users-management.component.html',
  styleUrls: ['./users-management.component.css']
})
export class UsersManagementComponent implements OnInit {

  navLinks: any[] = [
    {
        label: 'Usuarios',
        link: './users',
        index: 0
    }, 
    {
        label: 'Empresas',
        link: './companies',
        index: 1
    }, 
    {
        label: 'Proveedores',
        link: './providers',
        index: 2
    }, 
];

activeLinkIndex = 0; 

constructor(private router:Router) { }

ngOnInit(): void {
  this.router.events.subscribe((res) => {
    this.activeLinkIndex = this.navLinks.indexOf(this.navLinks.find(tab => tab.link === '.' + this.router.url));
  });
}

}
