<div id="content">
  <div class="page-title">
    <h1>Acciones comerciales</h1>
  </div>

  <div class="bisual-table">
    <form class="card paginator" [formGroup]="filterForm">
      <mat-paginator [length]="length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" [pageIndex]="pageIndex"
        (page)="changePage($event)" style="margin-right: auto;">
      </mat-paginator>
    </form>

    <div class="bisual-table-container">
      <table mat-table [dataSource]="dataSource">

        <ng-container matColumnDef="client">
          <th mat-header-cell *matHeaderCellDef>Cliente</th>
          <td mat-cell *matCellDef="let item">{{ item.potential_client.full_name }}</td>
        </ng-container>

        <ng-container matColumnDef="commercial">
          <th mat-header-cell *matHeaderCellDef>Comercial</th>
          <td mat-cell *matCellDef="let item">{{ item.potential_client.commercial.name + ' ' + item.potential_client.commercial.surnames }}</td>
        </ng-container>

        <ng-container matColumnDef="updater">
          <th mat-header-cell *matHeaderCellDef>Actualizador</th>
          <td mat-cell *matCellDef="let item">{{ item.user.name + ' ' + item.user.surnames }}</td>
        </ng-container>

        <ng-container matColumnDef="change">
          <th mat-header-cell *matHeaderCellDef>Cambio</th>
          <td mat-cell *matCellDef="let item">
            <span class="status-badge">{{ item.old_status==null ? 'Nueva creación' : item.old_status.name }}</span>
            <mat-icon>trending_flat</mat-icon>
            <span class="status-badge">{{ item.new_status.name }}</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="created_at">
          <th mat-header-cell *matHeaderCellDef>Fecha</th>
          <td mat-cell *matCellDef="let item">{{ item.created_at | date:'short' }}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
  </div>

</div>
