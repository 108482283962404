<div class="page-title">
  <h1>Hostings</h1>
  <button mat-flat-button color="accent" (click)="addHosting()" [disabled]="!permissions.validateDepartment(['direction', 'sysadmin'])">
    <mat-icon style="margin-right: 10px">add</mat-icon>
    Añadir hosting
  </button>
</div>

<form class="bisual-table" [formGroup]="filterForm">
  <div class="card paginator">
    <mat-paginator
      [length]="length"
      [pageSize]="pageSize"
      [pageSizeOptions]="pageSizeOptions"
      [pageIndex]="pageIndex"
      (page)="changePage($event)"
      style="margin-right: auto;"
    ></mat-paginator>

    <mat-form-field>
      <input matInput type="text" formControlName="hosting_fqdn">
      <button matSuffix mat-icon-button aria-label="Clear">
        <mat-icon>search</mat-icon>
      </button>
    </mat-form-field>
  </div>

  <div class="bisual-table-two-columns">

    <div class="card">
      <div class="field">
        <mat-label>Propietario</mat-label>
        <mat-form-field appearance="outline">
          <input [formControl]="companies_property_control" type="text" placeholder="Escribe un nombre..." matInput [matAutocomplete]="autoCompany">
          <mat-autocomplete #autoCompany="matAutocomplete">
            @for (company of companies_filtered; track company) {
              <mat-option [value]="company.fact_name" (click)="selectCompanyFilter(company)">{{company.fact_name}}</mat-option>
            }
          </mat-autocomplete>
        </mat-form-field>
      </div>

      <div class="field">
        <mat-label>Hosting padre</mat-label>
        <mat-form-field appearance="outline">
          <input [formControl]="parent_hosting_control" type="text" placeholder="Busca un hosting" matInput [matAutocomplete]="autoHostingParent">
          <mat-autocomplete #autoHostingParent="matAutocomplete">
            @for (hosting of parent_hostings_filtered; track hosting) {
              <mat-option [value]="hosting.hosting_fqdn" (click)="selectParentHostingFilter(hosting)">{{hosting.hosting_fqdn}}</mat-option>
            }
          </mat-autocomplete>
        </mat-form-field>
      </div>

      <div class="field">
        <mat-label>Padre/hijo</mat-label>
        <mat-radio-group formControlName="parent_hosting_id">
          <mat-radio-button [value]="''">Todos</mat-radio-button>
          <mat-radio-button [value]="'null'">Servidores padre</mat-radio-button>
          <mat-radio-button [value]="'not null'">Servidores hijo</mat-radio-button>
        </mat-radio-group>
      </div>

      <div class="field">
        <mat-label>Proveedor</mat-label>
        <mat-radio-group formControlName="provider_id">
          <mat-radio-button [value]="''">Todos</mat-radio-button>
          @for (p of providers; track p) {
            <mat-radio-button [value]="p.id">{{ p.name }}</mat-radio-button>
          }
        </mat-radio-group>
      </div>

      <div class="field">
        <mat-label>Tipo de hosting</mat-label>
        <mat-radio-group formControlName="is_shared">
          <mat-radio-button [value]="''">Todos</mat-radio-button>
          <mat-radio-button [value]="true">Compartido</mat-radio-button>
          <mat-radio-button [value]="false">Privado</mat-radio-button>
        </mat-radio-group>
      </div>

      <div class="field">
        <mat-label>Estado</mat-label>
        <mat-radio-group formControlName="status">
          <mat-radio-button [value]="''">Todos</mat-radio-button>
          <mat-radio-button [value]="'active'" matTooltip="Sigue en activo">Activo</mat-radio-button>
          <mat-radio-button [value]="'inactive'" matTooltip="El cliente lo ha marcado como inactivo">Inactivo</mat-radio-button>
          <mat-radio-button [value]="'cancelled'" matTooltip="El cliente ha cancelado y se eliminará próximamente cuando caduque">Cancelado</mat-radio-button>
          <mat-radio-button [value]="'expired'" matTooltip="Ha expirado, y el cliente no ha informado de su estado nuevo">Expirado</mat-radio-button>
          <mat-radio-button [value]="'deleted'" matTooltip="El cliente lo canceló y ha expirado, eliminado de Hetzner">Eliminado</mat-radio-button>
        </mat-radio-group>
      </div>
    </div>

    <div class="bisual-table-container">
      <table mat-table [dataSource]="dataSource">

        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef></th>
          <td class="text-center" mat-cell *matCellDef="let hosting">
            <div [matTooltip]="getHostingStatusTooltip(hosting.status)" class="status-point" [ngClass]="hosting.status"></div>
          </td>
        </ng-container>

        <ng-container matColumnDef="hosting_fqdn">
          <th mat-header-cell *matHeaderCellDef>FQDN</th>
          <td mat-cell *matCellDef="let hosting">
            {{ hosting.hosting_fqdn }}
          </td>
        </ng-container>

        <ng-container matColumnDef="provider">
          <th mat-header-cell *matHeaderCellDef>Proveedor</th>
          <td mat-cell *matCellDef="let hosting">
            {{ hosting.parent_hosting != null ? hosting.parent_hosting.provider.name : hosting.provider.name }}
          </td>
        </ng-container>

        <ng-container matColumnDef="is_parent">
          <th mat-header-cell *matHeaderCellDef>Jerarquia</th>
          <td mat-cell *matCellDef="let hosting">
            {{ hosting.parent_hosting_id==null ? 'Padre' : 'Hijo' }}
          </td>
        </ng-container>

        <ng-container matColumnDef="is_shared">
          <th mat-header-cell *matHeaderCellDef>Compartido</th>
          <td mat-cell *matCellDef="let hosting">
            {{ hosting.is_shared ? 'Compartido' : 'Privado' }}
          </td>
        </ng-container>

        <ng-container matColumnDef="quantity">
          <th mat-header-cell *matHeaderCellDef>Precio</th>
          <td mat-cell *matCellDef="let hosting">
            {{ hosting.quantity!=null ? (hosting.quantity | currency) + ' / ' + (hosting.renewal_days_period | daysToPeriod) : 'Sin precio' }}
          </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let hosting; let i = index;">
            <button mat-icon-button [matMenuTriggerFor]="menu">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item [routerLink]="['/employees', 'development', 'services', 'hostings', hosting.id, 'resume']">
                <mat-icon matPrefix>visibility</mat-icon>
                Ver hosting
              </button>
              @if (hosting.stripe_id != null) {
                <button mat-menu-item (click)="cancelHosting(hosting)" matTooltip="Cancelará el hosting y esperará a eliminarlo a que expire." [disabled]="!permissions.validateDepartment(['direction', 'sysadmin'])">
                  <mat-icon matPrefix>block</mat-icon>
                  Cancelar hosting
                </button>
              }
              <button mat-menu-item (click)="deleteHosting(hosting)" matTooltip="Si es de Hetzner, lo eliminará y marcará como tal. Sino lo marcará como inactivo." [disabled]="!permissions.validateDepartment(['direction', 'sysadmin'])">
                <mat-icon matPrefix>delete</mat-icon>
                Eliminar hosting
              </button>
            </mat-menu>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
  </div>

</form>
