import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PlannedExpenseCategory } from 'src/app/models/planned-expense-category.model';
import { Project } from 'src/app/models/project.model';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-add-billing-planning-concept-dialog',
  templateUrl: './add-billing-planning-concept-dialog.component.html',
  styleUrls: ['./add-billing-planning-concept-dialog.component.css']
})
export class AddBillingPlanningConceptDialogComponent implements OnInit {

  form:UntypedFormGroup;
  months:string[] = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
  categories:PlannedExpenseCategory[] = [];
  filteredProjects:Project[] = [];
  projectsControl:FormControl = new FormControl();
  constructor(private fb:UntypedFormBuilder,
              public dialogRef: MatDialogRef<AddBillingPlanningConceptDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data:any,
              private api:ApiService) {
                this.form = this.fb.group({
                  type: ['', Validators.required],
                  project_id: [''],
                  planned_expense_category_slug: [''],
                  month: ['', Validators.compose([Validators.required, Validators.min(1), Validators.max(12)])],
                  value: ['', Validators.compose([Validators.required, Validators.min(0)])]
                });
              }

  ngOnInit(): void {
    this.fetchCategories();
    this.listenProjectsControl();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  selectProject(project:Project) {
    this.form.patchValue({
      'project_id': project.id
    });
  }

  private fetchCategories() {
    this.api.getPlannedExpenseCategories().subscribe(
      data => {
        this.categories = data;
      }
    );
  }

  private listenProjectsControl() {
    this.projectsControl.valueChanges.subscribe(
      data => {
        if(data.length > 2) {
          this.api.getProjects({search: data}).subscribe(
            data => {
              this.filteredProjects = data.data;
            }
          );
        }
        else this.filteredProjects = [];
      }
    );
  }

}
