<div id="content">
  <div class="page-title">
    <h1>Calendario de guardias</h1>

  </div>

  <div class="container card">
    <div class="row text-center">
      <div class="col-md-4">
        <div class="btn-group">
          <div
            class="btn btn-primary"
            mwlCalendarPreviousView
            [view]="view"
            [(viewDate)]="viewDate"
            (viewDateChange)="onViewDateChange()"
            >
            Anterior
          </div>
          <div
            class="btn btn-outline-secondary"
            mwlCalendarToday
            [(viewDate)]="viewDate"
            >
            Hoy
          </div>
          <div
            class="btn btn-primary"
            mwlCalendarNextView
            [view]="view"
            [(viewDate)]="viewDate"
            (viewDateChange)="onViewDateChange()"
            >
            Siguiente
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <h3>{{ viewDate | calendarDate:(view + 'ViewTitle'):'es' }}</h3>
      </div>
    </div>

    <ng-template #customCellTemplate
      let-day="day"
      let-openDay="openDay"
      let-locale="locale"
      let-tooltipPlacement="tooltipPlacement"
      let-highlightDay="highlightDay"
      let-unhighlightDay="unhighlightDay"
      let-eventClicked="eventClicked"
      >
      <div class="month-cell-fill-height">
        <div class="cal-cell-top">
          @if (isProjectManagerOrProductManager && now < day.date && day.date.getDay() == 1) {
            <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
              <mat-icon>more_vert</mat-icon>
            </button>
          }
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="assignNewGuard(day.date)" [disabled]="!isProjectManagerOrProductManager">
              <mat-icon>person_add</mat-icon>
              <span>Asignar guardia</span>
            </button>
            <button mat-menu-item (click)="unassignGuard(day)" [disabled]="!isProjectManagerOrProductManager">
              <mat-icon>person_remove</mat-icon>
              <span>Desasignar guardia</span>
            </button>
          </mat-menu>

          <span class="cal-day-number"
            >{{ day.date | calendarDate:'monthViewDayNumber':locale }}</span
            >
          </div>
          <div class="cal-events">
            @for (event of day.events; track event) {
              @if (event.meta.type !== 'guardian') {
                <div
                  class="cal-event"
                  [style.backgroundColor]="event.color.primary"
                  [ngClass]="event?.cssClass"
                  (mouseenter)="highlightDay.emit({event: event})"
                  (mouseleave)="unhighlightDay.emit({event: event})"
                  [mwlCalendarTooltip]="event.title | calendarEventTitle:'monthTooltip':event"
                  [tooltipPlacement]="tooltipPlacement"
                  (click)="$event.stopPropagation(); eventClicked.emit({event: event})"
                ></div>
              }
            }
          </div>

          <ul class="guardians">
            @for (event of day.events; track event) {
              @if (event.meta.type === 'guardian') {
                <li>
                  <img class="avatar" [src]="event.meta.user.profile_picture_url" alt="{{ event.meta.user.name + ' ' + event.meta.user.surnames }}" matTooltip="{{ event.meta.user.name + ' ' + event.meta.user.surnames }}" />
                </li>
              }
            }
          </ul>
        </div>
      </ng-template>

      <mwl-calendar-month-view
        [viewDate]="viewDate"
        [events]="events"
        [locale]="locale"
        [weekStartsOn]="weekStartsOn"
        [weekendDays]="weekendDays"
        [refresh]="refresh"
        (beforeViewRender)="beforeMonthViewRender($event)"
        (dayClicked)="onDayClicked($event)"
        [cellTemplate]="customCellTemplate"
        >
      </mwl-calendar-month-view>
    </div>
  </div>
