import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/models/user.model';
import { PermissionsService } from 'src/app/services/permissions.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-lateral-menu-employee',
  templateUrl: './lateral-menu-employee.component.html',
  styleUrls: ['./lateral-menu-employee.component.css']
})
export class LateralMenuEmployeeComponent implements OnInit {

  constructor(public permissions: PermissionsService) {
  }

  ngOnInit(): void {

  }

}
