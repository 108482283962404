import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxDropzoneChangeEvent } from 'ngx-dropzone';

@Component({
  selector: 'app-import-file-dialog',
  templateUrl: './import-file-dialog.component.html',
  styleUrls: ['./import-file-dialog.component.css']
})
export class ImportFileDialogComponent implements OnInit {
  files: File[] = [];
  title: string = 'Échamelo aquí bb uwu';
  accepted_file_types: string = '*';

  images() {
    return this.files.filter(f => f.type.startsWith('image/'));
  }

  noImages() {
    return this.files.filter(f => !f.type.startsWith('image/'));
  }

  constructor(public dialogRef: MatDialogRef<ImportFileDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data:any) {
    this.title = this.data.title ?? this.title;
    this.accepted_file_types = this.data.accepted_file_types ?? this.accepted_file_types;
  }

  ngOnInit(): void {
  }

  onSelect(event: NgxDropzoneChangeEvent) {
    this.files.push(...event.addedFiles);
  }

  onRemove(file: File) {
    this.files.splice(this.files.indexOf(file), 1);
  }

  close() {
    this.dialogRef.close();
  }

  done() {
    this.dialogRef.close(this.files);
  }
}
