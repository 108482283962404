import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Project } from 'src/app/models/project.model';
import { ProjectDefaultTimeDistribution } from 'src/app/models/project_default_time_distribution.model';
import { ProjectDefaultTimeDistributionDistributable } from 'src/app/models/project_default_time_distribution_distributable.model';
import { ProjectPhase } from 'src/app/models/project_phase.model';
import { ProjectTeam } from 'src/app/models/project_team.model';
import { WorkingTimeCategory } from 'src/app/models/working_time_category.model';
import { ApiService } from 'src/app/services/api.service';
import { SelectorDialogComponent } from '../../../../selector-dialog/selector-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-project-distribution-time-dialog',
  templateUrl: './project-distribution-time-dialog.component.html',
  styleUrls: ['./project-distribution-time-dialog.component.css']
})
export class ProjectDistributionTimeDialogComponent implements OnInit {

  project:Project = null as any;
  distribution:ProjectDefaultTimeDistribution = null as any;
  wtcs:WorkingTimeCategory[] = [];
  form:FormGroup;
  tags:{[key:string]:string} = {};
  mockRealTime:number = 150 / 4;
  project_teams:ProjectTeam[]|undefined;

  constructor(
    public dialogRef: MatDialogRef<ProjectDistributionTimeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {project_id:number},
    private api:ApiService,
    private fb:FormBuilder,
    private dialog:MatDialog,
    private snack:MatSnackBar
  ) {
    this.form = this.fb.group({});
  }

  ngOnInit(): void {
    if(this.data.project_id==null) this.close();
    this.fetchProject();
    this.fetchCurrentDistributionTime();
    this.fetchWorkingTimeCategoriesSchedulables();
    this.initPreviewHours();
    this.initProjectTeams();
  }

  close(): void {
    this.dialogRef.close();
  }

  save() {
    this.api.updateProjectDefaultDistributionTime(this.data.project_id.toString(), this.form.value).subscribe(
      data => {
        this.close();
      }
    );
  }

  handleFormChange(event:FormGroup) {
    this.form = event;
  }

  openProjectTeamDialog() {
    const dialogRef = this.dialog.open(SelectorDialogComponent, {
      width: '400px',
      data: {
        dialog_title: "Añadir equipo de proyecto",
        dialog_ok_button: "Añadir",
        dialog_cancel_button: "Cancelar",
        data: this.project_teams!.map(team => {
          return {
            value: team,
            textToShow: team.name
          };
        }),
      }
    });

    dialogRef.afterClosed().subscribe(
      (data:ProjectTeam|undefined) => {
        if(data) {
          if(this.form.controls["project_team_" + data.id] != null) {
            this.snack.open("El equipo " + data.name + " ya está en esta distribución. No se ha podido añadir", "Ok");
          }
          else this.addProjectTeam(data);
        }
      }
    );
  }

  private fetchProject() {
    this.api.getProject(this.data.project_id.toString(), { with: 'project_phases'}).subscribe(
      data => {
        this.project = data;
        if(this.distribution != null)
          this.buildFormAndTags();
      }
    );
  }

  private fetchCurrentDistributionTime() {
    this.api.getProjectCurrentDefaultTimeDistribution(this.data.project_id.toString()).subscribe(
      data => {
        this.distribution = data;
        if(this.project != null)
          this.buildFormAndTags()
      }
    );
  }

  private fetchWorkingTimeCategoriesSchedulables() {
    this.api.getWorkingTimeCategoriesSchedulables().subscribe(
      data => {
        this.wtcs = data
      }
    );
  }

  private buildFormAndTags() {
    this.buildForm();
    this.tags = this.iBuildTags(this.distribution.project_default_time_distribution_distributables);
  }

  private buildForm() {
    let formObj:any = {};
    this.distribution.project_default_time_distribution_distributables = this.distribution.project_default_time_distribution_distributables.sort((a, b) => {
      if(a.distributable_type == "App\\Models\\WorkingTimeCategory" && b.distributable_type == "App\\Models\\ProjectPhase") return -1;
      if(a.distributable_type == "App\\Models\\ProjectPhase" && b.distributable_type == "App\\Models\\WorkingTimeCategory") return 1;
      if(a.distributable_type == "App\\Models\\ProjectTeam" && b.distributable_type != "App\\Models\\ProjectTeam") return -1;
      if(a.distributable_type != "App\\Models\\ProjectTeam" && b.distributable_type == "App\\Models\\ProjectTeam") return 1;
      return 0;
    });
    this.distribution.project_default_time_distribution_distributables.forEach(d => {
      const key = this.generateFormKeyByDistributable(d);
      formObj[key] = this.buildFormRecursively(d);
    });

    this.form = this.fb.group(formObj);
  }

  private iBuildTags(ds:ProjectDefaultTimeDistributionDistributable[]):{[key:string]:string} {
    let res:{[key:string]:string} = {};
    ds.forEach(d => {
      res[this.generateFormKeyByDistributable(d)] =
        d.distributable_type == "App\\Models\\WorkingTimeCategory" ?
          (d.distributable as WorkingTimeCategory).name : (
            d.distributable_type === "App\\Models\\ProjectPhase" ?
            (d.distributable as ProjectPhase).title :
            (d.distributable as ProjectTeam).name
          )

      if(d.children.length) {
        res = {...res, ...this.iBuildTags(d.children)};
      }
    });
    return res;
  }

  private buildFormRecursively(d:ProjectDefaultTimeDistributionDistributable) {
    let formObj:any = {};
    formObj["prcent"] = [d.time_prcnt, Validators.compose([Validators.min(0), Validators.max(100)])];
    formObj["fixed"] = [d.time_fixed, Validators.min(0)];
    formObj["periodicity"] = [d.periodicity];
    formObj["is_fixed"] = [d.time_fixed != null];
    formObj["n_of_members"] = [d.n_of_members, Validators.min(0)];
    formObj["period_start_date"] = [d.period_start_date!=null ? d.period_start_date.toString() : null];

    if(d.children.length) {
      let formObjChildren:any = {};
      d.children.forEach(child => {
        const key:string = this.generateFormKeyByDistributable(child);
        formObjChildren[key] = this.buildFormRecursively(child);
      });
      formObj["children"] = this.fb.group(formObjChildren);
    }

    return this.fb.group(formObj);
  }

  private generateFormKeyByDistributable(d:ProjectDefaultTimeDistributionDistributable) {
    return d.distributable_type==="App\\Models\\WorkingTimeCategory" ? (d.distributable as WorkingTimeCategory).slug : (d.distributable_type === "App\\Models\\ProjectPhase" ? "project_phase_"+d.distributable.id : "project_team_"+d.distributable_id);
  }

  private initPreviewHours() {
    this.api.getContracts({
      project_id: this.data.project_id,
      type: 'partner',
      limit: 1,
      order_by: 'starts_at',
      order_by_direction: 'desc',
      with: 'current_contract_variable_condition'
    }).subscribe(
      data => {
        if(data.length > 0 && data[0].current_contract_variable_condition.partner_monthly_hours != null) {
          this.mockRealTime = data[0].current_contract_variable_condition.partner_monthly_hours / 4;
        }
        else this.mockRealTime = 150 / 4;
      }
    );
  }

  private initProjectTeams() {
    this.api.getProjectProjectTeams(this.data.project_id.toString()).subscribe(
      data => {
        this.project_teams = data;
      }
    );
  }

  private addProjectTeam(team:ProjectTeam) {
    const key:string = "project_team_" + team.id;
    this.form.addControl(key, this.fb.group({
      prcent: [null, Validators.compose([Validators.min(0), Validators.max(100)])],
      fixed: [0, Validators.min(0)],
      periodicity: [null],
      is_fixed: [true],
      n_of_members: [0, Validators.min(0)],
      period_start_date: [null],
    }));
    this.tags[key] = team.name;
  }
}
