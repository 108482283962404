<!-- <h1 mat-dialog-title>{{title}}</h1>
<p *ngIf="from && to">{{ from }} a {{ to }}</p> -->

<mat-dialog-content>
  @if (from!=null && to!=null) {
    <app-project-assignment-table [from]="from" [to]="to" (onSave)="onSave($event)"></app-project-assignment-table>
  }
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button (click)="close()" cdkFocusInitial>Cerrar</button>
</mat-dialog-actions>
