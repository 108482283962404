import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ProviderOrderAlert } from 'src/app/models/alert.model';
import { ApiService } from 'src/app/services/api.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-billing-resume',
  templateUrl: './billing-resume.component.html',
  styleUrls: ['./billing-resume.component.css']
})
export class BillingResumeComponent implements OnInit {

  private _chartContainer:ElementRef = null as any;
  get chartContainer() {return this._chartContainer;};
  @ViewChild('chartContainer') set chartContainer(data:ElementRef) {
    this._chartContainer = data;
    if(this._chartContainer!=null) {
      this.mainChartView[0] = this._chartContainer.nativeElement.offsetWidth - 70;
      this.pieChartsView[0] = this._chartContainer.nativeElement.offsetWidth/3 - 70;
    }
  }
  mainChartView:[number,number] = [undefined as any, 250];
  pieChartsView:[number,number] = [undefined as any, 200];
  pieChartsLegendPosition:any = "below";
  colorScheme = this.utils.getGreenRedColorScheme();
  chartData:any;

  filterForm:UntypedFormGroup;
  private formPersistence:any;
  periods:string[];

  alerts:ProviderOrderAlert[] = [];

  constructor(private api:ApiService,
              private utils:UtilsService,
              private fb:UntypedFormBuilder,
              private activatedRoute:ActivatedRoute,
              private router:Router) {

    this.filterForm = this.fb.group({
      period: [this.activatedRoute.snapshot.queryParamMap.get('period')!=null ? (this.activatedRoute.snapshot.queryParamMap.get('period') as any) : this.getCurrentPeriod()],
    });

    this.periods = [this.filterForm.value['period']];
  }

  ngOnInit(): void {
    this.fetchAlerts();
    this.fetchPeriods();
    this.initFilterFormListener();
    this.listenQueryParameters();
    this.listenPeriodControl();
    // this.fetchOrdersChartData();
  }

  fetchAlerts() {
    this.api.getProviderOrderAlerts({'monthly': true}).subscribe( data => {
      this.alerts = data;
    })
  }

  private getCurrentPeriod() {
    return new Date().getFullYear();
  }

  private fetchPeriods() {
    this.api.getBillingPeriods().subscribe(
      data => {
        this.periods = data;
      }
    );
  }

  private initFilterFormListener() {
    this.filterForm.valueChanges.subscribe(
      data => {
        if(this.formPersistence==null || JSON.stringify(this.formPersistence)!=JSON.stringify(data)) {
          this.router.navigate(['/employees', 'direction', 'billing'], { queryParams: data });
        }
      }
    );
  }

  private listenQueryParameters() {
    this.activatedRoute.queryParams.subscribe(
      params => {
        if(JSON.stringify(params)!==JSON.stringify(this.filterForm.value)) { //si no ve de filtre s'ha de setejar el form
          let params_temp = this.utils.cloneObj(params); //params es read_only
          Object.keys(params_temp).forEach(param_key => {
            if(params_temp[param_key]!=null && params_temp[param_key]!="" && !isNaN(+params_temp[param_key])) params_temp[param_key] = +params_temp[param_key]; // si es numero, el transformem
          });
          this.filterForm.patchValue(params_temp, { emitEvent: false });
        }
        this.formPersistence = params;
        this.fetchOrdersChartData();
      }
    );
  }

  private listenPeriodControl() {
    this.filterForm.controls['period'].valueChanges.subscribe(
      period => {
        // this.fetchOrdersChartData();
      }
    );
  }

  private fetchOrdersChartData() {
    this.api.getBillingChartData(this.filterForm.value['period']).subscribe(
      data => {
        this.chartData = data;
      }
    );
  }

}
