<h1 mat-dialog-title>Añadir contrato al roadmap</h1>

<mat-dialog-content [formGroup]="form">
  <mat-form-field appearance="outline">
    <mat-label>Introduce el identificador del contrato</mat-label>
    <input matInput formControlName="contract_id" type="number" placeholder="Por ejemplo: '1'" />
    <span matPrefix>#</span>
  </mat-form-field>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button cdkFocusInitial (click)="close()">
    <mat-icon>close</mat-icon>
    Cerrar
  </button>
  <button mat-flat-button color="accent" [disabled]="!form.valid" (click)="create()">
    <mat-icon>add</mat-icon>
    Crear
  </button>
</mat-dialog-actions>
