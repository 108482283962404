import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { BillingPlannedRow } from 'src/app/models/billing-planned-row.model';
import { ApiService } from 'src/app/services/api.service';
import { UtilsService } from 'src/app/services/utils.service';
import { AddBillingPlanningConceptDialogComponent } from './add-billing-planning-concept-dialog/add-billing-planning-concept-dialog.component';

@Component({
  selector: 'app-billing-planning',
  templateUrl: './billing-planning.component.html',
  styleUrls: ['./billing-planning.component.css']
})
export class BillingPlanningComponent implements OnInit {

  filterForm:FormGroup = null as any;
  private formPersistence:any;

  dataSource:MatTableDataSource<BillingPlannedRowItem> = new MatTableDataSource();
  _displayedColumns:string[] = ["concept"];
  get displayedColumns():string[] {
    return this._displayedColumns.concat(this.monthControl.value);
  }

  months:{key:string, value:string}[] = [
    {key: "january", value: "Enero"},
    {key: "february", value: "Febrero" },
    {key: "march", value: "Marzo" },
    {key: "april", value: "Abril" },
    {key: "may", value: "Mayo" },
    {key: "june", value: "Junio" },
    {key: "july", value: "Julio" },
    {key: "august", value: "Agosto" },
    {key: "september", value: "Septiembre" },
    {key: "october", value: "Octubre" },
    {key: "november", value: "Noviembre" },
    {key: "december", value: "Diciembre" }
  ];

  monthControl:FormControl = new FormControl(this.months.map(v => v.key));
  currentMonthIndex:number = new Date().getMonth();
  nowYear:number = new Date().getFullYear();

  totals:number[] = [];

  constructor(private fb:FormBuilder,
              private activatedRoute:ActivatedRoute,
              private router:Router,
              private utils:UtilsService,
              private api:ApiService,
              private dialog:MatDialog) {

    this.filterForm = this.fb.group({
      year:[(this.activatedRoute.snapshot.queryParamMap.get('year')!=null ? +(this.activatedRoute.snapshot.queryParamMap.get('year') as any) : new Date().getFullYear()), Validators.required],
    });

  }

  ngOnInit(): void {
    this.listenQueryParameters();
    this.initFilterFormListener();
    // this.listenMonthFormControl();
  }

  changeYear(sumYears:number) {
    this.filterForm.controls['year'].setValue(this.filterForm.value['year']+sumYears);
  }

  changeInputEvt(row:BillingPlannedRowItem, monthIndex:number, evt:any) {
    const newVal = evt.target.value;
    if(newVal != null && newVal!=eval("row[this.months[monthIndex].key]")) {
      eval("this.totals[monthIndex] -= row.type==='expense' ? (newVal - row[this.months[monthIndex].key]) : (row[this.months[monthIndex].key] - newVal)");
      eval("row[this.months[monthIndex].key] = newVal");
      this.postMonth({
        type: row.type,
        month: monthIndex+1,
        year: this.filterForm.value['year'],
        value: newVal,
        project_id: row.project_id,
        planned_expense_category_slug: row.planned_expense_category_slug
      });
    }
  }

  openAddDialog() {
    const dialogRef = this.dialog.open(AddBillingPlanningConceptDialogComponent, {
      width: '600px'
    });

    dialogRef.afterClosed().subscribe(
      data => {
        if(data != null) {
          data.year = this.filterForm.value['year'];
          this.postMonth(data, true);
        }
      }
    );
  }

  private initFilterFormListener() {
    this.filterForm.valueChanges.subscribe(
      data => {
        if(this.formPersistence==null || JSON.stringify(this.formPersistence)!=JSON.stringify(data)) {
          this.router.navigate([], { queryParams: data });
        }
      }
    );
  }

  private listenQueryParameters() {
    this.activatedRoute.queryParams.subscribe(params => {
      if(JSON.stringify(params)!==JSON.stringify(this.filterForm.value)) { //si no ve de filtre s'ha de setejar el form
        let params_temp = this.utils.cloneObj(params); //params es read_only
        Object.keys(params_temp).forEach(param_key => {
          if(params_temp[param_key]!=null && params_temp[param_key]!="" && !isNaN(+params_temp[param_key])) params_temp[param_key] = +params_temp[param_key]; // si es numero, el transformem
        });
        this.filterForm.patchValue(params_temp, { emitEvent: false });
      }
      this.formPersistence = params;

      this.fetchPlanning();
    })
  }

  private fetchPlanning() {
    const year = this.filterForm.value['year'];
    const from = moment().year(year).startOf('year');
    const to = moment().year(year).endOf('year');
    this.dataSource.data = [];
    this.totals = [];
    this.api.getBillingPlanning(from.toDate(), to.toDate()).subscribe(
      data => {
        this.afterFetchingPlanning(data);
      }
    );
  }

  private afterFetchingPlanning(data:BillingPlannedRow[]) {
    let res:{[key:string]:BillingPlannedRowItem} = {};

    data.forEach(monthBpr => {
      // incomes
      monthBpr.incomes.forEach(income => {
        const key:string = `${income.name}_income`;
        if(res[key]==null) res[key] = this.initBillingPlannedRowItem(income.name, "income", income.project_id);
        eval("res[key][this.months[monthBpr.month-1].key] += income.total_net");
      });

      // expenses
      monthBpr.expenses.forEach(expense => {
        const key:string = `${expense.name}_income`;
        if(res[key]==null) res[key] = this.initBillingPlannedRowItem(expense.name, "expense", expense.planned_expense_category_slug);
        eval("res[key][this.months[monthBpr.month-1].key] += expense.total_net");
      });

      // totals
      this.totals.push(monthBpr.total.total);
    });

    this.dataSource.data = Object.values(res).sort((a,b) => {
      if(a.type==="income" && b.type==="income") return 0;
      else if(a.type==="income") return -1;
      else if(a.type==="expense") return 1;
      else return 0;
    });
  }

  private initBillingPlannedRowItem(name:string, type:"expense"|"income", extra_id:any):BillingPlannedRowItem {
    return {
      name: name,
      type: type,
      january: 0,
      february: 0,
      march: 0,
      april: 0,
      may: 0,
      june: 0,
      july: 0,
      august: 0,
      september: 0,
      october: 0,
      november: 0,
      december: 0,
      project_id: type==="expense" ? null : extra_id,
      planned_expense_category_slug: type==="expense" ? extra_id : null
    };
  }

  private postMonth(data:any, refresh:boolean = false) {
    this.api.postBillingPlanningMonth(data).subscribe(
      data => {
        if(refresh) this.fetchPlanning();
      }
    );
  }
}

class BillingPlannedRowItem {
  name:string;
  january:number;
  february:number;
  march:number;
  april:number;
  may:number;
  june:number;
  july:number;
  august:number;
  september:number;
  october:number;
  november:number;
  december:number;
  type:"expense"|"income";
  project_id:number;
  planned_expense_category_slug:string;
}
