import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'password'
})
export class PasswordPipe implements PipeTransform {

  transform(password:string):string {
    let res:string = "";
    for(let i=0; i<password.length; i++) {
      res += "*";
    }
    return res;
  }

}
