import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import * as moment from 'moment';
import { generate } from 'rxjs';
import { ProjectDefaultTimeDistributionDistributable } from 'src/app/models/project_default_time_distribution_distributable.model';
import { ProjectPhase } from 'src/app/models/project_phase.model';
import { ProjectUserMember } from 'src/app/models/project_user_member.model';
import { User } from 'src/app/models/user.model';
import { WeeklyWorkingTimeBudget, WeeklyWorkingTimeBudgetInfo } from 'src/app/models/weekly_working_time_budget.model';
import { WeeklyWorkingTimeBudgetDistributionDistributable } from 'src/app/models/weekly_working_time_budget_distribution_distributable.model';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-edit-weekly-working-time-budget-distribution-distributables-dialog',
  templateUrl: './edit-weekly-working-time-budget-distribution-distributables-dialog.component.html',
  styleUrls: ['./edit-weekly-working-time-budget-distribution-distributables-dialog.component.css']
})
export class EditWeeklyWorkingTimeBudgetDistributionDistributableDialogComponent implements OnInit {
  title: string = this.data.title;
  budget:WeeklyWorkingTimeBudget = null as any;
  from:string = null as any;
  to:string = null as any;
  firstLabelTitle:string = this.data.first_label_title;
  secondLabelTitle:string = this.data.second_label_title;
  budget_id:number = this.data.budget_id;
  root_id:number = this.data.root_id;

  firstDistribution:any;
  secondDistribution:any;
  firstDistributionTotal:number = 0;
  firstDistributionTotalToCompare:number = 0;
  secondDistributionTotal:number = 0;
  secondDistributionTotalToCompare:number = 0;
  firstDistributionPreviousTotal:number = 0;

  members:User[] = [];
  projectActiveMembers: ProjectUserMember[] = [];


  constructor(
    public dialogRef: MatDialogRef<EditWeeklyWorkingTimeBudgetDistributionDistributableDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any,
    private api: ApiService,
    private dialog:MatDialog
  ) { }

  ngOnInit(): void {
    this.fetchBudget();
  }

  fetchMembersAndGenerateFirstDistribution(children: WeeklyWorkingTimeBudgetDistributionDistributable[], last_week_distributable_children: WeeklyWorkingTimeBudgetDistributionDistributable[], default_distributable_children: ProjectDefaultTimeDistributionDistributable[]) {
    this.api.getActiveProjectMembers(this.budget.contract?.project_id?.toString() ?? '', { date: this.budget.start_week_day }).subscribe((data:any) => {
      this.members = data.map((obj:any) => obj.user);
      this.members = this.members.filter((v,i,a)=>a.findIndex(t=>(t.id === v.id))===i);
      this.projectActiveMembers = data;
      this.projectActiveMembers.forEach((member:ProjectUserMember) => {
        member.availability = member.user?.employee_month_working_capacities[0].development_budgetable_time.weeks.find((week:any) => week.from == this.budget.start_week_day)?.availability ?? 0;
      });
      this.generateFirstDistribution(children, last_week_distributable_children, default_distributable_children);
    });
  }

  fetchBudget() {
    if (this.root_id!=null) {
      this.api.getWeeklyWorkingTimeBudgetInfo(this.budget_id.toString(), this.root_id.toString()).subscribe((data:WeeklyWorkingTimeBudgetInfo) => {
        this.budget = data.budget;
        this.firstDistributionTotalToCompare = data.distributable.hours;
        this.fetchMembersAndGenerateFirstDistribution(data.distributable.children, data.last_week_distributable?.children ?? [], data.default_distributable.children ?? []);
      });
    } else {
      this.api.getWeeklyWorkingTimeBudget(this.budget_id.toString(), { append: 'distributables,last_week_distributables,default_distributables,distributable_status' }).subscribe((budget:WeeklyWorkingTimeBudget) => {
        this.budget = budget;
        this.firstDistributionTotalToCompare = this.budget.hours;
        this.fetchMembersAndGenerateFirstDistribution(this.budget.distributables, this.budget.last_week_distributables, this.budget.default_distributables);
      });
    }
  }

  isReadonly(key:string) {
    return ["task-time", "task-time-tracking", "development", "revision-time"].indexOf(key) !== -1;
  }

  generateFirstDistribution(distributables: WeeklyWorkingTimeBudgetDistributionDistributable[], last_week_distributables: WeeklyWorkingTimeBudgetDistributionDistributable[], default_distributables: ProjectDefaultTimeDistributionDistributable[]) {
    this.from = moment(this.budget.start_week_day).format('DD/MM/YYYY');
    this.to = moment(this.budget.end_week_day).format('DD/MM/YYYY');
    this.firstDistribution = [];
    this.firstDistributionTotal = 0;
    this.secondDistributionTotal = 0;
    this.secondDistributionTotalToCompare = 0;
    this.firstDistributionPreviousTotal = 0;
    distributables.forEach((distributable: WeeklyWorkingTimeBudgetDistributionDistributable) => { // aquest for each sel fuma no ? !!!!
      let last_week_distributable = last_week_distributables.find((lastWeekDistributable: WeeklyWorkingTimeBudgetDistributionDistributable) => lastWeekDistributable.distributable_id == distributable.distributable_id && lastWeekDistributable.distributable_type == distributable.distributable_type);
      let default_distributable = default_distributables.find((defaultDistributable: ProjectDefaultTimeDistributionDistributable) => defaultDistributable.distributable_id == distributable.distributable_id && defaultDistributable.distributable_type == distributable.distributable_type);

      this.firstDistribution.push({
        id: distributable.id,
        title: distributable.distributable_name,
        is_readonly: this.isReadonly(distributable.distributable_slug),
        last_week_value: last_week_distributable?.hours || 0,
        value: distributable.hours,
        default: default_distributable!.time_this_week,
        changed: false,
        has_children: distributable.children!=null && distributable.children.length>0
      });

      this.firstDistributionTotal += distributable.hours;
      this.firstDistributionTotal = Math.round(this.firstDistributionTotal * 100) / 100;
      this.firstDistributionPreviousTotal += (last_week_distributable?.hours || 0);
      this.firstDistributionPreviousTotal = Math.round(this.firstDistributionPreviousTotal * 100) / 100;

      if (distributable.children!=null && distributable.children.length>0) {
        this.generateSecondDistribution(distributable, last_week_distributable, default_distributable);
      }
    });
  }

  generateSecondDistribution(distributable:WeeklyWorkingTimeBudgetDistributionDistributable, last_week_distributable:WeeklyWorkingTimeBudgetDistributionDistributable|undefined, default_distributable:ProjectDefaultTimeDistributionDistributable|undefined) {
    this.secondDistribution = [];
    this.secondDistributionTotalToCompare = distributable.hours;
    distributable.children.forEach((distributable: WeeklyWorkingTimeBudgetDistributionDistributable) => {
      let last_week_value = last_week_distributable?.children.find((lastWeekDistributable: WeeklyWorkingTimeBudgetDistributionDistributable) => lastWeekDistributable.distributable_id == distributable.distributable_id && lastWeekDistributable.distributable_type == distributable.distributable_type)?.hours || 0;
      let d = default_distributable?.children.find((defaultDistributable: ProjectDefaultTimeDistributionDistributable) => defaultDistributable.distributable_id == distributable.distributable_id && defaultDistributable.distributable_type == distributable.distributable_type);

      this.secondDistribution.push({
        id: distributable.id,
        title: distributable.distributable_name,
        last_week_value: last_week_value,
        value: distributable.hours,
        is_readonly: this.isReadonly(distributable.distributable_slug),
        default: d?.time_this_week,
        parent_id: distributable.id,
        changed: false,
        has_children: distributable.children!=null && distributable.children.length>0,
        active_phase: distributable.distributable_type!='App\\Models\\ProjectPhase' || (distributable.distributable_type=='App\\Models\\ProjectPhase' && (distributable.distributable as any as ProjectPhase).is_active),
        meta_children: 1
      });
      this.secondDistributionTotal += distributable.hours;
      this.secondDistributionTotal = Math.round(this.secondDistributionTotal * 100) / 100;
    });
  }

  changeValue(first:boolean, value:number, i:number) {
    let array:any;
    let total:number;
    let totalToCompare:number;

    if (first) {
      array = this.firstDistribution;
      total = this.firstDistributionTotal;
      totalToCompare = this.firstDistributionTotalToCompare;
    } else {
      array = this.secondDistribution;
      total = this.secondDistributionTotal;
      totalToCompare = this.secondDistributionTotalToCompare;
    }

    array[i].value = value;
    array[i].changed = true;

    // 1. Get all members of first distribution with readonly attribute false
    let members = array.filter((distributable:any) => !distributable.is_readonly);
    // 2. Sum all values of members
    let members_total = this.round(members.reduce((a:any, b:any) => a + b.value, 0));
    let members_readonly = array.filter((distributable:any) => distributable.is_readonly);
    // 3. Get all members of first distribution with readonly attribute true
    members_readonly.forEach((distributable:any) => {
      if(distributable.has_children && first) {
        this.secondDistributionTotalToCompare -= distributable.value;
      }
      let value = (totalToCompare > members_total) ? this.round((totalToCompare - members_total)/members_readonly.length) : 0;
      distributable.value = value;
      distributable.changed = true;
      if(distributable.has_children && first) {
        this.secondDistributionTotalToCompare += distributable.value;
        this.secondDistribution[0].value = this.secondDistributionTotalToCompare - this.secondDistribution.slice(1, this.secondDistribution.length).reduce((a:any, b:any) => a + b.value, 0);
        this.secondDistribution[0].changed = true;
        this.secondDistributionTotal = this.secondDistribution.reduce((a:any, b:any) => a + b.value, 0);
      }
    });

    total = this.round(members_total + members_readonly.reduce((a:any, b:any) => a + b.value, 0));

    if (first) {
      this.firstDistribution = array;
      this.firstDistributionTotal = total;
      this.firstDistributionTotalToCompare = totalToCompare;
    } else {
      this.secondDistribution = array;
      this.secondDistributionTotal = total;
      this.secondDistributionTotalToCompare = totalToCompare;
    }
  }

  round(value:number) {
    return Math.round(value * 100) / 100;
  }

  openChild(i:number) {
    const dialog2 = this.dialog.open(EditWeeklyWorkingTimeBudgetDistributionDistributableDialogComponent, {
      width: '1200px',
      data: {
        budget_id: this.budget_id,
        root_id: this.secondDistribution[i].id,
        title: `Distribución de horas de la fase ${this.secondDistribution[i].title}`,
        first_label_title: `Distribución de ${this.secondDistribution[i].title}`,
        second_label_title: 'Distribución de desglose',
      },
    });
  }

  nearby(val1:number, val2:number) {
    return Math.abs(val1 - val2) <= 1;
  }

  update() {
    // find in first distribution the changed values and add them to array
    let distribution_changed:any = [];
    this.firstDistribution.forEach((distributable:any) => {
      if (distributable.changed) {
        distribution_changed.push({
          id: distributable.id,
          value: distributable.value
        });
      }
    });

    this.secondDistribution.forEach((distributable:any) => {
      if (distributable.changed) {
        distribution_changed.push({
          id: distributable.id,
          value: distributable.value
        });
      }
    });

    if (distribution_changed.length==0) {
      distribution_changed.push({
        id: this.firstDistribution[0].id,
        value: this.firstDistribution[0].value
      });
    }

    // call bulk update
    this.api.updateWeeklyWorkingTimeBudgetDistributionDistributableBulk(distribution_changed).subscribe((data:any) => {
      // this.dialogRef.close(data);
    });

  }

  loadDefaults() {
    this.api.getWeeklyWorkingTimeBudgetDistributionDistributable(this.budget_id.toString(), { force: true }).subscribe((data:any) => {
      this.fetchBudget();
    });
  }

  close(): void {
    this.dialogRef.close();
  }

}
