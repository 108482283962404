import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-selector-dialog',
  templateUrl: './selector-dialog.component.html',
  styleUrl: './selector-dialog.component.css'
})
export class SelectorDialogComponent implements OnInit {
  value:any = null;

  constructor(public dialogRef: MatDialogRef<SelectorDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data:SelectorDialogData) {}

  ngOnInit(): void {

  }

  submit() {
    this.dialogRef.close(this.value);
  }

  close() {
    this.dialogRef.close();
  }
}

export interface SelectorDialogData {
  dialog_title: string;
  dialog_ok_button: string;
  dialog_cancel_button: string;
  data:SelectorDialogDataRow[];
}

export interface SelectorDialogDataRow {
  value: any;
  textToShow: string;
}
