<div cdkDropListGroup>
  @for (board of boards; track board) {
    <mat-expansion-panel cdkDropList
      [cdkDropListEnterPredicate]="canDropTasks"
      [cdkDropListData]="board.tasks"
      cdkDropListOrientation="vertical"
      (cdkDropListDropped)="dropTask($event)"
      class="drag-list"
      [id]="board.id"
      [expanded]="board.id == 'backlog' || board.id == 'for-approval' || board.status == 'active'">
      <mat-expansion-panel-header #panelH #panelB>
        <mat-panel-title class="d-flex">
          @if (!board.is_archived) {
            <div class="d-flex justify-content-center">
              @if (board.id !== 'backlog' && board.id !== 'for-approval') {
                <span class="mini-circle" [matTooltip]="board.status == 'active' ? 'Activo' : board.status == 'finished' ? 'Finalizado' : 'No activo'" [style.background-color]="board.status == 'active' ? '#00C600' : board.status == 'finished' ? 'grey' : 'red'"></span>
              }
            </div>
          }
          @if (board.is_archived) {
            <div class="d-flex justify-content-center">
              <mat-icon class="archive-indicator">archive</mat-icon>
            </div>
          }
          <div class="board-title">{{ board.title ?? 'Sprint ' + (boards.indexOf(board) + 1) }}</div>
          @if (board.start_date && board.end_date) {
            <span class="board-info">
              {{ board.start_date | date: 'd MMM' }} - {{ board.end_date | date: 'd MMM' }}
            </span>
          }
          @if ((board.id !== 'backlog' && board.id !== 'for-approval') && (!board.start_date || !board.end_date)) {
            <span class="edit-dates" (click)="panelH._toggle(); editProjectPhaseBoard(board);">
              <mat-icon>edit</mat-icon> Añadir fechas
            </span>
          }
          @if (board.tasks.length > 0) {
            <span class="board-info">
              ({{ board.tasks.length }} {{ board.tasks.length == 1 ? 'tarea' : 'tareas' }}{{ sumTotalSprintEstimation(board) > 0 ? (', ' + (sumTotalSprintEstimation(board) | secondsToStringTime) ) : '' }})
            </span>
          }
        </mat-panel-title>
        <mat-panel-description>
          @if(isProjectManagerOrProductManager) {
            @if (board.id === 'backlog') {
              <button color="accent" mat-flat-button class="manage-sprint-button" (click)="createProjectPhaseBoard();">
                Crear nuevo sprint
              </button>
            }
            @else if(board.id !== "for-approval" && board.status!=null && ["active"].includes(board.status)) {
              <button color="accent" mat-flat-button class="manage-sprint-button" (click)="openCompleteDialog(board);">
                Completar sprint
              </button>
            }
          }
          @if (isProjectManagerOrProductManager) {
            <button mat-icon-button [matMenuTriggerFor]="boardmenu" class="actions-board small-icon-button" (click)="panelH._toggle();">
              <mat-icon>more_horiz</mat-icon>
            </button>
          }
          <mat-menu #boardmenu="matMenu">
            @if (board.id !== 'backlog' && board.id !== 'for-approval') {
              <button mat-menu-item (click)="editProjectPhaseBoard(board)" >
                <mat-icon>edit</mat-icon>
                <span>Editar</span>
              </button>
            }
            @if (board.id !== 'backlog' && board.id !== 'for-approval') {
              <button mat-menu-item (click)="deleteProjectPhaseBoard(board)" >
                <mat-icon>delete</mat-icon>
                <span>Borrar</span>
              </button>
            }
            <!-- <button mat-menu-item (click)="showDialogUploadTasks(board)">
              <mat-icon>upload_file</mat-icon>
              <span>Importar tareas</span>
            </button> -->
          @if (board.id !== 'backlog' && board.id !== 'for-approval') {
            <button mat-menu-item (click)="archiveBoard(board)">
              <mat-icon style="margin-right: 10px">archive</mat-icon>
              {{ !board.is_archived ? 'Archivar tablero' : 'Desarchivar tablero' }}
            </button>
          }
        </mat-menu>
        @if (selectingBoardId === board.id && selectedItems.length > 1) {
          <button mat-flat-button [matMenuTriggerFor]="menuMultiple" color="accent" class="multiSelectButton" (click)="panelH._toggle()" >
            <mat-icon style="margin-right: 10px" class="multiSelectButton">menu</mat-icon>
            Acciones
          </button>
        }
        <mat-menu #menuMultiple="matMenu">
          <button mat-menu-item (click)="deleteMultiple(board)">
            <mat-icon>delete</mat-icon>
            <span>Borrar  ({{ selectedItems.length }})</span>
          </button>
        </mat-menu>
      </mat-panel-description>
      </mat-expansion-panel-header>
      <ul class="task-list">
        @for (task of board.tasks; track task) {
          <li [id]="task.frontendId" class="task-box" cdkDrag (mwlClick)="selectDetector($event, board, task)" [ngStyle]="{ opacity: isArchived(task) ? '35%' : '100%'}" [cdkDragDisabled]="!canDrag(getProjectTask(task), board)">
            <div class="task-infobox">
              @if (canDrag(getProjectTask(task), board)) {
                <button mat-icon-button class="drag-handle small-icon-button">
                  <mat-icon>drag_handle</mat-icon>
                </button>
              }
              @if (editingTypeTaskId !== task.frontendId) {
                <img class="task-type-img" (click)="startChangeTaskType(task, board)" [src]="getTaskType(task) | projectTaskTypeImage" />
              }
              @if (editingTypeTaskId === task.frontendId) {
                <mat-form-field appearance="outline" class="type-select nobottom noborder" style="margin-right: 10px;">
                  <mat-select #editTypeTaskSelect [value]="getTaskType(task)" [ngClass]="getTaskType(task)" class="type-select" (valueChange)="changeTaskType(task, $event)">
                    @for (v of ['task','issue','story']; track v) {
                      <mat-option [value]="v"><img class="task-type" [src]="v | projectTaskTypeImage" /></mat-option>
                    }
                  </mat-select>
                </mat-form-field>
              }
              <p class="code">{{ getTaskCode(task) }}</p>
              @if (editingTitleTaskId !== task.frontendId) {
                <p class="title"  (click)="openTask(getProjectTask(task).id, board.id)" [matTooltip]="getTaskTitle(task)">
                  {{ getTaskTitle(task) }}
                  <!-- <mat-icon class="edit-title" (click)="startEditingTitle(task)">create</mat-icon> -->
                </p>
              }
              @if (editingTitleTaskId === task.frontendId) {
                <div class="edit-task-title">
                  <input #editTaskTitleInput [(ngModel)]="newTaskTitle" (mwlKeydownEnter)="editTaskTitle(task)" (focusout)="stopEditingTaskTitle()"/>
                </div>
              }
              @if (getProjectTask(task).tags && getProjectTask(task).tags.length) {
                <div class="w-100 tag-list">
                  @for (tag of getProjectTask(task).tags.slice(0,2); track tag) {
                    <mat-chip class="preview-tag">#{{ tag.tag.title }}</mat-chip>
                  }
                </div>
              }
            </div>
            <div class="task-box__actions">
              @if(getProjectTask(task).is_draft) {
                <mat-chip-listbox>
                  <mat-chip color="warn">
                    Borrador
                  </mat-chip>
                </mat-chip-listbox>
              }

              <img class="priority p-1" [src]="getTaskPriority(task) | priorityImg" />
              <div>
                <p [ngClass]="task.ro_time_estimation ? 'estimation_time': 'without_estimation' " (click)="startEditingTimeEstimation(task)">
                  {{
                  task.ro_time_estimation | secondsToStringTime
                  }}
                </p>
              </div>

              @if (!isProjectTask(task)) {
                <mat-select class="col-select"
                  [ngClass]="{'col-progress': getTaskColType(task) === 'progress', 'col-done' : getTaskColType(task) === 'done' }"
                  [ngModel]="getProjectTaskBoard(task).project_phase_board_col_id"
                  (ngModelChange)="onColumnChange(board, getProjectTaskBoard(task), $event)"
                  [disabled]="
                    !(isProjectManagerOrProductManager) &&
                    !(isCompanyDeveloperUser && board.can_client_dev_manage_board)
                  ">
                  @for (col of board.project_phase_board_cols; track col) {
                    <mat-option [value]="col.id">
                      {{col.title}}
                    </mat-option>
                  }
                </mat-select>
              }
              <!-- @if (board.id !== 'for-approval' && board.id !== 'backlog' && task.taskAssignees != null && task.taskAssignees.length) {
                <ul class="avatar-list">
                  @for(assignee of task.taskAssignees; track assignee) {
                    <li><img class="avatar" [src]="assignee.profile_picture_url" alt="avatar" /></li>
                  }
                </ul>
              } -->

              @if(board.id === 'for-approval') {
                @if (getProjectTask(task).client_has_approved === false) {
                  <mat-chip-listbox>
                    <mat-chip color="warn">
                      Tarea declinada
                    </mat-chip>
                  </mat-chip-listbox>
                }
                @else if(!getProjectTask(task).is_draft && getProjectTask(task).client_has_approved==null && me != null) {
                  @if(canAcceptTasks && (getProjectTask(task).creator == null || (getProjectTask(task).creator!.role.slug != me.role.slug))) {
                    <button mat-icon-button class="actions-menu small-icon-button" matTooltip="Aceptar tarea y añadirla al backlog" (click)="approveTask(task)">
                      <mat-icon>check</mat-icon>
                    </button>
                    <button mat-icon-button class="actions-menu small-icon-button" matTooltip="Declinar tarea" (click)="declineTask(task)">
                      <mat-icon>close</mat-icon>
                    </button>
                  }
                  @else {
                    <mat-chip-listbox>
                      <mat-chip color="warn">
                        Esperando respuesta
                      </mat-chip>
                    </mat-chip-listbox>
                  }
                }
                @if (isProductManager && getProjectTask(task).client_has_approved!=null) {
                  <button mat-icon-button class="actions-menu small-icon-button" matTooltip="Repreguntar al cliente" (click)="reaskApprovalTask(task)">
                    <mat-icon>reply</mat-icon>
                  </button>
                }
              }

              @if (board.id === 'backlog' && isProductManager) {
                <button mat-icon-button class="actions-menu small-icon-button" matTooltip="Pasar tarea a por aprobar y preguntar al cliente" (click)="requireClientApproveTask(task)">
                  <mat-icon>reply</mat-icon>
                </button>
              }
              @if (isProjectManagerOrProductManager || (isCompanyDeveloperUser && board.can_client_dev_manage_board)) {
                <button mat-icon-button [matMenuTriggerFor]="menu" class="actions-menu small-icon-button">
                  <mat-icon>more_horiz</mat-icon>
                </button>
              }
              <mat-menu #menu="matMenu">
                @if(board.id !== "backlog" && board.id !== "for-approval") {
                  <button mat-menu-item (click)="removeFromBoard(task, board)">
                    <mat-icon>do_not_disturb_on</mat-icon>
                    Eliminar del tablero
                  </button>
                }
                @else {
                  <button mat-menu-item (click)="deleteTask(task, board)">
                    <mat-icon>delete</mat-icon>
                    <span>Eliminar permanentemente</span>
                  </button>
                }
                <button mat-menu-item (click)="archiveTask(task)">
                  <mat-icon>archive</mat-icon>
                  {{ isArchived(task) ? 'Desarchivar' : 'Archivar' }}
                </button>
              </mat-menu>
            </div>
          </li>
        }
      </ul>
      @if (
        (
          isProjectManagerOrProductManager ||
          (isCompanyDeveloperUser && board.can_client_dev_manage_board) ||
          ( isCompanyBusinessUser && board.id === "for-approval" && project_phase.can_client_business_add_approval_tasks) ||
          ( isCompanyDeveloperUser && board.id === "backlog" && project_phase.can_client_dev_manage_backlog)
        ) &&
        (createTaskBoardId !== board.id || !creatingTask)
      ) {
        <div class="createTaskLine task-box" (click)="startCreatingTask(board.id)">
          <mat-icon>add</mat-icon>
          <p class="title">
            Crear tarea
          </p>
        </div>
      }
      @if (createTaskBoardId === board.id && creatingTask) {
        <div class="creating-task-box">
          @if (!selectNewTaskType) {
            <img class="task-type-img" (click)="startSelectingNewTaskType()" [src]="creatingTaskType | projectTaskTypeImage" />
          }
          @if (selectNewTaskType) {
            <mat-form-field class="type-select">
              <mat-select #editTypeTaskSelect [(value)]="creatingTaskType" [ngClass]="creatingTaskType" class="type-select">
                @for (v of ['task','issue','story']; track v) {
                  <mat-option value="{{v}}"><img class="task-type" src="{{v | projectTaskTypeImage}}" /></mat-option>
                }
              </mat-select>
            </mat-form-field>
          }
          <div class="create-task-title">
            <input #editTaskTitleInput [(ngModel)]="creatingNewTaskTitle" placeholder="¿Qué hay que hacer?"
              (keydown.esc)="stopCreatingTask($event);"
              (mwlKeydownEnter)="createTask(board, $event);"
              (paste)="createMultipleTasksFromClipboard($event,board);"
              (focusout)="createTaskBoardId = null; creatingTask = false" />
          </div>
        </div>
      }
    </mat-expansion-panel>
  }
</div>
