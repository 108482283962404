import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { PermissionsService } from 'src/app/services/permissions.service';
import { UtilsService } from 'src/app/services/utils.service';
import { RoleGuard } from './role.abstract.guard';

@Injectable()
export class ProductionTeamGuard extends RoleGuard {
  role: string[] = ["development", "designers"];
}
