<h1 mat-dialog-title>{{ data.direction === 'in' ? '¿Porqué se pasa a deadlock?' : '¿Porqué se desbloquea la tarea?' }}</h1>

<mat-dialog-content>
  <form class="container" [formGroup]="form" style="overflow: hidden">
    <div class="row">
      <div class="col-12" style="margin-top: 5px">
        <mat-form-field appearance="outline" class="nobottom">
          <mat-label>{{ data.direction === 'in' ? 'Escribe el motivo por el que se pone esta tarea en deadlock' : '¿Qué ha pasado para que pueda desbloquearse?' }}</mat-label>
          <textarea matInput formControlName="content" rows="5"></textarea>
        </mat-form-field>
      </div>
    </div>
  </form>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-flat-button color="accent" (click)="submit()" [disabled]="!form.valid">
    <mat-icon>check_circle</mat-icon>
    {{ data.direction === 'in' ? 'Pasar a deadlock' : 'Desbloquear tarea' }}
  </button>
</mat-dialog-actions>
