<div class="page-title">
    <h1>Empresas</h1>
    <button mat-flat-button color="accent" (click)="addCompany()" [disabled]="!permissions.validateDepartment(['direction', 'finance'])">
        <mat-icon style="margin-right: 10px">person_add</mat-icon>
        Añadir empresa
    </button>
</div>

<div class="bisual-table">
    <form class="card paginator" [formGroup]="filterForm">
        <mat-paginator
            [length]="length"
            [pageSize]="pageSize"
            [pageSizeOptions]="pageSizeOptions"
            [pageIndex]="pageIndex"
            (page)="changePage($event)"
            style="margin-right: auto;"
        ></mat-paginator>

        <mat-form-field>
            <input matInput type="text" formControlName="search">
            <button matSuffix mat-icon-button aria-label="Clear">
                <mat-icon>search</mat-icon>
            </button>
        </mat-form-field>
    </form>

    <div class="bisual-table-container">
        <table mat-table [dataSource]="dataSource">
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef>Nombre de empresa</th>
                <td mat-cell *matCellDef="let company">
                    <a [routerLink]="[company.id]">{{company.fact_name}}</a>
                </td>
            </ng-container>

            <ng-container matColumnDef="fact">
                <th mat-header-cell *matHeaderCellDef>Facturación</th>
                <td mat-cell *matCellDef="let company">{{company.is_fact_active ? 'Preparado' : 'Inactivo'}}</td>
            </ng-container>

            <ng-container matColumnDef="stripe">
                <th mat-header-cell *matHeaderCellDef>Stripe</th>
                <td mat-cell *matCellDef="let company">{{company.is_stripe_ready ? 'Activo' : 'Inactivo'}}</td>
            </ng-container>

            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let company; let i = index;">
                    <button mat-icon-button [matMenuTriggerFor]="menu">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item [routerLink]="[company.id]" [disabled]="!permissions.validateDepartment(['direction', 'finance'])">
                            <mat-icon matPrefix>edit</mat-icon>
                            Editar empresa
                        </button>
                        <button mat-menu-item (click)="deleteCompany(i)" [disabled]="!permissions.validateDepartment(['direction', 'finance'])">
                            <mat-icon matPrefix>delete</mat-icon>
                            Borrar empresa
                        </button>
                    </mat-menu>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>
</div>
