<h1 mat-dialog-title>Añadir nuevo hosting</h1>
<mat-dialog-content [formGroup]="hostingForm">
  <div class="container">
    <div class="row">
      <div class="col-12 col-lg-4">
        <mat-form-field appearance="outline">
          <mat-label>Hosting FQDN*</mat-label>
          <input matInput formControlName="hosting_fqdn">
        </mat-form-field>
      </div>
      <div class="col-12 col-lg-4">
        <mat-form-field appearance="outline">
          <mat-label>Proveedor*</mat-label>
          <mat-select formControlName="provider_id">
            @for (p of providers; track p) {
              <mat-option [value]="p.id">{{p.name}}</mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-12 col-lg-4">
        <mat-form-field appearance="outline">
          <mat-label>Propietario</mat-label>
          <input type="text" placeholder="Escribe un nombre" matInput
            [formControl]="propietarioControl"
            [matAutocomplete]="auto">
          <mat-autocomplete #auto="matAutocomplete">
            @for (company of companies_filtered; track company) {
              <mat-option [value]="company.fact_name" (click)="selectPropietario(company)">{{ company.fact_name }}</mat-option>
            }
          </mat-autocomplete>
          <mat-hint>Dejar en blanco si es de Bisual</mat-hint>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-lg-4">
        <mat-form-field appearance="outline">
          <mat-label>Período de renovación*</mat-label>
          <mat-select formControlName="provider_id" formControlName="renewal_days_period">
            <mat-option [value]="30">Mensual</mat-option>
            <mat-option [value]="60">Bimensual</mat-option>
            <mat-option [value]="90">Trimestral</mat-option>
            <mat-option [value]="120">Semestral</mat-option>
            <mat-option [value]="365">Anual</mat-option>
            <mat-option [value]="720">Bianual</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-12 col-lg-4">
        <mat-form-field appearance="outline">
          <mat-label>Precio (€)*</mat-label>
          <input matInput type="text" formControlName="quantity">
          <span matSuffix>€</span>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-lg-4">
        <mat-checkbox formControlName="is_fact_external">Facturado externamente</mat-checkbox>
      </div>
      <div class="col-12 col-lg-4">
        <mat-checkbox formControlName="is_shared">Servidor compartido</mat-checkbox>
      </div>
      @if (hostingForm.value['company_id']!=null && hostingForm.value['company_id']!='') {
        <div class="col-12 col-lg-4">
          <mat-checkbox formControlName="trial_enabled">Empezar con período de prueba</mat-checkbox>
          <span style="font-size: 12px;">(Útil cuando el 1r pago ha sido pagado con un proyecto)</span>
        </div>
      }
    </div>

    @if (isHetznerEnabled()) {
      <h2 class="dialog-section">Información de Hetzner</h2>
    }
    @if (isHetznerEnabled()) {
      <div class="row">
        <div class="col-12 col-lg-4">
          <mat-form-field appearance="outline">
            <mat-label>Servidor*</mat-label>
            <mat-select formControlName="hetzner_server_type">
              @for (h of hetzner_server_types; track h) {
                <mat-option [value]="h.name">{{ h.description }} ({{ h.prices[0].price_monthly.net | currency }}/mes)</mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-12 col-lg-4">
          <mat-form-field appearance="outline">
            <mat-label>Localización*</mat-label>
            <mat-select formControlName="hetzner_location">
              @for (l of hetzner_locations; track l) {
                <mat-option [value]="l.name">{{ l.description }}</mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-12 col-lg-4">
          <mat-form-field appearance="outline">
            <mat-label>Imagen*</mat-label>
            <mat-select formControlName="hetzner_image">
              @for (i of hetzner_images; track i) {
                <mat-option [value]="i.name">{{ i.description }}</mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    }
    @if (isHetznerEnabled()) {
      <div class="row">
        <div class="col-12 col-lg-4">
          <mat-checkbox formControlName="hetzner_enable_backups">Habilitar backups</mat-checkbox>
        </div>
      </div>
    }

    @if (!isHetznerEnabled()) {
      <h2 class="dialog-section">Información adicional</h2>
    }
    @if (!isHetznerEnabled()) {
      <div class="row">
        <div class="col-12 col-lg-4">
          <mat-form-field appearance="outline">
            <mat-label>Dirección IPv4*</mat-label>
            <input matInput formControlName="ip">
          </mat-form-field>
        </div>
        <div class="col-12 col-lg-4">
          <mat-form-field appearance="outline">
            <mat-label>Hosting padre</mat-label>
            <input type="text" placeholder="Escribe un fqdn" matInput
              [formControl]="hostingControl"
              [matAutocomplete]="auto">
            <mat-autocomplete #auto="matAutocomplete">
              @for (hosting of parent_hostings_filtered; track hosting) {
                <mat-option [value]="hosting.hosting_fqdn" (click)="selectParentHosting(hosting)">{{ hosting.hosting_fqdn }}</mat-option>
              }
            </mat-autocomplete>
          </mat-form-field>
        </div>
        @if (hostingForm.value['company_id']==null || hostingForm.value['company_id']=='') {
          <div class="col-12 col-lg-4">
            <mat-form-field appearance="outline">
              <mat-label>Fecha de expiración*</mat-label>
              <input matInput [matDatepicker]="picker" formControlName="expiration_date">
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
          </div>
        }
      </div>
    }
    @if (!isHetznerEnabled()) {
      <div class="row">
        <div class="col-12 col-lg-4">
          <mat-form-field appearance="outline">
            <mat-label>Número de CPUs*</mat-label>
            <input matInput type="text" formControlName="cores">
            <span matSuffix>vCPU</span>
          </mat-form-field>
        </div>
        <div class="col-12 col-lg-4">
          <mat-form-field appearance="outline">
            <mat-label>Memoria RAM*</mat-label>
            <input matInput type="text" formControlName="memory">
            <span matSuffix>GB</span>
          </mat-form-field>
        </div>
        <div class="col-12 col-lg-4">
          <mat-form-field appearance="outline">
            <mat-label>Espacio en disco*</mat-label>
            <input matInput type="text" formControlName="disk">
            <span matSuffix>GB</span>
          </mat-form-field>
        </div>
      </div>
    }
  </div>
  @if (hostingForm.value['company_id']!=null && hostingForm.value['company_id']!='') {
    <p style="margin-top: 20px;">* Si aceptas, se le facturará al cliente seleccionado.</p>
  }
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button (click)="onNoClick()" cdkFocusInitial type="button">Cancelar</button>
  <button mat-stroked-button color="accent" [mat-dialog-close]="hostingForm.value" [disabled]="!hostingForm.valid" type="submit">
    <mat-icon style="margin-right: 10px">add</mat-icon>
    Crear hosting
  </button>
</mat-dialog-actions>
