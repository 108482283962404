import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BusinessMail } from 'src/app/models/business_mail.model';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-business-mail-detail',
  templateUrl: './business-mail-detail.component.html',
  styleUrls: ['./business-mail-detail.component.css']
})
export class BusinessMailDetailComponent implements OnInit {

  business_mail:BusinessMail = null as any;
  business_mail_id:string = null as any;

  activeLinkIndex = 0; 
  navLinks: any[] = [];

  constructor(private router:Router,
              private api:ApiService,
              private activatedRoute:ActivatedRoute) { }

  ngOnInit(): void {
    this.business_mail_id = this.activatedRoute.snapshot.paramMap.get('id') as string;
    this.initNavLinks();
    this.listenRouterEvents();
    this.fetchBusinessMail();
  }

  private listenRouterEvents() {
    this.router.events.subscribe((res) => {
      this.activeLinkIndex = this.navLinks.indexOf(this.navLinks.find(tab => tab.link.indexOf(this.router.url) != -1));
    });
  }

  private fetchBusinessMail() {
    this.api.getBusinessMailComplete(this.business_mail_id).subscribe(
      data => {
        this.business_mail = data;

        if(this.business_mail.provider.slug=="ovh" && this.business_mail.ovh_id!=null && !this.business_mail.is_fact_external) {
          const snapshot = this.activatedRoute.snapshot;
          const base_route = '/' + snapshot.url[0].path + "/" + snapshot.url[1].path + "/";      
          this.navLinks.push({
            label: 'Panel OVH',
            link: base_route + "ovh",
            index: 4
          });
        }
      }
    );
  }

  private initNavLinks() {
    const snapshot = this.activatedRoute.snapshot;
    const base_route = '/' + snapshot.url[0].path + "/" + snapshot.url[1].path + "/";
    this.navLinks = [
        {
            label: 'Resumen',
            link: base_route + "resume",
            index: 0
        }, 
        {
            label: 'Facturación',
            link: base_route + "billing",
            index: 1
        }, 
        {
            label: 'Credenciales',
            link: base_route + "credentials",
            index: 2
        }, 
        {
            label: 'Editar',
            link: base_route + "edit",
            index: 3
        }
    ];
    this.activeLinkIndex = this.navLinks.indexOf(this.navLinks.find(tab => tab.link.indexOf(this.router.url) != -1));
  }

}
