import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-add-contract-to-roadmap-dialog',
  templateUrl: './add-contract-to-roadmap-dialog.component.html',
  styleUrls: ['./add-contract-to-roadmap-dialog.component.css']
})
export class AddContractToRoadmapDialogComponent implements OnInit {

  form:FormGroup;

  constructor(
    public dialogRef: MatDialogRef<AddContractToRoadmapDialogComponent>,
    private fb:FormBuilder,
    private api:ApiService
  ) {
    this.form = this.fb.group({
      contract_id: ['', Validators.compose([Validators.required, Validators.min(0)])]
    });
  }


  ngOnInit(): void {
  }

  create() {
    this.api.addContractToRoadmap(this.form.value['contract_id']).subscribe(
      data => {
        this.close({ refresh: true });
      }
    );
  }

  close(val:any = null): void {
    this.dialogRef.close(val);
  }

}
