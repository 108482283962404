import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'daysToPeriod'
})
export class DaysToPeriodPipe implements PipeTransform {

  transform(value:number):string {
    let res:string = "";

    switch(value) {
      case 30:
        res = "mes";
        break;
      case 60:
        res = "dos meses";
        break;
      case 365: 
        res = "año";
        break;
      case 730:
        res = "dos años";
        break;
      default:
        res = `${value} días`;
        break;
    }

    return res;
  }

}
