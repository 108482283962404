import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TinymceService {

  constructor() { }

  getKey() {
    const idx = Math.floor(Math.random() * environment.tiny_api_keys.length);
    return environment.tiny_api_keys[idx];
  }
}
