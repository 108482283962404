<ul>
  <li routerLinkActive="active">
    <a mat-button [routerLink]="['/clients', 'projects']">
      <mat-icon>apps</mat-icon>
      <span>Proyectos</span>
    </a>
  </li>
  <li routerLinkActive="active">
    <a mat-button [routerLink]="['/clients', 'my-profile']">
      <mat-icon>location_city</mat-icon>
      <span>Mis datos</span>
    </a>
  </li>
</ul>
