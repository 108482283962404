import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-project-distribution-time-dialog-level',
  templateUrl: './project-distribution-time-dialog-level.component.html',
  styleUrls: ['./project-distribution-time-dialog-level.component.css']
})
export class ProjectDistributionTimeDialogLevelComponent implements OnDestroy {
  private _form: FormGroup;
  private formChangeSubscription: Subscription;
  periodicities = [
    { value: 'daily', label: 'Diario' },
    { value: 'weekly', label: 'Semanal' },
    { value: 'biweekly', label: 'Bisemanal' },
    { value: 'monthly', label: 'Mensual' }
  ];

  @Input()
  set form(value: FormGroup) {
    if (this.formChangeSubscription) {
      this.formChangeSubscription.unsubscribe();
    }
    this._form = value;
    this.subscribeToInputsToRecalculateReadonlyFields();
    this.formChangeSubscription = this._form.valueChanges.subscribe(() => {
      this.onFormChange();
    });
  }

  get form(): FormGroup {
    return this._form;
  }

  get nonFixedControls(): Map<string, FormGroup> {
    let r = new Map<string, FormGroup>();
    for (let key in this.form.controls) {
      if (!this.form.controls[key].value.is_fixed) {
        r.set(key, this.form.controls[key] as FormGroup);
      }
    }
    return r;
  }

  get fixedControls(): Map<string, FormGroup> {
    let r = new Map<string, FormGroup>();
    for (let key in this.form.controls) {
      if (this.form.controls[key].value.is_fixed) {
        r.set(key, this.form.controls[key] as FormGroup);
      }
    }
    return r;
  }

  @Output() formChange = new EventEmitter<FormGroup>();

  @Input() tags:{[key:string]:string};
  @Input() title:string|undefined;
  @Input() mockRealTime:number;

  constructor() { }

  // Method to call when the form changes
  onFormChange() {
    this.validatePrcnt();
    this.formChange.emit(this.form);
  }

  castToFormGroup(val:any):FormGroup {
    return val as FormGroup;
  }

  castToFormArray(val:any):FormArray {
    return val as FormArray;
  }

  castToString(val:any): string {
    return val as string;
  }

  ngOnDestroy() {
    if (this.formChangeSubscription) {
      this.formChangeSubscription.unsubscribe();
    }
  }

  handleFormChange(event:FormGroup, key:string) {
    (this.form.controls[key] as FormGroup).controls['children'] = event;
  }

  isReadonly(key:string) {
    return ["task-time", "task-time-tracking", "development", "revision-time"].indexOf(key) !== -1;
  }

  deleteProjectTeam(formKey:string) {
    this.form.removeControl(formKey);
    this.form.markAsTouched();
  }

  private validatePrcnt() {
    // 1r. Treiem antigues validacions

    // 2n. Comprovem sumatori
    let total:number = 0;
    for(let key in this.form.controls) {
      const ctrl = (this.form.controls[key] as FormGroup).controls['prcent'];
      ctrl.setErrors(null);
      total += ctrl.value;
    }

    // 3r. Apliquem si fa falta la validació
    if(total !== 100) {
      for(let key in this.form.controls) {
        const ctrl = (this.form.controls[key] as FormGroup).controls['prcent'];
        ctrl.setErrors({sum_100: true})
      }
    }
    this.form.updateValueAndValidity({ emitEvent: false });
  }

  private currentLevelSums100() {
    let total:number = 0;
    for(let key in this.form.controls) {
      const ctrl = (this.form.controls[key] as FormGroup).controls['prcent'];
      total += ctrl.value;
    }
    return total === 100;
  }

  private recalcCurrentLevelTo100() {
    let total:number = 0;
    let ctrlReadonly:FormControl|undefined;
    for(let key in this.form.controls) {
      const ctrl = (this.form.controls[key] as FormGroup).controls['prcent'];
      if(!this.isReadonly(key)) {
        total += ctrl.value;
      }
      else ctrlReadonly = ctrl as FormControl;
    }

    if(ctrlReadonly != null) {
      ctrlReadonly.setValue(100 - total as number);
    }
  }

  private formHasReadonlyAttribute() {
    let res:boolean = false;
    for(let key in this.form.controls) {
      if(this.isReadonly(key)) {
        res = true;
        break;
      }
    }
    return res;
  }

  private subscribeToInputsToRecalculateReadonlyFields() {
    if(this.formHasReadonlyAttribute()) {
      for(let key in this.form.controls) {
        if(!this.isReadonly(key)) {
          this.form.controls[key].valueChanges.subscribe(
            data => {
              if (data.is_fixed) {
                let days_on_week = 1;
                if (data.periodicity == 'daily') {
                  days_on_week = 5;
                }

                if (data.fixed<=0 || data.n_of_members<=0) {
                  data.prcent = 0;
                } else {
                  data.prcent = data.fixed * (data.n_of_members ?? 0) * days_on_week * 100 / this.mockRealTime;
                }

                (this.form.controls[key] as FormGroup).controls['prcent'].setValue(data.prcent, { emitEvent: false });

                if (data.period_start_date!=null) {
                  let date = new Date(data.period_start_date);
                  date.setHours(12, 0, 0, 0);
                  (this.form.controls[key] as FormGroup).controls['period_start_date'].setValue(date.toISOString(), { emitEvent: false });
                }
              }

              if (!this.currentLevelSums100()) {
                this.recalcCurrentLevelTo100();
              }
            }
          );
        }
      }
    }
  }
}
