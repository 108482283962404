import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'employeeMonthWorkingCapacityField'
})
export class EmployeeMonthWorkingCapacityFieldPipe implements PipeTransform {

  transform(value: string): string {
    switch(value) {
      case 'total_availability.hours':
        return 'Horas totales';
      case 'productivity_prcnt':
        return '% de producción';
      case 'brute_salary':
        return 'Salario bruto';
      case 'development_time.hours':
        return 'Horas de desarrollo';
      case 'development_budgetable_time.hours':
        return 'Horas presupuestables';
      case 'real_cost_for_bisual':
        return 'Coste para Bisual';
      case 'real_cost_for_bisual_with_vacations':
        return 'Coste para Bisual (con vacaciones)';
      case 'non_productive_hours':
        return 'Horas no productivas (negocio, comercial, etc)';
      case 'chicle_hours':
        return 'Horas chicle 🍭';
      case 'price_per_hour':
        return 'Precio por hora';
      case 'seguridad_social_prcnt':
        return 'Seguridad Social (%)';
      default:
        return '';
    }
  }

}
