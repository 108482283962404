<div id="content">
  <div class="page-title">
    <h1>Clientes potenciales</h1>

    <button mat-flat-button color="accent" (click)="openAddPotentialClientDialog()">Añadir cliente potencial</button>
  </div>

  <div class="bisual-table">
    <form class="card paginator" [formGroup]="filterForm">
      <mat-paginator [length]="length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" [pageIndex]="pageIndex"
        (page)="changePage($event)" style="margin-right: auto;">
      </mat-paginator>

      <mat-form-field>
        <input matInput type="text" formControlName="search">
        <button matSuffix mat-icon-button aria-label="Clear">
          <mat-icon>search</mat-icon>
        </button>
      </mat-form-field>
    </form>

    <div class="bisual-table-container">
      <table mat-table [dataSource]="dataSource">

        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>Nombre</th>
          <td mat-cell *matCellDef="let item">{{ item.full_name }}</td>
        </ng-container>

        <ng-container matColumnDef="company">
          <th mat-header-cell *matHeaderCellDef>Empresa</th>
          <td mat-cell *matCellDef="let item">{{ item.company_name }}</td>
        </ng-container>

        <ng-container matColumnDef="updated_at">
          <th mat-header-cell *matHeaderCellDef>Últ. Actualización</th>
          <td mat-cell *matCellDef="let item">{{ item.updated_at | date:'short' }}</td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef>Estado</th>
          <td mat-cell *matCellDef="let item"><span class="status-badge">{{ item.potential_client_status.name }}</span></td>
        </ng-container>

        <ng-container matColumnDef="comercial">
          <th mat-header-cell *matHeaderCellDef>Comercial asign.</th>
          <td mat-cell *matCellDef="let item">{{ item.commercial.name + ' ' + item.commercial.surnames }}</td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let item">
            <button mat-icon-button [matMenuTriggerFor]="menu">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item [routerLink]="[item.id]" [queryParams]="getDetailQueryParams()">
                <mat-icon>visibility</mat-icon>
                <span>Ver detalle...</span>
              </button>
              <button mat-menu-item [disabled]="item.phone == null">
                <mat-icon>dialpad</mat-icon>
                <span>Llamar</span>
              </button>
              <button mat-menu-item [disabled]="item.email == null">
                <mat-icon>mail</mat-icon>
                <span>Enviar email</span>
              </button>
            </mat-menu>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
  </div>

</div>
