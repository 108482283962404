<h1 mat-dialog-title>{{ data.dialog_title }}</h1>

<mat-dialog-content>
  <mat-form-field appearance="outline">
    <mat-select [(ngModel)]="value">
      @for(row of data.data; track row;) {
        <mat-option [value]="row.value">{{ row.textToShow }}</mat-option>
      }
    </mat-select>
  </mat-form-field>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button (click)="close()">{{ data.dialog_cancel_button }}</button>
  <button mat-flat-button color="accent" (click)="submit()" [disabled]="value == null">{{ data.dialog_ok_button }}</button>
</mat-dialog-actions>
