import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { User } from 'src/app/models/user.model';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-project-team-credit-dialog',
  templateUrl: './project-team-credit-dialog.component.html',
  styleUrl: './project-team-credit-dialog.component.css'
})
export class ProjectTeamCreditDialogComponent implements OnInit {
  form: UntypedFormGroup;

  users:User[] = [];
  usersSearchCtrl = new FormControl('');
  filteredUsers: Observable<User[]>;

  get isCreate() { return this.data.project_team.id == null };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ProjectTeamCreditDialogComponent>,
    private fb: UntypedFormBuilder,
    private api:ApiService
  ) {
    this.form = this.fb.group({
      name: ['', Validators.compose([Validators.required, Validators.minLength(5)])],
      project_id: ['', Validators.required],
      users: [[], Validators.compose([Validators.required, Validators.minLength(1)])],
    });

    this.initFilteredUsers();
  }

  ngOnInit(): void {
    this.patchInput();
    this.fetchUsers();
  }

  onCancel() {
    this.dialogRef.close();
  }

  removeUser(idx:number) {
    const users:User[] = this.form.value.users;
    users.splice(idx, 1);
    this.form.controls['users'].patchValue(users);
  }

  selectedUser(user:User) {
    const users:User[] = this.form.value.users;
    users.push(user);
    this.form.controls['users'].patchValue(users);
    this.usersSearchCtrl.setValue('');
  }

  submit() {
    const data = {...this.form.value};
    data.users = data.users.map((u:User) => u.id)
    if(this.isCreate) {
      this.api.createProjectTeam(data).subscribe(
        data => {
          this.dialogRef.close(data);
        }
      );
    }
    else {
      this.api.updateProjectTeam(this.data.project_team.id, data).subscribe(
        data => {
          this.dialogRef.close(data);
        }
      );
    }
  }

  private initFilteredUsers() {
    this.filteredUsers = this.usersSearchCtrl.valueChanges.pipe(
      startWith(null),
      map((user: string | null) => (user && (typeof user === "string") ? this._filterUsers(user) : this.users.slice())),
    );
  }

  private _filterUsers(user: string): User[] {
    const filterValue = user.toLowerCase();
    return this.users.filter(user => user.name.toLowerCase().includes(filterValue));
  }

  private patchInput() {
    this.form.patchValue(this.data.project_team);
  }

  private fetchUsers() {
    this.api.getActiveProjectMembersWithBusiness(this.form.value.project_id).subscribe(
      data => {
        this.users = data.bisual_users.map(m => m.user).concat(data.company_users);
      }
    );
  }
}
