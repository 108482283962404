import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PotentialClient, PotentialClientStatus } from 'src/app/models/potential-client.interface';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-potential-client-change-status-dialog',
  templateUrl: './potential-client-change-status-dialog.component.html',
  styleUrls: ['./potential-client-change-status-dialog.component.css']
})
export class PotentialClientChangeStatusDialogComponent implements OnInit {

  form:FormGroup;
  potential_client_statuses:PotentialClientStatus[] = [];
  current_potential_client:PotentialClient;

  constructor(private fb:FormBuilder,
              public dialogRef: MatDialogRef<PotentialClientChangeStatusDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data:any,
              private api:ApiService) {

    this.current_potential_client = data.potential_client;

    this.form = this.fb.group({
      potential_client_status_slug: ['', Validators.required]
    });
  }

  ngOnInit(): void {
    this.fetchStatuses();
  }

  close(): void {
    this.dialogRef.close();
  }

  private fetchStatuses() {
    this.api.getPotentialClientStatus().subscribe(
      data => {
        this.potential_client_statuses = data;
      }
    );
  }
}
