import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Hosting } from 'src/app/models/hosting.model';
import { ApiService } from 'src/app/services/api.service';
import { PermissionsService } from 'src/app/services/permissions.service';
import { RegexService } from 'src/app/services/regex.service';

@Component({
  selector: 'app-hosting-detail-edit',
  templateUrl: './hosting-detail-edit.component.html',
  styleUrls: ['./hosting-detail-edit.component.css']
})
export class HostingDetailEditComponent implements OnInit {

  formGroup:UntypedFormGroup;

  private _hosting:Hosting = null as any;
  get hosting() { return this._hosting; };
  @Input() set hosting(h:Hosting) {
    if(h!=null) {
      this._hosting = h;
      this.formGroup.patchValue(h);
    }
  }

  constructor(private fb:UntypedFormBuilder,
              private api:ApiService,
              private regex:RegexService,
              public permissions:PermissionsService) {

    const regexParams = this.regex.getRegex();

    this.formGroup = this.fb.group({
      id: ['', Validators.required],
      hosting_fqdn: ['', Validators.compose([Validators.required, Validators.pattern(new RegExp(regexParams['fqdn']))])],
      ip: ['', Validators.compose([Validators.required, Validators.pattern(new RegExp(regexParams['ipv4']))])],
      provider_id: ['', Validators.required],
      user_id: [''],
      expiration_date: [''],
      is_fact_external: ['', Validators.required],
      renewal_days_period: ['', Validators.required],
      is_shared: ['', Validators.required],
      hetzner_id: [''],
      cores: ['', Validators.required],
      disk: ['', Validators.required],
      memory: ['', Validators.required],
      quantity: [''],
    });

  }

  ngOnInit(): void {
    
  }

  save() {
    this.api.updateHosting(this.hosting.id, this.formGroup.value).subscribe(
      data => {
        
      }
    );
  }

}
