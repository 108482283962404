<h1 mat-dialog-title>Añadir nuevo correo corporativo</h1>
<mat-dialog-content [formGroup]="mailForm">
  <div class="container">
    <div class="row">
      <div class="col-12 col-lg-4">
        <mat-form-field appearance="outline">
          <mat-label>Correo electrónico*</mat-label>
          <input matInput formControlName="email">
        </mat-form-field>
      </div>
      <div class="col-12 col-lg-4">
        <mat-form-field appearance="outline">
          <mat-label>Proveedor*</mat-label>
          <mat-select formControlName="provider_id">
            @for (p of providers; track p) {
              <mat-option [value]="p.id">{{p.name}}</mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-12 col-lg-4">
        <mat-form-field appearance="outline">
          <mat-label>Propietario</mat-label>
          <input type="text" placeholder="Escribe un nombre" matInput
            [formControl]="propietarioControl"
            [matAutocomplete]="auto">
          <mat-autocomplete #auto="matAutocomplete">
            @for (company of companies_filtered; track company) {
              <mat-option [value]="company.fact_name" (click)="selectPropietario(company)">{{ company.fact_name }}</mat-option>
            }
          </mat-autocomplete>
          <mat-hint>Dejar en blanco si es de Bisual</mat-hint>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      @if (!isOvhEnabled()) {
        <div class="col-12 col-lg-4">
          <mat-form-field appearance="outline">
            <mat-label>Período de renovación*</mat-label>
            <mat-select formControlName="renewal_days_period">
              <mat-option [value]="30">Mensual</mat-option>
              <mat-option [value]="60">Bimensual</mat-option>
              <mat-option [value]="90">Trimestral</mat-option>
              <mat-option [value]="120">Semestral</mat-option>
              <mat-option [value]="365">Anual</mat-option>
              <mat-option [value]="720">Bianual</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      }
      <div class="col-12 col-lg-4">
        <mat-form-field appearance="outline">
          <mat-label>Precio (€)*</mat-label>
          <input matInput type="text" formControlName="quantity">
          <span matSuffix>€</span>
          <mat-hint>Precio que se le cobra al cliente</mat-hint>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-lg-4">
        <mat-checkbox formControlName="is_fact_external">Facturado externamente</mat-checkbox>
      </div>
      @if (mailForm.value['company_id']!=null && mailForm.value['company_id']!='') {
        <div class="col-12 col-lg-8">
          <mat-checkbox formControlName="trial_enabled">Empezar con período de prueba</mat-checkbox>
          <span style="display: block; font-size: 12px;">(Útil cuando el 1r pago ha sido pagado con un proyecto)</span>
        </div>
      }
    </div>
  </div>

  @if (isOvhEnabled()) {
    <div class="container">
      <h2 class="dialog-section">Apartado OVH</h2>
      <div class="row">
        <div class="col-12 col-lg-6">
          <mat-form-field appearance="outline">
            <mat-label>Producto de Exchange disponible</mat-label>
            <mat-select>
              @for (offer of offers; track offer) {
                <mat-option [value]="offer" (onSelectionChange)="selectExchange(offer)" [matTooltip]="offer.planCode">{{ offer.productName }}</mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-12 col-lg-6">
          @if (selectedExchange != null) {
            <mat-form-field appearance="outline">
              <mat-label>Pricing del Exchange</mat-label>
              <mat-select>
                @for (pr of selectedExchange.prices; track pr) {
                  <mat-option [value]="pr" (onSelectionChange)="selectExchangePricing(pr)">{{ pr.price.text }}/{{ pr.interval }} {{ pr.duration }}</mat-option>
                }
              </mat-select>
            </mat-form-field>
          }
        </div>
      </div>
      @if (selectedExchange != null) {
        <div class="row">
          <div class="col-12 col-lg-6">
            <mat-form-field appearance="outline">
              <mat-label>Opciones de Exchange disponibles</mat-label>
              <mat-select>
                @for (option of options; track option) {
                  <mat-option [value]="option" (onSelectionChange)="selectExchangeOption(option)">{{ option.productName }}</mat-option>
                }
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-12 col-lg-6">
            @if (selectedExchangeOption != null) {
              <mat-form-field appearance="outline">
                <mat-label>Pricing de la cuenta de Exchange</mat-label>
                <mat-select>
                  @for (pr of selectedExchangeOption.prices; track pr) {
                    <mat-option [value]="pr" (onSelectionChange)="selectExchangeOptionPricing(pr)">{{ pr.price.text }}/{{ pr.interval }} {{ pr.duration }}</mat-option>
                  }
                </mat-select>
              </mat-form-field>
            }
          </div>
        </div>
      }
    </div>
  }

  @if (!isOvhEnabled()) {
    <div class="container">
      <div class="row">
        <div class="col-12 col-lg-4">
          <mat-form-field appearance="outline">
            <mat-label>Fecha de expiración*</mat-label>
            <input matInput [matDatepicker]="picker" formControlName="expiration_date">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="col-12 col-lg-4">
          <mat-form-field appearance="outline">
            <mat-label>Servidor IMAP</mat-label>
            <input matInput formControlName="imap_server">
          </mat-form-field>
        </div>
        <div class="col-12 col-lg-4">
          <mat-form-field appearance="outline">
            <mat-label>Puerto IMAP</mat-label>
            <mat-select formControlName="imap_port">
              <mat-option [value]="143">143</mat-option>
              <mat-option [value]="993">993</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-lg-4">
          <mat-form-field appearance="outline">
            <mat-label>Encriptación IMAP</mat-label>
            <mat-select formControlName="imap_encryption">
              <mat-option value="no_auth">No auth</mat-option>
              <mat-option value="start_tls">STARTTLS</mat-option>
              <mat-option value="ssl">SSL</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-12 col-lg-4">
          <mat-form-field appearance="outline">
            <mat-label>Servidor SMTP</mat-label>
            <input matInput formControlName="smtp_server">
          </mat-form-field>
        </div>
        <div class="col-12 col-lg-4">
          <mat-form-field appearance="outline">
            <mat-label>Puerto SMTP</mat-label>
            <mat-select formControlName="smtp_port">
              <mat-option [value]="25">25</mat-option>
              <mat-option [value]="26">26</mat-option>
              <mat-option [value]="465">465</mat-option>
              <mat-option [value]="2525">2525</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-lg-4">
          <mat-form-field appearance="outline">
            <mat-label>Encriptación SMTP</mat-label>
            <mat-select formControlName="smtp_encryption">
              <mat-option value="no_auth">No auth</mat-option>
              <mat-option value="start_tls">STARTTLS</mat-option>
              <mat-option value="ssl">SSL</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-12 col-lg-8">
          <mat-form-field appearance="outline">
            <mat-label>Webmail URL</mat-label>
            <input matInput formControlName="webmail_url">
          </mat-form-field>
        </div>
      </div>
    </div>
  }
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button (click)="onNoClick()" cdkFocusInitial type="button">Cancelar</button>
  <button mat-stroked-button color="accent" [mat-dialog-close]="mailForm.value" [disabled]="!mailForm.valid || loadingStack.length>0" type="submit">
    <mat-icon matPrefix>add</mat-icon>
    Crear correo corporativo
  </button>
</mat-dialog-actions>
