import { CurrencyPipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { DepartmentCategory } from 'src/app/models/department_category.model';
import { User } from 'src/app/models/user.model';
import { ApiService } from 'src/app/services/api.service';
import { DepartmentService } from 'src/app/services/department.service';
import { PermissionsService } from 'src/app/services/permissions.service';


@Component({
  selector: 'app-department-category-detail',
  templateUrl: './department-category-detail.component.html',
  styleUrls: ['./department-category-detail.component.css']
})
export class DepartmentCategoryDetailComponent implements OnInit {

  department_category_id:string = null as any;

  titleCategory = 'Categoria';

  categoryForm:UntypedFormGroup;



  constructor( public router: Router,
               public activatedRoute: ActivatedRoute,
               private fb:UntypedFormBuilder,
               private api:ApiService,
               private departmentService: DepartmentService,
               public permissions:PermissionsService,
              ) {


    this.categoryForm = this.fb.group({
      name: ['', Validators.compose([Validators.required])],
      description: ['', Validators.compose([Validators.required])],
      min_salary: ['', Validators.compose([Validators.required])],
      max_salary: ['', Validators.compose([Validators.required])],
      //num_employees: ['', Validators.compose([Validators.required])],
      department_name: ['', Validators.compose([Validators.required])],
    });

  }

  ngOnInit(): void {
    this.department_category_id = (this.activatedRoute.snapshot.paramMap.get('department_category_id') ?? this.departmentService.getCurrentDepartment().id) as string;
    this.fetchDepartmentCategory();
  }

  private fetchDepartmentCategory() {
    this.api.getDepartmentCategory(this.department_category_id).subscribe(
      data => {
        this.categoryForm.patchValue(data);
        this.titleCategory = data.name;
      }
    )
  }

  save() {
    if(confirm("¿Estás seguro que quieres guardar esta categoria?"))
    this.api.updateDepartmentCategory(this.department_category_id, this.categoryForm.value).subscribe();
  }

  updateDepartmentCategory() {
    if(confirm("¿Estás seguro que quieres guardar esta categoria?")){
      this.api.updateDepartmentCategory(this.department_category_id, this.categoryForm.value).subscribe();
    }

  }

}
