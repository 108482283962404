<div id="content" class="extensible">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="page-title">
          <h1>Planificación y hoja de ruta</h1>

          @if (showSaveButton) {
            <button mat-flat-button color="success" [disabled]="isSaving" (click)="save()">
              <mat-icon>done</mat-icon>
              {{ isSaving ? 'Guardando...' : 'Guardar cambios' }}
            </button>
          }

          <button mat-flat-button color="accent" (click)="openAddContractDialog()">
            <mat-icon>add</mat-icon>
            Añadir nuevo contrato al roadmap
          </button>

          <button mat-icon-button [matMenuTriggerFor]="menu">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item>
              <mat-checkbox [ngModel]="test_activated" (ngModelChange)="onChangeEsbozo($event)">Modo esbozo</mat-checkbox>
            </button>
            <button mat-menu-item disabled>
              <mat-icon>date_range</mat-icon>
              <span>Cambiar período...</span>
            </button>
            <button mat-menu-item (click)="scrollToCurrentWeek()">
              <mat-icon>today</mat-icon>
              <span>Hoy</span>
            </button>
          </mat-menu>
        </div>
      </div>
    </div>
  </div>

  @if (availability!=null && availability.length > 0 && roadmap!=null) {
    <div id="roadmap">
      <table>
        <thead>
          <tr><!-- Row del mes -->
            <th colspan="2" class="project" [style.z-index]="4"><!-- Columna de Empresa i Project --></th>
            @for (avail of availability; track avail) {
              <th colspan="7" class="month" [matTooltip]="weekTooltip(avail)" [ngClass]="{'current-week': avail.from.isSame(now, 'week')}"><!-- Columna de cada una de les setmanes -->
                {{ avail.from.format('MMMM \'YY') }}
              </th>
            }
          </tr>
          <tr><!-- Row de la setmana -->
            <th colspan="2" class="project" [style.z-index]="4"><!-- Columna de Empresa i Project --></th>
            <!-- Columna de cada una de les setmanes -->
            @for (item of daysOfWeek; track item) {
<th class="day" [matTooltip]="weekTooltip(item.avail)" [ngClass]="{
              'last-day-week': item.day.day()===0,
              'current-week': item.avail.from.isSame(now, 'week'),
              'weekend': [0,6].indexOf(item.day.day()) >= 0,
              'today': item.day.isSame(now, 'day'),
              'has-holidays': item.has_holidays,
              'has-non-working-times': item.has_non_working_times
            }">
                <span>{{ item.day.format('D') }}</span>
              </th>
            }
          </tr>
        </thead>
        <tbody>
          @for (entity of roadmap; track entity; let i = $index) {
            @if (test_activated || !entity.is_test) {
              <tr [ngClass]="{'last-company-row': entity.is_last_company, 'test': entity.is_test}">
                @if (entity.is_first_project) {
                  <th class="project" [attr.rowspan]="entity.project_or_contract_rowspan"><!-- Columna de Project -->
                    <div class="entity">
                      <span class="badge"><mat-icon>{{ 'bolt' }}</mat-icon></span>
                      <span class="id">{{ entity.entity_id }}</span>
                      <span class="title">
                        {{ entity.project.title }}
                        <span class="role">{{ entity.entity_name }} - {{ entity.company.fact_name }}</span>
                      </span>
                    </div>
                  </th>
                }
                <th class="contract"><!-- Columna de contracte -->
                  <div class="contract-container">
                    {{ (entity.type==='partner' ? 'Partner #' : 'Genuine #') + entity.id }}
                    @if (entity.genuine_total_hours != null && entity.type === 'project') {
                      <span class="availability">
                        {{ entity.genuine_total_hours | number:'1.0-0' }}h
                      </span>
                    }
                  </div>
                </th>
                @for (week of entity.weeks; track week; let j = $index) {
                  <td colspan="7" class="week" [ngClass]="getEntityWeekClasses(entity, week)"><!-- Columna de cada una de les setmanes -->
                    <div class="input-wrapper">
                      <input type="number" min="0" [ngModel]="week.hours" (ngModelChange)="onChangeInput($event, i, j)" [disabled]="isSaving || now > week.to"/>
                      @if (!isSaving && now <= week.to) {
                        <button mat-icon-button [matMenuTriggerFor]="menuInputHours">
                          <mat-icon>more_vert</mat-icon>
                        </button>
                      }
                      <mat-menu #menuInputHours="matMenu">
                        @if (week.weekly_working_time_budget_id != null) {
                          <button mat-menu-item (click)="saveAndChargeDefaults(i,j)">
                            <mat-icon>restart_alt</mat-icon>
                            <span>{{ week.changed ? 'Guardar horas y cargar distribución por defecto' : 'Cargar distribución por defecto' }}</span>
                          </button>
                        }
                        <button mat-menu-item (click)="openDistributablesPopup(i,j)">
                          <mat-icon>hourglass_bottom</mat-icon>
                          <span>Abrir distribución de tiempo</span>
                        </button>
                        <button mat-menu-item (click)="openProjectAssignmentDialog(i,j)">
                          <mat-icon>computer</mat-icon>
                          <span>Asignar proyectos</span>
                        </button>
                        <button mat-menu-item (click)="propagateDistributionAndAssignation(i,j)">
                          <mat-icon>start</mat-icon>
                          <span>Propagar</span>
                        </button>
                      </mat-menu>
                    </div>
                    @if (week.hours > 0 && week.distributable_status != null && week.distributable_status.distributable_categories.length) {
                      <div class="distribution-wrapper">
                        <ul>
                          @for (cat of week.distributable_status.distributable_categories; track cat) {
                            <li [matTooltip]="'Horas por hacer: ' + (cat.total_hours_to_do|number:'1.0-2') + 'h. Horas asignadas: ' + (cat.hours_assigned_budgetable | number:'1.0-2') + 'h. Horas disponibles: ' + (cat.total_hours_availables | number:'1.0-2') + 'h.'">
                    <span class="badge" [ngClass]="{
                      'ok': (cat.hours_assigned_budgetable-cat.total_hours_to_do <= 2) && (cat.hours_assigned_budgetable-cat.total_hours_to_do > -1),
                      'overflow': (cat.hours_assigned_budgetable-cat.total_hours_to_do > 2)
                    }">{{ cat.short_name }}</span>
                              <span class="hours">
                                {{ cat.hours_assigned_budgetable | number:'1.0-0' }}h / {{ cat.total_hours_to_do | number:'1.0-0' }}h
                              </span>
                              <div class="progress-bar-wrapper">
                                <div class="progress-bar-progress todo" [ngStyle]="{'width': (cat.total_hours_to_do / cat.total_hours_availables)*100 + '%'}">
                                </div>
                                <div class="progress-bar-progress assigned" [ngStyle]="{'width': (cat.hours_assigned_budgetable / cat.total_hours_availables)*100 + '%'}">
                                </div>
                              </div>
                            </li>
                          }
                        </ul>
                      </div>
                    }
                  </td>
                }
              </tr>
            }
          }
        </tbody>
        <tfoot>
          <tr><!-- Capacidad libre -->
            <th class="project" colspan="2" [matTooltip]="'Basado en las horas presupuestables (% de producción aplicado) de los trabajadores.'">Capacidad libre</th>
            @for (avail of availability; track avail) {
              <td class="week" colspan="7" [ngClass]="{'ok': avail.budgetable_availability_remaining >= -5 && avail.budgetable_availability_remaining < 30, 'incorrect': avail.budgetable_availability_remaining < -5, 'warning': avail.budgetable_availability_remaining >= 30 }">{{ avail.budgetable_availability_remaining | number:'1.0-2' }}h</td>
            }
          </tr>
          <tr><!-- Capacidad total -->
            <th class="project" colspan="2" [matTooltip]="'Basado en las horas presupuestables (% de producción aplicado) de los trabajadores.'">Capacidad total</th>
            @for (avail of availability; track avail) {
              <td class="week" colspan="7">{{ avail.budgetable_availability | number:'1.0-2' }}h</td>
            }
          </tr>
        </tfoot>
      </table>
    </div>
  }

</div>
