import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { WorkingTime } from 'src/app/models/working_time.model';
import { ApiService } from 'src/app/services/api.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-working-time-history-day-detail-dialog',
  templateUrl: './working-time-history-day-detail-dialog.component.html',
  styleUrls: ['./working-time-history-day-detail-dialog.component.css']
})
export class WorkingTimeHistoryDayDetailDialogComponent implements OnInit {

  worklogs:WorkingTime[];
  user_id:number = null as any;

  constructor(public dialogRef: MatDialogRef<WorkingTimeHistoryDayDetailDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data:any,
              private api:ApiService,
              private user:UserService) {

    this.worklogs = this.data.worklogs;

  }

  ngOnInit(): void {
    this.listenUserService();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  delete(i:number) {
    if(confirm("¿Estás seguro que quieres borrar este registro de tiempo?"))
    this.api.deleteWorkingTime(this.worklogs[i].id).subscribe(
      data => {
        this.worklogs.splice(i, 1);
      }
    );
  }

  private listenUserService() {
    this.user.currentUser.subscribe(
      data => {
        this.user_id = data.id;
      }
    );
  }

}
