import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { PermissionsService } from 'src/app/services/permissions.service';
import { RegexService } from 'src/app/services/regex.service';
import { TokenService } from 'src/app/services/token.service';
import * as Countries from 'src/assets/json/countries.json';

@Component({
  selector: 'app-provider-detail',
  templateUrl: './provider-detail.component.html',
  styleUrls: ['./provider-detail.component.css']
})
export class ProviderDetailComponent implements OnInit {

  provider_id:string = null as any;
  countries:any[] = (Countries as any).default;

  providerForm:UntypedFormGroup;

  constructor(private activatedRoute:ActivatedRoute,
              private api:ApiService,
              private fb:UntypedFormBuilder,
              private router:Router,
              public dialog: MatDialog,
              private tokenService:TokenService,
              private regex:RegexService,
              public permissions:PermissionsService) {

    const regexPatterns = this.regex.getRegex();

    this.providerForm = this.fb.group({
      name: ['', Validators.compose([Validators.required])],
      email: ['', Validators.compose([Validators.required, Validators.email])],
      phone: ['', Validators.compose([Validators.required, Validators.pattern(regexPatterns['phone'])])],
      website: [''],
      is_hosting_provider: [false],
      is_domain_provider: [false],
      is_business_mail_provider: [false],
      slug: ['', Validators.compose([Validators.required, Validators.minLength(3)])],
      fact_name: ['', Validators.compose([Validators.required])],
      fact_address: ['', Validators.compose([Validators.required])],
      fact_city: ['', Validators.compose([Validators.required])],
      fact_zipcode: ['', Validators.compose([Validators.required, Validators.minLength(5), Validators.maxLength(5)])],
      fact_county: ['', Validators.compose([Validators.required])],
      fact_country: ['', Validators.compose([Validators.required])],
      fact_country_alpha2: ['', Validators.compose([Validators.required])],
      vat: ['', Validators.compose([Validators.required])]
    });

  }

  ngOnInit(): void {
    this.provider_id = this.activatedRoute.snapshot.paramMap.get('id') as string;
    this.fetchProvider();
    this.listenCountryChanges();
  }

  save() {
    this.api.updateProvider(this.provider_id, this.providerForm.value).subscribe(
      data => {

      }
    );
  }

  delete() {
    if(confirm("¿Estás seguro que quieres eliminar este proveedor?")) {
      this.api.deleteProvider(+this.provider_id).subscribe(
        data => {
          this.router.navigate(['/employees', 'direction', 'users-management', 'providers']);
        }
      );
    }
  }

  private fetchProvider() {
    this.api.getProvider(this.provider_id).subscribe(
      data => {
        this.providerForm.patchValue(data);
      }
    );
  }

  private listenCountryChanges() {
    this.providerForm.controls['fact_country'].valueChanges.subscribe(
      data => {
        for(let c of this.countries) {
          if(c.name==data) {
            this.providerForm.patchValue({ fact_country_alpha2: c.alpha_2 });
            break;
          }
        }
      }
    );
  }

}
