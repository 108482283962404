@if (project) {
  <h1 mat-dialog-title>Distribución de tiempo: {{ project.title }}</h1>
}

@if (form && tags) {
  <mat-dialog-content [formGroup]="form" class="container">
    <app-project-distribution-time-dialog-level
      [form]="form"
      (formChange)="handleFormChange($event)"
      [tags]="tags"
      [mockRealTime]="mockRealTime"
    ></app-project-distribution-time-dialog-level>
  </mat-dialog-content>
}

<mat-dialog-actions align="end">
  @if(project_teams) {
    <button style="margin-right: auto" mat-stroked-button (click)="openProjectTeamDialog()">
      <mat-icon>add</mat-icon>
      Añadir equipo de proyecto
    </button>
  }
  <button mat-flat-button (click)="close()">
    <mat-icon>close</mat-icon>
    Cancelar
  </button>
  <button mat-flat-button color="accent" [disabled]="!form.touched" (click)="save()">
    <mat-icon>done</mat-icon>
    Guardar
  </button>
</mat-dialog-actions>
