@if (potential_client) {
  <div id="content">
    <div class="page-title">
      <h1>Cliente potencial: {{ potential_client.full_name }}</h1>
      <button mat-flat-button [routerLink]="['/employees', 'comercial', 'potential-clients']" [queryParams]="queryParamBackObject">
        <mat-icon>arrow_back</mat-icon>
        Volver
      </button>
      <button mat-flat-button color="accent" (click)="openChangeStatusDialog()">
        <mat-icon>refresh</mat-icon>
        Actualizar estado
      </button>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <div class="card-title">
                <h2>Detalle</h2>
                <p class="subtitle">Detalle de todos los atributos de este cliente potencial</p>
              </div>
              <button mat-flat-button color="accent" (click)="openEditDialog()">
                <mat-icon>edit</mat-icon>
                Editar datos
              </button>
            </div>
            <div class="container">
              <div class="row">
                <div class="col-12 col-lg-4">
                  <p class="title">Nombre</p>
                  <p class="value">{{ potential_client.full_name }}</p>
                </div>
                <div class="col-12 col-lg-4">
                  <p class="title">Empresa</p>
                  <p class="value">{{ potential_client.company_name }}</p>
                </div>
                <div class="col-12 col-lg-4">
                  <p class="title">Correo electrónico</p>
                  <p class="value">{{ potential_client.email }}</p>
                </div>
                <div class="col-12 col-lg-4">
                  <p class="title">Telefóno</p>
                  <p class="value">{{ potential_client.phone }}</p>
                </div>
                <div class="col-12 col-lg-4">
                  <p class="title">Estado</p>
                  <p class="value">{{ potential_client.potential_client_status.name }}</p>
                </div>
                <div class="col-12 col-lg-4">
                  <p class="title">Estrategia de contacto</p>
                  <p class="value">{{ potential_client.contact_strategy | titlecase }}</p>
                </div>
                <div class="col-12 col-lg-4">
                  <p class="title">Canal de contacto</p>
                  <p class="value">{{ potential_client.contact_channel | titlecase }}</p>
                </div>
                <div class="col-12 col-lg-4">
                  <p class="title">Comercial asignado</p>
                  <p class="value">{{ potential_client.commercial.name + ' ' + potential_client.commercial.surnames }}</p>
                </div>
                <div class="col-12 col-lg-4">
                  <p class="title">Product Owner asignado</p>
                  <p class="value">{{ potential_client.product_owner!=null ? potential_client.product_owner.name + ' ' + potential_client.product_owner.surnames : '-' }}</p>
                </div>
                <div class="col-12">
                  <p class="title">Descripción</p>
                  <p class="value" style="margin-bottom: unset">{{ potential_client.description }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <div class="card-title">
                <h2>Actas de reunión</h2>
                <p class="subtitle">Registra aquí todas las actas de las reuniones que tengas con el cliente</p>
              </div>
              <button mat-flat-button color="accent" [routerLink]="['meeting-records']">
                <mat-icon>add_user</mat-icon>
                Crear acta de reunión
              </button>
            </div>
            <div class="bisual-table" id="meetings-table">
              <div class="bisual-table-row-header">
                <div class="bisual-table-col">Concepto</div>
                <div class="bisual-table-col">Fecha</div>
                <div class="bisual-table-col">Asistentes cliente</div>
                <div class="bisual-table-col">Asistentes Bisual</div>
                <div class="bisual-table-col"></div>
              </div>
              @for (record of potential_client.meeting_records; track record) {
                <div class="bisual-table-row">
                  <div class="bisual-table-col">{{ record.title }}</div>
                  <div class="bisual-table-col">{{ record.date | date:'short' }}</div>
                  <div class="bisual-table-col">{{ record.assistants_client }}</div>
                  <div class="bisual-table-col">{{ record.assistants_provider }}</div>
                  <div class="bisual-table-col">
                    <button mat-button color="accent" [routerLink]="['meeting-records', record.id]">Ver acta</button>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <div class="card-title">
                <h2>Comentarios</h2>
                <p class="subtitle">Deja tus comentarios para ayudar a tus compañeros a mantener el estado de la situación.</p>
              </div>
            </div>
            <div class="bisual-table" id="comments-table">
              <div class="bisual-table-row-header">
                <div class="bisual-table-col">Autor</div>
                <div class="bisual-table-col">Contenido</div>
                <div class="bisual-table-col">Fecha</div>
              </div>
              @for (comment of potential_client.potential_client_comments; track comment) {
                <div class="bisual-table-row">
                  <div class="bisual-table-col">{{ comment.author.name + ' ' + comment.author.surnames }}</div>
                  <div class="bisual-table-col">{{ comment.content }}</div>
                  <div class="bisual-table-col">{{ comment.created_at | date:'short' }}</div>
                </div>
              }
              <div class="bisual-table-row" id="addCommentRow" [formGroup]="addCommentFormGroup">
                <div class="bisual-table-col">
                  <mat-form-field appearance="outline">
                    <mat-label>Añade un comentario...</mat-label>
                    <textarea matInput rows="5" formControlName="content"></textarea>
                  </mat-form-field>
                </div>
                <div class="bisual-table-col">
                  <button mat-flat-button color="accent" type="submit" [disabled]="!addCommentFormGroup.valid" (click)="addComment()">
                    <mat-icon>add_user</mat-icon>
                    Añadir comentario
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <div class="card-title">
                <h2>Histórico</h2>
                <p class="subtitle">Observa los cambios y los pasos que se han seguido con este cliente.</p>
              </div>
            </div>
            <div class="bisual-table" id="historic-table">
              <div class="bisual-table-row-header">
                <div class="bisual-table-col">Actualizador</div>
                <div class="bisual-table-col">Cambio</div>
                <div class="bisual-table-col">Fecha</div>
              </div>
              @for (record of potential_client.potential_client_status_change_records; track record) {
                <div class="bisual-table-row">
                  <div class="bisual-table-col">{{ record.user.name + ' ' + record.user.surnames }}</div>
                  <div class="bisual-table-col">
                    <span class="status-badge">{{ record.old_status!=null ? record.old_status.name : 'Sin estado' }}</span>
                    <mat-icon>trending_flat</mat-icon>
                    <span class="status-badge">{{ record.new_status.name }}</span>
                  </div>
                  <div class="bisual-table-col">{{ record.created_at | date:'short' }}</div>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
}
