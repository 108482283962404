import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EmployeeWorkingSheetDay } from 'src/app/models/employee-working-sheet.model';

@Component({
  selector: 'app-clock-registers-management-table-detail-dialog',
  templateUrl: './clock-registers-management-table-detail-dialog.component.html',
  styleUrls: ['./clock-registers-management-table-detail-dialog.component.css']
})
export class ClockRegistersManagementTableDetailDialogComponent implements OnInit {

  ews:EmployeeWorkingSheetDay = null as any;

  constructor(@Inject(MAT_DIALOG_DATA) public data:{ews:EmployeeWorkingSheetDay}) { }

  ngOnInit(): void {
    this.ews = this.data.ews;
  }

}
