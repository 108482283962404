<div id="content">

  <div class="page-title">
    <h1>Departamentos</h1>
    <button mat-flat-button color="accent" (click)="addDepartment()" [disabled]="!permissions.validateDepartment(['direction', 'rrhh'])">
      <!--[disabled]="!permissions.validateDepartment(['direction', 'rrhh'])-->
      <mat-icon style="margin-right: 10px">supervised_user_circle</mat-icon>
      Añadir Departamento
    </button>
  </div>

  <div class="bisual-table">
    <form class="card paginator" [formGroup]="filterForm">
      <mat-paginator [length]="length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" [pageIndex]="pageIndex"
        (page)="changePage($event)" style="margin-right: auto;">
      </mat-paginator>
      <mat-form-field>
        <input matInput type="text" formControlName="search">
        <button matSuffix mat-icon-button aria-label="Clear">
          <mat-icon>search</mat-icon>
        </button>
      </mat-form-field>
    </form>



    <div class="bisual-table-container">
      <table mat-table [dataSource]="dataSource">

        <ng-container matColumnDef="image">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let department">
            <mat-icon>supervised_user_circle</mat-icon>
          </td>
        </ng-container>

        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>Nombre</th>
          <td mat-cell *matCellDef="let department">{{department.name}}</td>

        </ng-container>

        <ng-container matColumnDef="description">
          <th mat-header-cell *matHeaderCellDef>Descripción</th>
          <td mat-cell *matCellDef="let department">{{department.description}}</td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let department; let i = index;">
            <button mat-icon-button [matMenuTriggerFor]="menu">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              @if (router.url.includes('/rrhh/departments')) {
                <button mat-menu-item [routerLink]="[ department.id ]">
                  <mat-icon matPrefix>login</mat-icon>
                  Ver detalles...
                </button>
              }
              @if (router.url.includes('/rrhh/departments')) {
                <button mat-menu-item (click)="deleteDepartment(i)">
                  <mat-icon matPrefix>delete</mat-icon>
                  Borrar Departamento
                </button>
              }
            </mat-menu>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

      </table>

    </div>
  </div>

</div>
