<ng-container [formGroup]="filterForm">
  <div class="container">
    <div class="row">
      <div class="col-12">
        @for (a of alerts; track a) {
          <div class="alert alert-danger" role="alert">
            Recuerda entrar la factura de {{a.provider.name}}
          </div>
        }
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="page-title">
          <h1>Gastos</h1>

          <button mat-flat-button color="accent" (click)="addProviderOrder()">
            <mat-icon matPrefix>add_user</mat-icon>
            Añadir nueva factura
          </button>

          <mat-form-field appearance="outline">
            <mat-label>Período</mat-label>
            <mat-select formControlName="period">
              @for (period of periods; track period) {
                <mat-option [value]="period">{{ period }}</mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>

  <div class="bisual-table">
    <div class="card paginator">
      <mat-paginator
        [length]="length"
        [pageSize]="pageSize"
        [pageSizeOptions]="pageSizeOptions"
        [pageIndex]="pageIndex"
        (page)="changePage($event)"
        style="margin-right: auto;"
      ></mat-paginator>

      <mat-form-field>
        <input matInput type="text" formControlName="search" placeholder="Número pedido">
        <button matSuffix mat-icon-button aria-label="Clear">
          <mat-icon>search</mat-icon>
        </button>
      </mat-form-field>
    </div>

    <div class="bisual-table-two-columns">

      <div class="card">
        <div class="field">
          <mat-label>Proveedor</mat-label>
          <mat-form-field appearance="outline">
            <input [formControl]="providers_property_control" type="text" placeholder="Escribe un nombre..." matInput [matAutocomplete]="autoProvider">
            <mat-autocomplete #autoProvider="matAutocomplete">
              @for (provider of providers_filtered; track provider) {
                <mat-option [value]="provider.fact_name" (click)="selectProviderFilter(provider)">{{provider.fact_name}}</mat-option>
              }
            </mat-autocomplete>
          </mat-form-field>
        </div>

        <div class="field">
          <mat-label>Categoria</mat-label>
          <mat-form-field appearance="outline">
            <mat-select formControlName="provider_order_category_id">
              <mat-option [value]="''">Sin seleccionar</mat-option>
              @for (cat of provider_order_categories; track cat) {
                <mat-option [value]="cat.id">{{ cat.name }}</mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>

        <div class="field">
          <mat-label>Estado del pago</mat-label>
          <mat-radio-group formControlName="payment_status">
            <mat-radio-button [value]="''">Todos</mat-radio-button>
            <mat-radio-button [value]="'payed'">Pagado</mat-radio-button>
            <mat-radio-button [value]="'not-payed'">No pagado</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>

      <div class="bisual-table-container">
        <table mat-table [dataSource]="dataSource">

          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef>#</th>
            <td mat-cell *matCellDef="let order">
              <a [routerLink]="[order.id]">#{{ order.id }}</a>
            </td>
          </ng-container>

          <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef>Fecha factura</th>
            <td mat-cell *matCellDef="let order">
              {{ order.invoice.emitted_date | date:'shortDate' }}
            </td>
          </ng-container>

          <ng-container matColumnDef="payment_status">
            <th mat-header-cell *matHeaderCellDef>Estado</th>
            <td mat-cell *matCellDef="let order">
              {{ order.payment.payed_at!=null ? 'Pagado' : (order.due_date!=null && order.due_date > now ? 'Expirado' : 'No pagado') }}
            </td>
          </ng-container>

          <ng-container matColumnDef="invoice">
            <th mat-header-cell *matHeaderCellDef>Núm. factura</th>
            <td mat-cell *matCellDef="let order">
              {{ order.invoice!=null ? ('#' + order.invoice.invoice_number) : 'Sin factura' }}
            </td>
          </ng-container>

          <ng-container matColumnDef="provider">
            <th mat-header-cell *matHeaderCellDef>Proveedor</th>
            <td mat-cell *matCellDef="let order">
              <a [routerLink]="['/direction', 'users-management', 'providers', order.provider.id]">
                {{ order.provider.fact_name }}
              </a>
            </td>
          </ng-container>

          <ng-container matColumnDef="type">
            <th mat-header-cell *matHeaderCellDef>Categoria</th>
            <td mat-cell *matCellDef="let order">
              {{ order.category.name }}
            </td>
          </ng-container>

          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let order; let i = index;">
              <button mat-icon-button [matMenuTriggerFor]="menu">
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button mat-menu-item [routerLink]="[order.id]">
                  <mat-icon matPrefix>visibility</mat-icon>
                  Ver pedido
                </button>
                @if (order.payment.payed_at==null) {
                  <button mat-menu-item (click)="markAsPayed(order)">
                    <mat-icon matPrefix>paid</mat-icon>
                    Marcar como pagado
                  </button>
                }
                @if (order.invoice.contasimple_id != null) {
                  <button mat-menu-item (click)="openProviderOrderFilesDialog(order)">
                    <mat-icon matPrefix>description</mat-icon>
                    Ver archivos
                  </button>
                }
                <button mat-menu-item (click)="delete(i)">
                  <mat-icon matPrefix>delete</mat-icon>
                  Eliminar gasto
                </button>
              </mat-menu>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      </div>
    </div>

  </div>

</ng-container>
