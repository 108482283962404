<h1 mat-dialog-title>Miembros de {{data.project.title}}</h1>

<mat-dialog-content class="container">
  <div class="row detail-row members-wrapper">
    <mat-chip-grid #chipList aria-label="Members selection">
      @for (member of members; track member) {
        <mat-chip-row>
          <img class="profile-image" [src]="member.profile_picture_url" />
          {{member.name + ' ' + member.surnames}}
          <button matChipRemove (click)="removeMember(member)">
            <mat-icon>cancel</mat-icon>
          </button>
        </mat-chip-row>
      }
      <input
        placeholder="Nuevo miembro..."
        #usersInput
        [formControl]="membersCntrl"
        [matAutocomplete]="auto"
        [matChipInputFor]="chipList"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        >
    </mat-chip-grid>
    <mat-autocomplete #auto="matAutocomplete">
      @for (w of filteredUsers | async; track w) {
        <mat-option [value]="w" (onSelectionChange)="addNewMember(w)">
          {{w}}
        </mat-option>
      }
    </mat-autocomplete>
  </div>

  <hr>
    <div>
      <ul class="flex flex-row active-members">
        @for (member of projectActiveMembers; track member) {
          <li>
            <div>
              <img class="profile-image" [src]="member.user!.profile_picture_url" />
              {{member.user!.name + ' ' + member.user!.surnames}}
              @if (member.department_category_name) {
                <span class="owner">({{ member.department_category_name }})</span>
              }
            </div>
          </li>
        }
      </ul>
    </div>
    <hr>

      @if (permissions.isDepartment('direction')) {
        <div>
          <ul>
            @for (member of projectUserMembers; track member) {
              <li class="member">
                <div class="flex flex-row mt-3">
                  <img class="profile-image" [src]="member[0].user!.profile_picture_url" />
                  {{member[0].user!.name + ' ' + member[0].user!.surnames}}
                </div>
                @for (m of member; track m) {
                  <div>
                    <div class="user_role">
                      <mat-form-field appearance="outline">
                        <mat-select [(ngModel)]="m.department_category_id" (selectionChange)="updateProjectUserMember(m)">
                          @for (dc of departmentCategory; track dc) {
                            <mat-option [value]="dc.id">
                              {{dc.name}}
                            </mat-option>
                          }
                        </mat-select>
                      </mat-form-field>
                      <mat-form-field appearance="outline">
                        <input matInput [matDatepicker]="picker" placeholder="Fecha de inicio" [(ngModel)]="m.start_date" (dateChange)="updateProjectUserMember(m)">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                      </mat-form-field>
                      <mat-form-field appearance="outline">
                        <input matInput [matDatepicker]="picker2" placeholder="Fecha de fin" [(ngModel)]="m.end_date" (dateChange)="updateProjectUserMember(m)">
                        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                        <mat-datepicker #picker2></mat-datepicker>
                      </mat-form-field>
                      <div class="flex flex-row">
                        @if (m !== member[0]) {
                          <button mat-icon-button (click)="deleteProjectUserMember(m)">
                            <mat-icon>delete</mat-icon>
                          </button>
                        }
                        @if (m === member[member.length - 1]) {
                          <button mat-icon-button (click)="addNewMember(m.user!.name + ' ' + m.user!.surnames)">
                            <mat-icon>add</mat-icon>
                          </button>
                        }
                      </div>
                    </div>
                  </div>
                }
              </li>
            }
          </ul>
        </div>
      }
    </mat-dialog-content>

    <mat-dialog-actions align="end">
      <button mat-flat-button color="accent" (click)="onNoClick()">
        <mat-icon>diversity_1</mat-icon>
        Hecho
      </button>
    </mat-dialog-actions>
