import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ShowOnDirtyErrorStateMatcher } from '@angular/material/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { da, el } from 'date-fns/locale';
import { Project } from 'src/app/models/project.model';
import { ProjectPhase } from 'src/app/models/project_phase.model';
import { ProjectTask } from 'src/app/models/project_task.model';
import { ProjectTaskBoardTimeBudget } from 'src/app/models/project_task_board_time_budget.model';
import { WorkingTime } from 'src/app/models/working_time.model';
import { WorkingTimeCategory } from 'src/app/models/working_time_category.model';
import { HourStringToSecondsPipe } from 'src/app/pipes/hour-string-to-seconds.pipe';
import { SecondsToHourStringPipe } from 'src/app/pipes/seconds-to-hour-string.pipe';
import { ApiService } from 'src/app/services/api.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-add-new-working-time-dialog',
  templateUrl: './add-new-working-time-dialog.component.html',
  styleUrls: ['./add-new-working-time-dialog.component.css']
})
export class AddNewWorkingTimeDialogComponent implements OnInit {

  form:UntypedFormGroup;
  dateControl:UntypedFormControl = new UntypedFormControl(new Date(), Validators.required);
  timeControl:UntypedFormControl = new UntypedFormControl('', Validators.compose([Validators.required, Validators.pattern(/^(?:(?<hours>\d+)[h]\s*)?(?:(?<minutes>\d+)[m]\s*)?$/)]));
  startTimeControl:UntypedFormControl = new UntypedFormControl('', Validators.compose([Validators.required, Validators.pattern(/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/)]));
  projectControl:UntypedFormControl = new UntypedFormControl('');
  projectPhaseControl:UntypedFormControl = new UntypedFormControl('');

  working_time_categories:WorkingTimeCategory[] = [];
  all_working_time_categories:WorkingTimeCategory[] = [];
  project_categories:WorkingTimeCategory[] = [];
  task:ProjectTask|undefined;

  filtered_projects:Project[] = [];
  filtered_project_phases:ProjectPhase[] = [];

  selected_working_time_categories:any[] = [];
  available_working_time_categories:WorkingTimeCategory[][] = [];
  is_project_category:boolean = false;
  is_project_phase_category:boolean = false;

  maxDate:Date = new Date();

  constructor(public dialogRef: MatDialogRef<AddNewWorkingTimeDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data:{task:ProjectTask, project_task_board_time_budget:ProjectTaskBoardTimeBudget, workingTime:WorkingTime|undefined, is_editing:boolean|undefined},
              private user:UserService,
              private fb:UntypedFormBuilder,
              private api:ApiService) {
    // if data has task, it means that we are adding a working time to a task
    if(data.task!=null && data.project_task_board_time_budget != null) {
      this.task = data.task;
      let duration_in_seconds_real = data.workingTime ? data.workingTime.duration_in_seconds_real : 0;
      this.form = this.fb.group({
        user_id: ['', Validators.required],
        working_time_category_id: ['', Validators.required],
        started_at: [new Date(), Validators.compose([Validators.required])],
        duration_in_seconds_real: [duration_in_seconds_real, Validators.compose([Validators.required, Validators.min(1)])],
        description: [data.workingTime ? data.workingTime.description : '', Validators.compose([Validators.required, Validators.minLength(4)])],
        relatable_id: [data.project_task_board_time_budget.id, Validators.required],
        relatable_type: ['App\\Models\\ProjectTaskBoardTimeBudget', Validators.required],
        project_id: [''],
        project_phase_id: [''],
        valid: [false, Validators.requiredTrue]
      });

      if(data.is_editing) {
        duration_in_seconds_real = 0;
        this.timeControl.setValue(new SecondsToHourStringPipe().transform(duration_in_seconds_real));
        this.startTimeControl.setValue(("00" + new Date(new Date().setSeconds(new Date().getSeconds() - duration_in_seconds_real)).getHours()).slice(-2) + ":" + ("00" + new Date(new Date().setSeconds(new Date().getSeconds() - duration_in_seconds_real)).getMinutes()).slice(-2));
      } else if (duration_in_seconds_real != 0) {
        this.timeControl.setValue(new SecondsToHourStringPipe().transform(duration_in_seconds_real));
        this.startTimeControl.setValue(("00" + new Date(new Date().setSeconds(new Date().getSeconds() - duration_in_seconds_real)).getHours()).slice(-2) + ":" + ("00" + new Date(new Date().setSeconds(new Date().getSeconds() - duration_in_seconds_real)).getMinutes()).slice(-2));
      }
    } else {
      this.form = this.fb.group({
        user_id: ['', Validators.required],
        working_time_category_id: ['', Validators.required],
        started_at: [new Date(), Validators.compose([Validators.required])],
        duration_in_seconds_real: [0, Validators.compose([Validators.required, Validators.min(1)])],
        description: ['', Validators.compose([Validators.required, Validators.minLength(4)])],
        project_id: [''],
        project_phase_id: [''],
        relatable_id: [''],
        relatable_type: [''],
        valid: [false, Validators.requiredTrue]
      });
    }


  }

  ngOnInit(): void {
    this.initUser();
    this.fetchWorkingTimeCategories();
    this.listenDateTimeControl();
    this.listenProjectControl();
    this.listenCategoryControl();
    this.checkFormValid();
    this.listenStartTimeControl();
    if(this.timeControl.value=='') this.fetchSuggestedTime();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  selectProject(p:Project) {
    this.form.patchValue({ project_id: p.id, relatable_id: p.id, relatable_type: 'App\\Models\\Project' });
    this.fetchWorkingTimeCategoryChildren(this.selected_working_time_categories[this.selected_working_time_categories.length - 1].id.toString());
    this.fetchProjectCategories(this.selected_working_time_categories[this.selected_working_time_categories.length - 1].id.toString());
  }

  selectProjectPhase(p:ProjectPhase) {
    this.form.patchValue({ project_phase_id: p.id, relatable_id: p.id, relatable_type: 'App\\Models\\ProjectPhase' });
  }

  resetProject() {
    this.form.patchValue({ project_id: '' });
    this.projectControl.setValue('');
    this.available_working_time_categories = [];
    this.selected_working_time_categories = [this.selected_working_time_categories[0]];
    this.is_project_phase_category = false;
  }

  resetProjectPhase() {
    this.form.patchValue({ project_phase_id: '', relatable_type: 'App\\Models\\Project', relatable_id: this.form.value['project_id'] });
    this.projectPhaseControl.setValue('');
  }

  displayFnProject(p:Project): string {
    return p ? p.title : '';
  }

  displayFnProjectPhase(p:ProjectPhase): string {
    return p ? p.title : '';
  }

  save() {
    this.form.patchValue({ working_time_category_id: this.selected_working_time_categories[this.selected_working_time_categories.length - 1].id });
    this.dialogRef.close(this.form.value);
  }

  onCategoryChange(event:any, level:number) {
    if(event!=null && event.value!=null) {
        // if level number is less than this.available_working_time_categories length, it means that we are selecting a parent and we have to remove the children in
        // this.available_working_time_categories and the selected categories in this.selected_working_time_categories
        if(level<this.available_working_time_categories.length - 1) {
          this.available_working_time_categories.splice(level + 1);
          this.selected_working_time_categories.splice(level + 1);
        }

        let category = this.getWorkingTimeCategoryById(event.value.toString());
        if (category != null) {
          if (category.parent_category_slug == null) {
            this.is_project_category = category.slug == 'project';
          } else if (level == 1) {
            this.is_project_phase_category = category.needs_relation_when_registering_time == 'project_phase';
            if (!this.is_project_phase_category) {
              this.form.patchValue({ relatable_type: 'App\\Models\\Project', relatable_id: this.form.value['project_id'] });
            }
          }
          this.is_project_phase_category = category.needs_relation_when_registering_time == 'project_phase';
        }

        this.selected_working_time_categories.push(category);

        if(category.slug != 'project') this.fetchWorkingTimeCategoryChildren(event.value);
    }
  }

  checkCategoryAssignability() {
    if(this.selected_working_time_categories.length==0 || this.available_working_time_categories.length > this.selected_working_time_categories.length) return false;
    const category_id = this.selected_working_time_categories[this.selected_working_time_categories.length - 1].id;
    if(category_id==null) return false;
    if(this.task!=null) return true;
    const category = this.getWorkingTimeCategoryById(category_id.toString());
    return category ? category.is_assignable : false;
  }

  private fetchSuggestedTime() {
    const user = this.user.getCurrentUser();
    if(user.role.slug==="worker") {
      this.api.getTimeWithoutRegister(this.form.value['user_id']).subscribe(
        data => {
          if(data!=null) {
            this.timeControl.patchValue(new SecondsToHourStringPipe().transform(data));
          }
        }
      );
    }
  }

  private initUser() {
    this.user.currentUser.subscribe(
      data => {
        if(data!=null && this.form.value['user_id']=='') {
          this.form.patchValue({ user_id: data.id });
        }
      }
    );
  }

  private fetchWorkingTimeCategories(project_id:string = '') {
    let params:any = {};
    if(project_id!='') params.project_id = project_id;
    if(this.task!=null) {
      this.api.getTaskWorkingTimeCategories().subscribe(
        data => {
          this.working_time_categories = data;
          if(this.data.project_task_board_time_budget!=null) {

            let working_time_category_id:number|null = this.data.project_task_board_time_budget.budgetable_id;
            if(this.data.project_task_board_time_budget.budgetable_type === "App\\Models\\ProjectTeam") {
              working_time_category_id = this.working_time_categories.find(wtc => wtc.slug === "task-time-tracking")!.id;
            }

            this.form.patchValue({ working_time_category_id: working_time_category_id });
            this.onCategoryChange({ value: working_time_category_id }, -1);
          }
        }
      );
    } else {
      this.api.getUserAssignableWorkingTimeCategories(this.form.value['user_id'], params).subscribe(
        data => {
          this.working_time_categories = data.filter(c => c.parent_category_slug==null);
        }
      );
    }
    this.api.getAllWorkingTimeCategories(this.form.value['user_id'], params).subscribe(
      data => {
        this.all_working_time_categories = data;
      }
    );
  }

  private fetchProjectCategories(id:string) {
    this.api.getWorkingTimeCategoryChildren(id, { project_id: this.form.value['project_id'] }).subscribe(
      data => {
        this.available_working_time_categories[this.available_working_time_categories.length - 1] = data;
      }
    );
  }

  private fetchWorkingTimeCategoryChildren(id:string) {
    // every time a category is selected, we will get the children
    this.api.getWorkingTimeCategoryChildren(id, { project_id: this.form.value['project_id'] }).subscribe(
      data => {
        if(data.length!==0) {
          this.available_working_time_categories.push(data);
        }
      }
    );
  }

  private listenDateTimeControl() {
    this.timeControl.valueChanges.subscribe(
      (time:string) => {
        let duration_in_seconds_real = 0;

        if(this.timeControl.valid) {
          duration_in_seconds_real = this.getHoursMinutesTime(time);
        }

        const new_date = new Date(new Date(this.dateControl.value).setSeconds(new Date(this.dateControl.value).getSeconds() - duration_in_seconds_real));

        this.form.patchValue({
          duration_in_seconds_real: duration_in_seconds_real,
          // started_at: date - aixo ja es fa automaticament al fer set value de StartTime
        });
        this.startTimeControl.setValue(duration_in_seconds_real==0 ? '' : ("00" + new_date.getHours()).slice(-2) + ":" + ("00" + new_date .getMinutes()).slice(-2));
      }
    );

    this.dateControl.valueChanges.subscribe(
      (data:Date) => {
        if(this.dateControl.valid && this.timeControl.valid) {
          const res_hours_minutes = this.startTimeControl.value.split(":");
          let hours = res_hours_minutes[0];
          let minutes = res_hours_minutes[1];
          let new_date:Date = new Date(data);
          new_date.setHours(hours);
          new_date.setMinutes(minutes);

          this.form.patchValue({
            started_at: new_date
          })
        }
      }
    );
  }

  private getWorkingTimeCategoryById(id:string) {
    let cat:WorkingTimeCategory = null as any;

    for(let c of this.working_time_categories) {
      if(c.id.toString()==id) {
        cat = c;
        break;
      }
    }

    if(cat==null) {
      for(let c of this.project_categories) {
        if(c.id.toString()==id) {
          cat = c;
          break;
        }
      }
    }

    if(cat==null) {
      for(let c of this.all_working_time_categories) {
        if(c.id.toString()==id) {
          cat = c;
          break;
        }
      }
    }

    return cat;
  }

  private listenProjectControl() {
    this.projectControl.valueChanges.subscribe(
      data => {
        this.api.getProjects({search:data}).subscribe(
          data => {
            this.filtered_projects = data.data;
          }
        );
      }
    );

    this.projectPhaseControl.valueChanges.subscribe(
      data => {
        this.api.getProjectPhases({ search: data, project_id: this.form.value['project_id'], is_active: true }).subscribe(
          data => {
            this.filtered_project_phases = data.data;
          }
        );
      }
    );
  }

  private getProjectUserMember() {
    this.api.getProjectUserMember(this.form.value['project_id'], this.form.value['user_id']).subscribe(
      data => {
        // get department_category_id

        // manage the subcategories depending on the department_category

      }
    );
  }

  private listenCategoryControl() {
    this.form.controls['working_time_category_id'].valueChanges.subscribe(
      data => {
        if(data!=null && data!='') {
          if(!this.is_project_category) this.form.patchValue({ project_id: '' });
         }
        else this.form.patchValue({ project_id: '' });
      }
    );
  }

  private checkFormValid() {
    this.form.valueChanges.subscribe(
      data => {
        let valid:boolean = true;

        if(this.is_project_phase_category && (data.project_phase_id=='')) valid = false;
        if(!this.is_project_phase_category && this.is_project_category && (data.project_id=='')) valid = false;

        this.form.patchValue({ valid: valid }, { emitEvent: false });
      }
    );
  }

  private listenStartTimeControl() {
    this.startTimeControl.valueChanges.subscribe(
      data => {
        if(this.startTimeControl.valid) {
          const splitted:number[] = data.split(":");
          let current_date:Date = new Date(this.dateControl.value);
          current_date.setHours(splitted[0]);
          current_date.setMinutes(splitted[1])

          this.form.patchValue({
            started_at: current_date
          });
        }
        else {
          this.form.patchValue({
            started_at: ''
          });
        }
      }
    );
  }

  private getHoursMinutesTime(time:string) {
    return new HourStringToSecondsPipe().transform(time);
  }

}
