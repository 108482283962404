import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-date-picker-dialog',
  templateUrl: './date-picker-dialog.component.html',
  styleUrl: './date-picker-dialog.component.css'
})
export class DatePickerDialogComponent implements OnInit {

  selectedDate: Date = new Date();

  constructor(public dialogRef: MatDialogRef<DatePickerDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data:DatePickerDialogData) {}

  ngOnInit(): void {
  }

  submit() {
    this.dialogRef.close(this.selectedDate);
  }

  close() {
    this.dialogRef.close();
  }

  filter = (d: Date | null): boolean => {
    if (this.data.data.filter === null) {
      return true;
    }
    const day = (d || new Date()).getDay();
    switch (this.data.data.filter) {
      case "monday":
        return day === 1;
      case "tuesday":
        return day === 2;
      case "wednesday":
        return day === 3;
      case "thursday":
        return day === 4;
      case "friday":
        return day === 5;
      case "saturday":
        return day === 6;
      case "sunday":
        return day === 0;
      default:
        return false;
    }
  };
}

export interface DatePickerDialogData {
  dialog_title: string;
  dialog_ok_button: string;
  dialog_cancel_button: string;
  data:DatePickerDialogDataRow;
}

export interface DatePickerDialogDataRow {
  filter: "monday" | "tuesday" | "wednesday" | "thursday" | "friday" | "saturday" | "sunday" | null;
}
