import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { ApiService } from 'src/app/services/api.service';
import { UtilsService } from 'src/app/services/utils.service';
import { CalendarAssignmentDialogComponent } from './calendar-assignment-dialog/calendar-assignment-dialog.component';
import { PermissionsService } from 'src/app/services/permissions.service';
import { User } from 'src/app/models/user.model';
import { UserService } from 'src/app/services/user.service';
import { DateFromToDialogComponent } from 'src/app/components/utils/date-from-to-dialog/date-from-to-dialog.component';

@Component({
  selector: 'app-project-assignment',
  templateUrl: './project-assignment.component.html',
  styleUrls: ['./project-assignment.component.css']
})
export class ProjectAssignmentComponent implements OnInit {

  filterForm:FormGroup = null as any;
  private formPersistence:any;
  me : User = null as any;

  constructor(private fb:FormBuilder,
              private activatedRoute:ActivatedRoute,
              private utils:UtilsService,
              private router:Router,
              private api:ApiService,
              private userService: UserService,
              private dialog:MatDialog,
              public permissions:PermissionsService) {
                this.filterForm = this.fb.group({
                  from:[(this.activatedRoute.snapshot.queryParamMap.get('from')!=null ? this.utils.stringToDate(this.activatedRoute.snapshot.queryParamMap.get('from') as string) : this.utils.getDefaultInitialDate()), Validators.required],
                  to:[(this.activatedRoute.snapshot.queryParamMap.get('to')!=null ? this.utils.stringToDate(this.activatedRoute.snapshot.queryParamMap.get('to') as string) : this.utils.getDefaultEndDate()), Validators.required],
                });
              }

  ngOnInit(): void {
    this.initFilterFormListener();
    this.listenQueryParameters();
    this.me = this.userService.getCurrentUser();
  }

  changeWeek(num:number) {
    const a_week_ms = 7 * 24 * 60 * 60 * 1000 * num; // Una semana en milisegundos
    const new_from:Date = new Date(new Date(this.filterForm.value['from']).getTime() + a_week_ms);
    const new_to:Date = new Date(new Date(this.filterForm.value['to']).getTime() + a_week_ms);

    this.filterForm.patchValue({
      from: new_from,
      to: new_to
    });
  }

  private initFilterFormListener() {
    this.filterForm.valueChanges.subscribe(
      data => {
        if(this.formPersistence==null || JSON.stringify(this.formPersistence)!=JSON.stringify(data)) {
          const params = this.utils.cloneObj(data);
          if(params.from!=null && params.from!="") params.from = this.utils.dateToString(new Date(params.from));
          if(params.to!=null && params.to!="") params.to = this.utils.dateToString(new Date(params.to));
          this.router.navigate(['/employees', 'development', 'assignment'], { queryParams: params });
        }
      }
    );
  }

  private listenQueryParameters() {
    this.activatedRoute.queryParams.subscribe(
      params => {
        if(JSON.stringify(params)!==JSON.stringify(this.filterForm.value)) { //si no ve de filtre s'ha de setejar el form
          let params_temp = this.utils.cloneObj(params); //params es read_only
          Object.keys(params_temp).forEach(param_key => {
            if(params_temp[param_key]!=null && params_temp[param_key]!="" && !isNaN(+params_temp[param_key])) params_temp[param_key] = +params_temp[param_key]; // si es numero, el transformem
            else if(params_temp[param_key]!=null && (params_temp[param_key] as string).indexOf("-")>-1) params_temp[param_key] = this.utils.stringToDate(params_temp[param_key]);
          });
          this.filterForm.patchValue(params_temp, { emitEvent: false });
        }
        this.formPersistence = params;
        // this.fetchAssignment();
      }
    );
  }
}
