<ng-container [formGroup]="filterForm">
  <div class="container">
    <div class="row">
      <div class="col-12">
        @if (project) {
          <div class="page-title">
            <h1>Resumen de {{project!.title}}</h1>
            <button mat-icon-button [matMenuTriggerFor]="menu">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              @if (project.wiki_node_id) {
                <button mat-menu-item [routerLink]="['/employees','wiki', project.wiki_node_id]">
                  <mat-icon>import_contacts</mat-icon>
                  Documentación
                </button>
              }
              <button mat-menu-item (click)="openManageMembersDialog()">
                <mat-icon>diversity_3</mat-icon>
                Gestionar miembros
              </button>
              <button mat-menu-item (click)="openProjectReport()">
                <mat-icon>summarize</mat-icon>
                Informe del proyecto
              </button>
              <button mat-menu-item (click)="openTimeDistribution()">
                <mat-icon>date_range</mat-icon>
                Distribuir tiempo
              </button>
            </mat-menu>
          </div>
        }
        <div class="filters">
          <div class="row d-flex justify-content-end">
          <mat-form-field appearance="outline" style="width: 150px;">
            <mat-label>Período</mat-label>
            <mat-select formControlName="period">
              @for (period of periods; track period) {
                <mat-option [value]="period">{{ period }}</mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
    @if (dailyChartData != null) {
      <div class="container">
        <div class="row">
          <div class="col-12 col-md-4">
            <div class="card">
              <h2>Horas realizadas</h2>
              <p class="subtitle">Durante {{ filterForm.value['period'] }}</p>
              <p class="price">{{ totalWorked | secondsToStringTime }}</p>
            </div>
          </div>
          <div class="col-12 col-md-4">
            <div class="card">
              <h2>Horas previstas</h2>
              <p class="subtitle">Según planificado</p>
              <p class="price">{{ forecastedWorking | secondsToStringTime }}</p>
            </div>
          </div>
          <div class="col-12 col-md-4">
            <div class="card">
              <h2>Diferencia total</h2>
              <p class="subtitle">Total de horas pendientes</p>
              <p class="price">{{ pendingWorkingTime | number:'1.0-2' }}</p>
            </div>
          </div>
        </div>
      </div>
    }
    @if (dailyChartData != null) {
      <div class="dashboard">
        <div class="row">
          <div class="col-12 col-lg-6">
            <div class="card" [style.height]="(mainChartView[1]+40) + 'px'" #chartContainer>
              <!-- <ngx-charts-line-chart [view]="mainChartView" [results]="dailyChartData" [gradient]="false"
              [scheme]="colorScheme" [xAxis]="true" [yAxis]="true" [xAxisLabel]="xDayAxisLabel"
              [yAxisLabel]="yAxisLabel" [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="showYAxisLabel"
              [legend]="false" [animations]="true">
            </ngx-charts-line-chart> -->
          </div>
        </div>
        <div class="col-12 col-lg-6">
          <div class="card" [style.height]="(mainChartView[1]+40) + 'px'">
            <!-- <ngx-charts-line-chart [view]="mainChartView" [results]="weeklyChartData" [gradient]="false"
            [scheme]="colorScheme" [xAxis]="true" [yAxis]="true" [xAxisLabel]="xWeekAxisLabel"
            [yAxisLabel]="yAxisLabel" [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="showYAxisLabel"
            [legend]="false" [animations]="true">
          </ngx-charts-line-chart> -->
        </div>
      </div>
    </div>
  </div>
}
</div>
</div>
</ng-container>
