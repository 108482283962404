import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { CredentialDetailDialogComponent } from 'src/app/components/utils/credential-detail-dialog/credential-detail-dialog.component';
import { Credential } from 'src/app/models/credential.model';
import { Hosting } from 'src/app/models/hosting.model';
import { ApiService } from 'src/app/services/api.service';
import { PermissionsService } from 'src/app/services/permissions.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-hosting-detail-credentials',
  templateUrl: './hosting-detail-credentials.component.html',
  styleUrls: ['./hosting-detail-credentials.component.css']
})
export class HostingDetailCredentialsComponent implements OnInit {

  private _hosting:Hosting = null as any;
  get hosting() { return this._hosting; };
  @Input() set hosting(h:Hosting) {
    if(h!=null) {
      this._hosting = h;
      this.initDataSource();
    }
  };

  dataSource:MatTableDataSource<Credential> = new MatTableDataSource();
  displayedColumns:string[] = ["protocol", "username", "password", "type", "host", "port", "actions"];

  constructor(private snack:MatSnackBar,
              private utils:UtilsService,
              private api:ApiService,
              public dialog: MatDialog,
              public permissions:PermissionsService) { }

  ngOnInit(): void {
    
  }

  copyPassword(element:Credential) {
    navigator.clipboard.writeText(element.password).then(() => {
      this.snack.open("Contraseña copiada.", "Ok", {duration: 3000});
    });
  }

  downloadPublicKey(element:Credential) {
    this.utils.downloadTextFile(element.ssh_public_key, "id_rsa_" + element.id);
  }

  downloadPrivateKey(element:Credential) {
    this.utils.downloadTextFile(element.ssh_public_key, "id_" + element.id);
  }

  delete(index:number) {
    if(confirm("¿Estás seguro que quieres eliminar esta credencial?")) {
      this.api.deleteCredential(this.dataSource.data[index].id).subscribe(
        data => {
          let source = this.dataSource.data;
          source.splice(index, 1);
          this.dataSource.data = source;
        }
      );
    }
  }

  openDetail(index:number = null as any) {
    const dialogRef = this.dialog.open(CredentialDetailDialogComponent, {
      width: '700px',
      data: { 
        credential: index==null ? null : this.dataSource.data[index],
        hosting_id: this.hosting.id,
        company_id: this.hosting.company_id,
        host: this.hosting.ip
      }
    });

    dialogRef.afterClosed().subscribe((data:Credential) => {
      if(data != null && index==null) {
        let source = this.dataSource.data;
        source.push(data);
        this.dataSource.data = source;
      }
    });
  }

  private initDataSource() {
    this.dataSource.data = this.hosting.credentials;
  }

}
