import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-absences-management',
  templateUrl: './absences-management.component.html',
  styleUrls: ['./absences-management.component.css']
})
export class AbsencesManagementComponent implements OnInit {

  navLinks: any[] = [
    {
      label: 'Vacaciones',
      link: './vacations',
      index: 0
    },
    {
      label: 'Peticiones de vacaciones',
      link: './vacation-requests',
      index: 1
    },
    {
      label: 'Bajas médicas',
      link: './medical-sick-leaves',
      index: 2
    },
  ];

  queryParams:any = {};

  constructor(private activatedRoute:ActivatedRoute) { }

  ngOnInit(): void {
    this.listenQueryParams();
  }

  private listenQueryParams() {
    this.activatedRoute.queryParamMap.subscribe((data:any) => {
      const params = data.params;
      this.queryParams = params;
    });
  }

}
