import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { WikiTree } from 'src/app/models/wiki-tree.model';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-wiki',
  templateUrl: './wiki.component.html',
  styleUrls: ['./wiki.component.css']
})
export class WikiComponent implements OnInit {
  wikiTree:WikiTree[] = [];

  constructor(
    private api:ApiService,
    private router:Router
    ) { }

  ngOnInit(): void {
    this.fetchWikiTree();
  }

  private fetchWikiTree(){
    this.api.getWikiTree().subscribe(data => {
      this.wikiTree = data;
    })
  }
}
