<div class="page-title">
  <h1>Editar cliente</h1>
  <button mat-flat-button [routerLink]="['/employees','direction', 'users-management', 'companies']" type="button">
    <mat-icon matPrefix>arrow_back</mat-icon>
    Volver
  </button>
  <!-- <button mat-flat-button color="warn" (click)="delete()" type="button" [disabled]="!permissions.validateDepartment(['direction', 'finance'])">
  <mat-icon matPrefix>delete</mat-icon>
  Eliminar
</button> -->
<button mat-flat-button color="accent" [disabled]="!companyForm.valid" (click)="save()" type="submit" [disabled]="!permissions.validateDepartment(['direction', 'finance'])">
  <mat-icon matPrefix>save</mat-icon>
  Guardar
</button>
</div>

<form class="card" [formGroup]="companyForm">
  <mat-tab-group mat-align-tabs="start" animationDuration="0ms">
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon style="margin-right: 5px">person</mat-icon>
        Datos generales
      </ng-template>

      <div class="container" style="margin-top: 20px">
        <div class="row">
          <div class="col-12 col-lg-3">
            <mat-form-field appearance="outline">
              <mat-label>Correo electrónico</mat-label>
              <input type="email" matInput formControlName="email">
            </mat-form-field>
          </div>
          <div class="col-12 col-lg-3">
            <mat-form-field appearance="outline">
              <mat-label>Teléfono móvil</mat-label>
              <input type="tel" matInput placeholder="+34.666 555 444" formControlName="phone">
              <mat-icon matSuffix>phone</mat-icon>
              <mat-hint>F: +XX.XXX XXX XXX</mat-hint>
            </mat-form-field>
          </div>
        </div>
      </div>
    </mat-tab>

    <mat-tab label="Datos de facturación">
      <ng-template mat-tab-label>
        <mat-icon style="margin-right: 5px">account_balance</mat-icon>
        Datos de facturación
      </ng-template>

      <div class="container" style="margin-top: 20px">
        <div class="row">
          <div class="col-12 col-lg-3">
            <mat-form-field appearance="outline">
              <mat-label>Nombre de facturación</mat-label>
              <input type="text" matInput formControlName="fact_name">
            </mat-form-field>
          </div>
          <div class="col-12 col-lg-3">
            <mat-form-field appearance="outline">
              <mat-label>NIF/CIF</mat-label>
              <input type="text" matInput formControlName="vat">
            </mat-form-field>
          </div>
          <div class="col-12 col-lg-3">
            <mat-form-field appearance="outline">
              <mat-label>Dirección de facturación</mat-label>
              <input type="text" matInput formControlName="fact_address">
            </mat-form-field>
          </div>
          <div class="col-12 col-lg-3">
            <mat-form-field appearance="outline">
              <mat-label>Población/Ciudad</mat-label>
              <input type="text" matInput formControlName="fact_city">
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-lg-3">
            <mat-form-field appearance="outline">
              <mat-label>Código postal</mat-label>
              <input type="text" matInput formControlName="fact_zipcode">
            </mat-form-field>
          </div>
          <div class="col-12 col-lg-3">
            <mat-form-field appearance="outline">
              <mat-label>Província</mat-label>
              <input type="text" matInput formControlName="fact_county">
            </mat-form-field>
          </div>
          <div class="col-12 col-lg-3">
            <mat-form-field appearance="outline">
              <mat-label>País</mat-label>
              <mat-select formControlName="fact_country">
                @for (c of countries; track c) {
                  <mat-option [value]="c.name">{{ c.name }}</mat-option>
                }
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>

    </mat-tab>

    <mat-tab label="Datos de pago">
      <ng-template mat-tab-label>
        <mat-icon style="margin-right: 5px">credit_card</mat-icon>
        Datos de pago
      </ng-template>

      <iframe
        id="payment-iframe"
                [src]="(payment_method_url + '/stripe/' + company_id + '/payment-method?token=' + token) | safeUrl"
            ></iframe>
        </mat-tab>

        <mat-tab label="usuarios">
            <ng-template mat-tab-label>
                <mat-icon style="margin-right: 5px">group</mat-icon>
                Usuarios
            </ng-template>

            <div class="container">
              <div class="row">
                <div class="col-12">
                  <table mat-table [dataSource]="usersDataSource">
                    <ng-container matColumnDef="name">
                      <th mat-header-cell *matHeaderCellDef>Nombre</th>
                      <td mat-cell *matCellDef="let user">
                        {{ user.name }}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="email">
                      <th mat-header-cell *matHeaderCellDef>Email</th>
                      <td mat-cell *matCellDef="let user">
                        {{ user.email }}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="role">
                      <th mat-header-cell *matHeaderCellDef>Rol</th>
                      <td mat-cell *matCellDef="let user">
                        {{ user.role.name }}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="actions">
                      <th mat-header-cell *matHeaderCellDef>
                        <button mat-flat-button (click)="addUser()">
                          <mat-icon>person_add</mat-icon>
                          Añadir usuario
                        </button>
                      </th>
                      <td mat-cell *matCellDef="let user">
                        <button mat-icon-button [matMenuTriggerFor]="menu">
                          <mat-icon>more_vert</mat-icon>
                        </button>
                        <mat-menu #menu="matMenu">
                          <button mat-menu-item [routerLink]="['..', '..', 'users', user.id]">
                            <mat-icon>person</mat-icon>
                            <span>Ver usuario</span>
                          </button>
                        </mat-menu>
                      </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="usersDisplayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: usersDisplayedColumns;"></tr>
                  </table>
                </div>
              </div>
            </div>
        </mat-tab>

        <mat-tab label="canales-de-contacto">
            <ng-template mat-tab-label>
                <mat-icon style="margin-right: 5px">outgoing_mail</mat-icon>
                Canales de comunicación
            </ng-template>

            <div class="container">
              <div class="row">
                <div class="col-12">
                  <table mat-table [dataSource]="cncDataSource">
                    <ng-container  matColumnDef="type">
                      <th mat-header-cell *matHeaderCellDef>Tipo</th>
                      <td mat-cell *matCellDef="let cnc">
                        {{ cnc.type | titlecase }}
                      </td>
                    </ng-container>

                    <ng-container  matColumnDef="route">
                      <th mat-header-cell *matHeaderCellDef>Ruta</th>
                      <td mat-cell *matCellDef="let cnc">
                        {{ cnc.type==='email' ? cnc.email : (cnc.type==='slack' ? cnc.slack_channel_name : (cnc.type==='teams' ? cnc.teams_channel_name : 'Tipo no especificado')) }}
                </td>
              </ng-container>

              <ng-container  matColumnDef="created_at">
                <th mat-header-cell *matHeaderCellDef>Fecha de creación</th>
                <td mat-cell *matCellDef="let cnc">
                  {{ cnc.created_at | date:'shortDate' }}
                </td>
              </ng-container>

              <ng-container  matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef>
                  <button mat-flat-button (click)="addCnc()">
                    <mat-icon>add</mat-icon>
                    Añadir canal de comunicación
                  </button>
                </th>
                <td mat-cell *matCellDef="let cnc">
                  <button mat-icon-button [matMenuTriggerFor]="menu">
                    <mat-icon>more_vert</mat-icon>
                  </button>
                  <mat-menu #menu="matMenu">
                    <button mat-menu-item (click)="editCnc(cnc)">
                      <mat-icon>edit</mat-icon>
                      <span>Editar canal</span>
                    </button>
                    <button mat-menu-item (click)="deleteCnc(cnc)">
                      <mat-icon>delete</mat-icon>
                      <span>Borrar canal</span>
                    </button>
                  </mat-menu>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="cncDisplayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: cncDisplayedColumns;"></tr>
            </table>
          </div>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</form>
