<div id="content" class="extensible">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="page-title">
          <h1>Planificación de plantilla</h1>

          @if (filterForm.value['year']!=null) {
            <div class="date-controller card">
              <button mat-icon-button (click)="changeYear(-1)" [disabled]="dataSource.data.length==0"><mat-icon>chevron_left</mat-icon></button>
              <span class="date">Año {{ filterForm.value['year'] }}</span>
              <button mat-icon-button (click)="changeYear(1)" [disabled]="dataSource.data.length==0"><mat-icon>chevron_right</mat-icon></button>
            </div>
          }

          @if (filterForm.value['year']!=null) {
            <div class="month-selector card">
              <mat-form-field appearance="outline">
                <mat-select [formControl]="monthControl" multiple [disabled]="dataSource.data.length==0">
                  @for (month of months; track month) {
                    <mat-option [value]="month.key">
                      {{ month.value }}
                    </mat-option>
                  }
                </mat-select>
              </mat-form-field>
            </div>
          }

          <button mat-flat-button color="accent" [disabled]="!changesAvailableToSave" (click)="save()">Guardar cambios</button>
        </div>
      </div>
    </div>
  </div>

  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="bisual-table-container">
          @if (dataSource.data.length > 0) {
            <table mat-table [dataSource]="dataSource">
              <ng-container matColumnDef="user">
                <th mat-header-cell *matHeaderCellDef></th>
                <ng-container  *matCellDef="let row;">
                  @if (isFirstUserRow(row)) {
                    <td mat-cell [attr.rowspan]="showUsers[row.user_id] ? getCategoriesByUser(row).length : 1" [ngStyle]="{'width': '300px', 'min-width': '300px', 'max-width': '300px'}">
                      <button mat-icon-button [matMenuTriggerFor]="menu"  style="margin-right: 5px;" color="grey">
                        <mat-icon>more_vert</mat-icon>
                      </button>
                      <mat-menu #menu="matMenu" xPosition="before">
                        <button mat-menu-item [routerLink]="['/employees', 'rrhh', 'working-contracts']" [queryParams]="{'user-name': row.name}">
                          <mat-icon>gavel</mat-icon>
                          <span>Ver contratos</span>
                        </button>
                      </mat-menu>
                      <button mat-icon-button [color]="showUsers[row.user_id] ? 'accent' : 'grey'" (click)="showUsers[row.user_id] = !showUsers[row.user_id]" style="margin-right: 5px;">
                        <mat-icon [ngStyle]="{'font-size': '20px'}">{{ showUsers[row.user_id] ? 'visibility' : 'visibility_off' }}</mat-icon>
                      </button>
                      <b>{{ row.name + ' ' + row.surnames }}</b>
                    </td>
                  }
                </ng-container>
                <td mat-footer-cell *matFooterCellDef #cell>
                  @if (isFooterRow('horas-proyectos-occupied-row', cell)) {
                    <b>Horas ocupadas</b>
                    <mat-icon class="table-info-icon" [matTooltip]="'Horas asignadas a proyectos con contrato'">info</mat-icon>
                  }
                  @if (isFooterRow('horas-proyectos-budgetable-row', cell)) {
                    <b>Horas de proyectos presupuestables</b>
                    <mat-icon class="table-info-icon" [matTooltip]="'Horas totales - horas no productivas * % de producción de cada empleado'">info</mat-icon>
                  }
                  @if (isFooterRow('horas-proyectos-row', cell)) {
                    <b>Horas de proyectos</b>
                    <mat-icon class="table-info-icon" [matTooltip]="'Horas totales - horas no productivas'">info</mat-icon>
                  }
                  @if (isFooterRow('horas-proyectos-total-row', cell)) {
                    <b>Horas totales</b>
                    <mat-icon class="table-info-icon" [matTooltip]="'Horas totales de plantilla disponibles, excluyendo festivos y vacaciones'">info</mat-icon>
                  }
                  @if (isFooterRow('coste-plantilla-row', cell)) {
                    <b>Coste de plantilla</b>
                  }
                  @if (isFooterRow('cost-plantilla-vacaciones-row', cell)) {
                    <b>Coste de plantilla con vacaciones</b>
                  }
                </td>
              </ng-container>
              <ng-container matColumnDef="category">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let row; let i = index;" [ngStyle]="showUsers[row.user_id] ? {'width': '300px', 'min-width': '300px', 'max-width': '300px'} : {'width': '200px', 'min-width': '200px', 'max-width': '200px'}">
                  <i>{{ row.employee_month_working_capacity_field | employeeMonthWorkingCapacityField }}</i>
                </td>
                <td mat-footer-cell *matFooterCellDef></td>
              </ng-container>
              @for (month of months; track month; let i = $index) {
                <ng-container [matColumnDef]="month.key">
                  <th mat-header-cell *matHeaderCellDef [ngStyle]="{'width': '90px', 'min-width': '90px' }">
                    {{ month.value }}
                    @if (isMonthPosterior(i)) {
                      <button mat-icon-button [matMenuTriggerFor]="menu" class="month-selector-button" aria-label="Example icon-button with a menu">
                        <mat-icon>more_vert</mat-icon>
                      </button>
                      <mat-menu #menu="matMenu">
                        <button mat-menu-item (click)="copyMonth(i)">
                          <mat-icon>content_copy</mat-icon>
                          <span>Copiar el mes anterior a {{ month.value }} {{ filterForm.value['year'] }}...</span>
                        </button>
                      </mat-menu>
                    }
                  </th>
                  <td mat-cell *matCellDef="let row;" [ngStyle]="{'width': '90px', 'min-width': '90px' }">
                    <mat-form-field appearance="outline"
                      [matTooltip]="getInputTooltip(row, i)"
                      (click)="onClickInput(row, i)"
                      (dblclick)="onDblClickInput(row, i)"
                      [ngStyle]="{'width': '90px', 'min-width': '90px' }"
                  [ngClass]="{
                    'modified': row.employee_month_working_capacity_value[i].changed,
                    'less-time-than-normal': isFieldLessTimeThanNormal(row, i),
                    'is-non-productive-hours': row.employee_month_working_capacity_field === 'non_productive_hours'
                  }">
                      <input matInput type="number" min="0"
                        [ngModel]="row.employee_month_working_capacity_value[i].val"
                        (focusout)="changeInputEvt(row, i, $event)"
                        [disabled]="row.readonly"
                        [ngStyle]="{'width': '100%'}">
                      <span matSuffix>{{ row.unit }}</span>
                    </mat-form-field>
                  </td>
                  <td mat-footer-cell *matFooterCellDef #cell>
                    @if (monthly_times_occupied.length==12 && isFooterRow('horas-proyectos-occupied-row', cell)) {
                      <b [matTooltip]="getHoursOccupiedTooltipByMonth(i)" [ngClass]="getHoursOccupiedClassByMonth(i)">
                        {{ monthly_times_occupied[i] | number:'1.0-2' }}h
                      </b>
                    }
                    @if (isFooterRow('horas-proyectos-budgetable-row', cell)) {
                      <b [ngClass]="{'modified': isMonthModified(i, cell.parentElement?.id)}">
                        {{ totals[i].projects_budgetable | number:'1.0-2' }}h
                      </b>
                    }
                    @if (isFooterRow('horas-proyectos-row', cell)) {
                      <b [ngClass]="{'modified': isMonthModified(i, cell.parentElement?.id)}">
                        {{ totals[i].projects | number:'1.0-2' }}h
                      </b>
                    }
                    @if (isFooterRow('horas-proyectos-total-row', cell)) {
                      <b [ngClass]="{'modified': isMonthModified(i, cell.parentElement?.id)}">
                        {{ totals[i].total_hours | number:'1.0-2' }}h
                      </b>
                    }
                    @if (isFooterRow('coste-plantilla-row', cell)) {
                      <b [ngClass]="{'modified': isMonthModified(i, cell.parentElement?.id)}">{{ totals[i].plantilla | currency }}</b>
                    }
                    @if (isFooterRow('cost-plantilla-vacaciones-row', cell)) {
                      <b [ngClass]="{'modified': isMonthModified(i, cell.parentElement?.id)}">{{ totals[i].plantilla_vacations | currency }}</b>
                    }
                  </td>
                </ng-container>
              }
              <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
              <ng-container *matRowDef="let row; let i = index; columns: displayedColumns;">
                <tr mat-row [ngStyle]="{ 'border-bottom-width': isLastUserRow(row) && showUsers[row.user_id] ? '2px' : '1px', 'border-top-width': isFirstUserRow(row) && showUsers[row.user_id] ? '2px' : '1px' }" [hidden]="!showUsers[row.user_id] && row.employee_month_working_capacity_field!='total_availability.hours'"></tr>
              </ng-container>
              <tr mat-footer-row *matFooterRowDef="displayedColumns; sticky: true;" id="horas-proyectos-occupied-row"></tr>
              <tr mat-footer-row *matFooterRowDef="displayedColumns; sticky: true;" id="horas-proyectos-budgetable-row"></tr>
              <tr mat-footer-row *matFooterRowDef="displayedColumns; sticky: true;" id="horas-proyectos-row"></tr>
              <tr mat-footer-row *matFooterRowDef="displayedColumns; sticky: true;" id="horas-proyectos-total-row"></tr>
              <tr mat-footer-row *matFooterRowDef="displayedColumns; sticky: true;" id="coste-plantilla-row"></tr>
              <tr mat-footer-row *matFooterRowDef="displayedColumns; sticky: true;" id="cost-plantilla-vacaciones-row"></tr>
            </table>
          }
        </div>
      </div>
    </div>
  </div>
</div>
