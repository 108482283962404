<div id="content">
  <nav mat-tab-nav-bar [tabPanel]="tabPanel">
    @for (link of navLinks; track link) {
      <a mat-tab-link
        [routerLink]="link.link"
        routerLinkActive #rla="routerLinkActive"
      [active]="rla.isActive">{{ link.label }}</a>
    }
  </nav>
  <mat-tab-nav-panel #tabPanel></mat-tab-nav-panel>

  @if (activeLinkIndex==0) {
    <app-domain-detail-resume [domain]="domain"></app-domain-detail-resume>
  }
  @if (activeLinkIndex==1) {
    <app-domain-detail-billing [domain]="domain"></app-domain-detail-billing>
  }
  @if (activeLinkIndex==2) {
    <app-domain-detail-credentials [domain]="domain"></app-domain-detail-credentials>
  }
  @if (activeLinkIndex==3) {
    <app-domain-detail-edit [domain]="domain"></app-domain-detail-edit>
  }
  @if (activeLinkIndex==4) {
    <app-domain-detail-dns [domain]="domain"></app-domain-detail-dns>
  }
</div>
