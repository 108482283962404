import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Provider } from 'src/app/models/provider.model';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-billing-alert-detail-dialog',
  templateUrl: './billing-alert-detail-dialog.component.html',
  styleUrls: ['./billing-alert-detail-dialog.component.css']
})
export class BillingAlertDetailDialogComponent implements OnInit {

  form:UntypedFormGroup;
  available_providers:Provider[] = [];

  providerSelectControl = new UntypedFormControl();

  constructor(private fb:UntypedFormBuilder,
              public dialogRef: MatDialogRef<BillingAlertDetailDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data:any,
              private api:ApiService) {

    this.form = this.fb.group({
      provider_id: ['', Validators.required]
    });

  }

  ngOnInit(): void {
    this.listenControlSearch();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  selectProvider(p:Provider) {
    this.form.patchValue({provider_id: p.id});
  }

  displayFn(p:Provider): string {
    return p ? p.fact_name : '';
  }

  private listenControlSearch() {
    this.providerSelectControl.valueChanges.subscribe(
      (search_string:string) => {
        if(search_string != null && search_string.length >= 3) {
          const params = {
            search: search_string
          };
          this.api.getProviders(params).subscribe(
            data => {
              this.available_providers = data;
            }
          );
        }
        else this.available_providers = [];
      }
    );
  }

}
