import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { User } from 'src/app/models/user.model';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-assign-new-guard-dialog',
  templateUrl: './assign-new-guard-dialog.component.html',
  styleUrls: ['./assign-new-guard-dialog.component.css']
})
export class AssignNewGuardDialogComponent implements OnInit {

  form:FormGroup
  users:User[] = [];

  constructor(private fb:FormBuilder,
              public dialogRef: MatDialogRef<AssignNewGuardDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data:{project_id:string, from:moment.Moment,to:moment.Moment,},
              private api:ApiService) {

    this.form = this.fb.group({
      user_id: ['', Validators.required]
    });

  }

  ngOnInit(): void {
    this.fetchPotentialGuards();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  submit() {
    this.api.assignProjectGuardianScheduledWeek(this.data.project_id, this.form.value.user_id, this.data.from.format('YYYY-MM-DD')).subscribe(
      data => {
        this.dialogRef.close(true);
      }
    );
  }

  private fetchPotentialGuards() {
    this.api.getActiveProjectMembers(this.data.project_id, { date: this.data.from.toDate() }).subscribe(
      data => {
        this.users = data.map(member => member.user as User)
      }
    );
  }

}
