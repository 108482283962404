import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router, ActivatedRoute } from '@angular/router';
import { ClockRegisterReviewRequest } from 'src/app/models/clock-register-review-request.model';
import { ApiService } from 'src/app/services/api.service';
import { PermissionsService } from 'src/app/services/permissions.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-clock-register-review-requests',
  templateUrl: './clock-register-review-requests.component.html',
  styleUrls: ['./clock-register-review-requests.component.css']
})
export class ClockRegisterReviewRequestsComponent implements OnInit {


  displayedColumns: string[] = ['clock_type','id', 'user_name', 'justification','current_date', 'proposed_date', 'difference', 'actions'];
  dataSource:MatTableDataSource<ClockRegisterReviewRequest> = new MatTableDataSource<ClockRegisterReviewRequest>();

  // dades per el paginator
  length:number = 0;
  pageSize:number = 10;
  pageSizeOptions:number[] = [5,10,20,50];
  pageIndex:number = 1;

  filterForm:UntypedFormGroup;
  private formPersistence:any;

  public st:any[] = [
    { name: "Pendiente", status: 'pending' },
    { name: "Aceptado", status: 'approved' },
    { name: "Rechazado", status: 'rejected' }
  ];

  constructor(private api:ApiService,
    private fb:UntypedFormBuilder,
    private router:Router,
    private activatedRoute:ActivatedRoute,
    public permissions:PermissionsService,
    private utils:UtilsService,
    public dialog: MatDialog) {
    this.filterForm = this.fb.group({
      search: [this.activatedRoute.snapshot.queryParamMap.get('search')!=null ? this.activatedRoute.snapshot.queryParamMap.get('search') : '', Validators.compose([Validators.minLength(3)])],
      per_page: [this.activatedRoute.snapshot.queryParamMap.get('per_page')!=null ? +(this.activatedRoute.snapshot.queryParamMap.get('per_page') as any) : this.pageSize, Validators.required],
      page: [this.activatedRoute.snapshot.queryParamMap.get('page')!=null ? +(this.activatedRoute.snapshot.queryParamMap.get('page') as any) : this.pageIndex, Validators.required],
      total: [this.activatedRoute.snapshot.queryParamMap.get('total')!=null ? +(this.activatedRoute.snapshot.queryParamMap.get('total') as any) : this.length],
      user_id: [this.activatedRoute.snapshot.queryParamMap.get('user_id')!=null ? +(this.activatedRoute.snapshot.queryParamMap.get('user_id') as any) : ''],
      status: [this.activatedRoute.snapshot.queryParamMap.get('status')!=null ? +(this.activatedRoute.snapshot.queryParamMap.get('status') as any) : 'pending'],
      clock_type: [this.activatedRoute.snapshot.queryParamMap.get('clock_type')!=null ? +(this.activatedRoute.snapshot.queryParamMap.get('clock_type') as any) :'', Validators.required],
      append: 'meta_data'
    });
  }

  ngOnInit(): void {
    this.initFilterFormListener();
    this.listenQueryParameters();
    this.fetchClockRegisterReviewRequests();
  }

  changePage(event:PageEvent) {
    this.filterForm.patchValue({
      page: event.pageIndex,
      per_page: event.pageSize
    });
  }

  private initFilterFormListener() {
    this.filterForm.valueChanges.subscribe(
      data => {
        data.per_page = +data.per_page;
        if(this.formPersistence==null || JSON.stringify(this.formPersistence)!=JSON.stringify(data)) {
          if(this.formPersistence.per_page!=data.per_page) data.page = 0;
          this.router.navigate(['/employees', 'rrhh', 'clock-registers', 'clock-register-review-requests'], { queryParams: data });
        }
      }
    );
  }

  private listenQueryParameters() {
    this.activatedRoute.queryParams.subscribe(
      params => {
        if(JSON.stringify(params)!==JSON.stringify(this.filterForm.value)) { //si no ve de filtre s'ha de setejar el form
          let params_temp = this.utils.cloneObj(params); //params es read_only
          Object.keys(params_temp).forEach(param_key => {
            if(params_temp[param_key]!=null && params_temp[param_key]!="" && !isNaN(+params_temp[param_key])) params_temp[param_key] = +params_temp[param_key]; // si es numero, el transformem
          });
          this.filterForm.patchValue(params_temp, { emitEvent: false });
        }
        this.formPersistence = params;
        this.fetchClockRegisterReviewRequests();
      }
    );
  }

  fetchClockRegisterReviewRequests() {
    this.api.getClockRegisterReviewRequestsPaged({...this.filterForm.value, order_by: 'created_at', order_by_direction: 'desc'}).subscribe(data => {
      this.dataSource.data = data.data;

      this.length = +data.meta.total;
      this.pageIndex = +data.meta.current_page-1;
      this.pageSize = +data.meta.per_page;
    });
  }

  differenceBetweeDates(date1:any, date2:any) {
    var d1 = new Date(date1);
    var d2 = new Date(date2);

    var diffDays = Math.floor((Date.UTC(d1.getFullYear(), d1.getMonth(), d1.getDate()) - Date.UTC(d2.getFullYear(), d2.getMonth(), d2.getDate()) ) / (1000 * 60 * 60 * 24));

    return (d1 > d2 ? '- ' : '+ ') +
            (diffDays!=0 ? (diffDays + ' dias ') : '') +
            Math.round(Math.abs((new Date(date1)).getTime() - (new Date(date2)).getTime()) / (1000 * 60)) + ' min';
  }

  reject(id:number) {
    if(confirm("¿Estás seguro que quieres rechazar esta solicitud?")) {
      this.api.updateClockRegisterReviewRequest(id.toString(), {"status": "rejected"}).subscribe(
        (data) => {
          this.fetchClockRegisterReviewRequests();
        }
      );
    }
  }

  approve(id:number) {
    if(confirm("¿Estás seguro que quieres aprobar esta solicitud?")) {
      this.api.updateClockRegisterReviewRequest(id.toString(), {"status": "approved"}).subscribe(
        (data) => {
          this.fetchClockRegisterReviewRequests();
        }
      );
    }

  }

}
