<h1 mat-dialog-title>Solicitud de cambio</h1>

<mat-dialog-content [formGroup]="form">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="row">
          <mat-label>Fecha propuesta</mat-label>
          <mat-form-field appearance="outline">
              <input matInput type="datetime-local" formControlName="proposed_date">
          </mat-form-field>
        </div>
        <div class="row">
          <mat-label>Descripción</mat-label>
          <mat-form-field appearance="outline" style="width: 100%;">
              <textarea matInput formControlName="justification" rows="3" style="resize: none;"></textarea>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button (click)="onNoClick()" cdkFocusInitial type="button">Cancelar</button>
  <button mat-flat-button color="accent" (click)="save()" [disabled]="!form.valid" type="submit">
    Solicitar cambio
  </button>
</mat-dialog-actions>
