import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { PotentialClient } from 'src/app/models/potential-client.interface';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-potential-client-meeting-record',
  templateUrl: './potential-client-meeting-record.component.html',
  styleUrls: ['./potential-client-meeting-record.component.css']
})
export class PotentialClientMeetingRecordComponent {

  meetingableType:string = 'App\\Models\\PotentialClient';
  meetingableId:string;
  meetingRecordId:string;

  get isCreate() {
    return this.meetingRecordId == null;
  }

  constructor(private activatedRoute:ActivatedRoute) {
    this.meetingableId = this.activatedRoute.snapshot.paramMap.get('id') as string;
    this.meetingRecordId = this.activatedRoute.snapshot.paramMap.get('meeting_id') as string;
  }
}
