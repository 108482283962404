import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  form:UntypedFormGroup;
  logging:boolean = false;
  redirect:string|null;

  constructor(private fb:UntypedFormBuilder,
              private auth:AuthService,
              private router:Router,
              private snack:MatSnackBar,
              private api:ApiService,
              private activatedRoute:ActivatedRoute) {
    this.redirect = this.activatedRoute.snapshot.queryParamMap.get('redirect');
    this.form = this.fb.group({
      email: ['', Validators.compose([Validators.required, Validators.email])],
      password: ['', Validators.compose([Validators.required, Validators.minLength(4)])],
      keepAlive: [true]
    });
  }

  ngOnInit(): void {

  }

  submit() {
    this.logging = true;
    this.auth.login(this.form.value['email'], this.form.value['password'], this.form.value['keepAlive']).subscribe(
      data => {
        this.logging = false;
        if(this.redirect != null) {
          this.router.navigateByUrl(this.redirect);
        }
        else if (data.user.role.slug == 'worker') {
          this.router.navigate(['/employees/dashboard']);
        }
        else if (data.user.role.slug == 'client') {
          this.router.navigate(['/clients/projects']);
        }
        else {
          this.router.navigate(['/']);
        }
      },
      (error:any) => {
        this.logging = false;
        if(error instanceof HttpErrorResponse) {
          if(error.status == 401) {
            this.snack.open("El correo y/o la contraseña son incorrectos", "Ok", { duration: 3000 });
          }
          else {
            this.snack.open("Error al conectar con el servidor", "Ok", { duration: 3000 });
          }
        }
        else {
          this.snack.open(error, "Ok", { duration: 3000 });
        }
      }
    );
  }

}
