import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ProjectTaskBoard } from 'src/app/models/project_task_board.model';
import { ProjectTaskBoardTimeBudget } from 'src/app/models/project_task_board_time_budget.model';
import { ProjectTeam } from 'src/app/models/project_team.model';
import { User } from 'src/app/models/user.model';
import { WorkingTimeCategory } from 'src/app/models/working_time_category.model';
import { ApiService } from 'src/app/services/api.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-task-from-todo-to-progress-dialog',
  templateUrl: './task-from-todo-to-progress-dialog.component.html',
  styleUrl: './task-from-todo-to-progress-dialog.component.css'
})
export class TaskFromTodoToProgressDialogComponent implements OnInit {

  project_task_board:ProjectTaskBoard|undefined;
  users:User[]|undefined;
  form:FormGroup;

  constructor(public dialogRef: MatDialogRef<TaskFromTodoToProgressDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data:{ project_task_board_id:number, project_id:number },
              private api:ApiService,
              private fb:FormBuilder,
              private user:UserService) {

    this.form = this.fb.group({
      active_project_task_board_time_budget_id: ['', Validators.required],
      assignee_id: [ '', Validators.required ]
    });

  }

  ngOnInit(): void {
    this.fetchTask();
    this.fetchProductionTeam();
    this.listenActiveProjectIdChanges();
  }

  async submit() {
    await this.api.updateProjectTaskBoardTimeBudget(this.form.value.active_project_task_board_time_budget_id, { assignee_id: this.form.value.assignee_id }).toPromise();
    await this.api.updateProjectTaskBoard(this.project_task_board!.id, { active_project_task_board_time_budget_id: this.form.value.active_project_task_board_time_budget_id }).toPromise();
    this.dialogRef.close(this.project_task_board);
  }

  isTimeBudgetSelectable(time_budget:ProjectTaskBoardTimeBudget): boolean {
    return time_budget.completed_by_user_id==null && ((time_budget.budgetable as WorkingTimeCategory).slug == null || (time_budget.budgetable as WorkingTimeCategory).slug !== 'review-task');
  }

  private fetchTask() {
    this.api.getProjectTaskBoard(this.data.project_task_board_id, { with: 'project_task_board_time_budgets..budgetable' }).subscribe(
      data => {
        this.project_task_board = data;

        this.form.patchValue({
          active_project_task_board_time_budget_id: this.project_task_board.active_project_task_board_time_budget_id,
          assignee_id: this.project_task_board.active_project_task_board_time_budget?.assignee_id ?? this.user.getCurrentUser().id
        });

        this.checkIfIsNecessary();
      }
    );
  }

  private fetchProductionTeam() {
    this.api.getProjectActiveProductionTeam(this.data.project_id.toString()).subscribe(
      data => {
        this.users = data.bisual_users.map((obj:any) => obj.user);
        data.company_users.forEach(cu => this.users!.push(cu));
      }
    );
  }

  private listenActiveProjectIdChanges() {
    this.form.controls['active_project_task_board_time_budget_id'].valueChanges.subscribe(
      (data:number) => {
        if(data != null) {
          const time_budget:ProjectTaskBoardTimeBudget = this.project_task_board!.project_task_board_time_budgets.find(tb => tb.id == data) as ProjectTaskBoardTimeBudget;
          this.form.controls['assignee_id'].patchValue(time_budget.assignee_id != null ? time_budget.assignee_id : this.form.value['assignee_id']);
        }
      }
    );
  }

  private checkIfIsNecessary() {
    let budget_to_assign:any = null;
    let count_budgets_assignable:number = 0;

    for(let time_budget of this.project_task_board!.project_task_board_time_budgets) {
      if(this.isTimeBudgetSelectable(time_budget) && time_budget.assignee_id != null) {
        count_budgets_assignable++;
        budget_to_assign = time_budget;
      }
    }

    if(count_budgets_assignable === 1 && budget_to_assign != null) {
      this.form.patchValue({
        active_project_task_board_time_budget_id: budget_to_assign.id,
        assignee_id: budget_to_assign.assignee_id
      });

      this.submit();
    }

  }
}
