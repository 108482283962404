import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PotentialClient, PotentialClientStatus } from 'src/app/models/potential-client.interface';
import { User } from 'src/app/models/user.model';
import { ApiService } from 'src/app/services/api.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-add-potential-client-dialog',
  templateUrl: './add-potential-client-dialog.component.html',
  styleUrls: ['./add-potential-client-dialog.component.css']
})
export class AddPotentialClientDialogComponent implements OnInit {

  form:FormGroup;
  potential_client_statuses:PotentialClientStatus[] = [];
  available_comercials:User[] = [];
  available_product_owners:User[] = [];

  comercialSelectControl = new UntypedFormControl();
  productOwnerSelectControl = new UntypedFormControl();

  is_edit:boolean = false;

  constructor(private fb:FormBuilder,
              public dialogRef: MatDialogRef<AddPotentialClientDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data:any,
              private api:ApiService,
              private user:UserService) {
                const potential_client = data!=null ? data.potential_client : null;
                this.is_edit = potential_client != null;

                this.form = this.fb.group({
                  full_name: [potential_client!=null ? potential_client.full_name : '', Validators.compose([Validators.required])],
                  company_name: [potential_client!=null ? potential_client.company_name : '', Validators.compose([Validators.required])],
                  email: [potential_client!=null ? potential_client.email : '', Validators.compose([Validators.email])],
                  phone: [potential_client!=null ? potential_client.phone : '', Validators.compose([])],
                  potential_client_status_slug: [potential_client!=null ? potential_client.potential_client_status_slug : '', Validators.compose([Validators.required])],
                  contact_strategy: [potential_client!=null ? potential_client.contact_strategy : '', Validators.compose([Validators.required])],
                  contact_channel: [potential_client!=null ? potential_client.contact_channel : '', Validators.compose([Validators.required])],
                  commercial_id: [potential_client!=null ? potential_client.commercial_id : '', Validators.compose([Validators.required])],
                  product_owner_id: [potential_client!=null ? potential_client.product_owner_id : '', Validators.compose([])],
                  description: [potential_client!=null ? potential_client.description : '', Validators.compose([])],
                });
              }

  ngOnInit(): void {
    this.fetchStatuses();
    this.listenComercialSearch();
    this.listenProductOwnerSearch();
    if(!this.is_edit) {
      this.loadCurrentCommercial();
    }
  }

  close(): void {
    this.dialogRef.close();
  }

  selectComercial(c:User) {
    this.form.patchValue({commercial_id: c.id});
  }

  selectProductOwner(po:User) {
    this.form.patchValue({product_owner_id: po.id});
  }

  displayFnComercial(c:User) {
    if(c!=null) return c.name + ' ' + c.surnames;
    else return '';
  }

  displayFnProductOwner(po:User) {
    if(po!=null) return po.name + ' ' + po.surnames;
    else return '';
  }

  private fetchStatuses() {
    this.api.getPotentialClientStatus().subscribe(
      data => {
        this.potential_client_statuses = data;
      }
    );
  }

  private listenComercialSearch() {
    this.comercialSelectControl.valueChanges.subscribe(
      (search_string:string) => {
        if(search_string != null && search_string.length >= 3) {
          const params = { search: search_string };
          this.api.getUsers(params).subscribe(
            data => {
              this.available_comercials = data.data;
            }
          );
        }
        else this.available_comercials = [];
      }
    );
  }

  private listenProductOwnerSearch() {
    this.productOwnerSelectControl.valueChanges.subscribe(
      (search_string:string) => {
        if(search_string != null && search_string.length >= 3) {
          const params = { search: search_string };
          this.api.getUsers(params).subscribe(
            data => {
              this.available_product_owners = data.data;
            }
          );
        }
        else this.available_product_owners = [];
      }
    );
  }

  private loadCurrentCommercial() {
    // 1. Agafem current user
    const user = this.user.getCurrentUser();

    // 2. Posem al form
    this.form.patchValue({
      commercial_id: user.id
    });

    // 3. Posem la opció
    this.available_comercials = [user];

    // 4. Posem al form control
    this.comercialSelectControl.patchValue(user);
  }
}
