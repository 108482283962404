<h1 mat-dialog-title>{{ formGroup==null || formGroup.value['id']=='' ? 'Crear credencial' : 'Editar credencial' }}</h1>
<mat-dialog-content [formGroup]="formGroup">
    <div class="container">
        <div class="row">
            <div class="col-12 col-lg-4">
                <mat-form-field appearance="outline">
                    <mat-label>Nombre</mat-label>
                    <input matInput formControlName="name">
                </mat-form-field>
            </div>
            <div class="col-12 col-lg-4">
                <mat-form-field appearance="outline">
                    <mat-label>Propietario</mat-label>
                    <input matInput formControlName="company_id">
                </mat-form-field>
            </div>
            <div class="col-12 col-lg-4">
                <mat-form-field appearance="outline">
                    <mat-label>Dominio</mat-label>
                    <input matInput formControlName="domain_id">
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-lg-4">
                <mat-form-field appearance="outline">
                    <mat-label>Hosting</mat-label>
                    <input matInput formControlName="hosting_id">
                </mat-form-field>
            </div>
            <div class="col-12 col-lg-4">
                <mat-form-field appearance="outline">
                    <mat-label>Proyecto</mat-label>
                    <input matInput formControlName="project_id">
                </mat-form-field>
            </div>
            <div class="col-12 col-lg-4">
                <mat-form-field appearance="outline">
                    <mat-label>Correo corporativo</mat-label>
                    <input matInput formControlName="business_mail_id">
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-lg-4">
                <mat-form-field appearance="outline">
                    <mat-label>Protocolo</mat-label>
                    <mat-select formControlName="protocol">
                        <mat-option [value]="'sftp'">SFTP</mat-option>
                        <mat-option [value]="'ftp'">FTP</mat-option>
                        <mat-option [value]="'http'">HTTP</mat-option>
                        <mat-option [value]="'https'">HTTPS</mat-option>
                        <mat-option [value]="'ssh'">SSH</mat-option>
                        <mat-option [value]="'imap'">IMAP</mat-option>
                        <mat-option [value]="'smtp'">SMTP</mat-option>
                        <mat-option [value]="'pop3'">POP3</mat-option>
                        <mat-option [value]="'other'">Otro</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-12 col-lg-4">
                <mat-form-field appearance="outline">
                    <mat-label>Host</mat-label>
                    <input matInput formControlName="host">
                </mat-form-field>
            </div>
            <div class="col-12 col-lg-4">
                <mat-form-field appearance="outline">
                    <mat-label>Port</mat-label>
                    <input matInput formControlName="port">
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-lg-4">
                <mat-form-field appearance="outline">
                    <mat-label>Tipo</mat-label>
                    <mat-select formControlName="type">
                        <mat-option [value]="'ssh'">SSH</mat-option>
                        <mat-option [value]="'password'">Contraseña</mat-option>
                        <mat-option [value]="'other'">Otro</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-12 col-lg-4">
                <mat-form-field appearance="outline">
                    <mat-label>Username</mat-label>
                    <input matInput formControlName="username">
                </mat-form-field>
            </div>
            <div class="col-12 col-lg-4">
                <mat-form-field appearance="outline">
                    <mat-label>Contraseña</mat-label>
                    <input matInput formControlName="password">
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-lg-4">
                <mat-form-field appearance="outline">
                    <mat-label>SSH Public Key</mat-label>
                    <input matInput formControlName="ssh_public_key">
                </mat-form-field>
            </div>
            <div class="col-12 col-lg-4">
                <mat-form-field appearance="outline">
                    <mat-label>SSH Private Key</mat-label>
                    <input matInput formControlName="ssh_private_key">
                </mat-form-field>
            </div>
        </div>
    </div>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-button (click)="onNoClick()" cdkFocusInitial type="button">Cancelar</button>
    <button mat-stroked-button color="accent" (click)="save()" [disabled]="!formGroup.valid" type="submit">
        <mat-icon style="margin-right: 10px">add</mat-icon>
        {{ formGroup==null || formGroup.value['id']=='' ? 'Crear credencial' : 'Guardar credencial' }}
    </button>
</mat-dialog-actions>
