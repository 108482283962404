import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { RegexService } from '../services/regex.service';
import { TokenService } from '../services/token.service';
import { UtilsService } from '../services/utils.service';

@Injectable()
export class RegexServiceInitiatedGuard  {
  constructor(private regex:RegexService,
              private utils:UtilsService) { }
    
  async canActivate(route:ActivatedRouteSnapshot, state:RouterStateSnapshot) {
    await this.isRegexServiceInitiated();

    return true;
  }

  private async isRegexServiceInitiated():Promise<any> {
    const res = this.regex.getRegex();
    
    if(res != null) return true;
    else {
        await this.utils.sleep(500);
        return await this.isRegexServiceInitiated();
    }

  }
}