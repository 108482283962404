import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-working-times-calendar-dialog',
  templateUrl: './working-times-calendar-dialog.component.html',
  styleUrls: ['./working-times-calendar-dialog.component.css']
})
export class WorkingTimesCalendarDialogComponent implements OnInit {

    constructor(
      public dialogRef: MatDialogRef<WorkingTimesCalendarDialogComponent>,
      private userService: UserService,
      @Inject(MAT_DIALOG_DATA) public data: any,
      private _snackBar: MatSnackBar
    ) { }


    calendarUrl: string;

    ngOnInit() {
      this.calendarUrl = this.data.calendarUrl;
    }

    onNoClick(): void {
      this.dialogRef.close()
    }

    localSincronize() {
      let url = this.calendarUrl.replace("http", "webcal").replace("https", "webcal");
      window.open(url, '_blank');
    }

    googleSincronize() {
      let url = "https://calendar.google.com/calendar?cid="+ this.calendarUrl +"&pli=1"
      window.open(url, '_blank');
    }

    copyLink() {
      this._snackBar.open('Enlace copiado!', 'Undo', {
        duration: 3000,
      });
    }


}
