<div class="page-title">
  <h1>Editar proveedor</h1>
  <button mat-flat-button [routerLink]="['/employees', 'direction', 'users-management', 'providers']" type="button">
    <mat-icon matPrefix>arrow_back</mat-icon>
    Volver
  </button>
  <button mat-flat-button color="warn" (click)="delete()" type="button" [disabled]="!permissions.validateDepartment(['direction', 'finance'])">
    <mat-icon matPrefix>delete</mat-icon>
    Eliminar
  </button>
  <button mat-flat-button color="accent" [disabled]="!providerForm.valid" (click)="save()" type="submit" [disabled]="!permissions.validateDepartment(['direction', 'finance'])">
    <mat-icon matPrefix>save</mat-icon>
    Guardar
  </button>
</div>

<form class="card" [formGroup]="providerForm">
  <mat-tab-group mat-align-tabs="start" animationDuration="0ms">
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon>person</mat-icon>
        Datos generales
      </ng-template>

      <div class="container" style="margin-top: 20px">
        <div class="row">
          <div class="col-12 col-lg-4">
            <mat-form-field appearance="outline">
              <mat-label>Nombre</mat-label>
              <input type="text" matInput formControlName="name">
            </mat-form-field>
          </div>
          <div class="col-12 col-lg-4">
            <mat-form-field appearance="outline">
              <mat-label>Correo electrónico</mat-label>
              <input type="email" matInput formControlName="email">
            </mat-form-field>
          </div>
          <div class="col-12 col-lg-4">
            <mat-form-field appearance="outline">
              <mat-label>Teléfono móvil</mat-label>
              <input type="tel" matInput placeholder="+34.666 555 444" formControlName="phone">
              <mat-icon matSuffix>phone</mat-icon>
              <mat-hint>F: +XX.XXX XXX XXX</mat-hint>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-lg-4">
            <mat-form-field appearance="outline">
              <mat-label>Página web</mat-label>
              <input type="text" matInput formControlName="website">
            </mat-form-field>
          </div>
          <div class="col-12 col-lg-4">
            <mat-form-field appearance="outline">
              <mat-label>Identificador (slug)</mat-label>
              <input type="text" matInput formControlName="slug">
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-lg-4">
            <mat-checkbox formControlName="is_hosting_provider">Es proveedor de hosting</mat-checkbox>
          </div>
          <div class="col-12 col-lg-4">
            <mat-checkbox formControlName="is_domain_provider">Es proveedor de dominio</mat-checkbox>
          </div>
          <div class="col-12 col-lg-4">
            <mat-checkbox formControlName="is_business_mail_provider">Es proveedor de correos</mat-checkbox>
          </div>
        </div>
      </div>
    </mat-tab>

    <mat-tab label="Datos de facturación">
      <ng-template mat-tab-label>
        <mat-icon>account_balance</mat-icon>
        Datos de facturación
      </ng-template>

      <div class="container" style="margin-top: 20px">
        <div class="row">
          <div class="col-12 col-lg-3">
            <mat-form-field appearance="outline">
              <mat-label>Nombre de facturación</mat-label>
              <input type="text" matInput formControlName="fact_name">
            </mat-form-field>
          </div>
          <div class="col-12 col-lg-3">
            <mat-form-field appearance="outline">
              <mat-label>NIF/CIF</mat-label>
              <input type="text" matInput formControlName="vat">
            </mat-form-field>
          </div>
          <div class="col-12 col-lg-3">
            <mat-form-field appearance="outline">
              <mat-label>Dirección de facturación</mat-label>
              <input type="text" matInput formControlName="fact_address">
            </mat-form-field>
          </div>
          <div class="col-12 col-lg-3">
            <mat-form-field appearance="outline">
              <mat-label>Población/Ciudad</mat-label>
              <input type="text" matInput formControlName="fact_city">
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-lg-3">
            <mat-form-field appearance="outline">
              <mat-label>Código postal</mat-label>
              <input type="text" matInput formControlName="fact_zipcode">
            </mat-form-field>
          </div>
          <div class="col-12 col-lg-3">
            <mat-form-field appearance="outline">
              <mat-label>Província</mat-label>
              <input type="text" matInput formControlName="fact_county">
            </mat-form-field>
          </div>
          <div class="col-12 col-lg-3">
            <mat-form-field appearance="outline">
              <mat-label>País</mat-label>
              <mat-select formControlName="fact_country">
                @for (c of countries; track c) {
                  <mat-option [value]="c.name">{{ c.name }}</mat-option>
                }
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>

    </mat-tab>
  </mat-tab-group>
</form>
