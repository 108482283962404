import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'SecondsToHourWithDecimal'
})
export class SecondsToHourWithDecimalPipe implements PipeTransform {

  transform(value:number, toFix:number = 2):string {
    const hours = value / 3600;

    if(hours==0) return '-';
    else return hours.toFixed(toFix) + 'h';
  }

}
