import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'departmentCategoryToIconPipe'
})
export class DepartmentCategoryToIconPipe implements PipeTransform {

  transform(dc:string|null|undefined): string {
    switch (dc) {
      case 'assistant-manager':
        return 'computer';
      case 'cco':
        return 'computer';
      case 'ceo':
        return 'computer';
      case 'cfo':
        return 'computer';
      case 'chro':
        return 'computer';
      case 'cmo':
        return 'computer';
      case 'coo':
        return 'computer';
      case 'cso':
        return 'computer';
      case 'cto':
        return 'computer';
      case 'junior-developer-a':
        return 'computer';
      case 'junior-developer-b':
        return 'computer';
      case 'junior-developer-c':
        return 'computer';
      case 'mid-developer-a':
        return 'computer';
      case 'mid-developer-b':
        return 'computer';
      case 'mid-developer-c':
        return 'computer';
      case 'prcticas':
        return 'computer';
      case 'product-designer-a':
        return 'brush';
      case 'product-designer-b':
        return 'brush';
      case 'product-designer-c':
        return 'brush';
      case 'product-manager-a':
        return 'computer';
      case 'product-manager-b':
        return 'computer';
      case 'product-manager-c':
        return 'computer';
      case 'project-manager-a':
        return 'computer';
      case 'project-manager-b':
        return 'computer';
      case 'project-manager-c':
        return 'computer';
      case 'senior-developer-a':
        return 'computer';
      case 'senior-developer-b':
        return 'computer';
      case 'senior-developer-c':
        return 'computer';
      case 'student-developer':
        return 'computer';
      default:
        return 'no_accounts';
    }
  }

}
