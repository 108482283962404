import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dayOfWeekPlural'
})
export class DayOfWeekPluralPipe implements PipeTransform {

  transform(dayOfWeekIso:number): string {
    switch(dayOfWeekIso) {
      case 1:
        return 'lunes';
        break;
      case 2:
        return 'martes';
        break;
      case 3:
        return 'miércoles';
        break;
      case 4:
        return 'jueves';
        break;
      case 5:
        return 'viernes';
        break;
      case 6:
        return 'sábados';
        break;
      case 7:
        return 'domingos';
        break;
      default:
        return '';
        break;
    }
  }

}
