import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'SecondsToHourString'
})
export class SecondsToHourStringPipe implements PipeTransform {

  transform(value:number):string {
    const hours = Math.floor(value / 3600);
    const seconds_rest = value % 3600;
    const minutes = Math.floor(seconds_rest / 60);

    if(hours==0 && minutes==0) return '-';
    else if(hours==0 && minutes!=0) return minutes + 'm';
    else if(hours!=0 && minutes!=0) return hours + 'h ' + minutes + 'm';
    else return hours + 'h';
  }
}
