import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/models/user.model';
import { PermissionsService } from 'src/app/services/permissions.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-lateral-menu-client',
  templateUrl: './lateral-menu-client.component.html',
  styleUrls: ['./lateral-menu-client.component.css']
})
export class LateralMenuClientComponent implements OnInit {


  constructor(public permissions: PermissionsService, private userService: UserService,) {
  }

  ngOnInit(): void {
  }

}
