<div id="content">
  <div class="page-title">
      <h1>Aplicaciones</h1>
      <button mat-flat-button color="accent" [disabled]="!permissions.validateDepartment(['direction'])" [routerLink]="['create']">
          <mat-icon style="margin-right: 10px">add</mat-icon>
          Crear nueva aplicación
      </button>
  </div>

  <div class="bisual-table">
    <div class="bisual-table-container">
      <table mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>Nombre</th>
          <td mat-cell *matCellDef="let app">{{ app.name }}</td>
        </ng-container>

        <ng-container matColumnDef="git">
          <th mat-header-cell *matHeaderCellDef>Github URL</th>
          <td mat-cell *matCellDef="let app">
            <a mat-flat-button [href]="app.git_url" target="_blank">
              <mat-icon>open_in_new</mat-icon>
              Abrir en Github
            </a>
          </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let app; let i = index;">
            <button mat-icon-button [matMenuTriggerFor]="menu">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item [routerLink]="[app.id]">
                <mat-icon>edit</mat-icon>
                <span>Editar</span>
              </button>
              <button mat-menu-item (click)="delete(app)">
                <mat-icon>delete</mat-icon>
                <span>Eliminar</span>
              </button>
            </mat-menu>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
  </div>
</div>
