<div id="content" [formGroup]="filterForm">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="page-title">
          <h1>Dashboard comercial</h1>
          <div class="date-controller card" >
            <button mat-icon-button (click)="changeWeek(-1)"><mat-icon>chevron_left</mat-icon></button>
            <span class="date">{{ filterForm.value['from'] | date:'shortDate' }} - {{ filterForm.value['to'] | date:'shortDate' }}</span>
            <button mat-icon-button (click)="changeWeek(1)"><mat-icon>chevron_right</mat-icon></button>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <table mat-table [dataSource]="dataSource">
          <ng-container matColumnDef="user">
            <th mat-header-cell *matHeaderCellDef>Comercial</th>
            <td mat-cell *matCellDef="let element">{{ element.commercial_name }}</td>
          </ng-container>

          <ng-container matColumnDef="hours">
            <th mat-header-cell *matHeaderCellDef>Horas empleadas</th>
            <td mat-cell *matCellDef="let element" [ngClass]="getHoursRegisteredNgClass(element)" [matTooltip]="'Horas registradas sobre horas planificadas'">
              <span class="data">
                {{ element.hours.registered | number:'1.0-2' }}h / {{ element.hours.expected | number:'1.0-2' }}h
              </span>
            </td>
          </ng-container>

          <ng-container matColumnDef="proposal_to_be_made">
            <th mat-header-cell *matHeaderCellDef>Presupuestos conseguidos</th>
            <td mat-cell *matCellDef="let element" [ngClass]="{'positive': element.prcnt_average_period_changed!=null && element.prcnt_average_period_changed.proposal_to_be_made>0, 'negative': element.prcnt_average_period_changed!=null && element.prcnt_average_period_changed.proposal_to_be_made<0}">
              <span class="data">
                @if (element.prcnt_average_period_changed!=null && element.prcnt_average_period_changed.proposal_to_be_made!=null && element.prcnt_average_period_changed.proposal_to_be_made!=0) {
                  <mat-icon>{{ element.prcnt_average_period_changed.proposal_to_be_made > 0 ? 'trending_up' : 'trending_down' }}</mat-icon>
                }
                {{ element.current_period.proposal_to_be_made!=null ? element.current_period.proposal_to_be_made : 'Sin datos' }}
              </span>
              @if (element.average_period!=null && element.average_period.proposal_to_be_made != null) {
                <span class="average" [matTooltip]="'Media durante los últimos meses de actividad'">
                  ·
                  Media: {{ element.average_period.proposal_to_be_made | number:'1.0-2' }}
                  @if (element.prcnt_average_period_changed.proposal_to_be_made!=null) {
                    ({{ element.prcnt_average_period_changed.proposal_to_be_made >= 0 ? '+' : '' }}{{ element.prcnt_average_period_changed.proposal_to_be_made | number:'1.0-2' }}%)
                  }
                </span>
              }
            </td>
          </ng-container>

          <ng-container matColumnDef="first_contact">
            <th mat-header-cell *matHeaderCellDef>Contactos realizados</th>
            <td mat-cell *matCellDef="let element" [ngClass]="{'positive': element.prcnt_average_period_changed!=null && element.prcnt_average_period_changed.first_contact>0, 'negative': element.prcnt_average_period_changed!=null && element.prcnt_average_period_changed.first_contact<0}">
              <span class="data">
                @if (element.prcnt_average_period_changed!=null && element.prcnt_average_period_changed.first_contact!=null && element.prcnt_average_period_changed.first_contact!=0) {
                  <mat-icon>{{ element.prcnt_average_period_changed.first_contact > 0 ? 'trending_up' : 'trending_down' }}</mat-icon>
                }
                {{ element.current_period.first_contact!=null ? element.current_period.first_contact : 'Sin datos' }}
              </span>
              @if (element.average_period!=null && element.average_period.first_contact != null) {
                <span class="average" [matTooltip]="'Media durante los últimos meses de actividad'">
                  ·
                  Media: {{ element.average_period.first_contact | number:'1.0-2' }}
                  @if (element.prcnt_average_period_changed.first_contact!=null) {
                    ({{ element.prcnt_average_period_changed.first_contact >= 0 ? '+' : '' }}{{ element.prcnt_average_period_changed.first_contact | number:'1.0-2' }}%)
                  }
                </span>
              }
            </td>
          </ng-container>

          <ng-container matColumnDef="first_meeting_scheduled">
            <th mat-header-cell *matHeaderCellDef>Reuniones concertadas</th>
            <td mat-cell *matCellDef="let element" [ngClass]="{'positive': element.prcnt_average_period_changed!=null && element.prcnt_average_period_changed.first_meeting_scheduled>0, 'negative': element.prcnt_average_period_changed!=null && element.prcnt_average_period_changed.first_meeting_scheduled<0}">
              <span class="data">
                @if (element.prcnt_average_period_changed!=null && element.prcnt_average_period_changed.first_meeting_scheduled!=null && element.prcnt_average_period_changed.first_meeting_scheduled!=0) {
                  <mat-icon>{{ element.prcnt_average_period_changed.first_meeting_scheduled > 0 ? 'trending_up' : 'trending_down' }}</mat-icon>
                }
                {{ element.current_period.first_meeting_scheduled!=null ? element.current_period.first_meeting_scheduled : 'Sin datos' }}
              </span>
              @if (element.average_period!=null && element.average_period.first_meeting_scheduled != null) {
                <span class="average" [matTooltip]="'Media durante los últimos meses de actividad'">
                  ·
                  Media: {{ element.average_period.first_meeting_scheduled | number:'1.0-2' }}
                  @if (element.prcnt_average_period_changed.first_meeting_scheduled!=null) {
                    ({{ element.prcnt_average_period_changed.first_meeting_scheduled >= 0 ? '+' : '' }}{{ element.prcnt_average_period_changed.first_meeting_scheduled | number:'1.0-2' }}%)
                  }
                </span>
              }
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      </div>
    </div>
  </div>
</div>
