import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { PermissionsService } from "src/app/services/permissions.service";
import { UtilsService } from "src/app/services/utils.service";

@Injectable()
export abstract class RoleGuard  {

  role:string[];
  departmentCategory?:string;

  constructor(private permissions:PermissionsService,
              private utils:UtilsService) { }

  async canActivate(route:ActivatedRouteSnapshot, state:RouterStateSnapshot) {
    if(this.departmentCategory) {
      return this.isDepartmentCategory() || (await this.isDepartment());
    } else return await this.isDepartment();
  }

  protected async isDepartment(it:number = 0):Promise<boolean> {
    if(it > 5) return false;
    else {
      let isDepartment:boolean = false;
      this.role.forEach(role => {
        isDepartment = isDepartment || this.permissions.isDepartment(role);
      })

      if(!isDepartment) {
        await this.utils.sleep(200);
        return this.isDepartment(it++);
      }
      else return true;
    }
  }

  protected isDepartmentCategory():boolean {
    return this.permissions.isDepartmentCategory(this.departmentCategory ?? '');
  }
}
