import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hoursToHourString',
  standalone: true
})
export class HoursToHourStringPipe implements PipeTransform {

  transform(valueInHours:number):string {
    const valueInSeconds:number = valueInHours*3600;
    const hours = Math.floor(valueInSeconds / 3600);
    const seconds_rest = valueInSeconds % 3600;
    const minutes = Math.floor(seconds_rest / 60);

    if(hours==0 && minutes==0) return '-';
    else if(hours==0 && minutes!=0) return minutes + 'm';
    else if(hours!=0 && minutes!=0) return hours + 'h ' + minutes + 'm';
    else return hours + 'h';
  }

}
