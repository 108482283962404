<div class="page-title">
  <h1>{{ domain!=null ? domain.domain : 'Cargando...' }}</h1>

  @if (domain!=null && domain.godaddy_id != null && domain.cloudflare_id==null) {
    <button mat-flat-button color="accent" (click)="connectDns()" [disabled]="domain.is_fact_external && !permissions.validateDepartment(['direction', 'sysadmin'])">
      <mat-icon matPrefix>cloud</mat-icon>
      Conectar Godaddy con Cloudflare
    </button>
  }

  @if (domain!=null && !domain.is_fact_external && domain.godaddy_id != null && domain.cloudflare_id != null) {
    <button mat-flat-button color="success">
      <mat-icon matPrefix>done</mat-icon>
      Godaddy y Cloudflare conectados
    </button>
  }
</div>

@if (domain!=null) {
  <div class="container">
    <div class="row">
      <div class="col-12">
        @if (domain.provider.slug != 'godaddy' || domain.is_fact_external) {
          <div class="card">
            <div class="card-header">
              <div class="card-title">
                <h2>DNS de Godaddy</h2>
                <p class="subtitle">Modifica los DNS de Godaddy para que apunten a Cloudflare o dónde quieras.</p>
              </div>
            </div>
            Solo puedes tocar los registros DNS si es de Godaddy y está facturado por Bisual.
          </div>
        }
        @if (domain.provider.slug == 'godaddy' && !domain.is_fact_external) {
          <div class="card">
            <div class="card-header">
              <div class="card-title">
                <h2>DNS de Godaddy</h2>
                <p class="subtitle">Modifica los DNS de Godaddy para que apunten a Cloudflare o dónde quieras.</p>
              </div>
              <button mat-flat-button color="accent" (click)="addGodaddyDnsField()" [disabled]="!permissions.validateDepartment(['direction', 'sysadmin'])">
                <mat-icon matPrefix>add</mat-icon>
                Añadir registro
              </button>
              <button mat-flat-button color="success" (click)="changeGodaddyDns()" [disabled]="!godaddyForm.valid && !permissions.validateDepartment(['direction', 'sysadmin'])">
                <mat-icon matPrefix>backup</mat-icon>
                Guardar DNS
              </button>
            </div>
            <div class="container">
              <div class="row">
                <div class="col-12 col-md-8">
                  <ul>
                    @for (control of godaddyForm.controls; track control; let i = $index) {
                      <li>
                        <mat-form-field appearance="outline">
                          <mat-label>Nombre de servidor</mat-label>
                          <input matInput [formControl]="getGodaddyControl(i)">
                          <button matSuffix mat-icon-button color="danger" (click)="removeGodaddyDnsField(i)" [disabled]="!permissions.validateDepartment(['direction', 'sysadmin'])">
                            <mat-icon>close</mat-icon>
                          </button>
                        </mat-form-field>
                      </li>
                    }
                  </ul>
                </div>
                <div class="col-12 col-md-4">
                  @if (domain.cloudflare_zone != null) {
                    <button mat-flat-button color="grey" (click)="copyCloudflareDns()">
                      <mat-icon matPrefix>content_copy</mat-icon>
                      Copiar DNS de Cloudflare
                    </button>
                  }
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    </div>
    @if (domain.provider.slug == 'godaddy' && !domain.is_fact_external) {
      <div class="row">
        <div class="col-12">
          @if (domain.cloudflare_id==null) {
            <div class="card">
              <div class="card-header">
                <div class="card-title">
                  <h2>DNS de Cloudflare</h2>
                  <p class="subtitle">Modifica los registros DNS del dominio.</p>
                </div>
              </div>
              Este dominio no está conectado a Cloudflare. Pulsa el botón de "Conectar".
            </div>
          }
          @if (domain.cloudflare_id!=null) {
            <div class="card">
              <div class="card-header">
                <div class="card-title">
                  <h2>DNS de Cloudflare</h2>
                  <p class="subtitle">Modifica los registros DNS del dominio.</p>
                </div>
                <button mat-flat-button color="accent" (click)="addCloudflareDnsField()" [disabled]="!permissions.validateDepartment(['direction', 'sysadmin'])">
                  <mat-icon matPrefix>add</mat-icon>
                  Añadir registro
                </button>
              </div>
              <div class="bisual-table">
                <div class="bisual-table-row-header">
                  <div class="bisual-table-col">Nombre</div>
                  <div class="bisual-table-col">Tipo</div>
                  <div class="bisual-table-col">Valor</div>
                  <div class="bisual-table-col">Proxy</div>
                  <div class="bisual-table-col">TTL</div>
                  <div class="bisual-table-col">Prioridad</div>
                  <div class="bisual-table-col"></div>
                </div>
                @for (control of dnsForm.controls; track control; let i = $index) {
                  <div style="padding-top: 10px; padding-bottom: 10px;" class="bisual-table-row" [formGroup]="getCloudflareFormGroup(i)">
                    <div class="bisual-table-col">
                      <mat-form-field appearance="outline">
                        <mat-label>Nombre</mat-label>
                        <input matInput formControlName="name" placeholder="dominio.com">
                      </mat-form-field>
                    </div>
                    <div class="bisual-table-col">
                      <mat-form-field appearance="outline">
                        <mat-label>Tipo</mat-label>
                        <mat-select formControlName="type">
                          <mat-option value="A">A</mat-option>
                          <mat-option value="AAAA">AAAA</mat-option>
                          <mat-option value="CA">CA</mat-option>
                          <mat-option value="CERT">CERT</mat-option>
                          <mat-option value="CNAME">CNAME</mat-option>
                          <mat-option value="DNSKEY">DNSKEY</mat-option>
                          <mat-option value="DS">DS</mat-option>
                          <mat-option value="HTTPS">HTTPS</mat-option>
                          <mat-option value="LOC">LOC</mat-option>
                          <mat-option value="MX">MX</mat-option>
                          <mat-option value="NAPTR">NAPTR</mat-option>
                          <mat-option value="NS">NS</mat-option>
                          <mat-option value="PTR">PTR</mat-option>
                          <mat-option value="SMIMEA">SMIMEA</mat-option>
                          <mat-option value="SPF">SPF</mat-option>
                          <mat-option value="SRV">SRV</mat-option>
                          <mat-option value="SSHFP">SSHFP</mat-option>
                          <mat-option value="SVCB">SVCB</mat-option>
                          <mat-option value="TLSA">TLSA</mat-option>
                          <mat-option value="TXT">TXT</mat-option>
                          <mat-option value="URI">URI</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div class="bisual-table-col">
                      <mat-form-field appearance="outline">
                        <mat-label>Contenido</mat-label>
                        <input matInput formControlName="content" placeholder="123.123.123.123">
                      </mat-form-field>
                    </div>
                    <div class="bisual-table-col">
                      <mat-checkbox formControlName="proxied">Activo</mat-checkbox>
                    </div>
                    <div class="bisual-table-col">
                      <mat-form-field appearance="outline">
                        <mat-label>TTL</mat-label>
                        <input matInput formControlName="ttl" placeholder="10">
                        <mat-hint>'1' es auto</mat-hint>
                      </mat-form-field>
                    </div>
                    <div class="bisual-table-col">
                      <mat-form-field appearance="outline">
                        <mat-label>Prioridad</mat-label>
                        <input matInput formControlName="priority" placeholder="10">
                      </mat-form-field>
                    </div>
                    <div class="bisual-table-col">
                      <button mat-icon-button color="success" (click)="saveCloudflareDns(i)" [disabled]="!permissions.validateDepartment(['direction', 'sysadmin'])"><mat-icon>save</mat-icon></button>
                      <button mat-icon-button color="danger" (click)="deleteCloudflareDns(i)" [disabled]="!permissions.validateDepartment(['direction', 'sysadmin'])"><mat-icon>clear</mat-icon></button>
                    </div>
                  </div>
                }
              </div>
            </div>
          }
        </div>
      </div>
    }
  </div>
}
