<h2 mat-dialog-title>Detalle del dia</h2>

@if (ews) {
  <mat-dialog-content>
    <ul id="resume">
      <li>
        <div class="header">
          <div class="semaforo" [ngClass]="[ews.working_times_flag]"></div>
          <h3>{{ ews.working_time_hours | number:'1.0-2' }} horas</h3>
        </div>
        <p class="subtitle">Registradas</p>
      </li>
      <li>
        <div class="header">
          <div class="semaforo far" [ngClass]="[ews.clock_registers_flag]"></div>
          <h3>{{ ews.clock_registers_hours | number:'1.0-2' }} horas</h3>
        </div>
        <p class="subtitle">Fichadas</p>
      </li>
      <li>
        <div class="header">
          <div class="semaforo far-away" [ngClass]="[ews.working_times_flag]"></div>
          <h3>{{ ews.working_contract_hours | number:'1.0-2' }} horas</h3>
        </div>
        <p class="subtitle">Por contrato</p>
      </li>
    </ul>
    <div id="clock-registers">
      <h3>Fichajes</h3>
      @if (ews.clock_registers.length===0) {
        <div>
          No hay fichajes durante este día
        </div>
      }
      @if (ews.clock_registers.length) {
        <mat-accordion>
          @for (c of ews.clock_registers; track c) {
            <mat-expansion-panel [disabled]="c.justification==null || c.justification==''" [hideToggle]="c.justification==null || c.justification==''">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <div class="semaforo" [ngClass]="[ c.journal_clock_type==='rest' ? 'rest' : c.flag ]"></div>
                  <mat-icon [ngClass]="{'ok': c.type==='in', 'far-away': c.type==='out'}">{{ c.type==='in' ? 'east' : 'west' }}</mat-icon>
                  {{ c.type==='in' ? 'Entrada' : 'Salida' }}
                </mat-panel-title>
                <mat-panel-description>
                  {{ c.created_at | date:'short' }}
                </mat-panel-description>
              </mat-expansion-panel-header>
              @if (c.justification) {
                <p>{{ c.justification }}</p>
              }
            </mat-expansion-panel>
          }
        </mat-accordion>
      }
    </div>
  </mat-dialog-content>
}

<mat-dialog-actions align="end">
  <button mat-flat-button color="accent" mat-dialog-close>Cerrar</button>
</mat-dialog-actions>
