<h2>Restablecer contraseña</h2>

<form [formGroup]="form">
  @if (!token_provided) {
    <div class="field">
      <label for="token">Token</label>
      <mat-form-field appearance="outline">
        <input matInput type="token" id="token" formControlName="token" placeholder="Introduce tu codigo">
      </mat-form-field>
    </div>
  }

  <div class="field">
    <label for="password">Contraseña</label>
    <mat-form-field appearance="outline">
      <input matInput type="password" placeholder="Contraseña" formControlName="password">
      @if (form.value['password'].invalid) {
        <mat-error>{{ 'Contraseña inválida' }}</mat-error>
      }
    </mat-form-field>
  </div>

  <div class="field">
    <label for="repeat_password">Repetir contraseña</label>
    <mat-form-field appearance="outline">
      <input matInput type="password" placeholder="Contraseña" formControlName="repeat_password">
      @if (form.value['repeat_password'].invalid) {
        <mat-error>{{ 'Contraseña inválida' }}</mat-error>
      }
      @if (form.errors?.notSame) {
        <mat-error>Las contraseñas no coinciden</mat-error>
      }
    </mat-form-field>
  </div>

  <button mat-flat-button color="black" type="submit" (click)="submit()" [disabled]="!form.valid || (form.value['repeat_password'] !== form.value['password'])">
    Crear contraseña
  </button>
</form>
