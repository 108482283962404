import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import * as moment from 'node_modules/moment';
import { EmployeeCalendarRegister } from 'src/app/models/employee_calendar_register.model';
import { Holiday } from 'src/app/models/holiday.model';

@Component({
  selector: 'app-non-productive-hours-planning-dialog',
  templateUrl: './non-productive-hours-planning-dialog.component.html',
  styleUrls: ['./non-productive-hours-planning-dialog.component.css']
})
export class NonProductiveHoursPlanningDialogComponent implements OnInit {

  row:any;
  index:number;
  year:number;
  dataSource:MatTableDataSource<TableRow> = new MatTableDataSource();

  displayedColumns:string[] = ["day", "hours_available", "negocio", "comercial", "formacion", "daily", "otros", "total", "actions"];

  totals:{[key:string]: number} = {
    negocio: 0,
    comercial: 0,
    formacion: 0,
    daily: 0,
    otros: 0,
    non_productive_hours: 0
  };

  currentVal:number;

  private already_asked:boolean = false;

  constructor(public dialogRef: MatDialogRef<NonProductiveHoursPlanningDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data:any) {
                this.row = data.row;
                this.index = data.index;
                this.year = data.year;
                this.currentVal = data.currentVal;
              }

  ngOnInit(): void {
    this.initMonth();
  }

  close(val:any = null) {
    this.dialogRef.close(val);
  }

  onNegocioChange(index:number, newVal:number) {
    if(!isNaN(newVal)) {
      const diff = this.dataSource.data[index].negocio - newVal;

      // Actualitzem valor
      this.dataSource.data[index].negocio = newVal;

      // Actualitzem total horitzontal
      this.dataSource.data[index].non_productive_hours -= diff;

      // Actualitzem total vertical
      this.totals.negocio -= diff;

      // Actualitzem total total
      this.totals.non_productive_hours -= diff;
    }
  }

  onComercialChange(index:number, newVal:number) {
    if(!isNaN(newVal)) {
      const diff = this.dataSource.data[index].comercial - newVal;

      // Actualitzem valor
      this.dataSource.data[index].comercial = newVal;

      // Actualitzem total horitzontal
      this.dataSource.data[index].non_productive_hours -= diff;

      // Actualitzem total vertical
      this.totals.comercial -= diff;

      // Actualitzem total total
      this.totals.non_productive_hours -= diff;
    }
  }

  onFormacionChange(index:number, newVal:number) {
    if(!isNaN(newVal)) {
      const diff = this.dataSource.data[index].formacion - newVal;

      // Actualitzem valor
      this.dataSource.data[index].formacion = newVal;

      // Actualitzem total horitzontal
      this.dataSource.data[index].non_productive_hours -= diff;

      // Actualitzem total vertical
      this.totals.formacion -= diff;

      // Actualitzem total total
      this.totals.non_productive_hours -= diff;
    }
  }

  onDailyChange(index:number, newVal:number) {
    if(!isNaN(newVal)) {
      const diff = this.dataSource.data[index].daily - newVal;

      // Actualitzem valor
      this.dataSource.data[index].daily = newVal;

      // Actualitzem total horitzontal
      this.dataSource.data[index].non_productive_hours -= diff;

      // Actualitzem total vertical
      this.totals.daily -= diff;

      // Actualitzem total total
      this.totals.non_productive_hours -= diff;
    }
  }

  onOtrosChange(index:number, newVal:number) {
    if(!isNaN(newVal)) {
      const diff = this.dataSource.data[index].otros - newVal;

      // Actualitzem valor
      this.dataSource.data[index].otros = newVal;

      // Actualitzem total horitzontal
      this.dataSource.data[index].non_productive_hours -= diff;

      // Actualitzem total vertical
      this.totals.otros -= diff;

      // Actualitzem total total
      this.totals.non_productive_hours -= diff;
    }
  }

  bulkCopy(index:number) {
    if(this.already_asked || confirm("¿Estás seguro que quieres copiar el contenido al resto de días?")) {
      this.already_asked = true;
      for(let i = index; i < this.dataSource.data.length; i++) {
        const item = this.dataSource.data[i];
        if(!item.is_weekend && item.holidays.length == 0 && item.non_working_times.length == 0) {
          this.onDailyChange(i, this.dataSource.data[index].daily);
          this.onOtrosChange(i, this.dataSource.data[index].otros);
          this.onNegocioChange(i, this.dataSource.data[index].negocio);
          this.onComercialChange(i, this.dataSource.data[index].comercial);
          this.onFormacionChange(i, this.dataSource.data[index].formacion);
        }
      }
    }
  }

  use() {
    this.close(this.totals.non_productive_hours);
  }

  private initMonth() {
    const emwc = this.row.emwc[this.index];
    this.dataSource.data = emwc.total_availability.days.map((item:any): TableRow => {
      const mom:moment.Moment = moment(item.day);
      return {
        day: mom.toDate(),
        hours_available: item.hours,
        negocio: 0,
        comercial: 0,
        formacion: 0,
        daily: 0,
        otros: 0,
        non_productive_hours: 0,
        non_working_times: item.non_working_times ?? [],
        holidays: item.holidays ?? [],
        is_weekend: mom.day()===6 || mom.day()===0
      };
    });

  }

}

interface TableRow {
  day:Date;
  hours_available:number;
  negocio:number;
  comercial:number;
  formacion:number;
  daily:number;
  otros:number;
  non_productive_hours:number;
  non_working_times:EmployeeCalendarRegister[];
  holidays:Holiday[];
  is_weekend:boolean;
}
