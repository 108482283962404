<h1 mat-dialog-title>Añadir nueva Categoria</h1>
<mat-dialog-content [formGroup]="categoryForm">
  <div class="container">
    <div class="row">
      <div class="col-12 col-lg-4">
        <mat-form-field appearance="outline">
          <mat-label>Nombre</mat-label>
          <input type="text" matInput formControlName="name">
        </mat-form-field>
      </div>
      <div class="col-12 col-lg-8">
        <mat-form-field appearance="outline">
          <mat-label>Descripción</mat-label>
          <input type="text" matInput formControlName="description">
        </mat-form-field>
      </div>

      <div class="col-12 col-lg-4">
        <mat-label style="padding-left: 10px;">Salario Mínimo Anual:</mat-label>
        <mat-form-field appearance="outline">
          <input type="number" matInput formControlName="min_salary">
          <span matSuffix>€</span>
        </mat-form-field>
      </div>

      <div class="col-12 col-lg-4">
        <mat-label style="padding-left: 10px;">Salario Máximo Anual:</mat-label>
        <mat-form-field appearance="outline">
          <input type="number" matInput formControlName="max_salary">
          <span matSuffix>€</span>
        </mat-form-field>
      </div>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button (click)="cancel()" cdkFocusInitial type="button">Cancelar</button>
  <button mat-flat-button color="accent" [mat-dialog-close]="categoryForm.value" [disabled]="!categoryForm.valid" type="submit">
      <mat-icon style="margin-right: 10px">add_user</mat-icon>
      Crear Categoria
  </button>
</mat-dialog-actions>
