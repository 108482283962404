import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { debounceTime } from 'rxjs/operators';
import { CountryZone } from 'src/app/models/country_zone.model';
import { Holiday } from 'src/app/models/holiday.model';
import { User } from 'src/app/models/user.model';
import { WorkingContractYearCondition } from 'src/app/models/working_contract_year_condition.model';
import { ApiService } from 'src/app/services/api.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-holidays',
  templateUrl: './holidays.component.html',
  styleUrls: ['./holidays.component.css']
})
export class HolidaysComponent implements OnInit {

  years:number[];
  filterForm:FormGroup;
  private formPersistence:any;
  country_zone_search:FormControl = new FormControl('');
  current_country_zone_selected:CountryZone = null as any;

  first_level_country_zones:CountryZone[] = [];
  second_and_third_level_country_zones_filtered:CountryZone[] = [];

  displayedColumns: string[] = ['name', 'date', 'type','approved'];
  dataSource:MatTableDataSource<Holiday> = new MatTableDataSource<Holiday>();

  usersWithData:{user:User; working_contract_year_condition: WorkingContractYearCondition|undefined}[] = [];

  constructor(private fb:FormBuilder,
              private api:ApiService,
              private router:Router,
              private activatedRoute:ActivatedRoute,
              private utils:UtilsService) {
    const now = new Date();
    this.years = [
      now.getFullYear()-5,
      now.getFullYear()-4,
      now.getFullYear()-3,
      now.getFullYear()-2,
      now.getFullYear()-1,
      now.getFullYear(),
      now.getFullYear()+1
    ]

    this.filterForm = this.fb.group({
      year: [now.getFullYear()],
      country: ['ES'],
      country_zone_id: ['']
    });
  }

  ngOnInit(): void {
    this.listenCountryZoneInput();
    this.listenCountryDropdown();
    this.fetchFirstLevelCountryZones();
    this.initFilterFormListener();
    this.listenQueryParameters();
    this.listenYearDropdown();
  }

  displayCountryZone(cz:CountryZone) {
    return cz.name;
  }

  onSelectCountryZone(cz:CountryZone) {
    this.filterForm.patchValue({ country_zone_id: cz.id });
  }

  clearCountryZone() {
    this.filterForm.patchValue({ country_zone_id: '' });
    this.country_zone_search.patchValue('');
    this.current_country_zone_selected = null as any;
  }

  onToggleApproved(h:Holiday, approved:boolean) {
    h.approved = approved;
    this.api.updateHoliday(h.id.toString(), { approved: approved }).subscribe(
      data => {

      }
    );
  }

  private fetchFirstLevelCountryZones() {
    this.api.getCountryZones({ level: 1 }).subscribe(
      data => {
        this.first_level_country_zones = data;
        this.initCurrentCountryZoneSelectedAttr('ES');
      }
    );
  }

  private initFilterFormListener() {
    this.filterForm.valueChanges.subscribe(
      data => {
        // data.per_page = +data.per_page;
        if(this.formPersistence==null || JSON.stringify(this.formPersistence)!=JSON.stringify(data)) {
          // if(this.formPersistence.per_page!=data.per_page) data.page = 0;
          this.router.navigate(['/employees', 'rrhh', 'holidays'], { queryParams: data });
        }
      }
    );
  }

  private listenQueryParameters() {
    this.activatedRoute.queryParams.subscribe(
      params => {
        if(JSON.stringify(params)!==JSON.stringify(this.filterForm.value)) { //si no ve de filtre s'ha de setejar el form
          let params_temp = this.utils.cloneObj(params); //params es read_only
          Object.keys(params_temp).forEach(param_key => {
            if(params_temp[param_key]!=null && params_temp[param_key]!="" && !isNaN(+params_temp[param_key])) params_temp[param_key] = +params_temp[param_key]; // si es numero, el transformem
          });
          this.filterForm.patchValue(params_temp, { emitEvent: false });
        }
        this.formPersistence = params;
        this.fetchHolidays();
      }
    );
  }

  private fetchHolidays() {
    const cz_id = this.filterForm.value.country_zone_id!='' ? this.filterForm.value.country_zone_id : (this.current_country_zone_selected != null ? this.current_country_zone_selected.id : null);
    if(cz_id != null) {
      this.api.getCountryZoneHolidays(cz_id, { year: this.filterForm.value.year }).subscribe(
        data => {
          this.dataSource.data = data
        }
      );
    }
  }

  private listenCountryZoneInput() {
    this.country_zone_search.valueChanges.pipe(debounceTime(500)).subscribe(
      (data:string) => {
        if(typeof data === 'string') {
          if(data.length < 3) {
            this.second_and_third_level_country_zones_filtered = [];
          }
          else {
            this.api.getCountryZones({ search: data, parent_country_zone: this.filterForm.value['country'], with: 'parent..parent' }).subscribe(
              data => {
                this.second_and_third_level_country_zones_filtered = data;
              }
            )
          }
        }
      }
    );
  }

  private listenCountryDropdown() {
    this.filterForm.controls['country'].valueChanges.pipe(debounceTime(500)).subscribe(
      data => {
        this.initCurrentCountryZoneSelectedAttr(data);
        this.filterForm.patchValue({ country_zone_id: '' })
      }
    );
  }

  private initCurrentCountryZoneSelectedAttr(code:string) {
    for(let cz of this.first_level_country_zones) {
      if(cz.code == code) {
        this.current_country_zone_selected = cz;
        break;
      }
    }
    this.fetchHolidays();
  }

  private listenYearDropdown() {
    this.fetchUsersYearlyConditions(this.filterForm.value.year);
    this.filterForm.controls['year'].valueChanges.subscribe(
      year => {
        this.fetchUsersYearlyConditions(year);
      }
    );
  }

  private fetchUsersYearlyConditions(year:string) {
    this.api.getEmployeesWorkingContractYearConditions(year).subscribe(
      data => {
        this.usersWithData = data
      }
    );
  }

}
