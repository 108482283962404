import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from 'src/app/models/user.model';
import { WorkingContract } from 'src/app/models/working_contract.model';
import { WorkingContractType } from 'src/app/models/working_contract_type.model';
import { ApiService } from 'src/app/services/api.service';
import { UserService } from 'src/app/services/user.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-working-contracts',
  templateUrl: './working-contracts.component.html',
  styleUrls: ['./working-contracts.component.css']
})
export class WorkingContractsComponent implements OnInit {

  @Input() user: User = null as any;



  usersContractForm:UntypedFormGroup;
  tableContracts:WorkingContract[] = [];
  workingContractsTypes:WorkingContractType[] = [];

  displayedColumns: string[] = ['ID', 'Nombre', 'Contrato', 'Fecha-inicio', 'Fecha-final', 'actions'];

  length:number = 0;
  pageSize:number = 10;
  pageSizeOptions:number[] = [5,10,20,50];
  pageIndex:number = 1;


  private formPersistence:any;

  constructor(private fb:UntypedFormBuilder,
              private api:ApiService,
              private activatedRoute:ActivatedRoute,
              private utils:UtilsService,
              private router:Router) {
    this.usersContractForm = this.fb.group({
      'user-name': [''],
      working_contract_type_id: [''],
      per_page: [this.activatedRoute.snapshot.queryParamMap.get('per_page')!=null ? +(this.activatedRoute.snapshot.queryParamMap.get('per_page') as any) : this.pageSize, Validators.required],
      page: [this.activatedRoute.snapshot.queryParamMap.get('page')!=null ? +(this.activatedRoute.snapshot.queryParamMap.get('page') as any) : this.pageIndex, Validators.required],
      user_id:[this.activatedRoute.snapshot.queryParamMap.get('user_id'), Validators.required],
    });
  }

  ngOnInit(): void {
    this.readInputUser();
    this.fetchWorkingContractsTypes();
    this.listenQueryParameters();
    this.initFilterFormListener();
  }

  private fetchWorkingContracts() {
    this.api.getWorkingContractsPaged({...this.usersContractForm.value}).subscribe(
      (contracts) => {
        if(contracts!=null) {
          this.usersContractForm.patchValue(contracts);

          this.tableContracts = contracts.data;

          this.length = +contracts.meta.total;
          this.pageIndex = +contracts.meta.current_page-1;
          this.pageSize = +contracts.meta.per_page;

        }

      }
    );
  }

  private initFilterFormListener() {
    this.usersContractForm.valueChanges.subscribe(
      data => {
        data.per_page = +data.per_page;
        if(this.formPersistence==null || JSON.stringify(this.formPersistence)!=JSON.stringify(data)) {
          if(this.formPersistence.per_page!=data.per_page) data.page = 0;

          if(this.user!=null && data.user_id!=null) {
            delete data.user_id;
          }

          this.router.navigate([], { queryParams: data });
        }
      }
    );
  }

  private listenQueryParameters() {
    this.activatedRoute.queryParams.subscribe(params => {
      if(JSON.stringify(params)!==JSON.stringify(this.usersContractForm.value)) { //si no ve de filtre s'ha de setejar el form
        let params_temp = this.utils.cloneObj(params); //params es read_only
        Object.keys(params_temp).forEach(param_key => {
          if(params_temp[param_key]!=null && params_temp[param_key]!="" && !isNaN(+params_temp[param_key])) params_temp[param_key] = +params_temp[param_key]; // si es numero, el transformem
        });
        this.usersContractForm.patchValue(params_temp, { emitEvent: false });
      }
      this.formPersistence = params;

      this.fetchWorkingContracts();
    })
  }

  private fetchWorkingContractsTypes() {
    this.api.getWorkingContractsTypes().subscribe(
      data => {
        this.workingContractsTypes = data;
      }
    )
  }

  changePage(event:PageEvent) {
    this.usersContractForm.patchValue({
      page: event.pageIndex,
      per_page: event.pageSize
    });
  }

  finishContract(wc:WorkingContract) {
    if(confirm("¿Estás seguro que quieres finalizar este contrato?")) {
      this.api.updateWorkingContract(wc.id.toString(), { end_date: new Date() }).subscribe(
        data => {
          this.fetchWorkingContracts();
        }
      );
    }
  }


  private readInputUser() {

    if(this.user != null){ // Ens passen usuari
      // Eliminem columna de user
      const index = this.displayedColumns.indexOf('Nombre');

      if(index > -1){
        this.displayedColumns.splice(index, 1);
      }

      //Fem consulta al usersContractForm per obtenir els contractes amb el mateix ID que l'usuari
      this.usersContractForm.patchValue({
        user_id : this.user.id
      })

    }
  }


}

