import { WikiArticle, WikiArticleVersion } from 'src/app/models/wiki-article.model';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Route, Router } from '@angular/router';
import * as hljs from 'highlight.js';
import { UserService } from 'src/app/services/user.service';
import { MoveWikiItemDialogComponent } from 'src/app/components/utils/move-wiki-item-dialog/move-wiki-item-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { WikiNode } from 'src/app/models/wiki-node.model';
import { ApplicationEnvironment } from 'src/app/models/application_environment.model';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { TinymceService } from 'src/app/services/tinymce.service';


@Component({
  selector: 'app-wiki-article',
  templateUrl: './wiki-article.component.html',
  styleUrls: ['./wiki-article.component.css']
})
export class WikiArticleComponent implements OnInit {
  articleId: string|undefined;
  github_file_name:string|undefined;
  article: WikiArticle = null as any;
  editMode = false;
  indice: {title: string;level: number;}[] = [];
  showIndice = false;
  upper_node_id:string|undefined;
  environments:ApplicationEnvironment[] = null as any;
  application_environment_id:string = null as any

  tinyMceInit = {
    automatic_uploads: true,
    statusbar: false,
    menubar: false,
    block_unsupported_drop: false,
    height: "68vh",
    font_formats: "Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats",
    codesample_languages: [
      { text: 'HTML/XML', value: 'c' },
      { text: 'JavaScript', value: 'javascript' },
      { text: 'CSS', value: 'css' },
      { text: 'PHP', value: 'php' },
      { text: 'Ruby', value: 'ruby' },
      { text: 'Python', value: 'python' },
      { text: 'Java', value: 'java' },
      { text: 'C', value: 'c' },
      { text: 'C#', value: 'csharp' },
      { text: 'C++', value: 'cpp' },
      { text: 'Bash', value: 'bash' },
      { text: 'SQL', value: 'sql' },
      { text: 'JSON', value: 'json' },
      { text: 'YAML', value: 'yaml' },
      { text: 'Markdown', value: 'markdown' },
      { text: 'Dockerfile', value: 'dockerfile' },
      { text: 'Git', value: 'git' },
      { text: 'Nginx', value: 'nginx' },
      { text: 'Apache', value: 'apache' },
      { text: 'Ini', value: 'ini' },
      { text: 'TypeScript', value: 'typescript' },
      { text: 'Dart', value: 'dart' }
    ]
  };

  constructor(
    private activatedRoute: ActivatedRoute,
    private api: ApiService,
    private userService: UserService,
    private router: Router,
    private dialog: MatDialog,
    public tinymce:TinymceService) {
  }

  ngOnInit(): void {
    // listen to route params
    this.upper_node_id = this.activatedRoute.snapshot.queryParamMap.get("upper_node") as string;
    this.application_environment_id = this.activatedRoute.snapshot.queryParamMap.get("application_environment_id") as string;
    this.activatedRoute.params.subscribe((params) => {
      this.articleId = params.article_id;
      this.github_file_name = params.github_file_name

      if(this.articleId != null) this.fetchWikiArticle();
      else if(this.github_file_name != null) {
        this.fetchWikiGithubArticle();
        this.fetchEnvironments(params.node_id);
      }
    });
  }

  generateIndex(content: string): void {
    this.indice = [];

    const container = document.createElement('div');
    container.innerHTML = content;

    const headings = container.querySelectorAll('h1, h2, h3, h4, h5, h6');
    const stack: {title: string; level: number;}[] = [];

    headings.forEach((heading: any) => {
      const level = parseInt(heading.tagName.substring(1));
      const title = heading.textContent || heading.innerText;

      // Eliminar elementos del stack para ajustar la jerarquía
      while (stack.length > 0 && stack[stack.length - 1].level >= level) {
        stack.pop();
      }

      const currentItem = { title, level };
      stack.push(currentItem);
      this.indice.push(currentItem);
    });
  }

  scrollToTitle(title: string): void {
    const titles = document.querySelectorAll('h1, h2, h3, h4, h5, h6');
    titles.forEach((heading: any) => {
      if (heading.textContent?.trim() === title) {
        heading.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    });

  }

  createAnchorLink(title: string): string {
    // Reemplaza espacios y caracteres especiales para generar un ID válido
    return title.toLowerCase().replace(/[^a-z0-9]+/g, '-');
  }

  highlightCode(description: string): string {
    const tempElement = document.createElement('div');
    tempElement.innerHTML = description;

    const codeBlocks = tempElement.querySelectorAll('code');
    codeBlocks.forEach((codeBlock: HTMLElement) => {
      hljs.default.highlightBlock(codeBlock);
    });

    return tempElement.innerHTML;
  }

  save(): void {
    const articleVersion = this.article.wiki_article_version;
    articleVersion.wiki_article_id = this.article.id;
    articleVersion.created_by_id = this.userService.getCurrentUser().id;

    this.api.createWikiArticleVersion(articleVersion).subscribe((res: WikiArticleVersion) => {
      // Fem dues crides ja que la crida de CREATE no ha de fer que l'article apunti a ell, ja que pot ser que no vulguem que la nova versió sigui la versió actual sempre.
      this.api.updateWikiArticle(this.article.id, { wiki_article_version_id: res.id }).subscribe((res: WikiArticle) => {
        this.article = res;
      });
    });
  }

  deleteArticle () {
    if (!confirm('¿Estas seguro que quieres borrar este documento?')) return;
    this.api.deleteWikiArticle(this.article.id).subscribe((data: any) => {
      this.router.navigate(['/employees','wiki',this.article.wiki_node_id]);
    });
  }

  updateIsEditableAttribute () {
    this.api.updateWikiArticle(this.article.id, { is_editable: !this.article.is_editable }).subscribe((data: WikiArticle) => {
      this.article = data;
    });
  }

  showMoveWikiItemDialog() {
    const dialogRef = this.dialog.open(MoveWikiItemDialogComponent, {
      width: '500px'
    });
    dialogRef.afterClosed().subscribe(
      body => {
        this.api.updateWikiArticle(this.article.id, { wiki_node_id: body.id }).subscribe((data: any) => {
          this.fetchWikiArticle();
        });
      }
    );
  }


  fetchWikiArticle(): void {
    this.api.getWikiArticle(this.articleId as string).subscribe((res: WikiArticle) => {
      this.generateIndex(res.wiki_article_version.description);
      res.wiki_article_version.description = this.highlightCode(res.wiki_article_version.description);
      this.article = res;
    });
  }

  onEnvironmentChanged(env_id:string) {
    this.application_environment_id = env_id;
    this.fetchWikiGithubArticle();
  }

  toggleEdit() {
    this.editMode = !this.editMode;
    this.fetchWikiArticle();
  }

  private fetchWikiGithubArticle() {
    this.api.getWikiGithubArticle(this.github_file_name as string, { application_environment_id: this.application_environment_id }).subscribe(
      data => {
        this.generateIndex(data.content)
        let article:WikiArticle = {
          is_editable: 0,
          wiki_article_version: {
            title: data.metadata.name,
            description: this.highlightCode(data.content),
          }
        } as WikiArticle;

        this.article = article
      }
    );
  }

  private fetchEnvironments(node_id:string) {
    const github_filename:string[] = this.github_file_name?.split("/") as string[]
    const org = github_filename[0];
    const repo = github_filename[1];
    this.api.getWikiNodeApplicationEnvironments(+node_id, { organization: org, repository: repo }).subscribe(
      data => {
        this.environments = data
      }
    );
  }
}
