<h1 mat-dialog-title>Detalle del dia</h1>

<mat-dialog-content>
  <mat-accordion>
    @for (worklog of worklogs; track worklog; let i = $index) {
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{ worklog.started_at | date:'medium' }}
          </mat-panel-title>
          <mat-panel-description>
            {{ worklog.duration_in_seconds_real | secondsToStringTime }}
          </mat-panel-description>
        </mat-expansion-panel-header>
        <p>{{ worklog.description }}</p>
        <p>Creado el: <i>{{ worklog.created_at | date:'long' }}</i></p>
        @if (user_id!=null && user_id==worklog.user_id) {
          <button mat-flat-button color="warn" (click)="delete(i)">Eliminar registro</button>
        }
      </mat-expansion-panel>
    }
  </mat-accordion>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button color="accent" (click)="onNoClick()" cdkFocusInitial type="button">Cerrar</button>
</mat-dialog-actions>
