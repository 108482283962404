import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  localStorageKeyToken:string = "bisual-token";

  constructor() { }

  handle(token:string) {
    this.set(token);
  }

  loggedIn() {
    return this.isValid();
  }

  private set(token:string) {
    localStorage.setItem(this.localStorageKeyToken, token);
  }

  get() {
    return localStorage.getItem(this.localStorageKeyToken);
  }

  public remove() {
    localStorage.removeItem(this.localStorageKeyToken);
  }

  private isValid() {
    const token = this.get();
    if(token) {
      const payload = this.payload(token);
      if(payload) {
        return payload.iss === `${environment.laravel_url}/api/v1/auth/login` || payload.iss === `${environment.laravel_url}/api/v1/auth/refresh`;
      }
    }
    return false;
  }

  private payload(token:string) {
    const payload = token.split('.')[1];
    return this.decode(payload);
  }

  private decode(payload:any) {
    return JSON.parse(atob(payload));
  }
}
