<div id="content">
  <div class="page-title">
    <h1>Equipos de proyecto</h1>
    <button mat-flat-button color="accent" (click)="add()">
      <mat-icon>add</mat-icon>
      Crear nuevo equipo
    </button>
  </div>

  <div class="bisual-table">
    <div class="bisual-table-container">
      <table mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>Equipo</th>
          <td mat-cell *matCellDef="let pt">{{ pt.name }}</td>
        </ng-container>

        <ng-container matColumnDef="users">
          <th mat-header-cell *matHeaderCellDef>Equipo</th>
          <td mat-cell *matCellDef="let pt">{{ pt.users.length + ' usuarios' }}</td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let pt; let i = index;">
            <button mat-icon-button [matMenuTriggerFor]="menu">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="edit(pt)">
                <mat-icon>edit</mat-icon>
                <span>Editar</span>
              </button>
              <button mat-menu-item (click)="delete(pt)">
                <mat-icon>delete</mat-icon>
                <span>Eliminar</span>
              </button>
            </mat-menu>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
  </div>
</div>
