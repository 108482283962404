<div id="content">
  <div class="page-title">
    <h1>{{ titleDepartment }}</h1>
    <button mat-flat-button color="accent" (click)="addCategory()" [disabled]="!permissions.validateDepartment(['direction', 'rrhh'])">
      <!--[disabled]="!permissions.validateDepartment(['direction', 'rrhh'])-->
      <mat-icon style="margin-right: 10px">person_add</mat-icon>
      Añadir Categoria
    </button>
    <button mat-flat-button color="accent" type="submit" (click)="save()" [disabled]="!departmentForm.valid" [disabled]="!permissions.validateDepartment(['direction', 'rrhh'])">
      <!--[disabled]="!permissions.validateDepartment(['direction', 'rrhh'])-->
      <mat-icon style="margin-right: 10px">save</mat-icon>
      Guardar
    </button>

  </div>

  <form class="card mb-5" [formGroup]="departmentForm">
    <div class="container" style="margin-top: 20px">
      <div class="row">
        <div class="col-12 col-lg-4">
          <mat-form-field appearance="outline">
            <mat-label>Nombre</mat-label>
            <input type="text" matInput formControlName="name">
          </mat-form-field>
        </div>
        <div class="col-12 col-lg-8">
          <mat-form-field appearance="outline">
            <mat-label>Descripción</mat-label>
              <input type="text" matInput formControlName="description">
          </mat-form-field>
        </div>
      </div>
    </div>
  </form>


  <div class="bisual-table">
    <form class="card paginator" [formGroup]="departmentcategoriesForm">
      <mat-paginator
        [length]="length"
        [pageSize]="pageSize"
        [pageSizeOptions]="pageSizeOptions"
        [pageIndex]="pageIndex"
        (page)="changePage($event)"
        style="margin-right: auto;">
      </mat-paginator>
      <mat-form-field>
        <input matInput type="text" formControlName="search">
        <button matSuffix mat-icon-button aria-label="Clear">
          <mat-icon>search</mat-icon>
        </button>
      </mat-form-field>
    </form>



    <div class="bisual-table-container">
      <table mat-table [dataSource]="dataSourceCategories">

        <ng-container matColumnDef="image">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let departmentCategories">
            <mat-icon>supervised_user_circle</mat-icon>
          </td>
        </ng-container>

        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>Nombre</th>
          <td mat-cell *matCellDef="let departmentCategories">{{departmentCategories.name}}</td>

        </ng-container>

        <ng-container matColumnDef="description">
          <th mat-header-cell *matHeaderCellDef>Descripción</th>
          <td mat-cell *matCellDef="let departmentCategories">{{departmentCategories.description}}</td>
        </ng-container>

        <ng-container matColumnDef="min_salary">
          <th mat-header-cell *matHeaderCellDef>Salario Mínimo</th>
          <td mat-cell *matCellDef="let departmentCategories">{{departmentCategories.min_salary | currency:'EUR'}}</td>
        </ng-container>

        <ng-container matColumnDef="max_salary">
          <th mat-header-cell *matHeaderCellDef>Salario Máximo</th>
          <td mat-cell *matCellDef="let departmentCategories">{{departmentCategories.max_salary | currency:'EUR'}}</td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let departmentCategories; let i = index;">
            <button mat-icon-button [matMenuTriggerFor]="menu">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              @if (router.url.includes('/rrhh/departments')) {
                <button mat-menu-item [routerLink]="[ 'department-categories', departmentCategories.id ]">
                  <mat-icon matPrefix>login</mat-icon>
                  Ver detalles...
                </button>
              }
              @if (router.url.includes('/rrhh/departments')) {
                <button mat-menu-item (click)="deleteDepartmentCategory(i)">
                  <mat-icon matPrefix>delete</mat-icon>
                  Borrar Categoria
                </button>
              }
            </mat-menu>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
  </div>
</div>
