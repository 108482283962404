<div id="content">
  <nav mat-tab-nav-bar [tabPanel]="tabPanel">
    @for (link of navLinks; track link) {
      <a mat-tab-link
        [routerLink]="link.link"
        routerLinkActive #rla="routerLinkActive"
        [active]="rla.isActive"
      [queryParams]="queryParams">{{ link.label }}</a>
    }
  </nav>
  <mat-tab-nav-panel #tabPanel></mat-tab-nav-panel>
  <router-outlet></router-outlet>
</div>
