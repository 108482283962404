import { Component, HostListener, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from 'src/app/models/user.model';
import { ApiService } from 'src/app/services/api.service';
import { PermissionsService } from 'src/app/services/permissions.service';
import { RegexService } from 'src/app/services/regex.service';
import { TokenService } from 'src/app/services/token.service';
import * as Countries from 'src/assets/json/countries.json';
import { environment } from 'src/environments/environment';
import { AddUserDialogComponent } from '../../users/add-user-dialog/add-user-dialog.component';
import { CompanyNotificationChannel } from 'src/app/models/company_notification_channel.model';
import { AddCompanyNotificationChannelDialogComponent } from '../add-company-notification-channel-dialog/add-company-notification-channel-dialog.component';

@Component({
  selector: 'app-company-detail',
  templateUrl: './company-detail.component.html',
  styleUrls: ['./company-detail.component.css']
})
export class CompanyDetailComponent implements OnInit {

  company_id:string = null as any;
  countries:any[] = (Countries as any).default;

  companyForm:UntypedFormGroup;

  payment_method_url:string = environment.laravel_url;
  token:string = "";

  usersDataSource:MatTableDataSource<User> = new MatTableDataSource();
  usersDisplayedColumns: string[] = ["name", "email", "role", "actions"];
  cncDataSource:MatTableDataSource<CompanyNotificationChannel> = new MatTableDataSource();
  cncDisplayedColumns: string[] = ["type", "route", "created_at", "actions"];

  constructor(private activatedRoute:ActivatedRoute,
              private api:ApiService,
              private fb:UntypedFormBuilder,
              private router:Router,
              public dialog: MatDialog,
              private tokenService:TokenService,
              private regex:RegexService,
              public permissions:PermissionsService) {

    const regexPatterns = this.regex.getRegex();

    this.companyForm = this.fb.group({
      email: ['', Validators.compose([Validators.required, Validators.email])],
      phone: ['', Validators.compose([Validators.required, Validators.pattern(regexPatterns['phone'])])],
      fact_name: ['', Validators.compose([Validators.required])],
      fact_address: ['', Validators.compose([Validators.required])],
      fact_city: ['', Validators.compose([Validators.required])],
      fact_zipcode: ['', Validators.compose([Validators.required, Validators.minLength(5), Validators.maxLength(5)])],
      fact_county: ['', Validators.compose([Validators.required])],
      fact_country: ['', Validators.compose([Validators.required])],
      fact_country_alpha2: ['', Validators.compose([Validators.required])],
      vat: ['', Validators.compose([Validators.required])]
    });

  }

  ngOnInit(): void {
    this.token = this.tokenService.get() as string;
    this.company_id = this.activatedRoute.snapshot.paramMap.get('id') as string;
    this.fetchCompany();
    this.listenCountryChanges();
  }

  save() {
    this.api.updateCompany(this.company_id, this.companyForm.value).subscribe(
      data => {

      }
    );
  }

  delete() {
    if(confirm("¿Estás seguro que quieres eliminar esta empresa?")) {
      this.api.deleteCompany(+this.company_id).subscribe(
        data => {
          this.router.navigate(['/employees', 'direction', 'users-management', 'companies']);
        }
      );
    }
  }

  addUser() {
    const dialogRef = this.dialog.open(AddUserDialogComponent, {
      width: '750px',
      data: {
        company_id: this.company_id,
      }
    });

    dialogRef.afterClosed().subscribe(
      (user:User) => {
        if(user != null) {
          this.fetchCompany();
        }
      }
    );
  }

  addCnc() {
    const dialogRef = this.dialog.open(AddCompanyNotificationChannelDialogComponent, {
      data: {
        company_id: this.company_id
      },
      width: '600px'
    });

    dialogRef.afterClosed().subscribe(
      data => {
        if(data) {
          this.fetchCompany();
        }
      }
    );
  }

  editCnc(cnc:CompanyNotificationChannel) {
    const dialogRef = this.dialog.open(AddCompanyNotificationChannelDialogComponent, {
      data: {
        company_notification_channel: cnc
      },
      width: '600px'
    });

    dialogRef.afterClosed().subscribe(
      data => {
        if(data) {
          this.fetchCompany();
        }
      }
    );
  }

  deleteCnc(cnc:CompanyNotificationChannel) {
    if(confirm("¿Estás seguro que quieres eliminar este canal de comunicación con el cliente? ")) {
      this.api.deleteCompanyNotificationChannel(cnc.id.toString()).subscribe(
        data => {
          this.fetchCompany();
        }
      )
    }
  }

  @HostListener('window:message',['$event'])
  onIframeMessage(e:any) {
    if (e.origin==this.payment_method_url) {
      if(e.data.status=='success') {
        const self = this;
        setTimeout(() => {

        }, 1000);
      }
      else if(e.data.status=='error') {
        const self = this;
        setTimeout(() => {

        }, 1000);
      }
    }
  }

  private fetchCompany() {
    const params:any = { with: 'users,company_notification_channels' };
    this.api.getCompany(this.company_id, params).subscribe(
      data => {
        this.companyForm.patchValue(data);

        this.usersDataSource.data = data.users;
        this.cncDataSource.data = data.company_notification_channels;
      }
    );
  }

  private listenCountryChanges() {
    this.companyForm.controls['fact_country'].valueChanges.subscribe(
      data => {
        for(let c of this.countries) {
          if(c.name==data) {
            this.companyForm.patchValue({ fact_country_alpha2: c.alpha_2 });
            break;
          }
        }
      }
    );
  }

}
