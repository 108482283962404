import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { MeetingRecord } from 'src/app/models/meeting-record.interface';
import { Project } from 'src/app/models/project.model';
import { ApiService } from 'src/app/services/api.service';
import { UserService } from 'src/app/services/user.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-project-meeting-records',
  templateUrl: './project-meeting-records.component.html',
  styleUrls: ['./project-meeting-records.component.css']
})
export class ProjectMeetingRecordsComponent implements OnInit {
  project_id:string = null as any;

  displayedColumns: string[] = ['title', 'date', 'assistants_client', 'assistants_provider', 'actions'];
  dataSource:MatTableDataSource<MeetingRecord> = new MatTableDataSource<MeetingRecord>();

  // dades per el paginator
  length:number = 0;
  pageSize:number = 10;
  pageSizeOptions:number[] = [5,10,20,50];
  pageIndex:number = 1;

  filterForm:UntypedFormGroup;
  private formPersistence:any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private api: ApiService,
    private fb:UntypedFormBuilder,
    private userService:UserService,
    private router:Router,
    private utils:UtilsService) {
      this.filterForm = this.fb.group({
        search: [this.activatedRoute.snapshot.queryParamMap.get('search')!=null ? this.activatedRoute.snapshot.queryParamMap.get('search') : '', Validators.compose([Validators.minLength(3)])],
        per_page: [this.activatedRoute.snapshot.queryParamMap.get('per_page')!=null ? +(this.activatedRoute.snapshot.queryParamMap.get('per_page') as any) : this.pageSize, Validators.required],
        page: [this.activatedRoute.snapshot.queryParamMap.get('page')!=null ? +(this.activatedRoute.snapshot.queryParamMap.get('page') as any) : this.pageIndex, Validators.required],
        total: [this.activatedRoute.snapshot.queryParamMap.get('total')!=null ? +(this.activatedRoute.snapshot.queryParamMap.get('total') as any) : this.length]
      });
    }

  ngOnInit(): void {
    this.project_id = this.activatedRoute.parent?.snapshot.parent?.paramMap.get('id') as string;
    this.filterForm.patchValue({ meetingable_id: this.project_id });
    this.initFilterFormListener();
    this.listenQueryParameters();
  }

  changePage(event:PageEvent) {
    this.filterForm.patchValue({
      page: event.pageIndex,
      per_page: event.pageSize
    });
  }

  private fetchMeetingRecords() {
    this.api.getProjectMeetingRecords({...this.filterForm.value, meetingable_id: this.project_id, meetingable_type: 'Project', order_by: 'date', order_by_direction: 'desc'}).subscribe(
      data => {
        this.dataSource.data = data.data;

        this.length = +data.meta.total;
        this.pageIndex = +data.meta.current_page-1;
        this.pageSize = +data.meta.per_page;
      }
    );
  }

  private initFilterFormListener() {
    let userRole = this.userService.getCurrentUser().role.slug;
    this.filterForm.valueChanges.subscribe(
      data => {
        data.per_page = +data.per_page;
        if(this.formPersistence==null || JSON.stringify(this.formPersistence)!=JSON.stringify(data)) {
          if(this.formPersistence.per_page!=data.per_page) data.page = 0;
          this.router.navigate(['/employees', 'development', 'projects', this.project_id, 'meeting-records'], { queryParams: data });
        }
      }
    );
  }

  private listenQueryParameters() {
    this.activatedRoute.queryParams.subscribe(
      params => {
        if(JSON.stringify(params)!==JSON.stringify(this.filterForm.value)) { //si no ve de filtre s'ha de setejar el form
          let params_temp = this.utils.cloneObj(params); //params es read_only
          Object.keys(params_temp).forEach(param_key => {
            if(params_temp[param_key]!=null && params_temp[param_key]!="" && !isNaN(+params_temp[param_key])) params_temp[param_key] = +params_temp[param_key]; // si es numero, el transformem
          });
          this.filterForm.patchValue(params_temp, { emitEvent: false });
        }
        this.formPersistence = params;
        this.fetchMeetingRecords();
      }
    );
  }
}
