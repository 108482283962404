import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-blogposts',
  templateUrl: './blogposts.component.html',
  styleUrls: ['./blogposts.component.css']
})
export class BlogpostsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
