import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Project } from 'src/app/models/project.model';
import { User } from 'src/app/models/user.model';
import { ApiService } from 'src/app/services/api.service';
import { UtilsService } from 'src/app/services/utils.service';
import { ProjectManageMembersDialogComponent } from './project-manage-members-dialog/project-manage-members-dialog.component';
import { ProjectReportDialogComponent } from './project-report-dialog/project-report-dialog.component';
import { ProjectDistributionTimeDialogComponent } from './project-distribution-time-dialog/project-distribution-time-dialog.component';

@Component({
  selector: 'app-project-resume',
  templateUrl: './project-resume.component.html',
  styleUrls: ['./project-resume.component.css']
})
export class ProjectResumeComponent implements OnInit {

  filterForm:FormGroup;
  private formPersistence:any;

  project_id:string = null as any;
  project: Project = null as any;
  periods:string[];
  users: User[] = [];
  selectedUsers:Number[] = [];

  private _chartContainer:ElementRef = null as any;
  get chartContainer() {return this._chartContainer;};
  @ViewChild('chartContainer') set chartContainer(data:ElementRef) {
    this._chartContainer = data;
    if(this._chartContainer!=null) {
        this.mainChartView[0] = this._chartContainer.nativeElement.offsetWidth - 70;
    }
  }
  showYAxisLabel: boolean = true;
  showXAxisLabel: boolean = true;
  xWeekAxisLabel: string = 'Semana';
  xDayAxisLabel: string = 'Día';
  yAxisLabel: string = 'Horas';
  mainChartView:[number,number] = [undefined as any, 350];
  pieChartsLegendPosition:any = "below";
  colorScheme = this.utils.getChartsColorScheme();
  dailyChartData:any;
  weeklyChartData:any;
  totalWorked:number = 0;
  forecastedWorking:number = 0;
  pendingWorkingTime:number = 0;


  constructor(private dialog:MatDialog,
    private activatedRoute:ActivatedRoute,
    private utils:UtilsService,
    private fb:FormBuilder,
    private router:Router,
    private api:ApiService) {
      this.filterForm = this.fb.group({
        period: [this.activatedRoute.snapshot.queryParamMap.get('period')!=null ? (this.activatedRoute.snapshot.queryParamMap.get('period') as any) : this.getCurrentPeriod()],
      });

      this.periods = [this.filterForm.value['period']];

  }

  ngOnInit(): void {
    this.project_id = this.activatedRoute.parent?.snapshot.paramMap.get('id') as string;
    this.fetchProject();

    this.fetchWorkers();
    this.fetchPeriods();
    this.initFilterFormListener();
    this.listenQueryParameters();
  }

  openTimeDistribution() {
    const dialogRef = this.dialog.open(ProjectDistributionTimeDialogComponent, {
      width: '800px',
      data: {
        'project_id': this.project_id
      }
    })
  }

  openProjectReport() {
    const dialogRef = this.dialog.open(ProjectReportDialogComponent, {
      width: '650px',
      data: {
        'project' : this.project
      }
    });

    dialogRef.afterClosed().toPromise().then(
      data => {
      }
    );
  }

  openManageMembersDialog() {
    const dialogRef = this.dialog.open(ProjectManageMembersDialogComponent, {
      width: '650px',
      data: {
        'project' : this.project
      }
    });

    dialogRef.afterClosed().toPromise().then(
      data => {
        this.fetchWorkers();
      }
    );
  }

  fetchWorkers() {
    const params: any = { role_id: 1 };
    this.api.getUsers(params).subscribe((users) => {
      this.users = users.data;
    });
  }

  redirectJira() {
    window.open(this.project.jira_url, '_blank');
  }

  private initFilterFormListener() {
    this.filterForm.valueChanges.subscribe(
      data => {
        if(this.formPersistence==null || JSON.stringify(this.formPersistence)!=JSON.stringify(data)) {
          this.router.navigate(['/employees', 'development', 'projects', this.project_id, 'resume'], { queryParams: data });
        }
      }
    );
  }

  private listenQueryParameters() {
    this.activatedRoute.queryParams.subscribe(
      params => {
        if(JSON.stringify(params)!==JSON.stringify(this.filterForm.value)) { //si no ve de filtre s'ha de setejar el form
          let params_temp = this.utils.cloneObj(params); //params es read_only
          Object.keys(params_temp).forEach(param_key => {
            if(params_temp[param_key]!=null && params_temp[param_key]!="" && !isNaN(+params_temp[param_key])) params_temp[param_key] = +params_temp[param_key]; // si es numero, el transformem
          });
          this.filterForm.patchValue(params_temp, { emitEvent: false });
        }
        this.formPersistence = params;
        this.fetchChartData();
      }
    );
  }

  private fetchChartData() {
    if(this.filterForm.value['period']!=null && this.filterForm.value['period']!='' && this.filterForm.value['period']!='') {
      let params:any = {...this.filterForm.value};
      params.period = params.period.replace('/', '_');
      // TODO: Arreglar
      // this.api.getProjectWorkingTimeChartData(this.project_id, params).subscribe(
      //   data => {
      //     this.dailyChartData = data.daily;
      //     this.weeklyChartData = data.weekly;
      //     this.totalWorked = data.summary.resume.done;
      //     this.forecastedWorking = data.summary.resume.expected;
      //     this.pendingWorkingTime = Math.min(this.forecastedWorking - this.totalWorked, 0);
      //   }
      // );
    }
  }

  private fetchProject() {
    this.api.getProject(this.project_id).subscribe(
      data => {
        this.project = data;
      }
    );
  }

  private getCurrentPeriod() {
    return new Date().getFullYear() + "/" + (new Date().getMonth()+1);
  }

  private fetchPeriods() {
    this.api.getBillingPeriods().subscribe(
      data => {
        this.periods = data;
      }
    );
  }

}
