import { Injectable } from '@angular/core';
import { BehaviorSubject, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ApiService } from './api.service';
import { ClockRegisterService } from './clock-register.service';
import { DepartmentService } from './department.service';
import { TokenService } from './token.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private loggedIn = new BehaviorSubject<boolean>(false);

  constructor(private token:TokenService,
              private api:ApiService,
              private userService:UserService,
              private departmentService:DepartmentService,
              private clock:ClockRegisterService) { }

  login(username: string, password: string, keepAlive:boolean = true) {
    return this.api.login(username, password).pipe(
      map(response => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        if(response.user && response.access_token) {
          this.onLoginSuccess(response);
        }

        return response;
      })
    );
  }

  logout() {
    // remove user from local storage to log user out
    this.token.remove();
    this.userService.deleteUser();
    this.clock.reset();
    this.changeAuthStatus(false);
  }

  refreshToken() {
    return this.api.refreshToken().pipe(
      map(response => {
        this.onLoginSuccess(response);
        return true;
      }),
      catchError(err => {
        return of(false);
      })
    );
  }

  isLoggedIn(): boolean {
    return this.loggedIn.value;
  }

  private changeAuthStatus(value:boolean) {
    this.loggedIn.next(value);
  }

  private onLoginSuccess(response:any) {
    this.token.handle(response.access_token);
    this.userService.setUser(response.user);
    this.clock.init();
    this.changeAuthStatus(true);
  }
}
