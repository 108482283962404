import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router, ActivatedRoute } from '@angular/router';
import { User } from 'src/app/models/user.model';
import { VacationRequest } from 'src/app/models/vacation_request';
import { ApiService } from 'src/app/services/api.service';
import { PermissionsService } from 'src/app/services/permissions.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-vacation-request',
  templateUrl: './vacation-requests.component.html',
  styleUrls: ['./vacation-requests.component.css'],
})
export class VacationRequestsComponent implements OnInit {
  displayedColumns: string[] = [
    'id',
    'nombre',
    'status',
    'dias solicitados',
    'actions',
  ];
  dataSource: MatTableDataSource<VacationRequest> =
    new MatTableDataSource<VacationRequest>();

  // dades per el paginator
  length: number = 0;
  pageSize: number = 10;
  pageSizeOptions: number[] = [5, 10, 20, 50];
  pageIndex: number = 0;

  filterForm: UntypedFormGroup;
  private formPersistence: any;
  timer: number = -1;


  constructor(
    private api: ApiService,
    private fb: UntypedFormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public permissions: PermissionsService,
    private utils: UtilsService,
    public dialog: MatDialog
  ) {
    this.filterForm = this.fb.group({
      search: [
        this.activatedRoute.snapshot.queryParamMap.get('search') != null
          ? this.activatedRoute.snapshot.queryParamMap.get('search')
          : '',
        Validators.compose([Validators.minLength(3)]),
      ],
      per_page: [
        this.activatedRoute.snapshot.queryParamMap.get('per_page') != null
          ? +(this.activatedRoute.snapshot.queryParamMap.get(
              'per_page'
            ) as any)
          : this.pageSize,
        Validators.required,
      ],
      page: [
        this.activatedRoute.snapshot.queryParamMap.get('page') != null
          ? +(this.activatedRoute.snapshot.queryParamMap.get(
              'page'
            ) as any)
          : this.pageIndex,
        Validators.required,
      ],
      total: [
        this.activatedRoute.snapshot.queryParamMap.get('total') != null
          ? +(this.activatedRoute.snapshot.queryParamMap.get('total') as any)
          : this.length,
      ],
      status: [
        this.activatedRoute.snapshot.queryParamMap.get('status') != null
          ? (this.activatedRoute.snapshot.queryParamMap.get('status') as any)
          : 'pending',
      ],
    });
  }

  ngOnInit(): void {
    this.fetchVacationRequest();
    this.initFilterFormListener();
    this.listenQueryParameters();
  }

  changePage(event:PageEvent) {
    this.filterForm.patchValue({
      page: event.pageIndex,
      per_page: event.pageSize
    });
  }

  private fetchVacationRequest() {
    this.api.getVacationRequestsPaged({...this.filterForm.value, ...{ with: 'vacation_request_periods,user' }}).subscribe(
    data => {
        this.dataSource.data = data.data;

        this.length = +data.meta.total;
        this.pageIndex = +data.meta.current_page-1;
        this.pageSize = +data.meta.per_page;

        if (this.filterForm.value.search) {
          if (
            this.filterForm.value.page + 1 >
            Math.ceil(this.length / this.pageSize)
          ) {
            this.filterForm.patchValue({
              page: 0,
            });
          }
        }

      }
    );
  }

  private initFilterFormListener() {
    this.filterForm.valueChanges.subscribe(
      data => {
        data.per_page = +data.per_page;
        if(this.formPersistence==null || JSON.stringify(this.formPersistence)!=JSON.stringify(data)) {
          if(this.formPersistence.per_page!=data.per_page) data.page = 0;
          this.router.navigate(['/employees/rrhh/absences-management/vacation-requests'], { queryParams: data });
        }
      }
    );
  }

  private listenQueryParameters() {
    this.activatedRoute.queryParams.subscribe(
      params => {
        if(JSON.stringify(params)!==JSON.stringify(this.filterForm.value)) { //si no ve de filtre s'ha de setejar el form
          let params_temp = this.utils.cloneObj(params); //params es read_only
          Object.keys(params_temp).forEach(param_key => {
            if(params_temp[param_key]!=null && params_temp[param_key]!="" && !isNaN(+params_temp[param_key])) params_temp[param_key] = +params_temp[param_key]; // si es numero, el transformem
          });
          this.filterForm.patchValue(params_temp, { emitEvent: false });
        }
        this.formPersistence = params;
        if(this.timer !== -1 && this.filterForm.value.search){
          clearTimeout(this.timer);
          this.timer = window.setTimeout(() =>{
            this.fetchVacationRequest();
          },500);
        }else{
          clearTimeout(this.timer);
          this.fetchVacationRequest();
          this.timer = 0;
        }
      }
    );
  }

  delete(id: number) {
    this.api.deleteVacationRequest(id.toString()).subscribe(data => {
      this.ngOnInit();
    });
  }

}
