<div mat-dialog-title>
  <h1>
    <span>{{ data.project_task.code }}</span>
    {{ data.project_task.title }}
  </h1>

  <button mat-stroked-button (click)="openProjectTeamsDialog()">
    <mat-icon>add</mat-icon>
    Añadir equipo de proyecto del cliente
  </button>
</div>

<mat-dialog-content>
  @if(is_initiated) {
    <div id="configuration" class="container mb-3">
      <div class="row">
        <div class="col-12">
          <mat-checkbox [(ngModel)]="blockWtcsAndPropagate">Bloquear inputs de WTCs y propagar las proporciones desde "DEV Plain"</mat-checkbox>
        </div>
      </div>
      @if(blockWtcsAndPropagate) {
        <div class="row">
          @for(multiplicador of multiplicadores | keyvalue; track multiplicador;) {
            <div class="col-12 col-lg-3">
              <mat-checkbox [(ngModel)]="multiplicador.value.checked" color="accent" [matTooltip]="multiplicador.value.description" [disabled]="multiplicador.value.disabled" >
                {{ multiplicador.value.name }}
              </mat-checkbox>
              <mat-form-field appearance="outline" class="nobottom">
                <input matInput [(ngModel)]="multiplicador.value.prcnt" min="0" step="5" type="number">
                <span matTextSuffix>%</span>
              </mat-form-field>
            </div>
          }
        </div>
      }
    </div>
    <hr>
    @if (project_task_distribution!=null) {
      <table>
        <thead>
          <tr>
            <th></th>
            @if(blockWtcsAndPropagate) {
              <th [matTooltip]="'Introduce el tiempo que se tardaría en realizar la tarea sin tiempo de descanso, con rendimiento al 100%, siendo un Mid/Senior, diseño gráfico, planteamiento, sin ir al baño, sin testing, sin documentación, etc. Únicamente tiempo de programación.'">
                DEV Plain
              </th>
            }
            @for(col of project_task_distribution.general.categories; track col;) {
              <th [matTooltip]="col.title">
                @if(blockWtcsAndPropagate && col.budgetable_slug != null && wtcsEnabled[col.budgetable_slug] != null) {
                  <mat-checkbox [(ngModel)]="wtcsEnabled[col.budgetable_slug]">{{ col.title }}</mat-checkbox>
                }
                @else {
                  {{ col.title }}
                }
              </th>
            }
          </tr>
          <tr>
            <td></td>
            @if(blockWtcsAndPropagate) {
              <td></td>
            }
            @for(col of project_task_distribution.general.categories; track col;) {
              <td>
                @if(col.budgetable_type==="App\\Models\\WorkingTimeCategory" && col.budgetable_slug != null && getDefaultTaskWtcsPrcnts(col.budgetable_slug) != null) {
                  {{ getDefaultTaskWtcsPrcnts(col.budgetable_slug)/100 | percent }}
                }
              </td>
            }
          </tr>
        </thead>
        <tbody>
          <!-- General -->
          <tr>
            <th (dblclick)="autofillGeneral()" [matTooltip]="'Haz doble click para compensar las horas a general a partir del detalle.'">General</th>
            @if(blockWtcsAndPropagate) {
              <td>
                <mat-form-field appearance="outline" class="nobottom">
                  <input matInput [(ngModel)]="plainHoursPerBoard.general" type="number" step="0.5" min="0" (keyup.enter)="propagateDevPlainCategories('general')" />
                  <button mat-icon-button matSuffix (click)="propagateDevPlainCategories('general')" [matTooltip]="'Propagar los tiempos proporcionalmente.'">
                    <mat-icon>arrow_right_alt</mat-icon>
                  </button>
                </mat-form-field>
              </td>
            }
            @for(col of project_task_distribution.general.categories; track col; let i = $index) {
              <td>
                @if(col.editable) {
                  <mat-form-field appearance="outline" class="nobottom">
                    <input matInput [ngModel]="col.time_to_do" (ngModelChange)="onGeneralFieldChange(i, $event)" type="number" step="0.5" min="0" [readonly]="blockWtcsAndPropagate && col.budgetable_type!=='App\\Models\\ProjectTeam'" />
                    <span matTextSuffix>h</span>
                  </mat-form-field>
                }
                @else {
                  {{ col.time_to_do | hoursToHourString }}
                }
              </td>
            }
          </tr>
          <!-- Extra row -->
          <tr>
            <td [colSpan]="1 + project_task_distribution.general.categories.length + (blockWtcsAndPropagate ? 1 : 0)">
              <hr>
            </td>
          </tr>
          <!-- Boards -->
          @for(row of project_task_distribution.boards; track row; let i = $index) {
            <tr>
              <td (dblclick)="autofillBoardRow(i)" [matTooltip]="'Haz doble click para rellenar este tablero con las horas restantes.'" [ngClass]="{ future: row.status === 'not_started', active: row.status === 'active' }">{{ row.title }}</td>
              @if(blockWtcsAndPropagate) {
                <td>
                  <mat-form-field appearance="outline" class="nobottom">
                    <input matInput [(ngModel)]="plainHoursPerBoard.boards[i]" type="number" step="0.5" min="0" (keyup.enter)="propagateDevPlainCategories('boards', i)" />
                    <button mat-icon-button matSuffix (click)="propagateDevPlainCategories('boards', i)" [matTooltip]="'Propagar los tiempos proporcionalmente.'">
                      <mat-icon>arrow_right_alt</mat-icon>
                    </button>
                  </mat-form-field>
                </td>
              }
              @for(col of row.categories; track col; let j = $index) {
                <td>
                  @if(col.editable) {
                    <mat-form-field appearance="outline" class="nobottom" (dblclick)="autofillBoardCell(i,j)">
                      <input matInput [ngModel]="col.time_to_do" (ngModelChange)="onBoardFieldChange(i,j,$event)" type="number" step="0.5" min="0" [max]="project_task_distribution.general.categories[j].time_to_do" [readonly]="blockWtcsAndPropagate && col.budgetable_type!=='App\\Models\\ProjectTeam'" />
                      <span matTextSuffix>h</span>
                    </mat-form-field>
                  }
                  @else {
                    {{ col.time_to_do | hoursToHourString }}
                  }
                </td>
              }
            </tr>
          }
        </tbody>
      </table>
    }
    <p><i>Haz doble click sobre el nombre del tablero o en "General" para compensar las horas restantes para estabilizar al 100% de las horas.</i></p>
  }
  @else {
    <p>Cargando...</p>
  }
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-flat-button (click)="addBoard()" style="margin-right: auto;">Añadir board +</button>
  <button mat-flat-button (click)="close()">Cancelar</button>
  <button mat-flat-button color="accent" (click)="submit()">Guardar cambios</button>
</mat-dialog-actions>
