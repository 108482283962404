<h1 mat-dialog-title>Planificación de horas de no desarrollo</h1>

<mat-dialog-content>

  @if (dataSource.data.length > 0) {
    <table mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="day" sticky>
        <th mat-header-cell *matHeaderCellDef>Día</th>
        <td mat-cell *matCellDef="let row;">{{ row.day | date:'shortDate' }}</td>
        <th mat-footer-cell *matFooterCellDef>Totales</th>
      </ng-container>
      <ng-container matColumnDef="hours_available" sticky>
        <th mat-header-cell *matHeaderCellDef>Horas disponibles</th>
        <td mat-cell *matCellDef="let row;">{{ row.hours_available | number:'1.0-2' }}h</td>
        <th mat-footer-cell *matFooterCellDef></th>
      </ng-container>
      <ng-container matColumnDef="negocio">
        <th mat-header-cell *matHeaderCellDef>Negocio</th>
        <td mat-cell *matCellDef="let row; let i = index;">
          <mat-form-field appearance="outline">
            <input matInput [ngModel]="row.negocio" (ngModelChange)="onNegocioChange(i, $event)" />
          </mat-form-field>
        </td>
        <th mat-footer-cell *matFooterCellDef>{{ totals.negocio }}h</th>
      </ng-container>
      <ng-container matColumnDef="comercial">
        <th mat-header-cell *matHeaderCellDef>Comercial</th>
        <td mat-cell *matCellDef="let row; let i = index;">
          <mat-form-field appearance="outline">
            <input matInput [ngModel]="row.comercial" (ngModelChange)="onComercialChange(i, $event)" />
          </mat-form-field>
        </td>
        <th mat-footer-cell *matFooterCellDef>{{ totals.comercial }}h</th>
      </ng-container>
      <ng-container matColumnDef="formacion">
        <th mat-header-cell *matHeaderCellDef>Formación</th>
        <td mat-cell *matCellDef="let row; let i = index;">
          <mat-form-field appearance="outline">
            <input matInput [ngModel]="row.formacion" (ngModelChange)="onFormacionChange(i, $event)" />
          </mat-form-field>
        </td>
        <th mat-footer-cell *matFooterCellDef>{{ totals.formacion }}h</th>
      </ng-container>
      <ng-container matColumnDef="daily">
        <th mat-header-cell *matHeaderCellDef>Daily</th>
        <td mat-cell *matCellDef="let row; let i = index;">
          <mat-form-field appearance="outline">
            <input matInput [ngModel]="row.daily" (ngModelChange)="onDailyChange(i, $event)" />
          </mat-form-field>
        </td>
        <th mat-footer-cell *matFooterCellDef>{{ totals.daily }}h</th>
      </ng-container>
      <ng-container matColumnDef="otros">
        <th mat-header-cell *matHeaderCellDef>Otros</th>
        <td mat-cell *matCellDef="let row; let i = index;">
          <mat-form-field appearance="outline">
            <input matInput [ngModel]="row.otros" (ngModelChange)="onOtrosChange(i, $event)" />
          </mat-form-field>
        </td>
        <th mat-footer-cell *matFooterCellDef>{{ totals.otros }}h</th>
      </ng-container>
      <ng-container matColumnDef="total" sticky>
        <th mat-header-cell *matHeaderCellDef>Totales</th>
        <th mat-cell *matCellDef="let row;">{{ row.non_productive_hours }}h</th>
        <th mat-footer-cell *matFooterCellDef>{{ totals.non_productive_hours }}h</th>
      </ng-container>
      <ng-container matColumnDef="actions" sticky>
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let row; let i = index;">
          <button mat-icon-button color="accent" (click)="bulkCopy(i)">
            <mat-icon>arrow_downward</mat-icon>
          </button>
        </td>
        <th mat-footer-cell *matFooterCellDef></th>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; let i = index; columns: displayedColumns;" [ngClass]="{'is-weekend': row.is_weekend, 'less-time': row.non_working_times.length>0 || row.holidays.length > 0 }"></tr>
      <tr mat-footer-row *matFooterRowDef="displayedColumns; sticky: true"></tr>
    </table>
  }


</mat-dialog-content>

<mat-dialog-actions align="end">
  @if (currentVal != null) {
    <p id="currentVal">
      <b>Horas actuales:</b> <span>{{ currentVal | number:'1.0-2' }}h</span>
    </p>
  }
  <button mat-button color="accent" (click)="close()" cdkFocusInitial type="button">Cerrar</button>
  <button mat-flat-button color="accent" (click)="use()" cdkFocusInitial type="button">Usar horas</button>
</mat-dialog-actions>
