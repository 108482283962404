import { Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { PermissionsService } from 'src/app/services/permissions.service';
import { Department } from 'src/app/models/department.model';
import { UtilsService } from 'src/app/services/utils.service';
import { ApiService } from 'src/app/services/api.service';
import { MatDialog } from '@angular/material/dialog';
import { AddDepartmentDialogComponent } from './add-department-dialog/add-department-dialog.component';

@Component({
  selector: 'app-departments',
  templateUrl: './departments.component.html',
  styleUrls: ['./departments.component.css']
})
export class DepartmentsComponent implements OnInit {

  displayedColumns: string[] = ['image', 'name', 'description','actions'];
  dataSource:MatTableDataSource<Department> = new MatTableDataSource<Department>();


  //paginator
  length:number = 0;
  pageSize:number = 10;
  pageSizeOptions:number[] = [5,10,20,50];
  pageIndex:number = 1;

  filterForm:UntypedFormGroup;
  private formPersistence:any;

  constructor(
              private fb:UntypedFormBuilder,
              public router:Router,
              private activatedRoute:ActivatedRoute,
              public permissions:PermissionsService,
              private api:ApiService,
              private utils:UtilsService,
              public dialog: MatDialog,
              ) {

      this.filterForm = this.fb.group({
        search: [this.activatedRoute.snapshot.queryParamMap.get('search')!=null ? this.activatedRoute.snapshot.queryParamMap.get('search') : '', Validators.compose([Validators.minLength(3)])],
        per_page: [this.activatedRoute.snapshot.queryParamMap.get('per_page')!=null ? +(this.activatedRoute.snapshot.queryParamMap.get('per_page') as any) : this.pageSize, Validators.required],
        page: [this.activatedRoute.snapshot.queryParamMap.get('page')!=null ? +(this.activatedRoute.snapshot.queryParamMap.get('page') as any) : this.pageIndex, Validators.required],
        total: [this.activatedRoute.snapshot.queryParamMap.get('total')!=null ? +(this.activatedRoute.snapshot.queryParamMap.get('total') as any) : this.length],
      });

   }

  ngOnInit(): void {
    this.fetchDepartments();
    this.initFilterFormListener();
    this.listenQueryParameters();
  }

  changePage(event:PageEvent) {
    this.filterForm.patchValue({
      page: event.pageIndex,
      per_page: event.pageSize
    });
  }

  addDepartment() {
    const dialogRef = this.dialog.open(AddDepartmentDialogComponent, {
      width: '750px',
      data: {
      }
    });

    dialogRef.afterClosed().subscribe((department:Department) => {
      if(department != null) {
        this.api.createDepartment(department).subscribe(data => {
          this.fetchDepartments();
        });
      }
    });
  }

  deleteDepartment(index:number) {
    const department = this.dataSource.data[index];
    if(confirm(`¿Estás seguro que quieres eliminar el Departamento "${department.name}"?`)) {
      this.api.deleteDepartment(department.id).subscribe(
        data => {
          this.fetchDepartments();
        }
      );
    }
  }


  private fetchDepartments() {
    this.api.getDepartmentsPaged(this.filterForm.value).subscribe(
      data => {
        this.dataSource.data = data.data;

        this.length = +data.meta.total;
        this.pageIndex = +data.meta.current_page-1;
        this.pageSize = +data.meta.per_page;
      }
    );
  }

  private initFilterFormListener() {
    this.filterForm.valueChanges.subscribe(
      data => {
        data.per_page = +data.per_page;
        if(this.formPersistence==null || JSON.stringify(this.formPersistence)!=JSON.stringify(data)) {
          if(this.formPersistence.per_page!=data.per_page) data.page = 0;
          this.router.navigate(['/employees', 'rrhh', 'departments'], { queryParams: data });
        }
      }
    );
  }

  private listenQueryParameters() {
    this.activatedRoute.queryParams.subscribe(
      params => {
        if(JSON.stringify(params)!==JSON.stringify(this.filterForm.value)) { //si no ve de filtre s'ha de setejar el form
          let params_temp = this.utils.cloneObj(params); //params es read_only
          Object.keys(params_temp).forEach(param_key => {
            if(params_temp[param_key]!=null && params_temp[param_key]!="" && !isNaN(+params_temp[param_key])) params_temp[param_key] = +params_temp[param_key]; // si es numero, el transformem
          });
          this.filterForm.patchValue(params_temp, { emitEvent: false });
        }
        this.formPersistence = params;
        this.fetchDepartments();
      }
    );
  }



}
