import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { ClockRegister } from 'src/app/models/clock-register.model';
import { EmployeeWorkingSheetDay, EmployeeWorkingSheet } from 'src/app/models/employee-working-sheet.model';
import { User } from 'src/app/models/user.model';
import { ApiService } from 'src/app/services/api.service';
import { UtilsService } from 'src/app/services/utils.service';
import { ClockRegistersManagementTableDetailDialogComponent } from '../clock-registers-management-table-detail-dialog/clock-registers-management-table-detail-dialog.component';

@Component({
  selector: 'app-clock-register-resume',
  templateUrl: './clock-register-resume.component.html',
  styleUrls: ['./clock-register-resume.component.css']
})
export class ClockRegisterResumeComponent implements OnInit {

  showLegend:boolean = false;
  tomorrow:Date = moment().add(1, 'day').startOf('day').toDate();

  filterForm:UntypedFormGroup;
  private formPersistence:any;
  initDisplayedColumns:string[] = ["worker"];
  displayedColumns:string[] = ["worker"];
  daysColumns:EmployeeWorkingSheetDay[] = [];

  dataSource:MatTableDataSource<EmployeeWorkingSheet> = new MatTableDataSource<EmployeeWorkingSheet>([]);

  constructor(private fb:UntypedFormBuilder,
              private activatedRoute:ActivatedRoute,
              private utils:UtilsService,
              private router:Router,
              private api:ApiService,
              private dialog:MatDialog) {
    this.filterForm = this.fb.group({
      user_id: [this.activatedRoute.snapshot.queryParamMap.get('user_id')!=null ? +(this.activatedRoute.snapshot.queryParamMap.get('user_id') as any) : '', Validators.required],
      from: [this.activatedRoute.snapshot.queryParamMap.get('from')!=null ? this.utils.stringToDate(this.activatedRoute.snapshot.queryParamMap.get('from') as string) : this.getInitOfWeekDate(), Validators.required],
      to: [this.activatedRoute.snapshot.queryParamMap.get('to')!=null ? this.utils.stringToDate(this.activatedRoute.snapshot.queryParamMap.get('to') as string) : this.getEndOfWeekDate(), Validators.required]
    });
  }

  ngOnInit(): void {
    this.initFilterFormListener();
    this.listenQueryParameters();

    if(this.filterForm.value['user_id']!='') this.fetchUser(this.filterForm.value['user_id']);
  }

  changeWeek(num:number) {
    const old_from:Date = this.filterForm.value['from'];
    const new_from:Date = new Date(new Date(old_from).setDate(old_from.getDate() + num*7));
    const new_to:Date = new Date(new Date(new_from).setDate(new_from.getDate() + 6));

    this.filterForm.patchValue({
      from: new_from,
      to: new_to
    });
  }

  selectUser(user:User) {
    this.filterForm.controls['user_id'].setValue(user.id);
  }

  getColumnKeyByDate(d:Date) {
    return `col_${d.getTime()}`;
  }

  getDayColumnHeader(d:Date) {
    return moment(d).format('ddd DD');
  }

  getWorklogsRegisteredTimeInHours(index:number) {
    let res:number = 0;
    this.dataSource.data[index].days.forEach(d => {
      res += d.working_time_hours;
    });
    return res;
  }

  getContractWeeklyTimeInHours(index:number) {
    let res:number = 0;
    this.dataSource.data[index].days.forEach(d => {
      res += d.working_contract_hours;
    });
    return res;
  }

  showSlotDetail(ews:EmployeeWorkingSheetDay) {
    const dialogRef = this.dialog.open(ClockRegistersManagementTableDetailDialogComponent, {
      width: '700px',
      data: { ews: ews }
    });
    // dialogRef.afterClosed().subscribe();
  }

  getClockRegistersFromEws(ews:EmployeeWorkingSheet) {
    let res:ClockRegister[] = [];
    ews.days.forEach(d => {
      res.push(...d.clock_registers.filter(c => c.clock_register_mood!=null));
    });
    return res;
  }

  private initFilterFormListener() {
    this.filterForm.valueChanges.subscribe(
      data => {
        if(this.formPersistence==null || JSON.stringify(this.formPersistence)!=JSON.stringify(data)) {
          const params = this.utils.cloneObj(data);
          if(params.from!=null && params.from!="") params.from = this.utils.dateToString(new Date(params.from));
          if(params.to!=null && params.to!="") params.to = this.utils.dateToString(new Date(params.to));
          this.router.navigate(['/employees', 'rrhh', 'clock-registers', 'clock-register-resume'], { queryParams: params });
        }
      }
    );
  }

  private listenQueryParameters() {
    this.activatedRoute.queryParams.subscribe(
      params => {
        if(JSON.stringify(params)!==JSON.stringify(this.filterForm.value)) { //si no ve de filtre s'ha de setejar el form
          let params_temp = this.utils.cloneObj(params); //params es read_only
          Object.keys(params_temp).forEach(param_key => {
            if(params_temp[param_key]!=null && params_temp[param_key]!="" && !isNaN(+params_temp[param_key])) params_temp[param_key] = +params_temp[param_key]; // si es numero, el transformem
            else if(params_temp[param_key]!=null && (params_temp[param_key] as string).indexOf("-")>-1) params_temp[param_key] = this.utils.stringToDate(params_temp[param_key]);
          });
          this.filterForm.patchValue(params_temp, { emitEvent: false });
        }
        this.formPersistence = params;
        this.fetchClockTimes();
      }
    );
  }

  private fetchUser(id:string) {
    this.api.getUser(id).toPromise().then(
      data => {
        if(data != undefined) this.selectUser(data);
      }
    );
  }

  private getInitOfWeekDate(now?:Date) {
    const d = now!=null ? now : new Date();
    var day = d.getDay();
    var diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
    return new Date(d.setDate(diff));
  }

  private getEndOfWeekDate(now?:Date) {
    const d = now!=null ? now : new Date();
    var day = d.getDay();
    var diff = d.getDate() + 7 - day; // adjust when day is sunday
    return new Date(d.setDate(diff));
  }

  private fetchClockTimes() {
    this.api.getWeeklyUsersWorkingSheet(this.filterForm.value).subscribe(
      data => {
        this.displayedColumns = this.initDisplayedColumns.map(item => item);
        this.daysColumns = [];
        if(data.length > 0) {
          const columns = data[0].days;
          for(let day of columns) {
            this.displayedColumns.push(this.getColumnKeyByDate(day.day));
            this.daysColumns.push(day);
          }
          this.displayedColumns.push('mood', 'resume');
        }

        this.dataSource.data = data;
      }
    );
  }

}
