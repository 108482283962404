import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { VacationRequest } from 'src/app/models/vacation_request';
import { VacationRequestPeriod } from 'src/app/models/vacation_request_period';
import { WorkingContract } from 'src/app/models/working_contract.model';
import { ApiService } from 'src/app/services/api.service';
import { PermissionsService } from 'src/app/services/permissions.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-manage-vacation-request',
  templateUrl: './manage-vacation-request.component.html',
  styleUrls: ['./manage-vacation-request.component.css'],
})
export class ManageVacationRequestComponent implements OnInit {
  vacation_request_id:string = null as any;

  displayedColumns: string[] = [
    'id',
    'day',
    'hours'
  ];

  dataSource: MatTableDataSource<VacationRequestPeriod> = new MatTableDataSource<VacationRequestPeriod>();

  // dades per el paginator
  length: number = 0;
  pageSize: number = 99;
  pageSizeOptions: number[] = [5, 10, 20, 50];
  pageIndex: number = 0;

  filterForm: UntypedFormGroup;
  private formPersistence: any;
  timer: number = -1;

  public vacation_request:VacationRequest = null as any;
  public working_contract:WorkingContract = null as any;

  constructor(
    private api: ApiService,
    private fb: UntypedFormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public permissions: PermissionsService,
    private utils: UtilsService,
    public dialog: MatDialog
  ) {
    this.filterForm = this.fb.group({
      search: [
        this.activatedRoute.snapshot.queryParamMap.get('search') != null
          ? this.activatedRoute.snapshot.queryParamMap.get('search')
          : '',
        Validators.compose([Validators.minLength(3)]),
      ],
      per_page: [
        this.activatedRoute.snapshot.queryParamMap.get('per_page') != null
          ? +(this.activatedRoute.snapshot.queryParamMap.get(
              'per_page'
            ) as any)
          : this.pageSize,
        Validators.required,
      ],
      page: [
        this.activatedRoute.snapshot.queryParamMap.get('page') != null
          ? +(this.activatedRoute.snapshot.queryParamMap.get(
              'page'
            ) as any)
          : this.pageIndex,
        Validators.required,
      ],
      total: [
        this.activatedRoute.snapshot.queryParamMap.get('total') != null
          ? +(this.activatedRoute.snapshot.queryParamMap.get('total') as any)
          : this.length,
      ],
      vacation_request_id: [
        this.activatedRoute.snapshot.paramMap.get('id')
      ],
    });
  }

  ngOnInit(): void {
    this.vacation_request_id = this.activatedRoute.snapshot.paramMap.get('id') as string;
    this.fetchVacationRequest();
    this.fetchVacationRequestPeriod();
    this.initFilterFormListener();
    this.listenQueryParameters();
  }

  changePage(event:PageEvent) {
    this.filterForm.patchValue({
      page: event.pageIndex,
      per_page: event.pageSize
    });
  }

  getHoursOfWorkPerDay(date:Date) {
    if(this.working_contract==null) return 0;
    return this.working_contract.user_weekly_expected_hours[date.getDay()];
  }

  private fetchVacationRequest() {
    this.api.getVacationRequest(this.vacation_request_id, { with: 'vacation_request_periods,user.current_working_contract.current_working_contract_variable_condition' }).subscribe(
      data => {
        this.vacation_request = data;
        this.api.getActiveWorkingContract(data.user_id, {appends: "user_weekly_expected_hours"}).subscribe(
          data => {
            this.working_contract = data;
          }
        );
      }
    );
  }

  private fetchVacationRequestPeriod(){
    this.api.getVacationRequestPeriodPaged(this.filterForm.value).subscribe(
      data => {
        this.dataSource.data = data.data;

        this.length = +data.meta.total;
        this.pageIndex = +data.meta.current_page-1;
        this.pageSize = +data.meta.per_page;

        if (this.filterForm.value.search) {
          if (
            this.filterForm.value.page + 1 >
            Math.ceil(this.length / this.pageSize)
          ) {
            this.filterForm.patchValue({
              page: 0,
            });
          }
        }
      }
    );
  }

  private initFilterFormListener() {
    this.filterForm.valueChanges.subscribe(
      data => {
        data.per_page = +data.per_page;
        if(this.formPersistence==null || JSON.stringify(this.formPersistence)!=JSON.stringify(data)) {
          if(this.formPersistence.per_page!=data.per_page) data.page = 0;
          this.router.navigate(['/employees', 'vacation-request'], { queryParams: data });
        }
      }
    );
  }

  private listenQueryParameters() {
    this.activatedRoute.queryParams.subscribe(
      params => {
        if(JSON.stringify(params)!==JSON.stringify(this.filterForm.value)) { //si no ve de filtre s'ha de setejar el form
          let params_temp = this.utils.cloneObj(params); //params es read_only
          Object.keys(params_temp).forEach(param_key => {
            if(params_temp[param_key]!=null && params_temp[param_key]!="" && !isNaN(+params_temp[param_key])) params_temp[param_key] = +params_temp[param_key]; // si es numero, el transformem
          });
          this.filterForm.patchValue(params_temp, { emitEvent: false });
        }
        this.formPersistence = params;
        if(this.timer !== -1 && this.filterForm.value.search){
          clearTimeout(this.timer);
          this.timer = window.setTimeout(() =>{
            this.fetchVacationRequestPeriod();
          },500);
        }else{
          clearTimeout(this.timer);
          this.fetchVacationRequestPeriod();
          this.timer = 0;
        }
      }
    );
  }

  public acceptVacation() {
    this.vacation_request.status = 'accepted';
    this.api.updateVacationRequest(this.vacation_request_id, this.vacation_request).subscribe();
    this.router.navigate(['/employees/rrhh/absences-management/vacation-requests']);
  }

  public declineVacation() {
    this.vacation_request.status = 'rejected';
    this.api.updateVacationRequest(this.vacation_request_id, this.vacation_request).subscribe();
    this.router.navigate(['/employees/rrhh/absences-management/vacation-requests']);
  }

}
