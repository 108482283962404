import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MeetingRecord } from 'src/app/models/meeting-record.interface';
import { PotentialClient } from 'src/app/models/potential-client.interface';
import { ApiService } from 'src/app/services/api.service';
import { TinymceService } from 'src/app/services/tinymce.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-meeting-record',
  templateUrl: './meeting-record.component.html',
  styleUrls: ['./meeting-record.component.css']
})
export class MeetingRecordComponent implements OnInit {

  @Input() meetingableType:string;
  @Input() meetingableId:string;
  @Input() meetingRecordId:string;
  @Input() onBackRoute:string;
  @Input() onSaveRoute:string;

  meetingRecordFormGroup:FormGroup;
  initialMeetingRecord:MeetingRecord = null as any;

  tinyMceInit = {
    automatic_uploads: true,
    statusbar: false,
    menubar: false,
    block_unsupported_drop: false,
    height: "68vh",
    codesample_languages: [
      { text: 'HTML/XML', value: 'c' },
      { text: 'JavaScript', value: 'javascript' },
      { text: 'CSS', value: 'css' },
      { text: 'PHP', value: 'php' },
      { text: 'Ruby', value: 'ruby' },
      { text: 'Python', value: 'python' },
      { text: 'Java', value: 'java' },
      { text: 'C', value: 'c' },
      { text: 'C#', value: 'csharp' },
      { text: 'C++', value: 'cpp' },
      { text: 'Bash', value: 'bash' },
      { text: 'SQL', value: 'sql' },
      { text: 'JSON', value: 'json' },
      { text: 'YAML', value: 'yaml' },
      { text: 'Markdown', value: 'markdown' },
      { text: 'Dockerfile', value: 'dockerfile' },
      { text: 'Git', value: 'git' },
      { text: 'Nginx', value: 'nginx' },
      { text: 'Apache', value: 'apache' },
      { text: 'Ini', value: 'ini' },
      { text: 'TypeScript', value: 'typescript' },
      { text: 'Dart', value: 'dart' }
    ]
  };

  get isCreate() {
    return this.meetingRecordId == null;
  }

  constructor(private api:ApiService,
              private fb:FormBuilder,
              private router:Router,
              private activatedRoute:ActivatedRoute,
              public tinymce:TinymceService) {

    this.meetingRecordFormGroup = this.fb.group({
      meetingable_id: [null, Validators.compose([Validators.required])],
      meetingable_type: [null, Validators.compose([Validators.required])],
      date: [new Date(), Validators.required],
      assistants_client: ['', Validators.required],
      assistants_provider: ['', Validators.required],
      title: ['', Validators.compose([Validators.required, Validators.minLength(5)])],
      content: ['', Validators.compose([Validators.minLength(10)])]
    });
  }

  ngOnInit(): void {
    this.meetingRecordFormGroup.patchValue({
      meetingable_id: this.meetingableId,
      meetingable_type: this.meetingableType
    });
    if(!this.isCreate) {
      this.fetchMeetingRecord();
    }
  }

  process() {
    if(this.isCreate) {
      this.api.createMeetingRecord(this.meetingRecordFormGroup.value).subscribe(
        data => {
          if(this.onSaveRoute) {
            this.router.navigateByUrl(this.onSaveRoute.replace(':meeting_record_id', data.id.toString()));
          } else {
            this.router.navigate([data.id], { relativeTo: this.activatedRoute });
          }
        }
      );
    }
    else {
      this.api.updateMeetingRecord(this.meetingRecordId, this.meetingRecordFormGroup.value).subscribe(
        data => {
          this.processMeetingRecord(data);
        }
      );
    }
  }

  private fetchMeetingRecord() {
    this.api.getMeetingRecord(this.meetingRecordId).subscribe(
      data => {
        this.processMeetingRecord(data);
      }
    );
  }

  canDeactivate(): boolean {
    return this.initialMeetingRecord != null &&
      this.initialMeetingRecord.title == this.meetingRecordFormGroup.value.title &&
      this.initialMeetingRecord.content == this.meetingRecordFormGroup.value.content &&
      this.initialMeetingRecord.assistants_client == this.meetingRecordFormGroup.value.assistants_client &&
      this.initialMeetingRecord.assistants_provider == this.meetingRecordFormGroup.value.assistants_provider &&
      this.initialMeetingRecord.date == this.meetingRecordFormGroup.value.date;
  }

  private processMeetingRecord(data:MeetingRecord) {
    this.meetingRecordFormGroup.patchValue(data);
    this.initialMeetingRecord = data;
  }
}
