import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { TokenService } from '../services/token.service';

@Injectable({
    providedIn: 'root'
})
export class TokenInterceptor implements HttpInterceptor {
    private apiUrl:string = environment.laravel_url + "/api";

    constructor(private token:TokenService) {}
  
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      const tractable:boolean = (request.url.indexOf(this.apiUrl) != -1);
  
      //tractem request
      if(tractable) {
        // posem token
        if(this.token.loggedIn()) {
          request = request.clone({ 
            headers: request.headers.set("Authorization", "Bearer " + this.token.get())
          });
        }
      }
  
      //tractem response
      return next.handle(request);
    }
}