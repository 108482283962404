import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-medical-sick-leaves',
  templateUrl: './medical-sick-leaves.component.html',
  styleUrls: ['./medical-sick-leaves.component.css']
})
export class MedicalSickLeavesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
