import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { TokenService } from '../services/token.service';
import { UserService } from '../services/user.service';

@Injectable()
export class LoggedGuard  {
  constructor(private router: Router,
              private auth:AuthService,
              private token:TokenService,
              private user:UserService) { }

  async canActivate(route:ActivatedRouteSnapshot, state:RouterStateSnapshot) {
    const tokenSet:boolean = this.token.get() != null;
    const authOk:boolean = this.auth.isLoggedIn();
    const loggedIn:boolean = (tokenSet && authOk) || await this.auth.refreshToken().toPromise() as boolean;

    if(!loggedIn) {
      return this.logout(route, state);
    }

    return true;
  }

  private logout(route:ActivatedRouteSnapshot, state:RouterStateSnapshot) {
    this.auth.logout();

    const query:any = {}
    if(state.url != null && state.url != "") query.redirect = state.url;

    this.router.navigate(['/auth', 'login'], { queryParams: query });
    return false;
  }
}
